import React from 'react'


import {  
    AreaChartOutlined,
    ArrowRightOutlined,
    PercentageOutlined,
    ClockCircleOutlined,
    DownloadOutlined,
    RiseOutlined,
    UnorderedListOutlined,
    BarChartOutlined,
    LoadingOutlined
} from '@ant-design/icons';
import UserList from '../../components/UserList';
import { COURSE_ITEM_TYPE_QUIZ, PRODUCT_TYPE_COURSE } from '../../Constants';
import { Button, Progress, Radio, Spin, Tooltip } from 'antd';
import Util from '../../util/Util';
import QuizSubmissionHistoryDialog from '../../components/QuizSubmissionHistoryDialog';

import '../../../node_modules/react-vis/dist/style.css';
import { AreaSeries,
    Crosshair,
    XYPlot,
    XAxis,
    YAxis,
    HorizontalGridLines,
    VerticalGridLines,
    VerticalBarSeries,
} from 'react-vis';
import Api from '../../session/Api';
import UIUtil from '../../util/UIUtil';

const QuizAttemptStatsChartView = (props) => {
    const [loading, setLoading] = React.useState(true);
    const [point, setPoint] = React.useState([]);

    const [data, setData] = React.useState([]);

    React.useEffect(() => {
        Api.getQuizSubmissionReport(props.courseItemId, response => {
            if (response.status === true) {
                setLoading(false);
                setData(response.payload)
            } else {
                setLoading(false);
                UIUtil.showError();
            }
        })
    }, [])

    return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
            {!loading ? <>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <h6 style={{fontSize: 12, textAlign: 'center', writingMode: "vertical-rl", transform: "rotate(180deg)", width: 0, marginLeft: -25}}>Number of Learners (%)</h6>
                <XYPlot width={600} height={300} yDomain={[0,100]} xDomain={[0,100]} xPadding={0} onMouseLeave={() => setPoint([])}>
                    <VerticalGridLines title="Test" />
                    <HorizontalGridLines />
                    <XAxis tickFormat={value => value + "%"} tickTotal={5} />
                    <YAxis tickFormat={value => value + "%"} tickSize={1} />
                    <VerticalBarSeries
                        className="vertical-bar-series-example"
                        //curve={'curveMonotoneX'}
                        color="#f26622"
                        /*data={[
                            //{x: 0, y: 75},
                            {x: 45, y: 75},
                            {x: 55, y: 60},
                            {x: 25, y: 80},
                            {x: 85, y: 25},
                            {x: 100, y: 90}
                        ]}*/
                        data={data}
                        onNearestX={v => {
                            console.log(v);
                            setPoint([v])
                        }}
                    />
                    <Crosshair values={point} style={{line: {background: '#4e2e89'}}}>
                        <div>
                            {point.length > 0 &&
                            <Tooltip key={point[0].x} visible title={<div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                <p style={{margin: 0, textAlign: 'center'}}>{point[0].title}</p>
                                <p style={{color: '#cecece', margin: 0, textAlign: 'center'}}>{point[0].subtitle}</p>
                            </div>} />}
                        </div>
                    </Crosshair>
                </XYPlot> 
            </div>
            <h6 style={{width: '100%', fontSize: 12, textAlign: 'center'}}>Best Score (%)</h6>

            </>:
            <Spin size="large" indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />}
        </div>
    )
}

class CourseLearnerAnalytics extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            displayMode: "list",
            quizAttemptHistoryUser: undefined
        }
    }

    render() {
        return (
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <h2 style={{color: 'black', fontSize: 35}}>
                    <AreaChartOutlined /> Learner Analytics
                </h2>

                {this.props.courseItem.type == COURSE_ITEM_TYPE_QUIZ && 
                <Radio.Group value={this.state.displayMode} onChange={e => this.setState({displayMode: e.target.value})} style={{alignSelf: 'flex-end', marginBottom: 15}}>
                    <Radio.Button value="list"><UnorderedListOutlined /></Radio.Button>
                    <Radio.Button value="chart"><BarChartOutlined /></Radio.Button>
                </Radio.Group>}

                {this.state.displayMode == "list" ? (
                    <UserList showCourseItemStats={{
                        courseItemId: this.props.courseItem.id,
                        courseId: this.props.courseId,
                        
                        courseItemType: this.props.courseItem.type, 
                        onQuizAttemptHistoryBtn: id => this.setState({quizAttemptHistoryUser: id})
                    }} emptyMessage="No learners enrolled in this course" />
                ) : (
                    <QuizAttemptStatsChartView courseItemId={this.props.courseItem.id} />
                )}

                {this.state.quizAttemptHistoryUser !== undefined &&
                <QuizSubmissionHistoryDialog quizAttemptUser={this.state.quizAttemptHistoryUser} quizItem={this.props.courseItem} 
                onCancel={() => this.setState({quizAttemptHistoryUser: undefined})} />}
            </div>
        )
    }

}

export default CourseLearnerAnalytics;