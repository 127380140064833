

export const WHITEBOARD_STREAM_WIDTH = 800;
export const WHITEBOARD_STREAM_HEIGHT = 600;

class CanvasStream {

    /**
     * @type {HTMLVideoElement}
     */
    background;
    
    /**
     * @type {HTMLCanvasElement}
     */
    canvas;

    /**
     * @type {MediaStream}
     */
    rawStream;

    constructor(canvas) {
        this.canvas = canvas;
    }

    setBackgroundStream(stream) {
        if (stream !== undefined) {
            const video = document.createElement("video");
            video.width = WHITEBOARD_STREAM_WIDTH;
            video.height = WHITEBOARD_STREAM_HEIGHT;
            video.srcObject = stream;
            video.muted = true;
            
            video.play();

            this.background = video;
        } else {
            this.background = undefined;
        }
    }

    startStream() {
        const streamCanvas = document.createElement("canvas");
        streamCanvas.width = WHITEBOARD_STREAM_WIDTH;
        streamCanvas.height = WHITEBOARD_STREAM_HEIGHT;

        this.rawStream = streamCanvas.captureStream(25);
        this.interval = setInterval(() => {
            if (streamCanvas.getContext) {
                const context = streamCanvas.getContext('2d');
                context.clearRect(0, 0, WHITEBOARD_STREAM_WIDTH, WHITEBOARD_STREAM_HEIGHT);

                context.fillStyle = "#ffffff";
                context.fillRect(0, 0, WHITEBOARD_STREAM_WIDTH, WHITEBOARD_STREAM_HEIGHT);

                if (this.background !== undefined) {
                    context.drawImage(this.background, 0, 0, WHITEBOARD_STREAM_WIDTH, WHITEBOARD_STREAM_HEIGHT);
                }

                context.drawImage(this.canvas, 0, 0);
            }
        }, 1000 / 25);

        if (!(this.rawStream !== undefined && this.rawStream !== null)) {
            throw new Error("Failed to start stream");
        }
    }

    closeStream() {
        if (this.rawStream !== undefined) {
            this.rawStream.getTracks().forEach(track => track.stop());
        }
        if (this.interval !== undefined) {
            clearInterval(this.interval);
        }
    }

}

export default CanvasStream;