import React, { useState } from 'react';
import Page from '../../base/Page';
import withMediaQuery from '../../components/WithMediaQuery';

import { 
    EyeOutlined,
    ClusterOutlined,
    AlertOutlined,
    ArrowRightOutlined,
    BookOutlined,
    UserOutlined,
    LeftOutlined,
    DeleteFilled,
    DragOutlined,
    FireOutlined,
    CameraFilled,
    InboxOutlined
} from '@ant-design/icons';
import { Button, Input, Popconfirm, Spin, Tooltip, Upload } from 'antd';
import Api from '../../session/Api';
import UIUtil from '../../util/UIUtil';

import NoImage from '../../images/NoImage.png';
import Util from '../../util/Util';
import { Link, useHistory, withRouter } from 'react-router-dom';

import {
    sortableContainer,
    sortableElement,
    sortableHandle,
} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { FEATURED_ITEM_TYPE_COURSES, FEATURED_ITEM_TYPE_HASHTAGS, FEATURED_ITEM_TYPE_LIVE_SESSIONS, FEATURED_ITEM_TYPE_ORGANIZATIONS, FEATURED_ITEM_TYPE_TUTORS } from './FeaturedSelectorPage';
import Modal from 'antd/lib/modal/Modal';
import { API_ERROR_MESSAGE_FEATURED_CONTENT_EXCEEDED_MAX } from '../../Constants';
import SessionManager from '../../session/SessionManager';

const FormPadding = () => {
    return ( <> <br /> <br /> </> )
}

const SortableItem = sortableElement(props => <FeaturedItem {...props} />);
const SortableContainer = sortableContainer(({children}) => <div>{children}</div>);

const FeaturedItem = ({item, itemIndex, onDeleted, onHashtagImageBtn, hashtagImageId}) => {
    const SortHandler = sortableHandle(() => <DragOutlined style={{fontSize: 18, cursor: 'move'}} />)
    
    const [mouseHover, setMouseHover] = useState(false);
    const [deleting, setDeleting] = useState(false);

    const onDeleteBtn = () => {
        setDeleting(true);
        Api.setContentFeature(false, item.type, item.objectId, response => {
            if (response.status === true) {
                UIUtil.showSuccess();
                onDeleted();
            } else {
                UIUtil.showError();
                setDeleting(false);
            }
        })
    }

    const getLink = () => {
        switch (item.type) {
            case FEATURED_ITEM_TYPE_COURSES:
                return "/course-sale/" + item.objectId;
            case FEATURED_ITEM_TYPE_LIVE_SESSIONS:
                return "/live-session-sale/" + item.objectId;
            case FEATURED_ITEM_TYPE_TUTORS:
                return "/profile/" + item.objectId;
            case FEATURED_ITEM_TYPE_ORGANIZATIONS:
                return "/profile/" + item.objectId;
            case FEATURED_ITEM_TYPE_HASHTAGS:
                return "/hashtag/" + item.objectId;
        }
    }

    const getImage = () => {
        switch (item.type) {
            case FEATURED_ITEM_TYPE_COURSES:
                return Api.getCourseThumbnail(item.objectId);
            case FEATURED_ITEM_TYPE_LIVE_SESSIONS:
                return Api.getLiveSessionThumbnail(item.objectId);
            case FEATURED_ITEM_TYPE_TUTORS:
                return Api.getProfilePic(item.objectId);
            case FEATURED_ITEM_TYPE_ORGANIZATIONS:
                return Api.getProfilePic(item.objectId);
            case FEATURED_ITEM_TYPE_HASHTAGS:
                let path = Api.getHashtagThumbnail(item.objectId);
                if (Util.isStringExists(hashtagImageId)) {
                    path += "&t=" + hashtagImageId;
                }
                return path;
        }
    }

    return (
        <div 
        onMouseEnter={() => setMouseHover(true)} onMouseLeave={() => setMouseHover(false)}
        style={{position: 'relative', zIndex: 100, display: 'flex', flex: 1, alignItems: 'center', background: item.empty ? '#f2f2f2' : 'white', marginBottom: '15px', boxShadow: item.empty ? 'none' : "0px 0px 10px 0px rgba(0, 0, 0, 0.1)", borderRadius: '5px', padding: '15px'}} >
            {!item.empty && 
            <div style={{position: 'absolute', left: -50, top: 0, bottom: 0, right: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', transition: '250ms', opacity: mouseHover ? 1 : 0}}>
                <SortHandler />
            </div>}
            <h1 style={{fontWeight: 'bolder', margin: 0, marginRight: 15, opacity: 0.65}}>{itemIndex + 1}</h1>
            {!item.empty ? (<>
                <Link to={getLink()} style={{display: 'flex', alignItems: 'center', flex: 1, color: 'black'}}>
                    <img src={getImage()} style={{width: 44, height: 44, objectFit: 'cover', borderRadius: '15px'}} onError={e => {
                        e.target.onerror = null;
                        e.target.src = NoImage;
                    }} />
                    
                    <div style={{paddingLeft: 15, paddingRight: 15, flex: 1}}>
                        <h3 style={{margin: 0}}>{item.objectName}</h3>
                        <p style={{margin: 0, marginTop: 0, opacity: 0.65, fontSize: 12}}>Set on {Util.getDateOnly(item.date)} by {item.adminName}</p>
                    </div>
                </Link>

                {item.type == FEATURED_ITEM_TYPE_HASHTAGS &&
                <Button onClick={onHashtagImageBtn} type="link" icon={<CameraFilled />} style={{marginRight: 15}} />}
                
                <Popconfirm okButtonProps={{loading: deleting}} onConfirm={onDeleteBtn} placement="left" title="Are you sure? Removing from the featured list will stop displaying it in the home page and discovery page.">
                    <Button danger type="link" icon={<DeleteFilled />} />
                </Popconfirm>
            </>) : <p style={{margin: 0, fontStyle: 'italic', opacity: 0.65}}>Empty feature slot</p>}
        </div>
    )
}

export const getFeaturedItemTypeIcon = (type) => {
    switch (type) {
        case FEATURED_ITEM_TYPE_COURSES:
            return BookOutlined;
        case FEATURED_ITEM_TYPE_LIVE_SESSIONS:
            return AlertOutlined;
        case FEATURED_ITEM_TYPE_TUTORS:
            return UserOutlined;
        case FEATURED_ITEM_TYPE_ORGANIZATIONS:
            return ClusterOutlined;
        case FEATURED_ITEM_TYPE_HASHTAGS:
            return FireOutlined;
    }
}

export const getFeaturedItemTypeTitle = (type) => {
    switch (type) {
        case FEATURED_ITEM_TYPE_COURSES:
            return "Featured Courses";
        case FEATURED_ITEM_TYPE_LIVE_SESSIONS:
            return "Featured Live Sessions";
        case FEATURED_ITEM_TYPE_TUTORS:
            return "Featured Tutors";
        case FEATURED_ITEM_TYPE_ORGANIZATIONS:
            return "Featured Organizations";
        case FEATURED_ITEM_TYPE_HASHTAGS:
            return "Trending Hashtags";
    }
}


class FeaturedSelectorView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            loading: true,
            items: [],
            featuringNewHashtagValue: "",
            updatingHashtagImagesValue: "",

            selectedHashtag: undefined,
            selectedHashtagThumbnail: undefined,
            selectedHashtagCover: undefined,
            uploadingHashtagImages: false
        }
    }

    componentDidMount() {
        this.setState({loading: true});
        Api.getFeatures(this.getFeaturedItemType(), response => {
            if (response.status === true) {
                this.setState({loading: false, items: response.payload})
            } else {
                UIUtil.showError();
            }
        })
    }

    getFeaturedItemType() {
        return this.props.featuredItemType;
    }

    getMaxItems() {
        switch (this.getFeaturedItemType()) {
            case FEATURED_ITEM_TYPE_COURSES:
                return 8;
            case FEATURED_ITEM_TYPE_LIVE_SESSIONS:
                return 8;
            case FEATURED_ITEM_TYPE_TUTORS:
                return 4;
            case FEATURED_ITEM_TYPE_ORGANIZATIONS:
                return 4;
            case FEATURED_ITEM_TYPE_HASHTAGS:
                return 4;
        }
    }

    getIcon() {
        switch (this.getFeaturedItemType()) {
            case FEATURED_ITEM_TYPE_COURSES:
                return BookOutlined;
            case FEATURED_ITEM_TYPE_LIVE_SESSIONS:
                return AlertOutlined;
            case FEATURED_ITEM_TYPE_TUTORS:
                return UserOutlined;
            case FEATURED_ITEM_TYPE_ORGANIZATIONS:
                return ClusterOutlined;
            case FEATURED_ITEM_TYPE_HASHTAGS:
                return FireOutlined;
        }
    }

    getTitle() {
        switch (this.getFeaturedItemType()) {
            case FEATURED_ITEM_TYPE_COURSES:
                return "Featured Courses";
            case FEATURED_ITEM_TYPE_LIVE_SESSIONS:
                return "Featured Live Sessions";
            case FEATURED_ITEM_TYPE_TUTORS:
                return "Featured Tutors";
            case FEATURED_ITEM_TYPE_ORGANIZATIONS:
                return "Featured Organizations";
            case FEATURED_ITEM_TYPE_HASHTAGS:
                return "Trending Hashtags";
        }
    }

    getAllPath() {
        switch (this.getFeaturedItemType()) {
            case FEATURED_ITEM_TYPE_COURSES:
                return "/all-courses";
            case FEATURED_ITEM_TYPE_LIVE_SESSIONS:
                return "/all-live-sessions";
            case FEATURED_ITEM_TYPE_TUTORS:
                return "/all-tutors";
            case FEATURED_ITEM_TYPE_ORGANIZATIONS:
                return "/all-organizations";
        }
    }

    getEmptyItems() {
        let emptySlots = [];
        for (let i = 0; i < this.getMaxItems() - this.state.items.length; i++) {
            emptySlots.push({empty: true})
        }
        return emptySlots;
    }

    onOrderUpdate({oldIndex, newIndex}) {
        this.setState(({items}) => ({
            items: arrayMove(items, oldIndex, newIndex),
        }), () => Api.setFeatureOrder(this.state.items.map(item => item.id), response => {
            if (response.status !== true) {
                UIUtil.showError("Reordering failed to sync. Please try again later.");
            }
        }));
    }

    onFeatureHashtag() {
        this.props.closeFeatureNewHashtag();
        this.props.onSetCreatingFeatureLoading(true);
        Api.setContentFeature(true, FEATURED_ITEM_TYPE_HASHTAGS, this.state.featuringNewHashtagValue, response => {
            this.setState({featuringNewHashtagValue: ""})
            this.props.onSetCreatingFeatureLoading(false);

            if (response.status === true) {
                if (response.payload != null) {
                    response.payload.adminName = SessionManager.getAccount().fullName;
                    response.payload.objectName = response.payload.objectId;
                }
                this.setState(prevState => ({items: [response.payload, ...prevState.items]}));
            } else {
                if (response.message == API_ERROR_MESSAGE_FEATURED_CONTENT_EXCEEDED_MAX) {
                    UIUtil.showInfo("No more items can be featured. Please remove another time to make space.")
                } else {
                    UIUtil.showError();
                }
            }
        })
    }

    uploadHashtagImages() {
        const hashtag = this.state.selectedHashtag;

        this.setState({uploadingHashtagImages: true})
        Api.uploadHashtagImages(hashtag, this.state.selectedHashtagThumbnail, this.state.selectedHashtagCover, response => {
            if (response.status === true) {
                this.setState({uploadingHashtagImages: false, selectedHashtag: undefined, selectedHashtagCover: undefined, selectedHashtagThumbnail: undefined, [hashtag + '-key']: Util.newTempId()})
                UIUtil.showSuccess();
            } else {
                this.setState({uploadingHashtagImages: false})
                UIUtil.showError();
            }
        })
    }

    render() {
        return (
            <div style={{width: '100%'}}>
                {this.state.loading ? (<Spin tip="Loading..." size="large" style={{width: '100%', marginTop: 25}} />) : (<>
                    {/* {this.state.items.map((item, index) => <FeaturedItem item={item} index={index} onDeleted={() => this.setState({items: [...this.state.items.filter(newItem => newItem != item)]})} />)} */}
                    <SortableContainer onSortEnd={this.onOrderUpdate.bind(this)} useDragHandle>
                        {this.state.items.map((item, index) => (
                            <SortableItem key={`item-real-${item.objectId}`} index={index} itemIndex={index} item={item} hashtagImageId={this.state[item.objectId + '-key']} onDeleted={() => this.setState({items: [...this.state.items.filter(newItem => newItem != item)]})} onHashtagImageBtn={() => this.setState({selectedHashtag: item.objectId})} />
                        ))}
                    </SortableContainer>
                    {this.getEmptyItems().map((item, index) => <FeaturedItem item={item} itemIndex={index + this.state.items.length} />)}
                </>)}

                <Modal
                    title="Feature New Hashtag"
                    visible={this.props.featureNewHashtag}
                    okText="Feature"
                    okButtonProps={{disabled: this.state.featuringNewHashtagValue.trim() === ""}}
                    onOk={this.onFeatureHashtag.bind(this)}
                    onCancel={this.props.closeFeatureNewHashtag}
                    >
                    <Input className="message-input" placeholder="Hashtag" size="large" 
                    addonBefore="#"
                    value={this.state.featuringNewHashtagValue} onChange={e => this.setState({featuringNewHashtagValue: e.target.value.replace("#", "")})} 
                    onPressEnter={this.onFeatureHashtag.bind(this)}/>
                </Modal>

                <Modal
                    title="Update Hashtag Images"
                    visible={this.props.updateHashtagImages}
                    okText="Select"
                    okButtonProps={{disabled: this.state.updatingHashtagImagesValue.trim() === ""}}
                    onOk={() => {
                        this.props.closeUpdateHashtagImages()
                        this.setState({selectedHashtag: this.state.updatingHashtagImagesValue, updatingHashtagImagesValue: ""})
                    }}
                    onCancel={this.props.closeUpdateHashtagImages}
                    >
                    <Input className="message-input" placeholder="Hashtag" size="large" 
                    addonBefore="#"
                    value={this.state.updatingHashtagImagesValue} onChange={e => this.setState({updatingHashtagImagesValue: e.target.value.replace("#", "")})} 
                    onPressEnter={() => {
                        this.props.closeUpdateHashtagImages()
                        this.setState({selectedHashtag: this.state.updatingHashtagImagesValue, updatingHashtagImagesValue: ""})
                    }}/>
                </Modal>


                <Modal
                    title="Hashtag Images"
                    width={750}
                    confirmLoading={this.state.uploadingHashtagImages}
                    visible={Util.isStringExists(this.state.selectedHashtag)}
                    okText="Upload"
                    okButtonProps={{disabled: this.state.selectedHashtagCover === undefined && this.state.selectedHashtagThumbnail === undefined}}
                    onOk={this.uploadHashtagImages.bind(this)}
                    onCancel={() => this.setState({selectedHashtag: undefined, selectedHashtagCover: undefined, selectedHashtagThumbnail: undefined})}
                    >
                        <h2>#{this.state.selectedHashtag}</h2>
                    
                        <h3 style={{marginBottom: 0}}>Thumbnail</h3>
                        <p>Suggested image size: 500x450 (to prevent cropping)</p>
                        <div style={{display: 'flex', maxHeight: '250px'}}>
                            <img src={this.state.selectedHashtagThumbnail !== undefined ? URL.createObjectURL(this.state.selectedHashtagThumbnail) : Api.getHashtagThumbnail(this.state.selectedHashtag)} onError={e => {
                                e.target.onerror = null;
                                e.target.src = NoImage;
                            }} style={{width: '300px', height: '250', objectFit: 'cover'}} />
                            <div style={{height: '250px', maxHeight: '250px', flex: 1, marginLeft: '25px'}}>
                                <Upload.Dragger accept="image/gif,image/jpeg,image/png" showUploadList={false} beforeUpload={file => {
                                    this.setState({selectedHashtagThumbnail: file})
                                    return false;
                                }}>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                </Upload.Dragger>
                            </div>
                        </div>

                        <FormPadding />
                        <h3>Cover</h3>
                        <div style={{display: 'flex', maxHeight: '250px'}}>
                            <img src={this.state.selectedHashtagCover !== undefined ? URL.createObjectURL(this.state.selectedHashtagCover) : Api.getHashtagCover(this.state.selectedHashtag)} onError={e => {
                                e.target.onerror = null;
                                e.target.src = NoImage;
                            }} style={{width: '300px', height: '250', objectFit: 'cover'}} />
                            <div style={{height: '250px', maxHeight: '250px', flex: 1, marginLeft: '25px'}}>
                                <Upload.Dragger accept="image/gif,image/jpeg,image/png" showUploadList={false} beforeUpload={file => {
                                    this.setState({selectedHashtagCover: file})
                                    return false;
                                }}>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                </Upload.Dragger>
                            </div>
                        </div>
                </Modal>
            </div>
        )
    }

}

export default withMediaQuery(FeaturedSelectorView);