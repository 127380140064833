import React from 'react'
import Modal from 'antd/lib/modal/Modal';
import FrontendAuthView from '../views/auth/FrontendAuthView';

class FrontendAuthModal extends React.Component {
    
    render() {
        return (
            <Modal visible={this.props.visible} footer={[]} centered onCancel={this.props.onCancel}>
                <FrontendAuthView registerType="0" miniMode onDonePath={this.props.onDonePath} />
            </Modal>
        )
    }

}

export default FrontendAuthModal;