import React from 'react';
import WaveSurfer from 'wavesurfer.js';

import { Button } from 'antd';

import {
    PlayCircleFilled,
    PauseCircleFilled
} from '@ant-design/icons'

class AudioWaveform extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            playing: false
        }

        this.waveformContainer = React.createRef();
    }

    componentWillReceiveProps(props) {
        if (props.message !== undefined && this.waveform !== undefined) {
            if (props.message.requestPlay) {
                props.message.requestPlay = false;
                if (!this.waveform.isPlaying()) {
                    this.onPlayPauseBtn();
                }
            } else if (props.message.requestStop) {
                props.message.requestStop = false;
                if (this.waveform.isPlaying()) {
                    this.setState({ playing: false });
                    this.waveform.stop();
                }
            }
        }
    }

    componentDidMount() {
        this.waveform = WaveSurfer.create({
            barWidth: 3,
            cursorWidth: 1,
            container: this.waveformContainer.current,
            backend: 'WebAudio',
            height: 80,
            progressColor: '#f26622',
            responsive: true,
            waveColor: this.props.black ? 'black' : 'white',
            cursorColor: 'transparent',
            barMinHeight: 1,
        })
        this.waveform.on('play', () => {
            if (this.props.onStartedPlayingAudio !== undefined) {
                this.props.onStartedPlayingAudio();
            }
        })
        this.waveform.on('finish', this.onAudioDonePlaying)
        this.waveform.on('ready', () => {
            this.props.onAudioDurationUpdate(this.waveform.getDuration())
        })
        this.waveform.on('audioprocess', () => {
            this.props.onAudioDurationUpdate(this.waveform.getCurrentTime())
        })
        this.waveform.on('seek', () => {
            if (!this.state.playing) {
                this.props.onAudioDurationUpdate(this.waveform.getCurrentTime())
            }
        })

        if (this.props.audioUrl !== undefined) {
            this.waveform.load(this.props.audioUrl);
        } else if (this.props.audioBlob !== undefined) {
            this.waveform.loadBlob(this.props.audioBlob);
        }
    }

    componentWillUnmount() {
        if (this.waveform !== undefined) {
            this.waveform.destroy();
        }
    }

    onAudioDonePlaying = () => {
        this.waveform.seekTo(0);
        if (this.state.playing) {
            this.setState({playing: false})

            if (this.props.onDonePlayingAudio !== undefined) {
                this.props.onDonePlayingAudio();
            }
        }
    }

    onPlayPauseBtn() {
        this.setState({ playing: !this.state.playing });
        this.waveform.playPause();
    }

    render() {
        return (
            <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingRight: '15px'}}>
                <Button onClick={this.onPlayPauseBtn.bind(this)} type="text" style={{color: this.props.black ? 'black' : 'white', fontSize: '30px', height: '100%'}}>
                    {this.state.playing ? <PauseCircleFilled /> : <PlayCircleFilled />}
                </Button>
                <div style={{flex: '1'}} ref={this.waveformContainer} />
            </div>
        )
    }
    
}

export default AudioWaveform;