import React from 'react'
import Api from '../../session/Api';
import Util from '../../util/Util';

import NoImage from '../../images/NoImage.png';
import { Tooltip, Button, Popconfirm } from 'antd';

import {  
    EyeOutlined,
    EyeInvisibleOutlined,
    EditOutlined,
    DeleteOutlined,
    VideoCameraOutlined,
    AudioOutlined,
    FileOutlined,
    TeamOutlined,
    AlertOutlined
} from '@ant-design/icons';
import UIUtil from '../../util/UIUtil';

import Countdown from 'antd/lib/statistic/Countdown';
import { Link } from 'react-router-dom';

class CourseScheduleItem extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            deleteLoading: false.duration,
            rootKey: Util.newTempId()
        }
    }

    onDeleteBtn() {
        this.setState({deleteLoading: true})
        Api.removeCourseSchedule(this.props.schedule.id, response => {
            if (response.status === true) {
                this.props.onItemDeleted();
            } else {
                UIUtil.showError();
            }
            this.setState({deleteLoading: false})
        })
    }

    render() {
        const schedule = this.props.schedule;
        const deletable = this.props.deletable;

        const playRecording = schedule.recorded

        const thumbnailWidth = '15%'
        const thumbnailHeight = '125px'
        return (
            <div key={this.state.rootKey} style={{width: '100%', borderRadius: '15px', padding: '15px', boxShadow: "0px 0px 25px 0px rgba(0, 0, 0, 0.2)", display: 'flex'}}>
                <div style={{width: thumbnailWidth, height: thumbnailHeight, objectFit: 'cover', borderRadius: '15px'}}>
                    <div style={{height: '100%', width: '100%', position: 'relative',}} onClick={this.props.onClick}>
                        <img src={Api.getCourseScheduleThumbnail(schedule.id) + (this.props.thumbnailSuffix !== undefined ? ("&t=" + this.props.thumbnailSuffix) : "")} style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: '15px'}} onError={e => {
                            e.target.onerror = null;
                            e.target.src = NoImage;
                        }} />
                        {/*<div style={{width: '50px', height: '50px', borderRadius: '50%', background: '#00000050', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', pointerEvents: 'none'}}>
                        </div>*/}
                    </div>
                </div>

                {/*<img src={Api.getCourseItemThumbnail(courseItem.id)} style={{width: '20%', height: '175px', objectFit: 'cover', borderRadius: '15px'}} onError={e => {
                    e.target.onerror = null;
                    e.target.src = NoImage;
                }} />*/}
                <div style={{marginLeft: '25px', flex: 1, minWidth: 1}}>
                    <div style={{display: 'flex', alignItems: 'center', marginRight: '5px'}}>
                        <h3 style={{margin: 0}} onClick={this.props.onClick}>{schedule.title}</h3>
                        {Util.isNumberExist(schedule.date) && <Button type="link" style={{cursor: 'default'}}>{Util.getFullDate(schedule.date)}</Button>}
                        {Util.isNumberExist(schedule.duration) && <Button type="link" style={{cursor: 'default'}}>{Util.formatSeconds(schedule.duration * 60)}</Button>}
    
                        <div style={{flex: '1'}} />
                        

                        {(schedule.date) > new Date().getTime() ? (<>
                            <Button type="link" className="red-link" style={{cursor: 'default', display: 'flex', alignItems: 'center'}}>
                                <AlertOutlined /> Live stream in {schedule.date > new Date().getTime() ? <Countdown onFinish={() => this.setState({rootKey: Util.newTempId()})} style={{marginLeft: '15px'}} value={schedule.date} valueStyle={{fontSize: '15px', color: '#990000'}}/> : " progress"}
                            </Button>
                            {playRecording && <Button type={"text"} style={{cursor: 'default', display: 'flex', alignItems: 'center', }} >
                                Will be recorded
                            </Button>}
                        </>) : (schedule.endDate) > new Date().getTime()  ? (<>
                            <Link to={Util.getLiveStreamPath(schedule.liveStreamId)}>
                                <Button type="primary" className="red-button" style={{ display: 'flex', alignItems: 'center'}}>
                                    <AlertOutlined /> Join
                                </Button>
                            </Link>
                            {playRecording && <Button type={"text"} style={{cursor: 'default', display: 'flex', alignItems: 'center', }} >
                                Recording
                            </Button>}
                        </>) : (<>
                            <Button type="text"style={{cursor: 'default', display: 'flex', alignItems: 'center'}}>
                                Live stream is over
                            </Button>
                            {playRecording && 
                            <Button type={playRecording ? "primary" : "text"} style={{cursor: !playRecording && 'default', display: 'flex', alignItems: 'center', color: !playRecording && '#990000'}} onClick={playRecording ? () => this.props.playRecording() : undefined} className={playRecording && "red-button"}>
                                {playRecording ? 'Play Recording' : 'Recorded'}
                            </Button>}
                        </>)}


                        

                        {this.props.studentMode ? <>
                            {schedule.hidden &&
                            <Button type="text" size="large" style={{cursor: 'default'}}><EyeInvisibleOutlined /></Button>}
                        </> : <>

                            {schedule.hasAttendanceReport &&
                            <Tooltip title="Attendance Report" placement="top">
                               <Button type="link" size="large" onClick={e => {
                                    e.stopPropagation();
                                    this.props.onViewAttendanceReport();
                                }}><TeamOutlined /></Button>
                            </Tooltip>}
 
                            <Tooltip title="Edit" placement="topRight">
                                <Button type="link" size="large" onClick={e => {
                                    e.stopPropagation();
                                    this.props.onEditBtn()
                                }}><EditOutlined /></Button>
                            </Tooltip>
        
                            {deletable ? 
                                <Popconfirm title="Are you sure you want to remove this schedule?" onConfirm={this.onDeleteBtn.bind(this)} placement="topRight">
                                    <Button danger type="link" size="large" onClick={e => e.stopPropagation()} loading={this.state.deleteLoading}><DeleteOutlined /></Button>
                                </Popconfirm>
                            : null}
                        </>}
                    </div>
                    
                    {//<p style={{paddingTop: '5px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', flex: '1'}}>
                    }
                    <p style={{paddingTop: '5px', marginTop: '5px', overflowWrap: 'break-word', whiteSpace: 'pre-line'}}>{schedule.description}</p>
                </div>

                
            </div>
        )
    }
}

export default CourseScheduleItem;