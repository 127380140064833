import React from 'react'
import Page from '../base/Page';
import PostsView from '../views/posts/PostsView';

class AdminAnnouncementsPages extends Page {

    onPageStart() {

    }

    render() {
        return (
            <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                <div style={{width: '50vw'}}>
                    <PostsView adminPosts={true} canAdd={true} />
                </div>
            </div>
        );
    }

}

export default AdminAnnouncementsPages;