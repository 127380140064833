import React from 'react';
import ReactDOM from 'react-dom'
import Page from '../../base/Page';
import { Button, Rate, Row, Col, Card, Checkbox, Modal, Tooltip } from 'antd';

import LogoWhite from '../../images/LogoWhite.png';
import LiveIcon from '../../images/LiveIcon.png';

import { default as SlickSlider } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import { 
    LeftOutlined,
    RightOutlined,
    UserOutlined,
    StarOutlined,
    CreditCardOutlined,
    VideoCameraOutlined,
    PaperClipOutlined,
    AudioOutlined,
    OrderedListOutlined,
    ScheduleOutlined,
    FontColorsOutlined,
    FileOutlined,
    BookOutlined,
    AlertOutlined,
    MessageOutlined,
    ClusterOutlined,
    TeamOutlined,
    QuestionCircleOutlined,
    CheckCircleFilled,
    CheckOutlined,
    CloseOutlined,
    LockFilled,
    EyeFilled,
    EyeInvisibleFilled,
    UnlockFilled,
} from '@ant-design/icons';



import NoImage from '../../images/NoImage.png'
import ProductImagePlaceholder from '../../images/ProductImagePlaceholder.png';

import './course-sale-page.css';
import { Link, withRouter } from 'react-router-dom';
import ProfilePicture from '../../components/ProfilePicture';
import Meta from 'antd/lib/card/Meta';
import Api from '../../session/Api';
import Error404Page from '../Error404Page';

import HorizontalCoursesView, { HORIZONTAL_MODE_CATEGORY_COURSES, HORIZONTAL_MODE_OTHER_TUTOR_COURSES, HORIZONTAL_MODE_SIMILAR_COURSES } from '../../views/courses/HorizontalCoursesView';
import Util from '../../util/Util';
import { PROFILE_CONTENT_COURSES } from '../tutor-profile-page/TutorProfilePage';
import SessionManager from '../../session/SessionManager';

import { CATEGORIES } from '../../Categories';
import { ACCOUNT_ROLE_ORGANIZATION, ACCOUNT_ROLE_TUTOR, GALLERY_ITEM_TYPE_IMAGE, GALLERY_ITEM_TYPE_VIDEO, PRODUCT_TYPE_COURSE, RATING_ITEM_TYPE_COURSE } from '../../Constants';
import VideoThumbnail from '../../components/VideoThumbnail';
import UIUtil from '../../util/UIUtil';

import Lightbox from '../../components/media-list-viewer/react-image-lightbox';
import FrontendAuthModal from '../../components/FrontendAuthModal';
import PresenceIndicator from '../../components/PresenceIndicator';
import LiveSubscriptionDialog from '../../components/LiveSubscriptionDialog';
import UserList from '../../components/UserList';
import withMediaQuery from '../../components/WithMediaQuery';
import OneTimePayment from '../../components/payment-system/OneTimePayment';
import { FEATURED_ITEM_TYPE_COURSES } from '../content-management-pages/FeaturedSelectorPage';
import FeaturedManageControl from '../content-management-pages/FeaturedManageControl';

const Divider = () => {
    return <div style={{width: '75%', height: '1px', background: '#cecece', marginTop: '40px', marginBottom: '30px'}} />;
}

class CourseSalePage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            playingSlide: false,
            loading: true,
            course: {},
            courseDoesNotExist: false,
            
            currentGallerySlide: 0,

            subscribe: false,
            purchasing: false,

            mediaViewIndex: undefined,

            showSubscribeDialog: false,
            showPurchaseDialog: false,

            togglingFeaturedHidden: false,
            togglingNonEnrollmentAccess: false
        }

        this.slideSelectorRef = React.createRef();
    }

    getCourseId() {
        return this.props.match.params.courseId;
    }

    getTutorId() {
        return this.state.course.tutorId !== undefined ? this.state.course.tutorId : 0;
    }

    getGalleryItems() {
        return Util.safeGetProperty(this.state.course, "galleryItems", []);
    }

    onPageStart() {
        this.setState({loading: true})
        Api.getPublicCourse(this.getCourseId(), response => {
            if (response.status === true) {
                if (response.payload.owned) {
                    this.props.history.replace((response.payload.hasEditAccess ? '/my-course/' : '/course/') + response.payload.id)
                    return;
                }

                //response.payload.galleryItems = [{id: Util.newTempId(), type: GALLERY_ITEM_TYPE_IMAGE},{id: Util.newTempId(), type: GALLERY_ITEM_TYPE_IMAGE}];
                this.setState({
                    course: response.payload,
                    courseDoesNotExist: false,
                    loading: false
                })
            } else {
                this.setState({
                    course: {},
                    courseDoesNotExist: true,
                    loading: false
                })
            }
        })
    }

    /*purchaseCourse() {
        const courseId = this.getCourseId();

        this.setState({purchasing: true})
        Api.purchaseCourse(courseId, this.state.subscribe, response => {
            if (response.status === true) {
                this.state.course.owned = true;
                Modal.success({
                    title: "Success!",
                    //content: "Course purchased successfully!",
                    content: "Got course access!",
                    onOk: () => this.props.history.push("/course/" + courseId)
                })
            } else {
                UIUtil.showError();
            }
            this.setState({purchasing: false})
        })
    }*/

    toggleFeaturedHidden() {
        const shouldFeatureHidden = !this.state.course.featuredHidden;
        Modal.confirm({
            title: "Are you sure?",
            content: shouldFeatureHidden ? "Hiding this will prevent it from being displayed in system content curated lists" : "Making this visible means the system may display it in system content curated lists",
            onOk: () => {
                this.setState({togglingFeaturedHidden: true});
                Api.setContentCuratedHidden(FEATURED_ITEM_TYPE_COURSES, this.getCourseId(), shouldFeatureHidden, response => {
                    if (response.status === true) {
                        this.state.course.featuredHidden = shouldFeatureHidden;
                        this.setState({togglingFeaturedHidden: false});
                    } else {
                        UIUtil.showError();
                        this.setState({togglingFeaturedHidden: false});
                    }
                })
            },
            okButtonProps: {
                loading: this.state.togglingFeaturedHidden
            },
        })
    }

    toggleNonEnrollmentAccess() {
        const newValue = !this.state.course.allowNonEnrollmentAccess;
        Modal.confirm({
            title: "Are you sure?",
            content: newValue ? "This course will be able to be accessed and viewed by anyone, including users without logging in" : "This course will not be able to be accessed by users that are not logged in or have not acquired access to the course",
            onOk: () => {
                this.setState({togglingNonEnrollmentAccess: true});
                Api.setContentNonEnrollmentAccess(FEATURED_ITEM_TYPE_COURSES, this.getCourseId(), newValue, response => {
                    if (response.status === true) {
                        this.state.course.allowNonEnrollmentAccess = newValue;
                        this.setState({togglingNonEnrollmentAccess: false});
                    } else {
                        UIUtil.showError();
                        this.setState({togglingNonEnrollmentAccess: false});
                    }
                })
            },
            okButtonProps: {
                loading: this.state.togglingNonEnrollmentAccess
            },
        })
    }

    getFreeAccess() {
        const courseId = this.getCourseId();

        this.setState({purchasing: true})
        Api.getCourseAccess(courseId, response => {
            if (response.status === true) {
                this.state.course.owned = true;
                Modal.success({
                    title: "Success!",
                    content: "Got course access!",
                    onOk: () => this.props.history.push("/course/" + courseId)
                })
            } else {
                UIUtil.showError();
            }
            this.setState({purchasing: false})
        })
    }

    onPurchaseSuccess() {
        this.state.course.owned = true;
        this.setState({showPurchaseDialog: false})
        Modal.success({
            title: "Success!",
            content: "Course purchased successfully!",
            onOk: () => this.props.history.push("/course/" + this.getCourseId())
        })
    }

    renderCategory() {
        if (this.state.course.category !== undefined) {
            for (const category of CATEGORIES) {
                if (category.id === this.state.course.category) {
                    return (
                        <Link to={"/course-category/" + category.title.toLowerCase()}>
                            {category.icon} &nbsp; {category.title}
                        </Link>
                    )
                }
            }

            return null;
        } else {
            return null;
        }
    }

    getMediaImageSrc(index) {
        const galleryItems = this.getGalleryItems();
        if (index < galleryItems.length) {
            const galleryItem = galleryItems[index];
            return galleryItem.type === GALLERY_ITEM_TYPE_IMAGE ? Api.getGalleryItem(galleryItem.id) : null;
        } else {
            return Api.getCourseThumbnail(this.state.course.id);
        }
    }

    getMediaVideoComponent(index) {
        const galleryItems = this.getGalleryItems();
        if (index < galleryItems.length) {
            const galleryItem = galleryItems[index];
            return galleryItem.type === GALLERY_ITEM_TYPE_VIDEO ? 
                <video autoPlay controls src={Api.getGalleryItem(galleryItem.id)} style={{maxHeight: '95%', maxWidth: '95%', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)',}} />
            : null;
        } else {
            return null;
        }
    }

    render() {
        if (this.state.loading) {
            return <div />
        }

        if (this.state.courseDoesNotExist) {
            return (
                <Error404Page {...this.props} customMessage="Course does not exist" />
            )
        }

        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div style={{width: this.props.renderFullWidth ? '90vw' : '75vw', paddingTop: '25px', paddingBottom: '25px'}}>
                    <div style={{marginBottom: '15px', display: 'flex', flexDirection: 'column'}}>
                        <div style={{display: 'flex', alignItems: 'center', }}>
                            <h1 style={{height: '35px'}}>{this.state.course.title}</h1>
                            {this.props.isSmall && <div style={{flex: '1'}} />}
                            <Button type="link" style={{marginLeft: '15px'}}>{this.renderCategory()}</Button>
                        </div>

                        {Util.isStringExists(this.state.course.hashtag) &&
                        <Link style={{marginTop: -10, fontSize: 14}} target="_blank" to={`/hashtag/${this.state.course.hashtag}`}>#{this.state.course.hashtag}</Link>}
                    </div>

                    <div style={{width: '100%', display: 'flex', ...(this.props.isSmall && {flexDirection:  'column'})}}>
                        <div style={{flex: '1', minWidth: '0'}}>
                            <div>
                                <CarouselProvider naturalSlideWidth={100} naturalSlideHeight={125} totalSlides={this.getGalleryItems().length + 1} currentSlide={this.state.currentGallerySlide} dragEnabled={false}>
                                    <Slider style={{height: '450px',}}>
                                        {this.getGalleryItems().map((galleryItem, index) => (
                                            <Slide index={index} onClick={e => this.setState({mediaViewIndex: index})}>
                                                {galleryItem.type === GALLERY_ITEM_TYPE_IMAGE && 
                                                <img src={Api.getGalleryItem(galleryItem.id)} style={{width: '100%', height: '450px', objectPosition: 'center', objectFit: 'contain', cursor: 'pointer'}}
                                                onError={e => {
                                                    e.target.onerror = null;
                                                    e.target.src = NoImage;
                                                }}/>}
                                                {galleryItem.type === GALLERY_ITEM_TYPE_VIDEO && 
                                                <VideoThumbnail height="250px" src={Api.getGalleryItem(galleryItem.id)} style={{width: '100%', height: '450px', objectPosition: 'center', objectFit: 'contain'}} />}
                                            </Slide>       
                                        ))}
                                        <Slide index={this.getGalleryItems().length} onClick={e => this.setState({mediaViewIndex: this.getGalleryItems().length})}>
                                            <img src={Api.getCourseThumbnail(this.state.course.id)} style={{width: '100%', height: '450px', objectPosition: 'center', objectFit: 'contain', cursor: 'pointer'}} onError={e => {
                                                e.target.onerror = null;
                                                e.target.src = ProductImagePlaceholder;
                                            }} />
                                        </Slide>   
                                    </Slider>

                                    {this.getGalleryItems().length > 0 && 
                                    <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                            <Button type="text" onClick={() => {
                                                this.slideSelectorRef.current.slickPrev();
                                                //if (this.state.playingSlide) {
                                                    //return;
                                                //}

                                                //this.backBtnRef.current.click();
                                                //this.slideSelectorRef.current.slickPrev();
                                                /*this.setState({
                                                    playingSlide: true,
                                                })*/
                                                //setTimeout(() => this.setState({playingSlide: false}), 600);
                                            }}><LeftOutlined /></Button>
                                            <SlickSlider focusOnSelect={true} ref={this.slideSelectorRef} style={{flex: '1', minWidth: '1px', width: '100%', marginTop: 25}}
                                            //variableWidth={true} adaptiveHeight={true}
                                            className={"center" + ((this.getGalleryItems().length) == 2 ? " two-slide" : "")} 
                                            centerMode={true} 
                                            infinite={true} beforeChange={(prevIndex, nextIndex) => setTimeout(() => this.setState({currentGallerySlide: nextIndex}), 10)}
                                            centerPadding="60px" arrows={false} slidesToShow={this.props.isSmall ? 1 : ((this.getGalleryItems().length + 1) > 3 ? 3 : this.getGalleryItems().length)} speed={500} draggable={false}>
                                                {this.getGalleryItems().map((galleryItem, index) => (
                                                    <div className="slide-selector">
                                                        {galleryItem.type === GALLERY_ITEM_TYPE_IMAGE && 
                                                        <img src={Api.getGalleryItem(galleryItem.id)} className="slide-selector-image" onError={e => {
                                                            e.target.onerror = null;
                                                            e.target.src = NoImage;
                                                        }}/>}

                                                        {galleryItem.type === GALLERY_ITEM_TYPE_VIDEO && 
                                                        <VideoThumbnail notClickable playSize="20px" src={Api.getGalleryItem(galleryItem.id)} className="slide-selector-image" />}
                                                    </div>
                                                ))}
                                                <div className="slide-selector">
                                                    <img src={Api.getCourseThumbnail(this.state.course.id)} className="slide-selector-image" onError={e => {
                                                        e.target.onerror = null;
                                                        e.target.src = ProductImagePlaceholder;
                                                    }} />
                                                </div>

                                                {/*<div />
                                                <div />
                                                {this.getGalleryItems().length === 2 && <div />*/}
                                            </SlickSlider>
                                            <Button type="text" onClick={() => {
                                                this.slideSelectorRef.current.slickNext();
                                                /*if (this.state.playingSlide) {
                                                    return;
                                                }

                                                this.nextBtnRef.current.click()
                                                this.slideSelectorRef.current.slickNext();
                                                this.setState({
                                                    playingSlide: true,
                                                })
                                                setTimeout(() => this.setState({playingSlide: false}), 600);*/
                                            }}><RightOutlined /></Button>
                                        </div>
                                    </div>}

                                    
                                    

                                    {/*<ButtonBack style={{display: 'none'}}>
                                        <span ref={this.backBtnRef} />
                                    </ButtonBack>
                                    <ButtonNext style={{display: 'none'}}>
                                        <span ref={this.nextBtnRef} />
                                    </ButtonNext>*/}
                                </CarouselProvider>
                            </div>
                            <br />
                            <p style={{overflowWrap: 'break-word', whiteSpace: 'pre-line'}}>{this.state.course.description}</p>    

                            {this.state.course.tutorRole == ACCOUNT_ROLE_ORGANIZATION && <>
                                <div style={{height: '50px'}} />
                                <h2 style={{flex: '1', height: '20px'}}><TeamOutlined /> Tutors</h2>
                                <div style={{height: '15px'}} />
                                <UserList showCourseEditorTutors={this.getCourseId()} />
                            </>}

                            {/*<div style={{display: 'flex', alignItems: 'center', marginTop: '25px'}}>
                                <h2>More Courses by Tutor</h2>
                                <div style={{flex: '1'}} />
                                <Button type="link" className="orange-link">View All</Button>
                            </div>

                            <HorizontalCoursesView style={{marginTop: '25px'}} category={0} mode={HORIZONTAL_MODE_CATEGORY_COURSES} count={2} keepNextBtnIn />

                            <div style={{display: 'flex', alignItems: 'center', marginTop: '25px'}}>
                                <h2>Similar Courses</h2>
                            </div>

                            <HorizontalCoursesView style={{marginTop: '25px'}} category={0} mode={HORIZONTAL_MODE_CATEGORY_COURSES} count={2} keepNextBtnIn />*/}

                        </div>



                        <div style={{width: '40px'}} />
                        <div style={{width: this.props.renderFullWidth ? '90vw' : '225px', ...(this.props.isSmall && {alignSelf: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center'})}}>
                            {(SessionManager.isLoggedInAsContentManager()) ? <>
                                <FeaturedManageControl item={this.state.course} featuredContentType={FEATURED_ITEM_TYPE_COURSES} />

                                <div style={{marginTop: 25}}>
                                    {this.state.course.featuredHidden ? (
                                        <div>
                                            <h3 style={{color: 'red', margin: 0}}>Hidden <EyeInvisibleFilled /></h3>
                                            <p style={{margin: 0, marginTop: 5, fontSize: 12}}>Course will not appear on system curated lists</p>

                                            <Button loading={this.state.togglingFeaturedHidden} icon={<CheckOutlined />} type="primary" style={{width: '100%', marginTop: 15}} onClick={this.toggleFeaturedHidden.bind(this)}>Set Visible</Button>
                                        </div>
                                    ) : (
                                        <div>
                                            <h3 style={{color: 'green', margin: 0}}>Visible <EyeFilled /></h3>
                                            <p style={{margin: 0, marginTop: 5, fontSize: 12}}>Course can appear on system content curated lists</p>
                                            
                                            <Button loading={this.state.togglingFeaturedHidden} icon={<CloseOutlined />} type="primary" danger style={{width: '100%', marginTop: 15}} onClick={this.toggleFeaturedHidden.bind(this)}>Set Hidden</Button>
                                        </div>
                                    )}
                                </div>

                                <div style={{marginTop: 25}}>
                                    {this.state.course.allowNonEnrollmentAccess ? (
                                        <div>
                                            <h3 style={{color: 'green', margin: 0}}>Allowing non-enrollment access <UnlockFilled /></h3>
                                            <p style={{margin: 0, marginTop: 5, fontSize: 12}}>This course can be accessed and viewed by anyone, including users without logging in</p>

                                            <Button loading={this.state.togglingNonEnrollmentAccess} icon={<CheckOutlined />} type="primary" style={{width: '100%', marginTop: 15}} onClick={this.toggleNonEnrollmentAccess.bind(this)}>Add Requirement</Button>
                                        </div>
                                    ) : (
                                        <div>
                                            <h3 style={{color: 'black', opacity: 0.65, margin: 0}}>Enrollment required <LockFilled /></h3>
                                            <p style={{margin: 0, marginTop: 5, fontSize: 12}}>This course cannot be accessed by users that are not logged in or have not acquired access to the course</p>
                                            
                                            <Button loading={this.state.togglingNonEnrollmentAccess} icon={<CloseOutlined />} type="primary" danger style={{width: '100%', marginTop: 15}} onClick={this.toggleNonEnrollmentAccess.bind(this)}>Remove Requirement</Button>
                                        </div>
                                    )}
                                </div>
                            </>: (<>
                                {!this.state.course.owned && !this.state.course.hasEditAccess ? (<>
                                    <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
                                        <h1>Access</h1>
                                        <div style={{flex: '1'}} />
                                        {Util.isNumberExist(this.state.course.price) &&
                                        <h4>{(this.state.course.price + " USD")}</h4>}
                                    </div>
                                    
                                    {this.state.course.allowSubscriptions && <> 
                                        <h5 style={{marginTop: -20, color: 'red'}}>
                                            Offers Live
                                            <Tooltip title={"Aid your studies with extra help by using Help Sessions and Live Streams" + 
                                            (!this.state.course.userHasLiveSubscription ? " (subscription required)" : "")}>
                                                <Button type="link" className="red-link" style={{marginLeft: -10}}>
                                                    <QuestionCircleOutlined />
                                                </Button>
                                            </Tooltip>
                                        </h5>
                                    </>}

                                    {SessionManager.isLoggedInAsStudentOrTutor() && 
                                    !this.state.course.userHasLiveSubscription && this.state.course.allowSubscriptions
                                    &&
                                    <div style={{width: '100%', height: '70px', marginTop: this.state.course.allowSubscriptions ? '25px' : 0, backgroundColor: '#2d3436', backgroundImage: 'linear-gradient(315deg, #2d3436 0%, #000000 74%)',
                                    borderRadius: '5px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', marginBottom: '10px'}} onClick={() => this.setState({showSubscribeDialog: true})}>
                                        <img src={LogoWhite} style={{width: '100px'}} />
                                        <img src={LiveIcon} style={{width: '25px'}} />
                                    </div>}

                                    {(SessionManager.isLoggedInAsStudentOrTutor() || !SessionManager.isLoggedIn()) &&
                                        ((SessionManager.isLoggedIn() && (!(Util.isNumberExist(this.state.course.price)) && !this.state.course.userHasLiveSubscription && !this.state.course.allowNonSubscriptionAccess))) ? (
                                            // <p style={{width: '100%', pointerEvents: 'none', fontSize: 12, }} size="small">
                                            //     Subscribe now to get free access!
                                            // </p>
                                            <Button onClick={() => {
                                                if (SessionManager.isLoggedIn()) {
                                                    this.setState({showSubscribeDialog: true})
                                                } else {
                                                    this.props.history.push("/auth?dP=" + encodeURIComponent("/course-sale/" + this.getCourseId()))
                                                }
                                            }} type="primary" style={{width: '100%'}} size="large"><BookOutlined /> Get Free Access</Button>

                                        ) : (
                                            <Button loading={this.state.purchasing} onClick={() => {
                                                if (SessionManager.isLoggedIn()) {
                                                    if ((Util.isNumberExist(this.state.course.price))) {
                                                        this.setState({showPurchaseDialog: true})
                                                    } else {
                                                        this.getFreeAccess();
                                                    }
                                                } else {
                                                    this.props.history.push("/auth?dP=" + encodeURIComponent("/course-sale/" + this.getCourseId()))
                                                }
                                            }} type="primary" style={{width: '100%'}} size="large">{(Util.isNumberExist(this.state.course.price)) ? <><CreditCardOutlined /> Purchase</> : <><BookOutlined /> Get Free Access</>}</Button>
                                        )
                                    }
                                </>) : (
                                    <Button type="primary" style={{width: '100%'}} size="large">
                                        <Link to={(this.state.course.hasEditAccess ? '/my-course/' : '/course/') + this.state.course.id}>
                                            <BookOutlined /> Open Course
                                        </Link>
                                    </Button>
                                )}
                            </>)}
                            
                            <Divider />

                            <h2 style={{marginTop: '10px', width: '100%'}}>Content</h2>
                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                                {Util.isNumberExist(this.state.course.lectureCount) && 
                                <Button type="link" style={{cursor: 'default'}}><VideoCameraOutlined /> {this.state.course.lectureCount} Lectures ({Util.formatSeconds(this.state.course.lectureRuntime)})</Button>}
                                {Util.isNumberExist(this.state.course.fileCount) && 
                                <Button type="link" style={{cursor: 'default'}}><FileOutlined /> {this.state.course.fileCount} Files ({Util.formatBytes(this.state.course.fileSize)})</Button>}
                                {Util.isNumberExist(this.state.course.quizCount) && 
                                <Button type="link" style={{cursor: 'default'}}><OrderedListOutlined /> {this.state.course.quizCount} Quizzes ({this.state.course.quizQuestions + " Q"})</Button>}
                                {Util.isNumberExist(this.state.course.liveSessionCount) &&
                                <Button type="link" style={{cursor: 'default'}}><AlertOutlined /> {this.state.course.liveSessionCount} Live Sessions ({this.state.course.liveSessionStreams})</Button>}
                                
                                {/*
                                {Util.isNumberExist(this.state.course.lectureCount) && 
                                <Button type="link" style={{cursor: 'default'}}><ScheduleOutlined /> 5 Flash cards</Button>}
                                {Util.isNumberExist(this.state.course.lectureCount) && 
                                <Button type="link" style={{cursor: 'default'}}><FontColorsOutlined /> 5 Spell Checks</Button>}
                                */}
                            </div>
                        
                            <Divider />

                            <h2 style={{marginTop: '10px', width: '100%'}}>Rating</h2>
                            <Rate disabled value={this.state.course.courseRating} />
                            <Button type="primary" className="orange-button" style={{width: '100%', marginTop: '25px',}}>
                                <Link to={"/reviews/" + RATING_ITEM_TYPE_COURSE + "/" + this.state.course.id + "?name=" + encodeURIComponent(this.state.course.title)}><StarOutlined /> View Reviews</Link>
                            </Button>

                            <Divider />

                            <ProfilePicture size={225} shape="square" userId={this.getTutorId()} style={{borderRadius: '15px'}} />
                            
                            <h2 style={{marginTop: '10px'}}>{this.state.course.tutorName}</h2>
                            {this.state.course.tutorRole == ACCOUNT_ROLE_TUTOR && <PresenceIndicator textMode userId={this.getTutorId()} style={{marginBottom: '15px', marginTop: '-10px'}} />}
                            <Rate disabled value={this.state.course.tutorRating} />

                            {this.state.course.tutorId != SessionManager.getAccount().id && (
                                <Button style={{width: '100%', marginTop: '25px'}} onClick={() => {
                                    if (SessionManager.isLoggedIn()) {
                                        this.props.history.push('/messages?userId=' + this.getTutorId());
                                    } else {
                                        this.setState({showSendMessageAuthModal: true})   
                                    }
                                }}>
                                    <MessageOutlined /> Send Message
                                </Button>
                            )}
                            
                            <Button type="primary" style={{width: '100%', marginTop: this.state.course.tutorId != SessionManager.getAccount().id ? '5px' : '25px'}}>
                                <Link to={Util.getProfilePath(this.getTutorId())}>
                                    {this.state.course.tutorRole == ACCOUNT_ROLE_ORGANIZATION ? (
                                        <><ClusterOutlined /> View Organization</>
                                    ) : (
                                        <><UserOutlined /> View Tutor</>
                                    )}
                                </Link>
                            </Button>

                        </div>
                    </div>

                    <div style={{display: 'flex', alignItems: 'center', marginTop: '45px'}}>
                        <h2>{'More Courses by ' + (this.state.course.tutorRole == ACCOUNT_ROLE_ORGANIZATION ? 'Organization' : 'Tutor')}</h2>
                        <div style={{flex: '1'}} />
                        {//!(SessionManager.isLoggedInAsTutor() && this.state.course.owned) ? 
                        SessionManager.getAccount().id != this.state.course.tutorId ?
                            <Button type="link" className="orange-link">
                                <Link to={Util.getProfilePath(this.getTutorId(), PROFILE_CONTENT_COURSES)}>View All</Link>
                            </Button> 
                        : null}
                    </div>

                    <HorizontalCoursesView style={{marginTop: '25px'}} courseId={this.getCourseId()} mode={HORIZONTAL_MODE_OTHER_TUTOR_COURSES} count={4} />

                    {/*<div style={{display: 'flex', alignItems: 'center', marginTop: '25px'}}>
                        <h2>Similar Courses</h2>
                    </div>

                        <HorizontalCoursesView style={{marginTop: '25px'}} courseId={this.getCourseId()} mode={HORIZONTAL_MODE_SIMILAR_COURSES} count={4} />*/}

                </div>

                {this.state.mediaViewIndex !== undefined && 
                    <Lightbox
                    mainSrc={this.getMediaImageSrc(this.state.mediaViewIndex)}
                    nextSrc={this.getMediaImageSrc((this.state.mediaViewIndex + 1) % (this.getGalleryItems().length + 1))}
                    prevSrc={this.getMediaImageSrc((this.state.mediaViewIndex + (this.getGalleryItems().length + 1) - 1) % (this.getGalleryItems().length + 1))}
                    mainCustomContent={this.getMediaVideoComponent(this.state.mediaViewIndex)}
                    nextCustomContent={this.getMediaVideoComponent((this.state.mediaViewIndex + 1) % (this.getGalleryItems().length + 1))}
                    prevCustomContent={this.getMediaVideoComponent((this.state.mediaViewIndex + (this.getGalleryItems().length + 1) - 1) % (this.getGalleryItems().length + 1))}
                    onCloseRequest={() => this.setState({ mediaViewIndex: undefined })}
                    onMovePrevRequest={() =>
                        this.setState({
                            mediaViewIndex: (this.state.mediaViewIndex + (this.getGalleryItems().length + 1) - 1) % (this.getGalleryItems().length + 1),
                        })
                    }
                    onMoveNextRequest={() =>
                        this.setState({
                            mediaViewIndex: (this.state.mediaViewIndex + 1) % (this.getGalleryItems().length + 1),
                        })
                    }
                />}

                <FrontendAuthModal visible={this.state.showSendMessageAuthModal} onCancel={() => this.setState({showSendMessageAuthModal: false})} onDonePath={'/messages?userId=' + this.getTutorId()} />

                <LiveSubscriptionDialog visible={this.state.showSubscribeDialog} onCancel={() => this.setState({showSubscribeDialog: false})} />

                <Modal footer={null} title="Payment Methods" visible={this.state.showPurchaseDialog} onCancel={() => this.setState({showPurchaseDialog: false})}>
                    <OneTimePayment productType={PRODUCT_TYPE_COURSE} productId={this.getCourseId()} onPurchaseSuccess={this.onPurchaseSuccess.bind(this)} /> 
                </Modal>
            </div>
        );
    }

}

export default withMediaQuery(withRouter(CourseSalePage));