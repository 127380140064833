import React from 'react'

import { Avatar, Badge } from 'antd';

import './room-item.less';
import Util from '../../util/Util';
import ProfilePicture from '../../components/ProfilePicture';
import { ACCOUNT_ROLE_TUTOR } from '../../Constants';

function escapeRegex(string) {
    return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
}

const Highlighted = ({text = '', highlight = ''}) => {
    if (!highlight.trim()) {
      return <span>{text}</span>
    }
    const regex = new RegExp(`(${escapeRegex(highlight)})`, 'gi')
    const parts = text.split(regex)
    return (
        <span>
            {parts.filter(part => part).map((part, i) => (
                regex.test(part) ? <mark key={i}>{part}</mark> : <span key={i}>{part}</span>
            ))}
        </span>
    )
}

class RoomItem extends React.Component {

    constructor(props) {
        super(props);
    }
    
    render() {
        return (
            <div className={this.props.selected ? 'room-item-selected' : 'room-item'} style={{width: '100%', height: '125px', display: 'flex', alignItems: 'center', padding: '25px'}}>
                <ProfilePicture realtime showStatus={this.props.room.otherMember.role === ACCOUNT_ROLE_TUTOR} userId={this.props.room.otherMember.id} size={75} style={{border: 'solid', borderWidth: '2px'}} />
                <div style={{flex: '1', paddingLeft: '25px', paddingTop: '15px'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <h2 style={{color: 'white'}}>
                            <Highlighted text={this.props.room.otherMember.fullName} highlight={this.props.searchedValue} />
                        </h2>
                        <p style={{color: '#cecece', flex: '1', textAlign: 'end', fontSize: '10px'}}>{Util.getDate(this.props.room.latestMessageDate)}</p>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <p style={{color: '#cecece'}}>{this.props.room.latestMessageText}</p>
                        <div style={{flex: '1', display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                            {this.props.room.unreadMessages > 0 ? <Badge count={this.props.room.unreadMessages} title="Unread Messages" /> : null}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default RoomItem;