import React, { useState } from 'react';

import { motion } from "framer-motion"

import { Input, Button, Avatar, Tag, Rate, Popover, Menu, Divider, Modal } from 'antd';

import Logo from '../../images/Logo.png'
import NoImage from '../../images/NoImage.png'
import ProductImagePlaceholder from '../../images/ProductImagePlaceholder.png';

import { 
    CommentOutlined,
    LikeOutlined,
    UserOutlined,
    CheckOutlined,
    MoreOutlined,
    EyeInvisibleOutlined,
    EyeOutlined,
    DeleteOutlined,
    EditOutlined,
    LeftOutlined,
    RightOutlined,
    LinkOutlined,
    EllipsisOutlined
} from '@ant-design/icons';


import Util from '../../util/Util';
import { POST_TYPE_IMAGE, POST_TYPE_VIDEO, POST_USER_ID_ADMIN, POST_PARENT_TYPE_RATING, ACCOUNT_ROLE_TUTOR, POST_PARENT_TYPE_COURSE, POST_PARENT_TYPE_LIVE_SESSION, POST_TYPE_GALLERY, GALLERY_ITEM_TYPE_IMAGE, GALLERY_ITEM_TYPE_VIDEO, ITEM_LIKE_PARENT_TYPE_POST } from '../../Constants';
import Api from '../../session/Api';
import CommentsView from '../comments/CommentsView';
import { Link } from 'react-router-dom';

import VideoThumbnail from '../../components/VideoThumbnail'
import UIUtil from '../../util/UIUtil';
import SessionManager from '../../session/SessionManager';
import ProfilePicture from '../../components/ProfilePicture';
import MenuItem from 'antd/lib/menu/MenuItem';

import Dots from 'react-carousel-dots';

import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import { default as SlickSlider } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import UserList from '../../components/UserList';

import {CopyToClipboard} from 'react-copy-to-clipboard';

import ReactHashtag from 'react-hashtag';

const { TextArea } = Input;

class PostItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showComments: false,
            closeComments: false,
            liking: false,
            postCommentCount: props.post.commentCount,
            settingHidden: false,
            deleting: false,
            showingDeleteConfirm: false,

            showEditPostDialog: false,
            editingPostContent: false,
            editPostContentValue: "",

            currentGallerySlide: 0,

            optionPopoverVisible: false
        }

        this.slideSelectorRef = React.createRef();
    }

    shouldComponentUpdate(nextProps, nextState) {
        return  this.state.showComments !== nextState.showComments || 
                this.state.closeComments !== nextState.closeComments || 
                this.state.liking !== nextState.liking || 
                this.state.settingHidden !== nextState.settingHidden ||
                this.state.deleting !== nextState.deleting ||
                this.state.showEditPostDialog !== nextState.showEditPostDialog ||
                this.state.editingPostContent !== nextState.editingPostContent ||
                this.state.editPostContentValue !== nextState.editPostContentValue ||
                this.state.postCommentCount !== nextState.postCommentCount ||
                this.props.post !== nextProps.post || 
                this.state.currentGallerySlide !== nextState.currentGallerySlide ||
                this.state.optionPopoverVisible !== nextState.optionPopoverVisible;
    }

    onCommentToggle() {
        this.setState({
            showComments: !this.state.showComments,
            closeComments: this.state.showComments
        })
    }

    onLikeBtn() {
        if (SessionManager.isLoggedIn()) {
            this.likePost();
        }
    }

    togglePostHidden() {
        this.setState({settingHidden: true});
        Api.setPostHidden(this.props.post.id, !this.props.post.hidden, response => {
            if (response.status === true) {
                this.props.post.hidden = !this.props.post.hidden;
            } else {
                UIUtil.showError();
            }
            this.setState({settingHidden: false})
        })
    }

    editPostContent() {
        this.setState({editingPostContent: true})
        Api.editPostContent(this.props.post.id, this.state.editPostContentValue, response => {
            if (response.status === true) {
                UIUtil.showSuccess();
                this.props.post.postContent = this.state.editPostContentValue;
                this.setState({editingPostContent: false, showEditPostDialog: false})
            } else {
                UIUtil.showError();
                this.setState({editingPostContent: false})
            }
        })
    }

    deletePost() {
        if (this.state.showingDeleteConfirm) {
            return;
        }

        this.setState({showingDeleteConfirm: true});
        Modal.confirm({
            title: "Confirm",
            content: "Are you sure you want to delete this? This action is irreversible.",
            onOk: () => {
                this.setState({deleting: true, showingDeleteConfirm: false});
                Api.deletePost(this.props.post.id, response => {
                    if (response.status === true) {
                        if (this.props.onItemDeleted !== undefined) {
                            this.props.onItemDeleted();
                        }
                    } else {
                        UIUtil.showError();
                    }
                    this.setState({deleting: false})
                })
            },
            onCancel: () => {
                this.setState({showingDeleteConfirm: false})
            }
        })
    }

    likePost() {
        this.setState({liking: true})

        Api.toggleLikePost(this.props.post.id, response => {
            if (response.status === true) {
                let post = this.props.post;
                if (post.liked && !response.payload) {
                    post.likeCount = post.likeCount - 1;
                } else if (!post.liked && response.payload) {
                    post.likeCount = post.likeCount + 1;
                }
                post.liked = response.payload;

                this.setState({
                    liking: false,
                    post: post
                })
            } else {
                //alert('error');
                this.setState({liking: false})
                UIUtil.showError();
            }
        })
    }

    onCommentAdded() {
        let post = this.props.post;
        post.commentCount = post.commentCount + 1;

        this.setState({
            postCommentCount: post.commentCount
        })
    }

    onCommentRemoved() {
        let post = this.props.post;
        post.commentCount = post.commentCount - 1;

        this.setState({
            postCommentCount: post.commentCount
        })
    }

    isAdminAnnouncement() {
        //return this.props.post.accountId == POST_USER_ID_ADMIN;    
        return this.props.post.parentId == POST_USER_ID_ADMIN;    
    }

    getGalleryItems() {
        //return this.props.post.galleryItems.concat(this.props.post.galleryItems);
        return this.props.post.galleryItems;
    }

    render() {
        const options = (<>
            {this.props.post.hidden &&
            <Tag style={{marginLeft: 25}}>
                <EyeInvisibleOutlined /> Hidden
            </Tag>}
            <div style={{flex: 1}} />
            <Popover 
            visible={this.state.optionPopoverVisible} onVisibleChange={visible => this.setState({optionPopoverVisible: visible})} 
            content={
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', width: '175px'}}>
                    <CopyToClipboard text={window.location.origin.toString() + "/post/" + this.props.post.id} onCopy={() => this.setState({optionPopoverVisible: false}, () => UIUtil.showSuccess("Copied to clipboard!"))}>
                        <Button style={{marginBottom: 5}} type="link">
                            <LinkOutlined style={{marginRight: 7}} /> Copy Link
                        </Button>
                    </CopyToClipboard>
                    {this.props.post.accountId == SessionManager.getAccount().id && <>
                        <Button style={{marginBottom: 5}} type="link" disabled={this.state.editingPostContent} onClick={() => this.setState({showEditPostDialog: true, editPostContentValue: this.props.post.postContent})}>
                            <EditOutlined style={{marginRight: 7}} /> Edit
                        </Button>
                        {!this.isAdminAnnouncement() && <Button type="link" loading={this.state.settingHidden} onClick={this.togglePostHidden.bind(this)}>
                            {!this.props.post.hidden ? <>
                                <EyeInvisibleOutlined style={{marginRight: 10}} /> Hide
                            </> : <>
                                <EyeOutlined style={{marginRight: 10}} /> Unhide
                            </>}
                        </Button>}
                        {this.props.post.parentType != POST_PARENT_TYPE_COURSE && this.props.post.parentType != POST_PARENT_TYPE_LIVE_SESSION && <>
                        <Divider style={{width: '100%', margin: 0, marginTop: 5, marginBottom: 5}} />
                        <Button type="link" danger loading={this.state.deleting} onClick={this.deletePost.bind(this)}>
                            <DeleteOutlined style={{marginRight: 8}} /> Delete
                        </Button>
                        </>}
                    </>}
                </div>
            } trigger="click">
                <Button type="link"><EllipsisOutlined /></Button>
            </Popover>
        </>)

        return (
            <div style={{width: '100%', borderRadius: '15px', padding: '15px', boxShadow: "0px 0px 25px 0px rgba(0, 0, 0, 0.2)"}}>
                <div style={{padding: '15px'}}>
                    {this.isAdminAnnouncement() ? (
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Link to="/all-announcements">
                                <div style={{display: 'flex'}}>
                                    <img src={Logo} style={{width: '100px', alignSelf: 'center'}} />
                                    <Tag color="volcano" style={{marginLeft: '25px', alignSelf: 'center', cursor: 'pointer'}}>{'Announcement - ' + Util.getDate(this.props.post.date)}</Tag>
                                    <h6 style={{color: '#8a8a8a', marginLeft: '15px', alignSelf: 'center'}}>{}</h6>
                                </div>
                            </Link>
                            <div style={{flex: 1}} />
                            {/* {this.props.post.accountId == SessionManager.getAccount().id && options} */}
                        </div>
                    ) : (
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            {this.props.hideProfilePic ? null : 
                                /*<Link to={Util.getProfilePath(this.props.post.accountId)}>
                                    <Avatar icon={<UserOutlined />} src={Api.getProfilePic(this.props.post.accountId)} style={{height: '40px', width: '40px'}} />
                                </Link>*/
                                <ProfilePicture realtime={this.props.realtimeUserStatus} showStatus={this.props.post.accountRole == ACCOUNT_ROLE_TUTOR} clickable userId={this.props.post.accountId} style={{height: '40px', width: '40px'}} />
                            }
                            <div style={{paddingLeft: '10px'}}>
                                <Link to={Util.getProfilePath(this.props.post.accountId)}><h5>{this.props.post.accountName}</h5></Link>
                                <h6 style={{color: '#8a8a8a'}}>{Util.getDate(this.props.post.date) + (this.props.post.updated ? " (edited)" : "")}</h6>
                            </div>

                            {/* {this.props.post.accountId == SessionManager.getAccount().id ? (<> */}
                                {/* {options} */}
                            {/* </>) : (<> */}
                                {((this.props.post.parentType == POST_PARENT_TYPE_COURSE || this.props.post.parentType == POST_PARENT_TYPE_LIVE_SESSION) && Util.isStringExists(this.props.post.productName)) && <>
                                    <div style={{flex: 1}} />
                                    <Link style={{display: 'flex'}} to={this.props.post.parentType == POST_PARENT_TYPE_COURSE ? 
                                        ('/course/' + this.props.post.parentId + '?tab=announcements') : 
                                        ('/live-session/' + this.props.post.parentId + '?tab=announcements')}>

                                        
                                        <div 
                                        //style={{minWidth: '105px'}}
                                        style={{marginRight: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: "flex-end"}}
                                        >
                                            <h5>{this.props.post.productName}</h5>
                                            <h6 style={{color: '#f26622'}}>Announcement</h6>
                                        </div>
                                        <img src={this.props.post.parentType == POST_PARENT_TYPE_COURSE ? Api.getCourseThumbnail(this.props.post.parentId) : Api.getLiveSessionThumbnail(this.props.post.parentId)} 
                                        style={{width: '40px', height: '40px', objectFit: 'cover', borderRadius: '15px'}} onError={e => {
                                            e.target.onerror = null;
                                            e.target.src = ProductImagePlaceholder;
                                        }} />
                                    </Link>
                                </>}
                            {/* </>)} */}
                        </div>
                    )}
                </div>

                {this.props.post.parentType === POST_PARENT_TYPE_RATING ? 
                    <Rate disabled defaultValue={this.props.post.ratingValue} style={{marginBottom: '10px', marginLeft: '15px'}} />
                : null}
    
                <p style={{padding: '5px 15px 5px 15px', overflowWrap: 'break-word', whiteSpace: 'pre-line'}}>
                    <ReactHashtag renderHashtag={(value) => <Link target="_blank" to={`/hashtag/${value.replaceAll(/[^\w]/g, '')}`}>{value}</Link>}>
                        {this.props.post.postContent}
                    </ReactHashtag>    
                </p>
    
                {this.props.post.postType === POST_TYPE_IMAGE ? 
                    <img onClick={this.props.onMediaClick} src={Api.getPostImage(this.props.post.id)} style={{width: '100%', maxHeight: '450px', objectFit: 'cover', marginTop: '10px', marginBottom: '10px', borderRadius: '15px', cursor: 'pointer'}} /> 
                : null}

                {this.props.post.postType === POST_TYPE_VIDEO ? 
                    <VideoThumbnail onClick={this.props.onMediaClick} src={Api.getPostVideo(this.props.post.id)} style={{width: '100%', maxHeight: '450px', objectFit: 'cover', marginTop: '10px', marginBottom: '10px', borderRadius: '15px'}} /> 
                : null}

                {this.props.post.postType === POST_TYPE_GALLERY ? 
                    <CarouselProvider naturalSlideWidth={100} naturalSlideHeight={125} totalSlides={this.getGalleryItems().length} currentSlide={this.state.currentGallerySlide} dragEnabled={false}>
                        <Slider style={{height: '450px', marginTop: '10px', marginBottom: '10px',}}>
                            {this.getGalleryItems().map((galleryItem, index) => (
                                <Slide index={index} onClick={e => this.setState({mediaViewIndex: index})}>
                                    {galleryItem.type === GALLERY_ITEM_TYPE_IMAGE && 
                                    <img onClick={() => {
                                        if (this.props.onMediaClick !== undefined) {
                                            this.props.onMediaClick(galleryItem)
                                        }
                                    }} src={Api.getGalleryItem(galleryItem.id)} style={{width: '100%', height: '450px', objectFit: 'cover', cursor: 'pointer', borderRadius: '15px'}}
                                    onError={e => {
                                        e.target.onerror = null;
                                        e.target.src = NoImage;
                                    }}/>}
                                    
                                    {galleryItem.type === GALLERY_ITEM_TYPE_VIDEO && 
                                    <VideoThumbnail onClick={() => {
                                        if (this.props.onMediaClick !== undefined) {
                                            this.props.onMediaClick(galleryItem)
                                        }
                                    }} height="450px" src={Api.getGalleryItem(galleryItem.id)} style={{width: '100%', height: '450px', objectFit: 'cover', borderRadius: '15px'}} />}
                                </Slide>       
                            ))}
                        </Slider>

                        {this.getGalleryItems().length > 1 && <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Button type="link" onClick={e => this.setState(prevState => ({currentGallerySlide: prevState.currentGallerySlide - 1}))} disabled={this.state.currentGallerySlide == 0} ><LeftOutlined /></Button>
                            {this.getGalleryItems().map((item, index) => (
                                <div style={{
                                    width: 8, height: 8, transition: '150ms',
                                    marginLeft: index > 0 ? 10 : 0, 
                                    borderRadius: '50%',
                                    background: this.state.currentGallerySlide == index ? '#4e2e89' : '#cecece',
                                    //transform: 'scale(' + this.state.currentGallerySlide == index ? 1.2 : 1 + ')'
                                    transform: this.state.currentGallerySlide == index ? 'scale(1.3)' : 'scale(1)'
                                }} />
                            ))}
                            <Button type="link" onClick={e => this.setState(prevState => ({currentGallerySlide: prevState.currentGallerySlide + 1}))} disabled={this.state.currentGallerySlide >= (this.getGalleryItems().length - 1)}><RightOutlined /></Button>
                        </div>}
                    </CarouselProvider>
                : null}
    
                <div style={{display: 'flex', alignItems: 'center', padding: '15px', paddingLeft: 0}}>
                    <div style={{width: '50%'}}>
                        {options}
                    </div>
                    <div style={{width: '50%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                        <Button type="link" onClick={this.onCommentToggle.bind(this)}><CommentOutlined /> {this.state.postCommentCount}</Button>
                        {this.props.post.parentType === POST_PARENT_TYPE_RATING ? (
                            <Button type={(this.props.post.liked || !SessionManager.isLoggedIn()) ? "link" : "default"} className={this.props.post.liked ? "orange-link" : ""} 
                            loading={this.state.liking} onClick={() => this.onLikeBtn()} style={{cursor: !SessionManager.isLoggedIn() && 'default'}}>
                                {(this.props.post.liked || !SessionManager.isLoggedIn()) ? <><CheckOutlined /> Found helpful {this.props.post.likeCount}</> : <><LikeOutlined /> Found helpful?</>}
                            </Button>
                        ) : (
                            <Popover destroyTooltipOnHide overlayClassName="notification-pop-over" content={<div style={{width: 300, maxHeight: 200, padding: 15, overflow: 'auto'}}>
                                <h2 style={{color: 'black', fontSize: 25, height: 30}}>
                                    <LikeOutlined /> Liked by
                                </h2>

                                <div style={{height: 10}} />
                                
                                {this.props.post.likeCount == 0 ? (
                                    <p style={{opacity: 0.65}}>No likes</p>
                                ) : (
                                    <UserList showLikedByUsers={{parentId: this.props.post.id, parentType: ITEM_LIKE_PARENT_TYPE_POST}} />
                                )}
                            </div>} trigger="hover">
                                <Button type="link" className={this.props.post.liked ? "orange-link" : ""} loading={this.state.liking} 
                                onClick={() => this.onLikeBtn()} style={{cursor: !SessionManager.isLoggedIn() && 'default'}}>
                                    <LikeOutlined /> {this.props.post.likeCount}
                                </Button>
                            </Popover>
                        )}
                    </div>
                </div>
    
                {this.state.showComments || this.state.closeComments ? (
                    <motion.div initial={this.state.showComments ? { height: 0, opacity: 0 } : { height: 400, opacity: 1 }} 
                                animate={this.state.closeComments ? { height: 0, opacity: 0 } : { height: 400, opacity: 1 }}
                                /*onAnimationStart={() => {
                                    if (this.state.showComments) {
                                        this.setState({
                                            showCommentsView: true
                                        })
                                    }
                                }}
                                onAnimationComplete={() => {
                                    if (this.state.closeComments) {
                                        this.setState({
                                            showCommentsView: false
                                        })
                                    }
                                }}*/>
                        <div style={{width: '100%', height: '100%', overflow: 'hidden'}}>
                            <CommentsView parentOwner={this.props.post.accountId} disableAdd={this.props.post.id !== SessionManager.getAccount().id || !this.props.canComment} replyMode={this.props.post.parentType === POST_PARENT_TYPE_RATING} postId={this.props.post.id} adminComments={this.props.adminPosts} 
                            onCommentAdded={this.onCommentAdded.bind(this)}
                            onCommentRemoved={this.onCommentRemoved.bind(this)} /> 
                        </div>
                    </motion.div> 
                ) : null}


                {this.state.showEditPostDialog && 
                <Modal okText="Update" visible={this.state.showEditPostDialog} 
                confirmLoading={this.state.editingPostContent}
                title="Edit" onOk={this.editPostContent.bind(this)}
                onCancel={() => this.setState({showEditPostDialog: false})} bodyStyle={{padding: 15}}>
                    <TextArea maxLength={2500} rows={4} value={this.state.editPostContentValue} 
                    onChange={e => this.setState({editPostContentValue: e.target.value})} />
                </Modal>}
                    
                    {/*
                {this.state.showComments ? (
                    <motion.div initial={{ height: 0, opacity: 0 }} animate={{ height: 200, opacity: 1 }}>
                        <div style={{width: '100%', height: '100%'}}>
                            <CommentsView />
                        </div>
                    </motion.div> 
                ) : null}
    
                {this.state.closeComments ? (
                    <motion.div initial={{ height: 200 }} animate={{ height: 0 }} />
                ) : null} */}
            </div>
        )
    }
}

export default PostItem;