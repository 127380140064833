import React from 'react';
import withMediaQuery from '../../components/WithMediaQuery';
import InfiniteScroll from 'react-infinite-scroller';
import LiveSessionScheduleItem from './LiveSessionScheduleItem';
import { Button, Spin, Input, Empty, Alert, Upload, InputNumber, DatePicker } from 'antd';
import Modal from 'antd/lib/modal/Modal';

import {  
    PlusOutlined,
    InboxOutlined
} from '@ant-design/icons';
import Api from '../../session/Api';
import { NO_PAGE_TOKEN, PAGINATION_PAGE_CONTENT_COUNT } from '../../Constants';
import Util from '../../util/Util';

import NoImage from '../../images/NoImage.png';
import UIUtil from '../../util/UIUtil';
import LiveStreamAttendanceReport2 from '../../livestream/components/LiveStreamAttendanceReport2';

const FormPadding = () => {
    return ( <> <br /> <br /> </> )
}

class LiveSessionScheduleView extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            items: [],
            page: NO_PAGE_TOKEN,
            hasMore: true,
            loadingItems: false,

            newScheduleThumbnail: undefined,
            newScheduleTitle: "",
            newScheduleDate: undefined,
            newScheduleDuration: 0,
            newScheduleDesc: "",
            newSchedule: false,
            creatingNewSchedule: false,

            updateScheduleThumbnail: undefined,
            updateScheduleTitle: "",
            updateScheduleDesc: "",
            updateSchedule: undefined,
            updatingSchedule: false,

            selectedLiveStreamId: undefined
        }
    }

    getNextSchedules() {
        if (this.state.loadingItems) {
            return;
        }

        this.setState({
            loadingItems: true
        })

        //list error
        Api.getLiveSessionSchedules(this.props.liveSession.id, this.state.page, response => {
            if (response.status === true) {
                let items = this.state.items;
                items.push(...response.payload.items);

                this.setState({
                    items: items,
                    page: response.payload.token,
                    hasMore: Util.isStringExists(response.payload.token),
                    loadingItems: false
                })
            } else {
                //alert('errowr');
            }
        });
    }

    newSchedule(thumbnail, successListener) {
        if (this.state.newScheduleDate.valueOf() < new Date().getTime()) {
            UIUtil.showError("Can not set a past date")
            return;
        }

        this.setState({creatingNewSchedule: true})
        Api.createLiveSessionSchedule({
            liveSessionId: this.props.liveSession.id,
            title: this.state.newScheduleTitle,
            date: this.state.newScheduleDate.valueOf(),
            duration: this.state.newScheduleDuration,
            description: this.state.newScheduleDesc,
        }, thumbnail, response => {
            if (response.status === true) {
                this.state.items.unshift(response.payload);

                UIUtil.showSuccess();
                successListener();
                if (this.props.onScheduleAdded) {
                    this.props.onScheduleAdded();
                }
            } else {
                UIUtil.showError(response.message);
            }
            this.setState({creatingNewSchedule: false})
        });
    }

    updateSchedule(thumbnail, successListener) {
        this.setState({updatingSchedule: true})
        Api.updateLiveSessionSchedule(this.state.updateSchedule.id, this.state.updateScheduleTitle, this.state.updateScheduleDesc, thumbnail, response => {
            if (response.status === true) {
                const index = this.state.items.indexOf(this.state.updateSchedule);
                if (index != -1) {
                    this.state.items[index] = response.payload;
                }
                if (thumbnail !== undefined) {
                    this.setState({
                        updatingSchedule: false,
                        [response.payload.id + "-thumbnail-suffix"]: new Date().getTime()
                    })
                } else {
                    this.setState({updatingSchedule: false})
                }
                successListener();
            } else {
                UIUtil.showError();
                this.setState({updatingSchedule: false})
            }
        })
    }

    render() {
        if (this.props.liveSession === undefined || !Util.isStringExists(this.props.liveSession.id)) {
            return null;
        }

        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div style={{width: this.props.renderFullWidth ? '90vw' : '75vw', paddingTop: '25px', paddingBottom: '25px'}}>
                    {/* <Alert
                        message="Important"
                        description="Make sure about the scheduling. The schedules can not be changed after the first publish."
                        type="info"
                        showIcon
                    />
                    <br /> */}
                    {//!this.props.liveSession.publishedAtLeastOnce && 
                    <>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                            <Button type="link" onClick={e => this.setState({newSchedule: true, newScheduleTitle: this.props.liveSession.title})}>
                                <PlusOutlined /> Create Schedule
                            </Button>
                        </div>
                        <br /> 
                    </>}
                    <InfiniteScroll
                        loadMore={this.getNextSchedules.bind(this)}
                        hasMore={this.state.hasMore}
                        threshold={750}
                        loader={
                            <div style={{display: 'flex', justifyContent: 'center', marginTop: '45px', marginBottom: '45px'}}>
                                <Spin size="large" tip="Loading..." />
                            </div>
                        }
                        style={{overflow: 'visible'}}>

                        {this.state.items.map((item, index) => {
                            return ( 
                                <div key={item.id}>
                                    {index > 0 ? <br /> : null}
                                    <LiveSessionScheduleItem 
                                    recorded={this.props.liveSession.record}
                                    studentMode={this.props.studentMode}
                                    schedule={item}
                                    //deletable={!this.props.liveSession.publishedAtLeastOnce}
                                    deletable
                                    thumbnailSuffix={this.state[item.id + "-thumbnail-suffix"]}
                                    /*onClick={() => {
                                        switch (item.type) {
                                            case COURSE_ITEM_TYPE_FILE:
                                                window.location = Api.getScheduleAttachment(item.id)
                                                break;    
                                            default:
                                                this.setState({ selectedSchedule: item });
                                                break;
                                        }
                                    }}*/
                                    onViewAttendanceReport={() => this.setState({selectedLiveStreamId: item.liveStreamId})}
                                    onItemDeleted={() => {
                                        const index = this.state.items.indexOf(item);
                                        if (index != -1) {
                                            this.state.items.splice(index, 1);
                                            this.setState({});
                                        }
                                    }}
                                    onEditBtn={() => this.setState({
                                        updateSchedule: item,
                                        updateScheduleTitle: item.title,
                                        updateScheduleDesc: item.description
                                    })} />
                                </div>
                            )
                        })}
                    </InfiniteScroll>

                    {!this.state.loadingItems ? <Empty style={{marginTop: '50px'}} image={Empty.PRESENTED_IMAGE_SIMPLE} description={this.state.items.length > 0 ? "No more schedules" : "No schedules"} /> : null}
                    <br />
                </div>

                <Modal
                title="Create Schedule"
                width={750}
                centered
                visible={this.state.newSchedule}
                okText="Upload"
                okButtonProps={{disabled: this.state.newScheduleTitle.trim() === "" || this.state.newScheduleDuration <= 0 || this.state.newScheduleDate === undefined}}
                confirmLoading={this.state.creatingNewSchedule}
                onOk={() => this.newSchedule(this.state.newScheduleThumbnail, 
                    () => this.setState({newScheduleThumbnail: undefined, newScheduleTitle: "", newScheduleDate: undefined, newScheduleDuration: 0, newScheduleDesc: "", newSchedule: false}))}
                onCancel={() => this.setState({newScheduleThumbnail: undefined, newScheduleTitle: "", newScheduleDate: undefined, newScheduleDuration: 0, newScheduleDesc: "", newSchedule: false})}>
                    <h3>Title</h3>
                    <Input size="large" value={this.state.newScheduleTitle} onChange={e => this.setState({newScheduleTitle: e.target.value})} />
                    <FormPadding />

                    <h3>Date and Time</h3>
                    <DatePicker 
                    showTime
                    showSecond={false}
                    minuteStep={5}
                    format="YYYY-MM-DD HH:mm"
                    value={this.state.newScheduleDate}
                    onChange={date => this.setState({newScheduleDate: date})}
                    size="large"
                    style={{width: '100%'}} />
                    <FormPadding />

                    <h3>Duration (minutes)</h3>
                    <InputNumber
                    size="large"
                    style={{width: '100%'}}
                    value={this.state.newScheduleDuration} onChange={value => this.setState({newScheduleDuration: value})} />
                    <FormPadding />

                    <h3>Description</h3>
                    <Input.TextArea maxLength={1000}  size="large" rows={4} value={this.state.newScheduleDesc} onChange={e => this.setState({newScheduleDesc: e.target.value})} />
                    <FormPadding />

                    <h3>Thumbnail</h3>
                    <div style={{display: 'flex', maxHeight: '250px'}}>
                        <img src={this.state.newScheduleThumbnail !== undefined ? URL.createObjectURL(this.state.newScheduleThumbnail) : NoImage} onError={e => {
                            e.target.onerror = null;
                            e.target.src = NoImage;
                        }} style={{width: '300px', height: '250', objectFit: 'cover'}} />
                        <div style={{height: '250px', maxHeight: '250px', flex: 1, marginLeft: '25px'}}>
                            <Upload.Dragger accept="image/gif,image/jpeg,image/png" showUploadList={false} beforeUpload={file => {
                                this.setState({newScheduleThumbnail: file})
                                return false;
                            }}>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            </Upload.Dragger>
                        </div>
                    </div>
                </Modal>

                <Modal
                title="Update"
                width={750}
                centered
                visible={this.state.updateSchedule !== undefined}
                okText="Update"
                okButtonProps={{disabled: this.state.updateScheduleTitle.trim() === ""}}
                confirmLoading={this.state.updatingSchedule}
                onOk={() => this.updateSchedule(this.state.updateScheduleThumbnail, 
                    () => this.setState({updateScheduleThumbnail: undefined, updateScheduleTitle: "", updateScheduleDesc: "", updateSchedule: undefined}))}
                onCancel={() => this.setState({updateScheduleThumbnail: undefined, updateScheduleTitle: "", updateScheduleDesc: "", updateSchedule: undefined})}>    
                    <h2>Details</h2>
                    <h3>Title</h3>
                    <Input size="large" value={this.state.updateScheduleTitle} onChange={e => this.setState({updateScheduleTitle: e.target.value})} />

                    <FormPadding />
                    <h3>Description</h3>
                    <Input.TextArea maxLength={1000}  size="large" rows={4} value={this.state.updateScheduleDesc} onChange={e => this.setState({updateScheduleDesc: e.target.value})} />

                    <FormPadding />

                    <h3>Thumbnail</h3>
                    <div style={{display: 'flex', maxHeight: '250px'}}>
                        <img src={this.state.updateScheduleThumbnail !== undefined ? URL.createObjectURL(this.state.updateScheduleThumbnail) : Api.getLiveSessionScheduleThumbnail(this.state.updateSchedule !== undefined ? this.state.updateSchedule.id : 0)} onError={e => {
                            e.target.onerror = null;
                            e.target.src = NoImage;
                        }} style={{width: '300px', height: '250', objectFit: 'cover'}} />
                        <div style={{height: '250px', maxHeight: '250px', flex: 1, marginLeft: '25px'}}>
                            <Upload.Dragger accept="image/gif,image/jpeg,image/png" showUploadList={false} beforeUpload={file => {
                                this.setState({updateScheduleThumbnail: file})
                                return false;
                            }}>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            </Upload.Dragger>
                        </div>
                    </div>
                </Modal>

                {this.state.selectedLiveStreamId !== undefined &&
                <Modal visible width={700} onCancel={() => this.setState({selectedLiveStreamId: undefined})} footer={null}>
                    <LiveStreamAttendanceReport2 liveStreamId={this.state.selectedLiveStreamId} />
                </Modal>}
            </div>
        )
    }

}

export default withMediaQuery(LiveSessionScheduleView);