import React from 'react'
import withMediaQuery from '../../components/WithMediaQuery';


import {
    TeamOutlined,
    UserOutlined,
    QuestionCircleOutlined,
    DashboardOutlined,
    DashboardFilled,
    CreditCardOutlined,
    ClockCircleFilled,
    DollarCircleFilled,
    WarningTwoTone,
    ReloadOutlined
} from '@ant-design/icons'
import { Tooltip, Button, Card, Spin, Slider } from 'antd';

import { Link } from 'react-router-dom';

import GetUserCreditsView from './GetUserCreditsView'
import Page from '../../base/Page';
import Api from '../../session/Api';
import Util from '../../util/Util';

const ValueLabel = ({label, value, bold}) => (
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
        <h2 style={{alignSelf: 'flex-start', margin: 0, marginBottom: bold ? -20 : -15, fontSize: bold ? 30 : 22.5}}>{label}</h2>
        <h1 style={{fontWeight: bold ? 'bold' : undefined, color: '#4e2e89', margin: 0, fontSize: bold ? 60 : 45}}>{Util.zeroPad(value, 4)}</h1>
        <h1 style={{margin: 0, marginTop: bold ? -20 : -15, fontSize: bold ? 30 : 22.5}}>users</h1>
    </div>
)

class UserAllowancePage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            loading: true,
            error: false,

            usage: null,
        }
    }

    onPageStart() {
        this.setState({loading: true})
        Api.getUserCreditUsage(response => {
            this.setState({loading: false, error: response.payload === null, usage: response.payload})
        })
    }

    render() {
        if (this.state.loading) {
            return (
                <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingTop: 45, paddingBottom: 45}}>
                    <Spin size="large" tip="Loading..." />
                </div>
            )
        }

        if (this.state.error) {
            return (
                <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingTop: 45, paddingBottom: 45}}>
                    

                    <WarningTwoTone twoToneColor="#f26622" style={{fontSize: 80}} />
                    <p style={{marginTop: 45, opacity: 0.65}}>An error occurred. Please try again later.</p>

                    <Button icon={<ReloadOutlined />} onClick={() => window.location.reload()}>Reload</Button>
                </div>
            )

        }

        return (
            <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                <div style={{width: this.props.isSmall ? '90vw' : '75vw', paddingTop: '25px', paddingBottom: '25px'}}>
                    <h1><TeamOutlined /> User Allowance</h1>

                    <h2>
                        <DashboardOutlined style={{marginRight: 5}} />
                        User Credits
                        <Tooltip color="purple" title={<p>
                            <strong>User credits are needed to be able to invite/create users.</strong>
                            <br />
                            <br />
                            <strong>Package</strong> credits may be included with your package.
                            <br />
                            <br />
                            <strong>Pay-as-you-go</strong> credits will be billed at the start of every month.
                            <br />
                            <br />
                            <strong>Users left</strong> is the amount of user allowance remaining from the package before pay-as-you-go is needed.
                        </p>}><Button type="link"><QuestionCircleOutlined /></Button></Tooltip>
                    </h2>

                    <div style={{paddingTop: 25, paddingBottom: 25, display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
                        <ValueLabel label="Total Users" value={this.state.usage.credits.totalUsers} bold />
                        <ValueLabel label="Package" value={this.state.usage.credits.packageUsers} />
                        <ValueLabel label="Pay-as-you-go" value={this.state.usage.credits.payAsYouGoUsers} />
                        <ValueLabel label="Limit Left" value={this.state.usage.credits.limitLeftUsers} />
                    </div>


                    <div style={{height: 25}} />
                    <h2>
                        <CreditCardOutlined style={{marginRight: 5}} />
                        Pay-as-you-go
                        <Tooltip color="purple" title={<p>
                            Pay-as-you-go credits can be purchased in order to add more users as needed. You will also receive free monthly live stream credits needed to run live streams.
                        </p>}><Button type="link"><QuestionCircleOutlined /></Button></Tooltip>
                    </h2>
                    <div //className="card-full-height-bottom"
                    >
                        <GetUserCreditsView 
                        liveStreamCreditRate={this.state.usage.liveStreamCreditRate} priceRate={this.state.usage.priceRate}
                        maxPayCredit={this.state.usage.maxPayCredit} payInterval={this.state.usage.payInterval} />
                    </div>
                </div>
            </div>
        )
    }

}

export default withMediaQuery(UserAllowancePage);