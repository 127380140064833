import { Button, Card, Input, Modal, Popconfirm, Select, Tooltip, Upload } from 'antd';
import { T } from 'antd/lib/upload/utils'
import React from 'react';
import Page from '../../base/Page';
import withMediaQuery from '../../components/WithMediaQuery';
import Api from '../../session/Api';
import Error404Page from '../Error404Page';

import NoImage from '../../images/NoImage.png'

import {CopyToClipboard} from 'react-copy-to-clipboard';

import { 
    PlusOutlined,
    EditOutlined,
    CheckOutlined,
    CloseOutlined,
    SaveOutlined,
    LinkOutlined,
    CopyOutlined,
    LineChartOutlined,
    CommentOutlined,
    LikeOutlined,
    EyeOutlined,
    DeleteOutlined,
    InboxOutlined
} from '@ant-design/icons';
import Util from '../../util/Util';
import { BLOG_POST_SOURCE_UNPUBLISHED } from '../../Constants';
import UIUtil from '../../util/UIUtil';
import { CATEGORIES } from '../../Categories';
import { withRouter } from 'react-router-dom';
import { BLGO_POST_TAGS } from '../../BlogPostTags';

import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

class MyBlogPostPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            loading: true,
            blogPost: undefined,

            thumbnailValue: undefined,
            titleValue: "",
            previewValue: "",
            contentValue: "",
            tagValue: -1,

            contentEditorState: EditorState.createEmpty(),

            publishToggleLoading: false,
            saving: false,
            deleting: false
        }
    }

    onPageStart() {
        if (this.state.blogPost !== undefined && this.state.pathParams.d == this.state.blogPost.date) {
            return;
        }

        this.setState({loading: true, blogPost: undefined})
        Api.getBlogPost(this.state.pathParams.t, this.state.pathParams.d, response => {
            if (response.status === true) {
                this.setBlogPost(response.payload, {loading: false});
            } else {
                this.setState({loading: false, blogPost: undefined})
            }
        })
    }

    setBlogPost(blogPost, extra) {
        this.setState({
            ...extra,

            blogPost: blogPost,

            thumbnailValue: undefined,
            titleValue: blogPost.title,
            previewValue: blogPost.preview,
            contentValue: blogPost.content,
            tagValue: blogPost.tag,

            contentEditorState: blogPost.content !== null ? EditorState.createWithContent(convertFromRaw(JSON.parse(blogPost.content))) : EditorState.createEmpty()
        })
    }

    onTogglePublishBtn() {
        this.setState({publishToggleLoading: true})
        Api.toggleBlogPostPublish(this.state.blogPost.date, response => {
            if (response.status === true) {
                this.state.blogPost.source = response.payload;
                this.setState({publishToggleLoading: false})
                
                UIUtil.showSuccess();
            } else {
                UIUtil.showError();
                this.setState({publishToggleLoading: false})
            }
        })
    }

    onSaveChangesBtn() {
        this.setState({saving: true})
        Api.saveBlog(this.state.titleValue, this.state.blogPost.date, this.state.tagValue, this.state.previewValue, this.state.contentValue, this.state.thumbnailValue, response => {
            if (response.status === true) {
                if (this.state.blogPost.title != this.state.titleValue) {
                    this.props.history.replace(Util.getBlogPostPath(true, this.state.titleValue, this.state.blogPost.date))
                }
                this.setBlogPost(response.payload, {saving: false});
                UIUtil.showSuccess();
            } else {
                UIUtil.showError(response.message);
                this.setState({saving: false})
            }
        })
    }

    deletePost() {
        this.setState({deleting: true})
        Api.deleteBlogPost(this.state.blogPost.date, response => {
            if (response.status === true) {
                Modal.success({
                    title: "Success!",
                    content: "Blog post permanently deleted",
                    onOk: () => this.props.history.goBack()
                })
            } else {
                UIUtil.showError();
            }
            this.setState({deleting: false})
        })
    }

    changesMade() {
        return  this.state.thumbnailValue !== undefined ||
                this.state.blogPost.title !== this.state.titleValue ||
                this.state.blogPost.preview !== this.state.previewValue ||
                this.state.blogPost.content !== this.state.contentValue ||
                this.state.blogPost.tag != this.state.tagValue;
    }

    render() {
        if (this.state.loading) {
            return <div />
        }

        if (this.state.blogPost === undefined) {
            return <Error404Page customMessage="Blog post does not exist" />
        }

        const blogPost = this.state.blogPost;
        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div style={{width: this.props.renderFullWidth ? '90vw' : '75vw', paddingTop: '25px', paddingBottom: '25px'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <h2><EditOutlined style={{marginRight: 5}} /> Blog Post Editor</h2>
                        <div style={{flex: '1'}} />

                        {blogPost.source == BLOG_POST_SOURCE_UNPUBLISHED ? (
                            <Button onClick={this.onTogglePublishBtn.bind(this)} icon={<CheckOutlined />} type="primary" disabled={this.changesMade()} loading={this.state.publishToggleLoading}>Publish</Button>
                        ) : (
                            <Button onClick={this.onTogglePublishBtn.bind(this)} icon={<CloseOutlined />} type="primary" className={this.changesMade() ? "" : "red-button"} disabled={this.changesMade()} loading={this.state.publishToggleLoading}>Unpublish</Button>  
                        )}
                        <Button icon={<SaveOutlined />} style={{marginLeft: '15px'}} disabled={!this.changesMade()} 
                        loading={this.state.saving} onClick={this.onSaveChangesBtn.bind(this)}>Save Changes</Button>

                    </div>

                    <div style={{display: 'flex', marginTop: 40}}>
                        <div style={{flex: 1}}>
                            <h5>Title</h5>
                            <Input title="Test" value={this.state.titleValue} onChange={e => this.setState({titleValue: e.target.value})}
                            size="large" style={{fontWeight: "bold", color: "black"}} maxLength={127} />

                            <h5 style={{marginTop: 25}}>Tag</h5>
                            <Select value={this.state.tagValue} onChange={value => this.setState({tagValue: value})} style={{ width: '100%' }} size="large">
                                {BLGO_POST_TAGS.map(tag => 
                                    <Select.Option value={tag.id}>{tag.icon} &nbsp;&nbsp;{tag.title}</Select.Option>)}
                            </Select>

                            <h5 style={{marginTop: 25}}>Thumbnail</h5>
                            <div style={{display: 'flex', maxHeight: '250px'}}>
                                <img src={this.state.thumbnailValue !== undefined ? URL.createObjectURL(this.state.thumbnailValue) : Api.getBlogPostThumbnail(this.state.blogPost.blogPostId)} onError={e => {
                                    e.target.onerror = null;
                                    e.target.src = NoImage;
                                }} style={{width: '360px', height: '250', objectFit: 'cover'}} />
                                <div style={{height: '250px', maxHeight: '250px', flex: 1, marginLeft: '25px'}}>
                                    <Upload.Dragger accept="image/gif,image/jpeg,image/png" showUploadList={false} beforeUpload={file => {
                                        this.setState({thumbnailValue: file})
                                        return false;
                                    }}>
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                    </Upload.Dragger>
                                </div>
                            </div>

                            <h5 style={{marginTop: 25}}>Preview</h5>
                            <Input.TextArea title="Test" value={this.state.previewValue} onChange={e => this.setState({previewValue: e.target.value})}
                            autoSize={{ minRows: 3, maxRows: 5 }} maxLength={255} />

                            <h5 style={{marginTop: 25}}>Content</h5>
                            <Editor
                            editorState={this.state.contentEditorState}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            editorStyle={{borderStyle: 'solid', borderWidth: 1, borderColor: '#00000015', paddingLeft: 10, paddingRight: 10}}
                            onEditorStateChange={state => this.setState({contentValue: JSON.stringify(convertToRaw(state.getCurrentContent())), contentEditorState: state})}
                            />

                            <div style={{height: 150}} />
                        </div>
                        <div style={{width: 25}} />
                        <div style={{width: 300, marginTop: 0}}>
                            <Card>
                                <div style={{display: 'flex', alignItems: 'center',}}>
                                    <div style={{borderRadius: '50%', marginRight: '10px', background: blogPost.source != BLOG_POST_SOURCE_UNPUBLISHED ? '#009900' : '#faad14', width: '10px', height: '10px'}} />
                                    <h4 style={{height: '16px'}}>{blogPost.source != BLOG_POST_SOURCE_UNPUBLISHED ? 'Published' : 'Unpublished'}</h4>
                                </div>

                                <p style={{margin: 0, marginTop: 10, fontSize: 12}}>Blog post created on {Util.getFullDate(this.state.blogPost.date)}</p>
                            </Card>

                            <Card style={{marginTop: 15,}}>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <h5 style={{margin: 0}}><CommentOutlined style={{marginRight: 5}} /> Comments</h5>
                                    <div style={{flex: 1}} />
                                    <p style={{margin: 0, fontSize: 12}}>{this.state.blogPost.commentCount}</p>
                                </div>

                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 15}}>
                                    <h5 style={{margin: 0}}><LikeOutlined style={{marginRight: 5}} /> Likes</h5>
                                    <div style={{flex: 1}} />
                                    <p style={{margin: 0, fontSize: 12}}>{this.state.blogPost.likeCount}</p>
                                </div>

                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 15}}>
                                    <h5 style={{margin: 0}}><EyeOutlined style={{marginRight: 5}} /> Views</h5>
                                    <div style={{flex: 1}} />
                                    <p style={{margin: 0, fontSize: 12}}>{this.state.blogPost.viewCount}</p>
                                </div>
                            </Card>

                            <Card style={{marginTop: 15}} bodyStyle={{paddingTop: 15}}>
                                <div style={{display: 'flex', alignItems: 'center',}}>
                                    <LinkOutlined style={{fontSize: 10, marginRight: 10,}} />
                                    <h4 style={{height: '16px'}}>Public Link</h4>
                                    <div style={{flex: 1}} />
                                    <CopyToClipboard text={window.location.origin.toString() + Util.getBlogPostPath(false, blogPost.title, blogPost.date)} onCopy={() => UIUtil.showSuccess("Copied to clipboard!")}>
                                        <Tooltip title="Copy"><Button type="link" style={{padding: 0}}><CopyOutlined /></Button></Tooltip>
                                    </CopyToClipboard>
                                </div>

                                
                                <div style={{paddingTop: 10}}>
                                    <a 
                                    target="_blank"
                                    href={window.location.origin.toString() + Util.getBlogPostPath(false, blogPost.title, blogPost.date)}
                                    style={{margin: 0, fontSize: 12, overflowWrap: 'break-word', whiteSpace: 'pre-line'}}>
                                        {window.location.origin.toString() + Util.getBlogPostPath(false, blogPost.title, blogPost.date)}
                                    </a>
                                </div>

                                {blogPost.source == BLOG_POST_SOURCE_UNPUBLISHED &&
                                <p style={{margin: 0, marginTop: 10, fontSize: 10}}>(Blog post is currenly not published)</p>}
                            </Card>

                            <Card style={{marginTop: 15,}} bodyStyle={{}}>
                                <Popconfirm 
                                okButtonProps={{loading: this.state.deleting}} onConfirm={() => this.deletePost()}
                                title="Are you sure you want to delete this blog post? This action is irreversible.">
                                    <div style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
                                        <DeleteOutlined style={{fontSize: 10, marginRight: 10, color: "#990000"}} />
                                        <h4 style={{height: '16px', color: "#990000"}}>Delete Permanently</h4>
                                        <div style={{flex: 1}} />
                                    </div>
                                </Popconfirm>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default withRouter(withMediaQuery(MyBlogPostPage));