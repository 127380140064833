import React from 'react';
import Page from '../base/Page'
import { Link } from 'react-router-dom';
import { Result, Button } from 'antd';

const styles = {
    bg: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        background: 'white'
    },
    root: {
        position: 'absolute', 
        left: '50%', 
        top: '50%',
        transform: 'translate(-50%, -50%)',
    },
    button: {
        position: 'absolute', 
        left: '50%', 
        transform: 'translate(-50%, -50%)'
    }
}

class Error404Page extends Page {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div style={styles.bg}>
                <div style={styles.root}>
                    <h1 style={{color: 'black'}} align="center">
                        404 Page not found
                    </h1>
                    <h5 style={{color: '#8a8a8a'}} align="center">
                        {this.props.customMessage !== undefined ? this.props.customMessage : 'The requested URL was not found on this server'}
                    </h5>

                    <br />
                    <br />
                    
                    <Button style={styles.button} type="primary" size="medium" >
                        <Link to="/">
                            Go Home
                        </Link>
                    </Button>
                </div>
            </div>
        )
    }
}

export default Error404Page;