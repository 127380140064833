import React from 'react'

import {
    AlertOutlined,
    DashboardOutlined,
    QuestionCircleOutlined,
    CreditCardOutlined,
    DollarCircleOutlined,
    HistoryOutlined,
    DollarCircleFilled,
    DashboardFilled,
    ClockCircleFilled,
    BarChartOutlined
} from '@ant-design/icons'
import { Tooltip, Button, Tabs, InputNumber, Card, Slider } from 'antd';

import { Link } from 'react-router-dom';
import Api from '../../session/Api';
import UIUtil from '../../util/UIUtil';


const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

function daysInThisMonth() {
    var now = new Date();
    return new Date(now.getUTCFullYear(), now.getUTCMonth()+1, 0).getUTCDate();
}  

const dayLabel = (day) => {
    return day == 1 ? "day" : "days";
}

class GetUserCreditsView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            creditPurchaseSliderValue: 0,
            purchasing: false
        }

        
        this.today = new Date().getUTCDate();
        this.monthName = monthNames[new Date().getUTCMonth()];

        this.totalDaysInMonth = daysInThisMonth();
        this.daysLeftInMonth = (this.totalDaysInMonth - this.today) + 1;
    }

    onPurchaseBtn() {
        this.setState({purchasing: true})
        Api.getUserCredit(this.state.creditPurchaseSliderValue, response => {
            if (response.status === true) {
                UIUtil.showSuccess();
                window.location.reload();
            } else {
                // UIUtil.showError()
                UIUtil.showInfo("Your account is not approved for more credits")
                this.setState({purchasing: false})
            }
        })
    }

    getCurrentPrice() {
        return (this.getRawDailyPrice() * this.daysLeftInMonth).toFixed(2);
    }

    getRawDailyPrice() {
        return this.getRawMonthlyPrice() / this.totalDaysInMonth;
    }

    getRawMonthlyPrice() {
        return this.state.creditPurchaseSliderValue * this.props.priceRate;
    }

    getDailyPrice() {
        return this.getRawDailyPrice().toFixed(2);
    }

    getMonthlyPrice() {
        return this.getRawMonthlyPrice().toFixed(2);
    }

    getFreeLiveStreamMinutes() {
        return this.state.creditPurchaseSliderValue * this.props.liveStreamCreditRate;
    }

    render() {
        return (
            <div className="card-full-height-bottom">
                <Card title={<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <p style={{margin: 0}}><DashboardFilled style={{marginRight: 7}} /> Get Credits</p>
                    <div style={{flex: 1}} />
                    <Link to="/billing">
                        <Button >Billing</Button>
                    </Link>
                </div>} 
                actions={[
                    <div>
                        <h5 style={{color: 'rgba(0, 0, 0, 0.65)', margin: 0}}><ClockCircleFilled style={{marginRight: 0}} /> Pay-as-you-go</h5>
                        <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Slider 
                            tipFormatter={() => "Today"}
                            marks={{
                                [this.totalDaysInMonth]: this.monthName,
                                [this.totalDaysInMonth - this.today]: {
                                    style: {
                                        //paddingTop: -50  
                                    },
                                    label: '',
                                },
                                1: {
                                    style: {
                                        color: 'green',
                                    },
                                    label: <strong>Billed</strong>,
                                },
                            }} defaultValue={this.totalDaysInMonth - this.today} min={1} max={this.totalDaysInMonth} reverse disabled style={{width: '75%', alignSelf: 'center',}} />
                        </div>
                        <p style={{fontSize: 12, color: 'black', opacity: 0.45, margin: 0, marginTop: 10,}}>
                            Paying for {this.daysLeftInMonth} {dayLabel(this.daysLeftInMonth)} up till next month

                            <Tooltip color="purple" title={<p>
                                You will only pay for the remaining days left in the month right now. You will be billed for the next full month at the start of the month.
                            </p>}><Button type="link"><QuestionCircleOutlined /></Button></Tooltip>
                        </p>
                    </div>,
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%', position: 'relative'}}>
                        <h5 style={{color: 'rgba(0, 0, 0, 0.65)', margin: 0}}><DollarCircleFilled style={{marginRight: 0}} /> Price</h5>
                        <p style={{fontSize: 20, margin: 0, marginTop: -5, color: 'black'}}>
                            ${this.getCurrentPrice()}
                        </p>
                        <p style={{fontSize: 12, color: 'black', opacity: 0.45, margin: 0, marginTop: 0,}}>
                            Then ${this.getMonthlyPrice()} monthly
                        </p>

                        <Tooltip color="purple" title={<p>
                            <strong>Pricing</strong>
                            <br />
                            <br />
                            {this.state.creditPurchaseSliderValue} users * ${this.props.priceRate.toFixed(2)} per user per month 
                            <br />
                            <strong>${this.getMonthlyPrice()} total per month</strong>
                            <br />
                            <br />
                            ${this.getMonthlyPrice()} per month / {this.totalDaysInMonth} {dayLabel(this.totalDaysInMonth)}
                            <br />
                            <strong>${this.getDailyPrice()} total per day</strong>
                            <br />
                            <br />
                            <strong>Pay-as-you-go</strong> price for the rest the month ({this.daysLeftInMonth} {dayLabel(this.daysLeftInMonth)})
                            <br />
                            <strong>${this.getCurrentPrice()}</strong>
                        </p>}><Button type="link" style={{position: 'absolute', right: 15, top: 10}}><QuestionCircleOutlined /></Button></Tooltip>
                    </div>,
                    <div style={{flex: 1, display: 'flex', justifyContent: 'flex-end', marginRight: 15}}>
                        <Button type="primary" disabled={this.state.creditPurchaseSliderValue == 0}
                        loading={this.state.purchasing}
                        onClick={this.onPurchaseBtn.bind(this)}>
                            <CreditCardOutlined /> Purchase
                        </Button>
                    </div>,
                ]}
                hoverable={false} style={{marginTop: 5}} bodyStyle={{paddingTop: 15, paddingBottom: 15}}>
                    <div style={{display: 'flex', alignItems: 'flex-end'}}>
                        <h1 style={{fontWeight: 'bolder', fontSize: 44, margin: 0, color: '#4e2e89'}}>
                            {this.state.creditPurchaseSliderValue}
                        </h1>
                        <h2 style={{marginLeft: 10}}>
                            users
                        </h2>

                        <div style={{paddingLeft: 20, paddingRight: 20}}>
                            <p style={{fontSize: 44, margin: 0, color: 'black', opacity: 0.65}}>
                                +
                            </p>
                        </div>

                        <h1 style={{fontWeight: 'bolder', fontSize: 44, margin: 0, color: '#990000'}}>
                            {this.getFreeLiveStreamMinutes()}
                        </h1>
                        <h2 style={{marginLeft: 10}}>
                            <strong>FREE</strong> live stream minutes
                        </h2>
                        <Tooltip color="purple" title={<p>
                            Live stream credits will be expired at the end of every month and will be renewed on bill payment.
                        </p>}><Button style={{marginBottom: 10}} type="link"><QuestionCircleOutlined /></Button></Tooltip>
                    </div>

                    <Slider style={{width: '100%'}} tooltipVisible={false}
                    onChange={value => this.setState({creditPurchaseSliderValue: value})}
                    step={this.props.payInterval} max={this.props.maxPayCredit}
                    value={this.state.creditPurchaseSliderValue} />
                </Card>
            </div>
        )
    }

}

export default GetUserCreditsView;