import React from 'react'
import Page from '../../base/Page';
import withMediaQuery from '../../components/WithMediaQuery';

import {
    AlertOutlined,
    DashboardOutlined,
    QuestionCircleOutlined,
    CreditCardOutlined,
    DollarCircleOutlined,
    HistoryOutlined,
    DollarCircleFilled,
    DashboardFilled,
    ClockCircleFilled,
    BarChartOutlined,
    WarningTwoTone,
    ReloadOutlined,
    ProjectOutlined
} from '@ant-design/icons'
import { Tooltip, Button, Tabs, InputNumber, Spin, Card, Slider, Empty } from 'antd';

import '../../../node_modules/react-vis/dist/style.css';
import {
    XYPlot,
    XAxis,
    YAxis,
    VerticalGridLines,
    HorizontalGridLines,
    VerticalBarSeries,
    VerticalBarSeriesCanvas,
    DiscreteColorLegend,
    FlexibleWidthXYPlot,
    RadialChart
} from 'react-vis';
import UserList from '../../components/UserList';
import GetLiveStreamCreditsView from './GetLiveStreamCreditsView';
import Api from '../../session/Api';
import Util from '../../util/Util';
import SessionManager from '../../session/SessionManager';
  

const { TabPane } = Tabs;

const ValueLabel = ({label, value, subtitle, bold}) => (
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
        <h2 style={{alignSelf: 'flex-start', margin: 0, marginBottom: bold ? -20 : -15, fontSize: bold ? 30 : 22.5}}>{label}</h2>
        <div style={{display: 'flex', alignItems: 'center'}}>
            <h1 style={{fontWeight: bold ? 'bold' : undefined, color: '#990000', margin: 0, fontSize: bold ? 60 : 45}}>{Util.zeroPad(value, 4)}</h1>
            {subtitle !== undefined &&
            <h1 style={{fontWeight: 'bold', color: '#000000', margin: 0, fontSize: bold ? 20 : 15, marginLeft: 5}}>/{subtitle}</h1>}
        </div>
        <h1 style={{margin: 0, marginTop: bold ? -20 : -15, fontSize: bold ? 30 : 22.5}}>minutes</h1>
    </div>
)

class LiveStreamCreditsPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            loading: true,
            error: false,

            usage: null,
        }
    }

    onPageStart() {
        this.setState({loading: true})
        Api.getLiveStreamCreditUsage(response => {
            this.setState({loading: false, error: response.payload === null, usage: response.payload})
        })
    }

    renderWeekUsageChart() {
        const useCanvas = true;
        const BarSeries = useCanvas ? VerticalBarSeriesCanvas : VerticalBarSeries;
        const content = useCanvas ? 'TOGGLE TO SVG' : 'TOGGLE TO CANVAS';
        return (
            <div style={{width: '100%'}}>
                <FlexibleWidthXYPlot
                    xType="ordinal"
                    stackBy="y"
                    //width={300}
                    height={300}
                >
                    <DiscreteColorLegend
                        style={{ position: 'absolute', left: '50px', top: '10px' }}
                        orientation="horizontal"
                        items={[
                            {
                                title: 'Daily Credit',
                                color: '#990000'
                            },
                            {
                                title: 'Monthly Credit',
                                color: '#440000'
                            },
                            {
                                title: 'Prepaid',
                                color: '#1c1c1c'
                            }
                        ]}
                    />
                    <VerticalGridLines />
                    <HorizontalGridLines />
                    <XAxis />
                    <YAxis />

                    {this.state.usage.weekUsageReport.reports.map(report => (
                        <BarSeries
                        cluster="2016"
                        color="#990000"
                        data={report}
                        />
                    ))}
                    
                    
                    {/* <BarSeries
                        cluster="2016"
                        color="#440000"
                        data={[
                            { x: 'Q1', y: 7 },
                            { x: 'Q2', y: 2 },
                            { x: 'Q3', y: 22 },
                            { x: 'Q4', y: 18 }
                        ]}
                    />
                    <BarSeries
                        cluster="2016"
                        color="#1c1c1c"
                        data={[
                            { x: 'Q1', y: 14 },
                            { x: 'Q2', y: 2 },
                            { x: 'Q3', y: 22 },
                            { x: 'Q4', y: 18 }
                        ]}
                    /> */}
                </FlexibleWidthXYPlot>
            </div>
        )
    }

    render() {
        if (this.state.loading) {
            return (
                <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingTop: 45, paddingBottom: 45}}>
                    <Spin size="large" tip="Loading..." />
                </div>
            )
        }

        if (this.state.error) {
            return (
                <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingTop: 45, paddingBottom: 45}}>
                    

                    <WarningTwoTone twoToneColor="#f26622" style={{fontSize: 80}} />
                    <p style={{marginTop: 45, opacity: 0.65}}>An error occurred. Please try again later.</p>

                    <Button icon={<ReloadOutlined />} onClick={() => window.location.reload()}>Reload</Button>
                </div>
            )

        }

        return (
            <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                <div style={{width: this.props.isSmall ? '90vw' : '75vw', paddingTop: '25px', paddingBottom: '25px'}}>
                    <h1><AlertOutlined /> Live Stream Usage</h1>

                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <h2>
                            <DashboardOutlined style={{marginRight: 5}} />
                            Credits
                            <Tooltip color="purple" title={<p>
                                <strong>Live Stream credits are needed to be able to have live streams.</strong>
                                <br />
                                <br />
                                <strong>Daily</strong> credits expire and renew every day.
                                <br />
                                <br />
                                <strong>Monthly</strong> credits expire and renew every month. It may be provided by your account package.
                                <br />
                                <br />
                                <strong>Prepaid</strong> credits never expire. They can be purchased as needed.
                            </p>}><Button type="link"><QuestionCircleOutlined /></Button></Tooltip>
                        </h2>
                        <div style={{flex: 1}} />
                    </div>

                    <div style={{paddingTop: 25, paddingBottom: 25, display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
                        <ValueLabel label="Total Credit" value={this.state.usage.credits.totalMinutes} bold />
                        <ValueLabel label="Daily" value={this.state.usage.credits.dailyMinutes} subtitle={this.state.usage.credits.dailyAllowanceMinutes} />
                        <ValueLabel label="Monthly" value={this.state.usage.credits.monthlyMinutes} subtitle={this.state.usage.credits.monthlyAllowanceMinutes} />
                        <ValueLabel label="Prepaid" value={this.state.usage.credits.prepaidMinutes} />
                    </div>

                    <div style={{height: 25}} />
                    <h2>
                        <CreditCardOutlined style={{marginRight: 5}} />
                        Prepaid
                        <Tooltip color="purple" title={<p>
                            Live Stream credits can be purchased and will only run out after use (no expiration date).
                        </p>}><Button type="link"><QuestionCircleOutlined /></Button></Tooltip>
                    </h2>
                    <div className="left-aligned-tabs">            
                        <GetLiveStreamCreditsView maxPayCredit={this.state.usage.maxPayCredit} payInterval={this.state.usage.payInterval} priceRate={this.state.usage.priceRate} />
                    </div>


                    <div style={{height: 25 + 20}} />
                    {/* <h2><BarChartOutlined style={{marginRight: 5}} /> Week Usage</h2>
                    {this.renderWeekUsageChart()}

                    <div style={{height: 25 + 20}} />
                    {SessionManager.isLoggedInAsOrganization() && <>
                        <h2><BarChartOutlined style={{marginRight: 5}} /> Tutor Week Usage</h2>
                        <div style={{display: 'flex', gap: 15}}>
                            <div style={{flex: 1}}>
                                <UserList showAll />
                            </div>
                            <div style={{width: 400, height: 400, display: 'flex', justifyContent: 'flex-end'}}>
                                <RadialChart
                                showLabels
                                width={350}
                                height={350}
                                data={this.state.usage.weekTutorUsageReport.report}
                                />
                            </div>
                        </div>
                    </>} */}
                </div>
            </div>
        )
    }

}

export default withMediaQuery(LiveStreamCreditsPage);