import React from 'react'
import { Card, Rate, Button, Tag } from 'antd';
import ProfilePicture from '../../components/ProfilePicture';
import Meta from 'antd/lib/card/Meta';

import { Link, useHistory } from 'react-router-dom';
import Api from '../../session/Api';

import NoImage from '../../images/NoImage.png';
import ProductImagePlaceholder from '../../images/ProductImagePlaceholder.png';

import { 
    MessageOutlined,
    UnlockFilled
} from '@ant-design/icons';
import SessionManager from '../../session/SessionManager';
import FrontendAuthModal from '../../components/FrontendAuthModal';
import { ACCOUNT_ROLE_TUTOR } from '../../Constants';

export default function SaleCourseItem(props) {
    const [showAuth, setShowAuth] = React.useState(false)
    const history = useHistory();

    const course = props.course;
    return (
        <div>
            {/*<Link to={'/course-sale/' + course.id}>*/}
                <Card
                    actions={[<Rate disabled value={course.rating} />]}
                    hoverable
                    onClick={() => history.push('/course-sale/' + course.id)}
                    style={{ width: '100%'}}
                    cover={
                    <div>
                        <img src={Api.getCourseThumbnail(course.id)} style={{width: '100%', height: '200px', objectFit: 'cover'}} onError={e => {
                            e.target.onerror = null;
                            e.target.src = ProductImagePlaceholder;
                        }}/>
                        <div style={{display: 'flex'}}>
                            <div style={{height: '61px', width: '61px', marginTop: '-30px', marginLeft: '10px'}}>
                                <ProfilePicture showStatus={course.tutorRole == ACCOUNT_ROLE_TUTOR} size={60} userId={course.tutorId} style={{borderStyle: 'solid'}} style={{marginRight: '-0px'}} />
                            </div>

                            <div style={{flex: '1'}} />

                            <Button type="link" style={{marginTop: '5px'}} onClick={e => {
                                e.stopPropagation();
                                if (SessionManager.isLoggedIn()) {
                                    history.push('/messages?userId=' + course.tutorId);
                                } else {
                                    setShowAuth(true);
                                }
                            }}><MessageOutlined /> Message</Button>
                        </div>
                        
                    </div>}
                    >
                    <Meta style={{marginTop: '-20px'}} title={
                        <>
                            <div class="ant-card-meta-title">{course.title}</div>
                            <h6>By {course.tutorName}</h6>
                            {course.allowNonEnrollmentAccess && <Tag icon={<UnlockFilled />} color="green">Enrollment Not Required</Tag>}
                        </>
                    } description={<p style={{height: 50 - (course.allowNonEnrollmentAccess ? 22 : 0),}}>{course.description}</p>} />
                </Card>
            {/*</Link>*/}
            {showAuth && <FrontendAuthModal visible={showAuth} onCancel={() => setShowAuth(false)} onDonePath={'/messages?userId=' + course.tutorId} />}
        </div>
    )
}