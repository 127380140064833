import React from 'react'
import Page from '../base/Page';
import PostsView from '../views/posts/PostsView';
import SessionManager from '../session/SessionManager';

//import MediaQuery from 'react-responsive'
import withMediaQuery from '../components/WithMediaQuery';

class FollowingPostsPage extends Page {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <div style={{width: this.props.renderFullWidth ? '90vw' : '50vw', marginTop: '25px'}}>
                    {(SessionManager.isLoggedInAsRelationManager() || SessionManager.isLoggedInAsPaymentManager() || SessionManager.isLoggedInAsContentManager()) ? 
                    <PostsView adminPosts={true} canAdd={true} />
                        : 
                    <PostsView realtimeUserStatus 
                    canAdd
                    //canAdd={SessionManager.isLoggedInAsTutor()} 
                    followingPosts />}
                </div>
            </div>
        );
    }

}

export default withMediaQuery(FollowingPostsPage);