import React from 'react'
import Page from '../../base/Page';

import {
    UserOutlined
} from '@ant-design/icons';
import { Link, withRouter } from 'react-router-dom';
import { Button } from 'antd';
import FooterView from '../../views/public-views/FooterView';

import StillHeaderAnim from '../../images/StillHeaderAnim.png';
import TutorVid from '../../images/TutorVid.mp4';
import Bg2 from '../../images/Bg2.jpg';

class BecomeATutorPage extends Page {

    getContent() {
        return (<>
            <br/>
            <h2>Tutor Guidelines</h2>
            <p>
                You can join Learnerix as tutor at any time for free. Simply <Link to="/auth?registerType=1">click here</Link> and create your account.
            Select a topic that you are professionally skilled at and create your course based on that.
            Select a reasonable price for your course based on market value. You can utilize your feed on Learnerix and start socializing with other members.
            You can promote your course on other social media platforms to increase your audience.
            For a more detailed tutorial on how to setup your course <a href="https://www.youtube.com/watch?v=DmdfXOrh8MI">click here.</a>
            </p>

            <br />
            <div>

            </div>
            <video src={TutorVid} style={{width: '100%', height: '300px', objectFit: !this.state.played && "cover"}} className="postered-video" controls poster={Bg2} 
            onPlay={() => this.setState({played: true})} />
            <br/>

            <br/>
            <h2>How to create professional video content: </h2>
            <p>Set up a professional background with good lighting. Choose a reasonable camera that can record at HD (1080x1920) resolution or higher. Make sure there are no distractions around you. Use microphones for optimal audio quality. If you are being presented, wear a suitable attire and adhere to professionalism. If you are using a smartphone you must record your videos horizontally and at highest resolution your smartphone is capable of. Use a video editing software to edit and demonstrate your content. Our quality assurance team will review your videos before publishing them. </p>

            <br/>
            <h2>Course structure:</h2>
            <p>Each course has to be minimum 45 minutes in duration with minimum 4 chapters (recorded videos). The course must include:</p>
            <ul>
                <li>a title</li>
                <li>a short description describing what the course is about</li>
                <li>a reasonable price</li>
                <li>gallery with few photos and videos</li>
                <li>a selected category.</li>
                <li>Each chapter must also have a title and description with videos related to that chapter.</li>
                <li>At the end of each chapter, a small quiz or questionnaire needs to be put in place to track the learner’s progression.</li>
            </ul>

            <br/>
            <h2>Copyrighted content:</h2>
            <p>
            Learnerix strictly prohibit the use of copyrighted materials by tutors. Tutors must not use any third party content in their course material such as image, video, sound, music, logo etc. that does not belong to them. Learnerix takes no responsibility in breach of copyright violation by users. If a tutor is reported for using unlicensed copyrighted content, Learnerix reserves the right to remove the course and its material.                
            </p>

            <br/>
            <h2>How to become a verified tutor?</h2>
            <p>Tutors have the option to become verified and receive a blue tick on their profile. The verification indicates that the tutor has one or more academic degrees in relation to their course or proven record of years of experience. Tutors can apply for verification by submitting their academic degrees and identification documents to their relation manager. </p>

            <br/>
            <h2>User sensitive content: </h2>
            <p>Tutors are advised to follow user sensitive content guidelines and conduct respectful and professional sessions at all times.<br />Tutors must: </p>
            - Avoid the use of cultural sensitive and racially inappropriate content and subjects 
            <br/>
            - Avoid swearing and cursing in their videos and posts 
            <br/>
            - Avoid the use of alcohol, drugs and tobacco in videos. 
            <br/>
            - Make sure their content is suitable for everyone.
            <br/>
        </>)
    }

    render() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{width: '100%', height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <img src={StillHeaderAnim} style={{width: '100%', height: '150px', objectFit: 'cover', position: 'absolute', zIndex: -1}} />
                    <h1 style={{ marginTop: '25px', marginBottom: '25px', color: 'white' }}><UserOutlined /> Become a tutor</h1>
                </div>
                <div style={{ width: '75vw', marginBottom: '25px' }}>
                    {this.getContent()}
                </div>
                <FooterView />
            </div>
        )
    }

}

export default withRouter(BecomeATutorPage);