import React, { useEffect } from 'react'
import Modal from 'antd/lib/modal/Modal'
import { Button, Popconfirm, Spin, Modal as ModalUtil } from 'antd'

import LogoWhite from '../images/LogoWhite.png';
import LiveIcon from '../images/LiveIcon.png';

import { 
    CloseOutlined,
    CreditCardOutlined,
    LoadingOutlined
} from '@ant-design/icons';
import Api from '../session/Api';
import Util from '../util/Util';
import UIUtil from '../util/UIUtil';
import SubscriptionPayment from './payment-system/SubscriptionPayment';
import SessionManager from '../session/SessionManager';

export default function LiveSubscriptionDialog(props) {
    const [initialLoading, setInitialLoading] = React.useState(true);
    const [subscription, setSubscription] = React.useState();

    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        Api.getLiveSubscription(response => {
            setSubscription(response.status === true ? response.payload : undefined)
            setInitialLoading(false);
        })
    }, [])

    const onSubscribeBtn = () => {
        setLoading(true);
        fetch(Api.getServerIp() + '/api/student/live-subscription/finalize-subscription/' + 'LNXLTFS21', {
            method: 'get',
            headers: {
                "Authorization": "Bearer " + SessionManager.getAccount().token
            }
        })
        .then(res => res.json())
        .then(response => {
            if (response.status === true) {
                onSubscribeSuccess();
            } else {
                UIUtil.showError();
            }
            setLoading(false);
        });
    }

    const onSubscribeSuccess = () => {
        ModalUtil.success({
            title: "Success!",
            content: "You have subscribed to Learnerix Live!",
            onOk: () => window.location.reload()
        })
    }

    const onUnsubscribeBtn = () => {
        setLoading(true);

        Api.cancelLiveSubscription(response => {
            setLoading(false);
            if (response.status === true) {
                ModalUtil.success({
                    title: "Success",
                    content: "You have unsubscribed from Learnerix Live.",
                    onOk: () => window.location.reload()
                })
            } else {
                UIUtil.showError();
            }
        }) 
    }

    return (
        <Modal footer={null} bodyStyle={{padding: 0, background: 'black'}} closeIcon={<CloseOutlined style={{color: 'white'}} />} {...props}>
            <div style={{width: '100%', backgroundColor: '#2d3436', backgroundImage: 'linear-gradient(315deg, #2d3436 0%, #000000 74%)',
            borderRadius: '5px', display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '50px', paddingBottom: '50px'}}>
                <img src={LogoWhite} style={{width: '125px'}} />
                <img src={LiveIcon} style={{width: '30px'}} />

                <div style={{height: '40px'}} />

                <h3 style={{color: 'white'}}>Be in class, be home</h3>

                <p style={{padding: '10px', paddingLeft: '25px', paddingRight: '25px', color: 'rgb(187,187,187)', fontSize: '12px', textAlign: 'center',overflowWrap: 'break-word', whiteSpace: 'pre-line'}}>
                    Get unlimited access to all public Courses and Live Sessions available on Learnerix. Aid your studies with extra help by using Help Sessions and Live Streams in courses that offer Live Support.
                </p>

                <div style={{height: '25px'}} />

                {!initialLoading ? <>
                    {subscription !== undefined ? (<>
                        <p style={{color: 'rgb(187,187,187)', fontSize: '10px', textAlign: 'center', overflowWrap: 'break-word', whiteSpace: 'pre-line'}}>Subscribed on {Util.getDate(subscription.dateCreated)}</p>
                        <Popconfirm loading={loading} title="Are you sure you want to cancel your subscription? You will lose all subscription benefits immediately." onConfirm={() => onUnsubscribeBtn()}>
                            <Button loading={loading} size="medium" type="primary" className="red-button"><CloseOutlined /> Cancel Subscription</Button>
                        </Popconfirm>
                    </>) : (<>
                        {/*<p style={{color: 'rgb(187,187,187)', fontSize: '10px', textAlign: 'center', overflowWrap: 'break-word', whiteSpace: 'pre-line'}}>9.99 USD / month</p>*/}
                        <p style={{color: 'rgb(187,187,187)', fontSize: '10px', textAlign: 'center', overflowWrap: 'break-word', whiteSpace: 'pre-line'}}>Free for a limited time!</p>
                        {/*<Button onClick={() => onSubscribeBtn()} loading={loading} size="medium" type="primary" className="red-button"><CreditCardOutlined /> Subscribe</Button>*/}
                        <Button onClick={() => onSubscribeBtn()} loading={loading} size="medium" type="primary" className="red-button">Subscribe for free</Button>
                        {/*<SubscriptionPayment darkMode onSubscribeSuccess={onSubscribeSuccess} />*/}
                    </>)}
                </> : <Spin indicator={<LoadingOutlined style={{ fontSize: 30, color: '#990000' }} spin />} style={{color: '#990000'}} /> }

            </div>
        </Modal>
    )
}