export const GOOGLE_PLAY_APP_LINK = "https://play.google.com/store/apps/details?id=com.learnerix";
export const APP_STORE_APP_LINK = "https://apps.apple.com/app/learnerix/id1548332261";

export const PAGINATION_PAGE_CONTENT_COUNT = 16;
export const NO_PAGE_TOKEN = "no-page";

export const ACCOUNT_SCOPE_BACKEND = 0;
export const ACCOUNT_SCOPE_FRONTEND = 1;

export const ACCOUNT_ROLE_ADMIN = 0;
export const ACCOUNT_ROLE_STUDENT = 1;
export const ACCOUNT_ROLE_TUTOR = 2;
export const ACCOUNT_ROLE_ORGANIZATION = 3;
export const ACCOUNT_ROLE_RELATION_MANAGER = 4;
export const ACCOUNT_ROLE_PAYMENT_MANAGER = 5;
export const ACCOUNT_ROLE_BLOGGER = 6;
export const ACCOUNT_ROLE_CONTENT_MANAGER = 7;

export const POST_USER_ID_ADMIN = "ADMIN"; //-1
export const ON_HOLD_DURATION_DAYS = 3;

export const POST_PARENT_TYPE_PROFILE = 0;
export const POST_PARENT_TYPE_COURSE = 1;
export const POST_PARENT_TYPE_RATING = 2;
export const POST_PARENT_TYPE_LIVE_SESSION = 3;

export const POST_TYPE_TEXT = 0;
export const POST_TYPE_IMAGE = 1;
export const POST_TYPE_VIDEO = 2;
export const POST_TYPE_GALLERY = 3;

export const ITEM_LIKE_PARENT_TYPE_POST = 0;
export const ITEM_LIKE_PARENT_TYPE_REVIEW = 1;
export const ITEM_LIKE_PARENT_TYPE_BLOG_POST = 2;

export const COMMENT_PARENT_TYPE_POST = 0;
export const COMMENT_PARENT_TYPE_REVIEW = 1;
export const COMMENT_PARENT_TYPE_BLOG_POST = 2;

export const ROOM_PARENT_TYPE_PROFILE = 0;
export const ROOM_PARENT_TYPE_LIVE_STREAM = 1;
export const ROOM_PARENT_TYPE_HELP_SESSION = 2;

export const MESSAGE_TYPE_TEXT = 0;
export const MESSAGE_TYPE_ATTACHMENT = 1;

export const MESSAGE_ATTACHMENT_TYPE_AUDIO = 0;
export const MESSAGE_ATTACHMENT_TYPE_IMAGE = 1;
export const MESSAGE_ATTACHMENT_TYPE_VIDEO = 2;
export const MESSAGE_ATTACHMENT_TYPE_FILE = 3;

export const SOCKET_EVENT_CHAT = "/user/queue/chat";
export const SOCKET_EVENT_WEB_RTC_SIGNALLING = "/user/queue/web-rtc-signaling"
export const SOCKET_EVENT_LIVE_STREAM_SIGNALLING = "/queue/live-stream-signaling"
export const SOCKET_EVENT_USER_LIVE_STREAM_SIGNALLING = "/user/queue/user-live-stream-signaling"
export const SOCKET_EVENT_HELP_SESSION_STATUS = "/user/queue/help-session-status"
export const SOCKET_EVENT_PRESENCE = "/queue/presence"
export const SOCKET_EVENT_NOTIFICATION = "/user/queue/notification"

export const COURSE_SECTION_TYPE_CUSTOM = 0;
export const COURSE_SECTION_TYPE_LECTURE = 1;
export const COURSE_SECTION_TYPE_HELP_SESSION = 2;
export const COURSE_SECTION_TYPE_LIVE_SESSION = 3;

export const COURSE_ITEM_TYPE_LECTURE_VIDEO = 0;
export const COURSE_ITEM_TYPE_LECTURE_AUDIO = 1;
export const COURSE_ITEM_TYPE_FILE = 2;
export const COURSE_ITEM_TYPE_QUIZ = 3;
export const COURSE_ITEM_TYPE_FLASHCARD = 4;
export const COURSE_ITEM_TYPE_SPELL_CHECK = 5;
export const COURSE_ITEM_TYPE_HELP_SESSION_LIVE = 6;

export const GALLERY_ITEM_TYPE_IMAGE = 0;
export const GALLERY_ITEM_TYPE_VIDEO = 1;

export const RATING_ITEM_TYPE_TUTOR = 0;
export const RATING_ITEM_TYPE_COURSE = 1;
export const RATING_ITEM_TYPE_LIVE_SESSION = 2;

export const HELP_SESSION_STATUS_PENDING_REQUEST_SEND = 0;
export const HELP_SESSION_STATUS_AWAITING_CONFIRM = 1;
export const HELP_SESSION_STATUS_ACTIVE = 2;
export const HELP_SESSION_STATUS_DONE = 3;

/*
public static final int PRESENCE_AVAILABLE = 0;
    public static final int PRESENCE_AWAY = 1;
    public static final int PRESENCE_DO_NOT_DISTURB = 2;
    public static final int PRESENCE_OFFLINE = 3;
*/

export const PRESENCE_AVAILABLE = 0;
export const PRESENCE_AWAY = 1;
export const PRESENCE_DO_NOT_DISTURB = 2;
export const PRESENCE_OFFLINE = 3;

export const PRODUCT_TYPE_COURSE = 0;
export const PRODUCT_TYPE_LIVE_SESSION = 1;

//public static final int DIRECTION_USER_TO_ORGANIZATION = 0;
//public static final int DIRECTION_ORGANIZATION_TO_USER = 1;

export const ENROLLMENT_INVITATION_DIRECTION_USER_TO_ORGANIZATION = 0;
export const ENROLLMENT_INVITATION_DIRECTION_ORGANIZATION_TO_USER = 1;

/*
public static final int PACKAGE_BRONZE = 0;
    public static final int PACKAGE_SILVER = 1;
    public static final int PACKAGE_GOLD = 2;
    public static final int PACKAGE_PLATINUM = 3;
*/

export const ORGANIZATION_PACKAGE_BRONZE = 0;
export const ORGANIZATION_PACKAGE_SILVER = 1;
export const ORGANIZATION_PACKAGE_GOLD = 2;
export const ORGANIZATION_PACKAGE_PLATINUM = 3;
export const ORGANIZATION_PACKAGE_BLANK = 4;

export const API_ERROR_MESSAGE_ORGANIZATION_TRIAL_OVER = "organization_account_trial_over";
export const API_ERROR_MESSAGE_SUBSCRIPTION_REQUIRED = "subscription_required";
export const API_ERROR_MESSAGE_FEATURED_CONTENT_EXCEEDED_MAX = "featured_content_count_exceeded";

export const API_ERROR_PAYLOAD = "maximum_upload_size_exceeded";

export const NOTIFICATION_PAYLOAD_TYPE_USER = 1;
export const NOTIFICATION_PAYLOAD_TYPE_COURSE = 2;
export const NOTIFICATION_PAYLOAD_TYPE_LIVE_SESSION = 3;

export const NOTIFICATION_EVENT_TYPE_NOTIFICATION = 0;
export const NOTIFICATION_EVENT_TYPE_UNREAD_COUNT_UPDATE = 1;

export const NOTIFICATION_CLICK_EVENT_NONE = 0;
export const NOTIFICATION_CLICK_EVENT_POST = 1;
export const NOTIFICATION_CLICK_EVENT_PROFILE = 2;
export const NOTIFICATION_CLICK_EVENT_BALANCE_PAGE = 3;
export const NOTIFICATION_CLICK_EVENT_MESSAGE = 4;
export const NOTIFICATION_CLICK_EVENT_LIVE_STREAM = 5;

export const STATEMENTS_DATA_SOURCE_ALL = 0;
export const STATEMENTS_DATA_SOURCE_EARNINGS = 1;
export const STATEMENTS_DATA_SOURCE_PURCHASES = 2;
export const STATEMENTS_DATA_SOURCE_WITHDRAWALS = 3;

export const STATEMENTS_TYPE_EARNING = 0;
export const STATEMENTS_TYPE_WITHDRAW = 1;
export const STATEMENTS_TYPE_SPENDING = 2;

export const STATEMENTS_SOURCE_TYPE_SUBSCRIPTION = 1;
export const STATEMENTS_SOURCE_TYPE_PURCHASE = 0;

export const STATEMENTS_PAYMENT_METHOD_LEARNERIX_BALANCE = 1;
export const STATEMENTS_PAYMENT_METHOD_PAY_PAL = 0;

export const BLOG_POST_SOURCE_UNPUBLISHED = -1;
export const BLOG_POST_SOURCE_HOME_ALL = 0;


export const LIVE_STREAM_PARENT_TYPE_LIVE_SESSION_SCHEDULE = 0;
export const LIVE_STREAM_PARENT_TYPE_HELP_SESSION_LIVE = 1;
export const LIVE_STREAM_PARENT_TYPE_ACCOUNT = 2;
export const LIVE_STREAM_PARENT_TYPE_COURSE_SCHEDULE = 3;