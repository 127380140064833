import { Button, Empty, Spin } from 'antd';
import React from 'react'
import { LIVE_STREAM_PARENT_TYPE_ACCOUNT, LIVE_STREAM_PARENT_TYPE_COURSE_SCHEDULE, LIVE_STREAM_PARENT_TYPE_HELP_SESSION_LIVE, LIVE_STREAM_PARENT_TYPE_LIVE_SESSION_SCHEDULE } from '../../Constants';
import Api from '../../session/Api';
import UIUtil from '../../util/UIUtil';

import NoImage from '../../images/NoImage.png'
import ProfilePicture from '../../components/ProfilePicture';
import ProductImagePlaceholder from '../../images/ProductImagePlaceholder.png';

import {
    AlertOutlined
} from '@ant-design/icons';
import Countdown from 'antd/lib/statistic/Countdown';
import Util from '../../util/Util';
import { useHistory } from 'react-router-dom';

const Header = ({ title }) => (
    <div style={{display: 'flex', alignItems: 'center', marginBottom: 5}}>
        <div style={{width: 10, height: 1, background: 'black', opacity: 0.15}} />
        <p style={{opacity: 0.65, fontSize: 12, margin: 0, marginLeft: 5, marginRight: 5}}>{title}</p>
        <div style={{flex: 1, height: 1, background: 'black', opacity: 0.15}} />
    </div>
)

const Item = ({ liveStream }) => {
    const history = useHistory();

    let thumbnail;
    let parentName;
    switch (liveStream.parentType) {
        case LIVE_STREAM_PARENT_TYPE_LIVE_SESSION_SCHEDULE:
            thumbnail = Api.getLiveSessionThumbnail(liveStream.parentDisplayId);
            parentName = "Live Session";
            break;
        case LIVE_STREAM_PARENT_TYPE_HELP_SESSION_LIVE:
            thumbnail = Api.getCourseThumbnail(liveStream.parentDisplayId);
            parentName = "Help Session";
            break;
        case LIVE_STREAM_PARENT_TYPE_ACCOUNT:
            thumbnail = Api.getProfilePic(liveStream.parentDisplayId);
            break;
        case LIVE_STREAM_PARENT_TYPE_COURSE_SCHEDULE:
            thumbnail = Api.getCourseThumbnail(liveStream.parentDisplayId);
            parentName = "Course";
            break;
    }

    return (
        <div onClick={() => {
            if (!liveStream.upcoming) {
                history.push(Util.getLiveStreamPath(liveStream.liveStreamId))
            }
        }} style={{marginBottom: '15px', boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)", borderRadius: '5px', padding: '15px', cursor: !liveStream.upcoming ? 'pointer' : undefined, 
        display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
            <div style={{ display: 'flex', alignItems: 'flex-start', width: '100%' }}>
                <div style={{ width: 60, height: 60, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                    <img src={thumbnail} style={{ width: 65, height: 65, borderRadius: 5, objectFit: 'cover' }} onError={e => {
                        e.target.onerror = null;
                        e.target.src = liveStream.parentType == LIVE_STREAM_PARENT_TYPE_ACCOUNT ? NoImage : ProductImagePlaceholder;
                    }} />
                    {liveStream.parentType != LIVE_STREAM_PARENT_TYPE_ACCOUNT && <div style={{ position: 'absolute', bottom: -8, right: -10 }}>
                        <ProfilePicture src={Api.getProfilePic(liveStream.ownerId)} size={25} style={{ borderWidth: 0.5, borderColor: 'white', border: 'solid' }} />
                    </div>}
                </div>

                <div style={{ flex: 1, marginLeft: 15 }}>
                    {Util.isStringExists(parentName) && <p style={{margin: 0, marginBottom: -5, fontSize: 12, opacity: 0.65}}>{parentName}</p>}
                    <h4 style={{ margin: 0 }}>{liveStream.parentDisplayName}</h4>
                    <p style={{ margin: 0 }}>{liveStream.parentType != LIVE_STREAM_PARENT_TYPE_ACCOUNT ? liveStream.ownerName : 'Live Stream'}</p>
                </div>
            </div>

            <div style={{display: 'flex', alignItems: 'center', width: '100%', marginTop: 10}}>
                {!liveStream.upcoming && 
                <p style={{flex: 1, margin: 0, fontSize: 12}}>{Util.formatSecondsBrief(liveStream.timeLeftToEnd / 1000)} remaining</p>}

                {liveStream.upcoming && <div style={{flex: 1}} />}

                <Button type="link" className={!liveStream.upcoming && "red-link"} style={{ cursor: !liveStream.upcoming ? 'pointer' : undefined, display: 'flex', padding: 0, alignItems: 'center' }}>
                    {liveStream.upcoming ? (<>
                        <Countdown style={{ marginLeft: '15px' }}
                        value={liveStream.startDate} valueStyle={{ fontSize: '15px', color: '#990000' }}
                        onFinish={() => { }} />
                    </>) : (<>
                        <AlertOutlined style={{marginRight: 5}} /> Active
                    </>)}
                </Button>
            </div>
        </div>
    )
}


class UpcomingLiveStreamsView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            liveStreams: []
        }
    }

    componentDidMount() {
        this.setState({ loading: true })
        Api.getUpcomingAndActiveLiveStreams(response => {
            if (response.status === true) {
                this.setState({ loading: false, liveStreams: response.payload })
            } else {
                UIUtil.showError();
            }
        })
    }

    render() {
        return (
            <div style={{ height: 400, width: 325, overflow: 'auto' }}>
                {this.state.loading ? <div style={{ display: 'flex', justifyContent: 'center', marginTop: '45px', marginBottom: '45px' }}>
                    <Spin size="large" tip="Loading..." />
                </div> : (<>
                    <div style={{ padding: 15 }}>
                        {this.state.liveStreams.filter(liveStream => !liveStream.upcoming).length > 0 && <>
                            <Header title="Active" />
                            {this.state.liveStreams.filter(liveStream => !liveStream.upcoming).map(liveStream => <Item liveStream={liveStream} />)}
                        </>}

                        {this.state.liveStreams.filter(liveStream => liveStream.upcoming).length > 0 && <>
                            <Header title="Upcoming" />
                            {this.state.liveStreams.filter(liveStream => liveStream.upcoming).map(liveStream => <Item liveStream={liveStream} />)}
                        </>}
                    </div>

                    {this.state.liveStreams.length == 0 ? <Empty style={{ marginTop: '50px' }} image={Empty.PRESENTED_IMAGE_SIMPLE} description={"No upcoming or active streams"} /> : null}
                </>)}
            </div>
        )
    }

}

export default UpcomingLiveStreamsView;