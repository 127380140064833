import Stream from "./Stream";
import { STREAM_TYPE_STAGE_WHITEBOARD, STREAM_TYPE_WHITEBOARD } from "./StreamTypes";


class StreamManager {

    /**
     * @type {Map<String, Stream>}
     */
    outputStreams = new Map();

    /**
     * @type {Map<String, MediaStream>}
     */
    inputRawStreams = new Map();

    /**
     * @type {String}
     */
    broadcasterUserId

    constructor(onNewRawStreamListener) {
        this.onNewRawStreamListener = onNewRawStreamListener;
    }

    /**
     * @returns {Stream}
     */
    async createStream(streamType, options) {
        this.closeStream(streamType);

        const stream = new Stream(streamType);
        if (options !== undefined && (streamType == STREAM_TYPE_WHITEBOARD || streamType == STREAM_TYPE_STAGE_WHITEBOARD) && options.whiteboardCanvas !== undefined) {
            stream.whiteboardCanvas = options.whiteboardCanvas;
            if (streamType == STREAM_TYPE_STAGE_WHITEBOARD) {
                stream.whiteboardCanvasBackgroundStream = this.inputRawStreams.get(this.broadcasterUserId + STREAM_TYPE_WHITEBOARD);
            }
        }
        await stream.start();

        this.outputStreams.set(streamType, stream);
        
        return stream;
    }

    onInputRawStream(userId, userFullName, userIsHost, streamType, rawStream) {
        if (streamType == STREAM_TYPE_STAGE_WHITEBOARD) {
            const whiteboardStream = this.outputStreams.get(STREAM_TYPE_WHITEBOARD);
            if (whiteboardStream !== undefined) {
                whiteboardStream.setWhiteboardCanvasBackgroundStream(rawStream)
            }
        }

        this.inputRawStreams.set(userId + streamType, rawStream);
        this.onNewRawStreamListener(userId, userFullName, userIsHost, streamType, rawStream);
        //remember below
    }

    closeStream(streamType) {
        const stream = this.outputStreams.get(streamType);
        if (stream === undefined) {
            return;
        }

        stream.close();
        this.outputStreams.delete(streamType);
    }

    closeInputRawStream(userId, streamType) {
        if (streamType == STREAM_TYPE_STAGE_WHITEBOARD) {
            const whiteboardStream = this.outputStreams.get(STREAM_TYPE_WHITEBOARD);
            if (whiteboardStream !== undefined) {
                whiteboardStream.setWhiteboardCanvasBackgroundStream(undefined)
            }
        }

        const rawStream = this.inputRawStreams.get(userId + streamType);
        if (rawStream === undefined) {
            return;
        }

        rawStream.getTracks().forEach(track => track.stop())
        this.inputRawStreams.delete(userId + streamType);
        this.onNewRawStreamListener(userId, undefined, undefined, streamType, undefined);
        //remember above
    }

    closeAllStreams() {
        this.outputStreams.forEach(stream => stream.close());
        this.inputTracks.forEach(track => track.stop());

        this.outputStreams.clear();
        this.inputTracks.clear();
    }

}

export default StreamManager;