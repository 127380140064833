import { SOCKET_EVENT_LIVE_STREAM_SIGNALLING, SOCKET_EVENT_USER_LIVE_STREAM_SIGNALLING } from "../../Constants";
import SocketSession from "../../session/SocketSession";

class LiveStreamSignaling {

    queue = [];
    isListening = false;

    constructor(liveStreamId) {
        this.liveStreamId = liveStreamId;
        this.liveStreamSubscriptionEvent = SOCKET_EVENT_LIVE_STREAM_SIGNALLING + "-" + this.liveStreamId;
    }

    start(signalingListener, listener) {
        this.signalingListener = signalingListener;

        this.liveStreamEventListener = this.onLiveStreamEvent.bind(this);
        this.userLiveStreamEventListener = this.onUserLiveStreamEvent.bind(this);

        SocketSession.registerListener(this.liveStreamSubscriptionEvent, this.liveStreamEventListener);
        SocketSession.registerListener(SOCKET_EVENT_USER_LIVE_STREAM_SIGNALLING, this.userLiveStreamEventListener);

        SocketSession.subscribe(this.liveStreamSubscriptionEvent, subscription => {
            this.liveStreamSubscription = subscription;

            SocketSession.subscribe(SOCKET_EVENT_USER_LIVE_STREAM_SIGNALLING, userSubscription => {
                this.userLiveStreamSubscription = userSubscription;

                //TODO handle subscribe error
                listener(true);
            })
        })
    }

    startListening() {
        this.queue.forEach(queue => this.signalingListener(queue.userEvent, queue.event))
        this.queue.length = 0;
        this.isListening = true;
    }

    onLiveStreamEvent(event) {
        if (this.isListening) {
            this.signalingListener(false, event);
        } else {
            this.queue.push({ userEvent: false, event })
        }
    }

    onUserLiveStreamEvent(event) {
        if (this.isListening) {
            this.signalingListener(true, event);
        } else {
            this.queue.push({ userEvent: true, event })
        }
    }

    stop() {
        if (this.liveStreamEventListener !== undefined) {
            SocketSession.unregisterListener(this.liveStreamSubscriptionEvent, this.liveStreamEventListener);
            this.liveStreamEventListener = undefined;
        }
        if (this.userLiveStreamEventListener !== undefined) {
            SocketSession.unregisterListener(SOCKET_EVENT_USER_LIVE_STREAM_SIGNALLING, this.userLiveStreamEventListener);
            this.userLiveStreamEventListener = undefined;
        }

        if (this.liveStreamSubscription !== undefined) {
            this.liveStreamSubscription.unsubscribe();
            this.liveStreamSubscription = undefined;
        }
        if (this.userLiveStreamSubscription !== undefined) {
            this.userLiveStreamSubscription.unsubscribe();
            this.userLiveStreamSubscription = undefined;
        }
    }

}

export default LiveStreamSignaling;