import React from 'react';
import Page from '../../base/Page';

import { Tabs, Button, Menu, Input, Upload, InputNumber, Checkbox, Alert, Popconfirm, Popover } from 'antd';

import './student-live-session-page.less'

import {  
    UnorderedListOutlined,
    EditOutlined,
    VideoCameraOutlined,
    QuestionCircleOutlined,
    EyeInvisibleOutlined,
    QuestionOutlined,
    AlertOutlined,
    AuditOutlined,
    CloseOutlined,
    CreditCardOutlined,
    FileOutlined,
    RightOutlined,
    CalendarOutlined,
    LockFilled
} from '@ant-design/icons';
import PostsView from '../../views/posts/PostsView';
import { withRouter, Link } from 'react-router-dom';
import RateChart from '../ratings-page/RateChart';
import Api from '../../session/Api';
import Error404Page from '../Error404Page';
import Util from '../../util/Util';
import { Tag, Tooltip } from 'antd';

import { Select } from 'antd';
import { CATEGORIES } from '../../Categories';
import TagBox from '../../components/TagBox';
import UIUtil from '../../util/UIUtil';
import { motion } from 'framer-motion';
import { API_ERROR_MESSAGE_SUBSCRIPTION_REQUIRED, GALLERY_ITEM_TYPE_IMAGE, GALLERY_ITEM_TYPE_VIDEO } from '../../Constants';
import Modal from 'antd/lib/modal/Modal';

import NoImage from '../../images/NoImage.png';
//import LiveSessionSectionView from '../../views/liveSession-section/LiveSessionSectionView';
import { getFieldId } from 'antd/lib/form/util';
import LiveStreamScheduleState from '../../components/LiveStreamScheduleState';
import SchedulePreviewListView from '../../views/live-session-schedule/SchedulePreviewListView';
import withMediaQuery from '../../components/WithMediaQuery';
import LiveSubscriptionDialog from '../../components/LiveSubscriptionDialog';

import LogoWhite from '../../images/LogoWhite.png';
import LiveIcon from '../../images/LiveIcon.png';

const { Option } = Select;
const { TabPane } = Tabs;
const { SubMenu } = Menu;

const TabTitle = ({icon, title}) => {
    return (
        <span style={{height: '35px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            {icon}
            {title}
        </span>
    )
}

const DetailsTabTitle = ({icon, title}) => {
    return (
        <span>
            {icon}
            {title}
        </span>
    )
}

const ContentSectionTabTitle = ({icon, title, hidden}) => {
    return (
        <span style={{height: '35px', width: '250px', display: 'flex', alignItems: 'center'}}>
            {icon}
            {title}

            <div style={{flex: 1}} />
            {hidden && <Button type="text" style={{width: 25}}>{<EyeInvisibleOutlined />}</Button>}
        </span>
    )
}

const FormPadding = () => {
    return ( <> <br /> <br /> </> )
}

class StudentLiveSessionPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            detailsTab: "store-listing",
            liveSession: {},
            liveSessionDoesNotExist: false,
            liveSessionRequiresSubscription: false,

            showHiddenItems: false,
            showSubscribeDialog: false,
        }
    }

    getLiveSessionId() {
        return this.props.match.params.liveSessionId;
    }

    onPageStart() {
        Api.getStudentLiveSession(this.getLiveSessionId(), response => {
            if (response.status === true) {
                this.setLiveSession(response.payload)
            } else {
                if (response.message == API_ERROR_MESSAGE_SUBSCRIPTION_REQUIRED) {
                    this.setState({liveSessionRequiresSubscription: true});
                } else {
                    this.setState({liveSessionDoesNotExist: true});
                }
            }
        })
    }

    setLiveSession(liveSession, extra) {
        this.setState({
            ...(extra !== undefined ? extra : {}),
            liveSession: liveSession,
        })
    }

    onChangeTab(tab) {
        this.props.history.push(this.props.location.pathname + "?tab=" + tab);
    }

    render() {
        if (this.state.liveSessionRequiresSubscription) {
            return (
                <div style={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                    <LockFilled style={{fontSize: 75}} />
                    <h2 style={{margin: 0, marginTop: 15}}>Subscription required in order to continue</h2>
                    <p>Subscribe again in order to regain Live Session access</p>

                    <div style={{height: 25}} />

                    <div style={{width: 200, height: '70px', backgroundColor: '#2d3436', backgroundImage: 'linear-gradient(315deg, #2d3436 0%, #000000 74%)',
                    borderRadius: '5px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}} onClick={() => this.setState({showSubscribeDialog: true})}>
                        <img src={LogoWhite} style={{width: '100px'}} />
                        <img src={LiveIcon} style={{width: '25px'}} />
                    </div>

                    <LiveSubscriptionDialog visible={this.state.showSubscribeDialog} onCancel={() => this.setState({showSubscribeDialog: false})} />
                </div>
            )
        }

        if (this.state.liveSessionDoesNotExist) {
            return (
                <Error404Page {...this.props} customMessage="Live Session does not exist" />
            )
        }

        return (
            <div style={{height: '100%'}}>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: '45px', paddingRight: '45px', marginTop: '30px', marginBottom: '12px'}}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <h1 style={{height: '34px'}}>{this.state.liveSession.title}</h1>
                        {Util.isStringExists(this.state.liveSession.hashtag) &&
                        <Link style={{marginTop: -10, fontSize: 14}} target="_blank" to={`/hashtag/${this.state.liveSession.hashtag}`}>#{this.state.liveSession.hashtag}</Link>}
                    </div>
                    
                    {!this.state.liveSession.published && this.state.liveSession.hasAtLeastOneStudent &&
                    <Tooltip title="This live session can still be accessed by the learners that own it"><Button type="link"><AuditOutlined /></Button></Tooltip>}

                    <div style={{flex: 1}} />

                    {Util.isStringExists(this.state.liveSession.id) && <>
                        <LiveStreamScheduleState state={this.state.liveSession.upcomingState} onStateUpdate={() => this.setState({upcomingStateInProgress: true})}/>
                        {//(this.state.upcomingStateInProgress || ((this.state.liveSession.upcomingState.startDate - (this.state.liveSession.loungeDuration * 60000)) < new Date().getTime())) &&
                        (this.state.upcomingStateInProgress || this.state.liveSession.upcomingState.inProgress) &&
                        <Link to={Util.getLiveStreamPath(this.state.liveSession.upcomingState.liveStreamId)}>
                            <Button type="primary" className="red-button" size="large" style={{marginLeft: '15px'}}>
                                {/* {this.state.upcomingStateInProgress ? 'Join Live Stream' : 'Join Live Stream Lounge'} <RightOutlined /> */}
                                Join Live Stream <RightOutlined />
                            </Button>
                        </Link>}
                    </>}

                    {/*this.state.liveSession.subscribed ? (
                        <Button type="primary" size="large"><QuestionCircleOutlined /> Help Session</Button>
                    ) : (this.state.liveSession.allowSubscriptions &&
                        <Button type="primary" size="large" className="red-button"><CreditCardOutlined /> Subscribe - {this.state.liveSession.subscriptionPrice + " USD monthly"}</Button>
                    )*/}
                </div>
                <Tabs activeKey={this.state.pathParams.tab} size="large" style={{width: '100%',}} type="card" onChange={this.onChangeTab.bind(this)} className="student-live-session-page-tabs">
                    <TabPane tab={<TabTitle icon={<CalendarOutlined />} title={'Schedule'} />} key="schedule">
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <div style={{width: this.props.renderFullWidth ? '90vw' : '75vw', paddingTop: '25px', paddingBottom: '25px'}}>
                                <SchedulePreviewListView recorded={this.state.liveSession.record} playRecording schedules={this.state.liveSession.schedules} />  
                            </div>
                        </div>
                    </TabPane>
                    <TabPane tab={<TabTitle icon={<UnorderedListOutlined />} title={'Announcements'} />} key="announcements">
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <div style={{width: '50vw', paddingTop: '25px'}}>
                                <PostsView liveSessionId={this.getLiveSessionId()} />
                            </div>
                        </div>
                    </TabPane>
                </Tabs>

            </div>
        )
    }

}

export default withMediaQuery(withRouter(StudentLiveSessionPage));