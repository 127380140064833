class CameraStream {

    hasAudio = true;
    hasVideo = true;

    startStream(listener) {
        var mediaOptions = { audio: this.hasAudio, video: this.hasVideo };

        //console.log("getting stream")
        /*navigator.mediaDevices.getUserMedia(mediaOptions).then((stream) => {
            //console.log("got stream");
            this.stream = stream;

            listener(true);
        }, (error) => {
            //console.log("failed stream");
            //console.log(error)
            listener(false);
        });*/
      
        if (!navigator.getUserMedia) {
            navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;
        }

        if (!navigator.getUserMedia){
            listener(false);
            return;
        }
      
        navigator.getUserMedia(mediaOptions, (stream) => {
            this.stream = stream;
            listener(true);
        }, (error) => {
            listener(false);
        });
    }

    closeStream() {
        if (this.stream !== undefined) {
            this.stream.getTracks().forEach(track => {
                track.stop();
            });
        }
    }

}

export default CameraStream;