import { Button, Modal } from 'antd';
import React from 'react'
import Api from '../../session/Api';

import { 
    CheckCircleFilled,
    CheckOutlined,
    CloseOutlined
} from '@ant-design/icons';
import Util from '../../util/Util';
import UIUtil from '../../util/UIUtil';
import SessionManager from '../../session/SessionManager';
import { API_ERROR_MESSAGE_FEATURED_CONTENT_EXCEEDED_MAX } from '../../Constants';
import FeaturedSelectorView, { getFeaturedItemTypeIcon, getFeaturedItemTypeTitle } from './FeaturedSelectorView';

class FeaturedManageControl extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            togglingFeatured: false,
            showFeaturedSelectorView: false
        }
    }

    toggleFeature() {
        const shouldFeature = this.props.item.featuredContent == null;
        Modal.confirm({
            title: "Are you sure?",
            content: shouldFeature ? "Featuring this will display it in the home page and discovery page" : "Removing from the featured list will stop displaying it in the home page and discovery page",
            onOk: () => {
                this.setState({togglingFeatured: true});
                Api.setContentFeature(shouldFeature, this.props.featuredContentType, this.props.itemId !== undefined ? this.props.itemId : this.props.item.id, response => {
                    if (response.status === true) {
                        if (response.payload != null) {
                            response.payload.adminName = SessionManager.getAccount().fullName;
                        }
                        this.props.item.featuredContent = response.payload;
                        this.setState({togglingFeatured: false});
                    } else {
                        if (response.message == API_ERROR_MESSAGE_FEATURED_CONTENT_EXCEEDED_MAX) {
                            Modal.confirm({
                                title: "Max featured item count reached!",
                                content: "No more items can be featured. Please remove another time to make space.",
                                okText: "Featured List Editor",
                                onOk: () => this.setState({showFeaturedSelectorView: true}),
                                icon: React.createElement(getFeaturedItemTypeIcon(this.props.featuredContentType))
                            })
                        } else {
                            UIUtil.showError();
                        }
                        this.setState({togglingFeatured: false});
                    }
                })
            },
            okButtonProps: {
                loading: this.state.togglingFeatured
            },
        })
    }

    render() {
        return (
            <div>
                <div style={{display: 'flex', alignItems: 'center', width: '100%', marginBottom: -7}}>
                    <h2 style={{margin: 0}}>Content Manager</h2>
                </div>
                {this.props.item.featuredContent ? (
                    <div>
                        <h3 style={{color: 'green', margin: 0}}>Featured <CheckCircleFilled /></h3>
                        <p style={{margin: 0, marginTop: 5, fontSize: 12}}>Set on {Util.getDateOnly(this.props.item.featuredContent.date)} by {this.props.item.featuredContent.adminName}</p>

                        <Button loading={this.state.togglingFeatured} icon={<CloseOutlined />} type="primary" danger style={{width: '100%', marginTop: 15}} onClick={this.toggleFeature.bind(this)}>Not Featured</Button>
                    </div>
                ) : (
                    <div>
                        <p style={{margin: 0}}>Not featured</p>
                        
                        <Button loading={this.state.togglingFeatured} icon={<CheckOutlined />} type="primary" style={{width: '100%', marginTop: 15}} onClick={this.toggleFeature.bind(this)}>Featured</Button>
                    </div>
                )}

                {this.state.showFeaturedSelectorView &&
                <Modal 
                // footer={[<Button key="done-list-btn" onClick={() => this.setState({showFeaturedSelectorView: false})}>
                //     Done
                // </Button>]}
                zIndex={99}
                footer={null}
                onCancel={() => this.setState({showFeaturedSelectorView: false})}
                visible={this.state.showFeaturedSelectorView} 
                title={getFeaturedItemTypeTitle(this.props.featuredContentType)} width={700} bodyStyle={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{width: 600}}>
                        <FeaturedSelectorView featuredItemType={this.props.featuredContentType} />
                    </div>
                </Modal>}
            </div>
        )
    }

}

export default FeaturedManageControl;