import LogoWhite from '../../images/LogoTextWhite.svg';
import LogoPurple from '../../images/LogoTextPurple.svg';

export const DARK_THEME = {
    id: "dark_theme",

    iconViewColors: {
        sideMenuColor: 'white',
        backgroundColor: '#141414',
        
        accentColor: '#3b3b3b',
        activeAccentColor: '#990000',
        sideMenuAccentColor: '#4f2d88',
    },

    className: "live-stream-dark-theme",

    mainDark: true,
    sideDark: false,

    backgroundColor: 'linear-gradient(315deg, #000000 0%, #141414 100%)',
    sideMenuColor: 'white',

    mainTextColor: 'white',
    sideTextColor: 'black',

    mainLogo: LogoWhite,
    menuLogo: LogoPurple,

    subtleTextColor: '#8a8a8a'
}

export const LIGHT_THEME = {
    id: "light_theme",

    iconViewColors: {
        sideMenuColor: 'black',
        backgroundColor: '#dedede',

        accentColor: '#3b3b3b',
        activeAccentColor: '#990000',
        sideMenuAccentColor: '#f15f43'
    },

    className: "live-stream-light-theme",

    mainDark: false,
    sideDark: true,

    backgroundColor: 'linear-gradient(315deg, #FFFFFF 0%, #f2f2f2 100%)',
    sideMenuColor: 'black',

    mainTextColor: 'black',
    sideTextColor: 'white',

    mainLogo: LogoPurple,
    menuLogo: LogoWhite,

    subtleTextColor: '#6e6e6e'
}

export const ALL_DARK_THEME = {
    id: "all_dark_theme",

    iconViewColors: {
        sideMenuColor: 'black',
        backgroundColor: '#141414',
        
        accentColor: '#3b3b3b',
        activeAccentColor: '#990000',
        sideMenuAccentColor: '#f15f43'
    },

    className: "live-stream-all-dark-theme",

    mainDark: true,
    sideDark: true,

    backgroundColor: 'linear-gradient(315deg, #000000 0%, #141414 100%)',
    sideMenuColor: 'black',

    mainTextColor: 'white',
    sideTextColor: 'white',

    mainLogo: LogoWhite,
    menuLogo: LogoWhite,

    subtleTextColor: '#8a8a8a'
}

export const ALL_LIGHT_THEME = {
    id: "all_light_theme",

    iconViewColors: {
        sideMenuColor: 'white',
        backgroundColor: '#dedede',

        accentColor: '#3b3b3b',
        activeAccentColor: '#990000',
        sideMenuAccentColor: '#4f2d88'
    },

    className: "live-stream-all-light-theme",

    mainDark: false,
    sideDark: false,

    backgroundColor: 'linear-gradient(315deg, #FFFFFF 0%, #f2f2f2 100%)',
    sideMenuColor: 'white',

    mainTextColor: 'black',
    sideTextColor: 'black',

    mainLogo: LogoPurple,
    menuLogo: LogoPurple,

    subtleTextColor: '#6e6e6e'
}

export const BLUE_THEME = {
    id: "blue_theme",

    iconViewColors: {
        sideMenuColor: 'white',
        backgroundColor: '#003263',
        
        accentColor: '#00627a',
        activeAccentColor: '#009dc4',
        sideMenuAccentColor: '#0066c9',
    },

    className: "live-stream-blue-theme",

    mainDark: true,
    sideDark: false,

    backgroundColor: 'linear-gradient(147deg, #000000 0%, #003263 100%)',
    sideMenuColor: 'white',

    mainTextColor: 'white',
    sideTextColor: 'black',

    mainLogo: LogoWhite,
    menuLogo: LogoPurple,

    subtleTextColor: '#8a8a8a'
}

export const RED_THEME = {
    id: "red_theme",

    iconViewColors: {
        sideMenuColor: 'white',
        backgroundColor: '#93291e',
        
        accentColor: '#4a0000',
        activeAccentColor: 'red',
        sideMenuAccentColor: '#c90000',
    },

    className: "live-stream-red-theme",

    mainDark: true,
    sideDark: false,

    backgroundColor: 'linear-gradient(to right, #ed213a, #93291e)',
    sideMenuColor: 'white',

    mainTextColor: 'white',
    sideTextColor: 'black',

    mainLogo: LogoWhite,
    menuLogo: LogoPurple,

    subtleTextColor: 'white'
}

export const GREEN_THEME = {
    id: "green_theme",

    iconViewColors: {
        sideMenuColor: 'white',
        backgroundColor: '#005420',
        
        accentColor: '#002401',
        activeAccentColor: '#009900',
        sideMenuAccentColor: '#2a6b40',
    },

    className: "live-stream-green-theme",

    mainDark: true,
    sideDark: false,

    backgroundColor: 'linear-gradient(315deg, #63d471 0%, #233329 74%)',
    sideMenuColor: 'white',

    mainTextColor: 'white',
    sideTextColor: 'black',

    mainLogo: LogoWhite,
    menuLogo: LogoPurple,

    subtleTextColor: 'white'
}

export const ORANGE_THEME = {
    id: "orange_theme",

    iconViewColors: {
        sideMenuColor: 'white',
        backgroundColor: '#fbd72b',
        
        accentColor: '#d68b00',
        activeAccentColor: '#a85400',
        sideMenuAccentColor: '#ff9419',
    },

    className: "live-stream-orange-theme",

    mainDark: false,
    sideDark: false,

    backgroundColor: 'linear-gradient(315deg, #f9484a 0%, #fbd72b 74%)',
    sideMenuColor: 'white',

    mainTextColor: 'black',
    sideTextColor: 'black',

    mainLogo: LogoPurple,
    menuLogo: LogoPurple,

    subtleTextColor: 'white'
}




export const THEME_LOCAL_STORAGE_KEY = "lnx-ls-theme";
export const ALL_THEMES = [ DARK_THEME, LIGHT_THEME, ALL_DARK_THEME, ALL_LIGHT_THEME, BLUE_THEME, RED_THEME, GREEN_THEME, ORANGE_THEME ];

export const getTheme = (id) => {
    for (const theme of ALL_THEMES) {
        if (theme.id == id) {
            return theme;
        }
    }

    return DARK_THEME;
}
