import React from 'react'
import Page from '../base/Page';
import PostsView from '../views/posts/PostsView';
import SessionManager from '../session/SessionManager';

import LogoLarge from '../images/LogoLarge.png';

import { 
    NotificationOutlined,
    LeftOutlined
} from '@ant-design/icons';

//import MediaQuery from 'react-responsive'
import withMediaQuery from '../components/WithMediaQuery';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

class AllAnnouncementsPage extends Page {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center',flexDirection: 'column'}}>
                <Link to="/"><img src={LogoLarge} style={{width: '250px', marginTop: '50px'}} /></Link>
                <h2 style={{marginTop: '25px'}}><NotificationOutlined /> Announcements</h2>
                <div style={{width: this.props.renderFullWidth ? '90vw' : '50vw', marginTop: '25px'}}>
                    <PostsView adminPosts />
                </div>
            </div>
        );
    }

}

export default withMediaQuery(AllAnnouncementsPage);