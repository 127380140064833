import { Button, Col, Popconfirm, Popover, Row, Switch, Tooltip } from 'antd';
import React from 'react';

import HandIcon from '../../images/HandIcon.png'

import {
    LeftOutlined,
    RightOutlined,
    LeftCircleTwoTone,
    RightCircleTwoTone,
    CloseCircleFilled,
    AudioFilled,
    VideoCameraFilled
} from '@ant-design/icons';
import ProfilePicture from '../../components/ProfilePicture';
import Api from '../../session/Api';
import UIUtil from '../../util/UIUtil';
import { SessionIcon } from './SessionView';
import { SESSION_TYPE_CAMERA } from '../sessions/Session';

const UserItem = ({user, broadcasting, liveStream}) => {
    const [showOptions, setShowOptions] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const onSetStageBtn = () => {
        setLoading(true);
        Api.setLiveStreamStageUser(liveStream.id, user.userId, true, response => {
            if (response.status === true) {
                UIUtil.showSuccess();
            } else {
                UIUtil.showError(response.message);
                setLoading(false);
            }
        })
    }

    return (
        <div key={user.id} onMouseEnter={broadcasting && (() => setShowOptions(true))} onMouseLeave={broadcasting && (() => setShowOptions(false))}
        style={{ height: '115px', borderRadius: '15px', backgroundColor: "black", display: 'flex',
        flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <ProfilePicture size={55} userId={user.userId} />
            
            {showOptions ? (<>
                <Button onClick={onSetStageBtn} loading={loading} type="primary" size="small" className="red-button" style={{marginTop: '11px'}}>Bring to stage</Button>
            </>) : (<>
                <h4 style={{marginTop: '7px', color: 'white'}}>
                    {user.fullName}
                    {user.raisedHand &&
                    <img src={HandIcon} style={{width: 15, height: 15, marginLeft: 5}} />}
                </h4>
                <h6 style={{color: user.userYourself ? "#f26622" : "gray", marginTop: '-10px', marginBottom: '-5px'}}>
                    {user.userYourself ? "Yourself" : "Viewer"}
                </h6>
            </>)}

        </div>
    )    
}

const StageUserItem = (props) => {
    const {user, broadcasting, liveStream, stage} = props;
    const [loading, setLoading] = React.useState(false);

    const [hasAudio, setHasAudio] = React.useState(true);
    const [hasVideo, setHasVideo] = React.useState(true);

    const onSetStageBtn = () => {
        setLoading(true);
        Api.setLiveStreamStageUser(liveStream.id, user.userId, false, response => {
            if (response.status === true) {
                UIUtil.showSuccess();
            } else {
                UIUtil.showError(response.message);
                setLoading(false);
            }
        })
    }

    const getTitle = () => {
        return user.userYourself ? (
            'Currently not sharing camera'
        ) : (
            'Camera not being shared currently'
        )
    }

    return (
        <div key={user.id} style={{height: '175px', display: 'flex', flexDirection: 'column'}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <h3 style={{color: 'white', marginRight: '5px', height: '20px', display: 'flex', alignItems: 'center'}}>
                    <ProfilePicture userId={user.userId} size={25} style={{marginRight: '5px'}} />
                    {user.fullName}
                </h3>
                <div style={{flex: 1}} />
                {broadcasting && <Tooltip title="Stop Stage Access">
                    <Button loading={loading} onClick={onSetStageBtn} type="link" className="red-link" style={{padding: '0px'}}>
                    <CloseCircleFilled />
                </Button>
                </Tooltip>}

                {user.userYourself && (stage.allowedMic || stage.allowedCamera) &&
                <Popconfirm overlayClassName="popconfirm-title-no-padding" trigger="click" disabled={props.active} icon={null} 
                style={{padding: 0}} placement="bottom"
                okButtonProps={{disabled: !(stage.allowedMic ? hasAudio : false) && !(stage.allowedCamera ? hasVideo : false)}}
                title={<div style={{width: '225px'}}>
                    <p>Share</p>

                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <AudioFilled style={{color: 'black'}} />
                        <p style={{fontWeight: 'bold', marginLeft: '5px', color: 'black', height: '10px'}}>Mic Audio</p>
                        <div style={{flex: 1}} />
                        <Switch disabled={!stage.allowedMic} checkedChildren="On" unCheckedChildren="Off" checked={stage.allowedMic ? hasAudio : false} onChange={checked => setHasAudio(checked)} />
                    </div>

                    <div style={{display: 'flex', alignItems: 'center', marginTop: '5px'}}>
                        <VideoCameraFilled style={{color: 'black'}} />
                        <p style={{fontWeight: 'bold', marginLeft: '5px', color: 'black', height: '10px'}}>Camera Video</p>
                        <div style={{flex: 1}} />
                        <Switch disabled={!stage.allowedCamera} checkedChildren="On" unCheckedChildren="Off"  checked={stage.allowedCamera ? hasVideo : false} onChange={checked => setHasVideo(checked)} />
                    </div>
                </div>}
                onVisibleChange={visible => setLoading(visible)}
                onConfirm={() => {
                    setLoading(false);
                    //props.onChange(true, hasAudio, hasVideo)
                    props.onChange(true, stage.allowedMic ? hasAudio : false, stage.allowedCamera ? hasVideo : false)
                }} onCancel={() => {
                    setLoading(false);
                }}>
                    <Switch checkedChildren="On" unCheckedChildren="Off" 
                    checked={props.active !== undefined ? props.active : false} 
                    loading={props.loading || loading} onChange={props.active ? props.onChange : null} 
                    />
                </Popconfirm>}
            </div>
            <div style={{background: '#1B1B1B', borderRadius: '25px', borderTopLeftRadius: '5px', borderTopRightRadius: '5px', width: '100%', flex: '1', overflow: 'hidden'}}>

                <div style={{width: '100%', height: '100%', position: 'relative'}}>
                    {(!props.hideInfo || !props.active) && <div style={{width: '100%', height: '100%', position: 'absolute'}}>
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', padding: '10px'}}>
                            <SessionIcon type={SESSION_TYPE_CAMERA} style={{fontSize: '60px'}} />
                            <h4 style={{marginTop: '7px', textAlign: 'center'}}>{getTitle()}</h4>
                        </div>
                    </div>}
                    {(props.active || props.showAnyway) &&
                    <div style={{width: '100%', height: '100%', position: 'absolute'}}>
                        {props.children}
                    </div>}
                </div>
            </div>
        </div>
    )  
}

class StageUserList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            page: 0,
            stage: {},
            items: []
        }
    }

    componentDidMount() {
        this.loadStageList();
    }

    loadStageList() {
        Api.getLiveStreamStageList(this.props.liveStream.id, response => {
            if (response.status === true) {
                if (response.payload.users.length > 0 && response.payload.users[0].userYourself && response.payload.users[0].onStage) {
                    if (this.props.onStageUserUpdate !== undefined) {
                        this.props.onStageUserUpdate(true, response.payload);
                    }
                    this.setState({
                        page: 0,
                        stage: response.payload,
                        items: response.payload.users
                    })
                } else {
                    if (this.props.onStageUserUpdate !== undefined) {
                        this.props.onStageUserUpdate(false, response.payload);
                    }
                    this.setState({
                        stage: response.payload,
                        items: response.payload.users
                    })
                }
            }
        })
    }

    count() {
        if (this.props.isSmall) {
            return 1;
        } else {
            return this.props.count !== undefined ? this.props.count : 6;
        }
    }

    isFirstPage() {
        return this.state.page === 0;
    }

    isLastPage() {
        return this.state.page === (this.state.items.length > 0 ? (Math.ceil(this.state.items.length / this.count()) - 1) : 0);
    }

    renderRowContent() {
        const items = this.state.items;
        const itemSelection = items.slice(this.state.page * this.count(), (this.state.page * this.count()) + this.count());
        const span = 24 / this.count();

        return itemSelection.map(item => {
            const Item = item.onStage ? StageUserItem : UserItem;
            return <Col key={item.id} span={span}><Item user={item} stage={this.state.stage} {...this.props} /></Col>;
        })
    }

    render() {
        return (
            <div style={{display: 'flex', alignItems: 'center', width: '100%', paddingTop: '5px'}}>
                <div>
                    <Button onClick={!this.isFirstPage() && (e => this.setState(prevState => ({ page: prevState.page - 1 })))} type="text" 
                    style={{color: 'white', opacity: !this.isFirstPage() ? 1 : 0, cursor: this.isFirstPage() && "default"}}><LeftOutlined /></Button>
                </div>
                <Row gutter={10} style={{flex: 1, height: '100%', alignItems: 'center'}}>
                    {this.renderRowContent()}
                </Row>
                <div>
                    <Button onClick={!this.isLastPage() && (e => this.setState(prevState => ({ page: prevState.page + 1 })))} type="text" 
                    style={{color: 'white', opacity: !this.isLastPage() ? 1 : 0, cursor: this.isLastPage() && "default"}}><RightOutlined /></Button>
                </div>
            </div>
        )
    }

}

export default StageUserList;