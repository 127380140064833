import React from 'react'
import Page from '../../base/Page';
import HeaderAnimatedB from '../../images/HeaderAnimatedB.mp4'
import LiveIcon from '../../images/LiveIcon.png'
import Bg2 from '../../images/Bg2.jpg'
import { Space, Dropdown, Button, Menu, Tabs, Popover, Input, Divider, Row, Col, Rate, Select, Collapse } from 'antd';

import { 
    AuditOutlined,
    UserOutlined,
    ArrowRightOutlined,
    SearchOutlined,
    InstagramFilled,
    FacebookFilled,
    TwitterCircleFilled,
    LikeOutlined,
    YoutubeFilled,
    MessageOutlined,
    ClusterOutlined,
    CloseOutlined,
    BookOutlined,
    FireFilled
} from '@ant-design/icons';
import { CATEGORIES, CATEGORY_OTHER } from '../../Categories';
import HorizontalCoursesView, { HORIZONTAL_MODE_CATEGORY_COURSES, HORIZONTAL_MODE_TOP_COURSES } from '../../views/courses/HorizontalCoursesView';
import withMediaQuery from '../../components/WithMediaQuery';
import { Link, withRouter } from 'react-router-dom';
import HorizontalLiveSessionsView, { HORIZONTAL_MODE_TOP_LIVE_SESSIONS } from '../../views/live-sessions/HorizontalLiveSessionsView';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

import './landing-page.less'
import Api from '../../session/Api';
import ProfilePicture from '../../components/ProfilePicture';
import Util from '../../util/Util';
import FrontendAuthModal from '../../components/FrontendAuthModal';
import PresenceIndicator from '../../components/PresenceIndicator';
import UserList from '../../components/UserList';
import MediaQuery from 'react-responsive';
import FooterView from '../../views/public-views/FooterView';
import HorizontalBlogPostView from '../../views/blog/HorizontalBlogPostView';
import SessionManager from '../../session/SessionManager';
import HorizontalHashtagsView from '../hashtag/HorizontalHashtagsView';

const footerColStyles = {
    flexBasis: "20%",
    width: "20%"
};

class LandingPage extends Page {

    constructor(props) {
        super(props)

        this.state = {
            selectedCategoryId: "category-featured"
        }
    }

    componentDidMount() {
        Api.getFeaturedTutors(response => {
            if (response.status === true) {
                this.setState({tutors: response.payload})
            }
        })
    }

    renderHeaderSection() {
        const height = this.props.isSmall ? '320px' : '75vh';
        const headerTitleFontSize = this.props.isSmall ? '40px' : '8vh';
        const headerSubTitleFontSize = this.props.isSmall ? '16px' : '2vh';
        const headerSubTitleSidePadding = this.props.isSmall ? '25px' : undefined;
        const buttonMarginTop = this.props.isSmall ? '30px' : '60px';
        const buttonWidth = this.props.isSmall ? '200px' : '250px';

        return (
            <div style={{width: '100%', height: height,}}>
                {/*<img src={HeaderBlur} style={{width: '100%', height: '75vh', objectFit: 'cover', position: 'absolute', zIndex: '-1'}} />*/}
                <video src={HeaderAnimatedB} playsInline autoPlay muted loop style={{width: '100%', height: height, objectFit: 'cover', position: 'absolute', zIndex: '-1'}}/>
                

                <Space direction="vertical" size="small" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: this.props.isSmall ? '39vh' : '69vh'}}>
                    <h1 style={{color: 'white', fontSize: headerTitleFontSize, textAlign: 'center', lineHeight: this.props.isSmall && '50px'}}>The classroom of the future</h1>
                    <h2 style={{color: 'white', textAlign: 'center', 
                    paddingLeft: headerSubTitleSidePadding, paddingRight: headerSubTitleSidePadding, 
                    marginTop: this.props.isSmall ? undefined : '-5vh', fontSize: headerSubTitleFontSize}}>
                        Get access to high quality courses with scheduled live streams and help sessions
                    </h2>

                    <Dropdown overlay={
                        <Menu>
                            <Menu.Item onClick={e => this.props.history.push("/auth?registerType=0")} key="0" style={{height: '50px', display: 'flex', alignItems: 'center'}}>
                                <UserOutlined style={{fontSize: '20px'}} /> As Learner
                            </Menu.Item>
                            <Menu.Item onClick={e => this.props.history.push("/auth?registerType=1")} key="1" style={{height: '50px', display: 'flex', alignItems: 'center'}}>
                                <AuditOutlined style={{fontSize: '20px'}} /> As Tutor
                            </Menu.Item>
                            <Menu.Item onClick={e => this.props.history.push("/auth?registerType=2")} key="1" style={{height: '50px', display: 'flex', alignItems: 'center'}}>
                                <ClusterOutlined style={{fontSize: '20px'}} /> As Organization
                            </Menu.Item>
                        </Menu>
                    } trigger={['click']}>
                        <Button className="orange-button" type="primary" size="large" 
                        style={{marginTop: buttonMarginTop, width: buttonWidth, ...(!this.props.isSmall && {height: '50px', fontSize: '20px'})}}>Get Started</Button>
                    </Dropdown>
                </Space>
            </div>
        )
    }

    render() {
        return (
            <div>
                {SessionManager.isLoggedInAsContentManager() &&
                <div style={{width: '100%', paddingTop: 25, paddingBottom: 25, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{width: this.props.renderFullWidth ? '90vw' : '75vw'}}>
                        <h1 style={{margin: 0, flex: 1}}>Content Preview</h1>
                    </div>
                    <Button icon={<CloseOutlined />} type="primary" danger onClick={() => this.props.history.goBack()}>Close Preview</Button>
                </div>}
                
                
                {!SessionManager.isLoggedIn() && this.renderHeaderSection()}

                
                
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '25px'}}>
                    <h1 style={{textAlign: 'center'}}><FireFilled style={{marginRight: 0}} /> Trending</h1>

                    <HorizontalHashtagsView style={{width: this.props.renderFullWidth ? '90vw' : '75vw'}}  />
                </div>

                <div style={{height: '1px', width: '100%', background: 'black', opacity: 0.1, marginBottom: 15}} />

                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '25px'}}>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
                        <img src={LiveIcon} style={{height: '20px', marginRight: '15px'}} />
                        <h1 style={{height: '30px'}}>{this.props.isSmall ? 'Upcoming Sessions' : 'Upcoming Live Sessions'}</h1>
                    </div>

                    <HorizontalLiveSessionsView style={{width: this.props.renderFullWidth ? '90vw' : '75vw', marginTop: '25px'}} mode={HORIZONTAL_MODE_TOP_LIVE_SESSIONS}/>

                    <Button className="orange-button" type="primary" size="large" style={{marginTop: '25px'}}>
                        <Link to="/all-live-sessions">
                            View All Live Sessions <ArrowRightOutlined />
                        </Link>
                    </Button>
                </div>

                <div style={{height: '2px', width: '100%', background: '#cecece'}} />

                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '25px'}}>
                    <h1>Popular Courses</h1>
                    <MediaQuery maxWidth={619}>
                        <Select value={this.state.selectedCategoryId} onChange={value => this.setState({selectedCategoryId: value})} 
                        style={{ width: '100%', marginBottom: '15px' }} size="large">
                            <Select.Option value="category-featured"><BookOutlined /> &nbsp;&nbsp;Featured (All categories)</Select.Option>
                            {CATEGORIES.filter(category => category.id != CATEGORY_OTHER).map(category => 
                                <Select.Option value={category.id}>{category.icon} &nbsp;&nbsp;{category.title}</Select.Option>)}
                        </Select>
                    </MediaQuery>
                    <MediaQuery minWidth={620}>
                        <Tabs activeKey={this.state.selectedCategoryId} onChange={key => this.setState({selectedCategoryId: key})} size="large" style={{width: '100%'}}>
                            <Tabs.TabPane key={"category-featured"} tab={
                                <Space direction="vertical" size="small" style={{width: '6vw', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <BookOutlined style={{fontSize: '45pt', marginLeft: '7px'}} />
                                    Featured
                                    <p style={{margin: 0, marginTop: -12, fontSize: 10, color: 'black', opacity: 0.65}}>All Categories</p>
                                </Space>
                            } style={{display: 'flex', alignItems: 'center'}} />
                            {CATEGORIES.filter(category => category.id != CATEGORY_OTHER).map(category => (
                                <Tabs.TabPane key={category.id} tab={
                                    <Space direction="vertical" size="small" style={{width: '6vw', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        {React.cloneElement(category.icon, {
                                            style: {fontSize: '45pt', marginLeft: '7px'}
                                        })}
                                        {category.title}
                                    </Space>
                                } style={{display: 'flex', alignItems: 'center'}} />
                            ))}
                        </Tabs>
                    </MediaQuery>
                    <HorizontalCoursesView style={{width: this.props.renderFullWidth ? '90vw' : '75vw'}} mode={this.state.selectedCategoryId == "category-featured" ? HORIZONTAL_MODE_TOP_COURSES : HORIZONTAL_MODE_CATEGORY_COURSES} category={this.state.selectedCategoryId} />
                    
                    <Button className="orange-button" type="primary" size="large" style={{marginTop: '25px'}}>
                        <Link to="/all-courses">
                            View All Courses <ArrowRightOutlined />
                        </Link>
                    </Button>
                </div>

                <div style={{height: '2px', width: '100%', background: '#cecece'}} />
                
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '25px'}}>
                    <h1 style={{textAlign: 'center'}}>Living Together! Learning Together! Earning Together!</h1>

                    <HorizontalBlogPostView style={{width: this.props.renderFullWidth ? '90vw' : '75vw'}}  />

                    <Button className="orange-button" type="primary" size="large" style={{marginTop: '25px'}}>
                        <Link to="/blog">
                            View Blog <ArrowRightOutlined />
                        </Link>
                    </Button>
                </div>

                <MediaQuery maxWidth={619}>
                    <div style={{width: '100%', backgroundImage: 'url("/t-bg.jpg")', backgroundSize: 'cover'}}>
                        <div direction="vertical" size="large" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', paddingTop: '25px', paddingBottom: '25px'}}>
                            <h1 style={{color: 'white'}}>Featured Tutors</h1>
                                
                            <Collapse expandIconPosition="right" accordion style={{width: '75vw', color: 'white'}} ghost>
                                {this.state.tutors !== undefined && this.state.tutors.map(tutor => (
                                    <Collapse.Panel header={
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <ProfilePicture clickable userId={tutor.id} size={40} style={{borderStyle: 'solid', borderWidth: '2px'}} />
                                            <h2 style={{color: 'white', marginLeft: '15px', height: '25px'}}>{tutor.fullName}</h2>
                                        </div>
                                    }>
                                        <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                            <ProfilePicture clickable userId={tutor.id} size={150} style={{borderStyle: 'solid'}} />
                                            <Link to={Util.getProfilePath(tutor.id)}><h2 style={{color: 'white', marginTop: '15px'}}>{tutor.fullName}</h2></Link>
                                            <PresenceIndicator textMode userId={tutor.id} style={{color: 'white', marginTop: '-10px', marginBottom: '15px'}} />
                                            <Rate disabled defaultValue={tutor.averageRating} />
                                            <Button type="text" style={{marginTop: '5px', color: 'white'}} onClick={() => this.setState({sendMessageTutorId: tutor.id})}><MessageOutlined /> Send Message</Button>
                                        </div>
                                    </Collapse.Panel>
                                ))}
                            </Collapse>

                            <div style={{marginTop: '30px'}}>
                                <Popover trigger="click" content={
                                    <Input.Group size="large" compact>
                                        <Input.Search size="large" style={{ width: '300px' }} placeholder="Search Tutors" 
                                        onSearch={value => Util.isStringExists(value) && this.props.history.push("/search?tutorValue=" + value)} />
                                    </Input.Group>
                                }>
                                    <Button type="text" size="large" style={{color: 'white'}}><SearchOutlined /> Search Tutors</Button>
                                </Popover>
                                <Divider type="vertical" style={{border:'1px solid #f0f0f0'}} />
                                <Button type="text" size="large" style={{color: 'white'}}>
                                    <Link to="/all-tutors">
                                        View All Tutors <ArrowRightOutlined />
                                    </Link>
                                </Button>
                                {/*<Input.Group size="large" compact>
                                    <Input.Search size="large" style={{ width: '500px' }} placeholder="Search Tutors" />
                                </Input.Group>*/}
                            </div>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery minWidth={620}>
                    <div style={{width: '100%'}}>
                        <img src={Bg2} style={{width: '100%', height: '465px', objectFit: 'cover', position: 'absolute', zIndex: '-1'}} />
                        <div direction="vertical" size="large" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', height: '465px', paddingTop: '25px', paddingBottom: '25px'}}>
                            <h1 style={{color: 'white'}}>Featured Tutors</h1>
                                

                            <Row style={{width: this.props.renderFullWidth ? '90vw' : '85vw'}}>
                                {this.state.tutors !== undefined && this.state.tutors.map(tutor => (
                                    <Col span={6} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                                        <ProfilePicture clickable userId={tutor.id} size={150} style={{borderStyle: 'solid'}} />
                                        <Link to={Util.getProfilePath(tutor.id)}><h2 style={{color: 'white', marginTop: '15px'}}>{tutor.fullName}</h2></Link>
                                        <PresenceIndicator textMode userId={tutor.id} style={{color: 'white', marginTop: '-10px', marginBottom: '15px'}} />
                                        <Rate disabled defaultValue={tutor.averageRating} />
                                        <Button type="text" style={{marginTop: '5px', color: 'white'}} onClick={() => this.setState({sendMessageTutorId: tutor.id})}><MessageOutlined /> Send Message</Button>
                                    </Col>
                                ))}
                            </Row>


                            <div style={{marginTop: '30px'}}>
                                <Popover trigger="click" content={
                                    <Input.Group size="large" compact>
                                        <Input.Search size="large" style={{ width: '300px' }} placeholder="Search Tutors" 
                                        onSearch={value => Util.isStringExists(value) && this.props.history.push("/search?tutorValue=" + value)} />
                                    </Input.Group>
                                }>
                                    <Button type="text" size="large" style={{color: 'white'}}><SearchOutlined /> Search Tutors</Button>
                                </Popover>
                                <Divider type="vertical" style={{border:'1px solid #f0f0f0'}} />
                                <Button type="text" size="large" style={{color: 'white'}}>
                                    <Link to="/all-tutors">
                                        View All Tutors <ArrowRightOutlined />
                                    </Link>
                                </Button>
                                {/*<Input.Group size="large" compact>
                                    <Input.Search size="large" style={{ width: '500px' }} placeholder="Search Tutors" />
                                </Input.Group>*/}
                            </div>
                        </div>
                    </div>
                </MediaQuery>

                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '25px'}}>
                    <h1>Partnered Organizations</h1>
                    <UserList showFeaturedOrganizations horizontalMode miniMode={this.props.isSmall} customWidth={this.props.renderFullWidth ? '90vw' : '75vw'} />
                    
                    <Button className="orange-button" type="primary" size="large" style={{marginTop: '25px'}}>
                        <Link to="/all-organizations">
                            View All Organizations <ArrowRightOutlined />
                        </Link>
                    </Button>
                </div>
                
                

                {/*<MediaQuery minWidth={620}>
                    <div style={{height: '2px', width: '100%', background: '#cecece'}} />

                    <div style={{paddingTop: '75px', paddingBottom: '75px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Row justify="center" align="middle" style={{width: this.props.renderFullWidth ? '90vw' : '75vw'}}>
                            <Col span={6} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                                <CountUp suffix="+" end={400} duration={2} redraw={true}>
                                    {({ countUpRef, start }) => (
                                        <VisibilitySensor onChange={(visible) => {
                                            if (visible) {
                                                start();
                                            }
                                        }} delayedCall>
                                            <>
                                                <p className="stats-number-purple" ref={countUpRef} />
                                                <h2 style={{marginTop: '-115px', color: 'white'}}>Tutors</h2>
                                            </>
                                        </VisibilitySensor>
                                    )}
                                </CountUp>
                            </Col>
                            <Col span={6} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                                <CountUp suffix="+" end={1500} duration={2} redraw={true}>
                                    {({ countUpRef, start }) => (
                                        <VisibilitySensor onChange={(visible) => {
                                            if (visible) {
                                                start();
                                            }
                                        }} delayedCall>
                                            <>
                                                <p className="stats-number-orange" ref={countUpRef} />
                                                <h2 style={{marginTop: '-115px', color: 'white'}}>Learners</h2>
                                            </>
                                        </VisibilitySensor>
                                    )}
                                </CountUp>
                            </Col>
                            <Col span={6} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                                <CountUp suffix="+" end={700} duration={2} redraw={true}>
                                    {({ countUpRef, start }) => (
                                        <VisibilitySensor onChange={(visible) => {
                                            if (visible) {
                                                start();
                                            }
                                        }} delayedCall>
                                            <>
                                                <p className="stats-number-purple" ref={countUpRef} />
                                                <h2 style={{marginTop: '-115px', color: 'white'}}>Courses</h2>
                                            </>
                                        </VisibilitySensor>
                                    )}
                                </CountUp>
                            </Col>
                            <Col span={6} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                                <CountUp suffix="+" end={7800} duration={2} redraw={true}>
                                    {({ countUpRef, start }) => (
                                        <VisibilitySensor onChange={(visible) => {
                                            if (visible) {
                                                start();
                                            }
                                        }} delayedCall>
                                            <>
                                                <p className="stats-number-orange" ref={countUpRef} />
                                                <h2 style={{marginTop: '-115px', color: 'white'}}>Live Streams</h2>
                                            </>
                                        </VisibilitySensor>
                                    )}
                                </CountUp>
                            </Col>
                        </Row>
                    </div>
                </MediaQuery>*/}

                <FooterView />

                <FrontendAuthModal visible={this.state.sendMessageTutorId !== undefined} onCancel={() => this.setState({sendMessageTutorId: undefined})} onDonePath={'/messages?userId=' + this.state.sendMessageTutorId} />
            </div>
        )
    }
}

export default withRouter(withMediaQuery(LandingPage));