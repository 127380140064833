import React, { useEffect } from 'react';
import Error404Page from './pages/Error404Page';

//import PublicWindow from './windows/public/public-window/PublicWindow';
import PublicWindow from './windows/public/PublicWindow';
import AdminWindow from './windows/admin/admin-window/AdminWindow';
import AdminLoginWindow from './windows/admin/AdminLoginWindow';


import SessionManager from './session/SessionManager'

import {
    BrowserRouter as Router,
    Route,
    Switch
} from 'react-router-dom'

import './App.less';
import FrontendWindow from './windows/frontend/FrontendWindow';
import SocketSession from './session/SocketSession';

import PresenceManager from './session/PresenceManager';
import ScrollToTop from './components/ScrollToTop';
import PublicAuthWindow from './windows/public/PublicAuthWindow'
import PublicResetPasswordWindow from './windows/public/PublicResetPasswordWindow';
import RawBlogPage from './pages/blog-pages/RawBlogPage';

// import LiveStreamWindow from './livestream/windows/LiveStreamWindow';
// import { default as LSW } from './livestream2/windows/LiveStreamWindow';

import LiveStreamWindow from './livestream2/windows/LiveStreamWindow';
import ScrollButton from './components/ScrollBtn';
import LiveStreamQrCode from './sharing-system/LiveStreamQrCode';
import MobileAppStoreLink from './marketing/MobileAppStoreLink';
import LiveStreamFinderWindow from './livestream2/windows/LiveStreamFinderWindow';
import LandingPageLink from './marketing/LandingPageLink';

/*function FrontendWindow() {
    return (<>{SessionManager.isLoggedInFrontend() ? 
            <>{SessionManager.isLoggedInAsStudent() ? <StudentWindow /> : <TutorWindow />}</>
            : <PublicWindow />
        }</>
    )
}*/

function App() {
    useEffect(() => {
        if (SessionManager.isLoggedIn()) {
            SocketSession.open();
            PresenceManager.start();
        }
    }, []);

    // return <LiveStreamQrCode />

    return (
        <div>
            <Router>
                <ScrollToTop>
                    <Switch>
                        <Route exact path="/e949c491" component={LandingPageLink} />
                        <Route exact path="/23567fa9" component={MobileAppStoreLink} />


                        <Route exact path="/bpr" component={RawBlogPage} />
                        <Route path="/lsr/:tkn/:liveStreamId" render={props => <div style={{width: '100vw', height: '100vh'}}>
                            <LiveStreamWindow recordingMode mobileAppMode={{userToken: props.match.params.tkn}}
                            liveStreamId={props.match.params.liveStreamId} 
                            onBack={() => window.ReactNativeWebView.postMessage(JSON.stringify({ eventCode: "goBack" }))} /></div>} />
                        <Route path="/lsw/:liveStreamId" render={props => <div style={{width: '100vw', height: '100vh'}}>
                            <LiveStreamWindow mobileAppMode
                            onBack={() => window.ReactNativeWebView.postMessage(JSON.stringify({ eventCode: "goBack" }))} 
                            {...props} /></div>} />


                        <Route path="/admin" component={AdminWindow} />

                        <Route path="/live-stream/:liveStreamId" component={LiveStreamWindow} />
                        <Route path="/lsnx-re/:liveStreamHashId" component={LiveStreamFinderWindow} />

                        {!SessionManager.isLoggedIn() && <Route path="/auth" component={PublicAuthWindow} />}
                        <Route path="/reset-password" component={PublicResetPasswordWindow} />

                        <Route path="/" component={SessionManager.isLoggedInFrontend() ? FrontendWindow : PublicWindow} />
                    </Switch>
                </ScrollToTop>
            </Router>
            <ScrollButton />
        </div>
    )
}

export default App;