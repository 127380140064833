import React from 'react';

import { Button, Input } from 'antd';

import { 
    LoginOutlined,
    UserOutlined,
    LockOutlined,
    AuditOutlined,
    ClusterOutlined,
    DeleteFilled
} from '@ant-design/icons';

const UserRecord = ({user, userUpdated, userRemoved, nonExistent, emailOnly}) => {
    const [state, setState] = React.useState([]);

    const onUserUpdate = (property, update) => {
        user[property] = update;
        userUpdated();

        setState([])
    }

    return (
        <div style={{display: 'flex'}}>
            {!emailOnly && <>
                <div style={{flex: '1'}}>
                    <h5>Full Name</h5>
                    <Input value={user.fullName} onChange={e => onUserUpdate('fullName', e.target.value)} />
                </div>
                <div style={{width: '5px'}} />
            </>}
            
            <div style={{flex: '2'}}>
                <h5>Email Address</h5>
                <Input value={user.email} type="email" onChange={e => onUserUpdate('email', e.target.value)} />
            </div>

            {!emailOnly && <>
                <div style={{width: '5px'}} />
                <div style={{flex: '1'}}>
                    <h5>Password</h5>
                    <Input value={user.password} onChange={e => onUserUpdate('password', e.target.value)} />
                </div>
            </>}

            
            {/*<div style={{width: '5px'}} />
            <div style={{flex: '1'}}>
                <h5>Confirm Password</h5>
                <Input value={user.passwordConfirm} type="password" onChange={e => onUserUpdate('passwordConfirm', e.target.value)} />
            </div>*/} 

            <div>
                <h5 style={{opacity: 0}}>Action</h5>
                <Button type="link" className={!nonExistent && "red-link"} disabled={nonExistent} onClick={() => userRemoved()}>
                    <DeleteFilled />
                </Button>
            </div>

        </div>
    )
}

export default function MultipleUserInput(props) {
    const [users, setUsers] = React.useState([{}]);

    const onUserUpdated = user => {
        if (users.indexOf(user) != (users.length - 1)) {
            return;
        }

        props.onInputUpdated(users);
        const updatedUsers = [...users, {}];
        if (updatedUsers.length <= props.limit) {
            setUsers(updatedUsers);
        }
    }

    const onUserRemoved = user => {
        let updatedUsers = [...users];
        updatedUsers.splice(updatedUsers.indexOf(user), 1);
        setUsers(updatedUsers);

        props.onInputUpdated(updatedUsers.slice(0, updatedUsers.length - 1));
    }

    return (
        <div>
            {users.map((user, index) => (<> 
                {index > 0 && <div style={{height: '15px'}} />} 
                <UserRecord emailOnly={props.emailOnly} nonExistent={users.indexOf(user) == (users.length - 1)} user={user} 
                userUpdated={() => onUserUpdated(user)} userRemoved={() => onUserRemoved(user)} /> 
            </>))}
        </div>
    )
}