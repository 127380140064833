import React from 'react'
import Page from '../../base/Page';
import Api from '../../session/Api';
import Error404Page from '../Error404Page';
import { ROOM_PARENT_TYPE_HELP_SESSION, HELP_SESSION_STATUS_PENDING_REQUEST_SEND, HELP_SESSION_STATUS_ACTIVE, HELP_SESSION_STATUS_AWAITING_CONFIRM, SOCKET_EVENT_HELP_SESSION_STATUS, HELP_SESSION_STATUS_DONE } from '../../Constants';
import ChatView from '../../views/chats/ChatView';
import { PageHeader, Tag, Result, Button } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import SocketSession from '../../session/SocketSession';

import { SmileOutlined, HistoryOutlined } from '@ant-design/icons';

class StudentHelpSessionPage extends Page {

    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            helpSession: {},
            helpSessionDoesNotExist: false,
        }
    }

    getCourseId() {
        return this.props.match.params.courseId;
    }

    componentDidMount() {
        super.componentDidMount();
        SocketSession.registerListener(SOCKET_EVENT_HELP_SESSION_STATUS, this.statusListener);
    }

    componentWillMount() {
        SocketSession.unregisterListener(SOCKET_EVENT_HELP_SESSION_STATUS, this.statusListener);
    }

    onPageStart() {
        this.setState({loading: true})
        Api.getCurrentHelpSessionRequest(this.getCourseId(), response => {
            if (response.status === true) {
                this.setState({
                    loading: false,
                    helpSession: response.payload,
                    helpSessionDoesNotExist: false,
                })
            } else {
                this.setState({
                    loading: false,
                    helpSession: {},
                    helpSessionDoesNotExist: true,
                })
            }
        })
    }

    onStatusListener(helpSessionStatusUpdate) {
        if (helpSessionStatusUpdate.helpSessionId === this.state.helpSession.id) {
            this.state.helpSession.status = helpSessionStatusUpdate.statusUpdate;
            this.setState({})
        }
    }
    statusListener = this.onStatusListener.bind(this);

    getStatusTitle() {
        switch (this.state.helpSession.status) {
            case HELP_SESSION_STATUS_AWAITING_CONFIRM:
                return <Tag color="blue">Awaiting Request Acceptance</Tag>;
            case HELP_SESSION_STATUS_ACTIVE:
                return <Tag color="green">Active</Tag>
        }
    }

    render() {
        if (this.state.helpSessionDoesNotExist) {
            return <Error404Page />
        }

        return (
            <div style={{flex: '1', height: '100%', display: 'flex', flexDirection: 'column'}}>
                <PageHeader
                    style={{background: 'rgb(237,240,243)'}}
                    className="site-page-header"
                    onBack={() => this.props.history.goBack()}
                    title="Help Session"
                    subTitle={this.state.helpSession.courseName}
                    extra={this.getStatusTitle()}
                />
                <div style={{flex: '1', display: 'flex'}}>
                    <div style={{flex: '1'}}>
                        {this.state.helpSession.status === HELP_SESSION_STATUS_DONE ? (
                            <Result
                            icon={<SmileOutlined />}
                            title="Good job! Help session is done!"
                            extra={<Button type="primary">
                                <Link replace to={"/help-session/" + this.getCourseId()}>
                                    <HistoryOutlined /> View History
                                </Link>
                            </Button>}
                            />
                        ) : (
                            <ChatView helpSession loading={this.state.loading} room={this.state.helpSession.room} roomParentType={ROOM_PARENT_TYPE_HELP_SESSION} roomParentId={this.state.helpSession.id} roomSecondaryParentId={this.getCourseId()}
                            roomOtherMemberId={this.state.helpSession.room !== undefined ? this.state.helpSession.room.otherMember.id : 0} />
                        )}
                    </div>
                </div>
            </div>
        )
    }

}

export default withRouter(StudentHelpSessionPage);