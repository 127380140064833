import React from 'react'
import Page from '../../base/Page';

import {
    UserOutlined,
    PhoneFilled,
    PhoneTwoTone,
    MailTwoTone,
    DatabaseTwoTone,
    EnvironmentTwoTone,
    ProfileFilled,
    CheckCircleFilled
} from '@ant-design/icons';
import { Link, withRouter } from 'react-router-dom';
import { Button, Input, Select, Tooltip } from 'antd';
import FooterView from '../../views/public-views/FooterView';

import StillHeaderAnim from '../../images/StillHeaderAnim.png';
import TutorVid from '../../images/TutorVid.mp4';
import Bg2 from '../../images/Bg2.jpg';

import Logo from '../../images/LogoLarge.png';
import Util from '../../util/Util';

import ReCAPTCHA from "react-google-recaptcha";
import SessionManager from '../../session/SessionManager';
import Api from '../../session/Api';
import withMediaQuery from '../../components/WithMediaQuery';
import UIUtil from '../../util/UIUtil';

const FormPadding = () => <div style={{height: 15}} />

const Topics = [
    { value: "General inquiries", content: "General inquiries" },
    { value: "Subscription and registration", content: "Subscription and registration" },
    { value: "Tutoring inquiries", content: "Tutoring inquiries" },
    { value: "Organizational network", content: "Organizational network" },
    { value: "Monetization and income", content: "Monetization and income" },
    { value: "Payments and billing", content: "Payments and billing" },
    { value: "Other", content: "Other" },
]

class ContactUsPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            nameValue: this.getAccountValue("fullName"),
            phoneValue: "",
            emailValue: this.getAccountValue("email"),
            topicValue: "",
            messageValue: "",
            captchaValue: undefined,

            sendingInquiryForm: false
        }

        this.captchaRef = React.createRef();
    }

    componentDidMount() {
        if (SessionManager.isLoggedInAsTutor()) {
            Api.getPhoneNumber(response => {
                if (response.status === true && !Util.isStringExists(this.state.phoneValue)) {
                    this.savedPhoneNumber = response.payload;
                    this.setState({phoneValue: response.payload})
                }
            })
        }
    }

    getAccountValue(property) {
        return SessionManager.isLoggedIn() && Util.isStringExists(SessionManager.getAccount()[property]) ? SessionManager.getAccount()[property] : "";
    }

    canSubmit() {
        return Util.isStringExists(this.state.nameValue) && Util.isStringExists(this.state.phoneValue) && Util.isStringExists(this.state.emailValue) && Util.isStringExists(this.state.topicValue) && Util.isStringExists(this.state.messageValue) && Util.isStringExists(this.state.captchaValue);
    }

    getTopicContent() {
        for (const topic of Topics) {
            if (topic.value == this.state.topicValue) {
                return topic.content;
            }
        }

        return undefined;
    }

    submit() {
        this.setState({sendingInquiryForm: true})
        Api.sendInquiryForm({
            fullName: this.state.nameValue,
            phoneNumber: this.state.phoneValue,
            email: this.state.emailValue,
            topic: this.getTopicContent(),
            message: this.state.messageValue,
            captchaToken: this.state.captchaValue,
        }, response => {
            this.captchaRef.current.reset();

            if (response.status === true) {
                this.setState({
                    nameValue: this.getAccountValue("fullName"),
                    phoneValue: Util.isStringExists(this.savedPhoneNumber) ? this.savedPhoneNumber : "",
                    emailValue: this.getAccountValue("email"),
                    topicValue: "",
                    messageValue: "",
                    captchaValue: undefined,
                    sendingInquiryForm: false
                })
                UIUtil.showSuccess("We will get back to you soon!");
            } else {
                this.setState({captchaValue: undefined, sendingInquiryForm: false})
                UIUtil.showError();
            }
        })
    }

    render() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{width: '100%', height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <img src={StillHeaderAnim} style={{width: '100%', height: '150px', objectFit: 'cover', position: 'absolute', zIndex: -1}} />
                    <h1 style={{ marginTop: '25px', marginBottom: '25px', color: 'white', }}><PhoneFilled /> Contact Us</h1>
                </div>
                <div style={{ width: this.props.isSmall ? '90vw' : '75vw', marginBottom: '25px' }}>
                    <div style={{width: '100%', marginTop: 60, display: 'flex'}}>
                        <div style={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                            <img src={Logo} style={{width: 300}} />
                            

                            <div style={{display: 'flex', width: '100%', flexDirection: this.props.isSmall ? "column" : "row", justifyContent: 'space-evenly', alignItems: 'center', marginTop: this.props.isSmall ? 40 : 60}}>
                                <a target="_blank" href="tel:+971042432411">
                                    <div onMouseEnter={() => this.setState({telIn: true})} 
                                    onMouseLeave={() => this.setState({telIn: false})}
                                    className="contact-us-item" style={{position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                                        <div style={{width: '100%', height: '100%', position: 'absolute', left: 0, top: 0, zIndex: 0, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                            <div style={{width: 50, height: 50, borderRadius: '50%', background: '#4e2e8920', transition: '250ms', transform: this.state.telIn ? 'scale(3)' : 'scale(0)'}} />
                                        </div>

                                        <PhoneTwoTone twoToneColor="#f26622" style={{fontSize: 65, zIndex: 1}} />
                                        <h4 style={{margin: 0, marginTop: 15, fontWeight: 'bolder'}}>Telephone</h4>
                                        <p style={{marginTop: -2, transition: '250ms', color: this.state.telIn ? '#4e2e89' : 'black', opacity: this.state.telIn ? 1 : 0.65}}>+971 04 243 2411</p>
                                    </div>
                                </a>

                                <div 
                                onMouseEnter={() => this.setState({poIn: true})} 
                                onMouseLeave={() => this.setState({poIn: false})}
                                className="contact-us-item" style={{position: 'relative', cursor: 'default', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop: this.props.isSmall ? 25 : 0}}>
                                    <div style={{width: '100%', height: '100%', position: 'absolute', left: 0, top: 0, zIndex: 0, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <div style={{width: 50, height: 50, borderRadius: '50%', background: '#4e2e8920', transition: '250ms', transform: this.state.poIn ? 'scale(3)' : 'scale(0)'}} />
                                    </div>

                                    <DatabaseTwoTone twoToneColor="#f26622" style={{fontSize: 65, zIndex: 1}} />
                                    <h4 style={{margin: 0, marginTop: 15, fontWeight: 'bolder'}}>P.O. Box</h4>
                                    <p style={{marginTop: -2, transition: '250ms', color: this.state.poIn ? '#4e2e89' : 'black', opacity: this.state.poIn ? 1 : 0.65}}>34190 Dubai</p>
                                </div>

                                <a target="_blank" href="mailto:info@learnerix.com">
                                    <div 
                                    onMouseEnter={() => this.setState({emIn: true})} 
                                    onMouseLeave={() => this.setState({emIn: false})}
                                    className="contact-us-item" style={{position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop: this.props.isSmall ? 25 : 0}}>
                                        <div style={{width: '100%', height: '100%', position: 'absolute', left: 0, top: 0, zIndex: 0, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                            <div style={{width: 50, height: 50, borderRadius: '50%', background: '#4e2e8920', transition: '250ms', transform: this.state.emIn ? 'scale(3)' : 'scale(0)'}} />
                                        </div>

                                        <MailTwoTone twoToneColor="#f26622" style={{fontSize: 65, zIndex: 1}} />
                                        <h4 style={{margin: 0, marginTop: 15, fontWeight: 'bolder'}}>Email</h4>
                                        <p style={{marginTop: -2, transition: '250ms', color: this.state.emIn ? '#4e2e89' : 'black', opacity: this.state.emIn ? 1 : 0.65}}>info@learnerix.com</p>
                                    </div>
                                </a>

                                <a target="_blank" href="https://www.google.com/maps/place/The+Binary+by+Omniyat/@25.187692,55.267003,15z/data=!4m5!3m4!1s0x0:0xa2db82f588c4c7fb!8m2!3d25.1876918!4d55.2670029?hl=en-CA">
                                    <div 
                                    onMouseEnter={() => this.setState({loIn: true})} 
                                    onMouseLeave={() => this.setState({loIn: false})}
                                    className="contact-us-item" style={{position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                                        <div style={{width: '100%', height: '100%', position: 'absolute', left: 0, top: 0, zIndex: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: this.props.isSmall ? 25 : 0}}>
                                            <div style={{width: 50, height: 50, borderRadius: '50%', background: '#4e2e8920', transition: '250ms', transform: this.state.loIn ? 'scale(3)' : 'scale(0)'}} />
                                        </div>

                                        <EnvironmentTwoTone twoToneColor="#f26622" style={{fontSize: 65, zIndex: 1}} />
                                        <h4 style={{margin: 0, marginTop: 15, fontWeight: 'bolder'}}>Location</h4>
                                        <p style={{marginTop: -2, transition: '250ms', color: this.state.loIn ? '#4e2e89' : 'black', opacity: this.state.loIn ? 1 : 0.65}}>Business bay, Bur Dubai, UAE</p>
                                    </div>
                                </a>
                            </div>

                            <iframe  style={{marginTop: 50, height: 350, width: '100%',}} frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?q=The%20Binary%20by%20Omniyat%20tower%2C%20Al%20Abraj%20street%2C%20Business%20bay%2C%20Bur%20Dubai%2C%20UAE&amp;t=m&amp;z=15&amp;output=embed&amp;iwloc=near" aria-label="The Binary by Omniyat tower, Al Abraj street, Business bay, Bur Dubai, UAE"></iframe>

                            <h1 style={{marginTop: 55}}><ProfileFilled /> Inquiry Form</h1>
                            <div style={{marginTop: 40, width: this.props.isSmall ? '100%' : '40vw'}}>
                                <h5 style={{marginBottom: 2}}>Name</h5>
                                <Input maxLength={100} value={this.state.nameValue} onChange={e => this.setState({nameValue: e.target.value})} placeholder="Full name"></Input>

                                <FormPadding />

                                <h5 style={{marginBottom: 2}}>Phone</h5>
                                <Input maxLength={100} value={this.state.phoneValue} onChange={e => this.setState({phoneValue: e.target.value})} placeholder="Phone number"></Input>

                                <FormPadding />

                                <h5 style={{marginBottom: 2}}>Email</h5>
                                <Input maxLength={100} value={this.state.emailValue} onChange={e => this.setState({emailValue: e.target.value})} placeholder="Email address"></Input>

                                <FormPadding />

                                <h5 style={{marginBottom: 2}}>Subject</h5>
                                <Select style={{width: '100%'}} placeholder="Select a topic" value={this.state.topicValue} onChange={option => this.setState({topicValue: option})}>
                                    {Topics.map(topic => <Select.Option value={topic.value}>{topic.content}</Select.Option>)}
                                </Select>

                                <FormPadding />
                                <div style={{marginBottom: 2, display: 'flex', alignItems: 'flex-end'}}>
                                    <h5 style={{marginBottom: 0, flex: 1}}>Message</h5>
                                    <h6 style={{margin: 0}}>{this.state.messageValue.length}/1000</h6>
                                </div>
                                <Input.TextArea maxLength={1000} value={this.state.messageValue} onChange={e => this.setState({messageValue: e.target.value})} rows={8} placeholder="Write a message" />
                                
                                <FormPadding />
                                <FormPadding />

                                <ReCAPTCHA
                                    ref={this.captchaRef}
                                    sitekey="6Lcj99MaAAAAAHIUh60YFchcKdA-RX-WLcxdLTdb"
                                    onChange={value => this.setState({captchaValue: value})}
                                />

                                <FormPadding />
                                

                                <Button onClick={this.submit.bind(this)} loading={this.state.sendingInquiryForm} disabled={!this.canSubmit()} type="primary" style={{width: '100%'}}>Submit <CheckCircleFilled /></Button>

                                <div style={{height: 50}} />
                            </div>
                        </div>
                        
                    </div>
                </div>
                <FooterView />
            </div>
        )
    }

}

export default withMediaQuery(ContactUsPage);