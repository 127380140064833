import Api from "../../session/Api";
import SocketSession from "../../session/SocketSession";
import { SOCKET_EVENT_WEB_RTC_SIGNALLING } from "../../Constants";

import WebRTCSession, { WEB_RTC_MESSAGE_TYPE_OFFER, WEB_RTC_MESSAGE_TYPE_CANDIDATE } from './WebRTCSession'

class WebRTCReceiver extends WebRTCSession {

    hasVideo = false;
    hasAudio = false;

    createConnection() {
        super.createConnection();
        this.connection.ontrack = event => {
            if (this.ontrack !== undefined) {
                this.ontrack(event)
                this.ontrack = undefined
            }
        }
        
        if (this.hasVideo) {
            this.connection.addTransceiver('video', {
                direction: 'recvonly'
            })
        }
        if (this.hasAudio) {
            this.connection.addTransceiver('audio', {
                direction: 'recvonly'
            })
        }
    }

}

export default WebRTCReceiver;