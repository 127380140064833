
class NativeMediaPlayer {

    src;

    playing = false;
    eventListeners = [];
    lastSetCurrentTime = 0;

    init(src) {
        this.src = src;
    }

    play() {
        this.playing = true;
        if (this.mediaView) {
            this.mediaView.play()
        }
    }

    pause() {
        this.playing = false;
        if (this.mediaView) {
            this.mediaView.pause();
        }
    }

    setCurrentTime(currentTime) {
        this.lastSetCurrentTime = currentTime;
        if (this.mediaView) {
            this.mediaView.currentTime = currentTime;
        }
    }

    registerEvent(event, listener) {
        this.eventListeners.push({ event, listener })
        if (this.mediaView) {
            this.mediaView.addEventListener(event, listener);
        }
    }

    /**
     * @param {HTMLMediaElement} mediaView 
     */
    attachView(mediaView) {
        if (this.mediaView) {
            for (const eventListener of this.eventListeners) {
                this.mediaView.removeEventListener(eventListener.event, eventListener.listener);
            }
            this.mediaView.pause();
        }
        this.mediaView = mediaView;
        this.mediaView.preload = "auto"
        this.mediaView.src = this.src;
        this.mediaView.currentTime = this.lastSetCurrentTime;
        for (const eventListener of this.eventListeners) {
            this.mediaView.addEventListener(eventListener.event, eventListener.listener);
        }
        if (this.playing) {
            this.mediaView.play();
        }
    }

    cleanup() {
        if (this.mediaView) {
            for (const eventListener of this.eventListeners) {
                this.mediaView.removeEventListener(eventListener.event, eventListener.listener);
            }
        }
    }

}

export default NativeMediaPlayer;