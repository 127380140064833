import React from 'react';
import Error404Page from '../../pages/Error404Page';
import Window from '../../base/Window';
import Logo from '../../images/Logo.png'
import MediaQuery from 'react-responsive'

import {
    BrowserRouter as Router,
    Route,
    Switch,
    withRouter,
    Link
  } from 'react-router-dom'
import { ACCOUNT_SCOPE_FRONTEND, ACCOUNT_ROLE_TUTOR, ACCOUNT_ROLE_STUDENT, PRESENCE_AVAILABLE, PRESENCE_AWAY, PRESENCE_DO_NOT_DISTURB, PRESENCE_OFFLINE, APP_STORE_APP_LINK, GOOGLE_PLAY_APP_LINK } from '../../Constants';
import PostsView from '../../views/posts/PostsView';
import FollowingPostsPage from '../../pages/FollowingPostsPage';

import { Space, Row, Col, Button, Avatar, Menu, Popover, Badge, Input, Drawer, Dropdown } from 'antd';

import { 
    UserOutlined,
    SettingOutlined,
    HomeOutlined,
    MessageOutlined,
    BookOutlined,
    AlertOutlined,
    DownOutlined,
    ShareAltOutlined,
    BellFilled,
    SearchOutlined,
    MenuOutlined,
    AuditOutlined,
    ClusterOutlined,
    LoginOutlined,
    AppleOutlined,
    AndroidOutlined
} from '@ant-design/icons';
import MessagesPage from '../../pages/messages-page/MessagesPage';
import TutorProfilePage from '../../pages/tutor-profile-page/TutorProfilePage';
import RatingsPage from '../../pages/ratings-page/RatingsPage';
import SessionManager from '../../session/SessionManager';
import Api from '../../session/Api';
import DiscoveryPage from '../../pages/DiscoveryPage';
import SocketSession from '../../session/SocketSession';
import CourseSalePage from '../../pages/course-sale-page/CourseSalePage';
import TutorMyCoursesPage from '../../pages/TutorMyCoursesPage';
import TutorCoursePage from '../../pages/tutor-course-page/TutorCoursePage';
import StudentProfileDialog from '../../components/student-profile-dialog/StudentProfileDialog';
import StudentMyCoursesPage from '../../pages/StudentMyCoursesPage';

import "./public-window.less"
import AllCoursesPage from '../../pages/AllCoursesPage';
import CourseCategoryPage from '../../pages/CourseCategoryPage';
import AccountSettingsPage from '../../pages/AccountSettingsPage';
import StudentCoursePage from '../../pages/student-course-page/StudentCoursePage';
import { StickyContainer, Sticky } from 'react-sticky';
import TutorMyLiveSessionsPage from '../../pages/TutorMyLiveSessionsPage';
import TutorLiveSessionPage from '../../pages/tutor-live-session-page/TutorLiveSessionPage';
import LiveSessionSalePage from '../../pages/live-session-sale-page/LiveSessionSalePage';
import StudentMyLiveSessionsPage from '../../pages/StudentMyLiveSessionsPage';
import AllLiveSessionsPage from '../../pages/AllLiveSessionsPage';
import LiveSessionCategoryPage from '../../pages/LiveSessionCategoryPage';
import StudentLiveSessionPage from '../../pages/student-live-session-page/StudentLiveSessionPage';
import TutorHelpSessionPage from '../../pages/help-session-page/TutorHelpSessionPage';
import StudentHelpSessionHistoryPage from '../../pages/help-session-page/StudentHelpSessionHistoryPage';
import ActiveHelpSessionPage from '../../pages/help-session-page/ActiveHelpSessionPage';
import PresenceManager from '../../session/PresenceManager';
import UIUtil from '../../util/UIUtil';
import PresenceIndicator from '../../components/PresenceIndicator';
import SearchPage from '../../pages/SearchPage';
import Util from '../../util/Util';
import AllTutorsPage from '../../pages/AllTutorsPage';
import LandingPage from '../../pages/landing-page/LandingPage';
import PublicAuthWindow from './PublicAuthWindow';
import AllOrganizationsPage from '../../pages/AllOrganizationsPage';
import { isAndroid, isIOS } from 'react-device-detect';
import PrivacyPolicyPage from '../../pages/public-pages/PrivacyPolicyPage';
import AboutUsPage from '../../pages/public-pages/AboutUsPage';
import BecomeATutorPage from '../../pages/public-pages/BecomeATutorPage';
import TermsOfServicePage from '../../pages/public-pages/TermsOfServicePage';
import BlogPage from '../../pages/blog-pages/BlogPage';
import BlogPostPage from '../../pages/blog-pages/BlogPostPage';
import RawBlogPage from '../../pages/blog-pages/RawBlogPage';
import ContactUsPage from '../../pages/public-pages/ContactUsPage';
import PostPage from '../../pages/PostPage';
import HashtagPage from '../../pages/hashtag/HashtagPage';
import HashtagCoursesPage from '../../pages/hashtag/HashtagCoursesPage';
import HashtagLiveSessionsPage from '../../pages/hashtag/HashtagLiveSessionsPage';

const { SubMenu } = Menu;

class PublicWindow extends Window {
    constructor(props) {
        super(props);

        this.state = {
            searchValue: "",
        }
    }

    renderMenu(mode, clearMargin) {
        //const height = mode === "horizontal" ? '75px' : undefined;
        const menuStyle = mode === "horizontal" ? {height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '15px'} : undefined;
        const onClick = mode === "inline" ? (e => this.setState({drawerMenuOpen: false})).bind(this) : undefined
        
        return (
            <div style={{marginRight: clearMargin ? undefined : '25px', background: 'transparent'}}>
                <Button size="large" style={{marginRight: '5px'}}>
                    <Link to="/auth">Login</Link>
                </Button>
                <Dropdown overlay={
                    <Menu>
                        <Menu.Item onClick={e => this.props.history.push("/auth?registerType=0")} key="0" style={{height: '50px', display: 'flex', alignItems: 'center'}}>
                            <UserOutlined style={{fontSize: '20px'}} /> As Learner
                        </Menu.Item>
                        <Menu.Item onClick={e => this.props.history.push("/auth?registerType=1")} key="1" style={{height: '50px', display: 'flex', alignItems: 'center'}}>
                            <AuditOutlined style={{fontSize: '20px'}} /> As Tutor
                        </Menu.Item>
                        <Menu.Item onClick={e => this.props.history.push("/auth?registerType=2")} key="1" style={{height: '50px', display: 'flex', alignItems: 'center'}}>
                            <ClusterOutlined style={{fontSize: '20px'}} /> As Organization
                        </Menu.Item>
                    </Menu>
                } placement="bottomRight" trigger={['click']}>
                    <Button size="large" type="primary">Sign Up</Button>
                </Dropdown>
            </div>
        )
    }

    getLayout() {
        return (
            <div style={{height: 'auto', minHeight: '100vh', display: 'flex', flexDirection: 'column'}}>
                {/*<Sticky bottomOffset={80}>
                    {({ style }) => (*/}
                    {/*)}
                </Sticky>*/}

                <div className="sticky-header">
                    <MediaQuery maxWidth={619}>
                        <div style={{paddingRight: '2vw', height: '60px', boxShadow: "0px 0px 15px 4px rgba(0, 0, 0, 0.5)", display: 'flex', alignItems: 'center' }}>
                            <Link to="/"><img src={Logo} style={{height: '35px', marginLeft: '15px'}} /></Link>
                            <div style={{flex: '1'}} />
                            <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                                {isIOS && 
                                <a target="_blank" href={APP_STORE_APP_LINK}>
                                    <Button style={{marginRight: '5px'}}><AppleOutlined /> Use App</Button>
                                </a>}
                                {isAndroid &&
                                <a target="_blank" href={GOOGLE_PLAY_APP_LINK}>
                                <Button style={{marginRight: '5px'}}><AndroidOutlined /> Use App</Button>
                                </a>}
                                <Popover trigger="click" placement="bottomRight" content={
                                    <Input.Group size="large" compact>
                                        <Input.Search size="large" style={{ maxWidth: '300px' }} placeholder="Search Learnerix" 
                                        onPressEnter={e => Util.isStringExists(this.state.searchValue) ? this.props.history.push("/search?value=" + this.state.searchValue) : null} 
                                        onSearch={v => Util.isStringExists(this.state.searchValue) ? this.props.history.push("/search?value=" + this.state.searchValue) : null} 
                                        value={this.state.searchValue} onChange={e => this.setState({searchValue: e.target.value})} />
                                    </Input.Group>
                                }>
                                    <Button type="link"><SearchOutlined /></Button>
                                </Popover>
                                <Popover trigger="click" placement="bottomRight" content={
                                    this.renderMenu("horizontal", true)
                                }>
                                    <Button type="link"><LoginOutlined /></Button>
                                </Popover>
                            </div>
                        </div>
                    </MediaQuery>
                    <MediaQuery minWidth={620}>
                        <Row style={{paddingLeft: '25px', paddingRight: '25px', height: '75px', 
                                        boxShadow: "0px 0px 15px 4px rgba(0, 0, 0, 0.5)" }} align="middle">
                            <Col span={4}>
                                <Space>
                                    <Link to="/"><img src={Logo} style={{height: '50px'}} /></Link>
                                </Space>
                            </Col>
                            <Col span={20} style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                                <Input placeholder="Search Learnerix" onPressEnter={e => Util.isStringExists(this.state.searchValue) ? this.props.history.push("/search?value=" + this.state.searchValue) : null} value={this.state.searchValue} onChange={e => this.setState({searchValue: e.target.value})} prefix={<SearchOutlined style={{marginRight: '5px'}} />} className="header-search-field" style={{marginRight: '25px', height: '45px', borderRadius: '15px', boxShadow: "0px 0px 0px 0px rgba(0, 0, 0, 0.2)"}} />
                                {//onChange={e => this.onSearch(e.target.value)} />
                                }

                                {this.renderMenu("horizontal")}

                                {SessionManager.isLoggedInAsTutor() ? (
                                    <Dropdown overlay={
                                        <Menu>
                                            <Menu.Item onClick={() => this.setPresence(PRESENCE_AVAILABLE)} style={{display: 'flex', alignItems: 'center'}}>
                                                <div style={{height: '10px', background: 'green', width: '10px', marginRight: '5px', borderRadius: '50%'}} /> Available
                                            </Menu.Item>
                                            <Menu.Item onClick={() => this.setPresence(PRESENCE_AWAY)} style={{display: 'flex', alignItems: 'center'}}>
                                                <div style={{height: '10px', background: '#fbc02d', width: '10px', marginRight: '5px', borderRadius: '50%'}} /> Away
                                            </Menu.Item>
                                            <Menu.Item onClick={() => this.setPresence(PRESENCE_DO_NOT_DISTURB)} style={{display: 'flex', alignItems: 'center'}}>
                                                <div style={{height: '10px', background: 'red', width: '10px', marginRight: '5px', borderRadius: '50%'}} /> Do not disturb
                                            </Menu.Item>
                                            <Menu.Item onClick={() => this.setPresence(PRESENCE_OFFLINE)} style={{display: 'flex', alignItems: 'center'}}>
                                                <div style={{height: '10px', background: '#cecece', width: '10px', marginRight: '5px', borderRadius: '50%'}} /> Hidden
                                            </Menu.Item>
                                        </Menu>
                                    } trigger="click">
                                        <Button loading={this.state.settingPresence} type="text" style={{marginRight: '5px', display: 'flex', alignItems: 'center'}}>
                                            {/*<span style={{fontSize: '10px', marginRight: '5px'}}>⬤</span>*/}
                                            {this.renderPresence(this.state.presence)}
                                        </Button>
                                    </Dropdown>
                                ) : null}

                                {
                                    //auth button
                                }
                                
                                
                            </Col>
                        </Row>
                    </MediaQuery>
                </div>

                {SessionManager.isLoggedInAsStudent() ? (
                    <StudentProfileDialog 
                    visible={this.state.showLearnerProfile}
                    name={SessionManager.getAccount().fullName}
                    onProfilePicUpdate={this.onProfilePicUpdate.bind(this)}
                    onCancel={() => this.setState({showLearnerProfile: false})} />
                ) : null}

                <div style={{flex: '1', display: 'flex', flexDirection: 'column',}}>
                    <Switch>
                        <Route exact path="/" component={LandingPage} />

                        <Route exact path="/course/:courseId" component={StudentCoursePage} />
                        <Route exact path="/live-session/:liveSessionId" component={StudentLiveSessionPage} />

                        <Route exact path="/profile/:tutorId" component={TutorProfilePage} />
                        <Route exact path="/post/:postId" component={PostPage} />
                        

                        <Route exact path="/all-courses" component={AllCoursesPage} />
                        <Route exact path="/course-category/:categoryName" component={CourseCategoryPage} />

                        <Route exact path="/course-sale/:courseId" component={CourseSalePage} />

                        <Route exact path="/all-live-sessions" component={AllLiveSessionsPage} />
                        <Route exact path="/live-session-category/:categoryName" component={LiveSessionCategoryPage} />

                        <Route exact path="/live-session-sale/:liveSessionId" component={LiveSessionSalePage} />


                        <Route exact path="/reviews/:itemType/:itemId" component={RatingsPage} />

                        <Route exact path="/search" component={SearchPage} />
                        <Route exact path="/all-tutors" component={AllTutorsPage} />
                        <Route exact path="/all-organizations" component={AllOrganizationsPage} />

                        <Route exact path="/privacy-policy" component={PrivacyPolicyPage} />
                        <Route exact path="/terms-of-service" component={TermsOfServicePage} />
                        <Route exact path="/about-us" component={AboutUsPage} />
                        <Route exact path="/become-a-tutor" component={BecomeATutorPage} />
                        <Route exact path="/contact" component={ContactUsPage} />

                        <Route exact path="/blog" component={BlogPage} />
                        <Route exact path="/blog-post" component={BlogPostPage} />
                        
                        <Route exact path="/hashtag/:hashtag" component={HashtagPage} />
                        <Route exact path="/hashtag-courses/:hashtag" component={HashtagCoursesPage} />
                        <Route exact path="/hashtag-live-sessions/:hashtag" component={HashtagLiveSessionsPage} />

                        <Route component={Error404Page} />
                    </Switch>
                </div>

            </div>
        )
    }

    isLockBehindAuth() {
        return false;
    }

    getScope() {
        return -1;
    }

    getRole() {
        return -1;
    }
    
}

export default withRouter(PublicWindow);