import { Button, DatePicker, Popconfirm, Table, Tag, Tooltip } from 'antd'
import React from 'react'
import Util from '../../util/Util'

import {
    ClockCircleOutlined,
    TableOutlined,
    DollarCircleOutlined,
    CreditCardOutlined,
    SyncOutlined,
    DownCircleOutlined,
    CloseCircleOutlined,
    CheckCircleOutlined,
    CheckCircleFilled,
    CheckOutlined,
    CloseOutlined
} from '@ant-design/icons'
import Api from '../../session/Api'
import { ON_HOLD_DURATION_DAYS, PRODUCT_TYPE_COURSE, PRODUCT_TYPE_LIVE_SESSION, STATEMENTS_PAYMENT_METHOD_LEARNERIX_BALANCE, STATEMENTS_PAYMENT_METHOD_PAY_PAL, STATEMENTS_SOURCE_TYPE_PURCHASE, STATEMENTS_SOURCE_TYPE_SUBSCRIPTION, STATEMENTS_TYPE_EARNING, STATEMENTS_TYPE_SPENDING, STATEMENTS_TYPE_WITHDRAW } from '../../Constants'
import Modal from 'antd/lib/modal/Modal'
import SessionManager from '../../session/SessionManager'
import ProfilePicture from '../../components/ProfilePicture'
import UIUtil from '../../util/UIUtil'

class AllStatementsTableView extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            data: [],

            filterDateRange: null,
            
            showGenerateReportDialog: false,
            reportDateRange: null,
        }
    }

    componentDidMount() {
        Api.getAllStatements({
            dataSource: this.props.dataSource,
            loadInProgressOnly: this.props.loadInProgressOnly
        }, response => {
            if (response.status === true) {
                this.setState({
                    loading: false,
                    data: response.payload
                })
            }
        })
    }

    getData() {
        if (this.state.filterDateRange != null) {
            const startDate = this.state.filterDateRange[0].valueOf();
            const endDate = this.state.filterDateRange[1].valueOf();
            return this.state.data.filter(item => item.date >= startDate && item.date <= endDate)
        } else {
            return this.state.data;
        }
    }

    exportToSpreadsheet() {
        const startDate = this.state.reportDateRange[0].valueOf();
        const endDate = this.state.reportDateRange[1].valueOf();
        const data = this.state.data.filter(item => item.date >= startDate && item.date <= endDate);

        const rows = data.map(transaction => {
            let title = "Transaction";
            switch (transaction.type) {
                case STATEMENTS_TYPE_EARNING:
                    title = transaction.productTitle + " - " + this.getTransactionSubtitle(transaction) + " - " + this.getProductTypeName(transaction.productType);
                    break;
                case STATEMENTS_TYPE_SPENDING:
                    title = transaction.productTitle + " - Learnerix Balance Payment - " + this.getProductTypeName(transaction.productType);
                    break;
                case STATEMENTS_TYPE_WITHDRAW:
                    title = "User initiated withdrawal"
                    break;
            }

            let status = this.getTransactionTypeName(transaction.type);
            if (transaction.type == STATEMENTS_TYPE_EARNING && (new Date().getTime() - transaction.date < (ON_HOLD_DURATION_DAYS * 86400000))) { //15 days
                status += " - On Hold";
            }
            if (transaction.type == STATEMENTS_TYPE_WITHDRAW && !transaction.finalized && !transaction.cancelled) {
                status += " - In Review";
            }
            if (transaction.type == STATEMENTS_TYPE_WITHDRAW && transaction.finalized && transaction.cancelled) {
                const cancelReason = transaction.cancelByUserId == SessionManager.getAccount().id ? "Cancelled by user" : transaction.cancelReason;
                status += " - Cancelled (" + cancelReason + ")";
            }
            if (transaction.type == STATEMENTS_TYPE_WITHDRAW && transaction.finalized && !transaction.cancelled) {
                status += " - Approved on " + Util.getFullDate(transaction.approvedDate) + " (" + transaction.approvedMessage + ")";
            }

            let amount = "$" + (transaction.cancelled ? 0.00.toFixed(2) : Math.abs(transaction.amount).toFixed(2));
            if (transaction.type == STATEMENTS_TYPE_WITHDRAW && transaction.cancelled) {
                amount += " (Original value: $" + Math.abs(transaction.amount).toFixed(2) + ")" 
            }

            return [
                title, 
                status,
                Util.getFullDate(transaction.date),
                amount
            ]
        })
        
        let csvContent = "data:text/csv;charset=utf-8,";
        
        [["Transaction", "", "Date", "Amount"], ...rows].forEach(function(rowArray) {
            let row = rowArray.join(",");
            csvContent += row + "\r\n";
        });

        const encodedUri = encodeURI(csvContent);

        const a = document.createElement("a");
        a.href = encodedUri;
        a.download = "LearnerixReport.csv";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    render() {
        return (
            <div>
                <Table rowKey="id" loading={this.state.loading} columns={this.props.showWithdrawActions ? [...this.columns, ...this.withdrawColumns] : this.columns} dataSource={this.getData()} title={() => (
                    <div style={{display: 'flex'}}>
                        <div style={{flex: 1}} />
                        <DatePicker.RangePicker showTime showSecond={false}
                        minuteStep={5}
                        format="DD MMMM YYYY HH:mm" style={{marginRight: 10}} onChange={(dates) => this.setState({filterDateRange: dates})} />
                        <Button onClick={() => this.setState({showGenerateReportDialog: true, reportDateRange: null})} type="primary"><TableOutlined /> Export to spreadsheet</Button>
                    </div>
                )} />

                <Modal visible={this.state.showGenerateReportDialog} title="Generating Report" 
                okButtonProps={{disabled: this.state.reportDateRange == null}} onOk={this.exportToSpreadsheet.bind(this)}
                onCancel={() => this.setState({showGenerateReportDialog: false})}>
                    <p>Select data range for report</p>
                    <DatePicker.RangePicker showTime showSecond={false} value={this.state.reportDateRange} style={{width: '100%'}}
                    minuteStep={5} format="DD MMMM YYYY HH:mm" onChange={(dates) => this.setState({reportDateRange: dates})} />
                </Modal>
            </div>
        )
    }

    getProductTypeName(productType) {
        switch (productType) {
            case PRODUCT_TYPE_COURSE:
                return "Course";
            case PRODUCT_TYPE_LIVE_SESSION:
                return "Live Session";
            default:
                return "";
        }
    }

    getTransactionTypeName(transactionType) {
        switch (transactionType) {
            case STATEMENTS_TYPE_EARNING:
                return "Earning";
            case STATEMENTS_TYPE_SPENDING:
                return "Purchase";
            case STATEMENTS_TYPE_WITHDRAW:
                return "Withdraw";
        }
    }

    getTransactionSubtitle(transaction) {
        let subtitle = "";
        if (transaction.type == STATEMENTS_TYPE_EARNING) {
            switch (transaction.source) {
                case STATEMENTS_SOURCE_TYPE_PURCHASE:
                    subtitle = "Purchase Earning";
                    break;
                case STATEMENTS_SOURCE_TYPE_SUBSCRIPTION:
                    subtitle = "Subscription Earning";
                    break;
            }
        } else {
            subtitle = "Purchase";
        }

        return subtitle;
    }

    removeItem(id) {
        let newData = [...this.state.data];
        for (let i = 0; i < newData.length; i++) {
            if (newData[i].id == id) {
                newData.splice(i, 1);
                break;
            }
        }
        this.setState({data: newData});
    }

    withdrawColumns = [
        {
            title: '',
            key: 'action',
            render: (text, record) => {
                const Actions = (() => {
                    const [loading, setLoading] = React.useState(false);
    
                    const onReject = () => {
                        setLoading(true);
                        const reason = prompt("Please specify rejection reason")

                        Api.rejectWithdrawRequest(record.id, reason, response => {
                            setLoading(false);

                            if (response.status === true) {
                                UIUtil.showSuccess();
                                this.removeItem(record.id);
                            } else {
                                UIUtil.showError(response.message);
                            }
                        })
                    }
    
                    const onApprove = () => {
                        setLoading(true);

                        Api.approveWithdrawRequest(record.id, response => {
                            setLoading(false);

                            if (response.status === true) {
                                UIUtil.showSuccess();
                                this.removeItem(record.id);
                            } else {
                                UIUtil.showError(response.message);
                            }
                        })
                    }

                    return (
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Popconfirm title="Are you sure you want to reject this withdraw?" placement="topRight" onConfirm={onReject}>
                                <Button loading={loading} danger type="link" size="small"><CloseOutlined /></Button>
                            </Popconfirm>
                            <Popconfirm title="Are you sure you want to accept this withdraw?" placement="topRight" onConfirm={onApprove}>
                                <Button loading={loading} type="primary" size="medium" style={{marginLeft: '15px'}}><CheckOutlined /></Button>
                            </Popconfirm>
                        </div>
                    )
                });

                return (
                    <Actions />
                )
            },
        }
    ]

    columns = [
        {
            title: 'User',
            dataIndex: 'receiverUserId',
            key: 'receiverUserId',
            render: (userId, transaction) => {
                return <ProfilePicture userId={userId} clickable  />
            },
        },
        {
            title: 'Transaction',
            dataIndex: 'id',
            key: 'id',
            render: (id, transaction) => {
                switch (transaction.type) {
                    case STATEMENTS_TYPE_EARNING:
                        return (
                            <div>
                                <p style={{color: 'black', margin: 0, padding: 0}}>{transaction.productTitle}</p>
                                <p style={{color: '#f26622', margin: 0, padding: 0, fontSize: 10, marginTop: -5}}>
                                    {this.getTransactionSubtitle(transaction)}
                                </p>
                                <p style={{margin: 0, padding: 0, fontSize: 10, marginTop: -5}}>{this.getProductTypeName(transaction.productType)}</p>
                            </div>
                        )
                    case STATEMENTS_TYPE_SPENDING:
                        return (
                            <div>
                                <p style={{color: 'black', margin: 0, padding: 0}}>{transaction.productTitle}</p>
                                <p style={{color: '#f26622', margin: 0, padding: 0, fontSize: 10, marginTop: -5}}>
                                    Learnerix Balance Payment
                                </p>
                                <p style={{margin: 0, padding: 0, fontSize: 10, marginTop: -5}}>{this.getProductTypeName(transaction.productType)}</p>
                            </div>
                        )
                    case STATEMENTS_TYPE_WITHDRAW:
                        return <p style={{ margin: 0, padding: 0}}>User initiated withdrawal</p>
                }
                
                return <div />
            },
        },
        {
            title: '',
            dataIndex: 'type',
            key: 'type',
            render: (type, transaction) => <>
                {type == STATEMENTS_TYPE_EARNING &&
                <Tag color="green" icon={<DollarCircleOutlined />}>{this.getTransactionTypeName(type)}</Tag>}

                {type == STATEMENTS_TYPE_SPENDING &&
                <Tag color="magenta" icon={<CreditCardOutlined />}>{this.getTransactionTypeName(type)}</Tag>}

                {type == STATEMENTS_TYPE_EARNING && transaction.paymentMethod == STATEMENTS_PAYMENT_METHOD_PAY_PAL &&
                <Tooltip title={"PayPal Order ID: " + transaction.payPalOrderId}>
                    <Tag color="gold">PayPal</Tag>
                </Tooltip>}

                {type == STATEMENTS_TYPE_EARNING && transaction.paymentMethod == STATEMENTS_PAYMENT_METHOD_LEARNERIX_BALANCE &&
                <Tooltip title={"Learnerix Payment ID: " + transaction.learnerixBalanceTransactionId}>
                    <Tag color="purple">Learnerix Balance</Tag>
                </Tooltip>}

                {type == STATEMENTS_TYPE_WITHDRAW &&
                <Tag icon={<DownCircleOutlined />}>{this.getTransactionTypeName(type)}</Tag>}

                {type == STATEMENTS_TYPE_EARNING && (new Date().getTime() - transaction.date < (ON_HOLD_DURATION_DAYS * 86400000)) && //15 days 
                <Tooltip title="Transactions from purchases will be on hold for 3 days after the payment date. Your balance includes these amounts as well but they can not be withdrawn (Display only).">
                    <Tag color="processing" icon={<ClockCircleOutlined />} >On Hold</Tag>
                </Tooltip>}

                {type == STATEMENTS_TYPE_WITHDRAW && !transaction.finalized && !transaction.cancelled &&
                <Tooltip title="Withdraw request is under review">
                    <Tag color="warning" icon={<SyncOutlined spin />}>In Review</Tag>
                </Tooltip>}

                {type == STATEMENTS_TYPE_WITHDRAW && transaction.finalized && transaction.cancelled &&
                <Tooltip title={transaction.cancelByUserId == SessionManager.getAccount().id ? "Cancelled by user" : transaction.cancelReason}>
                    <Tag color="error" icon={<CloseCircleOutlined />}>Cancelled</Tag>
                </Tooltip>}

                {type == STATEMENTS_TYPE_WITHDRAW && transaction.finalized && !transaction.cancelled &&
                <Tooltip title={transaction.approvedMessage}>
                    <Tag icon={<CheckCircleFilled />} color="#217000">Approved - {Util.getFullDate(transaction.approvedDate)}</Tag>
                </Tooltip>}
                
            </>,
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: date => Util.getFullDate(date)
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (value, transaction) => (
                <div>
                    <p style={{color: value < 0 ? (transaction.type == STATEMENTS_TYPE_SPENDING ? "red" : "") : "green", margin: 0, padding: 0}}>
                        ${transaction.cancelled ? 0.00.toFixed(2) : Math.abs(value).toFixed(2)}
                    </p>
                    {transaction.type == STATEMENTS_TYPE_WITHDRAW && transaction.cancelled &&
                    <p style={{fontSize: 10, margin: 0, padding: 0}}>
                        Original value: ${Math.abs(transaction.amount).toFixed(2)}  
                    </p>}
                </div>
            )
        },
    ]
}

export default AllStatementsTableView;