import { Button, Tag, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import Api from '../../session/Api';

import { 
    ReloadOutlined,
    LoadingOutlined,
    CustomerServiceFilled,
    EyeFilled,
    NotificationFilled,
    CloseCircleFilled
} from '@ant-design/icons';
import ProfilePicture from '../../components/ProfilePicture';
import SessionManager from '../../session/SessionManager';

import RaiseHandIconBlack from '../../images/RaiseHandIconBlack.svg';
import { ACCOUNT_ROLE_STUDENT } from '../../Constants';
import Util from '../../util/Util';
import StudentProfileDialog from '../../components/student-profile-dialog/StudentProfileDialog';
import UIUtil from '../../util/UIUtil';

const Header = ({Icon, customIcon, title, count, max, theme}) => (
    <div style={{display: 'flex', alignItems: 'center', marginLeft: 10, marginRight: 10}}>
        <div style={{width: 15, marginRight: 7, height: 1, backgroundColor: theme.sideTextColor, opacity: 0.15}} />

        {customIcon !== undefined ? customIcon : <Icon style={{fontSize: 10, color: theme.sideTextColor}} />}
        <h5 style={{margin: 0, marginLeft: 5, color: theme.sideTextColor}}>{title}</h5>

        <div style={{flex: 1, marginLeft: 7, marginRight: 7, height: 1, backgroundColor: theme.sideTextColor, opacity: 0.15}} />

        <p style={{margin: 0, marginLeft: 0, fontSize: 8, marginTop: 0, color: theme.sideTextColor}}>({count}{max && '/'}{max})</p>

        <div style={{width: 10, marginLeft: 7, height: 1, backgroundColor: theme.sideTextColor, opacity: 0.15}} />
    </div>
)

const UserItem = ({user, host, liveStreamId, onOpenStudentDialog, theme}) => {
    const [hover, setHover] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);

    useEffect(() => setLoadingBtn(false), [user.id])

    const setStageUser = (userId, add) => {
        setLoadingBtn(true);
        Api.setLiveStreamStageUser2(liveStreamId, userId, add, response => {
            if (response.status === true) {
                UIUtil.showSuccess();
            } else {
                UIUtil.showError(response.message);
                setLoadingBtn(false);
            }
        })
    }

    const onRemoveFromStageBtn = e => {
        e.stopPropagation();
        setStageUser(user.id, false);
    }

    const onBringToStageBtn = e => {
        e.stopPropagation();
        setStageUser(user.id, true)
    }

    return (
        <div key={user.id} onClick={() => {
            //if (user.role === ACCOUNT_ROLE_STUDENT) {
                //onOpenStudentDialog(user)
            //} else {
                window.open(Util.getProfilePath(user.id), "_blank");
            //}
        }}
        onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
        className="live-stream-user-item" style={{marginLeft: 10, marginRight: 10, marginTop: 5, display: 'flex', alignItems: 'center',}}>
            <ProfilePicture size={20} userId={user.id} />
            <p style={{margin: 0, fontSize: 12, marginLeft: 7, flex: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{user.fullName}</p>
    
            <div style={{transition: '250ms', transform: hover ? 'translateX(0px)' : 'translateX(400px)'}}>
                {SessionManager.getAccount().id == user.id ? (
                    <h6 style={{margin: 0, marginRight: 5, color: '#f26622'}}>Yourself</h6>
                ) : (
                    host ? (
                        user.stage ? (
                            <Tooltip title="Remove from stage">
                                <div className="live-stream-active-link">
                                    <Button disabled={loadingBtn} onClick={onRemoveFromStageBtn} size="small" type="link" style={{fontSize: 12}}>{loadingBtn ? <LoadingOutlined spin /> : <CloseCircleFilled />}</Button>
                                </div>
                            </Tooltip>
                        ) : (
                            <Tooltip title="Bring to stage">
                                <div className="live-stream-active-link">
                                    <Button disabled={loadingBtn} onClick={onBringToStageBtn} size="small" type="link" style={{fontSize: 12}}>{loadingBtn ? <LoadingOutlined spin /> : <NotificationFilled />}</Button>
                                </div>
                            </Tooltip>
                        )
                    ) : (
                        null
                    )
                )}
            </div>
        </div>
    )
}

const Padding = () => <div style={{height: 25}} />

export default function LiveStreamUserList({ userListKey, liveStreamId, host, theme, ...props}) {
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState(undefined);

    const [studentDialogUser, setStudentDialogUser] = useState(undefined);

    const loadUsers = () => {
        setLoading(users !== undefined ? false : true);
        Api.getLiveStreamUsers(liveStreamId, response => {
            if (response.status === true) {
                setUsers(response.payload);
            } else {
                setUsers(undefined)
            }
            setLoading(false);
        })
    }

    useEffect(loadUsers, [liveStreamId, userListKey])

    if (loading) {
        return (
            <div style={{marginTop: 25, marginBottom: 25, width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <LoadingOutlined style={{color: '#4e2e89', fontSize: 16}} />
            </div>
        )
    }

    if (users === undefined) {
        return (
            <div style={{marginTop: 25, marginBottom: 25, width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{margin: 0, fontSize: 12, textAlign: 'center'}}>Failed to load users</p>
                <a style={{fontSize: 12, marginTop: 10}} onClick={loadUsers}><ReloadOutlined /> Reload</a>
            </div>
        )
    }

    const broadcasters = users.filter(user => user.host);
    const stageUsers = users.filter(user => user.stage);
    const raisedHandUsers = users.filter(user => user.raisedHand);

    const otherUsers = users.filter(user => !user.host && !user.stage && !user.raisedHand);

    const onOpenStudentDialog = (user) => setStudentDialogUser(user);

    return (
        <div style={{paddingTop: 10, paddingBottom: 10}}>
            <Header theme={theme} Icon={CustomerServiceFilled} title="Broadcaster" count={broadcasters.length} max={1} />
            {broadcasters.map(user => <UserItem theme={theme} user={user} host={host} liveStreamId={liveStreamId} onOpenStudentDialog={onOpenStudentDialog} />)}
            
            {stageUsers.length > 0 && <>
                <Padding />
                <Header theme={theme} Icon={NotificationFilled} title="Stage" count={stageUsers.length} max={3} />
                {stageUsers.map(user => <UserItem theme={theme} user={user} host={host} liveStreamId={liveStreamId} onOpenStudentDialog={onOpenStudentDialog} />)}
            </>}

            {raisedHandUsers.length > 0 && <>
                <Padding />
                <Header theme={theme} customIcon={<img width={10} height={10} src={RaiseHandIconBlack} />} title="Hand Raised" count={raisedHandUsers.length} />
                {raisedHandUsers.map(user => <UserItem theme={theme} user={user} host={host} liveStreamId={liveStreamId} onOpenStudentDialog={onOpenStudentDialog} />)}
            </>}

            <Padding />
            <Header theme={theme} Icon={EyeFilled} title="Viewers" count={otherUsers.length} />
            {otherUsers.map(user => <UserItem theme={theme} user={user} host={host} liveStreamId={liveStreamId} onOpenStudentDialog={onOpenStudentDialog} />)}

            <StudentProfileDialog
            showSendMessage targetBlank
            visible={studentDialogUser !== undefined}
            name={studentDialogUser !== undefined ? studentDialogUser.fullName : ""}
            userId={studentDialogUser !== undefined ? studentDialogUser.id : 0}
            onCancel={() => setStudentDialogUser(undefined)} />
        </div>
    )
}