import React from 'react'
import Api from '../session/Api';

class ProgressAwareMedia extends React.Component {

    constructor(props) {
        super(props);

        this.mediaRef = React.createRef();

        this.isSetInitialPosition = false;
    }

    updateProgress() {
        if (!this.isSetInitialPosition) {
            return;
        }

        if (this.mediaRef.current !== undefined && this.mediaRef.current !== null) {
            if (this.props.progressListener !== undefined) {
                const currentProgress = this.mediaRef.current.currentTime;


                let roundThreshold = (25 / 100) * this.mediaRef.current.duration;
                if (roundThreshold > (120)) {//2 minutes
                    roundThreshold = 120;
                }

                const progress = Math.floor(currentProgress);
            
                if (progress != this.lastProgress) {
                    this.lastProgress = progress;
                    this.props.progressListener(progress, (this.mediaRef.current.duration - currentProgress) <= roundThreshold);
                }
            }
        }
    }

    componentDidMount() {
        this.progressChecker = setInterval(() => {
            this.updateProgress();
        }, 30 * 1000)
    }

    componentWillUnmount() {
        clearInterval(this.progressChecker);
        this.onPause();
        this.updateProgress();
    }

    onPlay() {
        if (!this.isSetInitialPosition) {
            this.isSetInitialPosition = true;
            this.mediaRef.current.currentTime = this.props.initialPos;
        }

        if (this.props.trackCourseLectureId !== undefined) {
            this.trackCourseLectureTrackerId = undefined;
            Api.startCourseLectureTracker(this.props.trackCourseLectureId, response => {
                if (response.status === true) {
                    this.trackCourseLectureTrackerId = response.payload;
                }
            })
        }
    }

    onPause() {
        if (this.trackCourseLectureTrackerId !== undefined) {
            Api.stopCourseLectureTracker(this.props.trackCourseLectureId, this.trackCourseLectureTrackerId, () => {})
            this.trackCourseLectureTrackerId = undefined;
        }
    }

    render() {
        if (this.props.video) {
            return <video ref={this.mediaRef} onPlay={this.onPlay.bind(this)} onPause={this.onPause.bind(this)} {...this.props} onContextMenu={e => e.preventDefault()} controlsList="nodownload" />
        } else {
            return <audio ref={this.mediaRef} onPlay={this.onPlay.bind(this)} onPause={this.onPause.bind(this)} {...this.props} onContextMenu={e => e.preventDefault()} controlsList="nodownload" />
        }
    }

}

export default ProgressAwareMedia;