import React from 'react';

import { Layout, Input, Button, Spin, Empty } from 'antd';

import { 
    CameraOutlined,
    SendOutlined,
    PaperClipOutlined,
} from '@ant-design/icons';

import ProfilePicture from '../../components/ProfilePicture';

import InfiniteScroll from 'react-infinite-scroller';

import { motion } from "framer-motion"
import CommentItem from './CommentItem';
import Api from '../../session/Api';
import { NO_PAGE_TOKEN, PAGINATION_PAGE_CONTENT_COUNT } from '../../Constants';

import LogoSquare from '../../images/LogoSquare.png'
import UIUtil from '../../util/UIUtil';
import SessionManager from '../../session/SessionManager';
import Util from '../../util/Util';

class CommentsView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            newCommentContent: undefined,

            items: [],
            page: NO_PAGE_TOKEN,
            hasMore: true,
            loadingItems: false,

            commenting: false,
            disableAdd: false,
        }
    }

    getNextComments() {
        if (this.state.loadingItems) {
            return;
        }

        this.setState({
            loadingItems: true
        })
        //list error
        const listener = response => {
            if (response.status === true) {
                let items = this.state.items;
                items.push(...response.payload.items);

                this.setState({
                    items: items,
                    page: response.payload.token,
                    hasMore: Util.isStringExists(response.payload.token),
                    loadingItems: false
                })
            } else {
                //alert('errowr');
            }
        };

        if (this.props.customParentType !== undefined) {
            Api.getComments(this.props.customParentId, this.props.customParentType, this.state.page, listener);
        } else {
            Api.getPostComments(this.props.postId, this.state.page, listener);
        }
    };

    onCommentBtn() {
        if (this.state.newCommentContent.trim().length === 0) {
            return;
        }

        this.setState({commenting: true});
        const listener = response => {
            if (response.status === true) {
                let items = this.state.items;

                items.unshift({
                    ...response.payload,
                    animate: true
                })
                //if (items.length > 1 && ((items.length - 1) % PAGINATION_PAGE_CONTENT_COUNT) === 0 && this.state.hasMore) {
                    //items.pop();
                //}

                this.setState({
                    items: items,
                    newCommentContent: "",
                    commenting: false
                })

                if (this.props.onCommentAdded !== undefined) {
                    this.props.onCommentAdded();
                }
            } else {
                UIUtil.showError();

                this.setState({
                    commenting: false
                })
            }
        };

        if (this.props.customParentType !== undefined) {
            Api.newComment(this.state.newCommentContent, this.props.customParentId, this.props.customParentType, listener);
        } else {
            Api.newPostComment(this.state.newCommentContent, this.props.postId, listener);   
        }
    }

    onCommentItemDelete(index) {
        let newItems = [...this.state.items];
        newItems.splice(index, 1);
        this.setState({items: newItems})

        if (this.props.onCommentRemoved !== undefined) {
            this.props.onCommentRemoved();
        }
    }

    render() {
        return (
            <div style={{ height: this.props.windowMode ? undefined : '100%', overflow: 'auto', padding: '15px'}}>
                {//!this.props.disableAdd && <>
                SessionManager.isLoggedIn() &&
                <>
                <div style={{display: 'flex'}}>
                    {this.props.adminComments ? (
                        <img src={LogoSquare} style={{width: '32px', height: '32px', marginTop: '15px'}} />
                    ) : (
                        <ProfilePicture style={{ marginTop: '15px' }} />
                    )}
                    <Input.TextArea rows={2} placeholder={"Write a " + (this.props.replyMode ? "reply" : "comment") + "..."} value={this.state.newCommentContent}
                        onChange={(e) => this.setState({newCommentContent: e.target.value})}
                        style={{ borderRadius: '15px', paddingTop: '15px', marginLeft: '15px'}} />
                </div>
                {this.state.newCommentContent !== undefined ?
                    <motion.div initial={this.state.newCommentContent !== '' ? { height: 0, opacity: 0 } : { height: 55, opacity: 1 }} 
                    animate={this.state.newCommentContent === '' ? { height: 0, opacity: 0 } : { height: 55, opacity: 1 }}>
                        <div style={{display: 'flex', justifyContent: 'flex-end', paddingTop: '15px', overflow: 'hidden', height: '100%'}}>
                            <Button loading={this.state.commenting} type="primary" size="large" onClick={() => this.onCommentBtn()} style={{width: '100px', marginLeft: '15px'}}>{this.props.replyMode ? "Reply" : "Comment"}</Button>
                        </div>
                    </motion.div> 
                : null} </>}

                <InfiniteScroll
                    loadMore={this.getNextComments.bind(this)}
                    hasMore={this.state.hasMore}
                    threshold={this.props.windowMode ? 750 : undefined}
                    useWindow={this.props.windowMode ? true : false}
                    loader={
                        <div style={{display: 'flex', justifyContent: 'center', marginTop: '45px', marginBottom: '45px'}}>
                            <Spin size="large" tip="Loading..." />
                        </div>
                    }
                    style={{overflow: 'visible'}}>

                    {this.state.items.map((item, index) => {
                        return ( 
                            <div key={item.id}>
                                <br />
                                {item.animate ? 
                                    (
                                        <motion.div initial={{ opacity: 0, y: -125 }} animate={{ opacity: 1, y: 0 }}>
                                            <CommentItem parentOwner={this.props.parentOwner} onItemDeleted={() => this.onCommentItemDelete(index)} comment={item} />
                                        </motion.div> 
                                    ) : (
                                        <CommentItem parentOwner={this.props.parentOwner} onItemDeleted={() => this.onCommentItemDelete(index)} comment={item} />
                                    )
                                }
                            </div>
                        )
                    })}
                </InfiniteScroll>

                {!this.state.loadingItems ? <Empty style={{marginTop: '50px'}} image={Empty.PRESENTED_IMAGE_SIMPLE} description={this.state.items.length > 0 ? "No more " + (this.props.replyMode ? "replies" : "comments") : "No " + (this.props.replyMode ? "replies" : "comments")} /> : null}

                <br />
                
            </div>
        )
    }
}

export default CommentsView;