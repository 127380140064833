import React from 'react'
import Api from '../../session/Api';
import Util from '../../util/Util';

import NoImage from '../../images/NoImage.png';
import { Tooltip, Button, Popconfirm } from 'antd';

import {  
    EyeOutlined,
    EyeInvisibleOutlined,
    EditOutlined,
    DeleteOutlined,
    VideoCameraOutlined,
    AudioOutlined,
    FileOutlined,
    TeamOutlined,
    PaperClipOutlined,
    PlayCircleOutlined
} from '@ant-design/icons';
import UIUtil from '../../util/UIUtil';
import Modal from 'antd/lib/modal/Modal';
import ScheduleAttachmentsView from './ScheduleAttachmentsView';
import LiveStreamWindow from '../../livestream2/windows/LiveStreamWindow';

class LiveSessionScheduleItem extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            deleteLoading: false,

            showAttachmentsDialog: false,
            showPlayRecordingDialog: false
        }
    }

    onDeleteBtn() {
        this.setState({deleteLoading: true})
        Api.removeLiveSessionSchedule(this.props.schedule.id, response => {
            if (response.status === true) {
                this.props.onItemDeleted();
            } else {
                UIUtil.showError();
            }
            this.setState({deleteLoading: false})
        })
    }

    render() {
        const schedule = this.props.schedule;
        const deletable = this.props.deletable;

        const thumbnailWidth = '20%'
        const thumbnailHeight = '175px'
        return (
            <div style={{width: '100%', borderRadius: '15px', padding: '15px', boxShadow: "0px 0px 25px 0px rgba(0, 0, 0, 0.2)", display: 'flex'}}>
                <div style={{width: thumbnailWidth, height: thumbnailHeight, objectFit: 'cover', borderRadius: '15px'}}>
                    <div style={{height: '100%', width: '100%', position: 'relative',}} onClick={this.props.onClick}>
                        <img src={Api.getLiveSessionScheduleThumbnail(schedule.id) + (this.props.thumbnailSuffix !== undefined ? ("&t=" + this.props.thumbnailSuffix) : "")} style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: '15px'}} onError={e => {
                            e.target.onerror = null;
                            e.target.src = NoImage;
                        }} />
                        {/*<div style={{width: '50px', height: '50px', borderRadius: '50%', background: '#00000050', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', pointerEvents: 'none'}}>
                        </div>*/}
                    </div>
                </div>

                {/*<img src={Api.getCourseItemThumbnail(courseItem.id)} style={{width: '20%', height: '175px', objectFit: 'cover', borderRadius: '15px'}} onError={e => {
                    e.target.onerror = null;
                    e.target.src = NoImage;
                }} />*/}
                <div style={{marginLeft: '25px', flex: 1, minWidth: 1}}>
                    <div style={{display: 'flex', alignItems: 'center', marginRight: '5px'}}>
                        <h2 style={{height: '25px'}} onClick={this.props.onClick}>{schedule.title}</h2>
                        {Util.isNumberExist(schedule.date) && <Button type="link" style={{cursor: 'default'}}>{Util.getFullDate(schedule.date)}</Button>}
                        {Util.isNumberExist(schedule.duration) && <Button type="link" style={{cursor: 'default'}}>{Util.formatSeconds(schedule.duration * 60)}</Button>}
    
                        <div style={{flex: '1'}} />
                        
                        {this.props.studentMode ? <>
                            {schedule.hidden &&
                            <Button type="text" size="large" style={{cursor: 'default'}}><EyeInvisibleOutlined /></Button>}
                        </> : <>

                            {schedule.hasAttendanceReport &&
                            <Tooltip title="Attendance Report" placement="top">
                               <Button type="link" size="large" onClick={e => {
                                    e.stopPropagation();
                                    this.props.onViewAttendanceReport();
                                }}><TeamOutlined /></Button>
                            </Tooltip>}

                            {this.props.recorded && (schedule.date + (schedule.duration * 60000)) <= new Date().getTime() &&
                            <Tooltip title="Play Recording" placement="top">
                                <Button danger type="link" size="large" onClick={e => {
                                    e.stopPropagation();
                                    this.setState({showPlayRecordingDialog: true})
                                }}><PlayCircleOutlined /></Button>
                            </Tooltip>} 

                            <Tooltip title="Attachments" placement="top">
                                <Button type="link" size="large" onClick={e => {
                                    e.stopPropagation();
                                    this.setState({showAttachmentsDialog: true})
                                }}><PaperClipOutlined /></Button>
                            </Tooltip>
 
                            <Tooltip title="Edit" placement="topRight">
                                <Button type="link" size="large" onClick={e => {
                                    e.stopPropagation();
                                    this.props.onEditBtn()
                                }}><EditOutlined /></Button>
                            </Tooltip>
        
                            {deletable ? 
                                <Popconfirm title="Are you sure you want to remove this schedule?" onConfirm={this.onDeleteBtn.bind(this)} placement="topRight">
                                    <Button danger type="link" size="large" onClick={e => e.stopPropagation()} loading={this.state.deleteLoading}><DeleteOutlined /></Button>
                                </Popconfirm>
                            : null}
                        </>}
                    </div>
                    
                    {//<p style={{paddingTop: '5px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', flex: '1'}}>
                    }
                    <p style={{paddingTop: '5px', marginTop: '5px', overflowWrap: 'break-word', whiteSpace: 'pre-line'}}>{schedule.description}</p>
                </div>

                {this.state.showAttachmentsDialog &&
                <Modal 
                title="Attachments"
                cancelText={null}
                footer={null}
                visible={this.state.showAttachmentsDialog} onCancel={() => this.setState({showAttachmentsDialog: false})}>
                    <ScheduleAttachmentsView scheduleId={schedule.id} canAdd />
                </Modal>}

                {this.state.showPlayRecordingDialog &&
                <Modal width="95vw" 
                closable={false}
                visible={this.state.showPlayRecordingDialog} 
                centered footer={null} bodyStyle={{padding: '0px'}} onCancel={() => this.setState({showPlayRecordingDialog: false})}>
                    <div style={{display: 'flex', height: '90vh'}}>
                        <LiveStreamWindow recordingMode liveStreamId={schedule.liveStreamId} onBack={() => this.setState({showPlayRecordingDialog: false})} />
                    </div>
                </Modal>}
            </div>
        )
    }
}

export default LiveSessionScheduleItem;