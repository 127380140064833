import React from 'react'

import { 
    AudioFilled
} from '@ant-design/icons';
import Util from '../../util/Util';

export function AudioStreamIndicator({stream, ...props}) {

    return (
        <div style={{borderRadius: 25, paddingLeft: 2.5, marginLeft: 10, marginTop: 5, background: '#210000', display: 'flex', alignItems: 'center', height: 25}}>
            <div style={{ width: 20, height: 20, borderRadius: '50%', background: '#990000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <AudioFilled style={{color: 'white', fontSize: 14, margin: 0}} />
            </div>

            <div style={{width: 10}} />

            <p style={{margin: 0, fontSize: 12, opacity: 0.85, overflow: 'hidden', textOverflow: 'ellipsis'}}>
                {Util.isStringExists(stream.userFullName) ? stream.userFullName.split(" ")[0] : ''}
            </p>
            <p style={{margin: 0, marginLeft: 4, fontSize: 10, opacity: 0.65, fontStyle: 'italic'}}>({stream.userIsHost ? 'host' : 'stage'})</p>

            <div style={{width: 10}} />
        </div>
    )
}