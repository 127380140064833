import Api from "../../session/Api";
import SocketSession from "../../session/SocketSession";
import { SOCKET_EVENT_WEB_RTC_SIGNALLING } from "../../Constants";

export const WEB_RTC_MESSAGE_TYPE_OFFER = 0;
export const WEB_RTC_MESSAGE_TYPE_CANDIDATE = 1;

class WebRTCSession {

    iceCandidateQueue = [];

    start(endpointId) {
        SocketSession.registerListener(SOCKET_EVENT_WEB_RTC_SIGNALLING, this.webRTCSignalListener);

        this.webRTCEndpointId = endpointId;
        this.createConnection();     
    }

    createConnection() { 
        this.connection = new RTCPeerConnection({
            iceServers: [
                {
                    urls: 'stun:130.61.235.42:3478'
                },
                {
                    urls: 'turn:130.61.235.42:3478',
                    username: 'turnuser',
                    credential: 'b1FCWLIvUpjns9po'
                }
            ]
        });
        //this.connection = new RTCPeerConnection({});
        this.connection.onconnectionstatechange = () => {
            //console.log("stae: " + this.connection.connectionState)
            if (this.connection.connectionState == "connected") {
                if (this.onconnected !== undefined) {
                    this.onconnected();
                    this.onconnected = undefined;
                }
            } else if (this.connection.connectionState == "disconnected" || this.connection.connectionState == "failed" || this.connection.connectionState == "closed") {
                this.onFailed();
            }
        }
        this.connection.onnegotiationneeded = () => {
            //console.log("Starting offer")
            this.connection.createOffer()
            .then(offer => this.connection.setLocalDescription(offer))
            .then(() => {
                this.signal(WEB_RTC_MESSAGE_TYPE_OFFER, this.connection.localDescription, answer => {
                    //console.log("resosne asnwer:", answer)
                    this.connection.setRemoteDescription(new RTCSessionDescription({
                        type: 'answer',
                        sdp: answer
                    }))
                    .then(() => {
                        this.iceCandidateQueue.forEach(candidate => this.connection.addIceCandidate(candidate));
                        this.iceCandidateQueue = [];
                    })
                    .catch(e => this.onFailed());
                })
            })
            .catch(error => this.onFailed());
        };

        this.connection.onicecandidate = event => {
            if (event.candidate) {
                this.signal(WEB_RTC_MESSAGE_TYPE_CANDIDATE, event.candidate)
            }
        }
    }

    signal(type, message, successListener) {
        Api.signalWebRTC({
            endpointId: this.webRTCEndpointId,
            type: type,
            message: message
        }, response => {
            if (response.status === true) {
                if (successListener !== undefined) {
                    successListener(response.payload);
                }
            } else {
                this.onFailed();
            }
        })
    }

    onWebRTCSignal({type, message}) {
        switch (type) {
            case WEB_RTC_MESSAGE_TYPE_CANDIDATE:
                const candidate = new RTCIceCandidate({
                    candidate: message.candidate,
                    sdpMid: message.sdpMid,
                    sdpMLineIndex: message.sdpMLineIndex
                });

                if (this.connection.remoteDescription !== undefined && this.connection.remoteDescription !== null) {
                    this.connection.addIceCandidate(candidate);
                } else {
                    this.iceCandidateQueue.push(candidate);
                }
                break;
        }
        
    }
    webRTCSignalListener = this.onWebRTCSignal.bind(this);

    onFailed() {

    }

    close() {
        if (this.connection !== undefined) {
            this.onconnected = undefined;
            this.ontrack = undefined;
            this.connection.close();
            this.connection = undefined;
        }
        SocketSession.unregisterListener(SOCKET_EVENT_WEB_RTC_SIGNALLING, this.webRTCSignalListener);
    }
}

export default WebRTCSession;