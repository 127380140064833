import React from 'react'
import Api from '../../session/Api';
import { NO_PAGE_TOKEN, PAGINATION_PAGE_CONTENT_COUNT } from '../../Constants';
import { Empty, Spin, Row, Col } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import Util from '../../util/Util';
import GridBlogPostItem from './GridBlogPostItem';
import ListBlogPostItem from './ListBlogPostItem';

class ListBlogPostView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            items: [],
            page: NO_PAGE_TOKEN,
            hasMore: true,
            loadingItems: false,
        }
    }

    getNextBlogPosts() {
        if (this.state.loadingItems) {
            return;
        }

        this.setState({
            loadingItems: true
        })

        //list error
        const listener = response => {
            if (response.status === true) {
                let items = this.state.items;
                items.push(...response.payload.items);

                this.setState({
                    items: items,
                    page: response.payload.token,
                    hasMore: Util.isStringExists(response.payload.token),
                    loadingItems: false
                })
            } else {
                //alert('errowr');
            }
        }

        if (this.props.filterTag != -1) {
            Api.getHomeBlogPostsByTag(this.props.filterTag, this.state.page, listener);
        } else {
            Api.getHomeBlogPosts(this.state.page, listener);
        }
    }

    count() {
        return this.props.count !== undefined ? this.props.count : 3;
    }

    renderItems() {
        const items = this.state.items;
        const elements = [];

        for (const item of items) {
            elements.push(
                <div style={{marginTop: elements.length === 0 ? '0px' : '17px'}}>
                    <ListBlogPostItem blogPost={item} />
                </div>
            )
        }

        return elements;
    }

    render() {
        return (
            <div>
                <InfiniteScroll
                    loadMore={this.getNextBlogPosts.bind(this)}
                    hasMore={this.state.hasMore}
                    threshold={750}
                    loader={
                        <div style={{display: 'flex', justifyContent: 'center', marginTop: '45px', marginBottom: '45px'}}>
                            <Spin size="large" tip="Loading..." />
                        </div>
                    }
                    style={{overflow: 'visible'}}>

                    {this.renderItems()}
                </InfiniteScroll>
                {!this.state.loadingItems ? <Empty style={{marginTop: '50px'}} image={Empty.PRESENTED_IMAGE_SIMPLE} description={this.state.items.length > 0 ? "No more blog posts" : "No blog posts"} /> : null}
                <br />
            </div>
        )
    }

}

export default ListBlogPostView;