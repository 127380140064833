import Api from "../../session/Api";
import SessionManager from "../../session/SessionManager";
import UIUtil from "../../util/UIUtil";
import Util from "../../util/Util";
import { isStreamTypeVideo, STREAM_TYPE_STAGE_WHITEBOARD, STREAM_TYPE_WHITEBOARD } from "../streams/StreamTypes";

export const DEFAULT_LIVE_STREAM_STATE = {
    loading: true,
    notSupportedError: false,
    joinInfo: undefined,
    liveStreamOver: false,

    recordingInfo: undefined,

    liveStreamLiveDuration: 0,
    liveStreamTimeLeft: 0,

    totalTimeSpent: 0,

    timeoutDisconnectedError: false,
    tokenChangedDisconnectedError: false,

    inputStreams: [],
    outputStreams: [],

    loadingOutputStreams: [],

    userCount: 0,
    userListKey: Util.newTempId(),

    updatingAllowMessaging: false,
    allowMessaging: false,

    updatingStagePermission: {},
    stagePermissions: {},

    stageUserIds: undefined,
    
    updatingRaiseHand: false,
    raisedHand: false,

    updatingWhiteboardStageAccess: false,
    whiteboardStageAccess: false,
}

class LiveStreamState {

    constructor(getState, setState) {
        this.getState = getState;
        this.setState = setState;
    }

    isInAnyError() {
        if (this.isLoading()) {
            return true;
        }

        if (this.isInNotSupportedError()) {
            return true;
        }

        if (this.isInError()) {
            return true;
        }

        if (this.isTimeoutDisconnectedError()) {
            return true;
        }

        if (this.isTokenChangedDisconnectedError()) {
            return true;
        }

        if (this.isLiveStreamOver()) {
            return true;
        }

        return false;
    }

    isTimeoutDisconnectedError() {
        return this.getState().timeoutDisconnectedError;
    }

    setTimeoutDisconnectedError(timeoutDisconnectedError) {
        this.setState({ timeoutDisconnectedError })
    }

    isTokenChangedDisconnectedError() {
        return this.getState().tokenChangedDisconnectedError;
    }

    setTokenChangedDisconnectedError(tokenChangedDisconnectedError) {
        this.setState({ tokenChangedDisconnectedError })
    }

    isLoading() {
        return this.getState().loading;
    }

    isInNotSupportedError() {
        return this.getState().notSupportedError;
    }

    isInError() {
        return this.getState().joinInfo === undefined && this.getState().recordingInfo === undefined;
    }

    isLiveStreamOver() {
        return this.getState().liveStreamOver;
    }

    isHost() {
        return this.hasLiveStream() && this.getJoinInfo().host;
    }

    hasLiveStream() {
        return !this.isLoading() && !this.isInNotSupportedError() && !this.isInError() && !this.isLiveStreamOver() && !this.isInAnyError() && this.getState().joinInfo !== undefined;
    }

    getRecordingInfo() {
        return this.getState().recordingInfo;
    }

    getJoinInfo() {
        return this.getState().joinInfo;
    }

    getLiveStream() {
        return this.getState().joinInfo.liveStream;
    }

    getUserCount() {
        return this.getState().userCount;
    }

    getLiveDuration() {
        return this.getState().liveStreamLiveDuration;
    }

    getTimeLeft() {
        return this.getState().liveStreamTimeLeft;
    }

    getTotalTimeSpent() {
        return this.getState().totalTimeSpent;
    }

    onUserCountUpdate(userCount) {
        this.setState({userCount: userCount})
    }

    getUserListKey() {
        return this.getState().userListKey;
    }

    updateUserList() {
        this.setState({userListKey: Util.newTempId()})
    }

    isUpdatingAllowMessaging() {
        return this.getState().updatingAllowMessaging;
    }

    updateAllowMessaging(allowMessaging) {
        this.setState({updatingAllowMessaging: true});
        Api.setLiveStreamAllowMessaging(this.getLiveStream().id, allowMessaging, response => {
            if (response.status === true) {
                this.setState({ allowMessaging, updatingAllowMessaging: false });
            } else {
                this.setState({ updatingAllowMessaging: false });
                UIUtil.showError();
            }
        })
    }

    setAllowMessaging(allowMessaging) {
        this.setState({ allowMessaging })
    }

    isAllowMessaging() {
        if (this.getState().recordingInfo !== undefined) {
            return true;
        }
        return this.getState().allowMessaging;
    }

    isUpdatingWhiteboardStageAccess() {
        return this.getState().updatingWhiteboardStageAccess;
    }

    updateWhiteboardStageAccess(whiteboardStageAccess) {
        this.setState({updatingWhiteboardStageAccess: true})
        Api.setLiveStreamWhiteboardStageAccess(this.getLiveStream().id, whiteboardStageAccess, response => {
            if (response.status === true) {
                this.setState({ whiteboardStageAccess, updatingWhiteboardStageAccess: false });
            } else {
                this.setState({ updatingWhiteboardStageAccess: false });
                UIUtil.showError();
            }
        })
    }

    setWhiteboardStageAccess(whiteboardStageAccess) {
        this.setState({ whiteboardStageAccess })
    }

    isWhiteboardStageAccess() {
        return this.getState().whiteboardStageAccess;
    }

    isUpdatingRaisedHand() {
        return this.getState().updatingRaiseHand;
    }

    updateHandRaise(raisedHand) {
        this.setState({updatingRaiseHand: true})
        Api.setLiveStreamHandRaise(this.getLiveStream().id, raisedHand, response => {
            if (response.status === true) {
                this.setState({ raisedHand, updatingRaiseHand: false });
            } else {
                this.setState({ updatingRaiseHand: false });
                UIUtil.showError();
            }
        })
    }

    setHandRaised(raisedHand) {
        this.setState({ raisedHand })
    }

    isHandRaised() {
        return this.getState().raisedHand;
    }

    setStageUserIds(stageUserIds) {
        this.setState({ stageUserIds })
        if (this.isStageUser()) {
            this.setHandRaised(false);
        }
    }

    isStageUser() {
        return this.getStageUserIds().includes(SessionManager.getAccount().id);
    }

    getStageUserIds() {
        return this.getState().stageUserIds;
    }

    isUpdatingStagePermission(streamType) {
        return this.getState().updatingStagePermission[streamType] === true;
    }

    updateStagePermission(streamType, permission) {
        this.setState(prevState => ({ updatingStagePermission: {
            ...prevState.updatingStagePermission,
            [streamType]: true
        } }))
        Api.setLiveStreamStagePermission2(this.getLiveStream().id, streamType, permission, response => {
            if (response.status === true) {
                this.setState(prevState => ({ 
                    stagePermissions: {
                        ...prevState.stagePermissions,
                        [streamType]: permission
                    },
                    updatingStagePermission: {
                        ...prevState.updatingStagePermission,
                        [streamType]: false
                    },
                }))
            } else {
                this.setState(prevState => ({ updatingStagePermission: {
                    ...prevState.updatingStagePermission,
                    [streamType]: false
                } }))
                UIUtil.showError();
            }
        })
    }

    setStagePermission(streamType, permission) {
        this.setState(prevState => ({ stagePermissions: {
            ...prevState.stagePermissions,
            [streamType]: permission
        } }))
    }
    
    isStagePermission(streamType) {
        return this.getState().stagePermissions[streamType];
    }

    refresh() {
        this.setState(DEFAULT_LIVE_STREAM_STATE);
    }

    onLoadedRecording(recordingInfo, callback) {
        this.setState({
            loading: false,
            notSupportedError: false,
            recordingInfo: recordingInfo !== null ? recordingInfo : undefined
        }, recordingInfo !== null ? callback : undefined)
    }

    onLoaded(joinInfo, notSupportedError, callback) {
        this.setState({ 
            loading: false, notSupportedError, joinInfo,

            liveStreamLiveDuration: joinInfo !== undefined ? (new Date().getTime() - joinInfo.liveStream.date) / 1000 : 0,
            liveStreamTimeLeft: joinInfo !== undefined ? ((joinInfo.liveStream.date + (joinInfo.liveStream.duration * 60000)) - new Date().getTime()) / 1000 : 0,

            allowMessaging: joinInfo !== undefined ? joinInfo.allowMessaging : false,
            stagePermissions: joinInfo !== undefined ? joinInfo.stagePermissions : {},

            stageUserIds: joinInfo !== undefined ? joinInfo.stageUserIds : undefined,
            raisedHand: joinInfo !== undefined ? joinInfo.raisedHand : false,

            whiteboardStageAccess: joinInfo !== undefined ? joinInfo.whiteboardStageAccess : false
        }, callback);
    }

    hasAnyStreamsExcept(skipStreamId) {
        return this.getAllStreamsExcept(skipStreamId).length > 0;
    }

    hasAnyStreams() {
        return this.getAllStreams().length > 0;
    }

    hasAnyAudioStreams() {
        return this.getAllAudioStreams().length > 0;
    }

    hasStream(streamId) {
        return this.getStream(streamId) !== undefined;
    }

    getAllStreamsExcept(skipStreamId) {
        return this.getAllStreams().filter(stream => stream.streamId != skipStreamId);
    }

    getStream(streamId) {
        for (const stream of this.getAllStreams()) {
            if (stream.streamId == streamId) {
                return stream;
            }
        }
        return undefined;
    }

    getAllStreams() {
        let allStreams = [];
        for (const streams of [this.getState().outputStreams, this.getState().inputStreams]) {
            for (const stream of streams) {
                if (isStreamTypeVideo(stream.streamType) && stream.streamType !== STREAM_TYPE_STAGE_WHITEBOARD) {
                    allStreams.push(stream);
                }
            }
        }
        return allStreams;
    }

    getAllAudioStreams() {
        let allStreams = [];
        for (const streams of [this.getState().outputStreams, this.getState().inputStreams]) {
            for (const stream of streams) {
                if (!isStreamTypeVideo(stream.streamType) && stream.streamType !== STREAM_TYPE_STAGE_WHITEBOARD) {
                    allStreams.push(stream);
                }
            }
        }
        return allStreams;
    }

    getAllInputAudioStreams() {
        let allStreams = [];
        for (const stream of this.getState().inputStreams) {
            if (!isStreamTypeVideo(stream.streamType) && stream.streamType !== STREAM_TYPE_STAGE_WHITEBOARD) {
                allStreams.push(stream);
            }
        }
        return allStreams;
    }

    getAllInputVideoStreams() {
        let allStreams = [];
        for (const stream of this.getState().inputStreams) {
            if (isStreamTypeVideo(stream.streamType) && stream.streamType !== STREAM_TYPE_STAGE_WHITEBOARD) {
                allStreams.push(stream);
            }
        }
        return allStreams;
    }

    hasOutputStream(streamType) {
        let outputStreams = this.getState().outputStreams;
        for (const stream of outputStreams) {
            if (stream.streamType == streamType) {
                return true;
            }
        }
        return false;
    }

    getAllInputStreams() {
        return this.getState().inputStreams;
    }

    getInputStream(userId, streamType) {
        let inputStreams = this.getState().inputStreams;
        for (const stream of inputStreams) {
            if (stream.userId == userId && stream.streamType == streamType) {
                return stream;
            }
        }
        return undefined;
    }

    getAnyInputStream(streamType) {
        let inputStreams = this.getState().inputStreams;
        for (const stream of inputStreams) {
            if (stream.streamType == streamType) {
                return stream;
            }
        }
        return undefined;
    }

    hasAnyInputStream(streamType) {
        return this.getAnyInputStream(streamType) !== undefined;
    }

    isLoadingOutputStream(streamType) {
        for (const stream of this.getState().loadingOutputStreams) {
            if (stream == streamType) {
                return true;
            }
        }
        return false;
    }

    produceStream(webRtcSession, streamType, options) {
        if (webRtcSession === undefined) {
            UIUtil.showError();
            return;
        }
        
        this.setState(prevState => ({ loadingOutputStreams: [streamType, ...prevState.loadingOutputStreams] }))
        webRtcSession.produceStream(streamType, options)
        .then(stream => {
            UIUtil.showSuccess()
            this.setState(prevState => ({ 
                outputStreams: [{ streamId: Util.newTempId(), userId: SessionManager.getAccount().id, userFullName: SessionManager.getAccount().fullName, userIsHost: this.getJoinInfo().host, streamType, rawStream: stream.rawStream }, ...prevState.outputStreams],
                loadingOutputStreams: prevState.loadingOutputStreams.filter(producingStream => producingStream != streamType),
            }))

            if (options !== undefined && options.onStartedStream) {
                options.onStartedStream();
            }
        })
        .catch((e) => {
            console.log(e);
            if (e !== undefined && e.name == "NotAllowedError") {
                UIUtil.showInfo("Permission not given");
            } else {
                if (e && e.status === false) {
                    UIUtil.showError(e.message);
                } else {
                    UIUtil.showError();
                }
            }
            this.setState(prevState => ({ loadingOutputStreams: prevState.loadingOutputStreams.filter(producingStream => producingStream != streamType) }))
        })
    }

    stopStream(webRtcSession, streamType) {
        webRtcSession.stopProducedStream(streamType, false);
        this.onRemoveOutputStream(streamType);
    }

    onRemoveOutputStream(streamType) {
        this.setState(prevState => ({ 
            outputStreams: prevState.outputStreams.filter(outputStream => outputStream.streamType != streamType),
            loadingOutputStreams: prevState.loadingOutputStreams.filter(producingStream => producingStream != streamType),
        }))
    }

    onNewRawInputStream(userId, userFullName, userIsHost, streamType, rawStream) {
        if (rawStream !== undefined) {
            this.setState(prevState => ({ inputStreams: [{ streamId: Util.newTempId(), userId, userFullName, userIsHost, streamType, rawStream, input: true }, ...prevState.inputStreams] }))
        } else {
            this.setState(prevState => ({ inputStreams: prevState.inputStreams.filter(inputStream => !(inputStream.userId == userId && inputStream.streamType == streamType)) }))
        }
    }

    createNewUrlInputStreamRequest(stream, userId, userFullName, userIsHost, streamType, streamUrl) {
        return { stream, userId, userFullName, userIsHost, streamType, streamUrl }
    }

    createUrlInputStream(request) {
        return { 
            streamId: request.stream.streamId, 
            userId: request.userId, 
            userFullName: request.userFullName, 
            userIsHost: request.userIsHost, 
            streamType: request.streamType, 
            streamUrl: request.streamUrl, 
            streamInfo: request.stream, 
            input: true 
        }
    }

    onMultipleNewUrlInputStream(requests) {
        const id = Util.newTempId();
        this.setState(prevState => {
            let inputStreams = [...prevState.inputStreams];

            for (const request of requests) {
                if (request.streamUrl !== undefined) {
                    let add = true;
            
                    for (const inputStream of inputStreams) {
                        if (inputStream.userId == request.userId && inputStream.streamType == request.streamType) {
                            add = false;
                            break;
                        }
                    }

                    if (add) {
                        inputStreams = inputStreams.filter(inputStream => !(inputStream.userId == request.userId && inputStream.streamType == request.streamType))
                        inputStreams.push({ 
                            streamId: request.stream.streamId, 
                            userId: request.userId, 
                            userFullName: request.userFullName, 
                            userIsHost: request.userIsHost, 
                            streamType: request.streamType, 
                            streamUrl: request.streamUrl, 
                            streamInfo: request.stream, 
                            input: true 
                        })
                    }
                } else {
                    inputStreams = inputStreams.filter(inputStream => inputStream.streamInfo.streamId != request.stream.streamId)
                }
            }

            return { inputStreams };
        })
    }

    onNewUrlInputStream(stream, userId, userFullName, userIsHost, streamType, streamUrl) {
        this.onMultipleNewUrlInputStream([this.createNewUrlInputStreamRequest(stream, userId, userFullName, userIsHost, streamType, streamUrl)])
    }

}

export default LiveStreamState;