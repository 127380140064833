import React from 'react';
import queryString from 'query-string'
import SessionManager from '../session/SessionManager';
import { ACCOUNT_SCOPE_BACKEND } from '../Constants';
import AdminAuthView from '../views/auth/AdminAuthView';
import FrontendAuthView from '../views/auth/FrontendAuthView';

class Window extends React.Component {
    constructor(props) {
        super(props);

        this.params = queryString.parse(this.props.location.search);
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.params = queryString.parse(this.props.location.search);
        }
    }

    canRender() {
        if (this.isLockBehindAuth()) {
            if (this.getRole() === -1 && SessionManager.isLoggedInScope(this.getScope())) {
                return true;
            } else if (SessionManager.isLoggedInAs(this.getRole())) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    isLockBehindAuth() {
        throw new Error("Not implemented");
    }

    getScope() {
        throw new Error("Not implemented");
    }

    getRole() {
        throw new Error("Not implemented");
    }

    render() {
        if (this.canRender()) {
            return this.getLayout();
        } else {
            if (this.getScope() == ACCOUNT_SCOPE_BACKEND) {
                return ( <AdminAuthView /> );
            } else {
                return ( <FrontendAuthView /> );
            }
        }
    }

    getLayout() {
        throw new Error("Not implemented");
    }

}

export default Window;