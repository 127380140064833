
import { Client, Message } from '@stomp/stompjs';
import * as SockJS from 'sockjs-client';
import Api from './Api';
import { SOCKET_EVENT_CHAT, SOCKET_EVENT_WEB_RTC_SIGNALLING, SOCKET_EVENT_HELP_SESSION_STATUS, SOCKET_EVENT_PRESENCE, SOCKET_EVENT_NOTIFICATION } from '../Constants';
import SessionManager from './SessionManager';

const events = [
    SOCKET_EVENT_CHAT,
    SOCKET_EVENT_WEB_RTC_SIGNALLING,
    SOCKET_EVENT_HELP_SESSION_STATUS,
    SOCKET_EVENT_PRESENCE,
    SOCKET_EVENT_NOTIFICATION
]

class SocketSession {

    constructor() {
        this.eventListeners = {};
        this.eventSubQueue = [];
    }

    registerListener(event, listener) {
        if (this.eventListeners[event] === undefined) {
            const listeners = [];
            listeners.push(listener);
            
            this.eventListeners[event] = listeners;
        } else {
            this.eventListeners[event].push(listener);
        }
    }

    unregisterListener(event, listener) {
        const listeners = this.eventListeners[event];
        const index = listeners !== undefined ? listeners.indexOf(listener) : -1;
        if (listeners !== undefined && index != -1) {
            listeners.splice(index, 1);

            if (listeners.length === 0) {
                delete this.eventListeners[event];
            }
        }
    }

    open() {
        if (this.socket !== undefined) {
            return;
        }

        this.socket = new Client({
            webSocketFactory: () => new SockJS(Api.getWebSocketEndpoint()),
            //"brokerURL": "ws://localhost:8080/api/socket/websocket" + "?token=" + SessionManager.getAccount().token,
            //"brokerURL": "ws://140.184.38.230:8080/api/socket/websocket",
            reconnectDelay: 2500,
        });

        this.socket.onConnect = () => {
            events.forEach(event => this.subscribe(event));
            this.eventSubQueue.forEach(({event, onResponse}) => this.subscribe(event, onResponse));
            this.eventSubQueue = [];
        }

        this.socket.activate();
    }

    subscribe(event, onResponse) {
        if (this.socket !== undefined && this.socket.connected) {
            const subscription = this.socket.subscribe(event, message => this.onMessage(event, JSON.parse(message.body)));

            if (onResponse !== undefined) {
                onResponse(subscription);
            }
        } else {
            this.eventSubQueue.push({
                event: event,
                onResponse: onResponse
            })
        }
    }

    onMessage(event, message) {
        const listeners = this.eventListeners[event];
        if (listeners !== undefined) {
            listeners.forEach(listener => listener(message));
        }
    }

    close() {
        if (this.socket === undefined) {
            return;
        }

        this.socket.deactivate();
        if (this.socket.connected) {
            this.socket.forceDisconnect();
        }
        this.socket = undefined;
    }
    
}

export default new SocketSession();