import Api from "./Api";
import { PRESENCE_OFFLINE, SOCKET_EVENT_PRESENCE, ACCOUNT_ROLE_TUTOR } from "../Constants";
import SocketSession from "./SocketSession";
import SessionManager from "./SessionManager";

class PresenceManager {

    constructor() {
        this.presenceListeners = {};
    }

    registerListener(userId, listener) {
        if (this.presenceListeners[userId] === undefined) {
            const listeners = [];
            listeners.push(listener);
            
            this.presenceListeners[userId] = listeners;
        } else {
            this.presenceListeners[userId].push(listener);
        }

        this.getPresence(userId, listener);
    }

    getPresence(userId, listener) {
        Api.getPresence(userId, response => {
            if (response.status === true) {
                listener(response.payload)
            } else {
                listener(PRESENCE_OFFLINE)
            }
        })
    }

    unregisterListener(userId, listener) {
        const listeners = this.presenceListeners[userId];
        const index = listeners !== undefined ? listeners.indexOf(listener) : -1;
        if (listeners !== undefined && index != -1) {
            listeners.splice(index, 1);

            if (listeners.length === 0) {
                delete this.presenceListeners[userId];
            }
        }
    }

    start() {
        if (SessionManager.getAccount().role === ACCOUNT_ROLE_TUTOR) {
            Api.broadcastPresence(() => {})
        }
        SocketSession.registerListener(SOCKET_EVENT_PRESENCE, event => this.onPresenceUpdate(event.userId, event.presence))
        if (SessionManager.getAccount().role === ACCOUNT_ROLE_TUTOR) {
            this.interval = setInterval(() => Api.pingPresence(), 4000)
        }
    }
    
    stop() {
        if (this.interval !== undefined) {
            clearInterval(this.interval);
        }
    }

    onPresenceUpdate(userId, presence) {
        const listeners = this.presenceListeners[userId];
        if (listeners !== undefined) {
            listeners.forEach(listener => listener(presence));
        }
    }

    setPresence(presence, listener) {
        Api.setPresence(presence, listener);
    }

}

export default new PresenceManager();