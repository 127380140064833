import React, { useState } from 'react'

export default function ThemeIconView({theme, selected, darkBg, onClick, actualTheme, ...props}) {
    const [hover, setHover] = useState(false);

    return (
        <div onClick={onClick}
        onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
        style={{position: 'relative', width: 60, height: 60, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'}}>
            <div style={{ position: 'absolute', left: 0, right: 0, width: '100%', height: '100%', borderRadius: 5, zIndex: 1, 
            transition: '250ms', background: (selected || hover) ? actualTheme.iconViewColors.sideMenuAccentColor : (darkBg ? 'white' : 'black'), opacity: selected ? 1 : (hover ? 0.80 : 1), transform: selected ? 'scale(1)' : (hover ? 'scale(1)' : 'scale(0.95)')}} />

            <div style={{position: 'relative', display: 'flex', width: '90%', height: '90%', borderRadius: 5, overflow: 'hidden', zIndex: 2}}>
                <div style={{width: 17, height: '100%', background: theme.iconViewColors.sideMenuColor}} />
                <div style={{flex: 1, height: '100%', background: theme.iconViewColors.backgroundColor}} />

                <div style={{position: 'absolute', left: 5, top: 5, width: 7, height: 7, borderRadius: '50%', background: theme.iconViewColors.sideMenuAccentColor}} />
                <div style={{position: 'absolute', right: 5 + 5 + 7, top: 5, width: 7, height: 7, borderRadius: '50%', background: theme.iconViewColors.accentColor}} />
                <div style={{position: 'absolute', right: 5, top: 5, width: 7, height: 7, borderRadius: '50%', background: theme.iconViewColors.activeAccentColor}} />
            </div>
        </div>
    )
}