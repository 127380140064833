import React from 'react'
import Page from '../base/Page';
import Api from '../session/Api';

import {
    WarningTwoTone,
    ReloadOutlined
} from '@ant-design/icons'
import { Tooltip, Button, Card, Spin, Slider } from 'antd';

import PostsView from '../views/posts/PostsView'

class PostPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            loading: true,
            error: false,

            post: undefined
        }
    }

    onPageStart() {
        this.setState({loading: true})
        Api.getOnePost(this.props.match.params.postId, response => {
            this.setState({loading: false, error: response.payload === null, post: response.payload})
        })
    }

    render() {
        if (this.state.loading) {
            return (
                <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingTop: 45, paddingBottom: 45}}>
                    <Spin size="large" tip="Loading..." />
                </div>
            )
        }

        if (this.state.error) {
            return (
                <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingTop: 45, paddingBottom: 45}}>
                    

                    <WarningTwoTone twoToneColor="#f26622" style={{fontSize: 80}} />
                    <p style={{marginTop: 45, opacity: 0.65}}>An error occurred. Please try again later.</p>

                    <Button icon={<ReloadOutlined />} onClick={() => window.location.reload()}>Reload</Button>
                </div>
            )

        }

        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <div style={{width: this.props.renderFullWidth ? '90vw' : '50vw', marginTop: '25px'}}>
                    <PostsView onePostMode={this.state.post} />
                </div>
            </div>
        );
    }

}

export default PostPage;