
import React from 'react';

import {  
    QuestionCircleOutlined,
    DeploymentUnitOutlined,
    EllipsisOutlined,
    FileTextOutlined,
    BarsOutlined
} from '@ant-design/icons';

export const BLOG_POST_TAG_GENERAL = 0;
export const BLOG_POST_TAG_NEWS = 1;
export const BLOG_POST_TAG_TUTORIALS = 2;
export const BLOG_POST_TAG_SHOWCASES = 3;
export const BLOG_POST_TAG_MISC = 4;

export const BLGO_POST_TAGS = [
    {
        id: BLOG_POST_TAG_GENERAL,
        title: "General", 
        icon: <BarsOutlined />,
        color: "#4e2e89"
    },
    {
        id: BLOG_POST_TAG_NEWS,
        title: "News", 
        icon: <FileTextOutlined />,
        color: "#3772FF"
    },
    {
        id: BLOG_POST_TAG_TUTORIALS,
        title: "Tutorials", 
        icon: <QuestionCircleOutlined />,
        color: "#15616D"
    },
    {
        id: BLOG_POST_TAG_SHOWCASES,
        title: "Showcases", 
        icon: <DeploymentUnitOutlined />,
        color: "#DF2935"
    },
    {
        id: BLOG_POST_TAG_MISC,
        title: "Misc", 
        icon: <EllipsisOutlined />,
        color: "#f26622"
    },
];