import { Spin } from 'antd'
import React from 'react'
import Api from '../../session/Api'

import { 
    LoadingOutlined
} from '@ant-design/icons';
import Util from '../../util/Util';
import ProfilePicture from '../../components/ProfilePicture';

class LiveStreamAttendanceReport extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            attendanceReport: undefined
        }
    }

    componentDidMount() {
        Api.getLiveStreamAttendanceReport(this.props.liveStreamId, response => {
            if (response.status === true) {
                this.setState({
                    loading: false,
                    attendanceReport: response.payload
                })
            }
        })
    }

    getAttendeeProgress(attendee) {
        let elements = [];

        let lastEndPoint = 0;
        let startPoint = -1;

        for (const attendancePoint of attendee.attendancePoints) {
            if (startPoint != -1) {
                if (lastEndPoint > 0) {
                    //console.log(lastEndPoint - startPoint)
                    elements.push(<div style={{width: (((startPoint - lastEndPoint) / this.state.attendanceReport.liveStreamDuration) * 100) + "%"}} />)
                } else {
                    if (elements.length == 0) {
                        elements.push(<div style={{width: (((startPoint) / this.state.attendanceReport.liveStreamDuration) * 100) + "%"}} />)
                    }
                }
                elements.push(<div style={{width: (((attendancePoint - startPoint) / this.state.attendanceReport.liveStreamDuration) * 100) + "%",
                backgroundColor: '#990000', borderRadius: '25px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <h6 style={{color: 'white', height: 10}}>{Util.formatSecondsBrief((attendancePoint - startPoint) / 1000)}</h6>
                </div>)

                lastEndPoint = attendancePoint;
                startPoint = -1;
            } else {
                startPoint = attendancePoint;
            }
        }

        if (startPoint != -1) {
            //totalTimeSpent += attendanceReport.getLiveStreamDuration() - startPoint;
            if (lastEndPoint > 0) {
                //console.log(lastEndPoint - startPoint)
                elements.push(<div style={{width: (((startPoint - lastEndPoint) / this.state.attendanceReport.liveStreamDuration) * 100) + "%"}} />)
            } else {
                if (elements.length == 0) {
                    elements.push(<div style={{width: (((startPoint) / this.state.attendanceReport.liveStreamDuration) * 100) + "%"}} />)
                }
            }
            elements.push(<div style={{width: (((this.state.attendanceReport.liveStreamDuration - startPoint) / this.state.attendanceReport.liveStreamDuration) * 100) + "%",
            backgroundColor: '#990000', borderRadius: '25px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <h6 style={{color: 'white', height: 10}}>{Util.formatSecondsBrief((this.state.attendanceReport.liveStreamDuration - startPoint) / 1000)}</h6>
            </div>)
        }

        return elements;
    }

    render() {
        return (
            <div style={{display: 'flex', flexDirection: 'column', width: '45vw'}}>
                <h2 style={{color: 'white', fontSize: 45}}>Attendance Report</h2>
                {this.state.attendanceReport !== undefined &&
                <h4 style={{color: '#990000', fontSize: 25, marginTop: -40}}>Live stream duration: {Util.formatSecondsBrief(this.state.attendanceReport.liveStreamDuration / 1000)}</h4>}
                {this.state.loading &&
                <Spin indicator={<LoadingOutlined style={{ fontSize: 40, color: '#990000' }} spin />} style={{color: '#990000'}} /> }

                {this.state.attendanceReport !== undefined && <>
                    {this.state.attendanceReport.attendees.map(attendee => (
                        <div style={{height: 75, width: '100%', display: 'flex', alignItems: 'center', marginTop: 40}}>
                            <ProfilePicture userId={attendee.userId} size={50} style={{borderWidth: 2, borderColor: 'white', borderStyle: 'solid'}} />
                            <div style={{flex: 1, justifyContent: 'center', marginLeft: 25}}>
                                <div style={{display: 'flex', alignItems: 'flex-end', marginBottom: 2}}>
                                    <h5 style={{color: 'white', height: 15, fontSize: 16}}>{attendee.userFullName}</h5>
                                    <h5 style={{color: '#8a8a8a', height: 12, fontSize: 12}}>&nbsp; - {Util.formatSecondsBrief(attendee.totalAttendanceDuration / 1000)} (total attendance duration)</h5>
                                </div>
                                <div style={{backgroundColor: '#1B1B1B', borderRadius: '25px', width: '100%', height: 15, overflow: 'hidden', display: 'flex'}}>
                                    {/*<div style={{backgroundColor: '#990000', width: '20%', borderRadius: 25}} />
                                    <div style={{width: '20%', borderRadius: 25}} />
                                    <div style={{backgroundColor: '#990000', width: '20%', borderRadius: 25}} />*/}
                                    {this.getAttendeeProgress(attendee)}
                                </div>
                            </div>
                        </div>
                    ))}
                    {this.state.attendanceReport.attendees.length == 0 && 
                    <h5 style={{color: '#8a8a8a', height: 15, fontSize: 16}}>
                        No one attended
                    </h5>}
                </>}
            </div>
        )
    }

}

export default LiveStreamAttendanceReport;