import React, { useEffect, useRef, useState } from 'react'
import Api from '../../session/Api';
import LiveStreamState from '../state/LiveStreamState';

import * as portals from 'react-reverse-portal';
import LiveVideo from '../components/LiveVideo';

//import { MediaPlayer } from 'dashjs'

import { Button, Slider, Spin, Tooltip } from 'antd';
import Util from '../../util/Util';

import { PauseOutlined, CaretRightOutlined, LoadingOutlined } from '@ant-design/icons';

import { VideoSeekSlider } from "react-video-seek-slider";
import "react-video-seek-slider/styles.css"
import StreamMediaPlayer, { MEDIA_PLAYER_TYPE } from '../mediaplayer/StreamMediaPlayer';
import { isStreamTypeVideo } from '../streams/StreamTypes';
import AudioPlayer from '../components/AudioPlayer';

import { isMobileOnly, isMobileSafari, isIOS } from 'react-device-detect'

export const DEFAULT_RECORDING_STATE = {
    recordingInitialized: false,



    //COMMENT THEM LATER:
    // recordingPlaying: false,
    // recordingPlayPosition: 0,
    // seekingRecording: false,
    // recordingSeekValue: 0,

    // recordingPlayStart: 0,
    // recordingLoading: [],
}

/**
 * 
 * @param {{ recordingManager: RecordingManager }} props 
 * @returns 
 */
export const RecordingSeekBar = ({theme, duration, recordingManager, ...props}) => {
    const [playing, setPlaying] = useState(recordingManager.playing);
    const [buffering, setBuffering] = useState(recordingManager.buffering);
    const [progressValue, setProgressValue] = useState(recordingManager.getPlayPos());
    const [seekSliderReset, setSeekSliderReset] = useState(false)
    const timerRef = useRef();

    const clearTimer = () => clearInterval(timerRef.current);
    const startTimer = () => {
        clearTimer();
        timerRef.current = setInterval(() => {
            if (recordingManager.playing && !recordingManager.buffering) {
                setProgressValue(recordingManager.getPlayPos());
                setPlaying(true);
                setBuffering(false);
            } else if (!recordingManager.playing) {
                setPlaying(false);
            } else if (recordingManager.buffering) {
                setBuffering(true);
            }
        }, 100);
    }

    useEffect(() => () => clearTimer(), []);
    useEffect(() => {
        if (playing) {
            startTimer();
        } else {
            clearTimer();
        }
    }, [playing]);

    useEffect(() => {
        console.log("BAMMED")
        setSeekSliderReset(true)
    }, [props.key])
    useEffect(() => {
        if (seekSliderReset) {
            setTimeout(() => {
                setSeekSliderReset(false)
            }, 250 + 50)
        }
    }, [seekSliderReset])

    const onPlayPauseBtn = () => setPlaying(playing => {
        playing = !playing;
        if (playing) {
            recordingManager.play();
        } else {
            recordingManager.pause();
        }
        return playing;
    })

    const onSeeked = (pos) => {
        setProgressValue(pos)
        recordingManager.seek(pos);
        if (!playing) {
            setPlaying(true);
        }
    }

    
    

    return (<>
        <Tooltip title={playing ? "Pause" : "Play"}>
            <div className={playing ? "live-stream-button" : "live-stream-active-button"}>
                <Button
                onClick={onPlayPauseBtn}
                style={{borderRadius: '50%', width: 35, height: 35, padding: 0}} type="primary">
                    {playing ? <PauseOutlined /> : <CaretRightOutlined />}
                </Button>
            </div>
        </Tooltip>

        <div style={{width: 25}} />
        {/* <button onClick={() => setTest(true)}>test</button> */}

        <div style={{flex: 1}}>
            {!seekSliderReset && <VideoSeekSlider
            max={duration / 1000}
            currentTime={progressValue / 1000}
            onChange={pos => onSeeked(pos * 1000)}
            secondsPrefix="00:00:"
            minutesPrefix="00:" />}
        </div>

        {buffering ? <LoadingOutlined spin style={{color: '#990000', marginLeft: 10, marginRight: 10, width: 15, height: 15}} />
        : <div style={{marginLeft: 10, marginRight: 10, width: 15, height: 15}} />}

        <p style={{color: theme.mainTextColor, opacity: 0.65, fontSize: 12, margin: 0, marginTop: 0, marginLeft: 0, marginRight: 15}}>
            {Util.formatSeconds((duration - progressValue) / 1000)}
        </p>
    </>)
}

class RecordingManager {

    /**
     * @type {LiveStreamState}
     */
    liveStreamState;

    /**
     * @type {Map<string, {stream: any, rawStream: any, mediaPlayer: StreamMediaPlayer}>}
     */
    recordingStreams;
    
    playStartPos;
    playStartTimestamp;
    playStartStalledDuration;

    playStalledTimestamp;

    pauseStartPos;

    playing;
    buffering;
 
    constructor(liveStreamState, getState, setState) {
        this.liveStreamState = liveStreamState;
        this.getState = getState;
        this.setState = setState;
    }

    init(portalNodes, chatRef) {
        this.portalNodes = portalNodes;
        this.recordingStreams = new Map();

        for (const stream of this.liveStreamState.getRecordingInfo().streams) {
            const recordingId = this.liveStreamState.getRecordingInfo().recordingId;
            const streamId = stream.streamId;

            const isStreamVideo = isStreamTypeVideo(stream.streamType);
            const isIPhone = isMobileOnly && isIOS;

            const mediaPlayerType = isIPhone ? MEDIA_PLAYER_TYPE.NATIVE : (isStreamVideo ? MEDIA_PLAYER_TYPE.MPEG_DASH : MEDIA_PLAYER_TYPE.NATIVE);
            const mediaPlayer = new StreamMediaPlayer(mediaPlayerType, recordingId, streamId);
            if (!isStreamVideo) {
                mediaPlayer.mediaFileExt = "mp4"
            } else if (isIPhone) {
                mediaPlayer.mediaFileExt = "m3u8"
            }
            mediaPlayer.init()
            this.setupBufferingEvents(mediaPlayer)

            this.recordingStreams.set(stream.streamId, {
                streamId: stream.streamId,
                rawStream: stream,
                stream: this.liveStreamState.createUrlInputStream(this.liveStreamState.createNewUrlInputStreamRequest(stream, stream.userId, stream.userFullName, stream.host, stream.streamType, Api.playLiveStreamRecording(this.liveStreamState.getRecordingInfo().recordingId, stream.streamId))),
                mediaPlayer: mediaPlayer
            });
            this.portalNodes[stream.streamId] = portals.createHtmlPortalNode({ attributes: { style: isStreamTypeVideo(stream.streamType) ? "width: 100%; height: 100%;" : "" } });
        }

        this.setState({recordingInitialized: true})

        this.timer = setInterval(this.onTick.bind(this), 50);

        this.loadRecordedMessages(chatRef)
    }

    release() {
        if (this.timer) {
            clearInterval(this.timer);
        }
        try {
            if (this.recordingStreams) {
                for (const [_, stream] of this.recordingStreams) {
                    stream.mediaPlayer.release();
                }
            }
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * 
     * @param {StreamMediaPlayer} mediaPlayer 
     */
    setupBufferingEvents(mediaPlayer) {
        const onBuffering = () => {
            this.buffering = true;
            this.onStalledStarted();

            for (const [_, stream] of this.recordingStreams) {
                if (this.isStreamVisible(stream.rawStream)) {
                    stream.mediaPlayer.pause();
                }
            }
        }
        const onStoppedBuffering = () => {
            this.buffering = false;
            this.onStalledEnded();

            for (const [_, stream] of this.recordingStreams) {
                if (this.isStreamVisible(stream.rawStream)) {
                    stream.mediaPlayer.play();
                }
            }
        }
        

        mediaPlayer.onBufferingStarted(() => {
            if (this.playing) {
                onBuffering();
            }
        })
        mediaPlayer.onBufferingStopped(() => {
            if (this.playing) {
                onStoppedBuffering();
            }
        })
    }
    
    play() {
        const seekValue = this.pauseStartPos !== undefined ? this.pauseStartPos : this.getPlayPos();
        this.seek(seekValue >= this.liveStreamState.getRecordingInfo().duration ? 0 : seekValue);
        this.pauseStartPos = undefined;
    }

    pause() {
        this.pauseStartPos = this.getPlayPos();
        this.playing = false;
        for (const [_, stream] of this.recordingStreams) {
            stream.mediaPlayer.pause();
        }
    }

    seek(newPlayPos) {
        this.playStartPos = newPlayPos;
        this.playStartTimestamp = new Date().getTime();
        this.playStartStalledDuration = 0;
        this.playStalledTimestamp = undefined;
        this.pauseStartPos = undefined;

        this.playing = true;

        const { visibleStreams, hiddenStreams } = this.getTimedStreams();

        hiddenStreams.forEach(stream => stream.mediaPlayer.pause());        
        for (const stream of visibleStreams) {
            stream.mediaPlayer.seek(this.getStreamPlayPos(stream.rawStream))
            stream.mediaPlayer.play();
        }

        this.liveStreamState.onMultipleNewUrlInputStream(
            hiddenStreams
            .map(stream => this.removeRequest(stream.rawStream))
            .concat(visibleStreams
                .map(stream => this.addRequest(stream.rawStream)))
        );
    }

    onTick() {
        if (!this.playing || this.buffering) {
            return;
        }

        if (this.getPlayPos() > this.liveStreamState.getRecordingInfo().duration) {
            this.playing = false;
        }

        let streamModifications = [];

        for (const [streamId, stream] of this.recordingStreams) {
            const visible = this.isStreamVisible(stream.rawStream);
            const exists = this.isStreamExists(streamId);

            if (visible && !exists) {
                stream.mediaPlayer.seek(this.getStreamPlayPos(stream.rawStream))
                stream.mediaPlayer.play();

                streamModifications.push(this.addRequest(stream.rawStream))
            } else if (!visible && exists) {
                stream.mediaPlayer.pause();
                streamModifications.push(this.removeRequest(stream.rawStream))
            }
        }

        this.liveStreamState.onMultipleNewUrlInputStream(streamModifications);
    }
    
    getTimedStreams() {
        let visibleStreams = [];
        let hiddenStreams = [];

        for (const [_, stream] of this.recordingStreams) {
            if (this.isStreamVisible(stream.rawStream)) {
                visibleStreams.push(stream);
            } else {
                hiddenStreams.push(stream);
            }
        }

        return { visibleStreams, hiddenStreams }
    }

    isStreamVisible(rawStream) {
        const startTime = rawStream.startTime;
        const endTime = rawStream.startTime + rawStream.duration;

        return this.getPlayPos() >= startTime && this.getPlayPos() < endTime;
    }

    isStreamExists(streamId) {
        for (const stream of this.liveStreamState.getAllInputStreams()) {
            if (stream.streamId == streamId) {
                return true;
            }
        }
        return false;
    }

    getStreamPlayPos(rawStream) {
        return (this.getPlayPos() - rawStream.startTime) / 1000;
    }

    onStalledStarted() {
        this.playStalledTimestamp = new Date().getTime();   
    }

    onStalledEnded() {
        if (this.playStalledTimestamp) {
            this.playStartStalledDuration += new Date().getTime() - this.playStalledTimestamp;
            this.playStalledTimestamp = undefined;
        }
    }

    getPlayPos() {
        return this.playStartPos !== undefined ? this.playStartPos + (new Date().getTime() - this.playStartTimestamp) - this.playStartStalledDuration : 0;
    }

    removeRequest(rawStream) {
        return this.liveStreamState.createNewUrlInputStreamRequest(rawStream, rawStream.userId, rawStream.userFullName, rawStream.host, rawStream.streamType, undefined);
    }

    addRequest(rawStream) {
        return this.liveStreamState.createNewUrlInputStreamRequest(rawStream, rawStream.userId, rawStream.userFullName, rawStream.host, rawStream.streamType, Api.playLiveStreamRecording(this.liveStreamState.getRecordingInfo().recordingId, rawStream.streamId))
    }



    loadRecordedMessages(chatRef) {
        const recordingInfo = this.liveStreamState.getRecordingInfo();

        if (chatRef.current) {
            recordingInfo.messages.map(message => chatRef.current.addMessage(message, true))
        }
    }



    // loadRecordedMessages() {
    //     const recordingInfo = this.liveStreamState.getRecordingInfo();
    //     const playPos = this.getState().recordingPlayPosition >= recordingInfo.duration ? 0 : this.getState().recordingPlayPosition;

    //     const addedMessages = recordingInfo.messages.filter(message => (message.recordedDate - playPos) < 0);
    //     addedMessages.forEach((message, index) => {
    //         if (this.chatRef.current !== null) {
    //             if (index == (addedMessages.length - 1)) {
    //                 setTimeout(() => {
    //                     if (this.chatRef.current !== null) {
    //                         this.chatRef.current.addMessage(message, true)
    //                     }
    //                 })
    //             } else {
    //                 if (this.chatRef.current !== null) {
    //                     this.chatRef.current.addMessage(message, false)
    //                 }
    //             }
    //         }
    //     })

    //     recordingInfo.messages
    //     .filter(message => (message.recordedDate - playPos) >= 0)
    //     .forEach(message => {
    //         if (this.chatRef.current !== null) {
    //             this.chatRef.current.removeMessage(message)
    //         }
    //     })
    // }

    // playLiveStreamRecording(seeked, postLoad) {
    //     if (this.getState().recordingPlaying) {
    //         this.pauseLiveStreamRecording(true);
    //     }

    //     const recordingInfo = this.liveStreamState.getRecordingInfo();
    //     const playPos = this.getState().recordingPlayPosition >= recordingInfo.duration ? 0 : this.getState().recordingPlayPosition;

    //     this.recordingPlayTicker = setInterval(() => {
    //         if (this.getState().recordingPlayPosition >= recordingInfo.duration) {
    //             this.pauseLiveStreamRecording();
    //         } else {
    //             this.setState(prevState => ({
    //                 recordingPlayPosition: prevState.recordingPlayPosition + 1000
    //             }))
    //         }
    //     }, 1000)


    //     if (seeked) {
    //         let streamModification = [];

    //         recordingInfo.streams
    //         .filter(stream => ((stream.startTime - playPos) >= 0 && ((stream.startTime + stream.duration) - playPos) >= 0) || ((stream.startTime - playPos) < 0 && ((stream.startTime + stream.duration) - playPos) < 0))
    //         .forEach(stream => streamModification.push(this.liveStreamState.createNewUrlInputStreamRequest(stream, stream.userId, stream.userFullName, stream.host, stream.streamType, undefined)));

    //         recordingInfo.streams
    //         .filter(stream => (stream.startTime - playPos) < 0 && ((stream.startTime + stream.duration) - playPos) >= 0)
    //         .forEach(stream => streamModification.push(this.liveStreamState.createNewUrlInputStreamRequest(stream, stream.userId, stream.userFullName, stream.host, stream.streamType, Api.playLiveStreamRecording(recordingInfo.recordingId, stream.streamId))));

    //         this.liveStreamState.onMultipleNewUrlInputStream(streamModification)

    //         this.loadRecordedMessages();
    //     }

    //     this.recordedStreamTimeouts = recordingInfo.streams
    //     .filter(stream => (stream.startTime - playPos) >= 0)
    //     .map(stream => setTimeout(() => {
    //         this.liveStreamState.onNewUrlInputStream(stream, stream.userId, stream.userFullName, stream.host, stream.streamType, Api.playLiveStreamRecording(recordingInfo.recordingId, stream.streamId));
    //     }, stream.startTime - playPos))

    //     this.recordedStreamStopTimeouts = recordingInfo.streams
    //     .filter(stream => ((stream.startTime - playPos) >= 0) || ((stream.startTime - playPos) < 0 && ((stream.startTime + stream.duration) - playPos) >= 0))
    //     .map(stream => setTimeout(() => {
    //         this.liveStreamState.onNewUrlInputStream(stream, stream.userId, stream.userFullName, stream.host, stream.streamType, undefined);
    //         // this.onRecordingLoadingUpdate(stream.streamId, false);
    //     }, (stream.startTime - playPos) + (stream.duration)))   

        

    //     this.messageTimeouts = recordingInfo.messages
    //     .filter(message => (message.recordedDate - playPos) >= 0)
    //     .map(message => setTimeout(() => {
    //         if (this.chatRef.current !== null) {
    //             this.chatRef.current.addMessage(message, true);
    //         }
    //     }, message.recordedDate - playPos));



    //     //play videos
    //     if (postLoad) {
    //         this.setState({recordingPlaying: true, recordingPlayPosition: playPos, recordingPlayStart: playPos})
    //     } else {
    //         this.setState({recordingPlaying: true, recordingPlayPosition: playPos,})
    //     }
    // }

    // pauseLiveStreamRecording(skipStateChange) {
    //     if (this.recordingPlayTicker !== undefined) {
    //         clearInterval(this.recordingPlayTicker);
    //         this.recordingPlayTicker = undefined;
    //     }

    //     if (this.recordedStreamTimeouts !== undefined) {
    //         this.recordedStreamTimeouts.forEach(timeout => clearTimeout(timeout));
    //     }

    //     if (this.recordedStreamStopTimeouts !== undefined) {
    //         this.recordedStreamStopTimeouts.forEach(timeout => clearTimeout(timeout));
    //     }

    //     if (this.messageTimeouts !== undefined) {
    //         this.messageTimeouts.forEach(timeout => clearTimeout(timeout));
    //     }

    //     //pause videos
    //     if (!skipStateChange) {
    //         this.setState({recordingPlaying: false})
    //     }
    // }

    renderStreams() {
        if (!this.portalNodes || Object.keys(this.portalNodes).length == 0) {
            return;
        }

        return this.liveStreamState.getRecordingInfo().streams.map(stream => {
            const recordingStream = this.recordingStreams.get(stream.streamId);

            return (
                <portals.InPortal key={stream.streamId + "prtl"} node={this.portalNodes[stream.streamId]}>
                    {isStreamTypeVideo(stream.streamType) ? (
                        <LiveVideo 
                        key={stream.streamId + "livevideo"} stream={recordingStream.stream}
                        placement={'c'} minimizedMode={false} fillParentWidth={false} fullScreenMode={false}
                        recordingMediaPlayer={recordingStream.mediaPlayer}
                        onPiPEntered={streamId => this.setState({lastPiPStreamId: streamId})} />
                    ) : (
                        <AudioPlayer key={stream.streamId + "audioplayer"} stream={stream} recordingMediaPlayer={recordingStream.mediaPlayer} />
                    )}
                </portals.InPortal>
            )
        })
    }

}

export default RecordingManager;