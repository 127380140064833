import React from 'react'
import { useMediaQuery } from 'react-responsive'

export default function withMediaQuery(Component) {
    function WrappedComponent(props) {
        const result = {
            isLarge: useMediaQuery({minWidth: 1224}),
            isMedium: useMediaQuery({minWidth: 620, maxWidth: 1223}),
            isSmall: useMediaQuery({maxWidth: 619}),
            renderFullWidth: useMediaQuery({maxWidth: 800})
        }
        return <Component {...result} {...props} />;
    }

    return WrappedComponent;
}