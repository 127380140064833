
import {Session} from 'bc-react-session';
import { ACCOUNT_ROLE_ADMIN, ACCOUNT_ROLE_BLOGGER, ACCOUNT_ROLE_CONTENT_MANAGER, ACCOUNT_ROLE_ORGANIZATION, ACCOUNT_ROLE_PAYMENT_MANAGER, ACCOUNT_ROLE_RELATION_MANAGER, ACCOUNT_ROLE_STUDENT, ACCOUNT_ROLE_TUTOR, ACCOUNT_SCOPE_BACKEND } from '../Constants';
import Api from './Api';
import SocketSession from './SocketSession';
import PresenceManager from './PresenceManager';
import { THEME_LOCAL_STORAGE_KEY } from '../livestream2/theme/Themes';

class SessionManager {

    createSession(accountObject) {
        Session.start({
            payload: {
                account: accountObject,
            },
            expiration: 86400000
        })
        SocketSession.open();
        PresenceManager.start();
    }

    isLoggedInFrontend() {
        return this.isLoggedInAsStudent() || this.isLoggedInAsTutor() || this.isLoggedInAsOrganization() || this.isLoggedInAsRelationManager() || this.isLoggedInAsPaymentManager() || this.isLoggedInAsBlogger() || this.isLoggedInAsContentManager();
    }

    isLoggedInAsStudent() {
        return this.isLoggedInAs(ACCOUNT_ROLE_STUDENT);
    }

    isLoggedInAsStudentOrTutor() {
        return this.isLoggedInAs(ACCOUNT_ROLE_STUDENT) || this.isLoggedInAs(ACCOUNT_ROLE_TUTOR);
    }

    isLoggedInAsTutor() {
        return this.isLoggedInAs(ACCOUNT_ROLE_TUTOR) || this.isLoggedInAs(ACCOUNT_ROLE_ORGANIZATION);
    }

    isLoggedInAsTutorONLY() {
        return this.isLoggedInAs(ACCOUNT_ROLE_TUTOR);
    }

    isLoggedInAsOrganization() {
        return this.isLoggedInAs(ACCOUNT_ROLE_ORGANIZATION);
    }

    isLoggedInAsRelationManager() {
        return this.isLoggedInAs(ACCOUNT_ROLE_RELATION_MANAGER);
    }

    isLoggedInAsPaymentManager() {
        return this.isLoggedInAs(ACCOUNT_ROLE_PAYMENT_MANAGER);
    }

    isLoggedInAsBlogger() {
        return this.isLoggedInAs(ACCOUNT_ROLE_BLOGGER);
    }

    isLoggedInAsContentManager() {
        return this.isLoggedInAs(ACCOUNT_ROLE_CONTENT_MANAGER);
    }

    isLoggedInAsAdmin() {
        return this.isLoggedInAs(ACCOUNT_ROLE_ADMIN);
    }

    isLoggedInAs(role) {
        return this.isLoggedIn() && this.getAccount().role === role;
    }

    isLoggedInScope(scope) {
        if (scope == ACCOUNT_SCOPE_BACKEND) {
            return this.isLoggedInAs(ACCOUNT_ROLE_ADMIN);
        } else {
            return this.isLoggedInFrontend();
        }
    }

    isLoggedIn() {
        return Session.get().isValid && Session.get().payload.account !== undefined;
    }

    updateAccount(updater) {
        const account = this.getAccount();
        updater(account);
        Session.setPayload({
            account: account
        })
    }

    getAccount() {
        if (this.isLoggedIn()) {
            return Session.get().payload.account;
        } else {
            return {
                id: -1
            }
        }
    }

    logout(onDoneListener) {
        if (!this.isLoggedIn()) {
            return;
        }
        //Api.logout(onDoneListener);
        Session.destroy();
        SocketSession.close();
        PresenceManager.stop();
        try {
            window.localStorage.removeItem(THEME_LOCAL_STORAGE_KEY);
        } catch (e) { }
        onDoneListener();
    }

}

export default new SessionManager();