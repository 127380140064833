import React from 'react'
import Page from '../base/Page';
import withMediaQuery from '../components/WithMediaQuery';

import { 
    IdcardOutlined
} from '@ant-design/icons';
import UserList from '../components/UserList';

class AllSubscribersPage extends Page {

    render() {
        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div style={{width: this.props.renderFullWidth ? '90vw' : '75vw', paddingTop: '25px', paddingBottom: '25px'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <h2 style={{height: '25px'}}><IdcardOutlined /> Subscribers</h2>
                        <div style={{flex: '1'}} />
                    </div>
                    <br />
                    <UserList showAllSubscribers />
                </div>
            </div>
        )
    }
}

export default withMediaQuery(AllSubscribersPage);