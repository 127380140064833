import React from 'react'
import Page from '../base/Page';

class AccountSettingsPage extends Page {

    render() {
        return (
            <h2>uyaaaaaa</h2>
        )
    }

}

export default AccountSettingsPage;