import { Col, Row, Button, Spin, Empty, Card } from 'antd';

import {
    LeftOutlined,
    RightOutlined,
    LeftCircleTwoTone,
    RightCircleTwoTone
} from '@ant-design/icons';
import React from 'react'
import Api from '../../session/Api';
import withMediaQuery from '../../components/WithMediaQuery';

import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import HashtagCover from '../../images/HashtagCover.jpg'
import { Link } from 'react-router-dom';
import Util from '../../util/Util';

const Item = ({hashtag}) => {
    let customFontSize;
    if (Util.isStringExists(hashtag) && hashtag.length > 12) {
        customFontSize = 18;
    }

    return (
        <Link to={"/hashtag/" + hashtag} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%'}}>
            <Card hoverable 
            bodyStyle={{padding: 0}}
            style={{borderRadius: 5, overflow: 'hidden', cursor: 'pointer', minWidth: 250, maxWidth: 250, height: 295, padding: 0}} >
                <img src={Api.getHashtagThumbnail(hashtag)} style={{width: '100%', height: 225, objectFit: 'cover', objectPosition: 'center'}}
                onError={e => {
                    e.target.onerror = null;
                    e.target.src = HashtagCover;
                }}/>
    
                <div style={{height: 70, display: 'flex', alignItems: 'center', paddingLeft: 15, paddingRight: 15}}>
                    <h1 style={{fontStyle: 'bolder', margin: 0, fontSize: customFontSize, wordWrap: 'break-word', overflow: 'hidden'}}>#{hashtag}</h1>
                </div>
            </Card>
        </Link>
    )
    
}


class HorizontalHashtagsView extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            items: [],
            page: 0,
        }
    }

    componentDidMount() {
        this.loadData(this.props);
    }

    componentWillReceiveProps(newProps) {
        if (this.props.courseId !== newProps.courseId || 
            this.props.searchValue !== newProps.searchValue ||
            this.props.category !== newProps.category) {
            this.setState({
                loading: true,
                items: [],
                page: 0
            })
    
            this.loadData(newProps);
        }
    }

    loadData(props) {
        const listener = response => {
            if (response.status === true) {
                this.setState({
                    loading: false,
                    items: response.payload
                })
            } else {
                //list error
                this.setState({loading: false})
            }
        }

        Api.getFeaturedHashtags(listener);
    }

    count() {
        if (this.props.isSmall) {
            return 1;
        } else {
            return this.props.count !== undefined ? this.props.count : 4;
        }
    }

    isFirstPage() {
        return this.state.page === 0;
    }

    isLastPage() {
        return this.state.page === (this.state.items.length > 0 ? (Math.ceil(this.state.items.length / this.count()) - 1) : 0);
    }

    renderRowContent() {
        const items = this.state.items;
        const itemSelection = items.slice(this.state.page * this.count(), (this.state.page * this.count()) + this.count());
        const span = 24 / this.count();

        return itemSelection.map(item => <Col key={item} span={span}><Item hashtag={item} /></Col>)
    }

    render() {
        if (this.state.loading) {
            return (
                <div style={{display: 'flex', justifyContent: 'center', marginTop: '45px', marginBottom: '45px'}}>
                    <Spin size="large" tip="Loading..." />
                </div>
            );
        }

        if (this.state.items.length === 0) {
            if (this.props.hideIfEmpty) {
                return null;
            } else {
                return <Empty style={{marginTop: '50px'}} image={Empty.PRESENTED_IMAGE_SIMPLE} description={"No trendings"} />
            }
        }

        return (
            <div>
                {this.props.children}
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', ...this.props.style}}>
                    {!this.isFirstPage() && <>
                        {this.props.isSmall ? (
                            <Button onClick={e => this.setState(prevState => ({ page: prevState.page - 1 }))} type="link" style={{position: 'absolute', width: '60px', height: '295px', left: '-5px', zIndex: '1',}}>
                                <LeftCircleTwoTone twoToneColor="#4e2e89" style={{fontSize: '40px', marginBottom: '100px'}} />
                            </Button>
                        ) : (
                            <Button onClick={e => this.setState(prevState => ({ page: prevState.page - 1 }))} type="text" style={{width: '50px', marginLeft: '-65px', marginRight: '15px'}}><LeftOutlined /></Button>
                        )}
                    </>}
                    <Row gutter={15} style={{flex: 1}}>
                        {/*this.state.items.map(item => <Col span={6}><SaleCourseItem course={item} /></Col>)*/}
                        {false ? (
                            <ReactCSSTransitionGroup
                            style={{display: 'flex'}}
                            transitionName="slide"
                            transitionEnterTimeout={500}
                            transitionLeaveTimeout={300}>
                                {this.renderRowContent()}
                            </ReactCSSTransitionGroup>
                        ) : (
                            this.renderRowContent()
                        )}
                        
                    </Row>
                    {!this.isLastPage() && <>
                        {this.props.isSmall ? (
                            <Button onClick={e => this.setState(prevState => ({ page: prevState.page + 1 }))} type="link" style={{position: 'absolute', width: '60px', height: '295px', right: '5px', zIndex: '1',}}>
                                <RightCircleTwoTone twoToneColor="#4e2e89" style={{fontSize: '40px', marginBottom: '100px'}} />
                            </Button>
                        ) : (
                            <Button onClick={e => this.setState(prevState => ({ page: prevState.page + 1 }))} type="text" style={{width: '50px', marginRight: !this.props.keepNextBtnIn ? '-65px' : '0', marginLeft: '15px'}}><RightOutlined /></Button>
                        )}
                    </>}
                </div>
            </div>
        )
    }

}

export default withMediaQuery(HorizontalHashtagsView);