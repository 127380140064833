import React from 'react'
import WaveSurfer from 'wavesurfer.js';

class AudioWaveformDisplay extends React.Component {
    
    constructor(props) {
        super(props);

        this.waveformContainer = React.createRef();
    }

    componentDidMount() {
        this.waveform = WaveSurfer.create({
            barWidth: 3,
            cursorWidth: 1,
            container: this.waveformContainer.current,
            backend: 'WebAudio',
            height: 80,
            progressColor: '#f26622',
            responsive: true,
            waveColor: '#4e2e89',
            interact: false,
            cursorColor: 'transparent',
            barMinHeight: 1,
        })

        if (this.props.onAudioDurationUpdate !== undefined) {
            this.waveform.on('ready', () => 
                this.props.onAudioDurationUpdate(this.waveform.getDuration()))
        }

        if (this.props.src !== undefined) {
            this.waveform.load(this.props.src);
        }
    }

    componentWillUnmount() {
        if (this.waveform !== undefined) {
            this.waveform.destroy();
        }
    }

    render() {
        return (
            <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingRight: '15px'}}>
                <div style={{width: '100%'}} ref={this.waveformContainer} />
            </div>
        )
    }

}

export default AudioWaveformDisplay;