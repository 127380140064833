import React from 'react'
import Page from '../base/Page';
import { CATEGORIES } from '../Categories';
import { Tabs, Space, Button, Row, Col } from 'antd';

import { 
    SmileOutlined, 
    FormOutlined, 
    VideoCameraOutlined, 
    BgColorsOutlined,
    PicLeftOutlined,
    NodeIndexOutlined,
    AudioOutlined,
    CameraOutlined,
    CommentOutlined,
    CodeOutlined,
    ArrowRightOutlined,
    LeftOutlined,
    RightOutlined,
    AuditOutlined,
    UserOutlined,
    FireOutlined,
    TrophyOutlined,
    InstagramFilled,
    FacebookFilled,
    TwitterCircleFilled,
    LikeOutlined,
    YoutubeFilled,
    SearchOutlined,
    EllipsisOutlined,
    PlayCircleFilled,
    CaretRightOutlined
} from '@ant-design/icons';
import SaleCourseItem from '../views/courses/SaleCourseItem';
import { Link } from 'react-router-dom';
import HorizontalCoursesView, { HORIZONTAL_MODE_CATEGORY_COURSES } from '../views/courses/HorizontalCoursesView';

const { TabPane } = Tabs

class AllCoursesPage extends Page {

    render() {
        
        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div style={{width: '75vw', paddingTop: '25px', paddingBottom: '25px'}}>
                    {CATEGORIES.map(category => 
                        <div>
                            <div style={{display: 'flex', alignItems: 'center', marginTop: '25px'}}>
                                {React.cloneElement(category.icon, {
                                    style: {
                                        fontSize: '20px',
                                        color: 'black'
                                    }
                                })}
                                &nbsp;&nbsp;&nbsp;
                                <h2 style={{height: '22px'}}>{category.title}</h2>
                                <div style={{flex: '1'}} />
                                <Link to={"/course-category/" + category.title.toLowerCase()}>
                                    <Button type="link" className="orange-link">View All</Button>
                                </Link>
                            </div>
                            <br />
                            <HorizontalCoursesView category={category.id} mode={HORIZONTAL_MODE_CATEGORY_COURSES} />
                        </div>
                    )}
                </div>
            </div>
        )
    }

}

export default AllCoursesPage;