
import Hls from 'hls.js'

class HlsMediaPlayer {

    hls = new Hls();
    playing = false;
    eventListeners = [];
    lastSetCurrentTime = 0;

    init(src) {
        this.hls.loadSource(src);
    }

    play() {
        this.playing = true;
        if (this.mediaView) {
            this.mediaView.play()
        }
    }

    pause() {
        this.playing = false;
        if (this.mediaView) {
            this.mediaView.pause();
        }
    }

    setCurrentTime(currentTime) {
        this.lastSetCurrentTime = currentTime;
        if (this.mediaView) {
            this.mediaView.currentTime = currentTime;
        }
    }

    registerEvent(event, listener) {
        this.eventListeners.push({ event, listener })
        if (this.mediaView) {
            this.mediaView.addEventListener(event, listener);
        }
    }

    /**
     * @param {HTMLMediaElement} mediaView 
     */
    attachView(mediaView) {
        if (this.mediaView) {
            for (const eventListener of this.eventListeners) {
                this.mediaView.removeEventListener(eventListener.event, eventListener.listener);
            }
            this.mediaView.pause();
        }
        this.mediaView = mediaView;
        this.hls.attachMedia(this.mediaView)
        this.mediaView.preload = "auto"
        this.mediaView.currentTime = this.lastSetCurrentTime;
        for (const eventListener of this.eventListeners) {
            this.mediaView.addEventListener(eventListener.event, eventListener.listener);
        }
        if (this.playing) {
            this.mediaView.play();
        }
    }

    release() {
        if (this.mediaView) {
            for (const eventListener of this.eventListeners) {
                this.mediaView.removeEventListener(eventListener.event, eventListener.listener);
            }
        }

        this.hls.destroy()
    }

}

export default HlsMediaPlayer;