import Modal from 'antd/lib/modal/Modal'
import React, { useEffect } from 'react'


import '../../node_modules/react-vis/dist/style.css';
import { AreaSeries,
    Crosshair,
    XYPlot,
    XAxis,
    YAxis,
    HorizontalGridLines,
    VerticalGridLines,
    LineMarkSeries
} from 'react-vis';
import { Button, Spin, Tooltip } from 'antd';
import Util from '../util/Util';

import { LoadingOutlined } from '@ant-design/icons';
import Api from '../session/Api';
import UIUtil from '../util/UIUtil';
import ProfilePicture from './ProfilePicture';

export default function QuizSubmissionHistoryDialog(props) {
    const [loading, setLoading] = React.useState(true);
    const [point, setPoint] = React.useState([]);

    const [data, setData] = React.useState([]);

    useEffect(() => {
        if (props.quizAttemptUser !== undefined) {
            Api.getStudentQuizSubmissionHistory(props.quizAttemptUser.id, props.quizItem.id, response => {
                if (response.status === true) {
                    setLoading(false);
                    setData(response.payload.map(record => ({
                        x: new Date(record.submissionDate),
                        y: record.percent,
                        ...record
                    })))
                } else {
                    props.onCancel()
                    UIUtil.showError();
                }
            })
        } else {
            Api.getQuizSubmissionHistory(props.quizItem.id, response => {
                if (response.status === true) {
                    setLoading(false);
                    setData(response.payload.map(record => ({
                        x: new Date(record.submissionDate),
                        y: record.percent,
                        ...record
                    })))
                } else {
                    props.onCancel()
                    UIUtil.showError();
                }
            })
        }
    }, [])

    return (
        <Modal {...props} visible title="Attempt History" width={850} bodyStyle={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}} footer={null}>
            {!loading ? <>
            {props.quizAttemptUser !== undefined && 
            <div style={{display: 'flex', alignItems: 'center', marginBottom: 25}}>
                <ProfilePicture userId={props.quizAttemptUser.id} />
                <h2 style={{fontSize: 25, height: 25, marginLeft: 15}}>{props.quizAttemptUser.fullName}</h2>
            </div>}

            <XYPlot width={800} height={300} yDomain={[0,100]} xType="time" onMouseLeave={() => setPoint([])}>
                <VerticalGridLines />
                <HorizontalGridLines />
                <XAxis tickTotal={5} />
                <YAxis tickFormat={value => value + "%"} tickSize={1} />
                <LineMarkSeries
                    className="linemark-series-example-2"
                    curve={'curveMonotoneX'}
                    color="#f26622"
                    /*data={[
                        {x: new Date('01/01/2018'), y: 75},
                        {x: new Date('01/14/2018'), y: 60},
                        {x: new Date('01/18/2018'), y: 80},
                        {x: new Date('01/25/2018'), y: 90}
                    ]}*/
                    data={data}
                    onNearestX={v => {
                        console.log(v);
                        setPoint([v])
                    }}
                />
                <Crosshair values={point} style={{line: {background: '#4e2e89'}}}>
                    <div>
                        {point.length > 0 &&
                        <Tooltip key={point[0].x.getTime()} visible title={<div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                            <p style={{margin: 0, textAlign: 'center'}}>{point[0].score}/{point[0].totalScore} ({point[0].y}%)</p>
                            <p style={{color: '#cecece', margin: 0, textAlign: 'center'}}>{Util.getDate(point[0].x.getTime())}</p>
                        </div>} />}
                    </div>
                </Crosshair>
            </XYPlot> 
            </>:
            <Spin size="large" indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />}
        </Modal>
    )
}