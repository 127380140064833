import React, { useState } from 'react'
import Page from '../../base/Page';
import Video from '../../components/Video';
import Api from '../../session/Api';
import UIUtil from '../../util/UIUtil';
import LiveStreamSignaling from '../signaling/LiveStreamSignaling';
import LiveStreamState, { DEFAULT_LIVE_STREAM_STATE } from '../state/LiveStreamState';
import LiveStreamTicker from '../state/LiveStreamTicker';
import { STREAM_TYPE_CAMERA_VIDEO, STREAM_TYPE_DESKTOP_AUDIO, STREAM_TYPE_DESKTOP_VIDEO, STREAM_TYPE_MIC_AUDIO, STREAM_TYPE_STAGE_WHITEBOARD, STREAM_TYPE_WHITEBOARD } from '../streams/StreamTypes';
import WebRtcSession from '../webrtc/WebRtcSession';


import LiveIconView from '../components/LiveIconView';
import { Badge, Button, Menu, Modal, notification, Radio, Slider, Spin, Switch, Tabs, Tag, Tooltip } from 'antd';

import { ALL_THEMES, DARK_THEME, getTheme, THEME_LOCAL_STORAGE_KEY } from '../theme/Themes';

import LogoSquare from '../../images/LogoSquare.png';

import { 
    LoadingOutlined,
    ApiTwoTone,
    SaveOutlined,
    ExclamationCircleTwoTone,
    MobileTwoTone,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    LogoutOutlined,
    AudioFilled,
    AudioOutlined,
    VideoCameraOutlined,
    VideoCameraFilled,
    TabletOutlined,
    TabletFilled,
    FullscreenExitOutlined,
    EyeOutlined,
    EyeFilled,
    TeamOutlined,
    MessageOutlined,
    SettingOutlined,
    SettingFilled,
    BellFilled,
    MessageFilled,
    LockFilled,
    NotificationFilled,
    FundFilled,
    FundOutlined,
    StopOutlined,
    EditOutlined,
    UserOutlined,
    UserSwitchOutlined,
    NotificationOutlined,
    CustomerServiceOutlined,
    CustomerServiceFilled,
    BgColorsOutlined,
    SoundFilled,
    SoundOutlined,
    CloseOutlined,
    PauseOutlined,
    CaretRightOutlined,
    ShareAltOutlined,
    ExportOutlined,
    QrcodeOutlined,
    FormatPainterOutlined
} from '@ant-design/icons';
import { BroadcastingQuestionView, ErrorMessageView, LiveStreamCreditOverMessage, LiveStreamOverMessage, LoadingMessageView } from '../components/LiveStreamSplashViews';
import Sider from 'antd/lib/layout/Sider';
import { withRouter } from 'react-router';
import LiveVideo from '../components/LiveVideo';
import Util from '../../util/Util';
import GridView from '../components/GridView';

import QrCodeLogo from '../../images/QrCodeLogo.png'

import { Scrollbars } from 'rc-scrollbars';
import LiveStreamUserList from '../components/LiveStreamUserList';
import { ROOM_PARENT_TYPE_LIVE_STREAM } from '../../Constants';
import ChatView, { getMessageTextForRoom } from '../../views/chats/ChatView';
import ProfilePicture from '../../components/ProfilePicture';
import SessionManager from '../../session/SessionManager';
import AudioPlayer from '../components/AudioPlayer';
import { AudioStreamIndicator } from '../components/AudioStreamIndicator';

import RaiseHandIconWhite from '../../images/RaiseHandIconWhite.svg';
import RaiseHandIconBlack from '../../images/RaiseHandIconBlack.svg';
import RaiseHandIconOrange from '../../images/RaiseHandIconOrange.svg';
import { WHITEBOARD_STREAM_HEIGHT, WHITEBOARD_STREAM_WIDTH } from '../streams/CanvasStream';
import Whiteboard from '../components/Whiteboard';
import ThemeIconView from '../theme/ThemeIconView';

import './live-stream-window-2.less';
import { QRCode } from 'react-qrcode-logo';
import LiveStreamQrCode from '../../sharing-system/LiveStreamQrCode';
import SocketSession from '../../session/SocketSession';
import { Link } from 'react-router-dom';
import withMediaQuery from '../../components/WithMediaQuery';

import * as portals from 'react-reverse-portal';
import RecordingManager, { DEFAULT_RECORDING_STATE, RecordingSeekBar } from '../recording/RecordingManager';

const DRAWER_WIDTH = 350;
const DRAWER_MIN_WIDTH = 300;
const DRAWER_MAX_WIDTH = 450;

const Drawer = (props) => {
    let currentWidth = DRAWER_WIDTH;
    const [width, setWidth] = React.useState(DRAWER_WIDTH);
    const [haveTransition, setHaveTransition] = React.useState(true);

    const resize = (e) => {
        const newWidth = e.movementX + currentWidth;
        setWidth(newWidth > DRAWER_MIN_WIDTH ? (newWidth < DRAWER_MAX_WIDTH ? newWidth : DRAWER_MAX_WIDTH) : DRAWER_MIN_WIDTH);
        currentWidth = newWidth;
    }

    const onMouseDown = () => {
        currentWidth = width;
        setHaveTransition(false);

        window.addEventListener('mousemove', resize)
        window.addEventListener('mouseup', stopResize)
    }

    const stopResize = () => {
        setHaveTransition(true);
        window.removeEventListener('mousemove', resize)
        window.removeEventListener('mouseup', stopResize)
    }

    return (<>
        <div style={{transition: haveTransition ? '250ms' : undefined, position: 'absolute', transform: props.drawerOpen ? 'translate(0px)' : 'translate(-' + width + 'px)', 
        opacity: props.recordingMode ? (props.drawerOpen ? 1 : 0) : 1,
        width: width, height: '100%', background: 'white', zIndex: props.isSmall ? 10000 : undefined}}>
            {props.children}
        </div>
        {!props.isSmall && <div style={{width: props.drawerOpen ? width : 0, transition: haveTransition ? '250ms' : undefined,}} />}
        {!props.isSmall && <div onMouseDown={onMouseDown} style={{height: '100%', width: 4, cursor: 'col-resize'}} />}
    </>);
}

class LiveStreamWindow extends Page {

    constructor(props) {
        super(props);

        if (props.mobileAppMode && props.mobileAppMode.userToken != "undefined" && props.mobileAppMode.userToken != "null") {
            Api.customToken = props.mobileAppMode.userToken;
        }

        this.state = {
            ...this.state,
            ...DEFAULT_LIVE_STREAM_STATE,
            ...DEFAULT_RECORDING_STATE,

            drawerOpen: false,
            fullScreenStreamId: undefined,
            lastPiPStreamId: undefined,

            showFullScreenOverlay: false,

            showHandRaiseNotification: true,
            showNewMessageNotification: true,
            unreadMessageCount: 0,

            menuTab: props.recordingMode ? "chat" : "users",

            showWhiteboard: false,
            whiteboardOptionsHover: false,

            theme:  getTheme(window.localStorage.getItem(THEME_LOCAL_STORAGE_KEY)),

            showBroadcastingQuestionDialog: false,

            showQrCodeDialog: false,

            qrCodeDisplayMode: "advanced"
        }

        this.liveStreamState = new LiveStreamState(() => this.state, this.setState.bind(this))
        if (!this.props.recordingMode) {
            this.liveStreamTicker = new LiveStreamTicker(this.liveStreamState, props.recordingMode);
        }
        this.portalNodes = {};

        this.recordingManager = new RecordingManager(this.liveStreamState, () => this.state, this.setState.bind(this));        
        // if (this.props.recordingMode) {
        //     this.recordingManager.init(this.portalNodes);
        // }

        this.rootRef = React.createRef();
        this.chatRef = React.createRef();
        this.whiteboardRef = React.createRef();
    }

    isGuest() {
        return !SessionManager.isLoggedIn(); 
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.liveStreamState.isInAnyError()) {
            this.safelyExitPiP();
        }

        if (this.isInFullScreen() && this.liveStreamState.isInAnyError()) {
            this.leaveFullScreen();
        }

        if (this.isChatOpen() && this.state.unreadMessageCount > 0) {
            this.setState({unreadMessageCount: 0})
        }

        if (this.state.lastPiPStreamId !== undefined && !this.liveStreamState.hasStream(this.state.lastPiPStreamId)) {
            this.safelyExitPiP();
            this.setState({lastPiPStreamId: undefined});
        }

        if (this.state.showWhiteboard) {
            if (!this.liveStreamState.hasOutputStream(STREAM_TYPE_WHITEBOARD) || this.isInFullScreen() || this.liveStreamState.isInAnyError()) {
                if (!this.liveStreamState.hasOutputStream(STREAM_TYPE_WHITEBOARD)) {
                    if (this.isInFullScreen() || this.liveStreamState.isInAnyError()) {
                        this.setState({showWhiteboard: false, whiteboardOptionsHover: false})
                    } else if (!(this.liveStreamState.isStageUser() && this.liveStreamState.isWhiteboardStageAccess() && this.liveStreamState.hasAnyInputStream(STREAM_TYPE_WHITEBOARD))) {
                        this.setState({showWhiteboard: false, whiteboardOptionsHover: false})
                    }
                } else {
                    this.setState({showWhiteboard: false, whiteboardOptionsHover: false})
                }
                
            }
        }
    }
    
    componentDidMount() {
        super.componentDidMount();
        if (this.liveStreamTicker !== undefined) {
            this.liveStreamTicker.start();
        }

        document.onfullscreenchange = () => {
            if (!document.fullscreenElement) {
                this.setState({fullScreenStreamId: undefined})
                if (this.props.recordingMode) {
                    this.recordingManager.loadRecordedMessages(this.chatRef);
                    // this.loadRecordedMessages();
                }
            }
        }
    }

    componentWillUnmount() {
        this.clearWebRtcComponents();
        if (this.props.recordingMode) {
            this.recordingManager.release();
        }
        if (this.liveStreamTicker !== undefined) {
            this.liveStreamTicker.stop();
        }
        document.onfullscreenchange = null;

        if (this.openedSocketSession) {
            SocketSession.close();
        }
    }

    safelyExitPiP() {
        if (document.pictureInPictureElement) {
            document.exitPictureInPicture();
        }
    }

    getLiveStreamId() {
        if (this.props.recordingMode) {
            return this.props.liveStreamId;
        } else {
            return this.props.match.params.liveStreamId;
        }
    }

    clearWebRtcComponents() {
        if (this.webRtcSession !== undefined) {
            try {
                this.webRtcSession.close();
            } catch (e) { }
            this.webRtcSession = undefined;
        }

        if (this.liveStreamSignaling !== undefined) {
            try {
                this.liveStreamSignaling.stop();
            } catch (e) { }
            this.liveStreamSignaling = undefined;
        }
    }

    startWebRtcComponents(listener) {
        this.webRtcSession = new WebRtcSession(this.getLiveStreamId(), this.liveStreamState);
        this.webRtcSession.chatMessageListener = this.onNewChatMessage.bind(this);
        this.webRtcSession.handRaiseListener = this.onHandRaise.bind(this);
        this.webRtcSession.getNewWhiteboardCanvas = () => {
            this.whiteboardRef.current.clear();
            return this.whiteboardRef.current.getCanvas();
        };

        this.liveStreamSignaling = new LiveStreamSignaling(this.getLiveStreamId());
        this.liveStreamSignaling.start(this.webRtcSession.onSignalingEvent.bind(this.webRtcSession), success => {
            if (success) {
                listener(true);
            } else {
                listener(false);
            }
        });
    }

    onError(notSupportedError) {
        this.clearWebRtcComponents();
        this.liveStreamState.onLoaded(undefined, notSupportedError);
    }

    onPageStart() {
        this.liveStreamState.refresh();

        if (this.props.recordingMode) {
            Api.getLiveStreamRecording(this.getLiveStreamId(), response => 
                this.liveStreamState.onLoadedRecording(response.payload, () => this.recordingManager.init(this.portalNodes, this.chatRef))
            )
        } else {
            // if (!SessionManager.isLoggedIn()) {
                
            // }

            this.clearWebRtcComponents();
            const onStart = () => {
                this.startWebRtcComponents(success => {
                    if (success) {
                        Api.shouldAskForLiveStreamBroadcasting(this.getLiveStreamId(), response => {
                            if (response.status === true) {
                                if (response.payload === true) {
                                    this.setState({showBroadcastingQuestionDialog: true})
                                } else {
                                    this.joinLiveStream(false);
                                }
                            } else {
                                this.onError(false);
                            }
                        })
                    } else {
                        this.onError(false);
                    }
                })
            }
            
            if (SessionManager.isLoggedIn()) {
                onStart();
            } else {
                Api.createGuestSession(response => {
                    if (response.status === true) {
                        Api.guestToken = response.payload;
                        
                        this.openedSocketSession = true;
                        SocketSession.open();

                        onStart();
                    } else {
                        this.onError(false);
                    }
                })
            }
        }
        
    }

    joinLiveStream(joinAsBroadcaster) {
        this.setState({showBroadcastingQuestionDialog: false})
        setTimeout(() => Api.joinLiveStream(this.getLiveStreamId(), joinAsBroadcaster, response => {
            if (response.status === true) {
                this.webRtcSession.start(response.payload.rtpCapabilities, response.payload.recvTransportInfo, response.payload.sendTransportInfo, response.payload.userCount, response.payload.broadcasterUserId)
                .then(() => this.liveStreamState.onLoaded(response.payload, undefined, () => this.liveStreamSignaling.startListening()))
                .catch(e => this.onError(true))
            } else {
                this.onError(false);
            }            
        }), 500)
    }

    onNewChatMessage(message) {
        if (this.isGuest()) {
            return;
        }

        if (message.deleteEvent) {
            if (this.chatRef.current !== null) {
                this.chatRef.current.onMessageIdDelete(message.messageId);
            }
            return;
        }

        if (this.chatRef.current !== null) {
            this.chatRef.current.addMessage(message, true);
        }

        if (this.state.showNewMessageNotification && this.liveStreamState.isAllowMessaging() && !this.isChatOpen() && message.userId != SessionManager.getAccount().id) {
            const notificationId = Util.newTempId();
            notification.open({
                key: notificationId,
                icon: <ProfilePicture userId={message.userId} />,
                message: message.userFullName,
                description: getMessageTextForRoom(message),
                placement: "bottomRight",
                style: {
                    cursor: 'pointer',
                },
                onClick: () => {
                    if (this.liveStreamState.isAllowMessaging()) {
                        this.setState({drawerOpen: true, menuTab: "chat"})
                    } else {
                        UIUtil.showInfo("Messaging has been disabled by broadcaster")
                    }
                    notification.close(notificationId)
                }
            })
        }

        if (!this.isChatOpen()) {
            this.setState(prevState => ({unreadMessageCount: prevState.unreadMessageCount + 1}))
        }
    }
    
    onHandRaise(user) {
        if (this.isGuest()) {
            return;
        }

        if (this.state.showHandRaiseNotification && user.userId != SessionManager.getAccount().id) {
            const notificationId = Util.newTempId();
            notification.open({
                key: notificationId,
                icon: <img width={30} height={30} src={RaiseHandIconBlack} />,
                message: user.userFullName,
                description: "Hand raise",
                btn: <Button type="primary" icon={<NotificationOutlined />} onClick={() => {
                    notification.close(notificationId);
                    if (!this.liveStreamState.getStageUserIds().includes(user.userId)) {
                        Api.setLiveStreamStageUser2(this.liveStreamState.getLiveStream().id, user.userId, true, response => {
                            if (response.status === true) {
                                UIUtil.showSuccess();
                            } else {
                                UIUtil.showError();
                            }
                        })
                    } else {
                        UIUtil.showInfo("User is already on stage")
                    }
                }}>Bring user to stage</Button>,
                placement: "bottomRight",
            })
        }
    }

    toggleDrawerMenu() {
        this.setState(prevState => ({ drawerOpen: !prevState.drawerOpen }))
    }

    goToFullScreen(streamType, skipFullScreenRequest) {
        if (!skipFullScreenRequest) {
            if (this.rootRef.current.requestFullscreen) {
                this.rootRef.current.requestFullscreen();
            } else if (this.rootRef.current.webkitRequestFullScreen) {
                this.rootRef.current.webkitRequestFullScreen();
            }
        }
        this.setState({fullScreenStreamId: streamType})
        this.safelyExitPiP();
    }

    leaveFullScreen() {
        document.exitFullscreen();
        this.setState({fullScreenStreamId: undefined})
        if (this.props.recordingMode) {
            // this.loadRecordedMessages();
            this.recordingManager.loadRecordedMessages(this.chatRef);
        }
    }

    isInFullScreen() {
        return this.state.fullScreenStreamId !== undefined;
    }

    onFullScreenMouseMove() {
        if (this.fullScreenOverlayTimer !== undefined) {
            clearTimeout(this.fullScreenOverlayTimer);
        } 
        this.setState({showFullScreenOverlay: true})
        this.fullScreenOverlayTimer = setTimeout(() => this.setState({showFullScreenOverlay: false}), 1000)
    }

    isChatOpen() {
        return this.state.menuTab == "chat" && this.state.drawerOpen;
    }

    stopWhiteboard() {
        Modal.confirm({
            icon: <SaveOutlined style={{color: '#4e2e89'}} />,
            title: "Do you want to save?",
            content: "Your whiteboard session will be saved for the next time you turn on the whiteboard",
            cancelText: "Don't Save",
            okText: "Save",
            onOk: () => {
                this.liveStreamState.stopStream(this.webRtcSession, STREAM_TYPE_WHITEBOARD)
            },
            onCancel: () => {
                this.liveStreamState.stopStream(this.webRtcSession, STREAM_TYPE_WHITEBOARD)
                this.whiteboardRef.current.clear();
            }
        })
    }

    renderStreamGrid() {
        const renderStream = (stream, placement) => (
            this.props.recordingMode ? 
            <portals.OutPortal node={this.portalNodes[stream.streamId]}
            onFullScreenBtn={this.goToFullScreen.bind(this)} 
            placement={placement} minimizedMode={false} fillParentWidth={false} fullScreenMode={false} />
                :
            <LiveVideo key={stream.streamId + "livevideo"} placement={placement} stream={stream} onFullScreenBtn={this.goToFullScreen.bind(this)} onPiPEntered={streamId => this.setState({lastPiPStreamId: streamId})} />
        )

        const streams = this.liveStreamState.getAllStreams();
        let elements = [];
        
        if (streams.length == 1) {
            elements.push(renderStream(streams[0], "c"))
        } else {
            const topStreams = streams.filter(stream => stream.streamType == STREAM_TYPE_CAMERA_VIDEO);
            const bottomStreams = streams.filter(stream => stream.streamType != STREAM_TYPE_CAMERA_VIDEO);

            const getStreamPlacement = (index, pos) => {
                const topPos = pos == "top";
                const list = topPos ? topStreams : bottomStreams;
                if (index == 0) {
                    return topPos ? (bottomStreams.length == 0 ? "l" : "tl") : "l";
                } else if (index == (list.length - 1)) {
                    return topPos ? (bottomStreams.length == 0 ? "r" : "tr") : "r"
                } else {
                    return topPos ? (bottomStreams.length == 0 ? "c" : "t") : "c";
                }
            }

            if (topStreams.length > 0) {
                elements.push(topStreams.length == 1 ? (
                    renderStream(topStreams[0], "b")
                ) : (
                    <GridView key="topgrid">
                        {topStreams.map((stream, index) => renderStream(stream, getStreamPlacement(index, "top")))}
                    </GridView>
                ))
            }
            
            if (bottomStreams.length > 0) {
                elements.push(bottomStreams.length == 1 ? (
                    renderStream(bottomStreams[0], "c")
                ) : (
                    <GridView key="bottomgrid">
                        {bottomStreams.map((stream, index) => renderStream(stream, getStreamPlacement(index, "bottom")))}
                    </GridView>
                ))
            }
        }

        return (
            <GridView vertical firstRowSmall>
                {elements}
            </GridView>
        )
    }

    renderOptionsContent() {
        const Header = ({Icon, customIcon, title}) => (
            <div style={{display: 'flex', alignItems: 'center', marginLeft: 10, marginRight: 10}}>
                <div style={{width: 15, marginRight: 7, height: 1, backgroundColor: this.state.theme.sideTextColor, opacity: 0.15}} />
        
                {customIcon !== undefined ? customIcon : <Icon style={{fontSize: 10, color: this.state.theme.sideTextColor}} />}
                <h5 style={{margin: 0, marginLeft: 5, color: this.state.theme.sideTextColor}}>{title}</h5>
        
                <div style={{flex: 1, marginLeft: 7, marginRight: 0, height: 1, backgroundColor: this.state.theme.sideTextColor, opacity: 0.15}} />
            </div>
        )

        return (
            <div style={{paddingTop: 10, paddingBottom: 10}}>
                {!this.props.recordingMode && <>
                <Header Icon={BellFilled} title="Notifications" />
                <div style={{marginLeft: 25, marginRight: 25, marginTop: 10, display: 'flex', alignItems: 'center',}}>
                    <p style={{margin: 0, fontSize: 14, marginLeft: 0, flex: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>New message</p>
                    
                    <Switch checked={this.state.showNewMessageNotification} onChange={checked => this.setState({showNewMessageNotification: checked})} />
                </div>
                {<div style={{marginLeft: 25, marginRight: 25, marginTop: 7, display: 'flex', alignItems: 'center',}}>
                    <p style={{margin: 0, fontSize: 14, marginLeft: 0, flex: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>Hand raise</p>
                    
                    <Switch checked={this.state.showHandRaiseNotification} onChange={checked => this.setState({showHandRaiseNotification: checked})} />
                </div>}
                </>}

                {this.liveStreamState.isHost() && <>
                    <div style={{height: 25}} />
                    <Header Icon={ShareAltOutlined} title="Sharing" />
                    <div style={{marginLeft: 25, marginRight: 25, marginTop: 10, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        <QRCode value={window.location.origin.toString() + Util.getHashLiveStreamPath(this.liveStreamState.getJoinInfo().hashId)} />

                        <Button onClick={() => this.setState({showQrCodeDialog: true})} style={{marginTop: 7}} type="default" icon={<ExportOutlined />}>Share</Button>

                        {/*  */}
                        {/* <QRCode value="https://www.learnerix.com/live-session-sale/A324234323242423"
                            eyeRadius={15}
                            eyeRadius={[
                                [10, 10, 0, 10],
                                [10, 10, 10, 0], // top/right eye
                                [10, 0, 10, 10], // bottom/left
                            ]}
                            //fgColor="#f15f43"
                            fgColor="#4f3688"
                            qrStyle="dots"
                            //quietZone={50}
                            // size={255}
                            logoWidth={45}
                            logoHeight={45}
                            logoImage={QrCodeLogo}
                        /> */}
                    </div>

                    <div style={{height: 25}} />
                    <Header Icon={LockFilled} title="Permissions" />
                    <div style={{marginLeft: 25, marginRight: 25, marginTop: 10, display: 'flex', alignItems: 'center',}}>
                        <p style={{margin: 0, fontSize: 14, marginLeft: 0, flex: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>Allow messaging</p>
                        
                        <Switch checked={this.liveStreamState.isAllowMessaging()} loading={this.liveStreamState.isUpdatingAllowMessaging()} onChange={checked => this.liveStreamState.updateAllowMessaging(checked)} />
                    </div>

                    <div style={{height: 25}} />
                    <Header Icon={NotificationFilled} title="Stage Permissions" />
                    
                    <div style={{marginLeft: 25, marginRight: 25, marginTop: 10, display: 'flex', alignItems: 'center',}}>
                        <p style={{margin: 0, fontSize: 14, marginLeft: 0, flex: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>Mic</p>
                        <Switch checked={this.liveStreamState.isStagePermission(STREAM_TYPE_MIC_AUDIO)} loading={this.liveStreamState.isUpdatingStagePermission(STREAM_TYPE_MIC_AUDIO)} onChange={checked => this.liveStreamState.updateStagePermission(STREAM_TYPE_MIC_AUDIO, checked)} />
                    </div>
                    <div style={{marginLeft: 25, marginRight: 25, marginTop: 7, display: 'flex', alignItems: 'center',}}>
                        <p style={{margin: 0, fontSize: 14, marginLeft: 0, flex: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>Camera</p>
                        <Switch checked={this.liveStreamState.isStagePermission(STREAM_TYPE_CAMERA_VIDEO)} loading={this.liveStreamState.isUpdatingStagePermission(STREAM_TYPE_CAMERA_VIDEO)} onChange={checked => this.liveStreamState.updateStagePermission(STREAM_TYPE_CAMERA_VIDEO, checked)} />
                    </div>
                    <div style={{marginLeft: 25, marginRight: 25, marginTop: 7, display: 'flex', alignItems: 'center',}}>
                        <p style={{margin: 0, fontSize: 14, marginLeft: 0, flex: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>Desktop</p>
                        <Switch checked={this.liveStreamState.isStagePermission(STREAM_TYPE_DESKTOP_VIDEO)} loading={this.liveStreamState.isUpdatingStagePermission(STREAM_TYPE_DESKTOP_VIDEO)} onChange={checked => this.liveStreamState.updateStagePermission(STREAM_TYPE_DESKTOP_VIDEO, checked)} />
                    </div>
                </>}

                {!this.props.recordingMode && <div style={{height: 25}} />}
                <Header Icon={BgColorsOutlined} title="Theme" />
                <div style={{marginLeft: 25, marginRight: 25, marginTop: 10, display: 'flex', alignItems: 'center', gap: 5, flexWrap: 'wrap'}}>
                    {ALL_THEMES.map(theme => <ThemeIconView theme={theme} actualTheme={this.state.theme} darkBg={this.state.theme.sideDark} selected={theme.id == this.state.theme.id} onClick={() => this.setState({ theme: theme }, () => window.localStorage.setItem(THEME_LOCAL_STORAGE_KEY, theme.id))} />)}
                </div>

            </div>
        )
    }

    renderDrawerContent() {
        return (
            <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', background: this.state.theme.sideMenuColor,}}>
                <div style={{ paddingLeft: 15, paddingRight: 15, paddingTop: 15, paddingBottom: 15, display: 'flex', alignItems: 'center'}}>
                    <img src={this.state.theme.menuLogo} style={{width: 100, marginRight: 7}} />
                    <LiveIconView small style={{marginTop: 7}} />

                    <div style={{flex: 1}} />

                    {this.props.isSmall ? (
                        <Button type="link" onClick={() => this.setState({ drawerOpen: false })}><CloseOutlined /></Button>
                    ) : (
                        <p style={{color: this.state.theme.sideTextColor, opacity: 0.65, fontSize: 12, margin: 0, marginTop: 7,}}>
                            {this.props.recordingMode ? (
                                'Recorded'
                            ) : (
                                Util.formatSeconds(this.liveStreamState.getLiveDuration()) + " - " + Util.formatSeconds(this.liveStreamState.getTimeLeft())
                            )}
                        </p>
                    )}
                </div>

                <Tabs size="middle" className="center-tabs" style={{flex: 1}} activeKey={this.state.menuTab} onChange={key => this.setState({menuTab: key})}>
                    {!this.props.recordingMode && <Tabs.TabPane
                    tab={<Badge>
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                            <TeamOutlined style={{marginRight: 0, fontSize: 16}} />
                            <p style={{margin: 0, fontSize: 9, fontWeight: 'bold', marginTop: 7}}>USERS</p>
                        </div>
                    </Badge>}
                    key="users">
                        <LiveStreamUserList theme={this.state.theme} userListKey={this.liveStreamState.getUserListKey()} liveStreamId={this.getLiveStreamId()} host={this.liveStreamState.isHost()} />
                    </Tabs.TabPane>}

                    <Tabs.TabPane
                    disabled={!this.liveStreamState.isAllowMessaging()}
                    tab={
                        <Badge count={this.liveStreamState.isAllowMessaging() ? this.state.unreadMessageCount : 0}>
                            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                <MessageOutlined style={{marginRight: 0, fontSize: 16}} />
                                <p style={{margin: 0, fontSize: 9, fontWeight: 'bold', marginTop: 7}}>MESSAGES</p>
                            </div>
                        </Badge>
                    }
                    key="chat">
                        {this.liveStreamState.isAllowMessaging() ? (
                            (this.liveStreamState.hasLiveStream()) ? (
                                <ChatView ref={this.chatRef} liveStream liveStreamIsBroadcaster={this.liveStreamState.isHost()} liveStreamStartDate={this.liveStreamState.getLiveStream().date} roomParentType={ROOM_PARENT_TYPE_LIVE_STREAM} roomParentId={this.getLiveStreamId()} />
                            ) : (
                                this.props.recordingMode ? (
                                    <ChatView ref={this.chatRef} liveStream liveStreamIsBroadcaster={this.liveStreamState.isHost()} skipLoad disableInput liveStreamStartDate={this.liveStreamState.getRecordingInfo().date} roomParentType={ROOM_PARENT_TYPE_LIVE_STREAM} roomParentId={this.getLiveStreamId()} />
                                ) : (
                                    null
                                )
                            )
                        ) : (
                            <div style={{marginTop: 25, marginBottom: 25, width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                <p style={{margin: 0, fontSize: 12, textAlign: 'center'}}>Messaging has been disabled by broadcaster</p>
                            </div>
                        )}
                    </Tabs.TabPane>

                    <Tabs.TabPane
                    tab={<Badge>
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                            <SettingOutlined style={{marginRight: 0, fontSize: 16}} />
                            <p style={{margin: 0, fontSize: 9, fontWeight: 'bold', marginTop: 7}}>OPTIONS</p>
                        </div>
                    </Badge>}
                    key="options">
                        {this.renderOptionsContent()}
                    </Tabs.TabPane>
                </Tabs>
            </div>
        )
    }

    renderStreamControl({ stopTitle, startTile }, { hasIcon, notIcon }, streamType) {
        const hasStream = this.liveStreamState.hasOutputStream(streamType);
        const loading = this.liveStreamState.isLoadingOutputStream(streamType);

        if (hasStream && !loading && streamType == STREAM_TYPE_WHITEBOARD) {
            return this.renderWhiteboardOptionsBtn();
        }

        return (
            <Tooltip title={hasStream ? stopTitle : startTile}>
                <div className={hasStream ? "live-stream-active-button" : "live-stream-button"}>
                    <Button disabled={loading}
                    onClick={() => hasStream ? this.liveStreamState.stopStream(this.webRtcSession, streamType) : this.liveStreamState.produceStream(this.webRtcSession, streamType, {
                        ...(streamType == STREAM_TYPE_WHITEBOARD ? ({
                            whiteboardCanvas: this.whiteboardRef.current.getCanvas(),
                            onStartedStream: () => this.setState({showWhiteboard: true})
                        }) : {}),
                    })}
                    style={{borderRadius: '50%', width: 35, height: 35, padding: 0}} type="primary">
                        {loading ? <LoadingOutlined spin style={{color: 'white'}} /> : (hasStream ? hasIcon : notIcon)}
                    </Button>
                </div>
            </Tooltip>
        )
    }

    renderWhiteboardOptionsBtn() {
        // const hasStageUser = this.liveStreamState.getStageUserIds().length > 0;
        const hasStageUser = false;
        const hasWhiteboardStageAccess = this.liveStreamState.isWhiteboardStageAccess();

        const whiteboardStageAccessLoading = this.liveStreamState.isUpdatingWhiteboardStageAccess();

        return (
            <div 
            onMouseEnter={() => this.setState({whiteboardOptionsHover: true})} onMouseLeave={() => this.setState({whiteboardOptionsHover: false})}
            style={{borderRadius: 25, paddingRight: 0, display: 'flex', alignItems: 'center', justifyContent: 'flex-end', 
            transition: '250ms', overflow: 'hidden', background: this.state.whiteboardOptionsHover ? 'white' : 'black',
            width: this.state.whiteboardOptionsHover ? (hasStageUser? 145 : 127) : 35, height: 35}}>
                <Tooltip title="Stop Whiteboard">
                    <div className="live-stream-active-link">
                        <Button type="link" style={{paddingRight: 15}} onClick={() => this.stopWhiteboard()}><StopOutlined /></Button>
                    </div>
                </Tooltip>
                <Tooltip title="Open Whiteboard">
                    <Button type="link" style={{paddingLeft: 5, paddingRight: hasStageUser ? 15 : 5}} onClick={() => this.setState({showWhiteboard: true, whiteboardOptionsHover: false})}><EditOutlined /></Button>
                </Tooltip>
                
                {/* {hasStageUser && 
                <Tooltip title={hasWhiteboardStageAccess ? "Stop Stage User Access" : "Give Access to Stage User"}>
                    <div className={hasWhiteboardStageAccess ? "live-stream-active-link" : undefined}>
                        <Button type="link" style={{paddingLeft: 5, paddingRight: 5}} 
                        disabled={whiteboardStageAccessLoading}
                        onClick={() => this.liveStreamState.updateWhiteboardStageAccess(!hasWhiteboardStageAccess)}>
                            {whiteboardStageAccessLoading ? <LoadingOutlined spin style={{color: 'white'}} /> : (hasWhiteboardStageAccess ? <UserSwitchOutlined /> : <UserOutlined />)}
                        </Button>
                    </div>
                </Tooltip>} */}

                <div style={{width: 15}} /> 
                <div className={"live-stream-active-button"}>
                    <Button
                    style={{borderRadius: '50%', width: 35, height: 35, padding: 0, pointerEvents: 'none', cursor: 'default', transition: '250ms', transform: this.state.whiteboardOptionsHover ? 'scale(0.8)' : 'scale(1)'}} type="primary">
                        <FundFilled />
                    </Button>
                </div>
            </div>
        )
    }

    renderHandRaiseBtn() {
        const raisedHand = this.liveStreamState.isHandRaised();
        const loading = this.liveStreamState.isUpdatingRaisedHand();
        return (
            <Tooltip title={raisedHand ? "Unraise Hand" : "Raise Hand"}>
                <div className={raisedHand ? "live-stream-button" : "live-stream-button"}>
                    <Button disabled={loading}
                    onClick={() => this.liveStreamState.updateHandRaise(!raisedHand)}
                    style={{borderRadius: '50%', width: 35, height: 35, padding: 0}} type="primary">
                        {loading ? <LoadingOutlined spin style={{color: 'white'}} /> : (raisedHand ? <img width={15} height={15} src={RaiseHandIconOrange} /> : <img width={15} height={15} src={RaiseHandIconWhite} />)}
                    </Button>
                </div>
            </Tooltip>
        )
    }

    renderStageOpenWhiteboardBtn() {
        return (
            <Tooltip title="Open Whiteboard">
                <div className={"live-stream-active-button"}>
                    <Button
                    onClick={() => this.setState({showWhiteboard: true})}
                    style={{borderRadius: '50%', width: 35, height: 35, padding: 0}} type="primary">
                        <FundFilled />
                    </Button>
                </div>
            </Tooltip>
        )
    }

    renderControls() {
        if (this.isGuest()) {
            return (<>
                <Link to="/auth">
                    <Button type="primary" size="large"><img src={LogoSquare} style={{height: '100%', objectFit: 'contain', objectPosition: 'center', marginRight: 15}} /> Create an account</Button>
                </Link>

                <div style={{width: 15}} />

                <Tooltip title="Leave Live Stream">
                    <div className="live-stream-active-button"><Button onClick={() => this.props.history.goBack()} style={{borderRadius: '50%', width: 35, height: 35, padding: 0}} type="primary"><LogoutOutlined /></Button></div>
                </Tooltip>
            </>);
        }

        return (<>
            {this.liveStreamState.isHost() ? <>
                {this.renderStreamControl({ stopTitle: "Stop Whiteboard", startTile: "Share Whiteboard" }, { hasIcon: <FundFilled />, notIcon: <FundOutlined /> }, STREAM_TYPE_WHITEBOARD)}
                <div style={{width: 5}} />
                {this.renderStreamControl({ stopTitle: "Stop Screen", startTile: "Share Screen" }, { hasIcon: <TabletFilled />, notIcon: <TabletOutlined /> }, STREAM_TYPE_DESKTOP_VIDEO)}
                {/* <div style={{width: 5}} />
                {this.renderStreamControl({ stopTitle: "Stop Desktop Audio", startTile: "Share Desktop Audio" }, { hasIcon: <SoundFilled />, notIcon: <SoundOutlined /> }, STREAM_TYPE_DESKTOP_AUDIO)} */}
                <div style={{width: 5}} />
                {this.renderStreamControl({ stopTitle: "Stop Camera", startTile: "Share Camera" }, { hasIcon: <VideoCameraFilled />, notIcon: <VideoCameraOutlined /> }, STREAM_TYPE_CAMERA_VIDEO)}
                <div style={{width: 5}} />
                {this.renderStreamControl({ stopTitle: "Stop Mic", startTile: "Share Mic" }, { hasIcon: <AudioFilled />, notIcon: <AudioOutlined /> }, STREAM_TYPE_MIC_AUDIO)}
            </> : <>
                {this.liveStreamState.isStageUser() ? (<>
                    <p style={{color: this.state.theme.subtleTextColor, opacity: 0.65, margin: 0, marginRight: 10, alignSelf: 'center'}}>You are on stage</p>

                    {this.liveStreamState.isWhiteboardStageAccess() && this.liveStreamState.hasAnyInputStream(STREAM_TYPE_WHITEBOARD) && <>
                        {this.renderStageOpenWhiteboardBtn()}
                        <div style={{width: 5}} />
                    </>}

                    {this.renderStreamControl({ stopTitle: "Stop Screen", startTile: "Share Screen" }, { hasIcon: <TabletFilled />, notIcon: <TabletOutlined /> }, STREAM_TYPE_DESKTOP_VIDEO)}
                    <div style={{width: 5}} />
                    {this.renderStreamControl({ stopTitle: "Stop Camera", startTile: "Share Camera" }, { hasIcon: <VideoCameraFilled />, notIcon: <VideoCameraOutlined /> }, STREAM_TYPE_CAMERA_VIDEO)}
                    <div style={{width: 5}} />
                    {this.renderStreamControl({ stopTitle: "Stop Mic", startTile: "Share Mic" }, { hasIcon: <AudioFilled />, notIcon: <AudioOutlined /> }, STREAM_TYPE_MIC_AUDIO)}
                </>) : (
                    this.renderHandRaiseBtn()
                )}
            </>}

            <div style={{width: 15}} />

            <Tooltip title="Leave Live Stream">
                <div className="live-stream-active-button"><Button onClick={() => this.props.history.goBack()} style={{borderRadius: '50%', width: 35, height: 35, padding: 0}} type="primary"><LogoutOutlined /></Button></div>
            </Tooltip>
        </>)
    }

    renderContent() {
        const shouldShowDrawer = () => {
            if (this.props.recordingMode) {
                return true;
            } else {
                return !this.isGuest()
            }
        }

        return (
            <div style={{width: '100%', height: '100%', display: 'flex'}}>
                {shouldShowDrawer() && <Drawer isSmall={this.props.isSmall} recordingMode={this.props.recordingMode} drawerOpen={this.state.drawerOpen}>
                    {this.renderDrawerContent()}
                </Drawer>}

                <div style={{flex: 1, height: '100%', display: 'flex', flexDirection: 'column'}}>
                    {this.props.recordingMode ? (
                        <div style={{ paddingLeft: 25, paddingRight: 25, paddingTop: 15, paddingBottom: 15, display: 'flex', alignItems: 'center'}}>
                            {<Badge dot={this.state.unreadMessageCount > 0 && !this.state.drawerOpen}>
                                {React.createElement(this.state.drawerOpen ? MenuFoldOutlined : MenuUnfoldOutlined, { style: { fontSize: 18, cursor: 'pointer' }, onClick: () => this.toggleDrawerMenu() })}
                            </Badge>}

                            <div style={{width: 15}} />

                            


                            <RecordingSeekBar 
                            key={'recording-seek-bar-' + this.state.drawerOpen}
                            recordingManager={this.recordingManager} theme={this.state.theme}
                            duration={this.liveStreamState.getRecordingInfo().duration} />

                            <Tooltip title="Close">
                                <div className="live-stream-active-button"><Button onClick={this.props.onBack} style={{borderRadius: '50%', width: 35, height: 35, padding: 0}} type="primary"><CloseOutlined /></Button></div>
                            </Tooltip>
                        </div>
                    ) : (
                        <div style={{ paddingLeft: this.isGuest() ? 15 : 25, paddingRight: 25, paddingTop: 15, paddingBottom: 15, display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                            {!this.isGuest() && <Badge dot={this.state.unreadMessageCount > 0 && !this.state.drawerOpen}>
                                {React.createElement(this.state.drawerOpen ? MenuFoldOutlined : MenuUnfoldOutlined, { style: { fontSize: 18, cursor: 'pointer' }, onClick: () => this.toggleDrawerMenu() })}
                            </Badge>}

                            <div style={{display: 'flex', alignItems: 'center', transition: '250ms', opacity: this.state.drawerOpen ? 0 : 1, transform: this.state.drawerOpen ? 'translate(200px)' : 'translate(0px)', pointerEvents: 'none'}}>
                                <img src={this.state.theme.mainLogo} style={{width: 120, marginLeft: 25, marginRight: 7}} />
                                <LiveIconView style={{marginTop: 7}} />
                                
                                <span color="#990000" style={{marginLeft: 25, marginTop: 7}}><TeamOutlined /> {this.liveStreamState.getUserCount()}</span>
                                <p style={{color: this.state.theme.mainTextColor, opacity: 0.65, fontSize: 12, margin: 0, marginTop: 7, marginLeft: 25}}>{Util.formatSeconds(this.liveStreamState.getLiveDuration()) + " - " + Util.formatSeconds(this.liveStreamState.getTimeLeft())}</p>
                            </div>
                            <div style={{flex: 1}} />


                            {this.props.isSmall ? <div style={{flex: 1, display: 'flex', marginTop: 15}}>
                                {this.renderControls()}
                            </div> : this.renderControls()}
                        </div>
                    )}

                    

                    {this.liveStreamState.hasAnyAudioStreams() && 
                    <div style={{paddingTop: 0, paddingBottom: 5, paddingLeft: 5, paddingRight: 15, display: 'flex', flexWrap: 'wrap'}}>
                        {this.liveStreamState.getAllAudioStreams().map(stream => <AudioStreamIndicator stream={stream} />)}
                    </div>}

                    <div style={{flex: 1, width: '100%', padding: /*15*/ 0, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        {this.liveStreamState.hasAnyStreams() ? (
                            this.renderStreamGrid()
                        ) : (
                            <p style={{color:  this.state.theme.subtleTextColor, opacity: 0.65,}}>No video stream is being shared</p>
                        )}
                    </div>
                </div>
            </div>
        )
    }

    renderFullScreenContent() {
        return (
            <div onMouseMove={this.onFullScreenMouseMove.bind(this)} style={{width: '100%', height: '100%', display: 'flex', position: 'relative'}}>
                <div style={{
                    flex: 1, height: '100%', display: 'flex', flexDirection: 'column', zIndex: 1, transition: '750ms', 
                    //background: this.liveStreamState.hasAnyStreamsExcept(this.state.fullScreenStreamId) ? 'linear-gradient(to top, rgba(0,0,0,0), rgba(0,0,0,0.65) 40%, rgba(0,0,0,1) 80%)' : 'linear-gradient(to top, rgba(0,0,0,0), rgba(0,0,0,0.65) 55%, rgba(0,0,0,1) 95%)', 
                    opacity: this.state.showFullScreenOverlay || !this.liveStreamState.hasStream(this.state.fullScreenStreamId) ? 1 : 0
                }}>
                    <div style={{ paddingLeft: 25, paddingRight: 25, paddingTop: 15, paddingBottom: 15, display: 'flex', alignItems: 'center'}}>
                        <div style={{display: 'flex', alignItems: 'center', transition: '250ms'}}>
                            <img src={this.state.theme.mainLogo} style={{width: 120, marginLeft: 25, marginRight: 7}} />
                            <LiveIconView style={{marginTop: 7}} />
                        </div>
                        <div style={{flex: 1}} />

                        <Tooltip title="Exit Full Screen">
                            <div className="live-stream-button"><Button onClick={() => this.leaveFullScreen()} style={{borderRadius: '50%', width: 35, height: 35, padding: 0}} type="primary"><FullscreenExitOutlined /></Button></div>
                        </Tooltip>
                    </div>
                    <Scrollbars 
                    renderThumbHorizontal={(props) => <div {...props} style={{...props.style, backgroundColor: 'rgba(255, 255, 255, 0.2)'}} />}
                    style={{height: 180, width: '100%', transition: 'opacity 150ms', opacity: this.state.showFullScreenOverlay || !this.liveStreamState.hasStream(this.state.fullScreenStreamId) ? 1 : 0}}>
                        <div style={{width: '100%', display: 'flex', alignItems: 'center', paddingLeft: 25, transition: '150ms', opacity: this.state.showFullScreenOverlay || !this.liveStreamState.hasStream(this.state.fullScreenStreamId) ? 1 : 0}}>
                            {this.liveStreamState.getAllStreamsExcept(this.state.fullScreenStreamId).map(stream => (<>
                                <div style={{height: 180}}>
                                    {this.props.recordingMode ? (
                                        <portals.OutPortal node={this.portalNodes[stream.streamId]}
                                        onFullScreenBtn={this.goToFullScreen.bind(this)} 
                                        placement={'c'} minimizedMode={true} fillParentWidth={true} fullScreenMode={false} />
                                    ) : (
                                        <LiveVideo key={stream.streamId + "livevideo"} stream={stream} minimizedMode fillParentWidth placement="c" onFullScreenBtn={this.goToFullScreen.bind(this)} onPiPEntered={streamId => this.setState({lastPiPStreamId: streamId})} />
                                    )}
                                </div>
                                <div style={{minWidth: 15, height: 180}} />
                            </>))}
                            <div style={{minWidth: 30, height: 180}} />
                        </div>
                    </Scrollbars>
                </div>

                <div style={{position: 'absolute', pointerEvents: 'none', width: '100%', height: '100%', left: 0, top: 0, zIndex: 0}}>
                    {this.liveStreamState.hasStream(this.state.fullScreenStreamId) &&
                    (
                        this.props.recordingMode ? (
                            <portals.OutPortal node={this.portalNodes[this.state.fullScreenStreamId]} 
                            onFullScreenBtn={this.goToFullScreen.bind(this)} 
                            placement={'c'} minimizedMode={false} fillParentWidth={false} fullScreenMode={true}
                            />
                        ) : (
                            <LiveVideo key={this.state.fullScreenStreamId + "livevideo"} fullScreenMode placement="c" stream={this.liveStreamState.getStream(this.state.fullScreenStreamId)} onPiPEntered={streamId => this.setState({lastPiPStreamId: streamId})} />
                        )
                    )}
                </div>

                {!this.liveStreamState.hasStream(this.state.fullScreenStreamId) && 
                <div style={{position: 'absolute', pointerEvents: 'none', width: '100%', height: '100%', left: 0, top: 0, zIndex: 2, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <p style={{color: this.state.theme.subtleTextColor, opacity: 0.65,}}>Stream is not being shared</p>
                </div>}
            </div>
        )
    }

    renderMessageView() {
        if (true) {
            //return <LiveStreamCreditOverMessage theme={this.state.theme} />
        }

        if (this.state.showBroadcastingQuestionDialog) {
            return <BroadcastingQuestionView theme={this.state.theme} onBroadcaster={() => this.joinLiveStream(true)} onViewer={() => this.joinLiveStream(false)} />
        }

        if (this.liveStreamState.isLoading()) {
            return <LoadingMessageView theme={this.state.theme} />
        }

        if (this.liveStreamState.isInNotSupportedError()) {
            return <ErrorMessageView theme={this.state.theme} icon={ApiTwoTone} message="Device not supported" onBackBtn={this.props.recordingMode ? this.props.onBack : undefined} />
        }

        if (this.liveStreamState.isInError()) {
            return <ErrorMessageView theme={this.state.theme} icon={ApiTwoTone} 
            title={this.props.recordingMode ? "Recording is being processed" : undefined}
            message={this.props.recordingMode ? "Please check back later" : "Live stream does not exist"} onBackBtn={this.props.recordingMode ? this.props.onBack : undefined} />
        }

        if (this.liveStreamState.isTimeoutDisconnectedError()) {
            return <ErrorMessageView theme={this.state.theme} icon={ApiTwoTone} message="Disconnected due to timeout" reconnectButton />
        }

        if (this.liveStreamState.isTokenChangedDisconnectedError()) {
            return <ErrorMessageView theme={this.state.theme} icon={MobileTwoTone} title="Live stream being accessed by another device" message="Live streams can only be accessed by one device per account" reconnectButton="Connect Here" />
        }

        if (this.liveStreamState.isLiveStreamOver()) {
            return <LiveStreamOverMessage theme={this.state.theme} totalTimeSpent={this.liveStreamState.getTotalTimeSpent()} />
        }

        return undefined;
    }

    render() {
        const messageView = this.renderMessageView();

        return (
            <div ref={this.rootRef} className={"live-stream-window " + this.state.theme.className} 
            style={{width: this.props.recordingMode ? '100%' : '100vw', height: this.props.recordingMode ? '100%' : '100vh', 
            background: this.props.mobileAppMode ? 'black' : this.state.theme.backgroundColor, color: this.state.theme.mainTextColor}}>
                {messageView === undefined && this.props.recordingMode && this.recordingManager.renderStreams()}
                {messageView === undefined && this.liveStreamState.getAllInputAudioStreams().map(stream => this.props.recordingMode ? (
                    <portals.OutPortal node={this.portalNodes[stream.streamId]} />
                ) : (
                    <AudioPlayer key={stream.streamId + "audioplayer"} stream={stream} />
                ))}

                {messageView !== undefined ? messageView : (this.isInFullScreen() ? this.renderFullScreenContent() : this.renderContent())}

                <Whiteboard ref={this.whiteboardRef} visible={this.state.showWhiteboard} onClose={() => this.setState({showWhiteboard: false})} 
                backgroundStream={this.liveStreamState.hasLiveStream() ? (
                    this.liveStreamState.isHost() ? (
                        //this.liveStreamState.getInputStream(this.liveStreamState.getStageUserId(), STREAM_TYPE_STAGE_WHITEBOARD)
                        <p>No whiteboard support</p>
                    ) : (
                        this.liveStreamState.isStageUser() ? (
                            this.liveStreamState.getInputStream(this.liveStreamState.getJoinInfo().broadcasterUserId, STREAM_TYPE_WHITEBOARD)
                        ) : (
                            undefined
                        )
                    )
                ) : undefined} />

                {/* {this.liveStreamState.getJoinInfo() && <h1 style={{color: 'green'}}>{this.liveStreamState.getJoinInfo().hashId + ' :id'}</h1>} */}
                {this.liveStreamState.getJoinInfo() && <Modal 
                footer={null}
                title={<><QrcodeOutlined /> QR Code</>}
                bodyStyle={{padding: 0,}}
                width={1080 * 0.5}
                visible={this.state.showQrCodeDialog} onCancel={() => this.setState({showQrCodeDialog: false})}>
                    <div style={{height: 50, display: 'flex', alignItems: 'center', paddingLeft: 15, paddingRight: 15}}>
                        <p style={{margin: 0, flex: 1}}>{this.state.qrCodeDisplayMode == "advanced" ? 'Right click / hold to save the image' : 'Basic QR Code'}</p>

                        <Radio.Group value={this.state.qrCodeDisplayMode} onChange={e => this.setState({qrCodeDisplayMode: e.target.value})} style={{}}>
                            <Radio.Button value="advanced"><FormatPainterOutlined /></Radio.Button>
                            <Radio.Button value="basic"><QrcodeOutlined /></Radio.Button>
                        </Radio.Group>
                    </div>
                    {this.state.qrCodeDisplayMode == "advanced" ? <LiveStreamQrCode 
                    hostName={this.liveStreamState.getJoinInfo().hostName}
                    liveStreamName={this.liveStreamState.getJoinInfo().liveStreamTitle}
                    value={window.location.origin.toString() + Util.getHashLiveStreamPath(this.liveStreamState.getJoinInfo().hashId)} /> : 
                    <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <QRCode size={255} 
                        value={window.location.origin.toString() + Util.getHashLiveStreamPath(this.liveStreamState.getJoinInfo().hashId)} />
                    </div>}
                </Modal>}


            </div>
        )
    }

}

export default withMediaQuery(withRouter(LiveStreamWindow));