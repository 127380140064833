import React from 'react';
import Page from '../base/Page';
import { Button, Radio, Input, Spin, Empty } from 'antd';

import { 
    AlertOutlined
} from '@ant-design/icons';
import Modal from 'antd/lib/modal/Modal';
import Api from '../session/Api';
import { withRouter, Link } from 'react-router-dom';
//import StudentLiveSessionItem from '../views/live-session/StudentLiveSessionItem';

import InfiniteScroll from 'react-infinite-scroller';
import { NO_PAGE_TOKEN, PAGINATION_PAGE_CONTENT_COUNT } from '../Constants';
import UIUtil from '../util/UIUtil';
import StudentLiveSessionItem from '../views/live-sessions/StudentLiveSessionItem';
import Util from '../util/Util';

class StudentMyLiveSessionsPage extends Page {

    constructor(props) {
        super(props)

        this.state = {
            ...this.state, 
            items: [],
            page: NO_PAGE_TOKEN,
            hasMore: true,
            loadingItems: false,
        }
    }

    getNextLiveSessions() {
        if (this.state.loadingItems) {
            return;
        }

        this.setState({
            loadingItems: true
        })

        //list error
        const listener = response => {
            if (response.status === true) {
                let items = this.state.items;
                items.push(...response.payload.items);

                this.setState({
                    items: items,
                    page: response.payload.token,
                    hasMore: Util.isStringExists(response.payload.token),
                    loadingItems: false
                })
            } else {
                //alert('errowr');
            }
        };

        if (this.props.showCourseLiveSessions) {
            Api.getStudentCourseLiveSessions(this.props.courseId, this.state.page, listener);
        } else if (this.props.organization !== undefined) {
            Api.getMyStudentOrganizationLiveSessions(this.props.organization.id, this.state.page, listener);
        } else {
            Api.getMyStudentLiveSessions(this.state.page, listener);
        }
    }

    render() {
        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div style={this.props.showCourseLiveSessions ? {
                    width: '100%', paddingRight: '25px', marginTop: '-10px'
                } : {width: this.props.renderFullWidth ? '90vw' : '75vw', paddingTop: this.props.organization === undefined && '25px', paddingBottom: '25px'}}>
                    {!this.props.showCourseLiveSessions && this.props.organization === undefined && <>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            {/*<Radio.Group value={"grid"} style={{width: '200px'}}>
                                <Radio.Button value="grid"><AppstoreOutlined /></Radio.Button>
                                <Radio.Button value="list"><UnorderedListOutlined /></Radio.Button>
                            </Radio.Group>*/}
                            <h2 style={{height: '25px'}}>Live Sessions</h2>
                            <div style={{flex: '1'}} />
                            <Button type="link">
                                <Link to="/all-live-sessions">
                                    <AlertOutlined /> View All Live Sessions
                                </Link>
                            </Button>
                        </div>
                        <br />
                    </>}

                    <InfiniteScroll
                        loadMore={this.getNextLiveSessions.bind(this)}
                        hasMore={this.state.hasMore}
                        threshold={750}
                        loader={
                            <div style={{display: 'flex', justifyContent: 'center', marginTop: '45px', marginBottom: '45px'}}>
                                <Spin size="large" tip="Loading..." />
                            </div>
                        }
                        style={{overflow: 'visible'}}>

                        {this.state.items.map((item) => {
                            return ( 
                                <div key={item.id}>
                                    <br />
                                    <StudentLiveSessionItem liveSession={item} />
                                </div>
                            )
                        })}
                    </InfiniteScroll>

                    {!this.state.loadingItems ? <Empty style={{marginTop: '50px'}} image={Empty.PRESENTED_IMAGE_SIMPLE} description={this.state.items.length > 0 ? "No more live sessions" : "No live sessions"} /> : null}
                    <br />
                </div>

            </div>
        );
    }

}

export default StudentMyLiveSessionsPage;