import React from 'react';
import Page from '../../base/Page';

import { Tabs, Button, Menu, Input, Upload, InputNumber, Checkbox, Alert, Popconfirm, Popover, Dropdown, Radio, AutoComplete, Spin, Card, Rate } from 'antd';

import './tutor-course-page.less'

import {  
    InfoCircleOutlined, 
    UnorderedListOutlined,
    EditOutlined,
    FormOutlined,
    StarOutlined,
    UserOutlined,
    DollarCircleOutlined,
    SaveOutlined,
    CheckOutlined,
    CloseOutlined,
    PlusOutlined,
    InboxOutlined,
    VideoCameraOutlined,
    QuestionCircleOutlined,
    EyeOutlined,
    EyeInvisibleOutlined,
    QuestionOutlined,
    AlertOutlined,
    FontSizeOutlined,
    DeleteOutlined,
    AuditOutlined,
    CopyOutlined,
    ClusterOutlined,
    ReadOutlined,
    FireFilled,
    FileOutlined,
    OrderedListOutlined,
    FolderViewOutlined,
    MessageOutlined
} from '@ant-design/icons';
import PostsView from '../../views/posts/PostsView';
import { withRouter, Link } from 'react-router-dom';
import RateChart from '../ratings-page/RateChart';
import Api from '../../session/Api';
import Error404Page from '../Error404Page';
import Util from '../../util/Util';
import { Tag, Tooltip } from 'antd';

import { Select } from 'antd';
import { CATEGORIES } from '../../Categories';
import TagBox from '../../components/TagBox';
import UIUtil from '../../util/UIUtil';
import { motion } from 'framer-motion';
import { COURSE_SECTION_TYPE_CUSTOM, COURSE_SECTION_TYPE_LECTURE, COURSE_SECTION_TYPE_HELP_SESSION, COURSE_SECTION_TYPE_LIVE_SESSION, GALLERY_ITEM_TYPE_IMAGE, GALLERY_ITEM_TYPE_VIDEO, RATING_ITEM_TYPE_COURSE, PRODUCT_TYPE_COURSE, ACCOUNT_ROLE_STUDENT, ACCOUNT_ROLE_TUTOR, ACCOUNT_ROLE_ORGANIZATION } from '../../Constants';
import Modal from 'antd/lib/modal/Modal';

import ProductImagePlaceholder from '../../images/ProductImagePlaceholder.png';
import NoImage from '../../images/NoImage.png';
import CourseSectionView from '../../views/course-section/CourseSectionView';
import { getFieldId } from 'antd/lib/form/util';
import RatingsPage from '../ratings-page/RatingsPage';

import {CopyToClipboard} from 'react-copy-to-clipboard';
import UserList from '../../components/UserList';
import SessionManager from '../../session/SessionManager';
import UserListDialog from '../../components/UserListDialog';

import HashtagCover from '../../images/HashtagCover.jpg'
import Meta from 'antd/lib/card/Meta';
import ProfilePicture from '../../components/ProfilePicture';

const { Option } = Select;
const { TabPane } = Tabs;
const { SubMenu } = Menu;

const TabTitle = ({icon, title}) => {
    return (
        <span style={{height: '35px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            {icon}
            {title}
        </span>
    )
}

const DetailsTabTitle = ({icon, title}) => {
    return (
        <span>
            {icon}
            {title}
        </span>
    )
}

const ContentSectionTabTitle = ({icon, title, hidden, editable, deletable, onToggleHiddenBtn, hiddenToggleLoading, onRenameBtn, onDeleteBtn, deleteLoading}) => {
    return (
        <span style={{height: '35px', width: '250px', display: 'flex', alignItems: 'center'}}>
            {icon}
            {title}

            <div style={{flex: 1}} />
            <Tooltip title={hidden ? "Show" : "Hide"} placement="top">
                <Button type="link" style={{width: 25}} loading={hiddenToggleLoading} onClick={e => {
                    e.stopPropagation();
                    onToggleHiddenBtn();
                }}>{hidden ? <EyeInvisibleOutlined /> : <EyeOutlined />}</Button>
            </Tooltip>

            {editable ? 
                <Tooltip title="Rename" placement="topRight">
                    <Button type="link" style={{width: 25}} onClick={e => {
                        e.stopPropagation();
                        onRenameBtn();
                    }}><FontSizeOutlined /></Button>
                </Tooltip>
            : null}

            {deletable ? 
                <Popconfirm title="Are you sure you want to delete this section?" onConfirm={onDeleteBtn}>
                    <Button danger type="link" style={{width: 25}} onClick={e => e.stopPropagation()} loading={deleteLoading}><DeleteOutlined /></Button>
                </Popconfirm>
            : null}
        </span>
    )
}

const FormPadding = () => {
    return ( <> <br /> <br /> </> )
}

class TutorCoursePage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            featuredHashtags: undefined,

            //detailsTab: "store-listing",
            detailsTab: 'store-preview',
            course: {},
            courseDoesNotExist: false,

            thumbnailValue: undefined,
            galleryItemsValue: [],
            titleValue: "",
            descriptionValue: "",
            categoryValue: false,
            hashtagValue: "",
            searchTagsValue: [],
            unlistedValue: false,
            privateValue: false,
            organizationPrivateAccessValue: false,
            priceValue: 0,
            //subPriceValue: 0,
            allowSubValue: false,
            allowNonSubAccessValue: false,

            saving: false,
            publishToggleLoading: false,
            showSuccess: false,

            selectedSectionId: undefined,
            createSection: false,
            createSectionTitle: "",
            creatingSection: false,

            renameSection: false,
            renameSectionItem: undefined,
            renameSectionTitle: "",
            renamingSection: false,

            addPrivateAccess: false,
            addPrivateAccessEmailAddress: "",
            addingPrivateAccess: false,

            grantLearnerAccess: false,
            grantingLearnersAccess: false,

            grantTutorAccess: false,
            grantingTutorsAccess: false,

            showEmptyCourseMessage: false
        }
    }

    getCourseId() {
        return this.props.match.params.courseId;
    }

    componentWillUnmount() {
        if (this.emptyCourseTimeout) {
            clearTimeout(this.emptyCourseTimeout);
        }
    }

    onPageStart() {
        if (this.emptyCourseTimeout) {
            clearTimeout(this.emptyCourseTimeout);
        }
        this.setState({showEmptyCourseMessage: false});
        Api.getTutorCourse(this.getCourseId(), response => {
            if (response.status === true) {
                this.setCourse(response.payload);
                if (response.payload.id != this.shownMessageForId) {
                    if (!Util.isNumberExist(response.payload.lectureCount) &&
                    !Util.isNumberExist(response.payload.fileCount) &&
                    !Util.isNumberExist(response.payload.quizCount) &&
                    !Util.isNumberExist(response.payload.liveSessionCount)) {
                        this.shownMessageForId = response.payload.id;
                        this.setState({showEmptyCourseMessage: true})
                        if (this.emptyCourseTimeout) {
                            clearTimeout(this.emptyCourseTimeout);
                        }
                        this.emptyCourseTimeout = setTimeout(() => this.setState({showEmptyCourseMessage: false}), 10000)
                    }
                }
            } else {
                this.setState({
                    courseDoesNotExist: true
                })
            }
        })

        Api.getFeaturedHashtags(response => this.setState({featuredHashtags: response.status === true ? response.payload : []}))
    }

    onTogglePublishBtn() {
        this.setState({ publishToggleLoading: true })
        Api.toggleCoursePublish(this.state.course.id, response => {
            if (response.status === true) {
                this.state.course.published = response.payload;
                this.setState({
                    publishToggleLoading: false,
                    showSuccess: true
                })
                setTimeout(() => this.setState({showSuccess: false}), 1500);
            } else {
                this.setState({ publishToggleLoading: false })
                UIUtil.showError(response.message);
            }
        })
    }

    onSaveChangesBtn() {
        if (this.state.galleryItemsValue !== undefined && this.state.galleryItemsValue.length > 10) {
            UIUtil.showError("Maximum gallery size exceeded (> 10 items)")
            return;
        }

        this.setState({ saving: true })
        Api.saveCourseDetail({
            id: this.state.course.id,
            title: this.state.titleValue,
            description: this.state.descriptionValue,
            galleryItems: this.state.galleryItemsValue,
            category: this.state.categoryValue,
            hashtag: this.state.hashtagValue,
            searchTags: this.state.searchTagsValue,
            unlisted: this.state.unlistedValue,
            privateAccess: this.state.privateValue,
            organizationPrivateAccess: this.state.organizationPrivateAccessValue,
            price: this.state.priceValue,
            //subscriptionPrice: this.state.subPriceValue,
            allowSubscriptions: this.state.allowSubValue,
            allowNonSubscriptionAccess: this.state.allowNonSubAccessValue
        }, this.state.thumbnailValue, this.state.galleryItemsValue.map(galleryItem => ({
            id: galleryItem.id,
            type: galleryItem.type,
            fileId: galleryItem.fileId,
            file: galleryItem.file,
        })), response => {
            if (response.status === true) {
                for (const galleryItem of this.state.galleryItemsValue) {
                    if (galleryItem.fileUrl !== undefined) {
                        URL.revokeObjectURL(galleryItem.fileUrl);
                    }
                }

                this.setCourse(response.payload, { saving: false, showSuccess: true })
                setTimeout(() => this.setState({showSuccess: false}), 1500);
            } else {
                this.setState({ saving: false })
                UIUtil.showError(Util.isStringExists(response.message) ? response.message : undefined);
            }
        })
    }

    setCourse(course, extra) {
        this.setState(prevState => ({
            course: course,
            thumbnailValue: undefined,
            titleValue: course.title,
            galleryItemsValue: Object.assign([], course.galleryItems),
            descriptionValue: Util.existString(course.description),
            categoryValue: course.category,
            hashtagValue: course.hashtag,
            searchTagsValue: course.searchTags,
            unlistedValue: course.unlisted,
            privateValue: course.privateAccess,
            organizationPrivateAccessValue: course.organizationPrivateAccess,
            priceValue: Util.existNumber(course.price),
            //subPriceValue: Util.existNumber(course.subscriptionPrice),
            allowSubValue: course.allowSubscriptions,
            allowNonSubAccessValue: course.allowNonSubscriptionAccess,

            selectedSectionId: course.sections[0].id.toString(),

            //detailsTab: course.tutorId == SessionManager.getAccount().id ? 'store-listing' : 'enrolled-learners',
            detailsTab: course.tutorId == SessionManager.getAccount().id ? (prevState.detailsTab == 'store-listing' ? 'store-listing' : 'store-preview') : 'enrolled-learners',

            ...(extra !== undefined ? extra : {}),
        }))
    }

    changesMade() {

        return  this.state.thumbnailValue !== undefined ||
                this.state.course.title !== this.state.titleValue || 
                !Util.arrayEqual(this.state.course.galleryItems, this.state.galleryItemsValue) ||
                Util.existString(this.state.course.description) !== this.state.descriptionValue ||
                this.state.course.category !== this.state.categoryValue ||
                this.state.course.hashtag !== this.state.hashtagValue ||
                !Util.arrayEqual(this.state.course.searchTags, this.state.searchTagsValue) ||
                this.state.course.unlisted !== this.state.unlistedValue ||
                this.state.course.privateAccess !== this.state.privateValue ||
                this.state.course.organizationPrivateAccess !== this.state.organizationPrivateAccessValue ||
                Util.existNumber(this.state.course.price) != this.state.priceValue ||
                //Util.existNumber(this.state.course.subscriptionPrice) != this.state.subPriceValue ||
                this.state.course.allowSubscriptions !== this.state.allowSubValue ||
                this.state.course.allowNonSubscriptionAccess !== this.state.allowNonSubAccessValue;
    }

    onChangeTab(tab) {
        this.props.history.push(this.props.location.pathname + "?tab=" + tab);
    }

    onCreateSection() {
        this.setState({creatingSection: true})
        Api.createCourseSection(this.state.course.id, this.state.createSectionTitle, response => {
            if (response.status === true) {
                Util.safeGetProperty(this.state.course, 'sections', []).push(response.payload);
                this.setState({
                    course: this.state.course,
                    selectedSectionId: response.payload.id.toString(),
                    createSection: false, 
                    createSectionTitle: "",
                    creatingSection: false
                })
            } else {
                this.setState({creatingSection: false})
                UIUtil.showError();
            }
        })
    }

    onRenameCourseSection() {
        this.setState({renamingSection: true})
        Api.renameCourseSection(this.state.renameSectionItem.id, this.state.renameSectionTitle, response => {
            if (response.status === true) {
                this.state.renameSectionItem.title = this.state.renameSectionTitle;
                this.setState({
                    renameSection: false, 
                    renameSectionItem: undefined,
                    renameSectionTitle: "",
                    renamingSection: false
                })
            } else {
                this.setState({renamingSection: false})
                UIUtil.showError();
            }
        })
    }

    addPrivateAccess(emailAddress, onSuccess) {
        this.setState({addingPrivateAccess: true})
        Api.addCoursePrivateAccess(this.getCourseId(), emailAddress, response => {
            if (response.status === true) {
                onSuccess();
                if (this.onUserAdded !== undefined) {
                    this.onUserAdded(response.payload)
                }
            } else {
                UIUtil.showError(response.message);
            }
            this.setState({addingPrivateAccess: false})
        })
    }

    grantLearnerAccess(users, onSuccess) {
        this.setState({grantingLearnersAccess: true})
        Api.createUsersAccess(this.getCourseId(), PRODUCT_TYPE_COURSE, users, response => {
            if (response.status === true) {
                onSuccess();
                if (this.onMultipleUserAdded !== undefined) {
                    this.onMultipleUserAdded(response.payload)
                }
            } else {
                UIUtil.showError();
            }
            this.setState({grantingLearnersAccess: false})
        })
    }

    grantTutorAccess(users, onSuccess) {
        this.setState({grantingTutorsAccess: true})
        Api.createTutorsEditAccess(this.getCourseId(), PRODUCT_TYPE_COURSE, users, response => {
            if (response.status === true) {
                onSuccess();
                if (this.onMultipleUserAddedTutor !== undefined) {
                    this.onMultipleUserAddedTutor(response.payload)
                }
            } else {
                UIUtil.showError();
            }
            this.setState({grantingTutorsAccess: false})
        })
    }

    renderDetailsTabContent() {
        switch (this.state.detailsTab) {
            case "store-preview":
                return this.renderPreviewPage();

            case "store-listing":
                return this.renderStoreListing();

            case "ratings":
                return this.renderRatings();

            case "enrolled-learners":
                return this.renderEnrolledLearners();

            case "view-access":
                return this.renderViewAccess();
            
            case "edit-access":
                return this.renderEditAccess();
        }
    }

    renderViewAccess() {
        return (
            <div key="view-access" style={{width: '75vw', paddingTop: '25px', paddingBottom: '25px'}}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <h2 style={{height: '25px'}}>Learners</h2>
                    <div style={{flex: '1'}} />
                    <Button type="link" onClick={e => this.setState({grantLearnerAccess: true})}>
                        <PlusOutlined /> Grant Viewing Access
                    </Button>
                </div>
                <br />
                <UserList showEnrolledUserAccess={{courseId: this.getCourseId(), role: ACCOUNT_ROLE_STUDENT}} emptyMessage="No learners given access" 
                onMultipleUserAddedMethodListener={onMultipleUserAdded => this.onMultipleUserAdded = onMultipleUserAdded}/>
            </div>
        )
    }

    renderEditAccess() {
        return (
            <div key="edit-access" style={{width: '75vw', paddingTop: '25px', paddingBottom: '25px'}}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <h2 style={{height: '25px'}}>Tutors</h2>
                    <div style={{flex: '1'}} />
                    <Button type="link" onClick={e => this.setState({grantTutorAccess: true})}>
                        <PlusOutlined /> Grant Editorial Access
                    </Button>
                </div>
                <br />
                <UserList showEnrolledUserAccess={{courseId: this.getCourseId(), role: ACCOUNT_ROLE_TUTOR}} emptyMessage="No tutors given access" 
                onMultipleUserAddedMethodListener={onMultipleUserAdded => this.onMultipleUserAddedTutor = onMultipleUserAdded}/>
            </div>
        )
    }

    renderEnrolledLearners() {
        return (
            <div style={{width: '75vw', paddingTop: '25px', paddingBottom: '25px'}}>
                <UserList showCourseStats={true} productAccessUsers={{id: this.getCourseId(), type: PRODUCT_TYPE_COURSE}} emptyMessage="No learners enrolled" />
            </div>
        )
    }

    renderPreviewPage() {
        return (
            <div style={{width: '75vw', paddingTop: '25px', paddingBottom: '25px',}}>
                {this.renderPreview(true)}
            </div>
        )
    }

    renderPreview(hideSaveChanges) {
        if (!Util.isStringExists(this.state.course.id)) {
            return null;
        }

        const course = this.state.course;
        return (
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: 450, width: '100%',}}>
                <img src={this.state.thumbnailValue !== undefined ? URL.createObjectURL(this.state.thumbnailValue) : Api.getCourseThumbnail(this.state.course.id)} onError={e => {
                    e.target.onerror = null;
                    e.target.src = ProductImagePlaceholder;
                }} style={{width: '360px', height: '250', borderRadius: 25, objectFit: 'cover',}} />
                {this.state.course.published ? (
                    <Link style={{textDecoration: 'none'}} to={"/course-sale/" + this.getCourseId()}>
                        <h1 style={{margin: 0, marginTop: 15,}}>{this.state.course.title}</h1>
                    </Link>
                ) : (
                    <h1 style={{margin: 0, marginTop: 15,}}>{this.state.course.title}</h1>
                )}

                {/* <Card
                    actions={[<Rate disabled value={course.rating} />]}
                    hoverable
                    //onClick={() => history.push('/course-sale/' + course.id)}
                    style={{ width: 350}}
                    cover={
                    <div>
                        <img src={this.state.thumbnailValue !== undefined ? URL.createObjectURL(this.state.thumbnailValue) : Api.getCourseThumbnail(this.state.course.id)} style={{width: '100%', height: '200px', objectFit: 'cover'}} onError={e => {
                            e.target.onerror = null;
                            e.target.src = ProductImagePlaceholder;
                        }}/>
                        <div style={{display: 'flex'}}>
                            <div style={{height: '61px', width: '61px', marginTop: '-30px', marginLeft: '10px'}}>
                                <ProfilePicture showStatus={false} size={60} userId={course.tutorId} style={{borderStyle: 'solid'}} style={{marginRight: '-0px'}} />
                            </div>

                            <div style={{flex: '1'}} />
                        </div>
                        
                    </div>}
                    >
                    <Meta style={{marginTop: '-20px'}} title={
                        <>
                            <div class="ant-card-meta-title">{course.title}</div>
                            <h6>By {SessionManager.getAccount().fullName}</h6>
                        </>
                    } description={<p style={{height: '50px'}}>{course.description}</p>} />
                </Card> */}


                <div style={{display: 'flex', alignItems: 'center', 
                //marginTop: 50
                }}>
                    <div style={{borderRadius: '50%', marginRight: '15px', background: this.state.course.published ? '#009900' : '#faad14', width: '12px', height: '12px'}} />
                    <h3 style={{margin: 0}}>{this.state.course.published ? 'Published' : 'Unpublished'}</h3>
                </div>


                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: 10}}>
                    {
                    !Util.isNumberExist(this.state.course.lectureCount) &&
                    !Util.isNumberExist(this.state.course.fileCount) &&
                    !Util.isNumberExist(this.state.course.quizCount) &&
                    !Util.isNumberExist(this.state.course.liveSessionCount) && 
                    <Alert
                        message="No Content"
                        description={<>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                Complete your course by uploading lectures and documents!
                                <br />
                                <Button onClick={() => this.onChangeTab("content")} style={{alignSelf: 'flex-end'}} type="link">Edit Content</Button>
                            </div>
                        </>}
                        type="error"
                        showIcon
                    />}

                    {Util.isNumberExist(this.state.course.lectureCount) && 
                    <Button type="link" style={{cursor: 'default'}}><VideoCameraOutlined /> {this.state.course.lectureCount} Lectures ({Util.formatSeconds(this.state.course.lectureRuntime)})</Button>}
                    {Util.isNumberExist(this.state.course.fileCount) && 
                    <Button type="link" style={{cursor: 'default'}}><FileOutlined /> {this.state.course.fileCount} Files ({Util.formatBytes(this.state.course.fileSize)})</Button>}
                    {Util.isNumberExist(this.state.course.quizCount) && 
                    <Button type="link" style={{cursor: 'default'}}><OrderedListOutlined /> {this.state.course.quizCount} Quizzes ({this.state.course.quizQuestions + " Q"})</Button>}
                    {Util.isNumberExist(this.state.course.liveSessionCount) &&
                    <Button type="link" style={{cursor: 'default'}}><AlertOutlined /> {this.state.course.liveSessionCount} Live Sessions ({this.state.course.liveSessionStreams})</Button>}
                </div>

                <div style={{marginTop: 40}}>
                    {/*<div style={{borderRadius: '50%', marginRight: '15px', background: this.state.course.published ? '#009900' : '#faad14', width: '12px', height: '12px'}} />
                    <h3 style={{height: '18px'}}>{this.state.course.published ? 'Published' : 'Unpublished'}</h3>*/}
                    <div style={{flex: 1}} />
                    <Popconfirm disabled={this.changesMade()} title="Are you sure?" onConfirm={this.onTogglePublishBtn.bind(this)}>
                        {!this.state.course.published ? (
                            <Button type="primary" disabled={this.changesMade()} loading={this.state.publishToggleLoading}><CheckOutlined /> Publish</Button>
                        ) : (
                            <Button type="primary" className={this.changesMade() ? "" : "red-button"} disabled={this.changesMade()} loading={this.state.publishToggleLoading}><CloseOutlined /> Unpublish</Button>  
                        )}
                    </Popconfirm>
                    
                    {!hideSaveChanges &&
                        <Button style={{marginLeft: '15px'}} disabled={!this.changesMade()} loading={this.state.saving} onClick={this.onSaveChangesBtn.bind(this)}><SaveOutlined /> Save Changes</Button>}

                    <Button style={{marginLeft: '15px'}} onClick={() => this.onChangeTab("content")}><EditOutlined /> Edit Content</Button>
                </div>
            </div>
        )
    }

    renderStoreListing() {
        return (
            <div style={{width: '75vw', paddingTop: '25px', paddingBottom: '25px'}}>

                {this.state.showSuccess ? 
                    <motion.div initial={{ height: 0, opacity: 0 }} animate={{ height: 55, opacity: 1 }}>
                        <Alert message="Success!" type="success" showIcon closable style={{marginBottom: '15px'}} />
                    </motion.div> 
                : null}

                <div style={{display: 'flex', alignItems: 'center'}}>
                    {/*<div style={{borderRadius: '50%', marginRight: '15px', background: this.state.course.published ? '#009900' : '#faad14', width: '12px', height: '12px'}} />
                    <h3 style={{height: '18px'}}>{this.state.course.published ? 'Published' : 'Unpublished'}</h3>*/}
                    <div style={{flex: 1}} />
                    <Popconfirm disabled={this.changesMade()} title="Are you sure?" onConfirm={this.onTogglePublishBtn.bind(this)}>
                        {!this.state.course.published ? (
                            <Button type="primary" disabled={this.changesMade()} loading={this.state.publishToggleLoading}><CheckOutlined /> Publish</Button>
                        ) : (
                            <Button type="primary" className={this.changesMade() ? "" : "red-button"} disabled={this.changesMade()} loading={this.state.publishToggleLoading}><CloseOutlined /> Unpublish</Button>  
                        )}
                    </Popconfirm>
                    
                    <Button style={{marginLeft: '15px'}} disabled={!this.changesMade()} loading={this.state.saving} onClick={this.onSaveChangesBtn.bind(this)}><SaveOutlined /> Save Changes</Button>
                </div>


                <FormPadding />

                <h2>Details</h2>
                <h3>Title</h3>
                <Input size="large" value={this.state.titleValue} onChange={e => this.setState({titleValue: e.target.value})} />

                <FormPadding />
                <h3>Description</h3>
                <Input.TextArea maxLength={1000} size="large" rows={4} value={this.state.descriptionValue} onChange={e => this.setState({descriptionValue: e.target.value})} />

                <FormPadding />
                <h3>Category</h3>
                <Select value={this.state.categoryValue} onChange={value => this.setState({categoryValue: value})} style={{ width: '100%' }} size="large">
                    {CATEGORIES.map(category => 
                        <Option value={category.id}>{category.icon} &nbsp;&nbsp;{category.title}</Option>)}
                </Select>

                <FormPadding />
                <h3>Hashtag <p style={{fontWeight: 'normal', fontSize: 14, opacity: 0.65}}>(optional) {this.state.featuredHashtags === undefined && <span style={{fontSize: 12, opacity: 0.65}}>Loading trending hashtags</span>}</p></h3>
                <AutoComplete size="large" addonBefore="#" 
                style={{width: '100%'}} 
                options={(this.state.featuredHashtags !== undefined && this.state.featuredHashtags.length > 0) ? [{
                    label: (
                        <p style={{pointerEvents: 'none', fontStyle: 'bolder', fontSize: 18, marginBottom: 2, marginTop: 2}}><FireFilled /> Trending</p>
                    ),
                    options: this.state.featuredHashtags.map(hashtag => ({
                        value: hashtag,
                        label: (
                            <div style={{height: 60, display: 'flex', alignItems: 'center', gap: 10}}>
                                <img src={Api.getHashtagThumbnail(hashtag)} style={{width: 45, height: 45, borderRadius: 5, objectFit: 'cover', objectPosition: 'center'}}
                                onError={e => {
                                    e.target.onerror = null;
                                    e.target.src = HashtagCover;
                                }}/>

                                <p style={{fontSize: 14, color: 'black', margin: 0, opacity: 0.80, textOverflow: 'ellipsis', overflow: 'hidden', paddingRight: 5}}>#{hashtag}</p>
                            </div>
                        )
                    }))
                }] : []}
                value={this.state.hashtagValue} onChange={e => this.setState({hashtagValue: e.replace("#", "")})}
                >
                    <Input addonBefore="#" size="large" />
                </AutoComplete>

                <FormPadding />
                <h3>Search Tags</h3>
                <TagBox value={this.state.searchTagsValue} onChange={e => this.setState({searchTagsValue: e})} />

                <FormPadding />

                <h2>Store Presence</h2>
            
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <h3 style={{height: '19px'}}>Store Link</h3>
                    <CopyToClipboard text={window.location.origin.toString() + "/course-sale/" + this.getCourseId()} onCopy={() => UIUtil.showSuccess("Copied to clipboard!")}>
                        <Tooltip title="Copy"><Button type="link"><CopyOutlined /></Button></Tooltip>
                    </CopyToClipboard>
                </div>
                <p>{window.location.origin.toString() + "/course-sale/" + this.getCourseId()}</p>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <h3 style={{height: 19}}>Visibility Mode</h3>
                    <Tooltip color="purple" title={<p>
                        <strong>Visibility mode affects who can view your course and get access to it.</strong>
                        <br />
                        <br />
                        <strong>Public</strong> includes everyone.
                        <br />
                        <br />
                        <strong>Unlisted</strong> means only the people with an link can view it. It will not appear in any list or search.
                        <br />
                        <br />
                        <strong>Private</strong> means no one can view the course except the accounts you specified.
                        {SessionManager.isLoggedInAsOrganization() && <>
                            <br />
                            <br />
                            <strong>Organization</strong> means no one can view the Live Session except the users enrolled under your organization.
                        </>}
                    </p>}><Button type="link"><QuestionOutlined /></Button></Tooltip>
                </div>
                {/* <Alert
                    message="Pricing"
                    description={<>
                        Prices can only be set for non-public courses. Free courses are monetized based on learner's monthly engagement. <a>Learn how you can earn more money with free courses.</a>
                    </>}
                    type="info"
                    showIcon
                /> */}
                {/* <br /> */}
                <Radio.Group value={(() => {
                    if (this.state.organizationPrivateAccessValue) {
                        return "3";
                    } else if (this.state.privateValue) {
                        return "2";
                    } else if (this.state.unlistedValue) {
                        return "1";
                    } else {
                        return "0";
                    }
                })()} onChange={e => {
                    switch (e.target.value) {
                        case "0":
                            this.setState({
                                unlistedValue: false,
                                privateValue: false,
                                organizationPrivateAccessValue: false,
                            })
                            break;
                        case "1":
                            this.setState({
                                unlistedValue: true,
                                privateValue: false,
                                organizationPrivateAccessValue: false,
                            })
                            break;
                        case "2":
                            this.setState({
                                unlistedValue: true,
                                privateValue: true,
                                organizationPrivateAccessValue: false,
                            })
                            break;
                        case "3":
                            this.setState({
                                unlistedValue: true,
                                privateValue: false,
                                organizationPrivateAccessValue: true,
                            })
                            break;
                    }
                }} buttonStyle="solid">
                    <Radio.Button value="0">Public</Radio.Button>
                    <Radio.Button value="1">Unlisted</Radio.Button>
                    <Radio.Button value="2">Private</Radio.Button>
                    {SessionManager.isLoggedInAsOrganization() && <Radio.Button value="3">Organization</Radio.Button>}
                </Radio.Group>
                <FormPadding />
                {this.state.privateValue && <>
                    <div style={{width: '100%', display: 'flex', alignItems: 'center'}}>
                        <h3 style={{height: '19px'}}>Private Access</h3>
                        <div style={{flex: '1'}} />
                        <Button onClick={() => this.setState({addPrivateAccess: true})}><PlusOutlined /> Add Access</Button>
                    </div>
                    <br />
                    <UserList coursePrivateAccessId={this.getCourseId()} onUserAddedMethodListener={onUserAdded => this.onUserAdded = onUserAdded}  />
                </>}

                {/*<Checkbox checked={this.state.unlistedValue} onChange={e => this.setState({unlistedValue: e.target.checked})}>Unlisted</Checkbox>*/}

                <FormPadding />
                <h2>Promotional Media</h2>

                <h3>Thumbnail</h3>
                <div style={{display: 'flex', maxHeight: '250px'}}>
                    <img src={this.state.thumbnailValue !== undefined ? URL.createObjectURL(this.state.thumbnailValue) : Api.getCourseThumbnail(this.state.course.id)} onError={e => {
                        e.target.onerror = null;
                        e.target.src = NoImage;
                    }} style={{width: '360px', height: '250', objectFit: 'cover'}} />
                    <div style={{height: '250px', maxHeight: '250px', flex: 1, marginLeft: '25px'}}>
                        <Upload.Dragger accept="image/gif,image/jpeg,image/png" showUploadList={false} beforeUpload={file => {
                            this.setState({thumbnailValue: file})
                            return false;
                        }}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        </Upload.Dragger>
                    </div>
                </div>
                {/*<div style={{height: '250px', maxHeight: '250px'}}>
                    <Upload.Dragger>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        {/*<p className="ant-upload-hint">
                            Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                            band files
                        </p>}
                    </Upload.Dragger>
                </div>*/}
                
                <FormPadding />

                <h3>Gallery</h3>
                <Upload 
                listType="picture-card" 
                fileList={this.state.galleryItemsValue.map(galleryItem => ({
                    uid: Util.isStringExists(galleryItem.id) ? galleryItem.id : galleryItem.fileId,
                    status: 'done',
                    name: galleryItem.type === GALLERY_ITEM_TYPE_VIDEO ? "Video" : "Image",
                    url: galleryItem.type === GALLERY_ITEM_TYPE_IMAGE ? (Util.isStringExists(galleryItem.id) ? Api.getGalleryItem(galleryItem.id) : galleryItem.fileUrl) : undefined
                }))} 
                showUploadList={{
                    showPreviewIcon: false
                }}
                accept="image/gif,image/jpeg,image/png,video/mp4"
                multiple={true}
                onRemove={file => {
                    for (let i = 0; i < this.state.galleryItemsValue.length; i++) {
                        const galleryItem = this.state.galleryItemsValue[i];
                        if ((Util.isStringExists(galleryItem.id) ? galleryItem.id : galleryItem.fileId) === file.uid) {
                            if (galleryItem.fileUrl !== undefined) {
                                URL.revokeObjectURL(galleryItem.fileUrl);
                            }
                            this.state.galleryItemsValue.splice(i, 1);
                            break;
                        }
                    }
                    this.setState({})
                }}
                beforeUpload={file => {
                    this.state.galleryItemsValue.push({
                        type: Util.getFileExt(file).toLowerCase() === "mp4" ? GALLERY_ITEM_TYPE_VIDEO : GALLERY_ITEM_TYPE_IMAGE,
                        fileId: Util.newTempId(),
                        file: file,
                        fileUrl: URL.createObjectURL(file)
                    })
                    this.setState({})
                    return false;
                }}>
                    <div>
                        <PlusOutlined />
                        <div className="ant-upload-text">Add</div>
                    </div>
                </Upload>

                <FormPadding />
                {//this.state.unlistedValue 
                true && <>
                    <FormPadding />
                    <h2 style={{margin: 0}}>Pricing</h2>
                    <h3 style={{margin: 0}}>Access Price</h3>

                    <br />
                    <Alert
                        message="Revenue Distribution"
                        description={<>
                            Learnerix takes a {SessionManager.getAccount().role == ACCOUNT_ROLE_ORGANIZATION ? '20%' : '40%'} cut of each paid course purchase. Only free courses will be monetized based on learner's monthly engagement. <a>Learn how you can earn more money with free courses.</a>
                        </>}
                        type="info"
                        showIcon
                    />
                    <br />
                    
                    <InputNumber
                        //defaultValue={1000}
                        size="large"
                        style={{width: '100%'}}
                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                        value={this.state.priceValue} onChange={value => this.setState({priceValue: value})} />
                    <FormPadding />
                </>}

                <FormPadding />
                <h2>Options</h2>
                <Checkbox checked={this.state.allowSubValue} onChange={e => this.setState({allowSubValue: e.target.checked})}>
                    Offer live
                    <Tooltip color="purple" title="If enabled, the course's help session and live streams will only be available to learners with an active subscription."><Button type="link" style={{cursor: 'default'}}><QuestionCircleOutlined /></Button></Tooltip>
                </Checkbox>
                {//!this.state.unlistedValue 
                !Util.isNumberExist(this.state.priceValue) && <>
                    <br />
                    <Checkbox checked={this.state.allowNonSubAccessValue} onChange={e => this.setState({allowNonSubAccessValue: e.target.checked})}>
                        Allow non-subscription access
                        <Tooltip color="purple" title="If enabled, learners without an active subscription can get access to the course. Their engagement's will not be monetized."><Button type="link" style={{cursor: 'default'}}><QuestionCircleOutlined /></Button></Tooltip>
                    </Checkbox>
                </>}

                <FormPadding />
                <FormPadding />

                <h2>You are all set!</h2>
                <p style={{marginTop: -15}}>Preview:</p>

                {this.renderPreview()}

                <FormPadding />
                <FormPadding />
            </div>
        )
    }

    renderRatings() {
        return (
            <div style={{width: '75vw', paddingTop: '25px', paddingBottom: '25px'}}>
                <RatingsPage itemId={this.state.course.id} itemType={RATING_ITEM_TYPE_COURSE} />
            </div>
        )
    }

    render() {
        if (this.state.courseDoesNotExist) {
            return (
                <Error404Page {...this.props} customMessage="Course does not exist" />
            )
        }


        if (!Util.isStringExists(this.state.course.id)) {
            return (
                <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingTop: 45, paddingBottom: 45}}>
                    <Spin size="large" tip="Loading..." />
                </div>
            )
        }

        return (
            <div style={{height: '100%'}}>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: '45px', paddingRight: '45px', marginTop: '30px', marginBottom: '12px'}}>
                    <h1 style={{height: '34px'}}>{this.state.course.title}</h1>
                        
                    <div style={{borderRadius: '50%', marginLeft: '25px', marginRight: '15px', background: this.state.course.published ? '#009900' : '#faad14', width: '12px', height: '12px'}} />
                    <h3 style={{height: '18px', marginRight: !this.state.course.published ? 15 : undefined}}>{this.state.course.published ? 'Published' : 'Unpublished'}</h3>
                    {!this.state.course.published &&
                    <Popconfirm disabled={this.changesMade()} title="Are you sure?" onConfirm={this.onTogglePublishBtn.bind(this)}>
                        <Button className="orange-button" type="primary" disabled={this.changesMade()} loading={this.state.publishToggleLoading}><CheckOutlined /> Publish</Button>
                    </Popconfirm>}
                    {!this.state.course.published && this.state.course.hasAtLeastOneStudent &&
                    <Tooltip title="This course can still be accessed by the learners that own it"><Button type="link" style={{cursor: 'default'}}><AuditOutlined /></Button></Tooltip>}

                    <div style={{flex: 1}} />
                    {//this.state.course.allowSubscriptions && (<>
                    (<>

                        {Util.isStringExists(this.state.course.liveStreamInProgress) && 
                            <Button type="link" className="red-link" style={{cursor: 'default'}}><AlertOutlined /> Live</Button>}
                        {Util.isStringExists(this.state.course.liveStreamInProgress) ? (
                            <Dropdown overlay={<Menu>
                                <Menu.Item onClick={() => this.props.history.push("/my-help-session/" + this.state.course.id)} style={{height: '50px', display: 'flex', alignItems: 'center'}}>
                                    <QuestionCircleOutlined style={{fontSize: '20px'}} /> Help Sessions
                                </Menu.Item>
                                <Menu.Item danger onClick={() => this.props.history.push(Util.getLiveStreamPath(this.state.course.liveStreamInProgress))} style={{height: '50px', display: 'flex', alignItems: 'center'}}>
                                    <AlertOutlined style={{fontSize: '20px'}} /> Join live stream
                                </Menu.Item>
                            </Menu>} 
                            trigger="click">
                                <Button type="primary" size="large" className="red-button" style={{paddingLeft: '25px', paddingRight: '25px'}}>
                                    <QuestionCircleOutlined /> Help Session
                                </Button>
                            </Dropdown> 
                        ) : (
                            <Button loading={!Util.isStringExists(this.state.course.id)} type="primary" size="large" style={{paddingLeft: '25px', paddingRight: '25px'}}>
                                <Link to={'/my-help-session/' + this.state.course.id}>
                                    <QuestionCircleOutlined /> Help Session
                                </Link>
                            </Button>
                        )}

                    </>)}
                    
                </div>
                <Tabs activeKey={this.state.pathParams.tab} size="large" style={{width: '100%',}} type="card" onChange={this.onChangeTab.bind(this)} className="course-page-tabs">
                    <TabPane tab={<TabTitle icon={<InfoCircleOutlined />} title={'Details'} />} key="details">
                        <div style={{display: 'flex'}}>
                            <Menu
                                onClick={this.handleClick}
                                style={{ width: 256, }}
                                //defaultSelectedKeys={[this.state.course.tutorId == SessionManager.getAccount().id ? 'store-listing' : 'enrolled-learners']}
                                defaultSelectedKeys={[this.state.course.tutorId == SessionManager.getAccount().id ? 'store-preview' : 'enrolled-learners']}
                                //onSelect={({key}) => this.setState({detailsTab: key},)}
                                onSelect={({key}) => this.setCourse(this.state.course, {detailsTab: key})}
                                selectedKeys={[this.state.detailsTab]}
                                mode="inline">
                                {this.state.course.tutorId == SessionManager.getAccount().id &&
                                <Menu.Item key="store-preview">
                                    <DetailsTabTitle icon={<FolderViewOutlined />} title="Preview" />
                                </Menu.Item>}
                                {this.state.course.tutorId == SessionManager.getAccount().id &&
                                <Menu.Item key="store-listing">
                                    <DetailsTabTitle icon={<FormOutlined />} title="Store Listing" />
                                </Menu.Item>}
                                <Menu.Item key="ratings">
                                    <DetailsTabTitle icon={<StarOutlined />} title="Ratings" />
                                </Menu.Item>
                                <Menu.Item key="enrolled-learners">
                                    <DetailsTabTitle icon={<UserOutlined />} title="Enrolled Learners" />
                                </Menu.Item>
                                {SessionManager.isLoggedInAsOrganization() &&
                                <Menu.SubMenu icon={<ClusterOutlined />} title="Organizational Access">
                                    <Menu.Item key="view-access">
                                        <DetailsTabTitle icon={<ReadOutlined />} title="Viewing Access" />
                                    </Menu.Item>
                                    <Menu.Item key="edit-access">
                                        <DetailsTabTitle icon={<EditOutlined />} title="Editorial Access" />
                                    </Menu.Item>
                                </Menu.SubMenu>}
                                {/*
                                <Menu.Item key="credits-earned">
                                    <DetailsTabTitle icon={<DollarCircleOutlined />} title="Credits Earned" />
                                </Menu.Item>*/}
                            </Menu>
                            <div style={{flex: 1, display: 'flex', justifyContent: 'center'}}>
                                {this.renderDetailsTabContent()}
                            </div>
                        </div>
                    </TabPane>
                    <TabPane tab={<Popover visible={this.state.pathParams.tab != "content" && this.state.showEmptyCourseMessage} placement="top" content={<div style={{display: this.state.pathParams.tab != "content" && this.state.showEmptyCourseMessage ? 'flex' : 'none', cursor: 'pointer', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        <InboxOutlined style={{fontSize: 60, marginBottom: 5, color: '#f26622'}} />
                        <h2 style={{margin: 0}}>Empty course!</h2>
                        <p>Make sure to add to your course!</p>
                    </div>}>
                        <TabTitle icon={<EditOutlined />} title={'Content'} />
                    </Popover>} key="content">
                        <Tabs size="large" style={{width: '100%',}} type="card" tabPosition="left" type="editable-card" tabBarStyle={{marginTop: 25}} onEdit={(targetKey, action) => {
                            if (action === 'add') {
                                this.setState({
                                    createSection: true
                                })
                            }
                        }} activeKey={this.state.selectedSectionId} onChange={selectedId => this.setState({selectedSectionId: selectedId})}>
                            {(Util.safeGetProperty(this.state.course, "sections", [])).map(section => {
                                const tabProps = {
                                    hidden: section.hidden,
                                    title: section.title,
                                    hiddenToggleLoading: section.loadingHiddenToggle,
                                    onToggleHiddenBtn: () => {
                                        section.loadingHiddenToggle = true;
                                        this.setState({});
                                        Api.toggleCourseSectionHidden(section.id, response => {
                                            if (response.status === true) {
                                                section.hidden = response.payload;
                                            } else {
                                                UIUtil.showError();
                                            }
                                            section.loadingHiddenToggle = undefined
                                            this.setState({});
                                        })
                                    },
                                    deleteLoading: section.loadingDelete,
                                    onDeleteBtn: () => {
                                        section.loadingDelete = true;
                                        this.setState({});
                                        Api.removeCourseSection(section.id, response => {
                                            if (response.status === true) {
                                                const sections = Util.safeGetProperty(this.state.course, "sections", []);
                                                let index = sections.indexOf(section);
                                                if (index != -1) {
                                                    sections.splice(index, 1);
                                                }
                                            } else {
                                                UIUtil.showError(response.message);
                                            }
                                            section.loadingDelete = undefined;
                                            this.setState({});
                                        })
                                    }
                                }

                                let tab;
                                switch (section.type) {
                                    case COURSE_SECTION_TYPE_CUSTOM:
                                        tab = <ContentSectionTabTitle editable onRenameBtn={() => this.setState({
                                            renameSection: true,
                                            renameSectionItem: section,
                                            renameSectionTitle: section.title,
                                        })} {...tabProps} deletable={(new Date().getTime() - section.dateCreated) <= (10 * 60 * 1000)} />
                                        break;
                                    case COURSE_SECTION_TYPE_LECTURE:
                                        tab = <ContentSectionTabTitle icon={<VideoCameraOutlined />} {...tabProps} />
                                        break;
                                    case COURSE_SECTION_TYPE_HELP_SESSION:
                                        tab = <ContentSectionTabTitle icon={<QuestionOutlined />} {...tabProps} />
                                        break;
                                    case COURSE_SECTION_TYPE_LIVE_SESSION:
                                        tab = <ContentSectionTabTitle icon={<AlertOutlined />} {...tabProps} title="Live Streams" />
                                        break;
                                    default:
                                        return (<> </>);
                                }

                                return (
                                    <TabPane tab={tab} closable={false} key={section.id}>
                                        <CourseSectionView section={section} />
                                    </TabPane>
                                )
                            })}

                            
                        </Tabs>
                        
                        {/* <div style={{//marginTop: -41, 
                            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: 284, padding: 15}}>
                            <h4 style={{margin: 0}}>Create new section/chapter</h4>
                            <p style={{textAlign: 'center', fontSize: 12}}>Attach documents/files and create quizzes</p>
                        </div> */}
                        
                    </TabPane>
                    <TabPane tab={<TabTitle icon={<UnorderedListOutlined />} title={'Announcements'} />} key="announcements">
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <div style={{width: '50vw', paddingTop: '25px'}}>
                                <PostsView canAdd={true} courseId={this.getCourseId()} />
                            </div>
                        </div>
                    </TabPane>
                </Tabs>

                <Modal
                    title="Create New Section"
                    visible={this.state.createSection}
                    okText="Create"
                    confirmLoading={this.state.creatingSection}
                    okButtonProps={{disabled: this.state.createSectionTitle.trim() === ""}}
                    onOk={() => this.onCreateSection()}
                    onCancel={() => this.setState({createSection: false, createSectionTitle: ""})}>
                        <Alert
                            message="Important"
                            description="Sections can only be removed for 10 minutes since creation. After 10 minutes, it can only be hidden while still being accessible to the learner. The same applies to every component created as well."
                            type="info"
                            showIcon
                        />
                        <br />
                        <Input className="message-input" placeholder="Title" size="large" value={this.state.createSectionTitle} onChange={e => this.setState({createSectionTitle: e.target.value})} 
                            onPressEnter={() => this.onCreateSection()}/>
                </Modal>

                <Modal
                    title="Rename Section"
                    visible={this.state.renameSection}
                    okText="Rename"
                    confirmLoading={this.state.renamingSection}
                    okButtonProps={{disabled: this.state.renameSectionTitle.trim() === ""}}
                    onOk={() => this.onRenameCourseSection()}
                    onCancel={() => this.setState({renameSection: false, renameSectionItem: undefined, renameSectionTitle: ""})}>
                        <Input className="message-input" placeholder="Title" size="large" value={this.state.renameSectionTitle} onChange={e => this.setState({renameSectionTitle: e.target.value})} 
                            onPressEnter={() => this.onRenameCourseSection()}/>
                </Modal>

                <Modal
                    title="Add Private Access"
                    visible={this.state.addPrivateAccess}
                    okText="Add"
                    confirmLoading={this.state.addingPrivateAccess}
                    okButtonProps={{disabled: this.state.addPrivateAccessEmailAddress.trim() === ""}}
                    onOk={() => this.addPrivateAccess(this.state.addPrivateAccessEmailAddress, () => this.setState({addPrivateAccess: false, addPrivateAccessEmailAddress: ""}))}
                    onCancel={() => this.setState({addPrivateAccess: false, addPrivateAccessEmailAddress: ""})}>
                        <Input className="message-input" placeholder="User email address" size="large" value={this.state.addPrivateAccessEmailAddress} onChange={e => this.setState({addPrivateAccessEmailAddress: e.target.value})} 
                            onPressEnter={() => this.addPrivateAccess(this.state.addPrivateAccessEmailAddress, () => this.setState({addPrivateAccess: false, addPrivateAccessEmailAddress: ""}))}/>
                </Modal>

                {this.state.grantLearnerAccess &&
                <UserListDialog showOrganizationNonAccessLearners title="Grant Access" message="Select learners that you want to grant access to" 
                selectable={true} visible={this.state.grantLearnerAccess} onCancel={() => this.setState({grantLearnerAccess: false})} 
                confirmLoading={this.state.grantingLearnersAccess} courseId={this.getCourseId()} emptyMessage="No users left"
                onOk={users => this.grantLearnerAccess(users, () => this.setState({grantLearnerAccess: false}))} />}

                {this.state.grantTutorAccess &&
                <UserListDialog showOrganizationNonAccessTutors title="Grant Access" message="Select tutors that you want to grant access to" 
                selectable={true} visible={this.state.grantTutorAccess} onCancel={() => this.setState({grantTutorAccess: false})} 
                confirmLoading={this.state.grantingTutorsAccess} courseId={this.getCourseId()} emptyMessage="No users left"
                onOk={users => this.grantTutorAccess(users, () => this.setState({grantTutorAccess: false}))} />}
            </div>
        )
    }

}

export default withRouter(TutorCoursePage);