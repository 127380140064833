import React, { useState } from 'react';
import Page from '../../base/Page';
import withMediaQuery from '../../components/WithMediaQuery';

import { 
    EyeOutlined,
    ClusterOutlined,
    AlertOutlined,
    ArrowRightOutlined,
    BookOutlined,
    UserOutlined,
    LeftOutlined,
    DeleteFilled,
    DragOutlined,
    FireOutlined,
    CameraOutlined
} from '@ant-design/icons';
import { Button, Popconfirm, Spin, Tooltip } from 'antd';
import Api from '../../session/Api';
import UIUtil from '../../util/UIUtil';

import NoImage from '../../images/NoImage.png';
import Util from '../../util/Util';
import { Link, useHistory, withRouter } from 'react-router-dom';

import {
    sortableContainer,
    sortableElement,
    sortableHandle,
} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import FeaturedSelectorView from './FeaturedSelectorView';

export const FEATURED_ITEM_TYPE_COURSES = 0;
export const FEATURED_ITEM_TYPE_LIVE_SESSIONS = 1;
export const FEATURED_ITEM_TYPE_TUTORS = 2;
export const FEATURED_ITEM_TYPE_ORGANIZATIONS = 3;
export const FEATURED_ITEM_TYPE_HASHTAGS = 4;


class FeaturedSelectorPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            featureNewHashtag: false,
            updateHashtagImages: false,
            loadingFeatureNewHashtag: false
        }
    }

    onPageStart() {
        this.setState({loading: true});
        Api.getFeatures(this.getFeaturedItemType(), response => {
            if (response.status === true) {
                this.setState({loading: false, items: response.payload})
            } else {
                UIUtil.showError();
            }
        })
    }

    getFeaturedItemType() {
        return parseInt(this.props.match.params.featuredItemType);
    }

    getIcon() {
        switch (this.getFeaturedItemType()) {
            case FEATURED_ITEM_TYPE_COURSES:
                return BookOutlined;
            case FEATURED_ITEM_TYPE_LIVE_SESSIONS:
                return AlertOutlined;
            case FEATURED_ITEM_TYPE_TUTORS:
                return UserOutlined;
            case FEATURED_ITEM_TYPE_ORGANIZATIONS:
                return ClusterOutlined;
            case FEATURED_ITEM_TYPE_HASHTAGS:
                return FireOutlined;
        }
    }

    getTitle() {
        switch (this.getFeaturedItemType()) {
            case FEATURED_ITEM_TYPE_COURSES:
                return "Featured Courses";
            case FEATURED_ITEM_TYPE_LIVE_SESSIONS:
                return "Featured Live Sessions";
            case FEATURED_ITEM_TYPE_TUTORS:
                return "Featured Tutors";
            case FEATURED_ITEM_TYPE_ORGANIZATIONS:
                return "Featured Organizations";
            case FEATURED_ITEM_TYPE_HASHTAGS:
                return "Trending Hashtags";
        }
    }

    getAllPath() {
        switch (this.getFeaturedItemType()) {
            case FEATURED_ITEM_TYPE_COURSES:
                return "/all-courses";
            case FEATURED_ITEM_TYPE_LIVE_SESSIONS:
                return "/all-live-sessions";
            case FEATURED_ITEM_TYPE_TUTORS:
                return "/all-tutors";
            case FEATURED_ITEM_TYPE_ORGANIZATIONS:
                return "/all-organizations";
        }
    }

    render() {
        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div style={{width: this.props.renderFullWidth ? '90vw' : '75vw', paddingTop: '25px', paddingBottom: '25px'}}>
                    <Button type="link" onClick={() => this.props.history.goBack()}><LeftOutlined /> Go Back</Button>
                    <div style={{display: 'flex', alignItems: 'center', marginTop: 15}}>
                        <h2 style={{height: '25px', flex: 1}}>{React.createElement(this.getIcon())} {this.getTitle()}</h2>
                        
                        {this.getFeaturedItemType() == FEATURED_ITEM_TYPE_HASHTAGS ? (<>
                            <Tooltip title="Update hashtag images">
                                <Button onClick={() => this.setState({updateHashtagImages: true})}>
                                    <CameraOutlined />
                                </Button>
                            </Tooltip>
                            <Button style={{marginLeft: 5}} type="primary" onClick={() => this.setState({featureNewHashtag: true})} loading={this.state.loadingFeatureNewHashtag}>
                                Feature New Hashtag
                            </Button>
                        </>) : (
                            <Link to={this.getAllPath()}>
                                <Button type="link">
                                    View All
                                </Button>
                            </Link>
                        )}
                    </div>
                    <br />
                    <div>
                        <FeaturedSelectorView featuredItemType={this.getFeaturedItemType()} 
                        featureNewHashtag={this.state.featureNewHashtag}
                        updateHashtagImages={this.state.updateHashtagImages}
                        closeFeatureNewHashtag={() => this.setState({featureNewHashtag: false})}
                        closeUpdateHashtagImages={() => this.setState({updateHashtagImages: false})}
                        onSetCreatingFeatureLoading={loading => this.setState({loadingFeatureNewHashtag: loading})} />
                    </div>
                </div>
            </div>
        )
    }

}

export default withMediaQuery(FeaturedSelectorPage);