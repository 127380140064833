import { Device, types } from "mediasoup-client";
import Api from "../../session/Api";
import SessionManager from "../../session/SessionManager";
import { EVENT_ON_ALLOW_MESSAGING_CHANGE, EVENT_ON_DELETE_MESSAGE, EVENT_ON_HAND_RAISE_CHANGE, EVENT_ON_NEW_MESSAGE, EVENT_ON_STAGE_PERMISSION_CHANGE, EVENT_ON_STAGE_USER_CHANGE, EVENT_ON_STREAM_STOPPED, EVENT_ON_USER_JOINED, EVENT_ON_USER_LEFT, EVENT_ON_WHITEBOARD_STAGE_ACCESS_CHANGE, USER_EVENT_ON_NEW_CONSUMER, USER_EVENT_ON_PING_REQUEST } from "../signaling/SignalingEvents";
import LiveStreamState from "../state/LiveStreamState";
import StreamManager from "../streams/StreamManager";
import { ALL_STREAM_TYPES, STREAM_TYPE_STAGE_WHITEBOARD } from "../streams/StreamTypes";

const ICE_SERVCES = [
    {
        urls: 'stun:130.61.235.42:3478'
    },
    {
        urls: 'turn:130.61.235.42:3478',
        username: 'turnuser',
        credential: 'b1FCWLIvUpjns9po'
    }
];

class WebRtcSession {

    liveStreamId;

    /**
     * @type {Map<number, types.Producer>}
     */
    producers = new Map();

    /**
     * @type {Map<number, types.Consumer>}
     */
    consumers = new Map();

    /**
     * @type {StreamManager}
     */
    streamManager;

    /**
     * @type {LiveStreamState}
     */
    liveStreamState;

    /**
     * @type {number}
     */
    userCount;

    /**
     * @type {boolean}
     */
    connected;

    /**
     * @type {() => HTMLCanvasElement}
     */
    getNewWhiteboardCanvas;

    chatMessageListener;
    handRaiseListener;

    constructor(liveStreamId, liveStreamState) {
        this.liveStreamId = liveStreamId;
        this.streamManager = new StreamManager(liveStreamState.onNewRawInputStream.bind(liveStreamState));
        this.liveStreamState = liveStreamState;
        this.userCount = 0;
        this.connected = false;
    }

    async start(rtpCapabilities, recvTransportInfo, sendTransportInfo, userCount, broadcasterUserId) {
        this.updateUserCount(userCount);
        return new Promise(async (resolve, reject) => {
            this.streamManager.broadcasterUserId = broadcasterUserId;

            this.device = this.getDevice();
            if (this.device === null) {
                reject("Device not supported");
                return;
            }

            try {
                await this.device.load({ routerRtpCapabilities: rtpCapabilities });

                this.recvTransport = this.device.createRecvTransport({...recvTransportInfo, iceServers: ICE_SERVCES});
                this.sendTransport = this.device.createSendTransport({...sendTransportInfo, iceServers: ICE_SERVCES});

                this.connectTransport(this.recvTransport, true);
                this.connectTransport(this.sendTransport, false);

                Api.initializeLiveStream(this.liveStreamId, this.device.rtpCapabilities, response => {
                    if (response.status === true) {
                        this.connected = true;

                        resolve();
                    } else {
                        reject("Failed to initialize user");
                    }
                })
            } catch (e) {
                reject(e);
                return;
            }
        })
    }

    async onSignalingEvent(isUserEvent, event) {
        if (this.liveStreamState.isTimeoutDisconnectedError() || this.liveStreamState.isTokenChangedDisconnectedError()) {
            return;
        }

        if (isUserEvent) {
            switch (event.type) {
                case USER_EVENT_ON_NEW_CONSUMER:
                    this.consumeStream(event.payload.producerUserId, event.payload.producerUserFullName, event.payload.host, event.payload.streamType, event.payload.consumerInfo);
                    break;
                
                case USER_EVENT_ON_PING_REQUEST:
                    if (this.liveStreamState.hasLiveStream()) {
                        Api.pingLiveStream(this.liveStreamState.getLiveStream().id, () => {});
                    }
                    break;
            }
        } else {
            switch (event.type) {
                case EVENT_ON_USER_JOINED:
                    this.updateUserList();
                    this.updateUserCount(event.payload.userCount);
                    this.clearConsumedStreams(event.payload.userId);

                    if (event.payload.userId == SessionManager.getAccount().id && this.liveStreamState.hasLiveStream() && this.liveStreamState.getJoinInfo().token != event.payload.userToken) {
                        this.liveStreamState.setTokenChangedDisconnectedError(true);
                    } 
                    
                    // else if (event.payload.userIsHost && this.liveStreamState.isHost() && event.payload.userId != SessionManager.getAccount().id) {
                    //     alert("1")
                    //     this.liveStreamState.setTokenChangedDisconnectedError(true);
                    // }
                    break;

                case EVENT_ON_STREAM_STOPPED:
                    this.stopConsumedStream(event.payload.userId, event.payload.streamType);
                    break;

                case EVENT_ON_USER_LEFT:
                    this.updateUserList();
                    this.updateUserCount(event.payload.userCount);
                    this.clearConsumedStreams(event.payload.userId);

                    if (this.liveStreamState.getStageUserIds().includes(event.payload.userId)) {
                        this.liveStreamState.setStageUserIds(this.liveStreamState.getStageUserIds().filter(item => item != event.payload.userId));
                    }

                    if (event.payload.userId == SessionManager.getAccount().id && this.liveStreamState.hasLiveStream() && this.liveStreamState.getJoinInfo().token == event.payload.userToken) {
                        if (event.payload.broadcastChanged === true) {
                            this.liveStreamState.setTokenChangedDisconnectedError(true);
                        } else {
                            this.liveStreamState.setTimeoutDisconnectedError(true);
                        }
                    }
                    break;

                case EVENT_ON_ALLOW_MESSAGING_CHANGE:
                    this.liveStreamState.setAllowMessaging(event.payload);
                    break;

                case EVENT_ON_NEW_MESSAGE:
                    if (this.chatMessageListener !== undefined) {
                        this.chatMessageListener(event.payload)
                    }
                    break;

                case EVENT_ON_DELETE_MESSAGE:
                    if (this.chatMessageListener !== undefined) {
                        this.chatMessageListener({ deleteEvent: true, messageId: event.payload })
                    }
                    break;

                case EVENT_ON_STAGE_PERMISSION_CHANGE:
                    this.liveStreamState.setStagePermission(event.payload.streamType, event.payload.permission)
                    break;

                case EVENT_ON_STAGE_USER_CHANGE:
                    //this.liveStreamState.setStageUserId(event.payload);
                    if (event.payload.add) {
                        this.liveStreamState.setStageUserIds([...this.liveStreamState.getStageUserIds(), event.payload.userId]);
                    } else {
                        this.liveStreamState.setStageUserIds(this.liveStreamState.getStageUserIds().filter(item => item != event.payload.userId));
                    }
                    this.updateUserList();

                    if (event.payload == SessionManager.getAccount().id) {
                        this.startStageWhiteboardIfNeeded(this.liveStreamState.isWhiteboardStageAccess());
                    }
                    break;

                case EVENT_ON_HAND_RAISE_CHANGE:
                    this.updateUserList();
                    if (event.payload.raised && this.handRaiseListener !== undefined) {
                        this.handRaiseListener(event.payload)
                    }
                    break;

                case EVENT_ON_WHITEBOARD_STAGE_ACCESS_CHANGE:
                    this.liveStreamState.setWhiteboardStageAccess(event.payload);
                    this.startStageWhiteboardIfNeeded(event.payload);
                    break;

            }
        }
    }

    startStageWhiteboardIfNeeded(hasWhiteboardStageAccess) {
        if (hasWhiteboardStageAccess && this.liveStreamState.isStageUser()) {
            this.produceStream(STREAM_TYPE_STAGE_WHITEBOARD, { whiteboardCanvas: this.getNewWhiteboardCanvas() })
        }
    }

    updateUserList() {
        this.liveStreamState.updateUserList();
    }

    updateUserCount(userCount) {
        this.userCount = userCount;
        this.liveStreamState.onUserCountUpdate(userCount);
    }

    async produceStream(streamType, options) {
        const stream = await this.streamManager.createStream(streamType, options);
        const track = stream.track;

        this.closeProducer(streamType);

        const producer = await this.sendTransport.produce({ track, appData: { streamType } })
        this.producers.set(streamType, producer);

        return stream;
    }

    stopProducedStream(streamType, fromServer) {
        this.streamManager.closeStream(streamType);
        this.closeProducer(streamType);
        if (fromServer) {
            this.liveStreamState.onRemoveOutputStream(streamType);
        } else {
            Api.stopStream(this.liveStreamId, streamType, () => {});
        }
    }

    async consumeStream(userId, userFullName, userIsHost, streamType, consumerInfo) {
        this.closeConsumer(userId, streamType);

        const consumer = await this.recvTransport.consume(consumerInfo)
        const rawStream = new MediaStream([ consumer.track ])

        this.streamManager.onInputRawStream(userId, userFullName, userIsHost, streamType, rawStream);
    }

    clearConsumedStreams(userId) {
        for (const stream of ALL_STREAM_TYPES) {
            this.stopConsumedStream(userId, stream);
        }
    }

    stopConsumedStream(userId, streamType) {
        if (userId == SessionManager.getAccount().id) {
            this.stopProducedStream(streamType, true);
        } else {
            this.streamManager.closeInputRawStream(userId, streamType);
            this.closeConsumer(userId, streamType);
        }
    }

    connectTransport(transport, recv) {
        transport.on("connect", ({ dtlsParameters }, callback, errback) => Api.connectLiveStream(this.liveStreamId, recv, dtlsParameters, response => {
            if (response.status === true) {
                callback();
            } else {
                errback();
            }
        }))

        transport.on("produce", (parameters, callback, errback) => Api.startStream(this.liveStreamId, parameters.appData.streamType, parameters, response => {
            if (response.status === true) {
                callback({ id: response.payload });
            } else {
                errback(response);
            }
        }));
    }

    getDevice() {
        try {
            return new Device();
        } catch (e) {
            console.error(e);
            return null;
        }
    }

    close() {
        this.producers.forEach(producer => {
            try {
                producer.close();
            } catch (e) { }
        })
        
        this.consumers.forEach(consumer => {
            try {
                consumer.close();
            } catch (e) { }
        })

        try {
            this.recvTransport.close();
        } catch (e) { }

        try {
            this.sendTransport.close();
        } catch (e) { }

        try {
            this.streamManager.closeAllStreams();
        } catch (e) { }

        this.producers.clear();
        this.consumers.clear();
        this.recvTransport = undefined;
        this.sendTransport = undefined;
        
        this.chatMessageListener = undefined;
    }

    closeProducer(streamType) {
        const producer = this.producers.get(streamType);
        if (producer === undefined) {
            return;
        }

        producer.close();
        this.producers.delete(streamType);
    }

    closeConsumer(userId, streamType) {
        const consumer = this.consumers.get(userId + streamType);
        if (consumer === undefined) {
            return;
        }

        consumer.close();
        this.consumers.delete(userId + streamType);
    }

}

export default WebRtcSession;