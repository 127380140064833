import React from 'react';

import NoImage from '../../images/NoImage.png';
import { Button, Tag, Badge, Rate, Modal, Progress } from 'antd';

import {  
    InfoCircleOutlined, 
    UnorderedListOutlined,
    EditOutlined,
    QuestionCircleOutlined,
    LinkOutlined,
    MessageOutlined,
    SnippetsOutlined,
    CreditCardOutlined,
    AlertOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Api from '../../session/Api';
import ProfilePicture from '../../components/ProfilePicture';
import Util from '../../util/Util';
import { ACCOUNT_ROLE_TUTOR, RATING_ITEM_TYPE_COURSE } from '../../Constants';
import StudentCourseHelpSessionButton from './StudentCourseHelpSessionButton';
import UIUtil from '../../util/UIUtil';

import LogoWhite from '../../images/LogoWhite.png';
import LiveIcon from '../../images/LiveIcon.png';

import ProductImagePlaceholder from '../../images/ProductImagePlaceholder.png';

export default function StudentCourseItem(props) {
    const course = props.course;
    const [subscribing, setSubscribing] = React.useState(false);

    const subscribeCourse = () => {
        const courseId = course.id;

        setSubscribing(true);
        Api.subscribeCourse(courseId, response => {
            if (response.status === true) {
                course.subscribed = true;
                Modal.success({
                    title: "Success!",
                    content: "Course subscribed successfully!",
                })
            } else {
                UIUtil.showError();
            }
            setSubscribing(false);
        })
    }

    return (
        <div style={{width: '100%', borderRadius: '15px', padding: '15px', boxShadow: "0px 0px 25px 0px rgba(0, 0, 0, 0.2)"}}>
            <div style={{display: 'flex'}}>
                <Link to={'/course/' + course.id + '?tab=content'} style={{width: '20%', height: '115px'}}>
                    <img src={Api.getCourseThumbnail(course.id)} style={{width: '100%', height: '115px', objectFit: 'cover', borderRadius: '15px'}} onError={e => {
                        e.target.onerror = null;
                        e.target.src = ProductImagePlaceholder;
                    }} />
                </Link>
                <div style={{marginLeft: '25px', flex: 1, minWidth: 1}}>
                    <div style={{display: 'flex', alignItems: 'center', marginRight: '0px'}}>
                        <Link to={'/course/' + course.id + '?tab=content'}><h2 style={{height: '25px'}}>{course.title}</h2></Link>

                        
                        <div style={{flex: '1'}} />

                        {Util.isStringExists(course.liveStreamInProgress) && 
                            <Button type="link" className="red-link" style={{cursor: 'default', marginLeft: 7}}><AlertOutlined /> Live</Button>}

                        {course.helpSessionState.hasCurrentRequest &&
                        <Badge count={course.unreadHelpSessionMessages} overflowCount={9}>
                            <Link to={"/active-help-session/" + course.id}>
                                <Tag style={{marginRight: course.unreadHelpSessionMessages > 9 ? undefined : 2, marginLeft: 7, cursor: 'pointer'}}icon={<MessageOutlined />} color="blue">Messages</Tag>
                            </Link>
                        </Badge>}

                        <Progress className="progress-non-white-color" type="circle" percent={course.progress} width={45} 
                        style={{marginRight: 0, marginLeft: 30}} strokeColor={course.progress < 100 && "#f26622"} strokeWidth={7} />
                        
                    </div>

                    <div style={{display: 'flex', alignItems: 'center', }}>
                        <Link to={Util.getProfilePath(course.tutorId)} style={{display: 'flex', alignItems: 'center', }}>
                        <div style={{width: '26px', height: '26px'}}>
                            <ProfilePicture showStatus={course.tutorRole == ACCOUNT_ROLE_TUTOR} userId={course.tutorId} size={25}/>
                        </div>
                        <Button type="link" style={{marginLeft: '-5px'}}>
                                {course.tutorName}
                        </Button>
                        </Link>
                    </div>

                    {/*<Button type="link">
                        <Link to={Util.getProfilePath(course.tutorId)}>
                            <ProfilePicture userId={course.tutorId} size={25} style={{marginRight: '10px', marginLeft: '-15px'}} />
                            {course.tutorName}
                        </Link>
                    </Button>*/}
                    {/*<div style={{display: 'flex', alignItems: 'center'}}>
                        {course.subscribed && <h4 style={{height: '16px', color: '#990000'}}>Subscribed</h4>}
                    </div>*/}
                    <br />
                    {/*<p style={{paddingTop: '5px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', flex: '1'}}></p>*/}
                </div>
            </div>

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '10px', width: '100%'}}>
                <Rate disabled value={course.courseRating} style={{marginLeft: '2px'}} />
                <Button type="link" className="orange-link" style={{marginLeft: '2px', paddingTop: '5px'}}>
                    <Link to={"/reviews/" + RATING_ITEM_TYPE_COURSE + "/" + course.id + "?name=" + encodeURIComponent(course.title)}>View Reviews</Link>
                </Button>
                <div style={{flex: 1}} />

                {/*<Button type="link">
                    <Link to={'/course/' + course.id + '?tab=content'}>
                        <FileOutlined /> Content
                    </Link>                    
                </Button>*/}
                <Button type="link">
                    <Link to={'/course/' + course.id + '?tab=announcements'}>
                        <UnorderedListOutlined /> Announcements
                    </Link>
                </Button>
                
                {course.subscribed ? (
                    <StudentCourseHelpSessionButton size="middle" style={{marginLeft: '15px'}} courseId={course.id} helpSessionState={course.helpSessionState} liveStreamInProgress={course.liveStreamInProgress} onUpdate={() => {}} />
                ) : (course.allowSubscriptions &&
                    //<Button type="primary" style={{marginLeft: '15px'}} className="red-button" onClick={() => subscribeCourse()}><CreditCardOutlined /> Subscribe - {course.subscriptionPrice + " USD monthly"}</Button>
                    <div style={{paddingLeft: '15px', paddingRight: '15px', marginLeft: '15px', height: '40px', backgroundColor: '#2d3436', backgroundImage: 'linear-gradient(315deg, #2d3436 0%, #000000 74%)',
                    borderRadius: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}} onClick={() => props.onSubscribeBtn()}>
                        <img src={LogoWhite} style={{width: '85px'}} />
                        <img src={LiveIcon} style={{width: '25px', marginLeft: '15px'}} />
                    </div>
                )}

                {/*course.subscribed ? (
                    <StudentCourseHelpSessionButton size="middle" style={{marginLeft: '15px'}} courseId={course.id} helpSessionState={course.helpSessionState} liveStreamInProgress={course.liveStreamInProgress} onUpdate={() => {}} />
                ) : (
                    <Button type="primary" style={{marginLeft: '15px'}} disabled={!course.subscribed}><QuestionCircleOutlined /> Help Session</Button>
                )*/}
            </div>
        </div>
    )
}