import { ITEM_LIKE_PARENT_TYPE_POST, COMMENT_PARENT_TYPE_POST, POST_PARENT_TYPE_PROFILE, ACCOUNT_ROLE_ADMIN, ACCOUNT_ROLE_STUDENT, ACCOUNT_ROLE_TUTOR, ACCOUNT_SCOPE_FRONTEND, ITEM_LIKE_PARENT_TYPE_BLOG_POST, MESSAGE_ATTACHMENT_TYPE_IMAGE, API_ERROR_MESSAGE_ORGANIZATION_TRIAL_OVER, API_ERROR_PAYLOAD } from "../Constants";
import axios from 'axios';
import SessionManager from "./SessionManager";
import Util from "../util/Util";
import { message } from "antd";
import Compressor from 'compressorjs';

class Api {

    customToken;
    guestToken;

    createGuestSession(listener) {
        this.get('/api/public/guest-session/create-session', listener)   
    }

    login(username, password, scope, listener) {
        this.post('/api/auth/login', {
            username: username,
            password: password,
            scope: scope
        }, listener)
    }

    loginAndActivateOrganizationAccount(username, password, serialNo, listener) {
        this.post('/api/auth/login', {
            username: username,
            password: password,
            scope: ACCOUNT_SCOPE_FRONTEND,
            serialNo: serialNo,
        }, listener)
    }
    
    getLiveSessionScheduleAttachments(scheduleId, listener) {
        this.get('/api/' + (SessionManager.isLoggedIn() ? 'frontend' :  'public') + '/live-session-schedule/get-attachments/' + scheduleId, listener);
    }
    
    removeLiveSessionScheduleAttachment(id, listener) {
        this.get('/api/tutor/live-session-schedule/remove-attachment/' + id, listener);
    }
    
    addLiveSessionScheduleAttachment(scheduleId, attachment, listener) {
        let files = [];
        const formData = new FormData();
        formData.append('liveSessionScheduleAttachment', JSON.stringify({
            scheduleId, 
            fileName: attachment.name, 
            fileSize: attachment.size
        }))
        files.push({name: 'attachment', rawFile: attachment, attachment: true});
        this.uploadS3('/api/tutor/live-session-schedule/add-attachment', files, formData, listener)
    }

    getCourseSchedules(courseId, pageToken, listener) {
        this.get('/api/' + (SessionManager.isLoggedIn() ? 'frontend' :  'public') + '/course-schedule/get-schedule/' + courseId + '/' + pageToken, listener)
    }

    getUpcomingCourseSchedules(courseId, pageToken, listener) {
        this.get('/api/' + (SessionManager.isLoggedIn() ? 'frontend' :  'public') + '/course-schedule/get-upcoming-schedules/' + courseId + '/' + pageToken, listener)
    }

    getRecordedCourseSchedules(courseId, pageToken, listener) {
        this.get('/api/' + (SessionManager.isLoggedIn() ? 'frontend' :  'public') + '/course-schedule/get-recorded-schedules/' + courseId + '/' + pageToken, listener)
    }

    createCourseSchedule(create, thumbnail, listener) {
        this.compressImage(thumbnail, true)
        .then(image => {
            let files = [];
            const formData = new FormData();
            formData.append('courseScheduleJson', JSON.stringify(create))
            if (image !== undefined) {
                files.push({name: 'thumbnail', rawFile: image});
            }
            this.uploadS3('/api/tutor/course-schedule/create-schedule', files, formData, listener)
        })
        .catch(() => listener(this.failedApiResponse()))
    }

    updateCourseSchedule(courseScheduleId, title, desc, thumbnail, listener) {
        this.compressImage(thumbnail, true)
        .then(image => {
            let files = [];
            const formData = new FormData();
            formData.append('courseScheduleUpdateJson', JSON.stringify({
                courseScheduleId: courseScheduleId,
                title: title,
                description: desc
            }))
            if (image !== undefined) {
                files.push({name: 'thumbnail', rawFile: image});
            }
            this.uploadS3('/api/tutor/course-schedule/update-schedule', files, formData, listener)
        })
        .catch(() => listener(this.failedApiResponse()))
    }

    removeCourseSchedule(courseScheduleId, listener) {
        this.get("/api/tutor/course-schedule/remove-course-schedule/" + courseScheduleId, listener)
    }

    getUpcomingAndActiveLiveStreams(listener) {
        this.get('/api/frontend/live-info/get-upcoming-and-active-live-streams', listener)
    }

    getUserCredit(userCount, listener) {
        this.get("/api/organization/user-credit/get-credit/" + userCount, listener)
    }

    getLiveStreamCredit(amount, listener) {
        this.get('/api/tutor/live-stream-credit/get-credit/' + amount, listener);
    }

    getBills(listener) {
        this.get('/api/organization/billing/get-bills', listener);
    }

    getUserCreditUsage(listener) {
        this.get('/api/organization/user-credit/get-usage', listener);
    }

    getLiveStreamCreditUsage(listener) {
        this.get('/api/tutor/live-stream-credit/get-usage', listener);
    }
    
    setContentCuratedHidden(type, objectId, hidden, listener) {
        this.get("/api/content-manager/set-content-curated-hidden/" + type + "/" + objectId + "/" + hidden, listener);
    }

    setContentNonEnrollmentAccess(type, objectId, value, listener) {
        this.get("/api/content-manager/set-non-enrollment-access/" + type + "/" + objectId + "/" + value, listener);
    }

    setFeatureOrder(ids, listener) {
        this.post('/api/content-manager/set-feature-order/', {
            ids
        }, listener);
    }
    
    getContentManagementStats(listener) {
        this.get('/api/content-manager/get-content-management-stats/', listener);
    }

    setContentFeature(feature, type, objectId, listener) {
        this.get('/api/content-manager/set-feature/' + feature + '/' + type + '/' + objectId, listener);
    }

    getFeatures(type, listener) {
        this.get('/api/content-manager/get-features/' + type, listener);
    }

    getLiveStreamRecording(liveStreamId, listener) {
        this.get('/api/public/live-stream/get-recording/' + liveStreamId, listener);
    }

    getStreamRecordingSrc(recordingId, streamId, ext) {
        const suffix = ext == 'mp4' ? 'aencoded' : ''
        return `https://s3-me-south-1.amazonaws.com/learnerixlivestreamrecordings/${recordingId}/${streamId}/${streamId + suffix}.${ext}`;
    }

    playLiveStreamRecording(recordingId, streamId) {
        //return 'https://d3nvh77gx4r4qr.cloudfront.net/' + recordingId + '/' + streamId + '/' + streamId + '.m3u8';
        //return 'https://d3nvh77gx4r4qr.cloudfront.net/' + recordingId + '/' + streamId + '/' + streamId + '.mpd';
        return 'https://s3-me-south-1.amazonaws.com/learnerixlivestreamrecordings/' + recordingId + '/' + streamId + '/' + streamId + '.mpd';

        // if (liveStreamId == "AAAAAAAAAShBQUFBQUFBQUFJcEJRVUZCUVVGQlFVRkVNV2haZWtVMVQwUlZkMDFwTUhkTlYxRTFURlJSTTFwcVRYUlpiVlpxVDFNeGFscHRUbXhOUkdzMVQwUnNhRTFIV1hoT2FrVXpUV3BKZDA5VVozZFBWRlV6WmtWNGNHUnRWbFJhV0U1NllWYzVkVUZCUVVGRVZFVXlUV3BuTVU5VVNURlBSR04zVFVSUlBYeE1TVlpGWDFORlUxTkpUMDVmVTBOSVJVUlZURVVBQUFBLU1UWXpNVEU0TVRBeE1EVTFNbnc0TkdabE5URmhZaTB6WlRWbUxUUmlZVEV0WW1Fd01DMHlZVFF5TXpKbU9XVXlOelV4TmpNeE1UYzRNVEV3T0RjMnxMSVZFX1NUUkVBTQAAAR4wfEFBQUFBQUFBQUlwQlFVRkJRVUZCUVVGRU1XaFpla1UxVDBSVmQwMXBNSGROVjFFMVRGUlJNMXBxVFhSWmJWWnFUMU14YWxwdFRteE5SR3MxVDBSc2FFMUhXWGhPYWtVelRXcEpkMDlVWjNkUFZGVXpaa1Y0Y0dSdFZsUmFXRTU2WVZjNWRVRkJRVUZFVkVVeVRXcG5NVTlVU1RGUFJHTjNUVVJSUFh4TVNWWkZYMU5GVTFOSlQwNWZVME5JUlVSVlRFVUFBQUEtTVRZek1URTRNVEF4TURVMU1udzROR1psTlRGaFlpMHpaVFZtTFRSaVlURXRZbUV3TUMweVlUUXlNekptT1dVeU56VXhOak14TVRjNE1URXdPRGMy") {
        //     return 'https://d3nvh77gx4r4qr.cloudfront.net/' + 'ebe41a21-7056-45e6-930d-02b5f9c860901631179810552' + '/' + streamId + '.mp4';
        // } else if (liveStreamId == "AAAAAAAAAShBQUFBQUFBQUFJcEJRVUZCUVVGQlFVRkVNV2haZWtVMVQwUlZkMDFwTUhkTlYxRTFURlJSTTFwcVRYUlpiVlpxVDFNeGFscHRUbXhOUkdzMVQwUnNhRTFIV1hoT2FrVXpUV3BKZDA5VVozZFBWRlV6WmtWNGNHUnRWbFJhV0U1NllWYzVkVUZCUVVGRVZFVXlUV3BuTVU5VVNURlBSR04zVFVSUlBYeE1TVlpGWDFORlUxTkpUMDVmVTBOSVJVUlZURVVBQUFBLU1UWXlPRFkzTWpFeE5qY3pPWHd5TVRNek1HRTFOeTA1TkROaExUUmlNREV0WWpneVppMWhPRFpoTVRKbU9HWTRObVF4TmpJNE5Ua3lOell6T0RnNHxMSVZFX1NUUkVBTQAAAR4wfEFBQUFBQUFBQUlwQlFVRkJRVUZCUVVGRU1XaFpla1UxVDBSVmQwMXBNSGROVjFFMVRGUlJNMXBxVFhSWmJWWnFUMU14YWxwdFRteE5SR3MxVDBSc2FFMUhXWGhPYWtVelRXcEpkMDlVWjNkUFZGVXpaa1Y0Y0dSdFZsUmFXRTU2WVZjNWRVRkJRVUZFVkVVeVRXcG5NVTlVU1RGUFJHTjNUVVJSUFh4TVNWWkZYMU5GVTFOSlQwNWZVME5JUlVSVlRFVUFBQUEtTVRZeU9EWTNNakV4Tmpjek9Yd3lNVE16TUdFMU55MDVORE5oTFRSaU1ERXRZamd5WmkxaE9EWmhNVEptT0dZNE5tUXhOakk0TlRreU56WXpPRGc0") {
        //     return 'https://d3nvh77gx4r4qr.cloudfront.net/' + '745d2885-bac3-4148-adca-51664eb436971628670616739' + '/' + streamId + '.mp4';
        // } else if (liveStreamId == "AAAAAAAAAShBQUFBQUFBQUFJcEJRVUZCUVVGQlFVRkVNV2haZWtVMVQwUlZkMDFwTUhkTlYxRTFURlJSTTFwcVRYUlpiVlpxVDFNeGFscHRUbXhOUkdzMVQwUnNhRTFIV1hoT2FrVXpUV3BKZDA5VVozZFBWRlV6WmtWNGNHUnRWbFJhV0U1NllWYzVkVUZCUVVGRVZFVXlUV3BuTVU5VVNURlBSR04zVFVSUlBYeE1TVlpGWDFORlUxTkpUMDVmVTBOSVJVUlZURVVBQUFBLU1UWXlPVGsyT0RRek1EVTBOM3hoWTJRd1pESm1ZUzFrTmpBd0xUUmxNakl0WWpBME1pMDJNak0wTkRFeVpXRmlNR0V4TmpJNU9USTJNREUxTXpRNXxMSVZFX1NUUkVBTQAAAR4wfEFBQUFBQUFBQUlwQlFVRkJRVUZCUVVGRU1XaFpla1UxVDBSVmQwMXBNSGROVjFFMVRGUlJNMXBxVFhSWmJWWnFUMU14YWxwdFRteE5SR3MxVDBSc2FFMUhXWGhPYWtVelRXcEpkMDlVWjNkUFZGVXpaa1Y0Y0dSdFZsUmFXRTU2WVZjNWRVRkJRVUZFVkVVeVRXcG5NVTlVU1RGUFJHTjNUVVJSUFh4TVNWWkZYMU5GVTFOSlQwNWZVME5JUlVSVlRFVUFBQUEtTVRZeU9UazJPRFF6TURVME4zeGhZMlF3WkRKbVlTMWtOakF3TFRSbE1qSXRZakEwTWkwMk1qTTBOREV5WldGaU1HRXhOakk1T1RJMk1ERTFNelE1") {
        //     return 'https://d3nvh77gx4r4qr.cloudfront.net/' + '8295ee1b-aa15-44b6-b51d-dc2d3406a2631629966630547' + '/' + streamId + '.mp4';
        // } else if (liveStreamId == "AAAAAAAAAShBQUFBQUFBQUFJcEJRVUZCUVVGQlFVRkVNV2haZWtVMVQwUlZkMDFwTUhkTlYxRTFURlJSTTFwcVRYUlpiVlpxVDFNeGFscHRUbXhOUkdzMVQwUnNhRTFIV1hoT2FrVXpUV3BKZDA5VVozZFBWRlV6WmtWNGNHUnRWbFJhV0U1NllWYzVkVUZCUVVGRVZFVXlUV3BuTVU5VVNURlBSR04zVFVSUlBYeE1TVlpGWDFORlUxTkpUMDVmVTBOSVJVUlZURVVBQUFBLU1UWXpNamd4T1RNeU1EQTVNSHc0TURrNU9ESTBNQzFqTXpjNUxUUXpZbVV0T0dKbE9DMWtaamd6WmpJelpHSTBOMlV4TmpNeU9EQTVOelF4TURNenxMSVZFX1NUUkVBTQAAAR4wfEFBQUFBQUFBQUlwQlFVRkJRVUZCUVVGRU1XaFpla1UxVDBSVmQwMXBNSGROVjFFMVRGUlJNMXBxVFhSWmJWWnFUMU14YWxwdFRteE5SR3MxVDBSc2FFMUhXWGhPYWtVelRXcEpkMDlVWjNkUFZGVXpaa1Y0Y0dSdFZsUmFXRTU2WVZjNWRVRkJRVUZFVkVVeVRXcG5NVTlVU1RGUFJHTjNUVVJSUFh4TVNWWkZYMU5GVTFOSlQwNWZVME5JUlVSVlRFVUFBQUEtTVRZek1qZ3hPVE15TURBNU1IdzRNRGs1T0RJME1DMWpNemM1TFRRelltVXRPR0psT0Mxa1pqZ3paakl6WkdJME4yVXhOak15T0RBNU56UXhNRE16") {
        //     return 'https://d3nvh77gx4r4qr.cloudfront.net/' + '491e7f8a-0c40-47aa-a2a9-0628d8f1e1d21632817820090' + '/' + streamId + '.mp4';
        // } else if (liveStreamId == "AAAAAAAAAShBQUFBQUFBQUFJcEJRVUZCUVVGQlFVRkVNV2haZWtVMVQwUlZkMDFwTUhkTlYxRTFURlJSTTFwcVRYUlpiVlpxVDFNeGFscHRUbXhOUkdzMVQwUnNhRTFIV1hoT2FrVXpUV3BKZDA5VVozZFBWRlV6WmtWNGNHUnRWbFJhV0U1NllWYzVkVUZCUVVGRVZFVXlUWHBqZWs1RVFUTk5SRmw1VGxSWlBYeE1TVlpGWDFORlUxTkpUMDVmVTBOSVJVUlZURVVBQUFBLU1UWXpOelEyT1RZek1UWTFOWHhqTkRNeU1UVXdNQzFrWTJNMkxUUmlPVEl0WWpGak55MDFNbUZpTUdSak9UQmpNV1l4TmpNM016UXdOemMyTkRZeXxMSVZFX1NUUkVBTQAAAR4wfEFBQUFBQUFBQUlwQlFVRkJRVUZCUVVGRU1XaFpla1UxVDBSVmQwMXBNSGROVjFFMVRGUlJNMXBxVFhSWmJWWnFUMU14YWxwdFRteE5SR3MxVDBSc2FFMUhXWGhPYWtVelRXcEpkMDlVWjNkUFZGVXpaa1Y0Y0dSdFZsUmFXRTU2WVZjNWRVRkJRVUZFVkVVeVRYcGplazVFUVROTlJGbDVUbFJaUFh4TVNWWkZYMU5GVTFOSlQwNWZVME5JUlVSVlRFVUFBQUEtTVRZek56UTJPVFl6TVRZMU5YeGpORE15TVRVd01DMWtZMk0yTFRSaU9USXRZakZqTnkwMU1tRmlNR1JqT1RCak1XWXhOak0zTXpRd056YzJORFl5") {
        //     return 'https://d3nvh77gx4r4qr.cloudfront.net/' + '1d66cca1-75b4-4645-a5c3-9d6652f463fd1637467231655' + '/' + streamId + '.mp4';
        // } else if (liveStreamId == "AAAAAAAAAShBQUFBQUFBQUFJcEJRVUZCUVVGQlFVRkVNV3hPTWxwclRWUkZNVTlETVd4T2FrWnRURlJSTVUxcVkzUlphazE1VG1rd2VFNHlSWGxaTWs1c1dsZGFhVTlFVVhoT2FrMHpUMFJWZWs5VVdUVlBWRlY2WmtWNGNHUnRWbFJhV0U1NllWYzVkVUZCUVVGRVZFVXlUWHBuZDAxRVl6Tk9hbFY2VGxSWlBYeE1TVlpGWDFORlUxTkpUMDVmVTBOSVJVUlZURVVBQUFBLU1UWXpPREF6TURZd05qRTNOM3d6WldVd1pUTmtOaTB3T1RGaExUUm1NVGd0WVRnek9DMDNZVFF3WXpreE5ETXlZalF4TmpNNE1EQTROREUzTmpRMXxMSVZFX1NUUkVBTQAAAR4wfEFBQUFBQUFBQUlwQlFVRkJRVUZCUVVGRU1XeE9NbHByVFZSRk1VOURNV3hPYWtadFRGUlJNVTFxWTNSWmFrMTVUbWt3ZUU0eVJYbFpNazVzV2xkYWFVOUVVWGhPYWswelQwUlZlazlVV1RWUFZGVjZaa1Y0Y0dSdFZsUmFXRTU2WVZjNWRVRkJRVUZFVkVVeVRYcG5kMDFFWXpOT2FsVjZUbFJaUFh4TVNWWkZYMU5GVTFOSlQwNWZVME5JUlVSVlRFVUFBQUEtTVRZek9EQXpNRFl3TmpFM04zd3paV1V3WlROa05pMHdPVEZoTFRSbU1UZ3RZVGd6T0MwM1lUUXdZemt4TkRNeVlqUXhOak00TURBNE5ERTNOalEx") {
        //     return 'https://d3nvh77gx4r4qr.cloudfront.net/' + '99ea2d54-cc8e-4a9c-9cf0-7c1483e28afa1638025206177' + '/' + streamId + '.mp4';
        // } else {
        //     return this.getLiveStreamServerIp() + '/api/public/live-stream/play-recording/' + liveStreamId + '/' + streamId + this.getParamToken();;
        // }
    }

    setLiveStreamWhiteboardStageAccess(liveStreamId, whiteboardStageAccess, listener) {
        this.get('/api/frontend/live-stream/set-whiteboard-stage-access/' + liveStreamId + '/' + whiteboardStageAccess, listener);
    }

    setLiveStreamHandRaise(liveStreamId, raise, listener) {
        this.get('/api/frontend/live-stream/set-hand-raise/' + liveStreamId + '/' + raise, listener);
    }

    setLiveStreamStageUser2(liveStreamId, userId, add, listener) {
        this.get('/api/frontend/live-stream/set-stage-user/' + liveStreamId + '/' + userId + '/' + add, listener);
    }

    setLiveStreamStagePermission2(liveStreamId, streamType, permission, listener) {
        this.get('/api/frontend/live-stream/set-stage-permission/' + liveStreamId + '/' + streamType + '/' + permission, listener);
    }

    setLiveStreamAllowMessaging(liveStreamId, allowMessaging, listener) {
        this.get('/api/frontend/live-stream/set-allow-messaging/' + liveStreamId + '/' + allowMessaging, listener);
    }

    getLiveStreamUsers(liveStreamId, listener) {
        this.get('/api/frontend/live-stream/get-users/' + liveStreamId, listener);
    }

    pingLiveStream(liveStreamId, listener) {
        this.get('/api/' + (!SessionManager.isLoggedIn() ? 'public' :  'frontend') + '/live-stream/ping/' + liveStreamId, listener);
    }

    shouldAskForLiveStreamBroadcasting(liveStreamId, listener) {
        this.get('/api/' + (!SessionManager.isLoggedIn() ? 'public' :  'frontend') + '/live-stream/should-ask-for-broadcasting/' + liveStreamId, listener);
    }

    joinLiveStream(liveStreamId, joinAsBroadcaster, listener) {
        this.post('/api/' + (!SessionManager.isLoggedIn() ? 'public' :  'frontend') + '/live-stream/join/' + liveStreamId, { joinAsBroadcaster }, listener);
    }

    initializeLiveStream(liveStreamId, rtpCapabilities, listener) {
        this.post('/api/' + (!SessionManager.isLoggedIn() ? 'public' :  'frontend') + '/live-stream/initialize/' + liveStreamId, rtpCapabilities, listener);
    }

    connectLiveStream(liveStreamId, recv, dtlsParameters, listener) {
        this.post('/api/' + (!SessionManager.isLoggedIn() ? 'public' :  'frontend') + '/live-stream/connect/' + liveStreamId + '/' + recv, dtlsParameters, listener);
    }

    startStream(liveStreamId, stream, producerParams, listener) {
        this.post('/api/frontend/live-stream/start-stream/' + liveStreamId + '/' + stream, producerParams, listener);
    }

    stopStream(liveStreamId, stream, listener) {
        this.get('/api/frontend/live-stream/stop-stream/' + liveStreamId + '/' + stream, listener);
    }

    webRtcSessionConnect(sessionId, payload, listener) {
        this.post('/api/frontend/web-rtc/connect', { sessionId, payload }, listener);
    }

    webRtcSessionProduce(sessionId, payload, listener) {
        this.post('/api/frontend/web-rtc/produce', { sessionId, payload }, listener);
    }

    createWebRtcSession(liveStreamId, mediaStreamId, listener) {
        this.get('/api/frontend/live-stream/create-web-rtc-session/' + liveStreamId + "/" + mediaStreamId, listener);
    }

    createMediaStream(liveStreamId, mediaStreamId, webRtcSessionId, hasAudio, hasVideo, listener) {
        this.post('/api/frontend/live-stream/create-media-stream/' + liveStreamId + "/" + mediaStreamId + "/" + hasAudio + "/" + hasVideo, {
            content: webRtcSessionId
        }, listener);
    }

    sendInquiryForm(inquiryForm, listener) {
        this.post("/api/public/contact/send-inquiry-form", inquiryForm, listener);
    }

    deleteComment(commentId, listener) {
        this.get("/api/frontend/comment/delete-comment/" + commentId, listener);
    }

    deleteBlogPost(date, listener) {
        this.get('/api/blogger/blog/delete-blog-post/' + date, listener);
    }

    toggleBlogPostPublish(date, listener) {
        this.get('/api/blogger/blog/toggle-blog-post-publish/' + date, listener);
    }

    newBlog(listener) {
        this.get('/api/blogger/blog/new-blog', listener);
    }

    saveBlog(title, date, tag, preview, content, thumbnail, listener) {
        if (thumbnail !== undefined) {
            this.compressImage(thumbnail, false)
            .then(image => {
                this.uploadS3AndPost('/api/blogger/blog/save-blog', [{name: 'thumbnailUpload', rawFile: image}], {
                    title, date, tag, preview, content
                }, listener);
            })
            .catch(() => listener(this.failedApiResponse()))
        } else {
            this.post('/api/blogger/blog/save-blog', {
                title, date, tag, preview, content
            }, listener)
        }
    }

    getMyBlogPosts(page, listener) {
        this.get('/api/blogger/blog/get-my-blog-posts/' + page, listener);
    }

    getHomeBlogPosts(page, listener) {
        this.get('/api/public/blog/get-home-blog-posts/' + page, listener);
    }

    getHomeBlogPostsByTag(tag, page, listener) {
        this.get('/api/public/blog/get-home-blog-posts-by-tag/' + tag + '/' + page, listener);
    }

    getLatestHomeBlogPosts(listener) {
        this.get('/api/public/blog/get-latest-home-blog-posts', listener);
    }

    getBlogPost(title, date, listener) {
        this.get('/api/public/blog/get-blog-post/' + title + "/" + date, listener);
    }

    getFeaturedHashtags(listener) {
        this.get('/api/public/hashtag/get-featured', listener);
    }

    register(account, listener) {
        this.post('/api/auth/register', account, listener);
    }

    confirmEmailToken(id, code, loginPassword, listener) {
        this.post('/api/auth/confirm-email-token/' + id + '/' + code, {
            content: loginPassword !== undefined ? loginPassword : ""
        }, listener);
    }

    resendEmailConfirmCode(id, listener) {
        this.get('/api/auth/resend-confirm-email-code/' + id, listener);
    }

    sendPasswordResetRequest(email, listener) {
        this.get('/api/auth/send-password-reset-request/' + email, listener);
    }

    validateResetPassword(id, listener) {
        this.get('/api/auth/validate-reset-password/' + id, listener);
    }

    resetPassword(requestId, password, listener) {
        this.post('/api/auth/reset-password/' + requestId, {
            content: password !== undefined ? password : ""
        }, listener);
    }

    getPhoneNumber(listener) {
        this.get('/api/frontend/account/get-phone-number', listener);
    }

    updateAccountInfo(fullName, phoneNumber, listener) {
        this.post('/api/frontend/account/update-account-info', {
            newFullName: fullName,
            newPhoneNumber: phoneNumber
        }, listener);
    }

    updateAccountPassword(currentPassword, newPassword, listener) {
        this.post('/api/frontend/account/update-account-password', {
            currentPassword: currentPassword,
            newPassword: newPassword
        }, listener);
    }

    getAllSubscribers(listener) {
        this.get('/api/payment-manager/user/get-all-subscribers', listener);
    }

    approveWithdrawRequest(withdrawId, listener) {
        this.get('/api/payment-manager/withdraw/accept-withdraw-request/' + withdrawId, listener);
    }

    rejectWithdrawRequest(withdrawId, reason, listener) {
        this.post('/api/payment-manager/withdraw/reject-withdraw-request/' + withdrawId, {
            content: reason
        }, listener);
    }

    cancelWithdrawRequest(listener) {
        this.get('/api/tutor/withdraw/cancel-withdraw-request', listener);
    }

    createWithdrawRequest(amount, listener) {
        this.post('/api/tutor/withdraw/create-withdraw-request', {
            amount: amount
        }, listener);
    }

    linkWithdrawDestination(emailAddress, listener) {
        this.post('/api/tutor/withdraw/link-withdraw-destination', {
            content: emailAddress
        }, listener);
    }

    unlinkWithdrawDestination(listener) {
        this.get('/api/tutor/withdraw/unlink-withdraw-destination', listener);
    }

    getBalanceLessOnHold(listener) {
        this.get('/api/tutor/learnerix-balance-payment/get-balance-less-on-hold', listener);
    }

    purchaseWithLearnerixBalance(productType, productId, listener) {
        this.get('/api/tutor/learnerix-balance-payment/purchase/' + productType + '/' + productId, listener);
    }

    getBalanceInfo(listener) {
        this.get('/api/tutor/balance/get-balance-info', listener);
    }
    
    getStatements(request, listener) {
        this.post('/api/tutor/statements/get-statements', request, listener);
    }

    getAllStatements(request, listener) {
        this.post('/api/payment-manager/statements/get-all-statements', request, listener);
    }

    deleteMessage(messageId, listener) {
        this.get('/api/frontend/messages/delete-message/' + messageId, listener);
    }

    deleteLiveStreamMessage(liveStreamId, messageId, listener) {
        this.get('/api/frontend/messages/delete-live-stream-message/' + liveStreamId + '/' + messageId, listener);
    }

    editPostContent(postId, postContent, listener) {
        this.post('/api/frontend/posts/edit-post-content/' + postId, {
            content: postContent
        }, listener);
    }

    deletePost(postId, listener) {
        this.get('/api/frontend/posts/delete-post/' + postId, listener);
    }

    getMyAccountInfo(listener) {
        this.get('/api/frontend/account/get-my-info', listener);
    }

    startAccountLiveStream(listener) {
        this.get('/api/tutor/account/start-live-stream', listener);
    }

    setPostHidden(postId, hidden, listener) {
        this.get('/api/frontend/posts/set-post-hidden/' + postId + '/' + hidden, listener);
    }

    getCourseStudentAnalyticsUsers(courseId, courseItemId, listener) {
        this.get('/api/tutor/user/get-course-student-analytics/' + courseId + "/" + courseItemId, listener);
    }

    getCourseProgressUpdate(courseId, courseSectionId, listener) {
        this.get('/api/student/course-analytics/get-course-progress-update/' + courseId + "/" + courseSectionId, listener);
    }

    getHelpSessionLiveHistory(courseId, listener) {
        this.get('/api/tutor/help-session/get-help-session-live-history/' + courseId, listener);
    }

    getLiveStreamAttendanceReport(liveStreamId, listener) {
        this.get('/api/tutor/live-stream/get-attendance-report/' + liveStreamId, listener);
    }

    getQuizSubmissionHistory(courseItemId, listener) {
        this.get('/api/student/course-analytics/get-quiz-submission-history/' + courseItemId, listener);
    }

    getStudentQuizSubmissionHistory(studentId, courseItemId, listener) {
        this.get('/api/tutor/course-analytics/get-student-quiz-submission-history/' + studentId + "/" + courseItemId, listener);
    }

    getQuizSubmissionReport(courseItemId, listener) {
        this.get('/api/tutor/course-analytics/get-quiz-submission-report/' + courseItemId, listener);
    }

    saveCourseLecturePoint(courseItemId, point, atEnd, listener) {
        this.get('/api/student/course-analytics/save-course-lecture-point/' + courseItemId + "/" + point + "/" + atEnd, listener);
    }

    recordCourseItemDownload(courseItemId, listener) {
        this.get('/api/student/course-analytics/record-course-item-download/' + courseItemId, listener);
    }

    startCourseLectureTracker(courseItemId, listener) {
        this.get('/api/student/course-analytics/start-course-lecture-tracker/' + courseItemId, listener);
    }

    stopCourseLectureTracker(courseItemId, trackerId, listener) {
        this.get('/api/student/course-analytics/stop-course-lecture-tracker/' + courseItemId + "/" + trackerId, listener);
    }

    saveLiveStreamRecordingPoint(liveStreamId, point, atEnd, listener) {
        this.get('/api/student/live-stream-analytics/save-live-stream-recording-point/' + liveStreamId + "/" + point + "/" + atEnd, listener);
    }

    startLiveStreamRecordingTracker(liveStreamId, listener) {
        this.get('/api/student/live-stream-analytics/start-live-stream-recording-tracker/' + liveStreamId, listener);
    }

    stopLiveStreamRecordingTracker(liveStreamId, trackerId, listener) {
        this.get('/api/student/live-stream-analytics/stop-live-stream-recording-tracker/' + liveStreamId + "/" + trackerId, listener);
    }

    getAccountAccess(listener) {
        this.get('/api/organization/account-access/get-access', listener);
    }

    activateAccountAccess(serialNo, listener) {
        this.post('/api/organization/account-access/activate-access', {
            content: serialNo
        }, listener);
    }

    updateAccountAccessPackage(accountId, updatePackage, listener) {
        this.get('/api/relation-manager/account-access/update-access-package/' + accountId + "/" + updatePackage, listener);
    }

    getLiveSubscription(listener) {
        this.get('/api/student/live-subscription/get-subscription', listener);
    }

    /*subscribeToLive(listener) {
        this.get('/api/student/live-subscription/subscribe', listener);
    }

    unsubscribeFromLive(listener) {
        this.get('/api/student/live-subscription/unsubscribe', listener);
    }*/

    cancelLiveSubscription(listener) {
        this.get('/api/student/live-subscription/cancel-subscription', listener);
    }

    inviteUsersForEnrollment(userEmails, listener) {
        this.post('/api/organization/enrollment-invitation/invite-users', userEmails, listener);
    }

    sendInvitationForEnrollment(id, listener) {
        this.get('/api/frontend/send-invitation/' + id, listener);
    }

    cancelEnrollmentInvitation(invitationId, listener) {
        this.get('/api/frontend/cancel-invitation/' + invitationId, listener);
    }

    acceptEnrollmentInvitation(invitationId, listener) {
        this.get('/api/frontend/accept-invitation/' + invitationId, listener);
    }

    getMyEnrollmentInvitations(listener) {
        this.get('/api/frontend/user/get-my-enrollment-invitations', listener)
    }

    getMySentEnrollmentInvitations(listener) {
        this.get('/api/organization/user/get-my-sent-enrollment-invitations', listener)
    }

    getEnrolledUsers(listener) {
        this.get('/api/organization/user/get-enrolled-users/', listener);
    }

    getOrganizationTutors(organizationId, listener) {
        this.get('/api/public/user/get-organization-tutors/' + organizationId, listener);
    }

    getTutorOrganizations(tutorId, listener) {
        this.get('/api/public/user/get-tutor-organizations/' + tutorId, listener);
    }

    getMyOrganizations(listener) {
        this.get('/api/frontend/user/get-my-organizations/', listener);
    }

    getOrganization(organizationId, listener) {
        this.get('/api/frontend/user/get-organization/' + organizationId, listener);
    }

    //START

    createUsersAccess(productId, productType, userIds, listener) {
        this.post('/api/organization/enrollment/create-users-access/' + productId + '/' + productType, userIds, listener);
    }

    createTutorsEditAccess(productId, productType, userIds, listener) {
        this.post('/api/organization/enrollment/create-tutors-edit-access/' + productId + '/' + productType, userIds, listener);
    }

    removeUserAccess(productId, productType, userId, listener) {
        this.get('/api/organization/enrollment/remove-user-access/' + productId + '/' + productType + '/' + userId, listener);
    }

    removeTutorEditAccess(productId, productType, userId, listener) {
        this.get('/api/organization/enrollment/remove-tutor-edit-access/' + productId + '/' + productType + '/' + userId, listener);
    }

    getEnrolledUsersWithoutAccess(productId, productType, role, listener) {
        this.get('/api/organization/user/get-enrolled-users-without-access/' + productId + '/' + productType + '/' + role, listener);
    }

    getCourseAccessEnrolledUsers(productId, productType, role, listener) {
        this.get('/api/organization/user/get-course-access-users/' + productId + '/' + productType + '/' + role, listener);
    }

    getCourseTutorEditors(productId, productType, listener) {
        this.get('/api/public/user/get-course-tutor-editors/' + productId + '/' + productType, listener);
    }

    getCoursePrivateAccessUsers(productId, productType, listener) {
        this.get('/api/tutor/user/get-course-private-access-users/' + productId + '/' + productType, listener);
    }

    addCoursePrivateAccess(courseId, emailAddress, listener) {
        this.post('/api/tutor/course/add-course-private-access/' + courseId, {
            content: emailAddress
        }, listener);
    }
    
    removeCoursePrivateAccess(courseId, userId, listener) {
        this.get('/api/tutor/course/remove-course-private-access/' + courseId + "/" + userId, listener);
    }

    addLiveSessionPrivateAccess(liveSessionId, emailAddress, listener) {
        this.post('/api/tutor/live-session/add-live-session-private-access/' + liveSessionId, {
            content: emailAddress
        }, listener);
    }
    
    removeLiveSessionPrivateAccess(liveSessionId, userId, listener) {
        this.get('/api/tutor/live-session/remove-live-session-private-access/' + liveSessionId + "/" + userId, listener);
    }

    //END

    revokeEnrollment(id, listener) {
        this.get('/api/frontend/enrollment/revoke-enrollment/' + id, listener);
    }

    getLikedByUsers(parentId, parentType, listener) {
        this.get('/api/public/user/get-liked-by-users/' + parentId + "/" + parentType, listener);
    }

    createUsersAndEnroll(users, listener) {
        this.post('/api/organization/enrollment/create-users-and-enroll', {
            users: users
        }, listener)
    }

    getLiveStreamViewers(liveStreamId, listener) {
        this.get('/api/frontend/user/get-live-stream-viewers/' + liveStreamId, listener);
    }

    getFollowUsers(tutorId, showFollowers, listener) {
        this.get('/api/public/user/get-follow-users/' + tutorId + '/' + showFollowers, listener);
    }

    getProductAccessUsers(productId, productType, listener) {
        this.get('/api/tutor/user/get-product-access-users/' + productId + "/" + productType, listener);
    }
    
    getQuizQuestions(courseItemId, page, listener) {
        this.get('/api/' + (SessionManager.isLoggedInAsTutor() ? 'tutor' :  'student') + '/quiz/get-quiz-questions/' + courseItemId + "/" + page, listener);
    }

    saveQuestion(question, image, listener) {
        this.compressImage(image, false)
        .then(resultImage => {
            let files = [];
            const formData = new FormData();

            formData.append('quizQuestionJson', JSON.stringify(question));
            if (resultImage !== undefined) {
                files.push({name: 'image', rawFile: resultImage});
            }
            this.uploadS3('/api/tutor/quiz/save-question', files, formData, listener)
        })
        .catch(() => listener(this.failedApiResponse()))
    }

    getQuizQuestionImage(questionId) {
        //return this.getServerIp() + '/api/' + (SessionManager.isLoggedInAsTutor() ? 'tutor' :  'student') + '/quiz/get-question-image/' + questionId + this.getParamToken();
        //return this.getCDN() + "/quizquestionimages/" + questionId + "?version=" + -(new Date().getTime());
        return this.getS3() + "/quizquestionimages/" + questionId + "?i=1";
    }

    deleteQuestion(questionId, listener) {
        this.get('/api/tutor/quiz/delete-question/' + questionId, listener);
    }

    answerQuestion(answer, listener) {
        this.post('/api/student/quiz/answer-question', answer, listener);
    }

    searchLearners(search, listener) {
        this.post('/api/relation-manager/user/search-learners/', {
            content: search
        }, listener);
    }

    searchTutors(search, listener) {
        this.post('/api/public/user/search-tutors/', {
            content: search
        }, listener);
    }

    searchOrganizations(search, listener) {
        this.post('/api/public/user/search-organizations/', {
            content: search
        }, listener);
    }

    getFeaturedTutors(listener) {
        this.get('/api/public/user/get-featured-tutors/', listener);
    }

    getFeaturedOrganizations(listener) {
        this.get('/api/public/user/get-featured-organizations/', listener);
    }

    getLearners(listener) {
        this.get('/api/relation-manager/user/get-learners/', listener);
    }
    
    getAllUsers(listener) {
        this.get('/api/relation-manager/user/get-all-users/', listener);
    }

    getTutors(listener) {
        this.get('/api/public/user/get-tutors/', listener);
    }

    getAllOrganizations(listener) {
        this.get('/api/public/user/get-organizations/', listener);
    }

    pingPresence() {
        this.get('/api/tutor/presence/ping-presence', () => {})
    }

    getPresence(userId, listener) {
        this.get('/api/public/presence/get-presence/' + userId, listener);
    }

    setPresence(presence, listener) {
        this.get('/api/tutor/presence/set-presence/' + presence, listener);
    }
    
    broadcastPresence(listener) {
        this.get('/api/tutor/presence/broadcast-presence', listener);
    }

    startHelpSessionLive(courseId, userIds, duration, record, title, description, listener) {
        this.post('/api/tutor/help-session/start-help-session-live', {
            courseId: courseId,
            userIds: userIds,
            duration: duration,
            record: record,
            title: title,
            description: description
        }, listener)
    }

    getHelpSessionUsers(courseId, listener) {
        this.get('/api/tutor/user/get-help-session-users/' + courseId, listener);
    }
    
    newHelpSessionRequest(courseId, listener) {
        this.get('/api/student/help-session/new-request/' + courseId, listener);
    }

    getCurrentHelpSessionRequest(courseId, listener) {
        this.get('/api/student/help-session/get-current-request/' + courseId, listener);
    }

    getCourseHelpSessionInfo(courseId, listener) {
        this.get('/api/frontend/help-session/get-course-help-session-info/' + courseId, listener);
    }

    setHelpSessionStatus(helpSessionId, status, listener) {
        this.get('/api/tutor/help-session/set-help-session-status/' + helpSessionId + "/" + status, listener);
    }

    getHelpSessionRooms(courseId, helpSessionStatus, listener) {
        this.get('/api/frontend/messages/get-help-session-rooms/' + courseId + "/" + helpSessionStatus, listener);
    }
    
    getLiveStreamStageList(liveStreamId, listener) {
        this.get('/api/frontend/live-stream/get-stage-list/' + liveStreamId, listener);
    }
    
    setLiveStreamRaiseHand(liveStreamId, raiseHand, listener) {
        this.get('/api/frontend/live-stream/set-raise-hand/' + liveStreamId + "/" + raiseHand, listener);
    }

    setLiveStreamStageUser(liveStreamId, stageUserId, listener) {
        this.get('/api/tutor/live-stream/set-stage-user/' + liveStreamId + '/' + stageUserId, listener);
    }
    
    setLiveStreamStagePermission(liveStreamId, hasMic, hasCamera, hasWhiteboard, listener) {
        this.get('/api/tutor/live-stream/set-stage-permission/' + liveStreamId + "/" + hasMic + "/" + hasCamera + "/" + hasWhiteboard, listener);
    }

    createWebRTCEndpoint(liveStreamId, sessionType, listener) {
        this.get('/api/frontend/live-stream/create-endpoint/' + liveStreamId + "/" + sessionType, listener)
    }

    createSession(liveStreamId, sessionType, endpointId, hasAudio, hasVideo, listener) {
        this.post('/api/frontend/live-stream/create-session/' + liveStreamId + "/" + sessionType + "/" + hasAudio + "/" + hasVideo, {
        //this.post('/api/frontend/live-stream/create-session/' + liveStreamId + "/" + sessionType, {
            content: endpointId
        }, listener);
    }

    closeSession(liveStreamId, sessionType, listener) {
        this.get('/api/frontend/live-stream/close-session/' + liveStreamId + "/" + sessionType, listener)
    }

    createOutputEndpoint(liveStreamId, listener) {
        this.get('/api/tutor/live-stream/create-output-endpoint/' + liveStreamId, listener)
    }

    createInputEndpoint(liveStreamId, listener) {
        this.get('/api/student/live-stream/create-input-endpoint/' + liveStreamId, listener)
    }

    setLiveStreamChatEnable(liveStreamId, enable, listener) {
        this.get('/api/tutor/live-stream/set-chat-enable/' + liveStreamId + "/" + enable, listener)   
    }

    setLiveStreamCamera(liveStreamId, enable, outputEndpointId, listener) {
        this.post('/api/tutor/live-stream/set-camera/' + liveStreamId + "/" + enable, {
            content: outputEndpointId
        }, listener);
    }

    getLiveStream(liveStreamId, listener) {
        this.get('/api/' + (SessionManager.isLoggedInAsTutor() ? 'tutor' :  'student') + '/live-stream/get-live-stream/' + liveStreamId, listener);
    }

    /*getLiveStreamRecording(liveStreamId, listener) {
        this.get('/api/frontend/live-stream/get-live-stream-recording/' + liveStreamId, listener);
    }

    getLiveStreamRecordingVideo(liveStreamId, type, date) {
        return this.getServerIp() + '/api/frontend/live-stream/get-live-stream-video/' + liveStreamId + '/' + type + '/' + date + this.getParamToken();
    }*/

    pingLiveStreamViewer(liveStreamId, listener) {
        this.get('/api/student/live-stream/ping-live-stream-viewer/' + liveStreamId, listener);
    }

    signalWebRTC(signalMessage, listener) {
        this.post('/api/frontend/web-rtc/signal', signalMessage, listener);
    }

    /*purchaseCourse(courseId, subscribe, listener) {
        if (subscribe.toString !== undefined) {
            this.get('/api/student/course/purchase-course/' + courseId + '/' + subscribe.toString(), listener);
        } else {
            listener(this.failedApiResponse())
        }
    }*/

    getCourseAccess(courseId, listener) {
        this.get('/api/student/course/get-course-access/' + courseId, listener);
    }

    subscribeCourse(courseId, listener) {
        this.get('/api/student/course/subscribe-course/' + courseId, listener);
    }

    createCourse(title, category, privateAccess, listener) {
        this.post('/api/tutor/course/create-course', {
            title: title,
            category: category,
            privateAccess: privateAccess
        }, listener);
    }

    toggleCoursePublish(courseId, listener) {
        this.get('/api/tutor/course/toggle-course-publish/' + courseId + '?d=' + new Date().getTime(), listener);
    }

    getCourseItems(courseSectionId, page, listener) {
        this.get('/api/' + (SessionManager.isLoggedIn() ? (SessionManager.isLoggedInAsTutor() ? 'tutor' :  'student') : 'public') + '/course/get-course-items/' + courseSectionId + "/" + page, listener);
    }

    createCourseItem(courseItem, thumbnail, attachment, listener) {
        this.compressImage(thumbnail, true)
        .then(image => {
            let files = [];
            const formData = new FormData();
            formData.append('courseItemJson', JSON.stringify(courseItem))
            if (image !== undefined) {
                //formData.append('thumbnail', thumbnail);
                files.push({name: 'thumbnail', rawFile: image});
            }
            if (attachment !== undefined) {
                //formData.append('attachment', attachment);
                files.push({name: 'attachment', rawFile: attachment, attachment: true});
            }
            this.uploadS3('/api/tutor/course/create-course-item', files, formData, listener)
        })
        .catch(() => listener(this.failedApiResponse()))
    }

    updateCourseItem(courseItemId, title, desc, thumbnail, listener) {
        this.compressImage(thumbnail, true)
        .then(image => {
            let files = [];
            const formData = new FormData();
            formData.append('courseItemUpdateJson', JSON.stringify({
                courseItemId: courseItemId,
                title: title,
                description: desc
            }))
            if (image !== undefined) {
                files.push({name: 'thumbnail', rawFile: image});
            }
            this.uploadS3('/api/tutor/course/update-course-item', files, formData, listener)
        })
        .catch(() => listener(this.failedApiResponse()))
    }

    toggleCourseItemHidden(courseItemId, listener) {
        this.get('/api/tutor/course/toggle-course-item-hidden/' + courseItemId + '?d=' + new Date().getTime(), listener);
    }

    removeCourseItem(courseItemId, listener) {
        this.get('/api/tutor/course/remove-course-item/' + courseItemId, listener);
    }

    createCourseSection(courseId, title, listener) {
        this.post('/api/tutor/course/create-course-section', {
            courseId: courseId,
            title: title
        }, listener);
    }

    renameCourseSection(courseSectionId, title, listener) {
        this.post('/api/tutor/course/rename-course-section', {
            courseSectionId: courseSectionId,
            title: title
        }, listener);
    }

    toggleCourseSectionHidden(courseSectionId, listener) {
        this.get('/api/tutor/course/toggle-course-section-hidden/' + courseSectionId + '?d=' + new Date().getTime(), listener);
    }

    removeCourseSection(courseSectionId, listener) {
        this.get('/api/tutor/course/remove-course-section/' + courseSectionId, listener);
    }

    getMyTutorCourses(page, listener) {
        this.get('/api/tutor/course/get-my-courses/' + page, listener);
    }

    getMyTutorOrganizationCourses(organizationId, page, listener) {
        this.get('/api/tutor/course/get-my-organization-courses/' + organizationId + "/" + page, listener);
    }

    getMyStudentCourses(page, listener) {
        this.get('/api/student/course/get-my-courses/' + page, listener);
    }

    getMyStudentOrganizationCourses(organizationId, page, listener) {
        this.get('/api/student/course/get-my-organization-courses/' + organizationId + "/" + page, listener);
    }

    getTutorCourses(tutorId, page, listener) {
        this.get('/api/public/course/get-tutor-courses/' + tutorId + "/" + page, listener);
    }

    getOtherTutorCourses(courseId, listener) {
        this.get('/api/public/course/get-other-tutor-courses/' + courseId, listener);
    }

    getCategoryCourses(category, page, listener) {
        this.get('/api/public/course/get-category-courses/' + category + "/" + page, listener);
    }

    getHashtagCourses(hashtag, page, listener) {
        this.get('/api/public/course/get-hashtag-courses/' + hashtag + "/" + page, listener);
    }

    getHashtagLiveSessions(hashtag, page, listener) {
        this.get('/api/public/live-session/get-hashtag-live-sessions/' + hashtag + "/" + page, listener);
    }

    getTopCategoryCourses(category, listener) {
        this.get('/api/public/course/get-top-category-courses/' + category, listener);
    }

    getTopCourses(listener) {
        this.get('/api/public/course/get-top-courses', listener);
    }

    searchCourses(searchValue, listener) {
        this.post('/api/public/course/search-courses/',{
            content: searchValue
        }, listener);
    }

    getTutorCourse(courseId, listener) {
        this.get('/api/tutor/course/get-course/' + courseId, listener);
    }

    getStudentCourse(courseId, listener) {
        if (!SessionManager.isLoggedIn()) {
            this.get('/api/' + (!SessionManager.isLoggedIn() ? 'public' :  'student') + '/course/get-public-course/' + courseId, listener);
        } else {
            this.get('/api/' + (!SessionManager.isLoggedIn() ? 'public' :  'student') + '/course/get-course/' + courseId, listener);
        }
    }

    getPublicCourse(courseId, listener) {
        this.get('/api/public/course/get-course/' + courseId, listener);
    }

    /*purchaseLiveSession(liveSessionId, listener) {
        this.get('/api/student/live-session/purchase-live-session/' + liveSessionId, listener);
    }*/

    getLiveSessionAccess(liveSessionId, listener) {
        this.get('/api/student/live-session/get-live-session-access/' + liveSessionId, listener);
    }

    createLiveSession(title, category, privateAccess, listener) {
        this.post('/api/tutor/live-session/create-live-session', {
            title: title,
            category: category,
            privateAccess: privateAccess
        }, listener);
    }

    createCourseLiveSession(title, category, courseId, listener) {
        this.post('/api/tutor/live-session/create-live-session', {
            title: title,
            category: category,
            courseId: courseId
        }, listener);
    }

    getMyTutorOrganizationLiveSessions(organizationId, page, listener) {
        this.get('/api/tutor/live-session/get-my-organization-live-sessions/' + organizationId + "/" + page, listener);
    }
    
    getMyStudentOrganizationLiveSessions(organizationId, page, listener) {
        this.get('/api/student/live-session/get-my-organization-live-sessions/' + organizationId + "/" + page, listener);
    }

    toggleLiveSessionPublish(liveSessionId, listener) {
        this.get('/api/tutor/live-session/toggle-live-session-publish/' + liveSessionId + '?d=' + new Date().getTime(), listener);
    }

    getLiveSessionSchedules(liveSessionId, page, listener) {
        this.get('/api/' + (SessionManager.isLoggedInAsTutor() ? 'tutor' :  'student') + '/live-session/get-live-session-schedules/' + liveSessionId + "/" + page, listener);
    }

    createLiveSessionSchedule(liveSessionSchedule, thumbnail, listener) {
        this.compressImage(thumbnail, true)
        .then(image => {
            let files = [];
            const formData = new FormData();
            formData.append('liveSessionScheduleJson', JSON.stringify(liveSessionSchedule))
            if (image !== undefined) {
                files.push({name: 'thumbnail', rawFile: image});
            }
            this.uploadS3('/api/tutor/live-session/create-live-session-schedule', files, formData, listener)
        })
        .catch(() => listener(this.failedApiResponse()))
    }

    updateLiveSessionSchedule(liveSessionScheduleId, title, desc, thumbnail, listener) {
        this.compressImage(thumbnail, true)
        .then(image => {
            let files = [];
            const formData = new FormData();
            formData.append('liveSessionScheduleUpdateJson', JSON.stringify({
                liveSessionScheduleId: liveSessionScheduleId,
                title: title,
                description: desc
            }))
            if (image !== undefined) {
                files.push({name: 'thumbnail', rawFile: image});
            }
            this.uploadS3('/api/tutor/live-session/update-live-session-schedule', files, formData, listener)
        })
        .catch(() => listener(this.failedApiResponse()))
    }

    removeLiveSessionSchedule(liveSessionScheduleId, listener) {
        this.get('/api/tutor/live-session/remove-live-session-schedule/' + liveSessionScheduleId, listener);
    }
    
    getMyTutorLiveSessions(page, listener) {
        this.get('/api/tutor/live-session/get-my-live-sessions/' + page, listener);
    }

    getMyStudentLiveSessions(page, listener) {
        this.get('/api/student/live-session/get-my-live-sessions/' + page, listener);
    }

    getTutorCourseLiveSessions(courseId, page, listener) {
        this.get('/api/tutor/live-session/get-course-live-sessions/' + courseId + "/" + page, listener);
    }

    getStudentCourseLiveSessions(courseId, page, listener) {
        this.get('/api/student/live-session/get-course-live-sessions/' + courseId + "/" + page, listener);
    }

    getTutorLiveSessions(tutorId, page, listener) {
        this.get('/api/public/live-session/get-tutor-live-sessions/' + tutorId + "/" + page, listener);
    }

    getOtherTutorLiveSessions(liveSessionId, listener) {
        this.get('/api/public/live-session/get-other-tutor-live-sessions/' + liveSessionId, listener);
    }

    getCategoryLiveSessions(category, page, listener) {
        this.get('/api/public/live-session/get-category-live-sessions/' + category + "/" + page, listener);
    }

    getTopCategoryLiveSessions(category, listener) {
        this.get('/api/public/live-session/get-top-category-live-sessions/' + category, listener);
    }

    getTopLiveSessions(listener) {
        this.get('/api/public/live-session/get-top-live-sessions/', listener);
    }

    searchLiveSessions(searchValue, listener) {
        this.post('/api/public/live-session/search-live-sessions/',{
            content: searchValue
        }, listener);
    }

    getTutorLiveSession(liveSessionId, listener) {
        this.get('/api/tutor/live-session/get-live-session/' + liveSessionId, listener);
    }
    
    findLiveStreamFromHash(tokenHash, listener) {
        this.get('/api/' + (!SessionManager.isLoggedIn() ? 'public' :  'frontend') + '/live-stream/find-stream/' + tokenHash, listener);
    }

    getStudentLiveSession(liveSessionId, listener) {
        // this.get('/api/student/live-session/get-live-session/' + liveSessionId, listener);
        if (!SessionManager.isLoggedIn()) {
            this.get('/api/' + (!SessionManager.isLoggedIn() ? 'public' :  'student') + '/live-session/get-public-live-session/' + liveSessionId, listener);
        } else {
            this.get('/api/' + (!SessionManager.isLoggedIn() ? 'public' :  'student') + '/live-session/get-live-session/' + liveSessionId, listener);
        }
    }

    getPublicLiveSession(liveSessionId, listener) {
        this.get('/api/public/live-session/get-live-session/' + liveSessionId, listener);
    }

    seenMessage(messageId) {
        this.get('/api/frontend/messages/seen-message/' + messageId, () => {});
    }

    getProfileRooms(requestUserId, listener) {
        this.get('/api/frontend/messages/get-profile-rooms/' + requestUserId, listener);
    }

    getMessages(roomId, listener) {
        this.get('/api/frontend/messages/get-messages/' + roomId, listener);
    }

    getLiveStreamMessages(liveStreamId, listener) {
        this.get('/api/frontend/messages/get-live-stream-messages/' + liveStreamId, listener);
    }

    newMessage(tempId, roomParentType, roomParentId, roomSecondaryParentId, roomOtherMemberId, message, listener) {
        this.post('/api/frontend/messages/new-message', {
            tempId: tempId,
            roomParentType: roomParentType,
            roomParentId: roomParentId,
            roomSecondaryParentId: roomSecondaryParentId,
            roomOtherMemberId: roomOtherMemberId,
            message: message
        }, listener);
    }

    newAttachedMessage(tempId, roomParentType, roomParentId, roomSecondaryParentId, roomOtherMemberId, message, attachmentType, attachment, listener, progressListener) {
        const action = (attachmentFile) => {
            let files = [];
            const formData = new FormData();
            formData.append('chatMessageJson', JSON.stringify({
                tempId: tempId,
                roomParentType: roomParentType,
                roomParentId: roomParentId,
                roomSecondaryParentId: roomSecondaryParentId,
                roomOtherMemberId: roomOtherMemberId,
                message: message
            }));
            formData.append('attachmentType', attachmentType);
            files.push({name: 'attachment', rawFile: attachmentFile, attachment: true});
            this.uploadS3('/api/frontend/messages/new-attached-message', files, formData, listener, progressListener)
        }

        if (attachmentType == MESSAGE_ATTACHMENT_TYPE_IMAGE) {
            this.compressImage(attachment, false)
            .then(image => action(image))
            .catch(() => listener(this.failedApiResponse()))
        } else {
            action(attachment);
        }
    }

    getProfile(userId, listener) {
        if (userId !== undefined) {
            this.get('/api/public/profile/get-profile/' + userId, listener);
        } else {
            this.get('/api/' + (SessionManager.isLoggedInAsTutor() ? 'tutor' :  'student') + '/profile/get-my-profile', listener);
        }
    }

    uploadHashtagImages(hashtag, thumbnail, cover, listener) {
        this.compressMultipleImages([this.getImg(thumbnail), this.getImg(cover)])
        .then(images => {
            const thumbnailImage = images[0].isnull === undefined ? images[0] : undefined;
            const coverImage = images[1].isnull === undefined ? images[1] : undefined;

            let files = [];
            const formData = new FormData();
            if (thumbnailImage !== undefined) {
                files.push({name: 'thumbnail', rawFile: thumbnailImage});
            }
            if (coverImage !== undefined) {
                files.push({name: 'cover', rawFile: coverImage});
            }
            this.uploadS3('/api/content-manager/hashtag/upload-hashtag-images/' + hashtag, files, formData, listener)
        })
        .catch(() => listener(this.failedApiResponse()))
    }

    updateStudentProfile(profilePicture, listener) {
        this.compressImage(profilePicture, true)
        .then(image => {
            let files = [];
            const formData = new FormData();
            if (profilePicture !== undefined) {
                files.push({name: 'profilePicture', rawFile: image});
            }
            this.uploadS3('/api/frontend/profile/update-student-profile', files, formData, listener)
        })
        .catch(() => listener(this.failedApiResponse()))
    }

    updateProfile(profile, coverImage, profilePicture, profileVideo, listener) {
        this.compressMultipleImages([this.getImg(coverImage), {...this.getImg(profilePicture), profile: true}])
        .then(images => {
            const cI = images[0].isnull === undefined ? images[0] : undefined;
            const pP = images[1].isnull === undefined ? images[1] : undefined;

            let files = [];
            const formData = new FormData();

            formData.append('profileUpdateJson', JSON.stringify(profile));
            if (cI !== undefined) {
                files.push({name: 'coverImage', rawFile: cI});
            }
            if (pP !== undefined) {
                files.push({name: 'profilePicture', rawFile: pP});
            }
            if (profileVideo !== undefined) {
                files.push({name: 'profileVideo', rawFile: profileVideo});
            }
            this.uploadS3('/api/' + (SessionManager.isLoggedInAsTutor() ? 'tutor' :  'student') + '/profile/update-profile', files, formData, listener)
        })
        .catch(() => listener(this.failedApiResponse()));
    }

    saveCourseDetail(courseUpdate, thumbnail, galleryItems, listener) {
        let images = [];
        images.push(this.getImg(thumbnail));
        if (galleryItems !== undefined) {
            for (const galleryItem of galleryItems) {
                if (galleryItem.file !== undefined) {
                    images.push(this.getImg(galleryItem.file));
                }
            }
        }

        this.compressMultipleImages(images)
        .then(images => {
            let files = [];
            const formData = new FormData();

            
            formData.append('courseUpdateJson', JSON.stringify({
                ...courseUpdate,
                galleryItems: galleryItems.map(galleryItem => ({
                    id: galleryItem.id,
                    type: galleryItem.type,
                    fileId: galleryItem.fileId,
                }))
            }));
            if (images[0].isnull === undefined) {
                files.push({name: 'thumbnail', rawFile: images[0]});
            }
            if (galleryItems !== undefined) {
                let i = 1;
                for (const galleryItem of galleryItems) {
                    if (galleryItem.file !== undefined) {
                        files.push({name: 'galleryFiles', rawFile: images[i], customName: galleryItem.fileId}); //NAME IMPORTANT
                        i++;
                    }
                }
            }
            this.uploadS3('/api/tutor/course/save-course-detail', files, formData, listener)
        })
        .catch(() => listener(this.failedApiResponse()));
    }

    saveLiveSessionDetail(liveSessionUpdate, thumbnail, galleryItems, listener) {
        let images = [];
        images.push(this.getImg(thumbnail));
        if (galleryItems !== undefined) {
            for (const galleryItem of galleryItems) {
                if (galleryItem.file !== undefined) {
                    images.push(this.getImg(galleryItem.file));
                }
            }
        }

        this.compressMultipleImages(images)
        .then(images => {
            let files = [];
            const formData = new FormData();

            formData.append('liveSessionUpdateJson', JSON.stringify({
                ...liveSessionUpdate,
                galleryItems: galleryItems.map(galleryItem => ({
                    id: galleryItem.id,
                    type: galleryItem.type,
                    fileId: galleryItem.fileId,
                }))
            }));
            if (images[0].isnull === undefined) {
                files.push({name: 'thumbnail', rawFile: images[0]});
            }
            if (galleryItems !== undefined) {
                let i = 1;
                for (const galleryItem of galleryItems) {
                    if (galleryItem.file !== undefined) {
                        files.push({name: 'galleryFiles', rawFile: images[i], customName: galleryItem.fileId}); //NAME IMPORTANT
                        i++;
                    }
                }
            }
            
            this.uploadS3('/api/tutor/live-session/save-live-session-detail', files, formData, listener)
        })
        .catch(() => listener(this.failedApiResponse()));        
    }

    getCourseItemThumbnail(courseItemId) {
        //return this.getServerIp() + '/api/public/course/get-course-item-thumbnail/' + courseItemId;
        //return this.getCDN() + "/courseitemthumbnail/" + courseItemId + "?version=" + -(new Date().getTime());
        return this.getS3() + "/courseitemthumbnail/" + courseItemId + "?i=1";
    }

    getLiveSessionScheduleThumbnail(liveSessionScheduleId) {
        //return this.getServerIp() + '/api/public/live-session/get-live-session-schedule-thumbnail/' + liveSessionScheduleId;
        //return this.getCDN() + "/livesessionschedulethumbnail/" + liveSessionScheduleId + "?version=" + -(new Date().getTime());
        return this.getS3() + "/livesessionschedulethumbnail/" + liveSessionScheduleId + "?i=1";
    }

    getCourseScheduleThumbnail(courseScheduleId) {
        return this.getS3() + "/courseschedulethumbnail/" + courseScheduleId + "?i=1";
    }

    getCourseItemAttachment(courseItemId) {
        //return this.getServerIp() + '/api/' + (SessionManager.isLoggedInAsTutor() ? 'tutor' :  'student') + '/course/get-course-item-attachment/' + courseItemId + this.getParamToken();
        return this.getCDN() + "/courseitemattachment/" + courseItemId
    }

    getLiveSessionScheduleAttachment(attachmentId) {
        return this.getCDN() + "/livesessionscheduleattachment/" + attachmentId
    }

    getCourseThumbnail(courseId) {
        //return this.getServerIp() + '/api/public/course/get-course-thumbnail/' + courseId;
        //return this.getCDN() + "/coursethumbnail/" + courseId + "?version=" + -(new Date().getTime());
        return this.getS3() + "/coursethumbnail/" + courseId + "?i=1";
    }

    getBlogPostThumbnail(blogPostId) {
        return this.getS3() + "/blogpostthumbnail/" + blogPostId + "?i=1";
    }

    getLiveSessionThumbnail(liveSessionId) {
        //return this.getServerIp() + '/api/public/live-session/get-live-session-thumbnail/' + liveSessionId;
        //return this.getCDN() + "/livesessionthumbnail/" + liveSessionId + "?version=" + -(new Date().getTime());
        return this.getS3() + "/livesessionthumbnail/" + liveSessionId + "?i=1";
    }

    getGalleryItem(galleryItemId) {
        //return this.getServerIp() + '/api/public/gallery/get-gallery-item/' + galleryItemId;
        return this.getCDN() + "/galleryitem/" + galleryItemId;
    }

    getMyCoverImage() {
        //return this.getServerIp() + '/api/tutor/profile/get-my-cover-image/' + this.getParamToken();
        return this.getCoverImage(SessionManager.getAccount().id);
    }

    getMyProfilePic() {
        //return this.getServerIp() + '/api/' + (SessionManager.isLoggedInAsTutor() ? 'tutor' :  'student') + '/profile/get-my-profile-pic/' + this.getParamToken();
        return this.getProfilePic(SessionManager.getAccount().id);
    }

    getMyProfileVideo() {
        //return this.getServerIp() + '/api/tutor/profile/get-my-profile-video/' + this.getParamToken();;
        return this.getProfileVideo(SessionManager.getAccount().id);
    }

    getCoverImage(accountId) {
        //return this.getServerIp() + '/api/public/profile/get-cover-image/' + accountId;
        //return this.getCDN() + "/coverimage/" + accountId + "?version=" + -(new Date().getTime());
        return this.getS3() + "/coverimage/" + accountId + "?i=1";
    }

    getCourseTutorProfilePic(courseId) {
        return this.getServerIp() + '/api/public/course/get-course-tutor-profile-pic/' + courseId;
    }

    getLiveSessionTutorProfilePic(liveSessionId) {
        return this.getServerIp() + '/api/public/live-session/get-live-session-tutor-profile-pic/' + liveSessionId;
    }

    getProfilePic(accountId) {
        //return this.getServerIp() + '/api/public/profile/get-profile-pic/' + accountId;
        //return this.getCDN() + "/profilepic/" + accountId + "?version=" + -(new Date().getTime());
        return this.getS3() + "/profilepic/" + accountId + "?i=1";
    }

    getHashtagThumbnail(hashtag) {
        if (!Util.isStringExists(hashtag)) {
            hashtag = "";
        }
        return this.getS3() + "/hashtagthumbnail/" + hashtag.trim().toLowerCase() + "?i=1";
    }

    getHashtagCover(hashtag) {
        if (!Util.isStringExists(hashtag)) {
            hashtag = "";
        }
        return this.getS3() + "/hashtagcover/" + hashtag.trim().toLowerCase() + "?i=1";
    }

    getProfileVideo(accountId) {
        //return this.getServerIp() + '/api/public/profile/get-profile-video/' + accountId;
        //return this.getCDN() + "/profilevideo/" + accountId + "?version=" + -(new Date().getTime());
        return this.getS3() + "/profilevideo/" + accountId + "?i=1";
    }

    newPost(postContent, parentId, parentType, postImage, postVideo, galleryItems, listener) {
        this.createNewPostData(postContent, parentId, parentType, postImage, postVideo, galleryItems, postData => {
            if (postData !== undefined) {
                this.uploadS3('/api/frontend/posts/new-post', postData.files, postData.formData, listener)
            } else {
                listener(this.failedApiResponse())
            }
        });
    }

    newAdminPost(postContent, parentId, parentType, postImage, postVideo, galleryItems, listener) {
        this.createNewPostData(postContent, parentId, parentType, postImage, postVideo, galleryItems, postData => {
            if (postData !== undefined) {
                this.uploadS3('/api/admin/posts/new-post', postData.files, postData.formData, listener)
            } else {
                listener(this.failedApiResponse())
            }
        });
    }

    newReview(postContent, parentId, parentType, postImage, postVideo, galleryItems, rating, listener) {
        this.createNewPostData(postContent, parentId, parentType, postImage, postVideo, galleryItems, postData => {
            if (postData !== undefined) {
                postData.formData.append("ratingJsonValue", JSON.stringify(rating));
                this.uploadS3('/api/student/posts/new-post', postData.files, postData.formData, listener)
            } else {
                listener(this.failedApiResponse())
            }
        });
    }

    createNewPostData(postContent, parentId, parentType, postImage, postVideo, galleryItems, responseListener) {
        let images = [];
        images.push(this.getImg(postImage));
        if (galleryItems !== undefined) {
            for (const galleryItem of galleryItems) {
                if (galleryItem.file !== undefined) {
                    images.push(this.getImg(galleryItem.file));
                }
            }
        }

        this.compressMultipleImages(images)
        .then(images => {
            let files = []
            const formData = new FormData();
            formData.append('postContent', postContent);
            formData.append('parentId', parentId !== undefined ? parentId : 0)
            formData.append('parentType', parentType !== undefined ? parentType : POST_PARENT_TYPE_PROFILE)
            if (images[0].isnull === undefined) {
                files.push({name: 'postImage', rawFile: images[0]});
            }
            if (postVideo !== undefined) {
                files.push({name: 'postVideo', rawFile: postVideo});
            }
            if (galleryItems !== undefined) {
                let i = 1;
                for (const galleryItem of galleryItems) {
                    if (galleryItem.file !== undefined) {
                        files.push({name: 'galleryFiles', rawFile: images[i], customName: galleryItem.fileId}); //NAME IMPORTANT
                        i++;
                    }
                }
            }

            responseListener({files, formData})
        })
        .catch(() => responseListener(undefined));
    }

    getPostComments(parentId, page, listener) {
        this.getComments(parentId, COMMENT_PARENT_TYPE_POST, page, listener);
    }

    newPostComment(commentContent, parentId, listener) {
        this.newComment(commentContent, parentId, COMMENT_PARENT_TYPE_POST, listener);
    }

    getNotifications(page, listener) {
        this.get('/api/frontend/notification/get-notifications/' + page, listener);
    }

    getComments(parentId, parentType, page, listener) {
        //this.get('/api/' + (SessionManager.isLoggedInAsAdmin() ? 'admin' :  'frontend') + '/comment/get-comments/' + parentId + '/' + parentType + '/' + page, listener)


        this.get('/api/public/comment/get-comments/' + parentId + '/' + parentType + '/' + page, listener)
    }

    newComment(commentContent, parentId, parentType, listener) {
        this.post('/api/' + (SessionManager.isLoggedInAsAdmin() ? 'admin' :  'frontend') + '/comment/new-comment/', {
            commentContent: commentContent,
            parentId: parentId,
            parentType, parentType
        }, listener);
    }

    toggleLikePost(postId, listener) {
        this.toggleLike(postId, ITEM_LIKE_PARENT_TYPE_POST, listener);
    }

    toggleLikeBlogPost(blogPostId, listener) {
        this.toggleLike(blogPostId, ITEM_LIKE_PARENT_TYPE_BLOG_POST, listener);
    }

    toggleLike(parentId, parentType, listener) {
        this.get('/api/' + (SessionManager.isLoggedInAsAdmin() ? 'admin' :  'frontend') + '/item-like/toggle-like/' + parentId + '/' + parentType + '?d=' + new Date().getTime(), listener);
    }

    toggleTutorFollow(tutorId, listener) {
        this.get('/api/frontend/profile/follow-tutor-toggle/' + tutorId + '?d=' + new Date().getTime(), listener);
    }

    toggleAccountVerification(userId, listener) {
        this.get('/api/relation-manager/account-verification/toggle-account-verification/' + userId, listener);
    }

    getOnePost(postId, listener) {
        this.get('/api/public/posts/get-one-post/' + postId, listener);
    }

    getPosts(parentId, parentType, page, listener) {
        if (parentId !== undefined) {
            this.get('/api/public/posts/get-posts/' + parentId + '/' + parentType + "/" + page, listener);
        } else {
            this.get('/api/' + (SessionManager.isLoggedInAsTutor() ? 'tutor' :  'student') + '/posts/get-my-posts/' + page, listener);
        }
    }

    getFollowingPosts(page, listener) {
        this.get('/api/frontend/posts/get-following-posts/' + page, listener);
    }

    getAllPosts(page, listener) {
        this.get('/api/frontend/posts/get-all-posts/' + page, listener);
    }

    getAdminPosts(page, listener) {
        this.get('/api/admin/posts/get-admin-posts/' + page, listener);
    }

    getRatingPosts(itemId, itemType, page, listener) {
        this.get('/api/public/posts/get-ratings-posts/' + itemId + "/" + itemType + "/" + page, listener);
    }

    getRatings(itemId, itemType, listener) {
        this.get('/api/public/posts/get-ratings/' + itemId + "/" + itemType, listener);
    }

    getHashtagUsage(hashtag, listener) {
        this.get('/api/public/hashtag/get-usage/' + hashtag, listener);
    }

    getLatestHashtagCourses(hashtag, listener) {
        this.get('/api/public/course/get-latest-hashtag-items/' + hashtag, listener)
    }

    getLatestHashtagLiveSessions(hashtag, listener) {
        this.get('/api/public/live-session/get-latest-hashtag-items/' + hashtag, listener)
    }

    getHashtagPosts(hashtag, pageToken, listener) {
        this.get('/api/public/posts/get-hashtag-posts/' + hashtag + '/' + pageToken, listener);
    }

    getPostImage(postId) {
        //return this.getServerIp() + '/api/public/posts/get-post-image/' + postId;
        return this.getCDN() + "/postimages/" + postId;
    }

    getPostVideo(postId) {
        //return this.getServerIp() + '/api/public/posts/get-post-video/' + postId;
        //return this.getCDN() + "/postvideo/" + postId;
        return this.getPostImage(postId);
    }

    getMessageAttachment(attachmentId) {
        //return this.getServerIp() + '/api/frontend/messages/get-message-attachment/' + attachmentId + this.getParamToken();;
        return this.getCDN() + "/messageattachment/" + attachmentId;
    }

    getRolePath() {
        if (SessionManager.isLoggedIn()) {
            switch (SessionManager.getAccount().role) {
                case ACCOUNT_ROLE_ADMIN:
                    return 'admin';
                case ACCOUNT_ROLE_STUDENT:
                    return 'student';
                case ACCOUNT_ROLE_TUTOR: 
                    return 'tutor';
            }
        } else {
            return "public";
        }
    }

    /*logout(onDoneListener) {
        this.get('/api/auth/logout', i => onDoneListener());
    }*/

    post(path, body, listener) {
        this.callApi(path, 'post', body, listener);
    }

    get(path, listener) {
        this.callApi(path, 'get', undefined, listener);
    }

    callApi(path, method, body, listener) {
        let actualPath;
        if (path.includes("/live-stream/") || path.includes("/web-rtc/") || path.includes("get-live-stream-viewers")) {
            actualPath = this.getLiveStreamServerIp() + path;
        } else {
            actualPath = this.getServerIp() + path;
        }

        axios(actualPath, method === 'post' ? ({
            method: method,
            headers: {
                "Content-Type": "application/json",
                // "Authorization": SessionManager.isLoggedIn() && "Bearer " + SessionManager.getAccount().token
                //"Authorization": (SessionManager.isLoggedIn() || this.customToken) && "Bearer " + this.getToken()
                "Authorization": this.getHeaderToken()
            },
            data: body,
            withCredentials: true
        }) : ({
            headers: {
                // "Authorization": SessionManager.isLoggedIn() && "Bearer " + SessionManager.getAccount().token
                //"Authorization": (SessionManager.isLoggedIn() || this.customToken) && "Bearer " + this.getToken()
                "Authorization": this.getHeaderToken()
            },
            method: method,
            withCredentials: true
        }))
        .then(response => {
            const result = response.data;
            if (result.status === true || result.status === false) {
                listener(result)
            } else {
                listener(this.failedApiResponse())
            }
        })
        .catch(error => {
            if (error.response && error.response.status == 401) {
                SessionManager.logout(() => {
                    alert("You have been logged out!");
                    window.location.href = "/";
                });
            } else {
                listener(this.failedApiResponse())
            }
        });
    }

    uploadS3(path, files, data, listener, progressListener) {
        if (files.length == 0) {
            this.upload(path, data, listener);
            return;
        }

        const taskCount = files.length + 2;
        const taskPerc = (1 / taskCount) * 100;
        let progress = 0;

        const onupdateprogress = (progressAdd) => {
            progress += progressAdd;
            if (progressListener !== undefined) {
                progressListener(Math.round(progress));
            }
        }

        //^^ does not need throttling since progress is not based on download with s3 now, fix it later (MAKE SURE to include the throttling system anywhere its used)

        this.post('/api/frontend/s3-upload/get-upload-url', {
            items: files.map(file => ({
                name: file.customName !== undefined ? file.customName : file.rawFile.name,
                size: file.rawFile.size,
                type: Util.isStringExists(file.rawFile.type) ? file.rawFile.type : 'application/octet-stream',
                attachment: file.attachment === true ? true : false
            }))
        }, async response => {
            onupdateprogress(taskPerc);

            if (response.status === true) {
                for (let i = 0; i < response.payload.items.length; i++) {
                    const uploadItem = response.payload.items[i];
                    const file = files[i];
                    
                    let form = new FormData();
                    for (const fields in uploadItem.presignedPost.fields) {
                        form.append(fields, uploadItem.presignedPost.fields[fields])
                    }
                    form.append('file', file.rawFile);

                    const result = await fetch(uploadItem.presignedPost.url, { method: 'POST', body: form })
                    if (!result.ok) {
                        console.log(result);
                        listener(this.failedApiResponse());
                        break;
                    }
                    onupdateprogress(taskPerc);
                }

                for (let i = 0; i < files.length; i++) {
                    const uploadItem = response.payload.items[i];
                    const file = files[i];

                    data.append(file.name, uploadItem.key)
                }
                
                this.upload(path, data, listener);
            } else {
                if (response.payload == API_ERROR_PAYLOAD) {
                    alert("Maximum upload size exceeded (> 2GB)")
                }
                listener(response);
            }
        })
    }

    uploadS3AndPost(path, files, body, listener, progressListener) {
        if (files.length == 0) {
            this.post(path, body, listener);
            return;
        }

        const taskCount = files.length + 2;
        const taskPerc = (1 / taskCount) * 100;
        let progress = 0;

        const onupdateprogress = (progressAdd) => {
            progress += progressAdd;
            if (progressListener !== undefined) {
                progressListener(Math.round(progress));
            }
        }

        //^^ does not need throttling since progress is not based on download with s3 now, fix it later (MAKE SURE to include the throttling system anywhere its used)

        this.post('/api/frontend/s3-upload/get-upload-url', {
            items: files.map(file => ({
                name: file.customName !== undefined ? file.customName : file.rawFile.name,
                size: file.rawFile.size,
                type: Util.isStringExists(file.rawFile.type) ? file.rawFile.type : 'application/octet-stream',
                attachment: file.attachment === true ? true : false
            }))
        }, async response => {
            onupdateprogress(taskPerc);

            if (response.status === true) {
                for (let i = 0; i < response.payload.items.length; i++) {
                    const uploadItem = response.payload.items[i];
                    const file = files[i];
                    
                    let form = new FormData();
                    for (const fields in uploadItem.presignedPost.fields) {
                        form.append(fields, uploadItem.presignedPost.fields[fields])
                    }
                    form.append('file', file.rawFile);

                    const result = await fetch(uploadItem.presignedPost.url, { method: 'POST', body: form })
                    if (!result.ok) {
                        console.log(result);
                        listener(this.failedApiResponse());
                        break;
                    }
                    onupdateprogress(taskPerc);
                }

                for (let i = 0; i < files.length; i++) {
                    const uploadItem = response.payload.items[i];
                    const file = files[i];

                    body[file.name] = uploadItem.key;
                }
                
                //this.upload(path, data, listener);
                this.post(path, body, listener);
            } else {
                listener(response);
            }
        })
    }

    upload(path, formData, listener, progressListener) {
        let formDataParams = new Object(); 
        for (const entry of formData.entries()) {
            if (formDataParams[entry[0]] === undefined) {
                if (entry[0] == "galleryFiles") {
                    formDataParams[entry[0]] = [entry[1]];
                } else {
                    formDataParams[entry[0]] = entry[1];
                }
            } else {
                const lastEntry = formDataParams[entry[0]];
                if (Array.isArray(lastEntry)) {
                    lastEntry.push(entry[1]);
                } else {
                    formDataParams[entry[0]] = [lastEntry, entry[1]];
                }
            }
        }

        /*console.log("fomrdata", formDataParams);
        if (true) {
            listener(this.failedApiResponse())
            return;
        }*/
        if (formDataParams.galleryFiles !== undefined) {
            formDataParams.galleryFiles = JSON.stringify(formDataParams.galleryFiles);
        }

        //axios.post(this.getServerIp() + path, formData, {
        axios.post(this.getServerIp() + path, undefined, {
            headers: {
                //"Authorization": SessionManager.isLoggedIn() && "Bearer " + SessionManager.getAccount().token
                // "Authorization": SessionManager.isLoggedIn() && "Bearer " + this.getToken()
                // "Authorization": (SessionManager.isLoggedIn() || this.customToken) && "Bearer " + this.getToken()
                "Authorization": this.getHeaderToken()
            },
            onUploadProgress: ({total, loaded}) => {
                if (progressListener !== undefined) {
                    progressListener(Math.round((loaded / total) * 100))
                }
            },
            withCredentials: true,
            params: formDataParams
        })
        .then(response => {
            const result = response.data;
            if (result.status === true || result.status === false) {
                listener(result)
            } else {
                listener(this.failedApiResponse())
            }
        })
        .catch(error => {
            if (error.response && error.response.status == 401) {
                SessionManager.logout(() => {
                    alert("You have been logged out!");
                    window.location.href = "/";
                });
            } else {
                listener(this.failedApiResponse())
            }
        });
    }

    getServerIp() {
        //return 'http://130.61.239.254:8080'
        //return 'https://dubaikey.app'
        //return '140.184.38.230:9000'
        //return 'https://learnerix.online'
        //return 'http://localhost:7000'
        // return ''
        return 'https://cnlb0tijvf.execute-api.me-south-1.amazonaws.com';
    }

    getLiveStreamServerIp() {
        return 'https://learnerix.online'
        // return '';
    }

    getRealtimeSocketIp() {
        return 'https://learnerix.online'
        // return '';
    }

    getCDN() {
        return 'https://d1n9c9kc7fz1oa.cloudfront.net';
    }

    getS3() {
        return 'https://s3-me-south-1.amazonaws.com/learnerixappuploads';
    }

    getWebSocketEndpoint() {
        return this.getRealtimeSocketIp() + '/api/socket' + this.getParamToken();
    }
    
    // getGuestWebSocketEndpoint() {
    //     // return this.getRealtimeSocketIp() + '/api/socket' + "?guestConnection=" + 'yes';
    //     return this.getRealtimeSocketIp() + '/api/socket?guestConnection=true';
    // }

    getHeaderToken() {
        if (SessionManager.isLoggedIn() || this.customToken) {
            return "Bearer " + this.getToken();
        } else if (this.guestToken) {
            return "Guest " + this.getToken();
        }
    }

    getParamToken() {
        // return "?token=" + SessionManager.getAccount().token;
        if (this.guestToken) {
            return "?guest-token=" + this.getToken();
        } else {
            return "?token=" + this.getToken();
        }
    }

    getToken() {
        return this.guestToken ? this.guestToken : this.customToken ? this.customToken : SessionManager.getAccount().token;
    }

    failedApiResponse() {
        return {
            status: false,
            message: "An error occurred. Please try again later.",
            payload: null
        }
    }

    compressImage(image, profile) {
        if (image !== undefined) {
            return new Promise((resolve, reject) => new Compressor(image, {
                maxWidth: profile ? 400 : 1028,
                maxHeight: profile ? 400 : 1028,
                quality: 0.6,
                success: (result) => resolve(result),
                error: (err) => reject(err)
            }));
        } else {
            return new Promise((resolve) => resolve(undefined));
        }
    }

    getImg(image) {
        return image !== undefined ? {rawImage: image} : {isnull: true};
    }

    compressMultipleImages(images) {
        if (images.length > 0) {
            return Promise.all(images.map(image => {
                if (image.isnull === undefined) {
                    return this.compressImage(image.rawImage, image.profile === true ? true : false);
                } else {
                    return new Promise((resolve) => resolve(this.getImg()));
                }
            }));
        } else {
            return new Promise((resolve) => resolve([]));
        }
    }

}

export default new Api();