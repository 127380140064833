import { Col, Row, Button, Spin, Empty } from 'antd';

import {
    LeftOutlined,
    RightOutlined,
    LeftCircleTwoTone,
    RightCircleTwoTone
} from '@ant-design/icons';
import React from 'react'
import Api from '../../session/Api';
import withMediaQuery from '../../components/WithMediaQuery';

import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import GridBlogPostItem from './GridBlogPostItem';

class HorizontalBlogPostView extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            items: [],
            page: 0,
        }
    }

    componentDidMount() {
        this.loadData(this.props);
    }

    componentWillReceiveProps(newProps) {
        if (this.props.courseId !== newProps.courseId || 
            this.props.searchValue !== newProps.searchValue ||
            this.props.category !== newProps.category) {
            this.setState({
                loading: true,
                items: [],
                page: 0
            })
    
            this.loadData(newProps);
        }
    }

    loadData(props) {
        const listener = response => {
            if (response.status === true) {
                this.setState({
                    loading: false,
                    items: response.payload
                })
            } else {
                //list error
                this.setState({loading: false})
            }
        }

        Api.getLatestHomeBlogPosts(listener);
    }

    count() {
        if (this.props.isSmall) {
            return 1;
        } else {
            return this.props.count !== undefined ? this.props.count : 3;
        }
    }

    isFirstPage() {
        return this.state.page === 0;
    }

    isLastPage() {
        return this.state.page === (this.state.items.length > 0 ? (Math.ceil(this.state.items.length / this.count()) - 1) : 0);
    }

    renderRowContent() {
        const items = this.state.items;
        const itemSelection = items.slice(this.state.page * this.count(), (this.state.page * this.count()) + this.count());
        const span = 24 / this.count();

        return itemSelection.map(item => <Col key={item.id} span={span}><GridBlogPostItem blogPost={item} /></Col>)
    }

    render() {
        if (this.state.loading) {
            return (
                <div style={{display: 'flex', justifyContent: 'center', marginTop: '45px', marginBottom: '45px'}}>
                    <Spin size="large" tip="Loading..." />
                </div>
            );
        }

        if (this.state.items.length === 0) {
            if (this.props.hideIfEmpty) {
                return null;
            } else {
                return <Empty style={{marginTop: '50px'}} image={Empty.PRESENTED_IMAGE_SIMPLE} description={"No blog posts"} />
            }
        }

        return (
            <div>
                {this.props.children}
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', ...this.props.style}}>
                    {!this.isFirstPage() && <>
                        {this.props.isSmall ? (
                            <Button onClick={e => this.setState(prevState => ({ page: prevState.page - 1 }))} type="link" style={{position: 'absolute', width: '60px', height: '295px', left: '-5px', zIndex: '1',}}>
                                <LeftCircleTwoTone twoToneColor="#4e2e89" style={{fontSize: '40px', marginBottom: '100px'}} />
                            </Button>
                        ) : (
                            <Button onClick={e => this.setState(prevState => ({ page: prevState.page - 1 }))} type="text" style={{width: '50px', marginLeft: '-65px', marginRight: '15px'}}><LeftOutlined /></Button>
                        )}
                    </>}
                    <Row gutter={15} style={{flex: 1}}>
                        {/*this.state.items.map(item => <Col span={6}><SaleCourseItem course={item} /></Col>)*/}
                        {false ? (
                            <ReactCSSTransitionGroup
                            style={{display: 'flex'}}
                            transitionName="slide"
                            transitionEnterTimeout={500}
                            transitionLeaveTimeout={300}>
                                {this.renderRowContent()}
                            </ReactCSSTransitionGroup>
                        ) : (
                            this.renderRowContent()
                        )}
                        
                    </Row>
                    {!this.isLastPage() && <>
                        {this.props.isSmall ? (
                            <Button onClick={e => this.setState(prevState => ({ page: prevState.page + 1 }))} type="link" style={{position: 'absolute', width: '60px', height: '295px', right: '5px', zIndex: '1',}}>
                                <RightCircleTwoTone twoToneColor="#4e2e89" style={{fontSize: '40px', marginBottom: '100px'}} />
                            </Button>
                        ) : (
                            <Button onClick={e => this.setState(prevState => ({ page: prevState.page + 1 }))} type="text" style={{width: '50px', marginRight: !this.props.keepNextBtnIn ? '-65px' : '0', marginLeft: '15px'}}><RightOutlined /></Button>
                        )}
                    </>}
                </div>
            </div>
        )
    }

}

export default withMediaQuery(HorizontalBlogPostView);