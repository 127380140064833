import React from 'react';
import { Input, Button, Empty, Spin, Rate, Tooltip, Upload, Mentions } from 'antd';

import { motion } from "framer-motion"

import { 
    CameraOutlined,
    CloseOutlined,
    VideoCameraOutlined,
    AppstoreAddOutlined,
    PlusOutlined,
    FireFilled
} from '@ant-design/icons';
import PostItem from './PostItem';
import Api from '../../session/Api';

import InfiniteScroll from 'react-infinite-scroller';


import FullScreenImg from '../../components/FullScreenImg';
import UploadWrapper from '../../components/UploadWrapper';

import Lightbox from '../../components/media-list-viewer/react-image-lightbox';
import '../../components/media-list-viewer/lightbox-style.css';
import { POST_TYPE_IMAGE, POST_TYPE_VIDEO, POST_PARENT_TYPE_PROFILE, POST_PARENT_TYPE_COURSE, PAGINATION_PAGE_CONTENT_COUNT, POST_PARENT_TYPE_RATING, POST_PARENT_TYPE_LIVE_SESSION, GALLERY_ITEM_TYPE_VIDEO, GALLERY_ITEM_TYPE_IMAGE, POST_TYPE_GALLERY, NO_PAGE_TOKEN } from '../../Constants';
import Util from '../../util/Util';
import ProfilePicture from '../../components/ProfilePicture';
import { Link } from 'react-router-dom';
import SessionManager from '../../session/SessionManager';
import UIUtil from '../../util/UIUtil';

import HashtagCover from '../../images/HashtagCover.jpg';

const { TextArea } = Input;

class PostsView extends React.Component {

    test = Util.newTempId();
    constructor(props) {
        super(props);

        this.state = {
            featuredHashtags: undefined,

            newPostContent: "",
            newPostImage: undefined,
            newPostVideo: undefined,
            newPostVideoUrl: undefined,
            newRating: 0,

            items: [],
            page: NO_PAGE_TOKEN,
            hasMore: true,
            loadingItems: false,

            posting: false,

            selectedPost: undefined,
            selectedPostGallery: undefined,

            inMediaMode: false,
            galleryItemsValue: [],
        }
    }

    componentDidMount() {
        Api.getFeaturedHashtags(response => {
            this.setState({featuredHashtags: response.status === true ? response.payload : []})
        })
    }

    getParentId() {
        if (this.props.tutorId !== undefined) {
            return this.props.tutorId;
        } else if (this.props.courseId !== undefined) {
            return this.props.courseId;
        } else if (this.props.liveSessionId !== undefined) {
            return this.props.liveSessionId;
        } else {
            return undefined;
        }
    }

    getParentType() {
        if (this.props.tutorId !== undefined) {
            return POST_PARENT_TYPE_PROFILE;
        } else if (this.props.courseId !== undefined) {
            return POST_PARENT_TYPE_COURSE;
        } else if (this.props.liveSessionId !== undefined) {
            return POST_PARENT_TYPE_LIVE_SESSION;
        } else {
            return POST_PARENT_TYPE_PROFILE;
        }
    }

    getNextPosts() {
        if (this.state.loadingItems) {
            return;
        }

        if (!this.state.hasMore) {
            return;
        }

        this.setState({
            loadingItems: true
        })

        //list error
        const listener = response => {
            if (response.status === true) {
                let items = this.state.items;
                items.push(...response.payload.items);

                this.setState({
                    items: items,
                    page: response.payload.token,
                    hasMore: Util.isStringExists(response.payload.token),
                    loadingItems: false
                })
            } else {
                //alert('errowr');
            }
        };

        if (this.props.onePostMode) {
            listener({
                status: true,
                payload: {
                    items: [this.props.onePostMode],
                    token: null
                }
            })
        } else if (this.props.adminPosts) {
            Api.getAdminPosts(this.state.page, listener);
        } else if (this.props.followingPosts) {
            Api.getFollowingPosts(this.state.page, listener);
        } else if (this.props.ratingPosts) {
            Api.getRatingPosts(this.props.ratingItemId, this.props.ratingItemType, this.state.page, listener);
        } else if (this.props.allPosts) {
            Api.getAllPosts(this.state.page, listener);
        } else if (this.props.hashtagPosts) {
            Api.getHashtagPosts(this.props.hashtagPosts.hashtag, this.state.page, listener);
        } else {
            Api.getPosts(this.getParentId(), this.getParentType(), this.state.page, listener);
        }
    };

    onPostBtn() {
        if (this.state.newPostContent.trim().length === 0 && this.state.newPostImage === undefined && this.state.newPostVideo === undefined && this.state.newRating === 0) {
            if (!(this.state.inMediaMode && this.state.galleryItemsValue.length > 0)) {
                return;
            }
        }

        if (this.state.inMediaMode && this.state.galleryItemsValue.length == 0) {
            UIUtil.showInfo("No media selected for gallery")
            return;
        }

        if (this.state.galleryItemsValue !== undefined && this.state.galleryItemsValue.length > 10) {
            UIUtil.showError("Maximum gallery size exceeded (> 10 items)")
            return;
        }
        

        this.setState({posting: true});
        const listener = response => {
            if (response.status === true) {
                let items = this.state.items;

                items.unshift({
                    ...response.payload,
                    animate: true
                })
                if (this.state.newPostVideoUrl !== undefined) {
                    URL.revokeObjectURL(this.state.newPostVideoUrl);
                }
                this.setState({
                    items: items,
                    newPostContent: "",
                    newPostImage: undefined,
                    newPostVideo: undefined,
                    newPostVideoUrl: undefined,
                    newRating: 0,
                    posting: false,
                    inMediaMode: false,
                    galleryItemsValue: []
                })
            } else {
                UIUtil.showError();
                this.setState({
                    posting: false
                })
            }
        }
        if (this.props.ratingPosts) {
            Api.newReview(this.state.newPostContent, 0, POST_PARENT_TYPE_RATING, this.state.newPostImage, this.state.newPostVideo, this.state.galleryItemsValue, {
                itemType: this.props.ratingItemType,
                itemId: this.props.ratingItemId,
                value: this.state.newRating,
            }, listener)
        } else {
            (this.props.adminPosts ? Api.newAdminPost : Api.newPost).bind(Api)(this.state.newPostContent, 
                this.getParentId(), this.getParentType(), this.state.newPostImage, this.state.newPostVideo, this.state.galleryItemsValue, listener);
        }
    }

    getSelectedPostImageSrc() {
        const selectedPost = this.state.selectedPost;
        const selectedPostGallery = this.state.selectedPostGallery;

        if (selectedPost.postType === POST_TYPE_IMAGE) {
            return Api.getPostImage(selectedPost.id);
        } else if (selectedPost.postType === POST_TYPE_GALLERY) {
            if (selectedPostGallery !== undefined && selectedPostGallery.type == GALLERY_ITEM_TYPE_IMAGE) {
                return Api.getGalleryItem(selectedPostGallery.id);
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    getSelectedPostVideoComponent() {
        const selectedPost = this.state.selectedPost;
        const selectedPostGallery = this.state.selectedPostGallery;

        if (selectedPost.postType === POST_TYPE_VIDEO) {
            return (
                <video autoPlay controls src={Api.getPostVideo(selectedPost.id)} style={{maxHeight: '95%', maxWidth: '95%', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)',}} />
            )
        } else if (selectedPost.postType === POST_TYPE_GALLERY) {
            if (selectedPostGallery !== undefined && selectedPostGallery.type == GALLERY_ITEM_TYPE_VIDEO) {
                return (
                    <video autoPlay controls src={Api.getGalleryItem(selectedPostGallery.id)} style={{maxHeight: '95%', maxWidth: '95%', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)',}} />
                )
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    getMediaTitle() {
        const selectedPost = this.state.selectedPost;
        console.log(selectedPost.accountId)
        return selectedPost.accountId > 0 ? (
            <div style={{ height: '50px', display: 'flex', alignItems: 'center'}}>
                <ProfilePicture userId={selectedPost.accountId} size="medium" />
                <h2 style={{color: 'white', marginLeft: '25px', height: '35px', fontSize: '25px'}}>
                    {selectedPost.accountName}
                </h2>
            </div>
        ) : null
    }

    getMediaCaption() {
        return Util.isStringExists(this.state.selectedPost.postContent) ? this.state.selectedPost.postContent : null;
    }

    onPostItemDelete(postIndex) {
        let newItems = [...this.state.items];
        newItems.splice(postIndex, 1);
        this.setState({items: newItems})
    }

    render() {
        return (
            <div style={{width: '100%'}}>
                {this.props.canAdd ? (
                    this.state.featuredHashtags !== undefined ? (
                        <div style={{width: '100%', borderRadius: '15px', boxShadow: "0px 0px 25px 0px rgba(0, 0, 0, 0.2)"}}>
                            {this.props.ratingPosts && 
                            <div style={{display: 'flex', padding: '15px'}}>
                                <Button type="link" className="orange-link" style={{cursor: 'default'}}>Rating: </Button>
                                <Rate value={this.state.newRating} onChange={value => this.setState({newRating: value})} allowClear />
                            </div>}
                            {/* <TextArea maxLength={1000} rows={4} placeholder={"Write a" + (this.props.ratingPosts ? "n optional review" : " post") + "..."}
                                style={{ borderRadius: ((this.state.newPostImage !== undefined || this.state.newPostVideo !== undefined) ? '15px 15px 0px 0px' : '15px'), paddingTop: '15px'}} value={this.state.newPostContent}
                                onChange={(e) => this.setState({newPostContent: e.target.value})} /> */}
                            <Mentions 
                            prefix={["#"]}
                            filterOption={() => true}
                            maxLength={2500} rows={4} placeholder={"Write a" + (this.props.ratingPosts ? "n optional review" : " post") + "..."}
                            style={{ borderRadius: ((this.state.newPostImage !== undefined || this.state.newPostVideo !== undefined) ? '15px 15px 0px 0px' : '15px'), paddingTop: '5px',}} value={this.state.newPostContent}
                            onChange={(e) => this.setState({newPostContent: e}, () => {
                                // setTimeout(() => {
                                //     debugger
                                // }, 2500)
                            })}
                            >
                                {this.state.featuredHashtags.length > 0 && <>
                                    <p style={{pointerEvents: 'none', fontStyle: 'bolder', fontSize: 18, marginBottom: 2, marginTop: 2}}><FireFilled /> Trending</p>
                                    {this.state.featuredHashtags.map(hashtag => (
                                        <Mentions.Option key={hashtag} value={hashtag} style={{height: 60, display: 'flex', alignItems: 'center', gap: 10}}>
                                            <img src={Api.getHashtagThumbnail(hashtag)} style={{width: 45, height: 45, borderRadius: 5, objectFit: 'cover', objectPosition: 'center'}}
                                            onError={e => {
                                                e.target.onerror = null;
                                                e.target.src = HashtagCover;
                                            }}/>

                                            <p style={{fontSize: 14, color: 'black', margin: 0, opacity: 0.80, textOverflow: 'ellipsis', overflow: 'hidden', paddingRight: 5}}>#{hashtag}</p>
                                        </Mentions.Option>
                                    ))}
                                </>}
                            </Mentions>

                            {this.state.inMediaMode && <div style={{padding: 15, paddingBottom: 0}}>
                                <Upload
                                listType="picture-card"
                                fileList={this.state.galleryItemsValue.map(galleryItem => ({
                                    uid: Util.isStringExists(galleryItem.id) ? galleryItem.id : galleryItem.fileId,
                                    status: 'done',
                                    name: galleryItem.type === GALLERY_ITEM_TYPE_VIDEO ? "Video" : "Image",
                                    url: galleryItem.type === GALLERY_ITEM_TYPE_IMAGE ? (Util.isStringExists(galleryItem.id) ? Api.getGalleryItem(galleryItem.id) : galleryItem.fileUrl) : undefined
                                }))} 
                                showUploadList={{
                                    showPreviewIcon: false
                                }}
                                accept="image/gif,image/jpeg,image/png,video/mp4"
                                multiple={true}
                                onRemove={file => {
                                    for (let i = 0; i < this.state.galleryItemsValue.length; i++) {
                                        const galleryItem = this.state.galleryItemsValue[i];
                                        if ((Util.isStringExists(galleryItem.id) ? galleryItem.id : galleryItem.fileId) === file.uid) {
                                            if (galleryItem.fileUrl !== undefined) {
                                                URL.revokeObjectURL(galleryItem.fileUrl);
                                            }
                                            this.state.galleryItemsValue.splice(i, 1);
                                            break;
                                        }
                                    }
                                    this.setState({})
                                }}
                                beforeUpload={file => {
                                    this.state.galleryItemsValue.push({
                                        type: Util.getFileExt(file).toLowerCase() === "mp4" ? GALLERY_ITEM_TYPE_VIDEO : GALLERY_ITEM_TYPE_IMAGE,
                                        fileId: Util.newTempId(),
                                        file: file,
                                        fileUrl: URL.createObjectURL(file)
                                    })
                                    this.setState({})
                                    return false;
                                }}>
                                    <div>
                                        <PlusOutlined />
                                        <div className="ant-upload-text">Add</div>
                                    </div>
                                </Upload>
                            </div>}

                            {this.state.newPostImage !== undefined ? 
                                <img src={URL.createObjectURL(this.state.newPostImage)} style={{width: '100%', maxHeight: '450px', objectFit: 'cover'}} /> 
                            : null}

                            {this.state.newPostVideo !== undefined ? 
                                <video muted autoPlay playsInline src={this.state.newPostVideoUrl} style={{width: '100%', maxHeight: '450px', objectFit: 'cover'}} /> 
                            : null}

                            <div style={{display: 'flex', alignItems: 'center', padding: '15px', paddingTop: this.state.inMediaMode ? 0 : 15}}>
                                <div style={{width: '50%'}}>
                                    {(this.state.newPostImage === undefined && this.state.newPostVideo === undefined && !this.state.inMediaMode) ? (
                                    //!this.state.inMediaMode ? (
                                        <>
                                            <UploadWrapper id="post-image-input" accept="image/gif,image/jpeg,image/png" onChange={e => this.setState({newPostImage: e.target.files[0]})}>
                                                <Button disabled={this.state.posting}><CameraOutlined /></Button>
                                            </UploadWrapper>
                                            <UploadWrapper id="post-video-input" accept="video/mp4" onChange={e => this.setState({newPostVideo: e.target.files[0], newPostVideoUrl: URL.createObjectURL(e.target.files[0])})}>
                                                <Button style={{marginLeft: '15px'}} disabled={this.state.posting}><VideoCameraOutlined /></Button>
                                            </UploadWrapper>
                                            <Button style={{marginLeft: 15}} onClick={() => this.setState({inMediaMode: true, galleryItemsValue: []})}><AppstoreAddOutlined /></Button>
                                        </>
                                    ) : (
                                        <Button loading={this.state.posting} onClick={() => {
                                            //this.setState({newPostImage: undefined, newPostVideo: undefined, newPostVideoUrl: undefined})
                                            this.state.galleryItemsValue.forEach(item => {
                                                if (item.fileUrl !== undefined) {
                                                    URL.revokeObjectURL(item.fileUrl)
                                                }
                                            })
                                            this.setState({galleryItemsValue: [], inMediaMode: false, newPostImage: undefined, newPostVideo: undefined, newPostVideoUrl: undefined})
                                        }}><CloseOutlined /></Button>
                                    )}
                                </div>
                                <div style={{width: '50%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                                    <Button loading={this.state.posting} disabled={this.props.ratingPosts ? this.state.newRating === 0 : false} type="primary" size="large" style={{width: '100px'}} onClick={this.onPostBtn.bind(this)}>{(this.props.ratingPosts ? "Submit" : "Post")}</Button>
                                </div>
                            </div>
                            
                        </div>
                    ) : (
                        null
                    )
                ) : null}

                {this.props.canAdd ? <> <br /> <br /> </> : null}
                <InfiniteScroll
                    loadMore={this.getNextPosts.bind(this)}
                    hasMore={this.state.hasMore}
                    threshold={750}
                    loader={
                        <div style={{display: 'flex', justifyContent: 'center', marginTop: '45px', marginBottom: '45px'}}>
                            <Spin size="large" tip="Loading..." />
                        </div>
                    }
                    style={{overflow: 'visible'}}>

                    {this.state.items.map((item, index) => {
                        return ( 
                            <div key={item.id}>
                                {index > 0 ? <br /> : null}
                                {item.animate ? 
                                    (
                                        <motion.div initial={{ opacity: 0, y: -125 }} animate={{ opacity: 1, y: 0 }}>
                                            <PostItem onItemDeleted={() => this.onPostItemDelete(index)} realtimeUserStatus={this.props.realtimeUserStatus} canComment={this.props.ratingPosts !== undefined ? this.props.canReply : true} hideProfilePic={this.props.hideProfilePic} post={item} 
                                            onMediaClick={galleryItem => this.setState({selectedPost: item, selectedPostGallery:galleryItem })} adminPosts={this.props.adminPosts} />
                                        </motion.div> 
                                    ) : (
                                        <PostItem onItemDeleted={() => this.onPostItemDelete(index)} realtimeUserStatus={this.props.realtimeUserStatus} canComment={this.props.ratingPosts !== undefined ? this.props.canReply : true} hideProfilePic={this.props.hideProfilePic} post={item} 
                                        onMediaClick={galleryItem => this.setState({selectedPost: item, selectedPostGallery:galleryItem })} adminPosts={this.props.adminPosts} />
                                    )
                                }
                            </div>
                        )
                    })}
                </InfiniteScroll>

                {!this.state.loadingItems && !this.props.onePostMode ? 
                    <Empty style={{marginTop: '50px'}} image={Empty.PRESENTED_IMAGE_SIMPLE} 
                    description={this.state.items.length > 0 ? ("No more " + (this.props.ratingPosts ? "reviews" : "posts")) : (this.props.followingPosts ? 
                        (SessionManager.isLoggedInAsStudent() ? (
                            <span>
                                When you follow tutors and organizations you're interested in, you will see their posts here
                                <br />
                                Go to <Link to="/discovery">Discovery</Link> to find tutors and organizations to follow
                            </span>
                        ) : "When you follow other tutors and organizations, you will see their posts here")
                    : ("No " + (this.props.ratingPosts ? "reviews" : "posts")))} /> 
                : null}
                <br />

                {this.state.selectedPost !== undefined && (
                    <Lightbox
                        onAfterOpen={() => this.savedScroll = window.scrollY}
                        reactModalProps={{
                            onAfterClose: () => {
                                if (this.savedScroll !== undefined) {
                                    window.scrollTo(0, this.savedScroll)
                                }
                            }
                        }}
                        mainSrc={this.getSelectedPostImageSrc()}
                        mainCustomContent={this.getSelectedPostVideoComponent()}
                        imageTitle={this.getMediaTitle(this.state.mediaViewIndex)}
                        imageCaption={this.getMediaCaption(this.state.mediaViewIndex)}
                        onCloseRequest={() => this.setState({ selectedPost: undefined, selectedPostGallery: undefined })}
                    />
                )}
            </div>
        );
    }

}

export default PostsView;