
import Moment from 'moment';
import SessionManager from '../session/SessionManager';
import { v4 as uuidv4 } from 'uuid';

class Util {

    zeroPad(num, numZeros) {
        if (num == 0 || num == null || num == undefined) {
            let string = "";
            for (let i = 0; i < numZeros; i++) {
                string += "0";
            }
            return string;
        }

        // var an = Math.abs (num);
        // var digitCount = 1 + Math.floor (Math.log (an) / Math.LN10);
        // if (digitCount >= numZeros) {
        //     return num;
        // }
        // var zeroString = Math.pow (10, numZeros - digitCount).toString ().substr (1);
        // return num < 0 ? '-' + zeroString + an : zeroString + an;
        let n = Math.abs(num);
        let zeros = Math.max(0, numZeros - Math.floor(n).toString().length );
        let zeroString = Math.pow(10,zeros).toString().substr(1);
        if( num < 0 ) {
            zeroString = '-' + zeroString;
        }

        return zeroString+n;
    }

    stripArrayUndefined(array) {
        return array.filter(item => item !== undefined);
    }

    getDate(dateMilli) {
        let moment = Moment(new Date(dateMilli));

        let diffDay = Moment(new Date()).diff(moment, 'days');
        if (diffDay >= 1) {
            return moment.format('DD MMMM YYYY');  
        } else {
            return moment.format('HH:mm');  
        }
    }

    getFullDate(dateMilli) {
        return Moment(new Date(dateMilli)).format('DD MMMM YYYY HH:mm');
    }

    getDateOnly(dateMilli) {
        return Moment(new Date(dateMilli)).format('DD MMMM YYYY');
    }

    getTime(dateMilli) {
        return Moment(new Date(dateMilli)).format('HH:mm');
    }

    getProfilePath(accountId, tab) {
        if (accountId === undefined || (SessionManager.isLoggedIn() && SessionManager.getAccount().id === accountId)) {
            return "/my-profile" + (tab !== undefined ? "?tab=" + tab : "");;
        } else {
            return "/profile/" + accountId + (tab !== undefined ? "?tab=" + tab : "");
            //return "/profile?tutorId=" + accountId;
        }
    }

    getLiveStreamPath(liveStreamId) {
        /*if (SessionManager.isLoggedInAsTutor()) {
            return "/live-stream-broadcaster/" + liveStreamId;
        } else {
            return "/live-stream-receiver/" + liveStreamId;
        }*/
        return "/live-stream/" + liveStreamId;
    }

    getHashLiveStreamPath(hashId) {
        return "/lsnx-re/" + hashId;
    }

    getBlogPostPath(my, title, date) {
        //REMEMBER MYBLOGSPAGE.js history push
        if (my) {
            return "/my-blog?t=" + encodeURIComponent(title) + "&d=" + date;
        } else {
            return "/blog-post?t=" + encodeURIComponent(title) + "&d=" + date;
        }
    }

    formatSeconds(duration) {
        // Hours, minutes and seconds
        var hrs = ~~(duration / 3600);
        var mins = ~~((duration % 3600) / 60);
        var secs = ~~duration % 60;

        // Output like "1:01" or "4:03:59" or "123:03:59"
        var ret = "";

        if (hrs > 0) {
            ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
        }

        ret += "" + mins + ":" + (secs < 10 ? "0" : "");
        ret += "" + secs;
        return ret;
    }

    formatSecondsBrief(duration) {
        // Hours, minutes and seconds
        var hrs = ~~(duration / 3600);
        var mins = ~~((duration % 3600) / 60);
        var secs = ~~duration % 60;

        if (hrs > 0) {
            return hrs + "h";
        } else if (mins > 0) {
            return mins + "m";
        } else if (secs > 0) {
            return secs + "s";
        } else {
            return "0";
        }
    }

    formatBytes(bytes) {
        if (bytes === 0) return '0 Bytes';
    
        const k = 1024;
        const dm = 0;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    
        const i = Math.floor(Math.log(bytes) / Math.log(k));
    
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    isStringExists(string) {
        return string !== undefined && string !== null && string.trim !== undefined && string.trim() !== "";
    }

    isNumberExist(number) {
        return number !== undefined && number !== null && !isNaN(number) && number > 0;
    }

    isBooleanExist(boolean) {
        return boolean === true || boolean === false;
    }

    existString(item) {
        return item === null || item === undefined ? "" : item;
    }

    existNumber(item) {
        return item === null || item === undefined ? 0 : item;
    }

    newTempId() {
        return uuidv4()
    }

    safeGetProperty(object, property, def) {
        if (object[property] !== undefined) {
            return object[property];
        } else {
            return def;
        }
    }

    arrayEqual(a, b) {
        if (a.length != b.length) {
            return false;
        }

        for (let i = 0; i < a.length; i++) {
            if (a[i] !== b[i]) {
                return false;
            }
        }

        return true;
    }

    getFileExt(file) {
        return file.name.split(".").pop();
    }

    getDaysLeft(daysLeft) {
        if (daysLeft >= 1) {
            return daysLeft + ' day' + (daysLeft > 1 && 's') + ' left';
        } else {
            return "Less than a day left";
        }
    }

}

export default new Util();