import { WHITEBOARD_HEIGHT, WHITEBOARD_WIDTH } from "../windows/LiveStreamWindow";

class CanvasStream {

    setBackground(stream) {
        if (stream !== undefined) {
            const video = document.createElement("video");
            video.width = WHITEBOARD_WIDTH;
            video.height = WHITEBOARD_HEIGHT;
            video.srcObject = stream;
            video.muted = true;
            
            video.play();

            this.background = video;
        } else {
            this.background = undefined;
        }
    }

    startStream(listener) {
        const streamCanvas = document.createElement("canvas");
        streamCanvas.width = WHITEBOARD_WIDTH;
        streamCanvas.height = WHITEBOARD_HEIGHT;

        this.stream = streamCanvas.captureStream(25);
        this.interval = setInterval(() => {
            if (streamCanvas.getContext) {
                const context = streamCanvas.getContext('2d');
                context.clearRect(0, 0, WHITEBOARD_WIDTH, WHITEBOARD_HEIGHT);

                context.fillStyle = "#ffffff";
                context.fillRect(0, 0, WHITEBOARD_WIDTH, WHITEBOARD_HEIGHT);

                if (this.background !== undefined) {
                    context.drawImage(this.background, 0, 0, WHITEBOARD_WIDTH, WHITEBOARD_HEIGHT);
                }

                context.drawImage(this.canvas, 0, 0);
            }
        }, 1000 / 25);

        /*this.stream = this.canvas.captureStream(25);
        this.interval = setInterval(() => {
            if (this.canvas.getContext) {
                const context = this.canvas.getContext("2d");
                context.fillStyle = "#00000000";
                context.fillRect(0, 0, 1, 1);
            }
        }, 1000 / 5);*/

        if (this.stream !== undefined && this.stream !== null) {
            listener(true)
        } else {
            listener(false);
        }
    }

    closeStream() {
        if (this.stream !== undefined) {
            this.stream.getTracks().forEach(track => {
                track.stop();
            });
        }
        if (this.interval !== undefined) {
            clearInterval(this.interval);
        }
    }

}

export default CanvasStream;