import React from 'react';
import Page from '../base/Page';
import { Button, Radio, Input, Spin, Empty, Checkbox } from 'antd';

import { 
    PlusOutlined,
    UnorderedListOutlined,
    AppstoreOutlined,
    LockOutlined
} from '@ant-design/icons';
import Modal from 'antd/lib/modal/Modal';
import Api from '../session/Api';
import { withRouter } from 'react-router-dom';
import TutorCourseItem from '../views/courses/TutorCourseItem';

import InfiniteScroll from 'react-infinite-scroller';
import { NO_PAGE_TOKEN, PAGINATION_PAGE_CONTENT_COUNT } from '../Constants';
import UIUtil from '../util/UIUtil';
import withMediaQuery from '../components/WithMediaQuery';
import Util from '../util/Util';
import { CATEGORIES } from '../Categories';
import { Select } from 'antd';

const { Option } = Select;

class TutorMyCoursesPage extends Page {

    constructor(props) {
        super(props)

        this.state = {
            ...this.state,
            createCourse: false,
            createCourseTitle: "",
            createCourseCategory: 0,
            createCoursePrivate: false,
            creatingCourse: false,

            items: [],
            page: NO_PAGE_TOKEN,
            hasMore: true,
            loadingItems: false,
        }
    }

    getNextCourses() {
        if (this.state.loadingItems) {
            return;
        }

        this.setState({
            loadingItems: true
        })
    
        //list error
        const listener = response => {
            if (response.status === true) {
                let items = this.state.items;
                items.push(...response.payload.items);

                this.setState({
                    items: items,
                    page: response.payload.token,
                    hasMore: Util.isStringExists(response.payload.token),
                    loadingItems: false
                })
            } else {
                //alert('errowr');
            }
        };

        if (this.props.organization !== undefined) {
            Api.getMyTutorOrganizationCourses(this.props.organization.id, this.state.page, listener);
        } else {
            Api.getMyTutorCourses(this.state.page, listener);
        }
    }

    onCreateCourse() {
        this.setState({creatingCourse: true});
        Api.createCourse(this.state.createCourseTitle, this.state.createCourseCategory, this.state.createCoursePrivate, response => {
            if (response.status === true) {
                this.setState({
                    createCourse: false, 
                    createCourseTitle: "",
                    createCourseCategory: 0,
                    createCoursePrivate: false,
                    creatingCourse: false
                })
                this.props.history.push("/my-course/" + response.payload + '?tab=content');
            } else {
                this.setState({creatingCourse: false})
                UIUtil.showError();
            }
        })
    }

    render() {
        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                {/*<div style={{width: this.props.renderFullWidth ? '90vw' : '75vw', paddingTop: '25px', paddingBottom: '25px'}}>*/}
                <div style={{width: '75vw', paddingTop: this.props.organization === undefined && '25px', paddingBottom: '25px'}}>
                    {this.props.organization === undefined && (<> 
                        <div style={{display: 'flex', alignItems: 'center'}}>
                        {/*<Radio.Group value={"grid"} style={{width: '200px'}}>
                            <Radio.Button value="grid"><AppstoreOutlined /></Radio.Button>
                            <Radio.Button value="list"><UnorderedListOutlined /></Radio.Button>
                        </Radio.Group>*/}
                        {/*<h2 style={{height: '25px'}}>My Courses</h2>*/}
                        <h2 style={{height: '25px'}}>Courses</h2>
                        <div style={{flex: '1'}} />
                        <Button type="link" onClick={e => this.setState({createCourse: true})}>
                            <PlusOutlined /> Create Course
                        </Button>
                        </div>
                        <br />
                    </>)}
                    
                    <InfiniteScroll
                        loadMore={this.getNextCourses.bind(this)}
                        hasMore={this.state.hasMore}
                        threshold={750}
                        loader={
                            <div style={{display: 'flex', justifyContent: 'center', marginTop: '45px', marginBottom: '45px'}}>
                                <Spin size="large" tip="Loading..." />
                            </div>
                        }
                        style={{overflow: 'visible'}}>

                        {this.state.items.map((item) => {
                            return ( 
                                <div key={item.id}>
                                    <br />
                                    <TutorCourseItem course={item} organization={this.props.organization} />
                                </div>
                            )
                        })}
                    </InfiniteScroll>

                    {!this.state.loadingItems ? <Empty style={{marginTop: '50px'}} image={Empty.PRESENTED_IMAGE_SIMPLE} description={this.state.items.length > 0 ? "No more courses" : "No courses"} /> : null}
                    <br />
                </div>

                <Modal
                    title="Create Course"
                    visible={this.state.createCourse}
                    okText="Create"
                    confirmLoading={this.state.creatingCourse}
                    okButtonProps={{disabled: this.state.createCourseTitle.trim() === ""}}
                    onOk={() => this.onCreateCourse()}
                    onCancel={() => this.setState({createCourse: false, createCourseTitle: "", createCourseCategory: 0, createCoursePrivate: false})}
                    >
                        
                        <h5>Title</h5>
                        <Input className="message-input" placeholder="Title" size="large" value={this.state.createCourseTitle} onChange={e => this.setState({createCourseTitle: e.target.value})} 
                        onPressEnter={() => this.onCreateCourse()}/>

                        <h5 style={{marginTop: 15}}>Category</h5>
                        <Select value={this.state.createCourseCategory} onChange={value => this.setState({createCourseCategory: value})} style={{ width: '100%' }} size="large">
                            {CATEGORIES.map(category => 
                                <Option value={category.id}>{category.icon} &nbsp;&nbsp;{category.title}</Option>)}
                        </Select>
                    
                        <Checkbox checked={this.state.createCoursePrivate} onChange={e => this.setState({createCoursePrivate: e.target.checked})} style={{marginTop: '15px'}}><LockOutlined /> Private</Checkbox>
                </Modal>
            </div>
        );
    }

}

export default withMediaQuery(withRouter(TutorMyCoursesPage));