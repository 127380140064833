import { Tabs } from 'antd';
import React from 'react'
import Page from '../../base/Page';
import withMediaQuery from '../../components/WithMediaQuery';
import { STATEMENTS_DATA_SOURCE_ALL, STATEMENTS_DATA_SOURCE_EARNINGS, STATEMENTS_DATA_SOURCE_PURCHASES, STATEMENTS_DATA_SOURCE_WITHDRAWALS } from '../../Constants';
import AllStatementsTableView from '../../views/accounting/AllStatementsTableView';

import {
    DollarCircleFilled,
    BankFilled,
    HistoryOutlined,
    LineChartOutlined,
    CreditCardFilled,
    HourglassOutlined,
    CalendarFilled,
    BulbFilled,
    CloseCircleOutlined,
    FieldTimeOutlined,
    ProfileFilled,
    TableOutlined,
    ClockCircleOutlined,
    QuestionCircleOutlined,
    UnorderedListOutlined,
    DownloadOutlined,
    DownCircleFilled,
    LoadingOutlined,
    MailFilled
} from '@ant-design/icons'

class AllTransactionsPage extends Page {

    render() {
        return (
            <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                <div style={{width: this.props.isSmall ? '90vw' : '75vw', paddingTop: '25px', paddingBottom: '25px'}}>
                    <Tabs //key={this.state.statmentsKey} 
                    defaultActiveKey="s" size="large" className="left-aligned-tabs" style={{marginTop: 0}}>
                        <Tabs.TabPane key="s" tab={<><ProfileFilled /> Statement</>}>
                            <AllStatementsTableView key="statments-table" dataSource={STATEMENTS_DATA_SOURCE_ALL} />
                        </Tabs.TabPane>
                        <Tabs.TabPane key="e" tab={<><DollarCircleFilled /> Earnings</>}>
                            <AllStatementsTableView key="earnings-table" dataSource={STATEMENTS_DATA_SOURCE_EARNINGS} />
                        </Tabs.TabPane>
                        <Tabs.TabPane key="p" tab={<><CreditCardFilled /> Purchases</>}>
                            <AllStatementsTableView key="earnings-table" dataSource={STATEMENTS_DATA_SOURCE_PURCHASES} />
                        </Tabs.TabPane>
                        <Tabs.TabPane key="w" tab={<><DownCircleFilled /> Withdrawals</>}>
                            <AllStatementsTableView key="withdrawals-table" dataSource={STATEMENTS_DATA_SOURCE_WITHDRAWALS} />
                        </Tabs.TabPane>
                    </Tabs>
                </div>
            </div>
        )
    }

}

export default withMediaQuery(AllTransactionsPage);