import React, { useState } from 'react'
import { withRouter } from 'react-router-dom';

import {
    TeamOutlined,
    UserOutlined,
    QuestionCircleOutlined,
    DashboardOutlined,
    DashboardFilled,
    CreditCardOutlined,
    ClockCircleFilled,
    DollarCircleFilled,
    ClockCircleOutlined,
    CheckCircleOutlined,
    DollarCircleOutlined,
    PieChartOutlined,
    WarningTwoTone,
    ReloadOutlined
} from '@ant-design/icons'
import Util from '../../util/Util';

import { Tag, Tooltip, Button, Spin } from 'antd';
import Page from '../../base/Page';
import Api from '../../session/Api';

function daysInThisMonth() {
    var now = new Date();
    return new Date(now.getUTCFullYear(), now.getUTCMonth()+1, 0).getUTCDate();
}  

const BillingItem = ({openByDefault, bill}) => {
    const [expanded, setExpanded] = useState(openByDefault);

    return (
        <div style={{transition: '250ms', height: expanded ? 190 + 75 : 75, overflow: 'hidden',
        background: 'white', marginBottom: '15px', boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)", borderRadius: '5px'}}>
            <div style={{height: 75, display: 'flex', flex: 1, alignItems: 'center', padding: '15px', cursor: 'pointer' }} onClick={() => setExpanded(!expanded)}>
                <div style={{width: 175}}>
                    {bill.paid ? (
                        <h3 style={{margin: 0, opacity: 1}}>Bill {bill.paid ? ('#' + bill.billId) : 'Next Bill'}</h3>
                    ) : (
                        <h3 style={{margin: 0, opacity: 1}}>Projected Bill</h3>
                    )}
                    <p style={{margin: 0, fontSize: 12}}>{bill.month}</p>
                </div>

                {bill.nextMonth ? (
                    <Tag color="default" icon={<ClockCircleOutlined />}>Next Month Projection</Tag>
                ) : bill.paid ? (
                    <Tag color="green" icon={<CheckCircleOutlined />}>Paid</Tag>
                ) : (
                    <Tag color="red" icon={<DollarCircleOutlined />}>Payment due</Tag>
                )}

                {/* {!bill.nextMonth && !bill.paid &&
                <p style={{margin: 0, marginLeft: 15, color: 'red'}}><strong>Four</strong> days remaining before user access deactivation.</p>} */}

                <div style={{flex: 1}} />

                <h2 style={{margin: 0, opacity: 1}}>${bill.amount.toFixed()}</h2>
            </div>
            <div style={{height: 115, paddingLeft: '15px', paddingBottom: 15, paddingRight: 15 }} >
                <div style={{height: 1, marginBottom: 15, width: '100%', background: 'black', opacity: 0.10,}} />
                <h4 style={{margin: 0}}>
                    <PieChartOutlined /> Breakdown
                    <Tooltip color="purple" title={<p>
                        Pay-as-you-go users billed for the coming month. {!bill.paid && "The month's live stream credits will be activated after bill payment"}
                    </p>}><Button style={{marginBottom: 0}} type="link"><QuestionCircleOutlined /></Button></Tooltip>
                </h4>
                <div style={{display: 'flex', alignItems: 'flex-end'}}>
                
                    <h2 style={{marginRight: 20}}>
                        for
                    </h2>
                    
                    <h1 style={{fontWeight: 'bolder', fontSize: 44, margin: 0, color: '#4e2e89'}}>
                        {bill.users}
                    </h1>
                    <h2 style={{marginLeft: 10}}>
                        users
                    </h2>

                    <div style={{paddingLeft: 20, paddingRight: 20}}>
                        <p style={{fontSize: 44, margin: 0, color: 'black', opacity: 0.65}}>
                            +
                        </p>
                    </div>

                    <h1 style={{fontWeight: 'bolder', fontSize: 44, margin: 0, color: '#990000'}}>
                        {bill.liveStreamMinute}
                    </h1>
                    <h2 style={{marginLeft: 10}}>
                        <strong>FREE</strong> live stream minutes
                    </h2>
                </div>
            </div>
            <div style={{height: 75, paddingLeft: '15px', paddingBottom: 15, paddingRight: 15, paddingTop: 15, background: '#f2f2f2',
        display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div>
                    <h4 style={{margin: 0}}><DollarCircleOutlined /> Price</h4>
                    <p style={{margin: 0}}>
                        {bill.users} user(s) * ${bill.userPrice.toFixed(2)} per user = <strong style={{color: 'black'}}>${bill.amount.toFixed()}</strong>
                    </p>
                </div>
                <div style={{flex: 1}} />

                {bill.nextMonth ? (
                    <Button icon={<CreditCardOutlined />} type="primary" size="large" disabled>Pay in {(daysInThisMonth() - new Date().getUTCDate()) + 1} days</Button>
                ) : bill.paid ? (
                    <p><strong style={{color: 'green'}}>Paid!</strong> Transaction ID: {bill.transactionId}</p>
                ) : (
                    <Button icon={<CreditCardOutlined />} type="primary" size="large">Pay</Button>
                )}
            </div>
        </div>
    )
}

class BillingPage extends Page {

    constructor(props) {
        super(props);
        this.now = new Date().getTime();

        this.state = {
            loading: true,
            error: false,
            bills: null
        }
    }

    onPageStart() {
        this.setState({loading: true})
        Api.getBills(response => {
            this.setState({loading: false, error: response.payload === null, bills: response.payload})
        })
    }

    render() {
        if (this.state.loading) {
            return (
                <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingTop: 45, paddingBottom: 45}}>
                    <Spin size="large" tip="Loading..." />
                </div>
            )
        }

        if (this.state.error) {
            return (
                <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingTop: 45, paddingBottom: 45}}>
                    

                    <WarningTwoTone twoToneColor="#f26622" style={{fontSize: 80}} />
                    <p style={{marginTop: 45, opacity: 0.65}}>An error occurred. Please try again later.</p>

                    <Button icon={<ReloadOutlined />} onClick={() => window.location.reload()}>Reload</Button>
                </div>
            )

        }

        return (
            <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                <div style={{width: this.props.isSmall ? '90vw' : '75vw', paddingTop: '25px', paddingBottom: '25px'}}>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <h1>
                            <CreditCardOutlined /> Billing

                            <Tooltip color="purple" title={<p>
                                Pay-as-you-go user allowance bills.
                            </p>}><Button size="large" type="link"><QuestionCircleOutlined /></Button></Tooltip>
                        </h1>
                        <div style={{flex: 1}} />
                        <p>{Util.getDateOnly(this.now)}</p>
                    </div>

                    <div style={{height: 25}} />
                    <div>
                        {this.state.bills.map((bill, index) => <BillingItem bill={bill} openByDefault={index == 0} />)}

                        {/* <BillingItem bill={{billId: "389hdD2", nextMonth: true}} />
                        <BillingItem bill={{billId: "h4kD212"}} openByDefault />
                        <BillingItem bill={{billId: "983FW21", paid: true}} />
                        <BillingItem bill={{billId: "9128392", paid: true}} /> */}
                    </div>
                </div>
            </div>
        )
    }

}

export default withRouter(BillingPage);