import React from 'react';
import './chat-view.css';

import { Layout, Input, Button, Avatar, Tag, Spin, message, Popover, Divider, PageHeader } from 'antd';

import { motion } from "framer-motion"

import { 
    CameraOutlined,
    SendOutlined,
    PaperClipOutlined,
    AudioOutlined,
    CheckCircleFilled,
    CloseCircleFilled,
    VideoCameraOutlined,
    PlusCircleOutlined,
    SmileOutlined
} from '@ant-design/icons';
import PostItem from '../posts/PostItem';
import ChatBubble from './ChatBubble';
import ProfilePicture from '../../components/ProfilePicture';
import Api from '../../session/Api';
import ReactStopwatch from 'react-stopwatch';
import MicRecorder from 'mic-recorder-to-mp3';
import { MESSAGE_ATTACHMENT_TYPE_AUDIO, MESSAGE_TYPE_ATTACHMENT, MESSAGE_TYPE_TEXT, MESSAGE_ATTACHMENT_TYPE_IMAGE, MESSAGE_ATTACHMENT_TYPE_VIDEO, MESSAGE_ATTACHMENT_TYPE_FILE, SOCKET_EVENT_CHAT, ACCOUNT_ROLE_TUTOR, ACCOUNT_ROLE_STUDENT, ACCOUNT_ROLE_ORGANIZATION } from '../../Constants';
import UploadWrapper from '../../components/UploadWrapper';
import Modal from 'antd/lib/modal/Modal';
import Util from '../../util/Util';

import Lightbox from '../../components/media-list-viewer/react-image-lightbox';
import '../../components/media-list-viewer/lightbox-style.css';
import AttachmentViewer from './AttachmentViewer';
import FileCard from '../../components/FileCard';
import SessionManager from '../../session/SessionManager';
import { Prompt, Link } from 'react-router-dom';
import SocketSession from '../../session/SocketSession';

import StudentProfileDialog from '../../components/student-profile-dialog/StudentProfileDialog'
import UIUtil from '../../util/UIUtil';

import Picker from 'emoji-picker-react';


const { Header, Footer, Sider, Content } = Layout;

export const getMessageTextForRoom = (message) => {
    //REMEMBER LIVE STREAM WINDOW
    let latestMessageText = "";

    if (message.type == MESSAGE_TYPE_TEXT) {
        latestMessageText = message.message.length > 40 ? (message.message.substring(0, 37) + "...") : message.message;
    } else if (message.type == MESSAGE_TYPE_ATTACHMENT) {
        switch (message.messageAttachment.attachmentType) {
            case MESSAGE_ATTACHMENT_TYPE_AUDIO:
                latestMessageText = "Voice Message";
                break;

            case MESSAGE_ATTACHMENT_TYPE_IMAGE:
                latestMessageText = "Image";
                break;

            case MESSAGE_ATTACHMENT_TYPE_VIDEO:
                latestMessageText = "Video";
                break;

            case MESSAGE_ATTACHMENT_TYPE_FILE:
                latestMessageText = "File";
                break;

            default:
                latestMessageText = "Attachment";
                break;
        }

        if (Util.isStringExists(message.message)) {
            latestMessageText = latestMessageText + ": " + (message.message.length > 40 ? (message.message.substring(0, 37) + "...") : message.message)
        }
    }

    return latestMessageText;
}

class ChatView extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            loadingMessages: false,
            messages: [],
            messagesInLimbo: [],
            sendMessageValue: "",
            attachmentCaptionValue: "",
            loadingAudio: false,
            recordingVoiceMessage: false,
            imageAttachment: undefined,
            videoAttachment: undefined,
            videoAttachmentUrl: undefined,
            fileAttachment: undefined,

            messageMedias: undefined,
            mediaViewIndex: undefined,

            showLearnerProfile: false,

            emojiPickerVisible: false,
            emojiPickerInited: false
        }

        this.messagesEndRef = React.createRef();
    }

    onMessageListener(message) {
        this.addMessage(message, true);
        if (message.userId != SessionManager.getAccount().id) {
            Api.seenMessage(message.id);
        }
    }
    chatEventListener = this.onMessageListener.bind(this);

    componentDidMount() {
        SocketSession.registerListener(SOCKET_EVENT_CHAT, this.chatEventListener);

        window.onbeforeunload = e => {
            if (this.state.messagesInLimbo.length > 0) {
                e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
                // Chrome requires returnValue to be set
                e.returnValue = '';
            } else {
                delete e['returnValue'];
            }
        };

        this.loadMessages();

        this.emojiPickerInitTimeout = setTimeout(() => this.setState({emojiPickerInited: true}), 3000);
    }

    componentWillUnmount() {
        SocketSession.unregisterListener(SOCKET_EVENT_CHAT, this.chatEventListener);
        window.onbeforeunload = null;

        clearTimeout(this.emojiPickerInitTimeout)
    }

    componentDidUpdate(prevProps) {
        if (!this.props.liveStream) {
            if (this.props.room !== prevProps.room) {
                this.loadMessages();
            }
        }
    }

    loadMessages() {
        if (this.props.skipLoad) {
            return;
        }

        if (!this.props.liveStream) {
            if (this.props.room === undefined) {
                return;
            }
        }

        this.setState({
            loadingMessages: true
        });
        //list error
        const listener = response => {
            if (response.status === true) {
                this.setState({
                    loadingMessages: false,
                    messages: response.payload
                }, () => this.scrollToBottom(true));
            } else {
                //alert('errowr');
            }
        }

        if (this.props.liveStream) {
            Api.getLiveStreamMessages(this.props.roomParentId, listener)
        } else {
            Api.getMessages(this.props.room.id, listener);
        }
    }

    sendMessage(messageContent, attachmentType, attachment) {
        const isAttachment = attachmentType !== undefined;

        const message = {
            tempId: Util.newTempId(),
            message: messageContent,
            roomId: this.props.liveStream ? 0 : this.props.room.id,
            date: new Date().getTime(),
            self: true,
            userFullName: this.props.liveStream ? SessionManager.getAccount().fullName : undefined,
            type: isAttachment ? MESSAGE_TYPE_ATTACHMENT : MESSAGE_TYPE_TEXT,
            messageAttachment: isAttachment ? {
                attachmentType: attachmentType,
                blob: attachment,
                uploadProgress: 0
            } : undefined
        }
        const room = this.props.room;

        this.addMessage(message, true);
        const listener = response => {
            if (response.status === true) {
                if (room !== undefined && room.id === null) {
                    room.id = response.payload.roomId;
                    if (this.props.onRoomUpdated !== undefined) {
                        this.props.onRoomUpdated(room);
                    }
                }

                const index = this.state.messagesInLimbo.indexOf(message);
                if (index != -1) {
                    this.state.messagesInLimbo.splice(index, 1);
                    this.setState({
                        messagesInLimbo: this.state.messagesInLimbo
                    })
                }

                this.addMessage(response.payload, false);
            } else {
                message.id = -1;
                this.setState({messages: this.state.messages})
            }
        };

        if (isAttachment) {
            const startTime = new Date().getTime();
            Api.newAttachedMessage(message.tempId, this.props.roomParentType, this.props.roomParentId, this.props.roomSecondaryParentId, this.props.liveStream ? 0 : this.props.roomOtherMemberId, message.message, attachmentType, attachment, listener, progress => {
                //if (((new Date().getTime() - startTime) % 250 === 0) || progress == 100) {
                    //^^ does not need throttling since progress is not based on download with s3 now, fix it later
                    message.messageAttachment.uploadProgress = progress;
                    this.setState({messagesInLimbo: this.state.messagesInLimbo})
                //}
            });
        } else {
            Api.newMessage(message.tempId, this.props.roomParentType, this.props.roomParentId, this.props.roomSecondaryParentId, this.props.liveStream ? 0 : this.props.roomOtherMemberId, message.message, listener);
        }
    }

    addMessage(message, scroll) {
        for (let i = 0; i < this.state.messages.length + this.state.messagesInLimbo.length; i++) {
            const element = i < this.state.messages.length ? this.state.messages[i] : this.state.messagesInLimbo[i - this.state.messages.length];
            if (element.id === message.id || ((Util.isStringExists(element.tempId) && Util.isStringExists(message.tempId)) ? element.tempId === message.tempId : false)) {
                return;
            }
        }

        const exists = Util.isStringExists(message.id);
        const inRoom = this.props.room !== undefined && this.props.room.id === message.roomId;
        const property = exists ? 'messages' : 'messagesInLimbo';
        
        if (inRoom || this.props.liveStream) {
            this.state[property].push(message);
            this.setState({
                [property]: this.state[property]
            }, scroll ? this.scrollToBottom : undefined);
        }

        if (exists && this.props.onRoomUpdated !== undefined) {
            const room = inRoom ? this.props.room : this.props.getRoom(message.roomId);

            if (room !== undefined) {
                room.latestMessageText = getMessageTextForRoom(message);
                room.latestMessageDate = message.date;
                this.props.onRoomUpdated(room);
            }
        }
    }

    removeMessage(message) {
        const index = this.state.messages.indexOf(message);
        if (index != -1) {
            this.state.messages.splice(index, 1);
            this.setState({});
        }
    }

    onSendMessageBtn() {
        if (this.state.sendMessageValue.trim() === "") {
            return;
        }
        this.sendMessage(this.state.sendMessageValue);
        this.setState({sendMessageValue: ""})
    }

    resendMessage(message) {
        const index = this.state.messagesInLimbo.indexOf(message);
        if (index != -1) {
            this.state.messagesInLimbo.splice(index, 1);
        }
        this.sendMessage(message.message, message.messageAttachment !== undefined ? message.messageAttachment.attachmentType : undefined, message.messageAttachment !== undefined ? message.messageAttachment.blob : undefined);
    }

    onStartVoiceRecorderBtn() {
        this.setState({loadingAudio: true})
        this.voiceRecorder = new MicRecorder({
            bitRate: 128
        });
        

        this.voiceRecorder.start().then(() => {
            this.setState({
                loadingAudio: false,
                recordingVoiceMessage: true,
            })
        }).catch(e => {
            this.setState({
                loadingAudio: false
            })
            UIUtil.showError()
        })
    }

    onCancelVoiceRecorderBtn() {
        this.setState({recordingVoiceMessage: false});
        if (this.voiceRecorder !== undefined) {
            this.voiceRecorder.stop();
            this.voiceRecorder = undefined;
        }
    }

    onSendImageBtn() {
        const attachment = this.state.imageAttachment;
        const caption = this.state.attachmentCaptionValue;
        this.setState({imageAttachment: undefined, attachmentCaptionValue: ""});
        this.sendMessage(caption, MESSAGE_ATTACHMENT_TYPE_IMAGE, attachment);
    }

    onSendVideoBtn() {
        const attachment = this.state.videoAttachment;
        const caption = this.state.attachmentCaptionValue;
        this.setState({videoAttachment: undefined, videoAttachmentUrl: undefined, attachmentCaptionValue: ""});
        this.sendMessage(caption, MESSAGE_ATTACHMENT_TYPE_VIDEO, attachment);
    }

    onSendFileBtn() {
        const attachment = this.state.fileAttachment;
        const caption = this.state.attachmentCaptionValue;
        this.setState({fileAttachment: undefined, attachmentCaptionValue: ""});
        this.sendMessage(caption, MESSAGE_ATTACHMENT_TYPE_FILE, attachment);
    }

    onSendVoiceRecordingBtn() {
        this.setState({
            recordingVoiceMessage: false,
            loadingAudio: this.voiceRecorder !== undefined
        });
        if (this.voiceRecorder !== undefined) {
            this.voiceRecorder.stop().getMp3().then(([buffer, blob]) => {
                this.setState({
                    loadingAudio: false
                });
                this.voiceRecorder = undefined;

                this.sendMessage("", MESSAGE_ATTACHMENT_TYPE_AUDIO, blob);
            }).catch((e) => {
                //alert('We could not retrieve your message');
                //console.log(e);
                this.setState({
                    loadingAudio: false
                })
                UIUtil.showError();
            })
        }
    }

    scrollToBottom(skipAnimation) {
        this.messagesEndRef.current.scrollIntoView({ behavior: skipAnimation ? 'auto' : 'smooth' })
    }

    onStartedPlayingAudio(messageAudio) {
        const voiceMessages = this.state.messages.filter(message => message.type === MESSAGE_TYPE_ATTACHMENT && message.messageAttachment.attachmentType === MESSAGE_ATTACHMENT_TYPE_AUDIO);

        for (let i = 0; i < voiceMessages.length; i++) {
            const message = voiceMessages[i];
            if (message !== messageAudio) {
                message.requestStop = true;
            }
        }

        this.setState({messages: this.state.messages})
    }

    onDonePlayingAudio(message) {
        const messageIndex = this.state.messages.indexOf(message);
        if (messageIndex === -1) {
            return;
        }

        let nextMessage = null;
        for (let i = messageIndex + 1; i < this.state.messages.length; i++) {
            const message = this.state.messages[i];
            if (message.type === MESSAGE_TYPE_ATTACHMENT && message.messageAttachment.attachmentType === MESSAGE_ATTACHMENT_TYPE_AUDIO) {
                nextMessage = message;
                break;
            } else {
                continue;
            }
        }

        if (nextMessage != null) {
            nextMessage.requestPlay = true;
            this.setState({messages: this.state.messages})
        }
    }

    openMediaViewer(startingMessage) {
        const messageMedias = this.state.messages.filter(message => message.type === MESSAGE_TYPE_ATTACHMENT && 
            (message.messageAttachment.attachmentType === MESSAGE_ATTACHMENT_TYPE_IMAGE || message.messageAttachment.attachmentType === MESSAGE_ATTACHMENT_TYPE_VIDEO));
        const startingIndex = messageMedias.indexOf(startingMessage);

        this.setState({
            messageMedias: messageMedias,
            mediaViewIndex: startingIndex != -1 ? startingIndex : 0
        })
    }

    getMediaImageSrc(index) {
        const message = this.state.messageMedias[index];
        if (message !== undefined && message.messageAttachment.attachmentType === MESSAGE_ATTACHMENT_TYPE_IMAGE) {
            return Api.getMessageAttachment(message.messageAttachment.id);
        } else {
            return null;
        }
    }

    getMediaVideoComponent(index) {
        const message = this.state.messageMedias[index];
        if (message.messageAttachment.attachmentType === MESSAGE_ATTACHMENT_TYPE_VIDEO) {
            //return Api.getMessageAttachment(message.attachmentId);
            return (
                <video autoPlay controls src={Api.getMessageAttachment(message.messageAttachment.id)} style={{maxHeight: '95%', maxWidth: '95%', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)',}} />
            )
        } else {
            return null;
        }
    }

    getMediaTitle(index) {
        const message = this.state.messageMedias[index];
        return (
            <div style={{ height: '50px', display: 'flex', alignItems: 'center'}}>
                <ProfilePicture userId={message.self ? undefined : message.userId} size="medium" />
                <h2 style={{color: 'white', marginLeft: '25px', height: '35px', fontSize: '25px'}}>
                    {message.self ? SessionManager.getAccount().fullName : (this.props.liveStream ? message.userFullName : this.props.room.otherMember.fullName)}
                </h2>
            </div>
        )
    }

    getMediaCaption(index) {
        const message = this.state.messageMedias[index];
        return Util.isStringExists(message.message) ? message.message : null;
    }

    onMessageIdDelete(messageId) {
        this.setState(prevState => ({ messages: prevState.messages.filter(message => message.id != messageId) }))
    }

    onMessageItemDelete(messageIndex) {
        let newItems = [...this.state.messages];
        newItems.splice(messageIndex, 1);
        this.setState({messages: newItems})
    }

    /*getMediaComponent(index) {
        const message = this.state.messageMedias[index];
        if (message.messageAttachment.attachmentType !== MESSAGE_ATTACHMENT_TYPE_IMAGE && message.messageAttachment.attachmentType !== MESSAGE_ATTACHMENT_TYPE_VIDEO) {
            return (
                <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}} id={message.id}>
                    <AttachmentViewer onAudioDurationUpdate={newDuration => {}} message={message} />
                </div>
            );
        } else {
            return null;
        }
    }*/

    render() {
        return (
            <div style={{height: '100%', borderRadius: this.props.liveStream ? /* '25px' */ undefined : undefined, overflow: 'hidden'}}>
                <Prompt
                    when={this.state.messagesInLimbo.length > 0}
                    message={params => params.pathname.startsWith('/messages') && !params.pathname.startsWith('/messages/') ? null : 'Your messages are still sending, are you sure you want to leave?'}
                    />
                {this.props.room !== undefined || this.props.liveStream ? (
                    <Layout className="chat-layout" style={{ borderRadius: this.props.liveStream ? /* '25px' */ undefined : undefined}}>
                        {!this.props.liveStream &&
                        <Header className="title-box">
                            <div className="title-bar">
                                <ProfilePicture clickable={this.props.room.otherMember.role === ACCOUNT_ROLE_TUTOR || this.props.room.otherMember.role === ACCOUNT_ROLE_ORGANIZATION} 
                                //showStatus={this.props.room !== undefined && this.props.room.otherMember !== undefined && this.props.room.otherMember.role === ACCOUNT_ROLE_TUTOR}
                                //showStatusWithText={this.props.room !== undefined && this.props.room.otherMember !== undefined && this.props.room.otherMember.role === ACCOUNT_ROLE_TUTOR}
                                onClick={this.props.room.otherMember.role === ACCOUNT_ROLE_STUDENT ? e => this.setState({showLearnerProfile: true}) : undefined} 
                                style={this.props.room.otherMember.role === ACCOUNT_ROLE_STUDENT ? {cursor: 'pointer'} : undefined}
                                userId={this.props.room.otherMember.id} size="large" />
                                {this.props.room.otherMember.role === ACCOUNT_ROLE_TUTOR || this.props.room.otherMember.role === ACCOUNT_ROLE_ORGANIZATION ||
                                this.props.room.otherMember.role === ACCOUNT_ROLE_STUDENT
                                 ? (
                                    <Link to={Util.getProfilePath(this.props.room.otherMember.id)}>
                                        <h1 style={{marginLeft: '25px', height: '52px'}}>{this.props.room.otherMember.fullName}</h1>
                                    </Link>
                                ) : (
                                    <h1 style={{marginLeft: '25px', height: '52px', cursor: 'pointer'}} onClick={e => this.setState({showLearnerProfile: true})}>{this.props.room.otherMember.fullName}</h1>
                                )}
                            </div>
                        </Header>}
                        <Content className="chat-box" style={{borderTopLeftRadius: this.props.liveStream ? /* '25px' */ undefined : undefined, borderTopRightRadius: this.props.liveStream ? /* '25px' */ undefined : undefined}}>
                            <div className="chat-content">
                                <div className="chat-list" style={this.props.liveStream ? {padding: '0px'} : {}}>
                                    {this.state.loadingMessages ? (
                                        <div style={{display: 'flex', justifyContent: 'center', marginTop: '45px', marginBottom: '45px'}}>
                                            <Spin size="large" tip="Loading..." />
                                        </div>
                                    ) : (<>
                                        {this.props.liveStream && <div style={{paddingLeft: '15px', paddingRight: '15px'}}>
                                            <Divider plain style={{width: '100%'}}>
                                                Start of live stream
                                                <h6>{Util.getDate(this.props.liveStreamStartDate)}</h6>
                                            </Divider>
                                        </div>}
                                        {this.state.messages.map((message, index) => ( 
                                            <ChatBubble key={message.id} roomParentId={this.props.roomParentId} liveStreamIsBroadcaster={this.props.liveStreamIsBroadcaster} onItemDeleted={() => this.onMessageIdDelete(message.id)} liveStream={this.props.liveStream} message={message} onMediaClick={() => this.openMediaViewer(message)} onDonePlayingAudio={() => this.onDonePlayingAudio(message)} onStartedPlayingAudio={() => this.onStartedPlayingAudio(message)} />
                                        ))}
                                        {this.state.messagesInLimbo.map(message => (this.props.liveStream || this.props.room.id === message.roomId) ? ( 
                                            <ChatBubble key={message.tempId} roomParentId={this.props.roomParentId} liveStreamIsBroadcaster={this.props.liveStreamIsBroadcaster} liveStream={this.props.liveStream} message={message} messageId={message.id} messageUploadProgress={message.messageAttachment !== undefined ? message.messageAttachment.uploadProgress : undefined} onResendMessage={() => this.resendMessage(message)} />
                                        ) : null)}
                                        {this.props.liveStream || this.props.helpSession && <div style={{height: '5px'}} />}
                                        
                                        {this.props.liveStream && this.props.disableInput && <div style={{height: '5px'}} />}
                                    </>)}
                                    <div ref={this.messagesEndRef} />
                                </div>
                            </div>
                        </Content>
                        {!this.props.disableInput &&
                        <Footer className={!this.props.liveStream ? "message-box" : ""} style={this.props.liveStream ? {padding: '0', paddingTop: '10px', marginTop: '5px', paddingBottom: '15px'} : {}}>
                            {this.props.liveStream ? (
                                <>
                                    <div style={{height: '40px', display: 'flex', alignItems: 'center', paddingLeft: '4px', paddingRight: '4px'}}>
                                        <Popover content={
                                            <div style={{display: 'flex', flexDirection: 'column', width: '30px', alignItems: 'center', justifyContent: 'center'}}>
                                                <UploadWrapper id="chat-attachment-file-input" accept="*/*" onChange={e => this.setState({fileAttachment: e.target.files[0]})}>
                                                    <Button type="link" style={{marginBottom: '10px'}}><PaperClipOutlined /></Button>
                                                </UploadWrapper>
                                                <UploadWrapper id="chat-attachment-video-input" accept="video/mp4" onChange={e => this.setState({videoAttachment: e.target.files[0], videoAttachmentUrl: URL.createObjectURL(e.target.files[0])})}>
                                                    <Button type="link" style={{marginBottom: '10px'}}><VideoCameraOutlined /></Button>
                                                </UploadWrapper>
                                                <UploadWrapper id="chat-attachment-image-input" accept="image/gif,image/jpeg,image/png" onChange={e => this.setState({imageAttachment: e.target.files[0]})}>
                                                    <Button type="link"><CameraOutlined /></Button>
                                                </UploadWrapper>
                                            </div>
                                        } trigger="hover">
                                            <Button type="link"><PlusCircleOutlined /></Button>
                                        </Popover>
                                        <Input placeholder="Message..." value={this.state.sendMessageValue} onChange={e => this.setState({sendMessageValue: e.target.value})} 
                                        className="live-stream-message-area"
                                        onPressEnter={() => this.onSendMessageBtn()} 
                                        // addonAfter={
                                        //     <Popover overlayClassName={`emoji-pop-over ${this.state.emojiPickerVisible ? 'hidden-pop-over-visible' : 'hidden-pop-over-hidden'}`} destroyTooltipOnHide={false}
                                        //     trigger="click" 
                                        //     placement="topRight"
                                        //     onPopupAlign
                                        //     onVisibleChange={this.state.emojiPickerVisible ? (visible => this.setState({ emojiPickerVisible: visible, emojiPickerInited: true })) : undefined}
                                        //     visible
                                        //     popupVisible
                                        //     defaultVisible
                                        //     content={
                                        //         <Picker native pickerStyle={{width: this.state.emojiPickerInited ? 350 : 0, height: this.state.emojiPickerInited ? undefined : 0, boxShadow: 'none', border: 'none'}}
                                        //         onEmojiClick={(event, emojiEvent) => this.setState(prevState => ({ sendMessageValue: prevState.sendMessageValue + emojiEvent.emoji }))} />
                                        //     }>
                                        //         <Button style={{width: 34, height: 34, padding: 2}} type="link" onClick={() => this.setState(prevState => ({ emojiPickerVisible: !prevState.emojiPickerVisible, emojiPickerInited: true }))}><SmileOutlined /></Button>
                                        //     </Popover>
                                        // } 
                                        />

                                        

                                        {this.state.sendMessageValue.trim() === "" && !this.state.recordingVoiceMessage ? (
                                            <motion.div initial={{ scale: 0 }} animate={{ scale: 1 }}>
                                                <Button type="link" loading={this.state.loadingAudio} onClick={this.onStartVoiceRecorderBtn.bind(this)}><AudioOutlined /></Button>
                                            </motion.div> 
                                        ) : null}
                                        {this.state.sendMessageValue.trim() !== "" && !this.state.recordingVoiceMessage ? (
                                            <motion.div initial={{ scale: 0 }} animate={{ scale: 1 }}>
                                                <Button type="link" onClick={() => this.onSendMessageBtn()}><SendOutlined /></Button>
                                            </motion.div> 
                                        ) : null}

                                        {this.state.recordingVoiceMessage ? (
                                            <motion.div initial={{ opacity: 0, scale: 0 }} animate={{ opacity: 1, scale: 1 }}>
                                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                    <Button type="link" danger onClick={this.onCancelVoiceRecorderBtn.bind(this)}><CloseCircleFilled /></Button>
                                                    <ReactStopwatch
                                                        seconds={0}
                                                        minutes={0}
                                                        hours={0}
                                                        render={({ formatted, hours, minutes, seconds }) => {
                                                            return (
                                                                <span style={{display: 'inline-flex', alignItems: 'center', justifyContent: 'center', width: '50px', marginBottom: '2px'}}>{minutes}:{seconds}</span>
                                                            );
                                                        }}
                                                    />
                                                    <Button type="link" onClick={this.onSendVoiceRecordingBtn.bind(this)}><CheckCircleFilled /></Button>
                                                </div>
                                            </motion.div> 
                                        ) : null}

                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="message-bar">
                                        <UploadWrapper id="chat-attachment-file-input" accept="*/*" onChange={e => this.setState({fileAttachment: e.target.files[0]})}>
                                            <Button type="link"><PaperClipOutlined /></Button>
                                        </UploadWrapper>
                                        <UploadWrapper id="chat-attachment-video-input" accept="video/mp4" onChange={e => this.setState({videoAttachment: e.target.files[0], videoAttachmentUrl: URL.createObjectURL(e.target.files[0])})}>
                                            <Button type="link"><VideoCameraOutlined /></Button>
                                        </UploadWrapper>
                                        <UploadWrapper id="chat-attachment-image-input" accept="image/gif,image/jpeg,image/png" onChange={e => this.setState({imageAttachment: e.target.files[0]})}>
                                            <Button type="link"><CameraOutlined /></Button>
                                        </UploadWrapper>
                                        
                                        <Input.TextArea autoSize={{minRows: 1, maxRows: 2}} maxLength={2048} placeholder="Message..." className="message-input" style={{marginLeft: '15px', marginRight: '15px'}} 
                                        value={this.state.sendMessageValue} onChange={e => this.setState({sendMessageValue: e.target.value})} 
                                        onPressEnter={() => this.onSendMessageBtn()} />

                                        <Popover overlayClassName={`emoji-pop-over ${this.state.emojiPickerVisible ? 'hidden-pop-over-visible' : 'hidden-pop-over-hidden'}`} destroyTooltipOnHide={false}
                                        trigger="click" 
                                        placement="topRight"
                                        onPopupAlign
                                        onVisibleChange={this.state.emojiPickerVisible ? (visible => this.setState({ emojiPickerVisible: visible, emojiPickerInited: true })) : undefined}
                                        visible
                                        popupVisible
                                        defaultVisible
                                        content={
                                            <Picker native pickerStyle={{width: this.state.emojiPickerInited ? 350 : 0, height: this.state.emojiPickerInited ? undefined : 0, boxShadow: 'none', border: 'none'}}
                                            onEmojiClick={(event, emojiEvent) => this.setState(prevState => ({ sendMessageValue: prevState.sendMessageValue + emojiEvent.emoji }))} />
                                        }>
                                            <Button type="link" onClick={() => this.setState(prevState => ({ emojiPickerVisible: !prevState.emojiPickerVisible, emojiPickerInited: true }))}><SmileOutlined /></Button>
                                        </Popover>



                                        {this.state.sendMessageValue.trim() === "" && !this.state.recordingVoiceMessage ? (
                                            <motion.div initial={{ scale: 0 }} animate={{ scale: 1 }}>
                                                <Button type="link" loading={this.state.loadingAudio} onClick={this.onStartVoiceRecorderBtn.bind(this)}><AudioOutlined /></Button>
                                            </motion.div> 
                                        ) : null}
                                        {this.state.sendMessageValue.trim() !== "" && !this.state.recordingVoiceMessage ? (
                                            <motion.div initial={{ scale: 0 }} animate={{ scale: 1 }}>
                                                <Button type="link" onClick={() => this.onSendMessageBtn()}><SendOutlined /></Button>
                                            </motion.div> 
                                        ) : null}

                                        {this.state.recordingVoiceMessage ? (
                                            <motion.div initial={{ opacity: 0, scale: 0 }} animate={{ opacity: 1, scale: 1 }}>
                                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                    <Button type="link" danger onClick={this.onCancelVoiceRecorderBtn.bind(this)}><CloseCircleFilled /></Button>
                                                    <ReactStopwatch
                                                        seconds={0}
                                                        minutes={0}
                                                        hours={0}
                                                        render={({ formatted, hours, minutes, seconds }) => {
                                                            return (
                                                                <span style={{display: 'inline-flex', alignItems: 'center', justifyContent: 'center', width: '50px', marginBottom: '2px'}}>{minutes}:{seconds}</span>
                                                            );
                                                        }}
                                                    />
                                                    <Button type="link" onClick={this.onSendVoiceRecordingBtn.bind(this)}><CheckCircleFilled /></Button>
                                                </div>
                                            </motion.div> 
                                        ) : null}
                                    </div>
                                </>
                            )}
                        </Footer>}
                    </Layout>
                ) : (
                    <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Tag color="#4e2e89" style={{borderRadius: '50px'}}>Select a {this.props.helpSession ? 'help session' : 'chat'} to start messaging</Tag>
                    </div>
                )}
                <Modal
                    title="Send Image"
                    visible={this.state.imageAttachment !== undefined}
                    okText="Send"
                    onOk={() => this.onSendImageBtn()}
                    onCancel={() => this.setState({imageAttachment: undefined, attachmentCaptionValue: ""})}
                    >
                    <img style={{width: '100%', height: '250px', objectFit: 'cover'}} src={this.state.imageAttachment !== undefined ? URL.createObjectURL(this.state.imageAttachment) : undefined} />
                    <Input className="message-input" style={{marginTop: '15px'}} placeholder="Caption" value={this.state.attachmentCaptionValue} onChange={e => this.setState({attachmentCaptionValue: e.target.value})} 
                    onPressEnter={() => this.onSendImageBtn()}/>
                </Modal>
                <Modal
                    title="Send Video"
                    visible={this.state.videoAttachment !== undefined}
                    okText="Send"
                    onOk={() => this.onSendVideoBtn()}
                    onCancel={() => this.setState({videoAttachment: undefined, videoAttachmentUrl: undefined, attachmentCaptionValue: ""})}
                    >
                    <video muted loop autoPlay playsInline style={{width: '100%', height: '250px', objectFit: 'cover'}} src={this.state.videoAttachmentUrl !== undefined ? this.state.videoAttachmentUrl : undefined} />
                    <Input className="message-input" style={{marginTop: '15px'}} placeholder="Caption" value={this.state.attachmentCaptionValue} onChange={e => this.setState({attachmentCaptionValue: e.target.value})}
                    onPressEnter={() => this.onSendVideoBtn()} />
                </Modal>
                <Modal
                    title="Send File"
                    visible={this.state.fileAttachment !== undefined}
                    okText="Send"
                    onOk={() => this.onSendFileBtn()}
                    onCancel={() => this.setState({fileAttachment: undefined, attachmentCaptionValue: ""})}
                    >
                    <FileCard black fileName={this.state.fileAttachment !== undefined ? this.state.fileAttachment.name : ""} fileSize={this.state.fileAttachment !== undefined ? this.state.fileAttachment.size : 0} />
                    <Input className="message-input" style={{marginTop: '15px'}} placeholder="Caption" value={this.state.attachmentCaptionValue} onChange={e => this.setState({attachmentCaptionValue: e.target.value})} 
                    onPressEnter={() => this.onSendFileBtn()}/>
                </Modal>

                {this.state.messageMedias !== undefined && (
                    <Lightbox
                        mainSrc={this.getMediaImageSrc(this.state.mediaViewIndex)}
                        //nextSrc={this.getMediaImageSrc((this.state.mediaViewIndex + 1) % this.state.messageMedias.length)}
                        //prevSrc={this.getMediaImageSrc((this.state.mediaViewIndex + this.state.messageMedias.length - 1) % this.state.messageMedias.length)}
                        mainCustomContent={this.getMediaVideoComponent(this.state.mediaViewIndex)}
                        //nextCustomContent={this.getMediaVideoComponent((this.state.mediaViewIndex + 1) % this.state.messageMedias.length)}
                        //prevCustomContent={this.getMediaVideoComponent((this.state.mediaViewIndex + this.state.messageMedias.length - 1) % this.state.messageMedias.length)}
                        imageTitle={this.getMediaTitle(this.state.mediaViewIndex)}
                        imageCaption={this.getMediaCaption(this.state.mediaViewIndex)}
                        onCloseRequest={() => this.setState({ messageMedias: undefined, mediaViewIndex: undefined })}
                        onMovePrevRequest={() =>
                            this.setState({
                                mediaViewIndex: (this.state.mediaViewIndex + this.state.messageMedias.length - 1) % this.state.messageMedias.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                mediaViewIndex: (this.state.mediaViewIndex + 1) % this.state.messageMedias.length,
                            })
                        }
                    />
                )}
                
                {this.props.room !== undefined ? (
                    <StudentProfileDialog 
                    visible={this.state.showLearnerProfile}
                    name={this.props.room.otherMember.fullName}
                    userId={this.props.room.otherMember.id}
                    onCancel={() => this.setState({showLearnerProfile: false})} />
                ) : null}
            </div>
        );
    }
}

export default ChatView;