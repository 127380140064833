import React, { useEffect } from 'react'


export default ({ }) => {
    useEffect(() => {
        window.location.replace('/')
    }, []);

    return (
        <div />
    )
}