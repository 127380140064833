import React from 'react';
import Error404Page from '../../pages/Error404Page';
import Window from '../../base/Window';
import Logo from '../../images/Logo.png'
import LogoWhite from '../../images/LogoWhite.png'
import LiveIcon from '../../images/LiveIcon.png'
import OrganizationIcon from '../../images/OrganizationIcon.png';
import MediaQuery from 'react-responsive'

import {
    BrowserRouter as Router,
    Route,
    Switch,
    withRouter,
    Link
  } from 'react-router-dom'
import { ACCOUNT_SCOPE_FRONTEND, ACCOUNT_ROLE_TUTOR, ACCOUNT_ROLE_STUDENT, PRESENCE_AVAILABLE, NOTIFICATION_EVENT_TYPE_NOTIFICATION, NOTIFICATION_EVENT_TYPE_UNREAD_COUNT_UPDATE, SOCKET_EVENT_NOTIFICATION, PRESENCE_AWAY, PRESENCE_DO_NOT_DISTURB, PRESENCE_OFFLINE } from '../../Constants';
import PostsView from '../../views/posts/PostsView';
import FollowingPostsPage from '../../pages/FollowingPostsPage';

import { Space, Row, Col, Button, Avatar, Menu, Popover, Badge, Input, Drawer, Dropdown, Tag, notification, Tooltip, Divider } from 'antd';

import { 
    UserOutlined,
    AlertFilled,
    SettingOutlined,
    HomeOutlined,
    MessageOutlined,
    BookOutlined,
    AlertOutlined,
    DownOutlined,
    ShareAltOutlined,
    BellFilled,
    SearchOutlined,
    MenuOutlined,
    CloseOutlined,
    CreditCardOutlined,
    ClusterOutlined,
    MailOutlined,
    FormOutlined,
    TeamOutlined,
    ApartmentOutlined,
    CheckCircleFilled,
    ArrowRightOutlined,
    DollarCircleOutlined,
    LogoutOutlined,
    DownCircleOutlined,
    IdcardOutlined,
    ExperimentOutlined,
    DashboardOutlined
} from '@ant-design/icons';
import MessagesPage from '../../pages/messages-page/MessagesPage';
import TutorProfilePage from '../../pages/tutor-profile-page/TutorProfilePage';
import RatingsPage from '../../pages/ratings-page/RatingsPage';
import SessionManager from '../../session/SessionManager';
import Api from '../../session/Api';
import DiscoveryPage from '../../pages/DiscoveryPage';
import SocketSession from '../../session/SocketSession';
import CourseSalePage from '../../pages/course-sale-page/CourseSalePage';
import TutorMyCoursesPage from '../../pages/TutorMyCoursesPage';
import TutorCoursePage from '../../pages/tutor-course-page/TutorCoursePage';
import StudentProfileDialog from '../../components/student-profile-dialog/StudentProfileDialog';
import StudentMyCoursesPage from '../../pages/StudentMyCoursesPage';

import "./frontend-window.less"
import AllCoursesPage from '../../pages/AllCoursesPage';
import CourseCategoryPage from '../../pages/CourseCategoryPage';
import AccountSettingsPage from '../../pages/AccountSettingsPage';
import StudentCoursePage from '../../pages/student-course-page/StudentCoursePage';
import { StickyContainer, Sticky } from 'react-sticky';
import TutorMyLiveSessionsPage from '../../pages/TutorMyLiveSessionsPage';
import TutorLiveSessionPage from '../../pages/tutor-live-session-page/TutorLiveSessionPage';
import LiveSessionSalePage from '../../pages/live-session-sale-page/LiveSessionSalePage';
import StudentMyLiveSessionsPage from '../../pages/StudentMyLiveSessionsPage';
import AllLiveSessionsPage from '../../pages/AllLiveSessionsPage';
import LiveSessionCategoryPage from '../../pages/LiveSessionCategoryPage';
import StudentLiveSessionPage from '../../pages/student-live-session-page/StudentLiveSessionPage';
import TutorHelpSessionPage from '../../pages/help-session-page/TutorHelpSessionPage';
import StudentHelpSessionHistoryPage from '../../pages/help-session-page/StudentHelpSessionHistoryPage';
import ActiveHelpSessionPage from '../../pages/help-session-page/ActiveHelpSessionPage';
import PresenceManager from '../../session/PresenceManager';
import UIUtil from '../../util/UIUtil';
import PresenceIndicator from '../../components/PresenceIndicator';
import SearchPage from '../../pages/SearchPage';
import Util from '../../util/Util';
import AllTutorsPage from '../../pages/AllTutorsPage';
import LiveStreamRecordingPage from '../../livestream/components/LiveStreamRecordingPlayer';
import OrganizationUsersPage from '../../pages/OranizationUsersPage';
import Modal from 'antd/lib/modal/Modal';
import ProfilePicture from '../../components/ProfilePicture';
import EnrolledOrganizationPage from '../../pages/EnrolledOrganizationPage';
import UserListDialog from '../../components/UserListDialog';
import LiveSubscriptionDialog from '../../components/LiveSubscriptionDialog';
import AllOrganizationsPage from '../../pages/AllOrganizationsPage';
import OrganizationAccessDialog from '../../components/OrganizationAccessDialog';
import AllAnnouncementsPage from '../../pages/AllAnnouncementsPage';
import PrivacyPolicyPage from '../../pages/public-pages/PrivacyPolicyPage';
import AboutUsPage from '../../pages/public-pages/AboutUsPage';
import TermsOfServicePage from '../../pages/public-pages/TermsOfServicePage';
import NotificationItem from '../../views/notifications/NotificationItem';
import NotificationsView from '../../views/notifications/NotificationsView';
import HelpSessionHistoryPage from '../../pages/help-session-page/HelpSessionHistoryPage';
import TutorBalancePage from '../../pages/user-accounting/TutorBalancePage';
import AllTransactionsPage from '../../pages/payment-manager/AllTransactionsPage';
import AllWithdrawsPage from '../../pages/payment-manager/AllWithdrawsPage';
import AllSubscribersPage from '../../pages/AllSubscribersPage';
import SettingsPage from '../../pages/SettingsPage';
import BloggerProfileDialog from '../../components/student-profile-dialog/BloggerProfileDialog';
import MyBlogsPage from '../../pages/blog-pages/MyBlogsPage';
import MyBlogPostPage from '../../pages/blog-pages/MyBlogPostPage';
import BlogPage from '../../pages/blog-pages/BlogPage';
import BlogPostPage from '../../pages/blog-pages/BlogPostPage';
import AllUsersPage from '../../pages/AllUsersPage';
import ContactUsPage from '../../pages/public-pages/ContactUsPage';
import ContentManagementPage from '../../pages/content-management-pages/ContentManagementPage';
import FeaturedSelectorPage from '../../pages/content-management-pages/FeaturedSelectorPage';
import LandingPage from '../../pages/landing-page/LandingPage';
import SquareBtn from '../../components/SquareBtn';
import LiveStreamCreditsPage from '../../pages/live-stream-credits/LiveStreamCreditsPage';
import UserAllowancePage from '../../pages/user-allowance-page/UserAllowancePage';
import BillingPage from '../../pages/billing-page/BillingPage';
import PostPage from '../../pages/PostPage';
import UpcomingLiveStreamsView from '../../views/upcoming-live-streams/UpcomingLiveStreamsView';
import HashtagPage from '../../pages/hashtag/HashtagPage';
import HashtagCoursesPage from '../../pages/hashtag/HashtagCoursesPage';
import HashtagLiveSessionsPage from '../../pages/hashtag/HashtagLiveSessionsPage';

const { SubMenu } = Menu;


function daysInThisMonth() {
    var now = new Date();
    return new Date(now.getUTCFullYear(), now.getUTCMonth()+1, 0).getUTCDate();
}  


class FrontendWindow extends Window {
    constructor(props) {
        super(props);

        this.state = {
            profilePicSuffix: '',
            showLearnerProfile: false,
            showBloggerProfile: false,

            drawerMenuOpen: false,

            presence: -1,
            settingPresence: false,

            searchValue: "",

            showSubscribeDialog: false,
            showOrganizationAccessDialog: false,

            organizations: [],
            showReceivedInvites: false,

            unreadNotificationCount: 0,
            notificationViewKey: Util.newTempId(),

            myAccountInfo: undefined,
            showAccountLiveStreamStartDialog: false,
            startingAccountLiveStream: false,

            profilePopoverVisible: false,
            notificationPopoverVisible: false,

            upcomingLiveStreamsViewKey: Util.newTempId(),
            upcomingLiveStreamsPopoverVisible: false
        }
    }

    incrementNotificationUnreadCount() {
        this.setState(state => ({unreadNotificationCount: state.unreadNotificationCount + 1}))
    }

    clearNotificationUnreadCount() {
        this.setState({unreadNotificationCount: 0})
    }

    componentWillUnmount() {
        SocketSession.unregisterListener(SOCKET_EVENT_NOTIFICATION, this.notificationEventListener);
    }

    componentDidMount() {
        //PresenceManager.getPresence(SessionManager.getAccount().id, presence => this.setState({presence: presence}))
        PresenceManager.registerListener(SessionManager.getAccount().id, presence => this.setState({presence: presence}))
        SocketSession.registerListener(SOCKET_EVENT_NOTIFICATION, this.notificationEventListener);

        Api.getMyOrganizations(response => {
            if (response.status === true && response.payload.length > 0) {
                this.setState({organizations: response.payload})
            }
        })

        Api.getMyAccountInfo(response => {
            if (response.status === true) {
                this.setState({myAccountInfo: response.payload});
            }
        })
    }

    onNotificationEvent(event) {
        switch (event.type) {
            case NOTIFICATION_EVENT_TYPE_NOTIFICATION:
                const notificationModel = event.payload;
                if (notificationModel.type == -1) {
                    //this.incrementMessageUnreadCount();
                } else {
                    this.incrementNotificationUnreadCount();
                }
                notificationModel.seen = true;
                
                const notificationId = Util.newTempId();
                notification.open({
                    key: notificationId,
                    message: <NotificationItem onNotificationClick={() => notification.close(notificationId)} notification={notificationModel} miniMode customHistory={this.props.history} />
                })
                
                /*this.setState({currentNotification: <NotificationItem notification={notification} />}, () => {
                    if (this.notificationRef.current) {
                        this.notificationRef.current.show();
                    }
                })*/
                break;
            case NOTIFICATION_EVENT_TYPE_UNREAD_COUNT_UPDATE:
                this.setState({
                    //unreadNotificationCount: event.payload
                    unreadNotificationCount: event.payload.unreadNotificationCount,
                    //unreadMessageCount: event.payload.unreadMessageCount
                })
                break;
        }
    }
    notificationEventListener = this.onNotificationEvent.bind(this);

    setPresence(presence) {
        this.setState({settingPresence: true})
        PresenceManager.setPresence(presence, response => {
            if (response.status === true) {
                this.setState({
                    presence: presence,
                    settingPresence: false
                })
            } else {
                UIUtil.showError();
                this.setState({settingPresence: false})
            }
        })
    }

    onProfilePicUpdate() {
        this.setState({profilePicSuffix: '&t=' + new Date().getTime()})
    }

    renderNotificationBtn() {
        return (
            <div style={{marginRight: '20px', marginLeft: 0}}>
                <Badge count={this.state.unreadNotificationCount}>
                    <Popover overlayClassName="notification-pop-over" 
                    visible={this.state.notificationPopoverVisible}
                    onVisibleChange={visible => {
                        if (visible) {
                            this.clearNotificationUnreadCount();
                            this.setState({notificationViewKey: Util.newTempId()})
                        }
                        this.setState({notificationPopoverVisible: visible})
                    }} content={<NotificationsView key={this.state.notificationViewKey} onNotificationClick={() => this.setState({notificationPopoverVisible: false})}
                    />} 
                    trigger="click" placement="bottomRight"
                    >
                        <Button shape="circle" style={{ display: 'flex', alignItems: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <BellFilled style={{fontSize: '15px'}} />
                        </Button>
                    </Popover>
                </Badge>
            </div>
        )
    }

    renderUpcomingLiveStreamBtn() {
        return (
            <div style={{marginRight: 10, marginLeft: 10}}>
                {/* <Badge count={this.state.unreadNotificationCount}> */}
                    <Popover overlayClassName="notification-pop-over" 
                    visible={this.state.upcomingLiveStreamsPopoverVisible}
                    onVisibleChange={visible => {
                        if (visible) {
                            this.setState({upcomingLiveStreamsViewKey: Util.newTempId()})
                        }
                        this.setState({upcomingLiveStreamsPopoverVisible: visible})
                    }} content={<UpcomingLiveStreamsView key={this.state.upcomingLiveStreamsViewKey} onNotificationClick={() => this.setState({upcomingLiveStreamsPopoverVisible: false})}
                    />} 
                    trigger="click" placement="bottomRight"
                    >
                        <Button shape="circle" style={{ display: 'flex', alignItems: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <AlertFilled style={{fontSize: '15px'}} />
                        </Button>
                    </Popover>
                {/* </Badge> */}
            </div>
        )
    }

    getAccountInfoValue(property, defValue) {
        return this.state.myAccountInfo === undefined ? (defValue !== undefined ? defValue : "Loading") : this.state.myAccountInfo[property];
    }

    isAccountInfoValueNumberOne(property) {
        return this.state.myAccountInfo === undefined ? false : this.state.myAccountInfo[property] == 1;
    }

    renderProfile() {
        if (SessionManager.isLoggedInAsRelationManager() || SessionManager.isLoggedInAsPaymentManager() || SessionManager.isLoggedInAsContentManager()) {
            return (
                <Popover placement="bottomRight" content={
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <h4 style={{width: '175px', textAlign: 'center'}}>{SessionManager.getAccount().fullName}</h4>
                        {SessionManager.isLoggedInAsRelationManager() ? (
                            <Button style={{cursor: 'default'}} type="link"><ApartmentOutlined /> Relation Manager</Button>
                        ) : (SessionManager.isLoggedInAsPaymentManager() ? (
                            <Button style={{cursor: 'default'}} type="link"><CreditCardOutlined /> Payment Manager</Button>
                        ) : (
                            <Button style={{cursor: 'default'}} type="link"><ExperimentOutlined /> Content Manager</Button>
                        ))}

                        <Button type="primary" danger style={{marginTop: '25px'}} onClick={() => {
                            SessionManager.logout(() => window.location.replace('/'));
                        }}>Logout</Button>
                    </div>
                } trigger="hover">
                    <Button type="link"><UserOutlined style={{marginRight: '10px', fontSize: '15px'}} /></Button>
                </Popover>
            )
        } else {
            return (
                <Popover visible={this.state.profilePopoverVisible} onVisibleChange={visible => this.setState({profilePopoverVisible: visible})} 
                placement="bottomRight" content={
                    <div style={{display: 'flex', flexDirection: 'column', width: 300}}>
                        <h2 style={{marginBottom: 0}}>
                            Welcome, {SessionManager.getAccount().fullName}!
                            {this.state.myAccountInfo !== undefined && this.state.myAccountInfo.verified === true &&
                            <Tooltip title="Verified">
                                <CheckCircleFilled style={{color: 'rgb(0,152,235)', fontSize: '18px', marginLeft: '10px'}} />
                            </Tooltip>}
                        </h2>
                        {SessionManager.isLoggedInAsTutorONLY() ? (
                            <Dropdown overlay={
                                <Menu>
                                    <Menu.Item onClick={() => this.setPresence(PRESENCE_AVAILABLE)} style={{display: 'flex', alignItems: 'center'}}>
                                        <div style={{height: '10px', background: 'green', width: '10px', marginRight: '5px', borderRadius: '50%'}} /> Available
                                    </Menu.Item>
                                    <Menu.Item onClick={() => this.setPresence(PRESENCE_AWAY)} style={{display: 'flex', alignItems: 'center'}}>
                                        <div style={{height: '10px', background: '#fbc02d', width: '10px', marginRight: '5px', borderRadius: '50%'}} /> Away
                                    </Menu.Item>
                                    <Menu.Item onClick={() => this.setPresence(PRESENCE_DO_NOT_DISTURB)} style={{display: 'flex', alignItems: 'center'}}>
                                        <div style={{height: '10px', background: 'red', width: '10px', marginRight: '5px', borderRadius: '50%'}} /> Do not disturb
                                    </Menu.Item>
                                    <Menu.Item onClick={() => this.setPresence(PRESENCE_OFFLINE)} style={{display: 'flex', alignItems: 'center'}}>
                                        <div style={{height: '10px', background: '#cecece', width: '10px', marginRight: '5px', borderRadius: '50%'}} /> Hidden
                                    </Menu.Item>
                                </Menu>
                            } trigger="click">
                                <Button loading={this.state.settingPresence} type="text" 
                                style={{marginTop: -2, paddingLeft: 10, paddingRight: 10, paddingTop: 0, paddingBottom: 0, display: 'flex', alignItems: 'center', marginLeft: -10, marginRight: -10}}>
                                    {this.renderPresence(this.state.presence)}
                                </Button>
                            </Dropdown>
                        ) : null}

                        {SessionManager.isLoggedInAsTutor() &&
                        <Link to="/balance" onClick={() => this.setState({profilePopoverVisible: false})}>
                            <div className="profile-pop-over-menu-button" style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 15}}>
                                <Button size="large" type="link" style={{marginRight: 10, pointerEvents: 'none', padding: 2}} >
                                    <DollarCircleOutlined style={{fontSize: 25, marginTop: 6}} />
                                </Button>
                                <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                                    <h3 style={{color: 'black', margin: 0}}>Balance</h3>
                                    <h4 style={{color: '#1f1f1f', margin: 0, marginTop: -4, flex: 1}}>
                                        {this.state.myAccountInfo !== undefined ? (
                                            '$' + this.state.myAccountInfo.balance.toFixed(2)
                                        ) : 'Loading'}
                                    </h4>
                                </div>
                                <Button type="link" style={{marginTop: 2}}><ArrowRightOutlined /></Button>
                            </div>
                        </Link>}
                                
                        <div className="profile-pop-over-menu-button" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', 
                        marginTop: 10, borderRadius: 5, backgroundColor: '#4e2e89', marginLeft: -10, marginRight: -10,
                        paddingLeft: 10, paddingRight: 10, paddingTop: 5, paddingBottom: 5}} onClick={() => {
                            // if (SessionManager.isLoggedInAsStudent()) {
                            //     this.setState({showLearnerProfile: true})
                            // } else 
                            
                            if (SessionManager.isLoggedInAsBlogger()) {
                                this.setState({showBloggerProfile: true})
                            } else {
                                this.props.history.push("/my-profile")
                            }
                            this.setState({profilePopoverVisible: false})
                        }}>
                            <Button size="large" type="link" style={{marginRight: 10, pointerEvents: 'none', padding: 2, color: 'white'}} >
                                <UserOutlined style={{fontSize: 20, marginTop: 5}} />
                            </Button>
                            <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                                <h4 style={{color: 'white', margin: 0}}>Profile</h4>
                                {(SessionManager.isLoggedInAsTutor() || SessionManager.isLoggedInAsStudent()) ? (
                                    <h5 style={{color: '#cecece', margin: 0, marginTop: -4, fontWeight: 'normal', flex: 1}}>
                                        {this.getAccountInfoValue('followers')} Follower{!this.isAccountInfoValueNumberOne('followers') ? 's' : ''}
                                    </h5>
                                ) : (
                                    <h5 style={{color: '#cecece', margin: 0, marginTop: -4, fontWeight: 'normal', flex: 1}}>
                                        {SessionManager.isLoggedInAsBlogger() ? 'Blogger' : 'Learner'}
                                    </h5>
                                )}
                            </div>
                            <Button type="link" style={{marginTop: 2, color: 'white'}}><ArrowRightOutlined /></Button>
                        </div>

                        {(SessionManager.isLoggedInAsTutorONLY()) && <>
                            <div style={{height: 1, marginTop: 9, width: '100%', background: 'black', opacity: 0.10,}} />
                            
                            <Link to="/live-stream-usage" onClick={() => this.setState({profilePopoverVisible: false})}>
                                <div className="profile-pop-over-menu-button" style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10}}>
                                    <Button size="large" type="link" style={{marginRight: 10, marginLeft: 2, pointerEvents: 'none', padding: 2}} >
                                        <DashboardOutlined style={{fontSize: 18, marginTop: 5}} />
                                    </Button>
                                    <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                                        <h4 style={{color: 'black', margin: 0}}>Live Stream Usage</h4>
                                        <h5 style={{color: '#1f1f1f', margin: 0, marginTop: -4, fontWeight: 'normal', flex: 1}}>
                                            {this.getAccountInfoValue("liveStreamCredits")} minutes remaining
                                        </h5>
                                    </div>
                                    <Button type="link" style={{marginTop: 2}}><ArrowRightOutlined /></Button>
                                </div>
                            </Link>

                            <div style={{height: 1, marginTop: 9, width: '100%', background: 'black', opacity: 0.10}} />
                        </>}

                        {(SessionManager.isLoggedInAsOrganization()) && <>
                            <div style={{height: 1, marginTop: 9, width: '100%', background: 'black', opacity: 0.10,}} />

                            
                            <Link to="/live-stream-usage" onClick={() => this.setState({profilePopoverVisible: false})}>
                                <div className="profile-pop-over-menu-button" style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10}}>
                                    <Button size="large" type="link" style={{marginRight: 10, marginLeft: 2, pointerEvents: 'none', padding: 2}} >
                                        <DashboardOutlined style={{fontSize: 18, marginTop: 5}} />
                                    </Button>
                                    <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                                        <h4 style={{color: 'black', margin: 0}}>Live Stream Usage</h4>
                                        <h5 style={{color: '#1f1f1f', margin: 0, marginTop: -4, fontWeight: 'normal', flex: 1}}>
                                            {this.getAccountInfoValue("liveStreamCredits")} minutes remaining
                                        </h5>
                                    </div>
                                    <Button type="link" style={{marginTop: 2}}><ArrowRightOutlined /></Button>
                                </div>
                            </Link>
                            
                            <Link to="/user-allowance" onClick={() => this.setState({profilePopoverVisible: false})}>
                                <div className="profile-pop-over-menu-button" style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10}}>
                                    <Button size="large" type="link" style={{marginRight: 10, marginLeft: 2, pointerEvents: 'none', padding: 2}} >
                                        <TeamOutlined style={{fontSize: 18, marginTop: 5}} />
                                    </Button>
                                    <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                                        <h4 style={{color: 'black', margin: 0}}>User Allowance</h4>
                                        <h5 style={{color: '#1f1f1f', margin: 0, marginTop: -4, fontWeight: 'normal', flex: 1}}>
                                            {this.getAccountInfoValue("userUsed")} accounts used ({this.getAccountInfoValue("userLeft")} left)
                                        </h5>
                                    </div>
                                    <Button type="link" style={{marginTop: 2}}><ArrowRightOutlined /></Button>
                                </div>
                            </Link>

                            
                                <Link to="/billing" onClick={() => this.setState({profilePopoverVisible: false})}>
                                    <div className="profile-pop-over-menu-button" style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10}}>
                                        <Button size="large" type="link" style={{marginRight: 10, paddingTop: 5, marginLeft: 2, pointerEvents: 'none', padding: 2}}>
                                            <Badge dot={false}>
                                                <CreditCardOutlined style={{fontSize: 18}} />
                                            </Badge>
                                        </Button>
                                        <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                                            <h4 style={{color: 'black', margin: 0}}>Billing</h4>
                                            {false ? (
                                                <h5 style={{color: 'red', margin: 0, marginTop: -4, fontWeight: 'normal', flex: 1}}>
                                                    Payment due! <strong>Four</strong> days left
                                                </h5>
                                            ) : (
                                                <h5 style={{color: '#1f1f1f', margin: 0, marginTop: -4, fontWeight: 'normal', flex: 1}}>
                                                    ${this.getAccountInfoValue("billAmount", 0).toFixed(2)} due in {(daysInThisMonth() - new Date().getUTCDate()) + 1} days (projected)
                                                </h5>
                                            )}
                                        </div>
                                        <Button type="link" style={{marginTop: 2}}><ArrowRightOutlined /></Button>
                                    </div>
                                </Link>
                            


                            <div style={{height: 1, marginTop: 9, width: '100%', background: 'black', opacity: 0.10}} />
                        </>}

                        {(SessionManager.isLoggedInAsStudentOrTutor()) &&
                        <div className="profile-pop-over-menu-button" style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10}}
                        onClick={() => this.setState({showReceivedInvites: true, profilePopoverVisible: false})}>
                            <Button size="large" type="link" style={{marginRight: 10, marginLeft: 2, pointerEvents: 'none', padding: 2}} >
                                <MailOutlined style={{fontSize: 18, marginTop: 5}} />
                            </Button>
                            <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                                <h4 style={{color: 'black', margin: 0}}>Invites</h4>
                                <h5 style={{color: '#1f1f1f', margin: 0, marginTop: -4, fontWeight: 'normal', flex: 1}}>
                                    {this.getAccountInfoValue('invites')} Invite{!this.isAccountInfoValueNumberOne('invites') ? 's' : ''}
                                </h5>
                            </div>
                            <Button type="link" style={{marginTop: 2}}><ArrowRightOutlined /></Button>
                        </div>}

                        {SessionManager.isLoggedInAsTutor() && this.state.myAccountInfo !== undefined && this.state.myAccountInfo.verified === true &&
                        <div className="profile-pop-over-menu-button" style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10}}
                        onClick={() => {
                            if (Util.isStringExists(this.state.myAccountInfo.liveStreamId)) {
                                this.props.history.push(Util.getLiveStreamPath(this.state.myAccountInfo.liveStreamId))
                            } else {
                                this.setState({showAccountLiveStreamStartDialog: true});
                            }
                            this.setState({profilePopoverVisible: false})
                        }}>
                            <Button size="large" type="link" className="red-link" style={{marginRight: 9, marginLeft: 1, pointerEvents: 'none', padding: 2}} >
                                <AlertOutlined style={{fontSize: 20, marginTop: 5}} />
                            </Button>
                            <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                                <h4 style={{color: 'black', margin: 0}}>Live Stream</h4>
                                <h5 style={{color: Util.isStringExists(this.state.myAccountInfo.liveStreamId) ? '#990000' : '#1f1f1f', margin: 0, marginTop: -4, fontWeight: 'normal', flex: 1}}>
                                    {Util.isStringExists(this.state.myAccountInfo.liveStreamId) ? 'In Progress' : 'Start Now'}
                                </h5>
                            </div>
                            <Button type="link" className="red-link" style={{marginTop: 2}}><ArrowRightOutlined /></Button>
                        </div>}

                        <div className="profile-pop-over-menu-button" style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10}}
                        onClick={() => {
                            this.props.history.push("/settings")
                            this.setState({profilePopoverVisible: false})
                        }}>
                            <Button size="large" type="link" style={{marginRight: 10, marginLeft: 2, pointerEvents: 'none', padding: 2}} >
                                <SettingOutlined style={{fontSize: 18, marginTop: 5}} />
                            </Button>
                            <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                                <h4 style={{color: 'black', margin: 0}}>Settings</h4>
                            </div>
                            <Button type="link" style={{marginTop: 2}}><ArrowRightOutlined /></Button>
                        </div>

                        <div style={{height: 25}} />

                        {SessionManager.isLoggedInAsStudentOrTutor() &&
                        <div style={{width: '100%', height: '70px', marginTop: '15px', backgroundColor: '#2d3436', backgroundImage: 'linear-gradient(315deg, #2d3436 0%, #000000 74%)',
                        borderRadius: '5px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}} onClick={() => this.setState({showSubscribeDialog: true, profilePopoverVisible: false})}>
                            <img src={LogoWhite} style={{width: '100px'}} />
                            <img src={LiveIcon} style={{width: '25px'}} />
                        </div>}

                        {SessionManager.isLoggedInAsOrganization() &&
                        <div style={{width: '100%', height: '80px', marginTop: '15px', backgroundColor: '#DBDBDB' , backgroundImage: 'linear-gradient(to right, #DBDBDB, #EAEAEA)',
                        borderRadius: '5px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}} onClick={() => this.setState({showOrganizationAccessDialog: true, profilePopoverVisible: false})}>
                            <img src={Logo} style={{width: '100px'}} />
                            <img src={OrganizationIcon} style={{width: '75px', marginTop: '10px'}} />
                        </div>}
    
                        <Button type="primary" danger style={{marginTop: '5px'}} onClick={() => {
                            //this.setState({profilePopoverVisible: false})
                            SessionManager.logout(() => window.location.replace('/'));
                        }}><LogoutOutlined /> Logout</Button>
                    </div>
                } trigger="hover">
                    <div style={{position: 'relative'}}>
                        <Badge //count={"!"}
                        >
                            <Avatar size="large" style={{minWidth: '40px', minHeight: '40px'}} icon={<UserOutlined />} src={Api.getMyProfilePic() + this.state.profilePicSuffix} />
                        </Badge>
                        {SessionManager.isLoggedInAsTutorONLY() && 
                        <PresenceIndicator realtime style={{position: 'absolute', bottom: 0, right: -3}} userId={SessionManager.getAccount().id} />}
                    </div>
                </Popover>
            )

            return (
                <Popover placement="bottomRight" content={
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <h4 style={{width: '175px', textAlign: 'center'}}>
                            {SessionManager.getAccount().fullName}
                            {this.state.myAccountInfo !== undefined && this.state.myAccountInfo.verified === true &&
                            <Tooltip title="Verified">
                                <CheckCircleFilled style={{color: 'rgb(0,152,235)', fontSize: '15px', marginLeft: '5px'}} />
                            </Tooltip>}
                        </h4>
                        <Button size="large" onClick={SessionManager.isLoggedInAsStudent() ? e => this.setState({showLearnerProfile: true}) : undefined}>
                            {SessionManager.isLoggedInAsTutor() ? <Link to="/my-profile"><UserOutlined /> Profile</Link> : <><UserOutlined /> Profile</>}
                        </Button>
    
                        {(SessionManager.isLoggedInAsStudentOrTutor()) &&
                        <Button size="large" onClick={() => this.setState({showReceivedInvites: true})} style={{marginTop: '5px'}}>
                            <MailOutlined /> Invites
                        </Button>}


                        {SessionManager.isLoggedInAsTutor() && this.state.myAccountInfo !== undefined && this.state.myAccountInfo.verified === true && <>
                            {Util.isStringExists(this.state.myAccountInfo.liveStreamId) &&
                            <p style={{color: '#990000', margin: 0, marginBottom: 5, marginTop: 25}}><AlertOutlined /> Live stream in progress</p>}

                            <Button className="red-button" onClick={() => {
                                if (Util.isStringExists(this.state.myAccountInfo.liveStreamId)) {
                                    this.props.history.push(Util.getLiveStreamPath(this.state.myAccountInfo.liveStreamId))
                                } else {
                                    this.setState({showAccountLiveStreamStartDialog: true});
                                }
                            }} style={{marginTop: Util.isStringExists(this.state.myAccountInfo.liveStreamId) ? '0px' : 5, 
                                        marginBottom: Util.isStringExists(this.state.myAccountInfo.liveStreamId) ? 20 : 0, 
                                        color: 'white'}}>
                                <AlertOutlined /> {Util.isStringExists(this.state.myAccountInfo.liveStreamId) ? 'Join Live Stream' : 'Start Live Stream'}
                            </Button>
                        </>}
                        
                        {SessionManager.isLoggedInAsStudentOrTutor() &&
                        <div style={{width: '100%', height: '70px', marginTop: '15px', backgroundColor: '#2d3436', backgroundImage: 'linear-gradient(315deg, #2d3436 0%, #000000 74%)',
                        borderRadius: '5px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}} onClick={() => this.setState({showSubscribeDialog: true})}>
                            <img src={LogoWhite} style={{width: '100px'}} />
                            <img src={LiveIcon} style={{width: '25px'}} />
                        </div>}
    
                        {SessionManager.isLoggedInAsOrganization() &&
                        <div style={{width: '100%', height: '80px', marginTop: '15px', backgroundColor: '#DBDBDB' , backgroundImage: 'linear-gradient(to right, #DBDBDB, #EAEAEA)',
                        borderRadius: '5px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}} onClick={() => this.setState({showOrganizationAccessDialog: true})}>
                            <img src={Logo} style={{width: '100px'}} />
                            <img src={OrganizationIcon} style={{width: '75px', marginTop: '10px'}} />
                        </div>}
    
                        {/*<Button size="large" style={{marginTop: '15px'}}>
                            <Link to="/account-settings"><SettingOutlined /> Account Settings</Link>
                        </Button>*/}
                        <Button type="primary" danger style={{marginTop: '25px'}} onClick={() => {
                            SessionManager.logout(() => window.location.replace('/'));
                        }}>Logout</Button>
                    </div>
                } trigger="hover">
                    <Avatar size="large" style={{minWidth: '40px', minHeight: '40px'}} icon={<UserOutlined />} src={Api.getMyProfilePic() + this.state.profilePicSuffix} />
                </Popover>
            )
        }
    }

    renderMenu(mode) {
        const height = mode === "horizontal" ? '75px' : undefined;
        const menuStyle = mode === "horizontal" ? {height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'} : undefined;
        const onClick = mode === "inline" ? (e => this.setState({drawerMenuOpen: false})).bind(this) : undefined

        const menuItemStyle = SessionManager.isLoggedInAsTutorONLY() ? {
            height: height, marginLeft: '10px', marginRight: '10px'
        } : {height: height}

        if (SessionManager.isLoggedInAsContentManager()) {
            return (
                <Menu size="large" selectedKeys={[this.props.location.pathname]} style={{height: height, marginRight: '15px' /*15*/, background: 'transparent'}} mode={mode}>
                    <Menu.Item key="/" style={menuItemStyle}>
                        <Link onClick={onClick} to="/" />
                        <div style={menuStyle}>
                            <HomeOutlined /> Home
                        </div>
                    </Menu.Item>
                    <Menu.Item key="/messages" style={menuItemStyle}>
                        <Link onClick={onClick} to="/messages">
                            <div style={menuStyle}>
                                {/*<Badge dot={true} style={{pointerEvents: 'none'}} >
                                    <MessageOutlined /> Messages
                                </Badge>*/}
                                <MessageOutlined /> Messages
                            </div>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="/content-management" style={menuItemStyle}>
                        <Link onClick={onClick} to="/content-management"/>
                        <div style={menuStyle}>
                            <ExperimentOutlined /> Content
                        </div>
                    </Menu.Item>
                </Menu>
            )
        } else if (SessionManager.isLoggedInAsBlogger()) {
            return (
                <Menu size="large" selectedKeys={[this.props.location.pathname]} style={{height: height, marginRight: '15px' /*15*/, background: 'transparent'}} mode={mode}>
                    <Menu.Item key="/" style={menuItemStyle}>
                        <Link onClick={onClick} to="/" />
                        <div style={menuStyle}>
                            <HomeOutlined /> Home
                        </div>
                    </Menu.Item>
                    <Menu.Item key="/messages" style={menuItemStyle}>
                        <Link onClick={onClick} to="/messages">
                            <div style={menuStyle}>
                                {/*<Badge dot={true} style={{pointerEvents: 'none'}} >
                                    <MessageOutlined /> Messages
                                </Badge>*/}
                                <MessageOutlined /> Messages
                            </div>
                        </Link>
                    </Menu.Item>
                </Menu>
            )
        } else if (SessionManager.isLoggedInAsRelationManager()) {
            return (
                <Menu size="large" selectedKeys={[this.props.location.pathname]} style={{height: height, marginRight: '15px' /*15*/, background: 'transparent'}} mode={mode}>
                    <Menu.Item key="/" style={menuItemStyle}>
                        <Link onClick={onClick} to="/" />
                        <div style={menuStyle}>
                            <HomeOutlined /> Home
                        </div>
                    </Menu.Item>
                    <Menu.Item key="/messages" style={menuItemStyle}>
                        <Link onClick={onClick} to="/messages">
                            <div style={menuStyle}>
                                {/*<Badge dot={true} style={{pointerEvents: 'none'}} >
                                    <MessageOutlined /> Messages
                                </Badge>*/}
                                <MessageOutlined /> Messages
                            </div>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="/all-users" style={menuItemStyle}>
                        <Link onClick={onClick} to="/all-users"/>
                        <div style={menuStyle}>
                            <TeamOutlined /> Users
                        </div>
                    </Menu.Item>
                    {/*<Menu.Item key="/all-tutors" style={menuItemStyle}>
                        <Link onClick={onClick} to="/all-tutors"/>
                        <div style={menuStyle}>
                            <TeamOutlined /> Tutors
                        </div>
                    </Menu.Item>
                    <Menu.Item key="/all-organizations" style={menuItemStyle}>
                        <Link onClick={onClick} to="/all-organizations"/>
                        <div style={menuStyle}>
                            <ClusterOutlined /> Organizations
                        </div>
                    </Menu.Item>*/}

                </Menu>
            )
        } else if (SessionManager.isLoggedInAsPaymentManager()) {
            return (
                <Menu size="large" selectedKeys={[this.props.location.pathname]} style={{height: height, marginRight: '15px' /*15*/, background: 'transparent'}} mode={mode}>
                    <Menu.Item key="/" style={menuItemStyle}>
                        <Link onClick={onClick} to="/" />
                        <div style={menuStyle}>
                            <HomeOutlined /> Home
                        </div>
                    </Menu.Item>
                    <Menu.Item key="/messages" style={menuItemStyle}>
                        <Link onClick={onClick} to="/messages">
                            <div style={menuStyle}>
                                <MessageOutlined /> Messages
                            </div>
                        </Link>
                    </Menu.Item>
                    
                    <Menu.Item key="/all-withdraws" style={menuItemStyle}>
                        <Link onClick={onClick} to="/all-withdraws"/>
                        <div style={menuStyle}>
                            <DownCircleOutlined /> Withdraws
                        </div>
                    </Menu.Item>

                    <Menu.Item key="/all-transactions" style={menuItemStyle}>
                        <Link onClick={onClick} to="/all-transactions"/>
                        <div style={menuStyle}>
                            <DollarCircleOutlined /> Transactions
                        </div>
                    </Menu.Item>

                    
                    <Menu.Item key="/all-subscribers" style={menuItemStyle}>
                        <Link onClick={onClick} to="/all-subscribers"/>
                        <div style={menuStyle}>
                            <IdcardOutlined /> Subscribers
                        </div>
                    </Menu.Item>

                    <Menu.Item key="/all-tutors" style={menuItemStyle}>
                        <Link onClick={onClick} to="/all-tutors"/>
                        <div style={menuStyle}>
                            <TeamOutlined /> Tutors
                        </div>
                    </Menu.Item>

                </Menu>
            )
        } else {
            return (
                <Menu size="large" selectedKeys={[this.props.location.pathname]} style={{height: height, marginRight: '0px' /*15*/, background: 'transparent'}} mode={mode}>
                    <Menu.Item key="/" style={menuItemStyle}>
                        <Link onClick={onClick} to="/" />
                        <div style={menuStyle}>
                            <HomeOutlined /> Home
                        </div>
                    </Menu.Item>
                    {SessionManager.isLoggedInAsStudentOrTutor() ? (
                        <Menu.Item key="/discovery" style={menuItemStyle}>
                            <Link onClick={onClick} to="/discovery"/>
                            <div style={menuStyle}>
                                <ShareAltOutlined /> Discovery
                            </div>
                        </Menu.Item>
                    ) : null}
                    <Menu.Item key="/messages" style={menuItemStyle}>
                        <Link onClick={onClick} to="/messages">
                            <div style={menuStyle}>
                                {/*<Badge dot={true} style={{pointerEvents: 'none'}} >
                                    <MessageOutlined /> Messages
                                </Badge>*/}
                                <MessageOutlined /> Messages
                            </div>
                        </Link>
                    </Menu.Item>
                    {SessionManager.isLoggedInAsStudent() || SessionManager.isLoggedInAsOrganization() ? (
                        <Menu.Item key={SessionManager.isLoggedInAsStudent() ? "/courses" : "/my-courses"} style={menuItemStyle}>
                            <Link onClick={onClick} to={SessionManager.isLoggedInAsStudent() ? "/courses" : "/my-courses"} />
                            <div style={menuStyle}>
                                <BookOutlined /> Courses
                            </div>
                        </Menu.Item>
                    ) : (
                        <Menu.Item key="/courses" style={menuItemStyle}>
                            <Dropdown trigger="hover" overlay={
                                <Menu>
                                    <Menu.Item key="/my-courses">
                                        <Link onClick={onClick} to="/my-courses">
                                            <div style={{display: 'flex', height: '35px', alignItems: 'center'}}>
                                                {/*<FormOutlined /> &nbsp;&nbsp; My Courses*/}
                                                <FormOutlined /> &nbsp;&nbsp; Tutoring
                                            </div>
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="/courses" style={{height: '50px'}}>
                                        <Link onClick={onClick} to="/courses">
                                            <div style={{display: 'flex', height: '35px', alignItems: 'center'}}>
                                                {/*<CreditCardOutlined /> &nbsp;&nbsp; Purchased Courses*/}
                                                <BookOutlined /> &nbsp;&nbsp; Learning
                                            </div>
                                        </Link>
                                    </Menu.Item>
                                </Menu>
                            }>
                                <div style={menuStyle}>
                                    <BookOutlined /> Courses
                                </div>
                            </Dropdown>
                        </Menu.Item>
                    )}
    
                    {SessionManager.isLoggedInAsStudent() || SessionManager.isLoggedInAsOrganization() ? (
                        <Menu.Item key={SessionManager.isLoggedInAsStudent() ? "/live-sessions" : "/my-live-sessions"} style={menuItemStyle}>
                            <Link onClick={onClick} to={SessionManager.isLoggedInAsStudent() ? "/live-sessions" : "/my-live-sessions"} />
                            <div style={menuStyle}>
                                <AlertOutlined /> Live Sessions
                            </div>
                        </Menu.Item>
                    ) : (
                        <Menu.Item key="/live-sessions" style={menuItemStyle}>
                            <Dropdown trigger="hover" overlay={
                                <Menu>
                                    <Menu.Item key="/my-live-sessions">
                                        <Link onClick={onClick} to="/my-live-sessions">
                                            <div style={{display: 'flex', height: '35px', alignItems: 'center'}}>
                                                {/*<FormOutlined /> &nbsp;&nbsp; My Live Sessions*/}
                                                <FormOutlined /> &nbsp;&nbsp; Tutoring
                                            </div>
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="/live-sessions" style={{height: '50px'}}>
                                        <Link onClick={onClick} to="/live-sessions">
                                            <div style={{display: 'flex', height: '35px', alignItems: 'center'}}>
                                                {/*<CreditCardOutlined /> &nbsp;&nbsp; Purchased Live Sessions*/}
                                                <BookOutlined /> &nbsp;&nbsp; Learning
                                            </div>
                                        </Link>
                                    </Menu.Item>
                                </Menu>
                            }>
                                <div style={menuStyle}>
                                    <AlertOutlined /> Live Sessions
                                </div>
                            </Dropdown>
                        </Menu.Item>
                    )}
                    
                    
                    {SessionManager.isLoggedInAsOrganization() ? (
                        <Menu.Item key="/users" style={menuItemStyle}>
                            <Link onClick={onClick} to="/users"/>
                            <div style={menuStyle}>
                                <UserOutlined /> Users
                            </div>
                        </Menu.Item>
                    ) : null}
                    {/*this.state.organizations.length === 1 && (
                        <Menu.Item key={"/organization/" + this.state.organizations[0].id} style={{height: height}}>
                            <Link onClick={onClick} to={"/organization/" + this.state.organizations[0].id}>
                                <div style={menuStyle}>
                                    <ProfilePicture userId={this.state.organizations[0].id} />
                                    &nbsp;&nbsp;
                                    <h5 style={{height: '40px', marginRight: '10px'}}>{this.state.organizations[0].fullName}</h5>
                                </div>
                            </Link>
                        </Menu.Item>
                    )*/}
                    {this.state.organizations.length > 0 && (
                        <Menu.Item style={menuItemStyle}>
                            <Dropdown trigger="hover" overlay={
                                <Menu>
                                    {this.state.organizations.map(organization => (
                                        <Menu.Item key={"/organization/" + organization.id} onClick={() => this.props.history.push("/organization/" + organization.id)}>
                                            {/*<Link onClick={onClick} to={"/organization/" + organization.id}/>*/}
                                            <div style={{display: 'flex', height: '40px', alignItems: 'center'}}>
                                                <ProfilePicture userId={organization.id} />
                                                &nbsp;&nbsp;
                                                <h5 style={{height: '18px', marginLeft: '5px'}}>{organization.fullName}</h5>
                                            </div>
                                        </Menu.Item>
                                    ))}
                                </Menu>
                            }>
                                <div style={menuStyle}>
                                    <ClusterOutlined /> Organizations
                                </div>
                            </Dropdown>
                        </Menu.Item>
                    )}
    
                </Menu>
            )
        }
    }

    renderPresence(presence) {
        switch (presence) {
            case PRESENCE_AVAILABLE:
                return <> <div style={{height: '10px', background: 'green', width: '10px', marginRight: '5px', borderRadius: '50%'}} /> Available <DownOutlined style={{fontSize: '10px', marginLeft: '5px'}} /> </>
            case PRESENCE_AWAY:
                return <> <div style={{height: '10px', background: '#fbc02d', width: '10px', marginRight: '5px', borderRadius: '50%'}} /> Away <DownOutlined style={{fontSize: '10px', marginLeft: '5px'}} /> </>
            case PRESENCE_DO_NOT_DISTURB:
                return <> <div style={{height: '10px', background: 'red', width: '10px', marginRight: '5px', borderRadius: '50%'}} /> Do not disturb <DownOutlined style={{fontSize: '10px', marginLeft: '5px'}} /> </>
            case PRESENCE_OFFLINE:
                return <> <div style={{height: '10px', background: '#cecece', width: '10px', marginRight: '5px', borderRadius: '50%'}} /> Hidden <DownOutlined style={{fontSize: '10px', marginLeft: '5px'}} /> </>
        }
    }

    getLayout() {
        return (
            <div style={{height: 'auto', minHeight: '100vh', display: 'flex', flexDirection: 'column'}}>
                {/*<Sticky bottomOffset={80}>
                    {({ style }) => (*/}
                    {/*)}
                </Sticky>*/}

                <div className="sticky-header">
                    <MediaQuery maxWidth={619}>
                        <div style={{paddingRight: '2vw', height: '60px', boxShadow: "0px 0px 15px 4px rgba(0, 0, 0, 0.5)", display: 'flex', alignItems: 'center' }}>
                            <Button type="link" onClick={e => this.setState({drawerMenuOpen: true})}><MenuOutlined /></Button>
                            <Link to="/"><img src={Logo} style={{height: '35px', marginLeft: '2vw'}} /></Link>
                            <div style={{flex: '1'}} />
                            <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                                {/*<Button shape="circle" style={{marginRight: '20px', display: 'flex', alignItems: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <BellFilled style={{fontSize: '15px'}} />
                                </Button>*/}
                                

                                {this.renderUpcomingLiveStreamBtn()}
                                {this.renderNotificationBtn()}
                                {this.renderProfile()}
                            </div>
                        </div>
                    </MediaQuery>
                    <MediaQuery minWidth={620} maxWidth={1223}>
                        <Row style={{paddingRight: '2vw', height: '60px', 
                                        boxShadow: "0px 0px 15px 4px rgba(0, 0, 0, 0.5)" }} align="middle">
                            <Col span={1}>
                                <Space>
                                    <Button type="link" onClick={e => this.setState({drawerMenuOpen: true})}><MenuOutlined /></Button>
                                </Space>
                            </Col>
                            <Col span={4}>
                                <Space>
                                    <Link to="/"><img src={Logo} style={{height: '35px', marginLeft: '15px'}} /></Link>
                                </Space>
                            </Col>
                            <Col span={19} style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                                <Input placeholder="Search Learnerix" onPressEnter={e => Util.isStringExists(this.state.searchValue) ? this.props.history.push("/search?value=" + this.state.searchValue) : null} value={this.state.searchValue} onChange={e => this.setState({searchValue: e.target.value})} prefix={<SearchOutlined style={{marginRight: '5px'}} />} className="header-search-field" style={{marginRight: '25px', height: '45px', borderRadius: '15px', boxShadow: "0px 0px 0px 0px rgba(0, 0, 0, 0.2)"}} />

                                {/*SessionManager.isLoggedInAsTutorONLY() ? (
                                    <Dropdown overlay={
                                        <Menu>
                                            <Menu.Item onClick={() => this.setPresence(PRESENCE_AVAILABLE)} style={{display: 'flex', alignItems: 'center'}}>
                                                <div style={{height: '10px', background: 'green', width: '10px', marginRight: '5px', borderRadius: '50%'}} /> Available
                                            </Menu.Item>
                                            <Menu.Item onClick={() => this.setPresence(PRESENCE_AWAY)} style={{display: 'flex', alignItems: 'center'}}>
                                                <div style={{height: '10px', background: '#fbc02d', width: '10px', marginRight: '5px', borderRadius: '50%'}} /> Away
                                            </Menu.Item>
                                            <Menu.Item onClick={() => this.setPresence(PRESENCE_DO_NOT_DISTURB)} style={{display: 'flex', alignItems: 'center'}}>
                                                <div style={{height: '10px', background: 'red', width: '10px', marginRight: '5px', borderRadius: '50%'}} /> Do not disturb
                                            </Menu.Item>
                                            <Menu.Item onClick={() => this.setPresence(PRESENCE_OFFLINE)} style={{display: 'flex', alignItems: 'center'}}>
                                                <div style={{height: '10px', background: '#cecece', width: '10px', marginRight: '5px', borderRadius: '50%'}} /> Hidden
                                            </Menu.Item>
                                        </Menu>
                                    } trigger="click">
                                        <Button loading={this.state.settingPresence} type="text" style={{marginRight: '5px', display: 'flex', alignItems: 'center'}}>
                                            {this.renderPresence(this.state.presence)}
                                        </Button>
                                    </Dropdown>
                                ) : null*/}

                                {/*<Button shape="circle" style={{marginRight: '20px', display: 'flex', alignItems: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <BellFilled style={{fontSize: '15px'}} />
                                </Button>*/}

                                {this.renderUpcomingLiveStreamBtn()}
                                {this.renderNotificationBtn()}
                                {this.renderProfile()}
                            </Col>
                        </Row>
                    </MediaQuery>
                    <MediaQuery minWidth={1224}>
                        <Row style={{paddingLeft: '25px', paddingRight: '25px', height: '75px', 
                                        boxShadow: "0px 0px 15px 4px rgba(0, 0, 0, 0.5)" }} align="middle">
                            <Col span={4}>
                                <Space>
                                    <Link to="/"><img src={Logo} style={{height: '50px'}} /></Link>
                                </Space>
                            </Col>
                            <Col span={20} style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                                <Input placeholder="Search Learnerix" onPressEnter={e => Util.isStringExists(this.state.searchValue) ? this.props.history.push("/search?value=" + this.state.searchValue) : null} value={this.state.searchValue} onChange={e => this.setState({searchValue: e.target.value})} prefix={<SearchOutlined style={{marginRight: '5px'}} />} className="header-search-field" style={{marginRight: '25px', height: '45px', borderRadius: '15px', boxShadow: "0px 0px 0px 0px rgba(0, 0, 0, 0.2)"}} />
                                {//onChange={e => this.onSearch(e.target.value)} />
                                }

                                {this.renderMenu("horizontal")}

                                {/*SessionManager.isLoggedInAsTutorONLY() ? (
                                    <Dropdown overlay={
                                        <Menu>
                                            <Menu.Item onClick={() => this.setPresence(PRESENCE_AVAILABLE)} style={{display: 'flex', alignItems: 'center'}}>
                                                <div style={{height: '10px', background: 'green', width: '10px', marginRight: '5px', borderRadius: '50%'}} /> Available
                                            </Menu.Item>
                                            <Menu.Item onClick={() => this.setPresence(PRESENCE_AWAY)} style={{display: 'flex', alignItems: 'center'}}>
                                                <div style={{height: '10px', background: '#fbc02d', width: '10px', marginRight: '5px', borderRadius: '50%'}} /> Away
                                            </Menu.Item>
                                            <Menu.Item onClick={() => this.setPresence(PRESENCE_DO_NOT_DISTURB)} style={{display: 'flex', alignItems: 'center'}}>
                                                <div style={{height: '10px', background: 'red', width: '10px', marginRight: '5px', borderRadius: '50%'}} /> Do not disturb
                                            </Menu.Item>
                                            <Menu.Item onClick={() => this.setPresence(PRESENCE_OFFLINE)} style={{display: 'flex', alignItems: 'center'}}>
                                                <div style={{height: '10px', background: '#cecece', width: '10px', marginRight: '5px', borderRadius: '50%'}} /> Hidden
                                            </Menu.Item>
                                        </Menu>
                                    } trigger="click">
                                        <Button loading={this.state.settingPresence} type="text" style={{marginRight: '5px', display: 'flex', alignItems: 'center'}}>
                                            {this.renderPresence(this.state.presence)}
                                        </Button>
                                    </Dropdown>
                                ) : null*/}

                                {/*<Button shape="circle" style={{marginRight: '20px', display: 'flex', alignItems: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <BellFilled style={{fontSize: '15px'}} />
                                </Button>*/}

                                {this.renderUpcomingLiveStreamBtn()}
                                {this.renderNotificationBtn()}
                                {this.renderProfile()}
                            </Col>
                        </Row>
                    </MediaQuery>
                </div>

                {SessionManager.isLoggedInAsStudent() ? (
                    <StudentProfileDialog 
                    visible={this.state.showLearnerProfile}
                    name={SessionManager.getAccount().fullName}
                    onProfilePicUpdate={this.onProfilePicUpdate.bind(this)}
                    onCancel={() => this.setState({showLearnerProfile: false})} />
                ) : null}

                {SessionManager.isLoggedInAsBlogger() ? (
                    <BloggerProfileDialog 
                    visible={this.state.showBloggerProfile}
                    name={SessionManager.getAccount().fullName}
                    onProfilePicUpdate={this.onProfilePicUpdate.bind(this)}
                    onCancel={() => this.setState({showBloggerProfile: false})} />
                ) : null}

                <div style={{flex: '1', display: 'flex', flexDirection: 'column'}}>
                    <Switch>
                        {/* Pages */}
                        <Route exact path="/" component={SessionManager.isLoggedInAsBlogger() ? MyBlogsPage : FollowingPostsPage} />
                        <Route exact path="/messages" component={MessagesPage} />
                        {/*<Route exact path="/courses" component={SessionManager.isLoggedInAsTutor() ? TutorMyCoursesPage : StudentMyCoursesPage} />*/}
                        {SessionManager.isLoggedInAsStudentOrTutor() && <Route exact path="/courses" component={StudentMyCoursesPage} />}
                        {SessionManager.isLoggedInAsTutor() && <Route exact path="/my-courses" component={TutorMyCoursesPage} />}

                        {/*<Route exact path="/live-sessions" component={SessionManager.isLoggedInAsTutor() ? TutorMyLiveSessionsPage : StudentMyLiveSessionsPage} />*/}
                        {SessionManager.isLoggedInAsStudentOrTutor() && <Route exact path="/live-sessions" component={StudentMyLiveSessionsPage} />}
                        {SessionManager.isLoggedInAsTutor() && <Route exact path="/my-live-sessions" component={TutorMyLiveSessionsPage} />}

                        {SessionManager.isLoggedInAsStudentOrTutor() && <Route exact path="/discovery" component={DiscoveryPage} />}

                        <Route exact path="/my-profile" render={(props) => <TutorProfilePage {...props} myProfile={true} onProfilePicUpdate={this.onProfilePicUpdate.bind(this)} />} />
                        <Route exact path="/profile/:tutorId" component={TutorProfilePage} />
                        <Route exact path="/post/:postId" component={PostPage} />

                        <Route exact path="/account-settings" component={AccountSettingsPage} />

                        <Route exact path="/all-courses" component={AllCoursesPage} />
                        <Route exact path="/course-category/:categoryName" component={CourseCategoryPage} />

                        {/*<Route exact path="/course/:courseId" component={SessionManager.isLoggedInAsTutor() ? TutorCoursePage : StudentCoursePage} />*/}
                        {SessionManager.isLoggedInAsStudentOrTutor() && <Route exact path="/course/:courseId" component={StudentCoursePage} />}
                        {SessionManager.isLoggedInAsTutor() && <Route exact path="/my-course/:courseId" component={TutorCoursePage} />}

                        <Route exact path="/course-sale/:courseId" component={CourseSalePage} />

                        {SessionManager.isLoggedInAsStudentOrTutor() && <Route exact path="/active-help-session/:courseId" component={ActiveHelpSessionPage} />}

                        {/*<Route exact path="/help-session/:courseId" component={SessionManager.isLoggedInAsTutor() ? TutorHelpSessionPage : StudentHelpSessionHistoryPage} />*/}
                        {SessionManager.isLoggedInAsStudentOrTutor() && <Route exact path="/help-session/:courseId" component={StudentHelpSessionHistoryPage} />}
                        {SessionManager.isLoggedInAsTutor() && <Route exact path="/my-help-session/:courseId" component={TutorHelpSessionPage} />}
                        {SessionManager.isLoggedInAsTutor() && <Route exact path="/my-help-session-history/:courseId" component={HelpSessionHistoryPage} />}

                        <Route exact path="/all-live-sessions" component={AllLiveSessionsPage} />
                        <Route exact path="/live-session-category/:categoryName" component={LiveSessionCategoryPage} />

                        {/*<Route exact path="/live-session/:liveSessionId" component={SessionManager.isLoggedInAsTutor() ? TutorLiveSessionPage : StudentLiveSessionPage} />*/}
                        {SessionManager.isLoggedInAsStudentOrTutor() && <Route exact path="/live-session/:liveSessionId" component={StudentLiveSessionPage} />}
                        {SessionManager.isLoggedInAsTutor() && <Route exact path="/my-live-session/:liveSessionId" component={TutorLiveSessionPage} />}

                        <Route exact path="/live-session-sale/:liveSessionId" component={LiveSessionSalePage} />

                        {/*<Route exact path="/live-stream-recording/:liveStreamId" component={LiveStreamRecordingPage} />*/}

                        <Route exact path="/reviews/:itemType/:itemId" component={RatingsPage} />

                        <Route exact path="/search" component={SearchPage} />
                        <Route exact path="/all-tutors" component={AllTutorsPage} />
                        <Route exact path="/all-organizations" component={AllOrganizationsPage} />
                        <Route exact path="/all-announcements" component={AllAnnouncementsPage} />

                        {SessionManager.isLoggedInAsRelationManager() && <Route exact path="/all-users" component={AllUsersPage} />}

                        {SessionManager.isLoggedInAsOrganization() && <Route exact path="/users" component={OrganizationUsersPage} />}
                        <Route exact path="/organization/:organizationId" component={EnrolledOrganizationPage} />

                        <Route exact path="/privacy-policy" component={PrivacyPolicyPage} />
                        <Route exact path="/terms-of-service" component={TermsOfServicePage} />
                        <Route exact path="/about-us" component={AboutUsPage} />
                        <Route exact path="/contact" component={ContactUsPage} />

                        <Route exact path="/blog" component={BlogPage} />
                        <Route exact path="/blog-post" component={BlogPostPage} />

                        <Route exact path="/hashtag/:hashtag" component={HashtagPage} />
                        <Route exact path="/hashtag-courses/:hashtag" component={HashtagCoursesPage} />
                        <Route exact path="/hashtag-live-sessions/:hashtag" component={HashtagLiveSessionsPage} />

                        {SessionManager.isLoggedInAsTutor() && <Route exact path="/balance" component={TutorBalancePage} />}
                        {SessionManager.isLoggedInAsPaymentManager() && <Route exact path="/all-withdraws" component={AllWithdrawsPage} />}
                        {SessionManager.isLoggedInAsPaymentManager() && <Route exact path="/all-transactions" component={AllTransactionsPage} />}
                        {SessionManager.isLoggedInAsPaymentManager() && <Route exact path="/all-subscribers" component={AllSubscribersPage} />}

                        <Route exact path="/settings" component={SettingsPage} />

                        {SessionManager.isLoggedInAsBlogger() && <Route exact path="/my-blog" component={MyBlogPostPage} />}

                        {SessionManager.isLoggedInAsContentManager() && <Route exact path="/content-management" component={ContentManagementPage} />}
                        {SessionManager.isLoggedInAsContentManager() && <Route exact path="/featured-selection/:featuredItemType" component={FeaturedSelectorPage} />}
                        {SessionManager.isLoggedInAsContentManager() && <Route exact path="/content-management-preview" component={LandingPage} />}

                        {SessionManager.isLoggedInAsTutor() && <Route exact path="/live-stream-usage" component={LiveStreamCreditsPage} />}
                        {SessionManager.isLoggedInAsOrganization() && <Route exact path="/user-allowance" component={UserAllowancePage} />}
                        {SessionManager.isLoggedInAsOrganization() && <Route exact path="/billing" component={BillingPage} />}

                        <Route component={Error404Page} />
                    </Switch>
                </div>

                <MediaQuery maxWidth={1223}>
                    <Drawer
                    title={<Link to="/" onClick={e => this.setState({drawerMenuOpen: false})}><img src={Logo} style={{height: '25px'}} /></Link>}
                    placement="left"
                    closable={true}
                    bodyStyle={{padding: '0'}}
                    onClose={() => this.setState({drawerMenuOpen: false})}
                    visible={this.state.drawerMenuOpen}
                    >
                        <MediaQuery maxWidth={619}>
                            <Input placeholder="Search Learnerix" onPressEnter={e => Util.isStringExists(this.state.searchValue) ? this.props.history.push("/search?value=" + this.state.searchValue) : null} value={this.state.searchValue} onChange={e => this.setState({searchValue: e.target.value})} prefix={<SearchOutlined style={{marginRight: '5px'}} />} className="header-search-field-unanimated" style={{width: '100%', height: '45px', borderRadius: '15px', boxShadow: "0px 0px 0px 0px rgba(0, 0, 0, 0.2)"}} />
                        </MediaQuery>
                        {this.renderMenu("inline")}
                    </Drawer>
                </MediaQuery>

                {/*<Modal footer={null} visible={this.state.showSubscribeDialog} onCancel={() => this.setState({showSubscribeDialog: false})} bodyStyle={{padding: 0, background: 'black'}} closeIcon={<CloseOutlined style={{color: 'white'}} />}>
                    <div style={{width: '100%', backgroundColor: '#2d3436', backgroundImage: 'linear-gradient(315deg, #2d3436 0%, #000000 74%)',
                    borderRadius: '5px', display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '50px', paddingBottom: '50px'}}>
                        <img src={LogoWhite} style={{width: '125px'}} />
                        <img src={LiveIcon} style={{width: '30px'}} />

                        <div style={{height: '40px'}} />

                        <h3 style={{color: 'white'}}>Be in class, be home</h3>

                        <p style={{padding: '10px', paddingLeft: '25px', paddingRight: '25px', color: 'rgb(187,187,187)', fontSize: '12px', textAlign: 'center',overflowWrap: 'break-word', whiteSpace: 'pre-line'}}>Gain access to Help Sessions and Live Streams in courses that offer them by subscribing, to aid your studies by answering your specific questions and needs.</p>

                        <div style={{height: '25px'}} />

                        <p style={{color: 'rgb(187,187,187)', fontSize: '10px', textAlign: 'center', overflowWrap: 'break-word', whiteSpace: 'pre-line'}}>9.99 USD / month</p>
                        <Button size="medium" type="primary" className="red-button"><CreditCardOutlined /> Subscribe</Button>
                    </div>
                </Modal>*/}
                <LiveSubscriptionDialog visible={this.state.showSubscribeDialog} onCancel={() => this.setState({showSubscribeDialog: false})} />
                {SessionManager.isLoggedInAsOrganization() && <OrganizationAccessDialog visible={this.state.showOrganizationAccessDialog} onCancel={() => this.setState({showOrganizationAccessDialog: false})} />}

                {this.state.showReceivedInvites &&
                <UserListDialog
                    title="Received Organization Invites"
                    emptyMessage="No invites currenly received/pending"
                    width="60vw"
                    visible={this.state.showReceivedInvites}
                    onCancel={() => this.setState({showReceivedInvites: false})}
                    footer={null}
                    showReceivedInvites
                    inviteAcceptListener={user => this.setState(prevState => ({organizations: [user, ...prevState.organizations]}))}>
                </UserListDialog>}

                <Modal title="Live Stream" visible={this.state.showAccountLiveStreamStartDialog}
                confirmLoading={this.state.startingAccountLiveStream} onOk={() => {
                    this.setState({startingAccountLiveStream: true});
                    Api.startAccountLiveStream(response => {
                        if (response.status === true) {
                            this.state.myAccountInfo.liveStreamId = response.payload;
                            this.props.history.push(Util.getLiveStreamPath(response.payload))
                        } else {
                            UIUtil.showError();
                        }
                        this.setState({showAccountLiveStreamStartDialog: false, startingAccountLiveStream: false})
                    })
                }} onCancel={() => this.setState({showAccountLiveStreamStartDialog: false})}>
                    <p>Are you sure you want to start a live stream for 1 hour? Your followers will recieve a notification to know your live streaming!</p>
                </Modal>
            </div>
        )
    }

    isLockBehindAuth() {
        return true;
    }

    getScope() {
        return ACCOUNT_SCOPE_FRONTEND;
    }

    getRole() {
        return -1;
    }
    
}

export default withRouter(FrontendWindow);