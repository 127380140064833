import React, { useEffect } from 'react'
import { isAndroid, isIOS } from 'react-device-detect';

import Logo from '../images/Logo.png'
import GooglePlayButton from '../images/GooglePlayButton.png'
import AppStoreButton from '../images/AppStoreButton.png'

import { Button, Col, Divider, Row, Space } from 'antd';
import { APP_STORE_APP_LINK, GOOGLE_PLAY_APP_LINK } from '../Constants';

export default ({ }) => {
    useEffect(() => {
        if (isIOS) {
            window.location.replace(APP_STORE_APP_LINK)
        } else if (isAndroid) {
            window.location.replace(GOOGLE_PLAY_APP_LINK)
        }
    }, []);

    return (
        <div style={{width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
            <img src={Logo} />

            <h1 style={{ color: 'black', margin: 0 }}>Mobile Apps</h1>
            <h2 style={{margin: 0}}>Welcome to Learnerix!</h2>
            <Divider style={{ border: '1px solid #f0f0f0' }} />

            {/*<img style={{ width: '80%' }} src={apps} />*/}
            <a target="_blank" href={GOOGLE_PLAY_APP_LINK} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <img style={{ width: '50%', objectFit: 'contain', objectPosition: 'center' }} src={GooglePlayButton} />
            </a>
            <a target="_blank" href={APP_STORE_APP_LINK} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <img style={{ width: '50%', objectFit: 'contain', objectPosition: 'center', marginTop: 9 }} src={AppStoreButton} />
            </a>
        </div>
    )
}