import React from 'react'
import Page from '../../base/Page';
import withMediaQuery from '../../components/WithMediaQuery';

import { 
    EyeOutlined,
    ClusterOutlined,
    AlertOutlined,
    ArrowRightOutlined,
    BookOutlined,
    UserOutlined,
    FireFilled,
    FireOutlined
} from '@ant-design/icons';
import { Button, Divider } from 'antd';
import { Link } from 'react-router-dom';
import { FEATURED_ITEM_TYPE_COURSES, FEATURED_ITEM_TYPE_HASHTAGS, FEATURED_ITEM_TYPE_LIVE_SESSIONS, FEATURED_ITEM_TYPE_ORGANIZATIONS, FEATURED_ITEM_TYPE_TUTORS } from './FeaturedSelectorPage';
import Util from '../../util/Util';
import Api from '../../session/Api';

const ContentSection = ({icon, title, stats, featuredItemType}) => (
    <div style={{ marginTop: 7}}>
        <Link to={"/featured-selection/" + featuredItemType}>
            <div className="settings-item" style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10}}>
                <Button size="large" type="link" style={{marginRight: 10, marginLeft: 2, pointerEvents: 'none', padding: 2}} >
                    {React.createElement(icon, {
                        style: {
                            fontSize: 24, marginTop: 3
                        }
                    })}
                </Button>
                <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                    <h3 style={{color: 'black', margin: 0}}>{title}</h3>
                    {stats !== undefined &&
                    <h5 style={{color: '#1f1f1f', opacity: 0.65, margin: 0, marginTop: -4, fontWeight: 'normal', flex: 1}}>
                        {stats.lastUpdateDate == -1 ? (<> 
                            No items currently featured
                        </>) : (<>
                            Latest feature set on {Util.getDateOnly(stats.lastUpdateDate)}
                        </>)}
                    </h5>}
                </div>
                {stats !== undefined && <h3 style={{color: 'black', margin: 0, marginRight: 10}}>({stats.count}/{stats.max})</h3>}
                {<Button type="link" style={{marginTop: 2}}><ArrowRightOutlined /></Button>}
            </div>
        </Link>
    </div>
)

class ContentManagementPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            stats: {}
        }
    }

    componentDidMount() {
        Api.getContentManagementStats(response => {
            if (response.status === true) {
                this.setState({stats: response.payload.featuredStats});
            }
        })
    }

    render() {
        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div style={{width: this.props.renderFullWidth ? '90vw' : '75vw', paddingTop: '25px', paddingBottom: '25px'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <h2 style={{height: '25px', flex: 1}}>Content Management</h2>
                        <Link to="/content-management-preview">
                            <Button icon={<EyeOutlined />} type="primary" style={{marginLeft: '10px'}}>Preview</Button>
                        </Link>
                    </div>
                    <br />
                    <div>
                        <Divider orientation="left" style={{width: '100%', marginTop: 0}}>Trending</Divider>
                        <ContentSection icon={FireOutlined} title="Trending Hashtags" stats={this.state.stats[FEATURED_ITEM_TYPE_HASHTAGS]} featuredItemType={FEATURED_ITEM_TYPE_HASHTAGS} />

                        <Divider orientation="left" style={{width: '100%', marginTop: 25}}>Courses</Divider>
                        <ContentSection icon={BookOutlined} title="Featured Courses" stats={this.state.stats[FEATURED_ITEM_TYPE_COURSES]} featuredItemType={FEATURED_ITEM_TYPE_COURSES} />
                        
                        <Divider orientation="left" style={{width: '100%', marginTop: 25}}>Live Sessions</Divider>
                        <ContentSection icon={AlertOutlined} title="Featured Live Sessions" stats={this.state.stats[FEATURED_ITEM_TYPE_LIVE_SESSIONS]} featuredItemType={FEATURED_ITEM_TYPE_LIVE_SESSIONS} />

                        <Divider orientation="left" style={{width: '100%', marginTop: 25}}>Users</Divider>
                        <ContentSection icon={UserOutlined} title="Featured Tutors" stats={this.state.stats[FEATURED_ITEM_TYPE_TUTORS]} featuredItemType={FEATURED_ITEM_TYPE_TUTORS} />
                        <ContentSection icon={ClusterOutlined} title="Featured Organizations" stats={this.state.stats[FEATURED_ITEM_TYPE_ORGANIZATIONS]} featuredItemType={FEATURED_ITEM_TYPE_ORGANIZATIONS} />
                    </div>
                </div>
            </div>
        )
    }

}

export default withMediaQuery(ContentManagementPage);