import { Button, Checkbox, DatePicker, Empty, Input, InputNumber, Radio, Spin, Upload } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React from 'react'
import InfiniteScroll from 'react-infinite-scroller';
import { NO_PAGE_TOKEN } from '../../Constants';
import Api from '../../session/Api';
import UIUtil from '../../util/UIUtil';
import Util from '../../util/Util';

import {  
    PlusOutlined,
    InboxOutlined
} from '@ant-design/icons';

import NoImage from '../../images/NoImage.png';
import CourseScheduleItem from './CourseScheduleItem';
import LiveStreamWindow from '../../livestream2/windows/LiveStreamWindow';

const FormPadding = () => {
    return ( <> <br /> <br /> </> )
}

class CourseLiveStreamsView extends React.Component {

    constructor(props) {
        super(props) 

        this.state = {
            items: [],
            page: NO_PAGE_TOKEN,
            hasMore: true,
            loadingItems: false,

            filterMode: "upcoming",

            newScheduleThumbnail: undefined,
            newScheduleTitle: "",
            newScheduleDate: undefined,
            newScheduleDuration: 0,
            newScheduleRecord: true,
            newScheduleDesc: "",
            newSchedule: false,
            creatingNewSchedule: false,

            updateScheduleThumbnail: undefined,
            updateScheduleTitle: "",
            updateScheduleDesc: "",
            updateSchedule: undefined,
            updatingSchedule: false,

            playLiveStream: undefined
        }
    }

    componentDidMount() {
        //this.getNextItems()
    }

    setFilterMode(filterMode) {
        this.setState({ filterMode, items: [], page: NO_PAGE_TOKEN, hasMore: true, loadingItems: false, }, () => {
            //this.getNextItems();
        });
    }

    getNextItems() {
        if (this.state.loadingItems) {
            return;
        }

        this.setState({
            loadingItems: true
        })
        //list error
        const listener = response => {
            if (response.status === true) {
                let items = this.state.items;
                items.push(...response.payload.items);

                this.setState({
                    items: items,
                    page: response.payload.token,
                    hasMore: Util.isStringExists(response.payload.token),
                    loadingItems: false
                })
            } else {
                //alert('errowr');
            }
        }

        if (this.state.filterMode == "upcoming") {
            Api.getUpcomingCourseSchedules(this.props.courseId, this.state.page, listener);
        } else if (this.state.filterMode == "recorded") {
            Api.getRecordedCourseSchedules(this.props.courseId, this.state.page, listener);
        } else {
            Api.getCourseSchedules(this.props.courseId, this.state.page, listener);
        }
    };

    newSchedule(thumbnail, successListener) {
        if (this.state.newScheduleDate.valueOf() < new Date().getTime()) {
            UIUtil.showError("Can not set a past date")
            return;
        }

        this.setState({creatingNewSchedule: true})
        Api.createCourseSchedule({
            courseId: this.props.courseId,
            title: this.state.newScheduleTitle,
            date: this.state.newScheduleDate.valueOf(),
            duration: this.state.newScheduleDuration,
            recorded: this.state.newScheduleRecord,
            description: this.state.newScheduleDesc,
        }, thumbnail, response => {
            if (response.status === true) {
                this.state.items.unshift(response.payload);

                UIUtil.showSuccess();
                successListener();
            } else {
                UIUtil.showError(response.message);
            }
            this.setState({creatingNewSchedule: false})
        });
    }

    updateSchedule(thumbnail, successListener) {
        this.setState({updatingSchedule: true})
        Api.updateCourseSchedule(this.state.updateSchedule.id, this.state.updateScheduleTitle, this.state.updateScheduleDesc, thumbnail, response => {
            if (response.status === true) {
                const index = this.state.items.indexOf(this.state.updateSchedule);
                if (index != -1) {
                    this.state.items[index] = response.payload;
                }
                if (thumbnail !== undefined) {
                    this.setState({
                        updatingSchedule: false,
                        [response.payload.id + "-thumbnail-suffix"]: new Date().getTime()
                    })
                } else {
                    this.setState({updatingSchedule: false})
                }
                successListener();
            } else {
                UIUtil.showError();
                this.setState({updatingSchedule: false})
            }
        })
    }

    render() {
        return (
            <div style={{paddingRight: 25}}>
                {<>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <Radio.Group value={this.state.filterMode} onChange={e => this.setFilterMode(e.target.value)} style={{flex: 1}}>
                            <Radio.Button value="upcoming">Active/Upcoming</Radio.Button>
                            <Radio.Button value="recorded">Recorded</Radio.Button>
                            <Radio.Button value="all">All</Radio.Button>
                        </Radio.Group>

                        {!this.props.studentMode && <Button type="link" onClick={e => this.setState({newSchedule: true})}>
                            <PlusOutlined /> Create Schedule
                        </Button>}
                    </div>
                    <br /> 
                </>}
                <InfiniteScroll
                    loadMore={this.getNextItems.bind(this)}
                    hasMore={this.state.hasMore}
                    threshold={750}
                    loader={
                        <div style={{display: 'flex', justifyContent: 'center', marginTop: '45px', marginBottom: '45px'}}>
                            <Spin size="large" tip="Loading..." />
                        </div>
                    }
                    style={{overflow: 'visible'}}>
                        {this.state.items.map((item, index) => {
                            return ( 
                                <div key={item.id}>
                                    {index > 0 ? <br /> : null}
                                    <CourseScheduleItem 
                                    studentMode={this.props.studentMode}
                                    schedule={item}
                                    //deletable={!this.props.liveSession.publishedAtLeastOnce}
                                    deletable
                                    thumbnailSuffix={this.state[item.id + "-thumbnail-suffix"]}
                                    /*onClick={() => {
                                        switch (item.type) {
                                            case COURSE_ITEM_TYPE_FILE:
                                                window.location = Api.getScheduleAttachment(item.id)
                                                break;    
                                            default:
                                                this.setState({ selectedSchedule: item });
                                                break;
                                        }
                                    }}*/
                                    playRecording={() => this.setState({playLiveStream: item.liveStreamId})}
                                    onViewAttendanceReport={() => this.setState({selectedLiveStreamId: item.liveStreamId})}
                                    onItemDeleted={() => {
                                        const index = this.state.items.indexOf(item);
                                        if (index != -1) {
                                            this.state.items.splice(index, 1);
                                            this.setState({});
                                        }
                                    }}
                                    onEditBtn={() => this.setState({
                                        updateSchedule: item,
                                        updateScheduleTitle: item.title,
                                        updateScheduleDesc: item.description
                                    })} />
                                </div>
                            )
                        })}
                </InfiniteScroll>

                {!this.state.loadingItems ? <Empty style={{marginTop: '50px'}} image={Empty.PRESENTED_IMAGE_SIMPLE} description={this.state.items.length > 0 ? "No more live streams" : "No live streams"} /> : null}

                <br />


                <Modal
                title="Create Schedule"
                width={750}
                centered
                visible={this.state.newSchedule}
                okText="Upload"
                okButtonProps={{disabled: this.state.newScheduleTitle.trim() === "" || this.state.newScheduleDuration <= 0 || this.state.newScheduleDate === undefined}}
                confirmLoading={this.state.creatingNewSchedule}
                onOk={() => this.newSchedule(this.state.newScheduleThumbnail, 
                    () => this.setState({newScheduleThumbnail: undefined, newScheduleTitle: "", newScheduleDate: undefined, newScheduleDuration: 0, newScheduleRecord: true, newScheduleDesc: "", newSchedule: false}))}
                onCancel={() => this.setState({newScheduleThumbnail: undefined, newScheduleTitle: "", newScheduleDate: undefined, newScheduleDuration: 0, newScheduleRecord: true, newScheduleDesc: "", newSchedule: false})}>
                    <h3>Title</h3>
                    <Input size="large" value={this.state.newScheduleTitle} onChange={e => this.setState({newScheduleTitle: e.target.value})} />
                    <FormPadding />

                    <h3>Date and Time</h3>
                    <DatePicker
                    showTime
                    showSecond={false}
                    minuteStep={5}
                    format="YYYY-MM-DD HH:mm"
                    value={this.state.newScheduleDate}
                    onChange={date => this.setState({newScheduleDate: date})}
                    size="large"
                    style={{width: '100%'}} />
                    <FormPadding />

                    <h3>Duration (minutes)</h3>
                    <InputNumber
                    size="large"
                    style={{width: '100%'}}
                    value={this.state.newScheduleDuration} onChange={value => this.setState({newScheduleDuration: value})} />
                    <FormPadding />

                    <h3>Recording</h3>
                    <Checkbox
                    checked={this.state.newScheduleRecord} onChange={e => this.setState({newScheduleRecord: e.target.checked})}
                    >Record</Checkbox>
                    <FormPadding />

                    <h3>Description</h3>
                    <Input.TextArea maxLength={1000}  size="large" rows={4} value={this.state.newScheduleDesc} onChange={e => this.setState({newScheduleDesc: e.target.value})} />
                    <FormPadding />

                    <h3>Thumbnail</h3>
                    <div style={{display: 'flex', maxHeight: '250px'}}>
                        <img src={this.state.newScheduleThumbnail !== undefined ? URL.createObjectURL(this.state.newScheduleThumbnail) : NoImage} onError={e => {
                            e.target.onerror = null;
                            e.target.src = NoImage;
                        }} style={{width: '300px', height: '250', objectFit: 'cover'}} />
                        <div style={{height: '250px', maxHeight: '250px', flex: 1, marginLeft: '25px'}}>
                            <Upload.Dragger accept="image/gif,image/jpeg,image/png" showUploadList={false} beforeUpload={file => {
                                this.setState({newScheduleThumbnail: file})
                                return false;
                            }}>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            </Upload.Dragger>
                        </div>
                    </div>
                </Modal>

                <Modal
                title="Update"
                width={750}
                centered
                visible={this.state.updateSchedule !== undefined}
                okText="Update"
                okButtonProps={{disabled: this.state.updateScheduleTitle.trim() === ""}}
                confirmLoading={this.state.updatingSchedule}
                onOk={() => this.updateSchedule(this.state.updateScheduleThumbnail, 
                    () => this.setState({updateScheduleThumbnail: undefined, updateScheduleTitle: "", updateScheduleDesc: "", updateSchedule: undefined}))}
                onCancel={() => this.setState({updateScheduleThumbnail: undefined, updateScheduleTitle: "", updateScheduleDesc: "", updateSchedule: undefined})}>    
                    <h2>Details</h2>
                    <h3>Title</h3>
                    <Input size="large" value={this.state.updateScheduleTitle} onChange={e => this.setState({updateScheduleTitle: e.target.value})} />

                    <FormPadding />
                    <h3>Description</h3>
                    <Input.TextArea maxLength={1000}  size="large" rows={4} value={this.state.updateScheduleDesc} onChange={e => this.setState({updateScheduleDesc: e.target.value})} />

                    <FormPadding />

                    <h3>Thumbnail</h3>
                    <div style={{display: 'flex', maxHeight: '250px'}}>
                        <img src={this.state.updateScheduleThumbnail !== undefined ? URL.createObjectURL(this.state.updateScheduleThumbnail) : Api.getCourseScheduleThumbnail(this.state.updateSchedule !== undefined ? this.state.updateSchedule.id : 0)} onError={e => {
                            e.target.onerror = null;
                            e.target.src = NoImage;
                        }} style={{width: '300px', height: '250', objectFit: 'cover'}} />
                        <div style={{height: '250px', maxHeight: '250px', flex: 1, marginLeft: '25px'}}>
                            <Upload.Dragger accept="image/gif,image/jpeg,image/png" showUploadList={false} beforeUpload={file => {
                                this.setState({updateScheduleThumbnail: file})
                                return false;
                            }}>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            </Upload.Dragger>
                        </div>
                    </div>
                </Modal>

                {/* {this.state.selectedLiveStreamId !== undefined &&
                <Modal visible width={700} onCancel={() => this.setState({selectedLiveStreamId: undefined})} footer={null}>
                    <LiveStreamAttendanceReport2 liveStreamId={this.state.selectedLiveStreamId} />
                </Modal>} */}

                {this.state.playLiveStream !== undefined &&
                <Modal width="95vw" 
                closable={false}
                visible={this.state.playLiveStream !== undefined} 
                centered footer={null} bodyStyle={{padding: '0px'}} onCancel={() => this.setState({playLiveStream: undefined})}>
                    <div style={{display: 'flex', height: '90vh'}}>
                        <LiveStreamWindow recordingMode liveStreamId={this.state.playLiveStream} onBack={() => this.setState({playLiveStream: undefined})} />
                    </div>
                </Modal>}
            </div>
        )
    }

}

export default CourseLiveStreamsView;
