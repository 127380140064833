//---------------user events---------------
export const USER_EVENT_ON_NEW_CONSUMER = 0;
export const USER_EVENT_ON_PING_REQUEST = 1;


//---------------events---------------
export const EVENT_ON_USER_JOINED = 0;
export const EVENT_ON_STREAM_STOPPED = 2;
export const EVENT_ON_USER_LEFT = 3;
export const EVENT_ON_ALLOW_MESSAGING_CHANGE = 5;
export const EVENT_ON_NEW_MESSAGE = 6;
export const EVENT_ON_STAGE_PERMISSION_CHANGE = 7;
export const EVENT_ON_STAGE_USER_CHANGE = 8;
export const EVENT_ON_HAND_RAISE_CHANGE = 9;
export const EVENT_ON_WHITEBOARD_STAGE_ACCESS_CHANGE = 10;
export const EVENT_ON_DELETE_MESSAGE = 11;