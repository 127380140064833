import React from 'react'
import Page from '../../base/Page';
import Api from '../../session/Api';
import Error404Page from '../Error404Page';
import { ROOM_PARENT_TYPE_HELP_SESSION, HELP_SESSION_STATUS_AWAITING_CONFIRM, HELP_SESSION_STATUS_ACTIVE, HELP_SESSION_STATUS_DONE } from '../../Constants';
import ChatView from '../../views/chats/ChatView';
import { PageHeader, Row, Col, Radio, Button, InputNumber, Checkbox, Input } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import MessagesPage from '../messages-page/MessagesPage';

import {
    CheckOutlined,
    AlertOutlined
} from '@ant-design/icons'

import './tutor-help-session-page.less';
import Util from '../../util/Util';
import UIUtil from '../../util/UIUtil';
import UserListDialog from '../../components/UserListDialog';

class TutorHelpSessionPage extends Page {

    constructor(props) {
        super(props)

        this.state = {
            courseTitle: undefined,
            liveStreamInProgress: 0,
            helpSessionAccessDoesNotExist: false,

            status: this.state.pathParams.initialShowActive ? HELP_SESSION_STATUS_ACTIVE : HELP_SESSION_STATUS_AWAITING_CONFIRM,

            selectedHelpSessionId: undefined,
            updateStatuses: [],

            settingStatus: false,

            showUsers: false,
            newLiveStreamDuration: 0,
            newLiveStreamRecord: false,
            newLiveStreamTitle: "",
            newLiveStreamDescription: "",
            startingLiveStream: false,
        }
    }

    getCourseId() {
        return this.props.match.params.courseId;
    }

    onPageStart() {
        this.setState({loading: true})
        Api.getCourseHelpSessionInfo(this.getCourseId(), response => {
            if (response.status === true) {
                this.setState({
                    courseTitle: response.payload.courseTitle,
                    liveStreamInProgress: response.payload.liveStreamInProgress,
                    helpSessionAccessDoesNotExist: false,
                })
            } else {
                this.setState({
                    courseTitle: "",
                    liveStreamInProgress: 0,
                    helpSessionAccessDoesNotExist: true,
                })
            }
        })
    }

    setHelpSessionStatus(status) {
        this.setState({settingStatus: true})
        Api.setHelpSessionStatus(this.state.selectedHelpSessionId, status, response => {
            if (response.status === true) {
                this.state.updateStatuses.push(this.state.selectedHelpSessionId)
            } else {
                UIUtil.showError();
            }
            this.setState({
                settingStatus: false,
            })
        })
    }

    renderHelpSessionOptions() {
        //return [<Button type="primary" loading={this.state.settingStatus} onClick={() => this.setHelpSessionStatus(HELP_SESSION_STATUS_ACTIVE)}><CheckOutlined /> Accept Request</Button>]

        if (this.state.selectedHelpSessionId !== undefined) {
            let updated = 0;
            for (const id of this.state.updateStatuses) {
                if (id === this.state.selectedHelpSessionId) {
                    updated++;
                }
            }

            switch (this.state.status + updated) {
                case HELP_SESSION_STATUS_AWAITING_CONFIRM:
                    return [<Button type="primary" loading={this.state.settingStatus} onClick={() => this.setHelpSessionStatus(HELP_SESSION_STATUS_ACTIVE)}><CheckOutlined /> Accept Request</Button>]
                case HELP_SESSION_STATUS_ACTIVE:
                    return [<Button type="primary" loading={this.state.settingStatus} onClick={() => this.setHelpSessionStatus(HELP_SESSION_STATUS_DONE)}><CheckOutlined /> Finish Session</Button>]
                default:
                    return [];
            }
        } else {
            return [];
        }
    }

    shouldDisableInput() {
        if (this.state.selectedHelpSessionId !== undefined) {
            let updated = 0;
            for (const id of this.state.updateStatuses) {
                if (id === this.state.selectedHelpSessionId) {
                    updated++;
                }
            }

            switch (this.state.status + updated) {
                case HELP_SESSION_STATUS_AWAITING_CONFIRM:
                    return true;
                case HELP_SESSION_STATUS_ACTIVE:
                    return false;
                default:
                    return true;
            }
        } else {
            return true;
        }
    }

    render() {
        if (this.state.helpSessionAccessDoesNotExist) {
            return <Error404Page />
        }

        return (
            <div style={{flex: '1', height: '100%', display: 'flex', flexDirection: 'column'}}>
                <Row style={{background: 'rgb(237,240,243)'}}>
                    <Col span={6} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Radio.Group
                        className="help-session-tab"
                        options={[
                            { label: 'Requests', value: HELP_SESSION_STATUS_AWAITING_CONFIRM },
                            { label: 'Active', value: HELP_SESSION_STATUS_ACTIVE },
                            { label: 'Done', value: HELP_SESSION_STATUS_DONE },
                        ]}
                        size="large"
                        onChange={e => this.setState({status: e.target.value, updateStatuses: []})}
                        value={this.state.status}
                        optionType="button"
                        buttonStyle="solid"
                        />
                    </Col>
                    <Col span={18}>
                        <PageHeader
                        className="site-page-header"
                        //onBack={() => this.props.history.goBack()}
                        title="Help Session"
                        subTitle={this.state.courseTitle}
                        extra={this.renderHelpSessionOptions().concat(Util.isStringExists(this.state.liveStreamInProgress) ? (<>
                            <Button type="primary" className="red-button">
                                <Link to={Util.getLiveStreamPath(this.state.liveStreamInProgress)}>
                                    <AlertOutlined /> Join live stream
                                </Link>
                            </Button>
                            <Link to={"/my-help-session-history/" + this.getCourseId()}>
                                <Button><AlertOutlined /></Button>
                            </Link>
                        </>) : (<>
                            <Button onClick={e => this.setState({showUsers: true})} type="primary" className="red-button"><AlertOutlined /> Start live stream</Button>
                            <Link to={"/my-help-session-history/" + this.getCourseId()}>
                                <Button><AlertOutlined /></Button>
                            </Link>
                        </>))}
                        />
                    </Col>
                </Row>
                <div style={{flex: '1', display: 'flex'}}>
                    <div style={{flex: '1'}}>
                        <MessagesPage {...this.props} helpSession={{courseId: this.getCourseId(), status: this.state.status, tutor: true}} 
                        onRoomSelected={room => this.setState({selectedHelpSessionId: room !== undefined ? room.parentId : undefined})}
                        disableInput={this.shouldDisableInput()} roomParentType={ROOM_PARENT_TYPE_HELP_SESSION} roomParentId={this.state.selectedHelpSessionId} roomSecondaryParentId={this.getCourseId()} />
                    </div>
                </div>

                {this.state.showUsers && 
                <UserListDialog emptyMessage="No subscribed learner" showHelpSessionUsers title="Start live stream" message="Select learners that can join the live stream" selectable={true} 
                courseId={this.getCourseId()} visible={this.state.showUsers} confirmLoading={this.state.startingLiveStream}
                onOk={selectedUsers => {
                    if (selectedUsers.length === 0) {
                        UIUtil.showError("No users selected")
                        return;
                    }

                    if (this.state.newLiveStreamRecord && !Util.isStringExists(this.state.newLiveStreamTitle)) {
                        UIUtil.showError("Title is required");
                        return;
                    }

                    this.setState({startingLiveStream: true})
                    Api.startHelpSessionLive(this.getCourseId(), selectedUsers, this.state.newLiveStreamDuration, this.state.newLiveStreamRecord, this.state.newLiveStreamTitle, this.state.newLiveStreamDescription, response => {
                        if (response.status === true) {
                            this.setState({
                                startingLiveStream: false,
                                showUsers: false, 
                                newLiveStreamDuration: 0, 
                                newLiveStreamRecord: false,
                                newLiveStreamTitle: "",
                                newLiveStreamDescription: "",
                                liveStreamInProgress: response.payload
                            })
                            this.props.history.push(Util.getLiveStreamPath(response.payload))
                        } else {
                            this.setState({
                                startingLiveStream: false,
                            })
                            UIUtil.showError(response.message);
                        }
                    })
                }} onCancel={() => this.setState({showUsers: false, newLiveStreamDuration: 0, newLiveStreamRecord: false, newLiveStreamTitle: "", newLiveStreamDescription: "",})}>
                    <h3>Duration (minutes)</h3>
                    <InputNumber
                    size="large"
                    style={{width: '100%'}}
                    value={this.state.newLiveStreamDuration} onChange={value => this.setState({newLiveStreamDuration: value})} />
                    <br />

                    <h3>Recording</h3>
                    <Checkbox 
                    checked={this.state.newLiveStreamRecord} onChange={e => this.setState({newLiveStreamRecord: e.target.checked})}
                    >Record</Checkbox>
                    <br />

                    {this.state.newLiveStreamRecord && (<>
                        <h4>Title</h4>
                        <Input
                        size="large"
                        style={{width: '100%'}}
                        value={this.state.newLiveStreamTitle} onChange={e => this.setState({newLiveStreamTitle: e.target.value})} />
                        <br />

                        <h4>Description (optional)</h4>
                        <Input.TextArea
                        size="large"
                        style={{width: '100%'}}
                        value={this.state.newLiveStreamDescription} onChange={e => this.setState({newLiveStreamDescription: e.target.value})} />
                        <br />
                    </>)}
                </UserListDialog>}
            </div>
        )
    }

}

export default withRouter(TutorHelpSessionPage);