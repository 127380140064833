import React from 'react'
import Page from '../../base/Page';
import withMediaQuery from '../../components/WithMediaQuery';


import CoverPlaceholder from '../../images/HashtagCover.jpg'
import Api from '../../session/Api';
import UIUtil from '../../util/UIUtil';
import Util from '../../util/Util';

import { 
    PlusOutlined,
    UnorderedListOutlined,
    BookOutlined,
    ArrowLeftOutlined,
    AlertOutlined,
    ClusterOutlined,
    ArrowRightOutlined,
    AuditOutlined
} from '@ant-design/icons';
import { Button } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import HorizontalCoursesView, { HORIZONTAL_MODE_LATEST_HASHTAG_COURSES, HORIZONTAL_MODE_TOP_COURSES } from '../../views/courses/HorizontalCoursesView';
import HorizontalLiveSessionsView, { HORIZONTAL_MODE_LATEST_HASHTAG_LIVE_SESSIONS, HORIZONTAL_MODE_TOP_LIVE_SESSIONS } from '../../views/live-sessions/HorizontalLiveSessionsView';
import PostsView from '../../views/posts/PostsView';
import CoursesView, { VIEW_MODE_CATEGORY_COURSES, VIEW_MODE_HASHTAG_COURSES } from '../../views/courses/CoursesView';


class HashtagCoursesPage extends Page {

    getHashtag() {
        return this.props.match.params.hashtag;
    }

    render() {
        let customFontSize;
        if (Util.isStringExists(this.getHashtag()) && this.getHashtag().length > 12) {
            customFontSize = this.props.isSmall ? 40 : 60;
        }

        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <img src={Api.getHashtagCover(this.getHashtag())}
                onError={e => {
                    e.target.onerror = null;
                    e.target.src = CoverPlaceholder;
                }}
                style={{height: this.props.isSmall ? 225 : '300px', width: '100%', objectFit: 'cover', position: 'absolute', zIndex: '-1'}} />

                <div style={{height: this.props.isSmall ? 225 : '300px', width: this.props.renderFullWidth ? '90vw' : '75vw', display: 'flex', alignItems: 'flex-end'}}>
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingLeft: this.props.isSmall ? 5 : 25, width: '100%'}}>
                        <div onClick={() => this.props.history.goBack()} style={{display: 'flex', alignItems: 'center', color: 'white'}} className="hashtag-btn">
                            <ArrowLeftOutlined style={{marginRight: 5}} />
                            Back
                        </div>
                        <h2 style={{color: 'white', fontWeight: 'bold', fontSize: customFontSize !== undefined ? customFontSize : (this.props.isSmall ? 60 : '100px'), lineHeight: 1, margin: 0, wordWrap: 'break-word'}}>
                            <span style={{marginRight: 5}}>#</span>
                            {this.getHashtag()}
                        </h2>
                        <div style={{display: 'flex', alignItems: 'center', //marginTop: this.props.isSmall ? -17 : -25, 
                        marginBottom: '1em', opacity: 0.8}}>
                            <p style={{color: 'white', margin: 0}}><BookOutlined /> Courses</p>
                            <div style={{marginLeft: 10, marginRight: 10, width: 4, height: 4, borderRadius: '50%', background: 'white'}} />
                            <Link to="/all-courses">
                                <p style={{color: 'white', margin: 0, textDecoration: 'underline'}}>View All {!this.props.isSmall && 'Courses'}</p>
                            </Link>
                        </div>
                    </div>
                </div>

                <div style={{width: this.props.renderFullWidth ? '90vw' : '75vw', paddingTop: '25px', paddingBottom: '25px'}}>
                    <CoursesView hashtag={this.getHashtag()} count={this.props.isSmall ? 1 : 4} mode={VIEW_MODE_HASHTAG_COURSES} />
                </div>
            </div>
        )
    }

}

export default withMediaQuery(withRouter(HashtagCoursesPage));