import React from 'react'
import { Link } from 'react-router-dom';
import Api from '../../../session/Api';

import NoImage from '../../../images/NoImage.png';
import Util from '../../../util/Util';
import { Badge, Button, Tag, Tooltip } from 'antd';

import {  
    EyeOutlined,
    CommentOutlined,
    LikeOutlined,
    LinkOutlined,
    EditOutlined,
    DeleteOutlined
} from '@ant-design/icons';
import { BLOG_POST_SOURCE_UNPUBLISHED } from '../../../Constants';
import { BLGO_POST_TAGS } from '../../../BlogPostTags';

class MyBlogPostItem extends React.Component {

    getBlogPostTag() {
        for (const tag of BLGO_POST_TAGS) {
            if (tag.id == this.props.blogPost.tag) {
                return tag;
            }
        }

        return BLGO_POST_TAGS[0];
    }

    render() {
        const blogPost = this.props.blogPost;
        const tag = this.getBlogPostTag();
        return (
            <div style={{width: '100%', borderRadius: '15px', padding: '15px', boxShadow: "0px 0px 25px 0px rgba(0, 0, 0, 0.2)"}}>
                <div style={{display: 'flex'}}>
                    <Link to={Util.getBlogPostPath(true, blogPost.title, blogPost.date)} style={{width: '20%', height: '115px'}}>
                        <img src={Api.getBlogPostThumbnail(blogPost.id)} style={{width: '100%', height: '115px', objectFit: 'cover', borderRadius: '15px'}} onError={e => {
                            e.target.onerror = null;
                            e.target.src = NoImage;
                        }} />
                    </Link>
                    <div style={{marginLeft: '25px', flex: 1, minWidth: 1}}>
                        <div style={{display: 'flex', alignItems: 'center', marginRight: '0px'}}>
                            <Link to={Util.getBlogPostPath(true, blogPost.title, blogPost.date)}><h2 style={{height: '25px'}}>{blogPost.title}</h2></Link>

                            <div style={{flex: 1}} />
                            <Tooltip title="Comments">
                                <Tag icon={<CommentOutlined />} color="blue">
                                    <strong>{blogPost.commentCount}</strong>
                                </Tag>
                            </Tooltip>
                            <div style={{width: '5px'}} />
                            <Tooltip title="Likes">
                                <Tag icon={<LikeOutlined />} color="blue">
                                    <strong>{blogPost.likeCount}</strong>
                                </Tag>
                            </Tooltip>
                            <div style={{width: '5px'}} />
                            <Tooltip title="Views">
                                <Tag icon={<EyeOutlined />} color="blue">
                                    <strong>{blogPost.viewCount}</strong>
                                </Tag>
                            </Tooltip>
                        </div>

                        <div style={{display: 'flex', alignItems: 'center', marginTop: -5}}>
                            <div style={{borderRadius: '50%', marginRight: '10px', background: blogPost.source != BLOG_POST_SOURCE_UNPUBLISHED ? '#009900' : '#faad14', width: '10px', height: '10px'}} />
                            <h4 style={{height: '16px'}}>{blogPost.source != BLOG_POST_SOURCE_UNPUBLISHED ? 'Published' : 'Unpublished'}</h4>
                        </div>

                        <p style={{paddingTop: '10px', fontSize: '12px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', flex: '1'}}>
                            {Util.isStringExists(blogPost.preview) ? blogPost.preview : 'No preview'}
                        </p>
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '12px', width: '100%'}}>
                    <Tag color={tag.color} icon={tag.icon}>{tag.title}</Tag>

                    <div style={{flex: 1}} />

                    <Link to={Util.getBlogPostPath(false, blogPost.title, blogPost.date)}>
                        <Button type="link" icon={<LinkOutlined />} disabled={blogPost.source == BLOG_POST_SOURCE_UNPUBLISHED}>
                            Public Link
                        </Button>
                    </Link>
                    <div style={{width: 5}} />
                    <Link to={Util.getBlogPostPath(true, blogPost.title, blogPost.date)}>
                        <Button type="primary" icon={<EditOutlined />}>
                            Edit
                        </Button>
                    </Link>
                </div>
            </div>
        )
    }

}

export default MyBlogPostItem;