import { Button, Empty, Spin } from 'antd';
import React from 'react'
import InfiniteScroll from 'react-infinite-scroller';
import Page from '../../base/Page';
import withMediaQuery from '../../components/WithMediaQuery';
import { NO_PAGE_TOKEN } from '../../Constants';
import Api from '../../session/Api';
import Util from '../../util/Util';
import MyBlogPostItem from '../../views/blog/myblog/MyBlogPostItem';

import { 
    PlusOutlined,
    UnorderedListOutlined,
    AppstoreOutlined,
    LockOutlined
} from '@ant-design/icons';
import UIUtil from '../../util/UIUtil';
import { withRouter } from 'react-router-dom';

class MyBlogsPage extends Page {

    constructor(props) {
        super(props)

        this.state = {
            ...this.state,

            items: [],
            page: NO_PAGE_TOKEN,
            hasMore: true,
            loadingItems: false,

            creatingNewPost: false
        }
    }

    getNextBlogPosts() {
        if (this.state.loadingItems) {
            return;
        }

        this.setState({
            loadingItems: true
        })
    
        //list error
        const listener = response => {
            if (response.status === true) {
                let items = this.state.items;
                items.push(...response.payload.items);

                this.setState({
                    items: items,
                    page: response.payload.token,
                    hasMore: Util.isStringExists(response.payload.token),
                    loadingItems: false
                })
            } else {
                //alert('errowr');
            }
        };

        Api.getMyBlogPosts(this.state.page, listener);
    }

    createNewPost() {
        if (this.state.creatingNewPost) {
            return;
        }
        
        this.setState({creatingNewPost: true})
        Api.newBlog(response => {
            this.setState({creatingNewPost: false})
            if (response.status === true) {
                this.props.history.push({
                    pathname: "/my-blog",
                    search: "?t=" + encodeURIComponent(response.payload.title) + "&d=" + response.payload.date
                })
            } else {
                UIUtil.showError();
            }
        })
    }

    render() {
        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div style={{width: this.props.renderFullWidth ? '90vw' : '75vw', paddingTop: '25px', paddingBottom: '25px'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <h2>My Blog Posts</h2>
                        <div style={{flex: '1'}} />
                        <Button type="primary" onClick={this.createNewPost.bind(this)} loading={this.state.creatingNewPost}>
                            <PlusOutlined /> New Blog Post
                        </Button>
                    </div>

                    <InfiniteScroll
                        loadMore={this.getNextBlogPosts.bind(this)}
                        hasMore={this.state.hasMore}
                        threshold={750}
                        loader={
                            <div style={{display: 'flex', justifyContent: 'center', marginTop: '45px', marginBottom: '45px'}}>
                                <Spin size="large" tip="Loading..." />
                            </div>
                        }
                        style={{overflow: 'visible'}}>

                        {this.state.items.map((item) => {
                            return ( 
                                <div key={item.id}>
                                    <br />
                                    <MyBlogPostItem blogPost={item} />
                                </div>
                            )
                        })}
                    </InfiniteScroll>

                    {!this.state.loadingItems ? <Empty style={{marginTop: '50px'}} image={Empty.PRESENTED_IMAGE_SIMPLE} description={this.state.items.length > 0 ? "No more blog posts" : "No blog posts"} /> : null}
                    <br />
                </div>
            </div>
        )
    }

}

export default withRouter(withMediaQuery(MyBlogsPage));