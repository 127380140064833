import React from 'react'


import {
    AlertOutlined,
    DashboardOutlined,
    QuestionCircleOutlined,
    CreditCardOutlined,
    DollarCircleOutlined,
    HistoryOutlined,
    DollarCircleFilled,
    DashboardFilled,
    ClockCircleFilled,
    BarChartOutlined
} from '@ant-design/icons'
import { Tooltip, Button, Tabs, InputNumber, Card, Slider } from 'antd';
import Api from '../../session/Api';
import UIUtil from '../../util/UIUtil';


class GetLiveStreamCreditsView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            creditPurchaseSliderValue: 0,
            purchasing: false
        }
    }

    onPurchaseBtn() {
        this.setState({purchasing: true})
        Api.getLiveStreamCredit(this.state.creditPurchaseSliderValue, response => {
            if (response.status === true) {
                UIUtil.showSuccess();
                window.location.reload();
            } else {
                // UIUtil.showError()
                UIUtil.showInfo("Your account is not approved for more credits")
                this.setState({purchasing: false})
            }
        })
    }

    getPrice() {
        return (this.state.creditPurchaseSliderValue * this.props.priceRate).toFixed(2);
    }

    render() {
        return (
            <Card title={<><DashboardFilled style={{marginRight: 7}} /> Get Credits</>} 
            actions={[
                <div>
                    <h5 style={{color: 'rgba(0, 0, 0, 0.65)', margin: 0}}><ClockCircleFilled style={{marginRight: 0}} /> Activation Time</h5>
                    <p style={{fontSize: 20, margin: 0, marginTop: -5, color: '#009900'}}>
                        Instant
                    </p>
                </div>,
                <div style={{width: '100%', position: 'relative'}}>
                    <h5 style={{color: 'rgba(0, 0, 0, 0.65)', margin: 0}}><DollarCircleFilled style={{marginRight: 0}} /> Price</h5>
                    <p style={{fontSize: 20, margin: 0, marginTop: -5, color: 'black'}}>
                        ${this.getPrice()}
                    </p>
                    <Tooltip color="purple" title={<p>
                        <strong>Pricing</strong>
                        <br />
                        ${this.props.priceRate.toFixed(2)} per {this.props.payInterval} minutes
                    </p>}><Button type="link" style={{position: 'absolute', right: 15, top: 4}}><QuestionCircleOutlined /></Button></Tooltip>
                </div>,
                <div style={{flex: 1, display: 'flex', justifyContent: 'flex-end', marginRight: 15}}>
                    <Button type="primary" disabled={this.state.creditPurchaseSliderValue == 0} 
                    loading={this.state.purchasing}
                    onClick={this.onPurchaseBtn.bind(this)}>
                        <CreditCardOutlined /> Purchase
                    </Button>
                </div>,
            ]}
            hoverable={false} style={{marginTop: 5}} bodyStyle={{paddingTop: 15, paddingBottom: 15}}>
                <div style={{display: 'flex', alignItems: 'flex-end'}}>
                    <h1 style={{fontWeight: 'bolder', fontSize: 44, margin: 0, color: '#990000'}}>
                        {this.state.creditPurchaseSliderValue}
                    </h1>
                    <h2 style={{marginLeft: 10}}>
                        minutes
                    </h2>
                </div>

                <Slider style={{width: '100%'}} tooltipVisible={false}
                onChange={value => this.setState({creditPurchaseSliderValue: value})}
                step={this.props.payInterval} max={this.props.maxPayCredit}
                value={this.state.creditPurchaseSliderValue} />
            </Card>
        )
    }

}

export default GetLiveStreamCreditsView;