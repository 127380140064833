import React from 'react'
import ProfilePicture from '../../components/ProfilePicture';
import Util from '../../util/Util';
import { Link } from 'react-router-dom';

import LogoSquare from '../../images/LogoSquare.png'
import { POST_USER_ID_ADMIN, ACCOUNT_ROLE_TUTOR, ACCOUNT_ROLE_STUDENT, ACCOUNT_ROLE_ORGANIZATION } from '../../Constants';
import StudentProfileDialog from '../../components/student-profile-dialog/StudentProfileDialog';

import { Button, Modal, Popover } from 'antd';
import Api from '../../session/Api';
import UIUtil from '../../util/UIUtil';


import { 
    MoreOutlined,
    DeleteOutlined,
} from '@ant-design/icons';
import SessionManager from '../../session/SessionManager';


class CommentItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            learnerProfileId: undefined,
            learnerProfileFullName: undefined,
            showLearnerProfile: false,

            deleting: false,
            showingDeleteConfirm: false,
        }

        //this.props.comment.accountId = POST_USER_ID_ADMIN;
    }

    deleteComment() {
        if (this.state.showingDeleteConfirm) {
            return;
        }

        this.setState({showingDeleteConfirm: true});
        Modal.confirm({
            title: "Confirm",
            content: "Are you sure you want to delete this? This action is irreversible.",
            onOk: () => {
                this.setState({deleting: true, showingDeleteConfirm: false});
                Api.deleteComment(this.props.comment.id, response => {
                    if (response.status === true) {
                        if (this.props.onItemDeleted !== undefined) {
                            this.props.onItemDeleted();
                        }
                    } else {
                        UIUtil.showError();
                    }
                    this.setState({deleting: false})
                })
            },
            onCancel: () => {
                this.setState({showingDeleteConfirm: false})
            }
        })
    }

    render() {
        return (
            <div style={{display: 'flex', width: '100%'}}>
                {this.props.comment.accountId === POST_USER_ID_ADMIN ? (
                    <img src={LogoSquare} style={{width: '32px', height: '32px', marginTop: '15px'}} />
                ) : (
                    //<Link to={Util.getProfilePath(this.props.comment.accountId)}><h5><ProfilePicture style={{ marginTop: '15px' }} userId={this.props.comment.accountId} /></h5></Link>
                    <ProfilePicture showStatus={this.props.comment.accountRole === ACCOUNT_ROLE_TUTOR} clickable={this.props.comment.accountRole === ACCOUNT_ROLE_TUTOR || this.props.comment.accountRole === ACCOUNT_ROLE_ORGANIZATION} 
                        onClick={this.props.comment.accountRole === ACCOUNT_ROLE_STUDENT ? e => this.setState({learnerProfileId: this.props.comment.accountId, learnerProfileFullName: this.props.comment.accountName, showLearnerProfile: true}) : undefined} 
                        style={this.props.comment.accountRole === ACCOUNT_ROLE_STUDENT ? {cursor: 'pointer', marginTop: '15px'} : {marginTop: '15px'}}
                        userId={this.props.comment.accountId} />
                )}
                <div style={{flex: '1', minWidth: '0', borderRadius: '15px', marginLeft: '15px', minHeight: '75px', background: '#29316a25'}}>
                    <div style={{padding: '15px 15px 0px 15px', display: 'flex', alignItems: 'center'}}>
                        {this.props.comment.accountId === POST_USER_ID_ADMIN ? (
                            <>
                                <h5 style={{color: '#4e2e89'}}>Learnerix</h5>
                                <h6 style={{color: '#8a8a8a', marginLeft: '15px'}}>{Util.getDate(this.props.comment.date)}</h6>

                                <div style={{flex: 1}} />
                                {this.props.comment.adminId == SessionManager.getAccount().id &&
                                <Popover content={
                                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', width: '175px'}}>
                                        <Button type="link" danger loading={this.state.deleting} onClick={this.deleteComment.bind(this)}>
                                            <DeleteOutlined style={{marginRight: 8}} /> Delete
                                        </Button>
                                    </div>
                                } trigger="click">
                                    <Button type="link"><MoreOutlined /></Button>
                                </Popover>}
                            </>
                        ) : (
                            <>
                                {this.props.comment.accountRole === ACCOUNT_ROLE_TUTOR || this.props.comment.accountRole === ACCOUNT_ROLE_ORGANIZATION || this.props.comment.accountRole === ACCOUNT_ROLE_STUDENT ? (
                                    <Link to={Util.getProfilePath(this.props.comment.accountId)}><h5>{this.props.comment.accountName}</h5></Link>
                                ) : (
                                    <a onClick={() => this.setState({learnerProfileId: this.props.comment.accountId, learnerProfileFullName: this.props.comment.accountName, showLearnerProfile: true})}><h5>{this.props.comment.accountName}</h5></a>
                                )}
                                <h6 style={{color: '#8a8a8a', marginLeft: '15px'}}>{Util.getDate(this.props.comment.date)}</h6>

                                <div style={{flex: 1}} />
                                {(this.props.comment.accountId == SessionManager.getAccount().id || (this.props.parentOwner == SessionManager.getAccount().id && this.props.comment.accountId !== POST_USER_ID_ADMIN)) &&
                                <Popover content={
                                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', width: '175px'}}>
                                        <Button type="link" danger loading={this.state.deleting} onClick={this.deleteComment.bind(this)}>
                                            <DeleteOutlined style={{marginRight: 8}} /> Delete
                                        </Button>
                                    </div>
                                } trigger="click">
                                    <Button type="link"><MoreOutlined /></Button>
                                </Popover>}
                            </>
                        )}
                    </div>

                    <p style={{padding: '0px 25px 0px 25px', overflowWrap: 'break-word', whiteSpace: 'pre-line'}}>{this.props.comment.commentContent}</p>
                </div>

                {this.state.showLearnerProfile ? (
                    <StudentProfileDialog 
                    showSendMessage
                    visible={this.state.showLearnerProfile}
                    name={this.state.learnerProfileFullName}
                    userId={this.state.learnerProfileId}
                    onCancel={() => this.setState({showLearnerProfile: false})} />
                ) : null}
            </div>
        )
    }
}

export default CommentItem;