import React from 'react'
import { Row, Col, Alert, Dropdown, Menu, Checkbox, Modal } from 'antd';
import AuthBg from '../../images/AuthBg.jpg'
import Logo from '../../images/Logo.png'
import LogoWhite from '../../images/LogoWhite.png'

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import ReactCodeInput from 'react-code-input';

import { Form, Input, Button, Tag } from 'antd';

import { 
    LoginOutlined,
    UserOutlined,
    LockOutlined,
    AuditOutlined,
    ClusterOutlined,
    MailTwoTone,
    ReloadOutlined,
    ArrowRightOutlined,
    LockTwoTone,
    MailOutlined,
    ArrowLeftOutlined,
    TeamOutlined
} from '@ant-design/icons';
import Api from '../../session/Api';
import SessionManager from '../../session/SessionManager';
import { ACCOUNT_SCOPE_FRONTEND, ACCOUNT_ROLE_STUDENT, ACCOUNT_ROLE_TUTOR, ACCOUNT_ROLE_ORGANIZATION, API_ERROR_MESSAGE_ORGANIZATION_TRIAL_OVER } from '../../Constants';
import { Link, withRouter } from 'react-router-dom';
import UIUtil from '../../util/UIUtil';
import OrganizationAccessDialog from '../../components/OrganizationAccessDialog';
import withMediaQuery from '../../components/WithMediaQuery';
import Util from '../../util/Util';

class FrontendAuthView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            inLogin: props.registerType !== undefined ? false : true,
            registerType: props.registerType,
            errorMessage: undefined,
            phoneNumberValue: "",

            showAccountAccess: false,
            accountAccess: undefined,
            accountAccessUsername: undefined,
            accountAccessPassword: undefined,

            inConfirm: false,
            confirmId: undefined,
            loginEmail: undefined,
            loginPassword: undefined,
            confirmCodeValue: "",
            confirmingCode: false,
            confirmInputKey: Util.newTempId(),
            resendConfirmCodeTimer: 15, //seconds
            resendingConfirmCode: false,

            inForgetPassword: false,
            sendingPasswordLink: false,
            passwordResetEmailValue: ""
        }
    }

    componentWillUnmount() {
        if (this.confirmTimer !== undefined) {
            clearInterval(this.confirmTimer);
        }
    }

    onLogin(values) {
        this.setState({
            loading: true,
        })

        Api.login(values.username, values.password, ACCOUNT_SCOPE_FRONTEND, response => this.onResponse(values.username, values.password, response))
    }

    onActivateOrganizationAccount(serialNo, listener) {
        Api.loginAndActivateOrganizationAccount(this.state.accountAccessUsername, this.state.accountAccessPassword, serialNo, listener);
    }

    onRegister(values) {
        if (values.passwordcofirm !== values.password) {
            UIUtil.showError("Passwords do not match")
            return;
        }

        this.setState({
            loading: true
        })

        Api.register({
            email: values.email,
            fullName: values.name,
            role: this.getRegisterTypeRole(),
            passwordValue: values.password,
            phoneNumberValue: (this.getRegisterTypeRole() == ACCOUNT_ROLE_TUTOR || this.getRegisterTypeRole() == ACCOUNT_ROLE_ORGANIZATION) ? "+" + this.state.phoneNumberValue : undefined
        }, response => {
            if (response.status === true) {
                this.setState({inConfirm: true, confirmId: response.payload.uniqueId, loginEmail: values.email, loginPassword: values.password})
                this.confirmTimer = setInterval(() => this.setState(prevState => ({resendConfirmCodeTimer: prevState.resendConfirmCodeTimer - 1})), 1000);
            } else {
                this.onResponse(values.email, values.password, response);
            }
        })
    }

    resendConfirmCode() {
        this.setState({resendingConfirmCode: true});
        Api.resendEmailConfirmCode(this.state.confirmId, response => {
            if (response.status === true) {
                this.setState({resendingConfirmCode: false, resendConfirmCodeTimer: 15});
            } else {
                this.setState({resendingConfirmCode: false});
                UIUtil.showError();
            }
        })
    }

    onConfirmCode() {
        this.setState({confirmingCode: true});
        Api.confirmEmailToken(this.state.confirmId, this.state.confirmCodeValue, this.state.loginPassword, response => {
            this.onResponse(this.state.loginEmail, this.state.loginPassword, response);
            if (response.status !== true) {
                this.setState({confirmingCode: false, confirmCodeValue: "", confirmInputKey: Util.newTempId()});
            }
        });
    }

    sendPasswordLink() {
        this.setState({sendingPasswordLink: true})
        Api.sendPasswordResetRequest(this.state.passwordResetEmailValue, response => {
            if (response.status === true) {
                Modal.success({
                    title: "Success!",
                    content: "If a valid account was associated with the email, a password reset link has been sent to it.",
                    onOk: () => this.setState({inForgetPassword: false})
                })
            } else {
                this.setState({sendingPasswordLink: false, errorMessage: response.message})
            }
        })
    }

    onResponse(usernameUsed, passwordUsed, response) {
        if (response.status === true) {
            this.onFinished(response);
        } else {
            this.setState({
                loading: false,
                errorMessage: response.message == API_ERROR_MESSAGE_ORGANIZATION_TRIAL_OVER ? undefined : response.message,
                showAccountAccess: response.message == API_ERROR_MESSAGE_ORGANIZATION_TRIAL_OVER,
                accountAccess: response.message == API_ERROR_MESSAGE_ORGANIZATION_TRIAL_OVER ? response.payload : undefined,
                accountAccessUsername: response.message == API_ERROR_MESSAGE_ORGANIZATION_TRIAL_OVER ? usernameUsed : undefined,
                accountAccessPassword: response.message == API_ERROR_MESSAGE_ORGANIZATION_TRIAL_OVER ? passwordUsed : undefined,
            })
        }
    }

    onFinished(response) {
        SessionManager.createSession(response.payload);
        if (this.props.onDonePath !== undefined) {
            window.location.replace(this.props.onDonePath);
        } else {
            window.location.replace('/')
        }
    }

    getRegisterTypeName() {
        switch (this.state.registerType) {
            case "0":
                return "Learner";
            case "1":
                return "Tutor";
            case "2":
                return this.state.asTeam ? "Team" : "Organization"
        }
    }

    getRegisterTypeRole() {
        switch (this.state.registerType) {
            case "0":
                return ACCOUNT_ROLE_STUDENT;
            case "1":
                return ACCOUNT_ROLE_TUTOR;
            case "2":
                return ACCOUNT_ROLE_ORGANIZATION;
        }
    }

    renderContent() {
        if (this.state.inForgetPassword) {
            return (
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', flexDirection: 'column'}}>
                    {this.props.miniMode ? (
                        <img src={Logo} style={{height: '75px', marginTop: '15px'}} />
                    ) : (
                        <Link to="/">
                            <img src={Logo} style={{height: '75px'}} />
                        </Link>
                    )}

                    <LockTwoTone style={{fontSize: 50, marginTop: 25}} twoToneColor="#f15f43" />

                    {this.state.errorMessage !== undefined ? 
                        <Alert
                            message="Error"
                            description={this.state.errorMessage}
                            style={{marginTop: '25px'}}
                            type="error"
                            closable
                            afterClose={() => this.setState({errorMessage: undefined})}
                            />
                    : null}

                    <h2 style={{marginTop: 15}}>Forgot password?</h2>
                    <h4 style={{marginTop: -15}}>We'll send you a link to reset your password</h4>

                    <div style={{width: 250, marginTop: 40}}>
                        <Input size="large" style={{width: '100%'}} disabled={this.state.loading} 
                        onPressEnter={() => this.sendPasswordLink()}
                        value={this.state.passwordResetEmailValue} onChange={e => this.setState({passwordResetEmailValue: e.target.value})}
                        prefix={<MailOutlined />} placeholder="Email Address" />

                        <Button disabled={!Util.isStringExists(this.state.passwordResetEmailValue)} loading={this.state.sendingPasswordLink} onClick={() => this.sendPasswordLink()} style={{width: '100%', marginTop: 25}} type="primary"><LockOutlined /> Send Reset Password</Button>
                        <Button disabled={this.state.sendingPasswordLink} onClick={() => this.setState({inForgetPassword: false})} style={{width: '100%', marginTop: 5}} size="small"><ArrowLeftOutlined /> Go back</Button>
                    </div>
                    
                </div>
            )
        }

        if (this.state.inConfirm) {
            return (
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', flexDirection: 'column'}}>
                    {this.props.miniMode ? (
                        <img src={Logo} style={{height: '75px', marginTop: '15px'}} />
                    ) : (
                        <Link to="/">
                            <img src={Logo} style={{height: '75px'}} />
                        </Link>
                    )}

                    <MailTwoTone style={{fontSize: 50, marginTop: 25}} twoToneColor="#f15f43" />

                    {this.state.errorMessage !== undefined ? 
                        <Alert
                            message="Error"
                            description={this.state.errorMessage}
                            style={{marginTop: '25px'}}
                            type="error"
                            closable
                            afterClose={() => this.setState({errorMessage: undefined})}
                            />
                    : null}

                    <h2 style={{marginTop: 15}}>You're almost done!</h2>
                    <h4 style={{marginTop: -15}}>Please confirm your e-mail address</h4>

                    <p style={{marginTop: 15}}>We have sent a confirmation code to <strong>{this.state.loginEmail}</strong></p>
                    <p style={{marginTop: -15}}>Please enter it below to finish your registration</p>

                    <div key={this.state.confirmInputKey} className="hidden-number-field-arrows" style={{marginTop: 15}}>
                        <ReactCodeInput fields={5} disabled={this.state.confirmingCode} value={this.state.confirmCodeValue} onChange={value => {
                            this.setState({confirmCodeValue: value}, () => {
                                if (value.length == 5) {
                                    this.onConfirmCode();
                                }
                            })
                        }} />
                    </div>

                    {/*<Button type="primary" style={{marginTop: 25}} disabled={this.state.confirmCodeValue.length != 5}>Confirm <ArrowRightOutlined /></Button>*/}

                    <p style={{marginTop: 40}}>Didn't receive a code?</p>
                    {this.state.resendConfirmCodeTimer > 0 ? (
                        <Button disabled style={{marginTop: -15}} type="link">Resend in {this.state.resendConfirmCodeTimer} seconds <ReloadOutlined /></Button>
                    ) : (
                        <Button onClick={this.resendConfirmCode.bind(this)} loading={this.state.resendingConfirmCode} style={{marginTop: -15}} type="link">Resend email<ReloadOutlined /></Button>
                    )}
                </div>
            )
        }

        return (
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', flexDirection: 'column'}}>
                {this.props.miniMode ? (
                    <img src={Logo} style={{height: '75px', marginTop: '15px'}} />
                ) : (
                    <Link to="/">
                        <img src={Logo} style={{height: '75px'}} />
                    </Link>
                )}

                {this.state.errorMessage !== undefined ? 
                    <Alert
                        message="Error"
                        description={this.state.errorMessage}
                        style={{marginTop: '25px'}}
                        type="error"
                        closable
                        afterClose={() => this.setState({errorMessage: undefined})}
                        />
                : null}

                {this.state.inLogin ? (
                    <>
                        <Tag color="orange" style={{marginRight: '25px', marginTop: '50px'}}>Login</Tag>
                        <Form
                            name="normal_login"
                            className="login-form"
                            style={{marginTop: '2vh',}}
                            size="large"
                            initialValues={{ remember: true }}
                            onFinish={this.onLogin.bind(this)}
                            >
                            <Form.Item
                                name="username"
                                rules={[{ required: true, message: 'Field is required' }]}
                            >
                                <Input disabled={this.state.loading} prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email Address" />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[{ required: true, message: 'Field is required' }]}
                            >
                                <Input
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="password"
                                placeholder="Password"
                                disabled={this.state.loading}
                                />
                            </Form.Item>
                            
                            <div style={{position: 'relative'}}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" className="login-form-button" style={{width: '100%', }} loading={this.state.loading}>
                                    Log in
                                </Button>
                            </Form.Item>
                            <a style={{position: 'absolute', bottom: -30, right: 0}} onClick={() => this.setState({inForgetPassword: true, errorMessage: undefined, passwordResetEmailValue: "", sendingPasswordLink: false})}><LockOutlined /> Forgot password?</a>
                            </div>
                        
                            <div style={{height: 15}} />
                        </Form>
                    </>
                ) : (
                    <>
                        <Tag color="red" style={{marginRight: '25px', marginTop: '50px'}} onClick={() => {
                            if (this.state.registerType === "0") {
                                this.setState({registerType: "1"})
                            } else if (this.state.registerType === "1") {
                                this.setState({registerType: "0"})
                            }
                        }}>Register as {this.getRegisterTypeName()}</Tag>
                        <Form
                            name="normal_register"
                            className="login-form"
                            style={{marginTop: '2vh',}}
                            size="large"
                            initialValues={{ remember: true }}
                            onFinish={this.onRegister.bind(this)}
                            >
                                <Form.Item
                                name="name"
                                rules={[{ required: true, message: 'Field is required' }]}
                            >
                                <Input disabled={this.state.loading} placeholder={this.state.registerType == "2" ? "Name" : "Full Name"} />
                            </Form.Item>
                            <Form.Item
                                name="email"
                                rules={[{ required: true, message: 'Field is required' }]}
                            >
                                <Input type="email" disabled={this.state.loading} placeholder="Email Address" />
                            </Form.Item>
                            {(this.getRegisterTypeRole() == ACCOUNT_ROLE_TUTOR || this.getRegisterTypeRole() == ACCOUNT_ROLE_ORGANIZATION) &&
                            <Form.Item
                                name="phoneNumber"
                                rules={[{ required: true, message: 'Field is required' }]}
                            >
                                {/*<Input type="number" disabled={this.state.loading} placeholder="Phone Number" />*/}
                                <PhoneInput
                                inputClass="ant-input ant-input-lg"
                                placeholder="Phone Number"
                                containerStyle={{height: 40}}
                                inputStyle={{height: 40, borderRadius: 2, fontSize: '16px'}}
                                value={this.state.phoneNumberValue}
                                onChange={phoneNumberValue => this.setState({ phoneNumberValue: phoneNumberValue })}
                                />
                            </Form.Item>}
                            <Form.Item
                                name="password"
                                rules={[{ required: true, message: 'Field is required' }]}
                            >
                                <Input
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="password"
                                placeholder="Password"
                                disabled={this.state.loading}
                                />
                            </Form.Item>
                            <Form.Item
                                name="passwordcofirm"
                                rules={[{ required: true, message: 'Field is required' }]}
                            >
                                <Input
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="password"
                                placeholder="Confirm Password"
                                disabled={this.state.loading}
                                />
                            </Form.Item>
                            <Form.Item name="confirmterms" valuePropName="checked" rules={[
                            {
                                validator: (_, value) =>
                                value ? Promise.resolve() : Promise.reject('You must agree to register'),
                            },
                            ]}>
                                <Checkbox>I agree to the <Link target="_blank" to="terms-of-service">terms of service</Link></Checkbox>
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" htmlType="submit" className="login-form-button" style={{width: '100%', }} loading={this.state.loading}>
                                    Register
                                </Button>
                            </Form.Item>
                        </Form>
                    </>
                )}

                {this.state.inLogin ? (
                    <Dropdown overlay={
                        <Menu>
                            <Menu.Item onClick={e => this.setState({registerType: "0", asTeam: false, inLogin: false})} key="0" style={{height: '50px', display: 'flex', alignItems: 'center'}}>
                                <UserOutlined style={{fontSize: '20px'}} /> Register as Learner
                            </Menu.Item>
                            <Menu.Item onClick={e => this.setState({registerType: "1", asTeam: false, inLogin: false})} key="1" style={{height: '50px', display: 'flex', alignItems: 'center'}}>
                                <AuditOutlined style={{fontSize: '20px'}} /> Register as Tutor
                            </Menu.Item>
                            <Menu.Item onClick={e => this.setState({registerType: "2", asTeam: true, inLogin: false})} key="1" style={{height: '50px', display: 'flex', alignItems: 'center'}}>
                                <TeamOutlined style={{fontSize: '20px'}} /> Register as Team
                            </Menu.Item>
                            <Menu.Item onClick={e => this.setState({registerType: "2", asTeam: false, inLogin: false})} key="1" style={{height: '50px', display: 'flex', alignItems: 'center'}}>
                                <ClusterOutlined style={{fontSize: '20px'}} /> Register as Organization
                            </Menu.Item>
                        </Menu>
                    } trigger={['click']}>
                        <Button style={{marginTop: '25px'}} disabled={this.state.loading}>
                            Don't have an account?
                        </Button>
                    </Dropdown>
                ) : (
                    <Button style={{marginTop: '25px'}} disabled={this.state.loading} onClick={e => this.setState({registerType: undefined, inLogin: true})}>
                        Already have an account?
                    </Button>
                )}

                {/*
                <Button style={{marginTop: '25px'}} onClick={() => {this.setState({inLogin: !this.state.inLogin})}} disabled={this.state.loading}>
                    {this.state.inLogin ? "Don't have an account?" : "Already have an account?"}
                </Button>*/}
            </div>
        )
    }

    render() {
        if (this.props.miniMode || this.props.isSmall) {
            return (
                <div style={{width: '100%', height: '100%', marginTop: this.props.isSmall && '40px'}}>
                    {this.renderContent()}
                </div>
            )
        } else {
            return (
                <Row style={{width: '100%', height: '100%'}}>
                    <Col span={16}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%', height: '100vh'}}>
                            <img src={AuthBg} style={{width: '100%', height: '100%', objectFit: 'cover', position: 'absolute', zIndex: '-1' }} />
                            <div style={{width: '100%', height: '100%', background: 'black', opacity: '0.5', position: 'absolute', zIndex: '-1' }} />
                            <h1 style={{color: 'white', fontSize: '7vh'}}>The classroom of the future</h1>
                            <h2 style={{color: 'white', marginTop: '-5vh', fontSize: '2vh'}}>Your journey starts today</h2>
                        </div>
                    </Col>
                    <Col span={8} style={{boxShadow: "0px 0px 15px 4px rgba(0, 0, 0, 0.5)"}}>
                        {this.renderContent()}

                        {this.state.showAccountAccess && 
                        <OrganizationAccessDialog 
                        customActivateHandlerOnSuccess={this.onFinished.bind(this)} customActivateHandler={this.onActivateOrganizationAccount.bind(this)}
                        accountAccess={this.state.accountAccess} visible={this.state.showAccountAccess} onCancel={() => this.setState({showAccountAccess: false})} />}
                    </Col>    
                </Row>
            );
        }
    }

}

export default withMediaQuery(withRouter(FrontendAuthView));