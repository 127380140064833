import CameraStream from "../streams/CameraStream";
import WebRTCBroadcaster from "../webrtc/WebRTCBroadcaster";
import Api from "../../session/Api";
import DesktopStream from "../streams/DesktopStream";
import WebRTCReceiver from "../webrtc/WebRTCReceiver";
import CanvasStream from "../streams/CanvasStream";

export const SESSION_TYPE_CAMERA = 0;
export const SESSION_TYPE_DESKTOP = 1;
export const SESSION_TYPE_BOARD = 2;
export const SESSION_TYPE_STAGE_CAMERA = 3;
export const SESSION_TYPE_STAGE_BOARD_OVERLAY = 4;

class Session {

    hasAudio = true;
    hasVideo = true;

    constructor(liveStreamId, sessionType, broadcasting) {
        this.liveStreamId = liveStreamId;
        this.sessionType = sessionType;
        this.broadcasting = broadcasting;
    }

    getTrackStream() {
        return this.broadcasting ? this.stream.stream : this.receiverStream;
    }

    create(listener) {
        this.listener = listener;
        if (this.broadcasting) {
            this.createStream();
        } else {
            this.createWebRTCSession();
        }
    }

    createStream() {
        this.stream = this.getStream();
        if (this.sessionType === SESSION_TYPE_BOARD || this.sessionType === SESSION_TYPE_STAGE_BOARD_OVERLAY) {
            this.stream.canvas = this.boardCanvas;
        }
        if (this.sessionType === SESSION_TYPE_CAMERA || this.sessionType === SESSION_TYPE_STAGE_CAMERA) {
            this.stream.hasAudio = this.hasAudio;
            this.stream.hasVideo = this.hasVideo;
        }
        this.stream.startStream(success => {
            if (success) {
                this.createWebRTCSession();
            } else {
                this.onFailed();
            }
        })
    }

    createWebRTCSession() {
        Api.createWebRTCEndpoint(this.liveStreamId, this.sessionType, response => {
            if (response.status === true) {
                if (this.broadcasting) {
                    this.webRTCBroadcaster = new WebRTCBroadcaster();
                    this.webRTCBroadcaster.onconnected = () => {
                        this.createSession();
                    }
                    this.webRTCBroadcaster.start(response.payload.endpointId);
                    this.webRTCBroadcaster.addStream(this.stream.stream);
                } else {
                    this.webRTCReceiver = new WebRTCReceiver();
                    this.webRTCReceiver.hasVideo = response.payload.hasVideo;
                    this.webRTCReceiver.hasAudio = response.payload.hasAudio;
                    this.webRTCReceiver.ontrack = event => {
                        //console.log("ON TRACK", event);
                        this.receiverStream = event.streams[0];
                        this.listener(true);
                    }
                    this.webRTCReceiver.start(response.payload.endpointId);
                }
            } else {
                console.log(response)
                this.onFailed();
            }
        })
    }

    createSession() {
        Api.createSession(this.liveStreamId, this.sessionType, this.webRTCBroadcaster.webRTCEndpointId, this.hasAudio, this.hasVideo, response => {
            if (response.status === true) {
                this.listener(true);
            } else {
                this.onFailed();
            }
        })
    }

    onFailed() {
        this.close();
        if (this.listener !== undefined) {
            this.listener(false);
            this.listener = undefined;
        }
    }

    close(callApi) {
        if (this.closed) {
            return;
        }
        this.closed = true;
        if (this.stream !== undefined) {
            this.stream.closeStream()
        }
        if (this.webRTCBroadcaster !== undefined) {
            this.webRTCBroadcaster.close();
        }
        if (this.webRTCReceiver !== undefined) {
            this.webRTCReceiver.close();
        }
        if (callApi && this.broadcasting) {
            Api.closeSession(this.liveStreamId, this.sessionType, i => {})
        }
    }

    getStream() {
        switch (this.sessionType) {
            case SESSION_TYPE_CAMERA:case SESSION_TYPE_STAGE_CAMERA:
                return new CameraStream();
            case SESSION_TYPE_DESKTOP:
                return new DesktopStream();
            case SESSION_TYPE_BOARD:case SESSION_TYPE_STAGE_BOARD_OVERLAY:
                return new CanvasStream();
        }
    }

}

export default Session;