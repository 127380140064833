import CanvasStream from "./CanvasStream";
import { STREAM_TYPE_CAMERA_VIDEO, STREAM_TYPE_DESKTOP_AUDIO, STREAM_TYPE_DESKTOP_VIDEO, STREAM_TYPE_MIC_AUDIO, STREAM_TYPE_STAGE_WHITEBOARD, STREAM_TYPE_WHITEBOARD } from "./StreamTypes";

class Stream {

    /**
     * @type {number}
     */
    streamType;

    /**
     * @type {MediaStream}
     */
    rawStream;

    /**
     * @type {MediaStreamTrack}
     */
    track;

    /**
     * @type {CanvasStream}
     */
    canvasStream;

    /**
     * @type {HTMLCanvasElement}
     */
    whiteboardCanvas;

    /**
     * @type {MediaStream}
     */
    whiteboardCanvasBackgroundStream;

    constructor(streamType) {
        this.streamType = streamType;
    }

    setWhiteboardCanvasBackgroundStream(backgroundStream) {
        this.whiteboardCanvasBackgroundStream = backgroundStream;
        if (this.canvasStream !== undefined) {
            this.canvasStream.setBackgroundStream(this.whiteboardCanvasBackgroundStream);
        }
    }

    async start() {
        switch (this.streamType) {
            case STREAM_TYPE_CAMERA_VIDEO:
                this.rawStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
                this.track = this.rawStream.getVideoTracks()[0];
                break;
            
            case STREAM_TYPE_DESKTOP_VIDEO:
                this.rawStream = await navigator.mediaDevices.getDisplayMedia({ video: true, audio: false });
                this.track = this.rawStream.getVideoTracks()[0];
                break;

            case STREAM_TYPE_MIC_AUDIO:
                this.rawStream = await navigator.mediaDevices.getUserMedia({ video: false, audio: true });
                this.track = this.rawStream.getAudioTracks()[0];
                break;

            case STREAM_TYPE_DESKTOP_AUDIO:
                this.rawStream = await navigator.mediaDevices.getDisplayMedia({ video: true, audio: true });
                this.track = this.rawStream.getAudioTracks()[0];
                break;

            case STREAM_TYPE_WHITEBOARD:case STREAM_TYPE_STAGE_WHITEBOARD:
                if (this.whiteboardCanvas === undefined) {
                    throw new Error("Whiteboard does not exist");
                }

                this.canvasStream = new CanvasStream(this.whiteboardCanvas);
                if (this.whiteboardCanvasBackgroundStream !== undefined) {
                    this.canvasStream.setBackgroundStream(this.whiteboardCanvasBackgroundStream);
                }
                this.canvasStream.startStream()
                
                this.rawStream = this.canvasStream.rawStream;
                this.track = this.rawStream.getVideoTracks()[0];
                break;

            default:
                throw new Error("Invalid stream type");
        }
    }

    close() {
        if (this.canvasStream !== undefined) {
            this.canvasStream.closeStream();
        } else if (this.rawStream !== undefined) {
            this.rawStream.getTracks().forEach(track => track.stop());
        }
    }

}

export default Stream;