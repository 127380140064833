import React from 'react'
import { withRouter } from 'react-router-dom'

class ScrollToTop extends React.Component {
    componentDidUpdate(prevProps) {
      if (this.props.location !== prevProps.location) {
        if (this.shouldIgnore(this.props.location, prevProps.location)) {
          if (this.props.location.search == prevProps.location.search) {
            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            });
          }
          return;
        }

        window.scrollTo(0, 0)
      }
    }

    shouldIgnore(location, prevLocation) {
      //return location.pathname == prevLocation.pathname;
      return location.pathname == prevLocation.pathname;
    }
  
    render() {
      return this.props.children
    }
  }
  
export default withRouter(ScrollToTop)