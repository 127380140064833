import React from 'react';
import Error404Page from '../../pages/Error404Page';
import LogoLarge from '../../images/LogoLarge.png'
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import {
    BrowserRouter as Router,
    Route,
    Switch,
    withRouter
} from 'react-router-dom'

import { Card, Space, Alert } from 'antd';
import { Form, Input, Button, Checkbox } from 'antd';
import Api from '../../session/Api';
import { ACCOUNT_SCOPE_BACKEND } from '../../Constants';
import SessionManager from '../../session/SessionManager';


class AdminAuthView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            errorMessage: undefined,
        }
    }

    onLogin(values) {
        this.setState({
            loading: true,
        })

        Api.login(values.username, values.password, ACCOUNT_SCOPE_BACKEND, (response) => {
            if (response.status === true) {
                SessionManager.createSession(response.payload);
                window.location.reload(false);
            } else {
                this.setState({
                    loading: false,
                    errorMessage: response.message
                })
            }
        })
    }

    render() {
        return (
            <div style={{display: 'flex', flexDirection: 'column', minHeight: '100vh', background: '#ececec'}}>
                <Space align="center" direction="vertical" style={{position: 'absolute', left: '50%', top: '40%', transform: 'translate(-50%, -50%)',}} >
                    <img src={LogoLarge} style={{width: '30vh', marginTop: '5vh'}} />
                    <h1>Admin Panel</h1>
                    <Card title="Login" bordered={false} style={{ width: 500 }}>
                        {this.state.errorMessage !== undefined ? 
                            <Alert
                                message="Error"
                                description={this.state.errorMessage}
                                type="error"
                                closable
                                afterClose={() => this.setState({errorMessage: undefined})}
                                />
                        : null}
                        <Form
                            name="normal_login"
                            className="login-form"
                            style={{marginTop: '2vh'}}
                            initialValues={{ remember: true }}
                            onFinish={this.onLogin.bind(this)}
                            >
                            <Form.Item
                                name="username"
                                rules={[{ required: true, message: 'Field is required' }]}
                            >
                                <Input disabled={this.state.loading} prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[{ required: true, message: 'Field is required' }]}
                            >
                                <Input
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="password"
                                placeholder="Password"
                                disabled={this.state.loading}
                                />
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" htmlType="submit" className="login-form-button" loading={this.state.loading}>
                                    Log in
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </Space>
            </div>
            
        )
    }
}

export default withRouter(AdminAuthView);