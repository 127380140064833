import React from 'react'
import Page from '../../base/Page';

import {
    AuditOutlined
} from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import { Button } from 'antd';
import FooterView from '../../views/public-views/FooterView';

class TermsOfServicePage extends Page {

    getContent() {
        return (<>
            <h2>Table of Contents</h2>
            <ol style={{fontSize: 15, color: "black"}}>
                <li>
                    Accounts
                </li>
                <li>
                    Course Enrollment
                </li>
                <li>
                    Content and Disciplinary
                </li>
                <li>
                    Content Ownership and Learnerix right
                </li>
                <li>
                    Learner’s Accountability
                </li>
                <li>
                    LEARNERIX’s Rights
                </li>
                <li>
                    Tutor Obligations
                </li>
                <li>
                    Updating These Terms
                </li>
                <li>
                    Agreement
                </li>
                <li>
                    Disclaimer
                </li>
            </ol>
            
            <br />
            <h2>1. Accounts</h2>
            <p>
                In order to carry out and proceed with variety of activities on our platform, you need to be registered and have an account.  You must submit all valid information such as an authentic e-mail address and personal identification prior to registering for an account. You are advised to secure your password and you will be held accountable for every activity you conduct on the platform and if any harm that is being exhibited. You shall not give anyone the authority to use your account on your behalf for any purposes.   If by any chance you assume that your account is hacked or someone is invading your privacy, then inform us as soon as possible through our support team in order to take actions and proceed accordingly.  You will not be entitled to demand an access to your account unless you submit all personal identification verifying that you are the owner of the particular account.  In order to obtain the most use of our service such as participating in a specific course, buying/publishing a course, and being an ongoing learner/tutor, you must be considered legally an adult in the region you are living in and providing us valid and authentic information from you in order for the verification process to be confirmed.  In case of severe circumstances, such as death of the user, the account will be deactivated.
            </p>
            <br/>
            


            <h2>2. Course Enrollment</h2>
            <p>
            As a learner in LEARNERIX, you shall not have the permission to share the course or make alterations. In addition, LEAERNERIX usually offers a lifetime access to the learners whenever they wish to enroll for a course. Due to certain circumstances, such as legal factors or change in regulations we may eliminate or hide the course. Learners are not entitled to resell the courses they purchased.
            </p>
            <br/>

            <h2>3. Content and Disciplinary</h2>
            <p>
            You shall solely use our service for academic purposes at all times. You are held accountable for every activity you carry out and whatever content you wish to post, comment, or publish they must conform with our terms and conditions, the regulations of the country you are located, and safety guidelines. You shall maintain a professional behavior at all times when interacting between learners or tutors. You are entirely accountable for your own professionalism using this platform.
            </p>
            <p>You are not entitled to have an access of our services if you are located in the following region (Iran, Cuba, North Korea, Syria, Yemen etc.).</p>
            <p>Your account will be terminated and you will be banned from accessing the platform if we acknowledge or encounter the following events that potentially violates our safety guidelines under our terms and conditions</p>
            <ul>
                <li>
                    If you showcase yourself under false pretenses
                </li>
                <li>
                    If you collect information from others and impersonate their contributions
                </li>
                <li>
                    If you exhibit any counterproductive behavior such as using offensive language, or exhibiting hostile behavior like being verbally and non-verbally abusive etc.
                </li>
                <li>
                    If your content contains or showcases explicit materials which signifies an offence to the audience like deliberately promoting vulgarism (displaying adult content) and cultural appropriation
                </li>
            </ul>
            <br />

            <h2>4. Content Ownership and Learnerix right</h2>
            <p>Whether you are a learner or a tutor, whatever you post or publish you are still entitled to obtain the ownership of the contents you publish on the platform. LEAERNERIX however is entitled to obtain the authorization to use your content for marketing purposes. You shall be aware that whatever content you post on the platform then this will indicate that we use that specific content for marketing purposes and distribute to other entities such as corporations and people who have some sort of affiliation with LEARNERIX.</p>
            <br />

            <h2>5. Learner’s Accountability</h2>
            <p>You shall acknowledge that as a learner, when you enroll in a particular course, therefore you are aware that the course you have enrolled for would only provide you contents associated with what the tutor has already demonstrated and designed. LEAERNERIX does not hold accountable about the precision or validity of the contents provided by the tutor. In addition, as you enroll in any particular course, you shall be aware of the nature of that course which could have an influence on the physical and mental aspect of oneself. Some courses may exhibit contents that you may find offensive due to cultural reasons or perhaps example courses related to physical health may require kinesthetic activity that could potentially impose injury to oneself. As a learner, you willingly accept all the consequences that may or not occur when being exposed to such contents as we do not have the authority to control for the aftermath. You shall agree to hold accountable for any outcome that may occur from the time you have enrolled in the course until the completion of the course.</p>
            <br />

            <h2>6. LEARNERIX Rights</h2>
            <p>We solely have the ownership of what our service and platform include such as our logo and concept etc. and will provide in the future such as potential applications/content that may be created by our potential staff members. We solely are protected by copyright and trademark.</p>
            <br />

            <h2>7.Tutor Obligations</h2>
            <p>When you register an account as a tutor, therefore you acknowledge and accept you are responsible for all the materials relevant to your subjects and the course you wish to teach and upload in different formats such as pdf, audio, video, assessment test etc.</p>
            <br />
            <h3>7.1 You shall conform to the following policies:</h3>
            <ul>
                <li>
                    You will submit and showcase authentic materials at all times.
                </li>
                <li>
                    Your course materials shall not invade any organization’s intellectual property rights.
                </li>
                <li>
                    You obtain official document such as valid identification and authentic qualification associated with the courses you are teaching and your skills that you are demonstrating.
                </li>
                <li>
                    You guarantee to maintain an excellence service associated with the training being demonstrated by you
                </li>
            </ul>
            <h3>7.2 You shall accept you will not:</h3>
            <ul>
                <li>
                    Post anything that may signify an inappropriate or offensive content to others such as the following
                </li>
                <li>
                    Adult content
                </li>
                <li>
                    Discrimination (racism)
                </li>
                <li>
                    Deceptive information
                </li>
                <li>
                    Intentionally conduct any sort of counterproductive behavior that will negatively impact the audience.
                </li>
                <li>
                    Deliberately promote or send any spam materials to any Learnerix user
                </li>
                <li>
                    Conduct or participate in any form of activity which will involve Learnerix to pay
                </li>
                <li>
                    Mimic other public figures or tutors for personal gain and invade their privacy by hacking into their account.
                </li>
                <li>
                    Deliberately restrict other tutors for publishing their course materials and inhibit their achievements.
                </li>
                <li>
                    Intentionally misuse Learnerix resources.
                </li>
            </ul>
            <h3>7.3 Learnerix has the authority to remove any materials that is uploaded on the tutor profile page under the following reasons.</h3>
            <ul>
                <li>
                    Any courses containing explicit and offensive tutorials or the material in regards to the course being provided by them does not conform with our terms and conditions
                </li>
                <li>
                    The course being demonstrated or taught has a negative influence on the learner’s experience.
                </li>
                <li>
                    The tutor constantly receives negative reviews and disputes
                </li>
                <li>
                    The tutor does not conform with the terms and conditions or legal terms.
                </li>
            </ul>

            <h3>7.4 Users data</h3>
            <p>As a tutor, the only information you obtain from learners is what will be submitted to you. You shall accept that you will not misuse any of their data for any other purposes and to maintain a confidential agreement at all times.</p>

            <h3>7.5 Copy Right and Trademark</h3>
            <p>As a tutor you are only entitled to the following in terms of using our trademarks:</p>
            <ul>
                <li>
                    Make use of our trademarks under specific circumstances such as in a time where there is promotion implemented on your specific courses
                </li>
            </ul>
            <p>You  SHALL NOT misuse our trademark for the following purposes:</p>
            <ul>
                <li>
                    Misuse our trademark that implies LEARNERIX grants endorsement or approval of your contents and courses;
                </li>
                <li>
                    Misuse our trademark which associates with offensive contents and which may violate terms and conditions
                </li>
            </ul>
            <h3>7.6 Termination of an account</h3>
            <p>If you decide to delete your account for any purpose instructions will be given to you on our support center. You must also acknowledge that once your account has been deleted, the learning materials demonstrated by you will remain available for learners who have already enrolled to your course.</p>
            <h3>7.7 Mutual Relation</h3>
            <p>You must acknowledge and accept that there is a mutual agreement that none of the following concepts is established between you and Learnerix .</p>
            <ul>
                <li>
                    Joint venture
                </li>
                <li>
                    Partnership
                </li>
                <li>
                    Employment
                </li>
                <li>
                    Contractor
                </li>
                <li>
                    Agency
                </li>
            </ul>
            <br />

            <h2>8. Updating These Terms</h2>
            <p>LEARNERIX solely obtains the right to alter the terms and conditions at any time. For any alternations that may occur on the platform such as advancements of course materials, new features, updated terms and conditions, you will be informed through e-mail and we will also announce it on our home page so you can acknowledge them. You must accept the new terms and conditions being updated as you wish to continue to become a regular user.</p>
            <br />


            <h2>9. Agreement</h2>
            <p>As you become a learner or a tutor you agree to accept all terms and conditions listed on LEARNERIX. If you decline the terms and conditions then you shall not have an access on our services nor carry out any activity on the platform.</p>
            <br />

            <h2>10. Disclaimers</h2>
            <p>Sometimes circumstances may occur that is beyond our control which could impact the quality and performance of our platform. We may need to keep track on the security system to ensure safety is prioritized this could also slow down the speed of our platform. Therefore, LEAERNERIX may also be potentially exposed to external factors such as environmental factors like natural disasters, or legal factors such as government limitations, and technological factors such as glitch in internet access which may require maintenance. As such events happen, you will not be entitled to use any sort of legal action against us as we do not hold accountable for such events.</p>
            <br />


        </>)
    }

    render() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '25px' }}>
                <h1 style={{ marginTop: '25px', marginBottom: '25px' }}><AuditOutlined /> Terms of Service</h1>
                <div style={{ width: '75vw', marginBottom: '25px' }}>
                    {this.getContent()}
                </div>
                <FooterView />
            </div>
        )
    }

}

export default withRouter(TermsOfServicePage);