import React from 'react'
import { Radio, Button, Calendar, Badge } from 'antd';

import NoImage from '../../images/NoImage.png'

import { 
    UnorderedListOutlined,
    CalendarOutlined,
    AlertOutlined,
    FileFilled,
    PlayCircleFilled
} from '@ant-design/icons';
import Api from '../../session/Api';
import Util from '../../util/Util';

import './schedule-preview-list-view.less'
import Countdown from 'antd/lib/statistic/Countdown';

import Moment from 'moment';
import Modal from 'antd/lib/modal/Modal';
import LiveStreamRecordingPlayer from '../../livestream/components/LiveStreamRecordingPlayer';
import withMediaQuery from '../../components/WithMediaQuery';
import LiveStreamWindow from '../../livestream2/windows/LiveStreamWindow';
import ScheduleAttachmentsView from './ScheduleAttachmentsView';

const ScheduleItem = ({schedule, haveTopMargin, recorded, playRecording, viewAttachments}) => (
    <div style={{width: '100%', borderRadius: '15px', padding: '15px', marginTop: haveTopMargin ? "15px" : undefined, boxShadow: "0px 0px 25px 0px rgba(0, 0, 0, 0.2)", display: 'flex'}}>
        <div style={{width: '100px', height: '100px', objectFit: 'cover', borderRadius: '15px'}}>
            <div style={{height: '100%', width: '100%', position: 'relative',}}>
                <img src={Api.getLiveSessionScheduleThumbnail(schedule.id)} style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: '15px'}} onError={e => {
                    e.target.onerror = null;
                    e.target.src = NoImage;
                }} />
            </div>
        </div>

        <div style={{marginLeft: '25px', flex: 1, minWidth: 1}}>
            <div style={{display: 'flex', alignItems: 'flex-start', marginRight: '5px', justifyContent: 'flex-end', flexWrap: 'wrap'}}>
                <h2 style={{margin: 0}}>{schedule.title}</h2>
                {/* <h2 style={{magrin: 0}}>{'Introduction to Learnerix & Signing Up'}</h2> */}
                
                {Util.isNumberExist(schedule.date) && <Button type="link" style={{cursor: 'default'}}>{Util.getFullDate(schedule.date)}</Button>}
                {Util.isNumberExist(schedule.duration) && <Button type="link" style={{cursor: 'default'}}>{Util.formatSeconds(schedule.duration * 60)}</Button>}

                <div style={{flex: '1'}} />

                {(schedule.date + (schedule.duration * 60000)) > new Date().getTime() ? (
                    <Button type="link" className="red-link" style={{cursor: 'default', display: 'flex', alignItems: 'center'}}>
                        <AlertOutlined /> Live stream in {schedule.date > new Date().getTime() ? <Countdown style={{marginLeft: '15px'}} value={schedule.date} valueStyle={{fontSize: '15px', color: '#990000'}}/> : " progress"}
                    </Button>
                ) : (<>
                    <Button type="text"style={{cursor: 'default', display: 'flex', alignItems: 'center'}}>
                        Live stream is over
                    </Button>
                    {recorded && 
                    <Button icon={<PlayCircleFilled />} type={playRecording ? "primary" : "text"} style={{cursor: !playRecording && 'default', display: 'flex', alignItems: 'center', color: !playRecording && '#990000'}} onClick={playRecording ? () => playRecording() : undefined} className={playRecording && "red-button"}>
                        {playRecording ? 'Play Recording' : 'Recorded'}
                    </Button>}
                    
                    {playRecording && schedule.hasAttachments && <Button onClick={viewAttachments} type="primary" icon={<FileFilled />} style={{marginLeft: 5}}>
                        Documents
                    </Button>}
                </>)}
            </div>
            
            {//<p style={{paddingTop: '5px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', flex: '1'}}>
            }
            <p style={{paddingTop: '5px', marginTop: '5px', overflowWrap: 'break-word', whiteSpace: 'pre-line'}}>{schedule.description}</p>
        </div>
    </div>
)

const SmallScheduleItem = ({schedule, haveTopMargin, recorded, playRecording, viewAttachments}) => (
    <div style={{width: '100%', borderRadius: '15px', padding: '15px', marginTop: haveTopMargin ? "15px" : undefined,
    boxShadow: "0px 0px 25px 0px rgba(0, 0, 0, 0.2)", display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <div style={{width: '100%', height: '150px', objectFit: 'cover', borderRadius: '15px'}}>
            <div style={{height: '100%', width: '100%', position: 'relative',}}>
                <img src={Api.getLiveSessionScheduleThumbnail(schedule.id)} style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: '15px'}} onError={e => {
                    e.target.onerror = null;
                    e.target.src = NoImage;
                }} />
            </div>
        </div>

        <h2 style={{height: '25px', marginTop: '15px'}}>{schedule.title}</h2>

        <div style={{flex: 1, minWidth: 1}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
                {Util.isNumberExist(schedule.date) && <Button type="link" style={{cursor: 'default', margin: '0px', padding: '0px'}}>{Util.getFullDate(schedule.date)}</Button>}
                <div style={{flex: '1'}} />
                {Util.isNumberExist(schedule.duration) && <Button type="link" style={{cursor: 'default', margin: '0px', padding: '0px'}}>{Util.formatSeconds(schedule.duration * 60)}</Button>}
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
                
                {(schedule.date + (schedule.duration * 60000)) > new Date().getTime() ? (
                    <Button type="link" className="red-link" style={{cursor: 'default', display: 'flex', alignItems: 'center', margin: '0px', padding: '0px'}}>
                        <AlertOutlined /> Live stream in {schedule.date > new Date().getTime() ? <Countdown style={{marginLeft: '15px'}} value={schedule.date} valueStyle={{fontSize: '15px', color: '#990000'}}/> : " progress"}
                    </Button>
                ) : (<>
                    <Button type="text"style={{cursor: 'default', display: 'flex', alignItems: 'center', margin: '0px', padding: '0px'}}>
                        Live stream is over
                    </Button>
                    <div style={{flex: '1'}} />
                    {recorded && 
                    <Button type={playRecording ? "primary" : "text"} style={{cursor: !playRecording && 'default', display: 'flex', alignItems: 'center', color: !playRecording && '#990000', margin: '0px', padding: '0px'}} onClick={playRecording ? () => playRecording() : undefined} className={playRecording && "red-button"}>
                        {playRecording ? 'Play Recording' : 'Recorded'}
                    </Button>}
                </>)}
            </div>

            {playRecording && schedule.hasAttachments && <Button onClick={viewAttachments} type="primary" icon={<FileFilled />} style={{marginTop: 5}}>
                Documents
            </Button>}
            
            {//<p style={{paddingTop: '5px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', flex: '1'}}>
            }
            <p style={{paddingTop: '5px', marginTop: '5px', overflowWrap: 'break-word', whiteSpace: 'pre-line'}}>{schedule.description}</p>
        </div>
    </div>
)

class SchedulePreviewListView extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            mode: "list",
            playLiveStream: undefined,
            showAttachmentsDialog: undefined
        }
    }

    renderSchedule() {
        switch (this.state.mode) {
            case "list":
                return this.renderListView();
            case "calendar":
                return this.renderCalendarView();
        }
    }

    renderListView() {
        if (this.props.isSmall) {
            return this.props.schedules.map(schedule => (
                <SmallScheduleItem schedule={schedule} haveTopMargin recorded={this.props.recorded} viewAttachments={() => this.setState({showAttachmentsDialog: schedule.id})} playRecording={this.props.playRecording ? () => this.setState({playLiveStream: schedule.liveStreamId}) : undefined} />
            ))
        } else {
            return this.props.schedules.map(schedule => (
                <ScheduleItem schedule={schedule} haveTopMargin recorded={this.props.recorded} viewAttachments={() => this.setState({showAttachmentsDialog: schedule.id})} playRecording={this.props.playRecording ? () => this.setState({playLiveStream: schedule.liveStreamId}) : undefined} />
            ))
        }
    }

    renderCalendarView() {
        return <Calendar dateCellRender={value => {
            /*return this.props.schedules.filter(schedule => Moment(schedule.date).isSame(value, "day"))
                    .map(schedule => (
                        <Badge status="error" text={schedule.title} />
                    ))*/
            return (
                <ul className="events">
                    {this.props.schedules.filter(schedule => Moment(schedule.date).isSame(value, "day"))
                    .map(schedule => (
                        <li key={schedule.id}>
                            <Badge status="error" text={schedule.title + " " + Util.getTime(schedule.date)} />
                        </li>
                    ))}
                </ul>
            )
        }} className="schedule-preview-calendar" />
    }

    render() {
        return (
            <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
                <div style={{display: 'flex', width: '100%', alignItems: 'center'}}>
                    <h2 style={{flex: '1', height: '20px'}}><CalendarOutlined /> Schedule</h2>
                    <Radio.Group value={this.state.mode} onChange={e => this.setState({mode: e.target.value})}>
                        <Radio.Button value="list"><UnorderedListOutlined /></Radio.Button>
                        <Radio.Button value="calendar"><CalendarOutlined /></Radio.Button>
                    </Radio.Group>
                </div>
                {this.props.schedules !== undefined && this.renderSchedule()}

                {this.state.playLiveStream !== undefined &&
                <Modal width="95vw" 
                closable={false}
                visible={this.state.playLiveStream !== undefined} 
                centered footer={null} bodyStyle={{padding: '0px'}} onCancel={() => this.setState({playLiveStream: undefined})}>
                    <div style={{display: 'flex', height: '90vh'}}>
                        <LiveStreamWindow recordingMode liveStreamId={this.state.playLiveStream} onBack={() => this.setState({playLiveStream: undefined})} />
                    </div>
                </Modal>}

                {this.state.showAttachmentsDialog !== undefined &&
                <Modal 
                title="Documents"
                cancelText={null}
                footer={null}
                visible={this.state.showAttachmentsDialog !== undefined} onCancel={() => this.setState({showAttachmentsDialog: undefined})}>
                    <ScheduleAttachmentsView scheduleId={this.state.showAttachmentsDialog} />
                </Modal>}
            </div>
        )
    }
}

export default withMediaQuery(SchedulePreviewListView);