import React from 'react'
import { Card, Rate, Button, Tag } from 'antd';
import ProfilePicture from '../../components/ProfilePicture';
import Meta from 'antd/lib/card/Meta';

import { Link, useHistory } from 'react-router-dom';
import Api from '../../session/Api';

import NoImage from '../../images/NoImage.png';
import ProductImagePlaceholder from '../../images/ProductImagePlaceholder.png';

import {
    PlayCircleFilled,
    AlertOutlined,
    MessageOutlined,
    UnlockFilled
} from '@ant-design/icons';
import Countdown from '../../components/Countdown';
import FrontendAuthModal from '../../components/FrontendAuthModal';
import SessionManager from '../../session/SessionManager';
import { ACCOUNT_ROLE_TUTOR } from '../../Constants';

const LiveStateIndicator = ({state}) => {
    if (state.inProgress) {
        return <Button type="link" className="red-link"><AlertOutlined /> Live stream in progress</Button>
    } else if (state.startDate > 0) {
        return <Countdown date={state.startDate} />
    } else {
        return state.recorded ? (
            <Button type="text" className="red-link" icon={<PlayCircleFilled />}>Watch Recording</Button>
        ) : (
            <Button type="text">Session is over</Button>
        )
    }
}

export default function SaleLiveSessionItem(props) {
    const [showAuth, setShowAuth] = React.useState(false)
    const history = useHistory();

    const liveSession = props.liveSession;
    return (
        <div className="card-extra-full">
            {/*<Link to={'/live-session-sale/' + liveSession.id}>*/}
                <Card
                    onClick={() => history.push('/live-session-sale/' + liveSession.id)}
                    extra={<div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
                        <Button type="link" style={{padding: 0}} onClick={e => {
                            e.stopPropagation();
                            if (SessionManager.isLoggedIn()) {
                                history.push('/messages?userId=' + liveSession.tutorId);
                            } else {
                                setShowAuth(true);
                            }
                        }}><MessageOutlined /></Button>

                        <div style={{flex: '1'}} />

                        <h5 title={liveSession.tutorName} style={{marginTop: '5px', marginLeft: 10, marginRight: '10px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{liveSession.tutorName}</h5>
                        <ProfilePicture showStatus={liveSession.tutorRole == ACCOUNT_ROLE_TUTOR} userId={liveSession.tutorId} />
                    </div>
                    }
                    actions={[<div style={{height: "60px", display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <LiveStateIndicator state={liveSession.upcomingState} liveSession={liveSession} />
                    </div>]}
                    hoverable
                    style={{ width: '100%' }}
                    cover={<div style={{width: '100%', height: '350px', position: 'relative'}}>
                        <img src={Api.getLiveSessionThumbnail(liveSession.id)} style={{width: '100%', height: '350px', position: 'absolute', objectFit: 'cover'}} onError={e => {
                            e.target.onerror = null;
                            e.target.src = ProductImagePlaceholder;
                        }}/>
                        <PlayCircleFilled style={{fontSize: '50px', color: 'white', opacity: '0.60', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}} />
                    </div>}
                    >
                    <Meta 
                    //title={<div class="ant-card-meta-title">{liveSession.title}</div>} 
                    title={
                        <>
                            <div class="ant-card-meta-title">{liveSession.title}</div>
                            {liveSession.allowNonEnrollmentAccess && <Tag icon={<UnlockFilled />} color="green">Enrollment Not Required</Tag>}
                        </>
                    }
                    description={<p style={{height: 50 - (liveSession.allowNonEnrollmentAccess ? 22 : 0)}}>{liveSession.description}</p>} />
                </Card>
            {/*</Link>*/}
            {showAuth && <FrontendAuthModal visible={showAuth} onCancel={() => setShowAuth(false)} onDonePath={'/messages?userId=' + liveSession.tutorId} />}
        </div>
    )
}