import { Card, Tag } from 'antd';
import React from 'react'


import CoverImage from '../../images/CoverPlaceholder.jpg';
import Api from '../../session/Api';
import Util from '../../util/Util';

import { 
    CommentOutlined,
    LikeOutlined
} from '@ant-design/icons';
import { BLGO_POST_TAGS, BLOG_POST_TAG_TUTORIALS } from '../../BlogPostTags';
import { Link } from 'react-router-dom';


class GridBlogPostItem extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            hover: false
        }
    }

    getBlogPostTag() {
        for (const tag of BLGO_POST_TAGS) {
            if (tag.id == this.props.blogPost.tag) {
                return tag;
            }
        }

        return BLGO_POST_TAGS[0];
    }

    render() {
        const blogPost = this.props.blogPost;
        const tag = this.getBlogPostTag();
        return (
            <Link to={Util.getBlogPostPath(false, blogPost.title, blogPost.date)}>
                <Card style={{borderRadius: 5, overflow: 'hidden', cursor: 'pointer'}} 
                onMouseEnter={() => this.setState({hover: true})}
                onMouseLeave={() => this.setState({hover: false})}
                bodyStyle={{padding: 0, position: 'relative', backgroundColor: 'black'}} hoverable={false} bordered={false}>
                    <img src={Api.getBlogPostThumbnail(blogPost.id)} style={{width: '100%', height: '300px', objectFit: 'cover'}}
                    onError={e => {
                        e.target.onerror = null;
                        e.target.src = CoverImage;
                    }}/>

                    <div style={{position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', }}>
                        <div style={{position: 'absolute', height: this.state.hover ? '100%' : 175, width: '100%', bottom: 0, transition: '250ms',
                        backgroundImage: this.state.hover ? 'linear-gradient(to bottom, rgba(0,0,0,0.2), rgba(0,0,0,0.65) 20%, rgba(0,0,0,1) 60%)' : 
                        'linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.65) 40%, rgba(0,0,0,1) 80%)'}} />

                        <Tag color={tag.color} style={{position:'absolute', right: 10, top: 10, margin: 0, opacity: 1, fontSize: 14, fontWeight: 'lighter'}}>{tag.title}</Tag>

                        <div style={{position: 'absolute', width: '100%', transition: '250ms', //bottom: 0, height: this.state.hover ? 265 : 65, 
                        padding: 10,
                    bottom: this.state.hover ? 135 : 0, height: undefined}}>
                            <h1 style={{fontStyle: 'bold', margin: 0, marginBottom: 0, color: 'white', lineHeight: 1, }}>{blogPost.title}</h1>
                            <div style={{display: 'flex', width: '100%', alignItems: 'center'}}>
                                <p style={{margin: 0, color: 'white', opacity: 1, fontSize: 14, fontWeight: 'lighter'}}>{blogPost.userFullName}</p>
                                <div style={{marginLeft: 10, marginRight: 10, width: 4, height: 4, opacity: 0.65, background: 'white', borderRadius: '50%'}} />
                                <p style={{margin: 0, color: 'white', opacity: 1, fontSize: 14, fontWeight: 'lighter'}}>{Util.getDateOnly(blogPost.date)}</p>
                                
                                <div style={{flex: 1}} />

                                <p style={{margin: 0, color: 'white', opacity: 1, fontSize: 14, fontWeight: 'lighter'}}><CommentOutlined style={{marginRight: 2}} /> {blogPost.commentCount}</p>
                                <div style={{marginLeft: 5, marginRight: 5, width: 4, height: 4, opacity: 0, background: 'white', borderRadius: '50%'}} />
                                <p style={{margin: 0, color: 'white', opacity: 1, fontSize: 14, fontWeight: 'lighter'}}><LikeOutlined style={{marginRight: 2}} /> {blogPost.likeCount}</p>
                            </div>

                            {this.state.hover &&
                            <p style={{margin: 0, maxHeight: 135, height: 135, marginTop: 10, marginBottom: -135, color: 'white', opacity: 1, fontSize: 16, fontWeight: 'lighter', overflowWrap: 'break-word', whiteSpace: 'pre-line',
                            }}>
                                {blogPost.preview}
                            </p>}
                        </div>
                    </div>
                </Card>
            </Link>
        )
    }

}

export default GridBlogPostItem;