import React from 'react';
import { Button } from 'antd';

import {  
    AlertOutlined
} from '@ant-design/icons';
import Countdown from 'antd/lib/statistic/Countdown';

export default function LiveStreamScheduleState(props) {
    const state = props.state;
    if (state === undefined) {
        return null;
    }

    //state.startDate = new Date().getTime() + 5000;
    if (state.inProgress) {
        return <Button type="link" className="red-link" style={{cursor: 'default'}}><AlertOutlined /> Live stream in progress</Button>
    } else if (state.startDate > 0) {
        return (
            <Button type="link" className="red-link" style={{cursor: 'default', display: 'flex', alignItems: 'center'}}>
                <AlertOutlined /> Live stream in <Countdown style={{marginLeft: '15px'}} 
                value={state.startDate} valueStyle={{fontSize: '15px', color: '#990000'}}
                onFinish={() => {
                    state.inProgress = true;
                    if (props.onStateUpdate !== undefined) {
                        props.onStateUpdate();
                    }
                }} />
            </Button>
        )
    } else {
        return <Button type="text" style={{cursor: 'default'}}>No more live streams</Button>
    }
}