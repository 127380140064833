import { Button, Input, Popover, Slider } from 'antd';
import React from 'react'

import { 
    DesktopOutlined,
    VideoCameraOutlined,
    EyeOutlined,
    DragOutlined,
    EditOutlined,
    LineOutlined,
    BorderOutlined,
    AimOutlined,
    ToolOutlined,
    CloseOutlined,
    FontSizeOutlined,
    CheckOutlined,
    CameraOutlined,
    BgColorsOutlined,
    StockOutlined,
    InsertRowBelowOutlined,
    CaretDownOutlined,
    VideoCameraFilled,
    EditFilled,
    AudioFilled
} from '@ant-design/icons';

import Draggable from 'react-draggable';
import {SketchField, Tools} from 'react-sketch';
import UploadWrapper from '../../components/UploadWrapper';
import { TwitterPicker } from 'react-color';
import { WHITEBOARD_STREAM_HEIGHT, WHITEBOARD_STREAM_WIDTH } from '../streams/CanvasStream';
import Util from '../../util/Util';
import Video from '../../components/Video';

const createNewState = () => ({
    key: Util.newTempId(),

    selectedWhiteboardTool: Tools.Pencil,
    whiteboardAddTextValue: "",
    whiteboardStrokeColor: "black",
    whiteboardStrokeWeight: 3
})

class Whiteboard extends React.Component {

    constructor(props) {
        super(props)
        
        this.state = {
            ...createNewState()
        }

        this.whiteboardRef = React.createRef();
    }

    clear() {
        this.setState({ ...createNewState() })
    }

    getCanvas() {
        return this.whiteboardRef.current._canvas;
    }

    renderWhiteboardTool(tool, icon) {
        return (
            <div className="live-stream-active-button">
                <Button onClick={e => this.setState({selectedWhiteboardTool: tool})} type={this.state.selectedWhiteboardTool === tool ? "primary" : "link"} size="large" 
                className={this.state.selectedWhiteboardTool === tool ? "red-button" : "red-link"} style={{color: 'white'}}>{icon}</Button>
            </div>
        )
    }

    render() {
        return (
            <div key={this.state.key} style={!this.props.visible ? {opacity: '0', pointerEvents: 'none'} : undefined}>
                <Draggable handle=".side-bar">
                    <div style={{position: 'fixed', top: '20px', left: '20px', borderRadius: '25px', boxShadow: "0px 0px 25px 0px rgba(0, 0, 0, 0.2)", overflow: 'hidden', display: 'flex'}}>
                        <div className="side-bar" style={{background: '#1B1B1B', width: '45px', height: WHITEBOARD_STREAM_HEIGHT, 
                        borderRadius: '25px 0 0 25px', cursor: 'move', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <div style={{flex: '1', overflow: 'auto'}}>
                                <br />
                                {this.renderWhiteboardTool(Tools.Select, <ToolOutlined />)}
                                {this.renderWhiteboardTool(Tools.Pencil, <EditOutlined />)}
                                {this.renderWhiteboardTool(Tools.Line, <LineOutlined />)}
                                {this.renderWhiteboardTool(Tools.Rectangle, <BorderOutlined />)}
                                {this.renderWhiteboardTool(Tools.Circle, <AimOutlined />)}
                                {this.renderWhiteboardTool(Tools.Pan, <DragOutlined />)}
                                <div style={{height: '25px'}} />
                                <Popover placement="topLeft" trigger="click" content={
                                    <Input size="large"
                                    onPressEnter={e => {
                                        this.whiteboardRef.current.addText(this.state.whiteboardAddTextValue)
                                        this.setState({whiteboardAddTextValue: ""})
                                    }}
                                    value={this.state.whiteboardAddTextValue} onChange={e => this.setState({whiteboardAddTextValue: e.target.value})} style={{ width: '300px' }} placeholder="Add text" 
                                    suffix={<Button disabled={this.state.whiteboardAddTextValue === undefined || this.state.whiteboardAddTextValue.trim() === ""} onClick={e => {
                                        this.whiteboardRef.current.addText(this.state.whiteboardAddTextValue)
                                        this.setState({whiteboardAddTextValue: ""})
                                    }}><CheckOutlined /></Button>} />
                                }>
                                    <Button type={"link"} size="large" 
                                        className={"red-link"} style={{color: 'white'}}><FontSizeOutlined /></Button>
                                </Popover>
                                {//!((!this.state.isStageUser && this.state[sessionEnabled(SESSION_TYPE_STAGE_BOARD_OVERLAY)]) || this.state.isStageUser) && 
                                <UploadWrapper accept="image/gif,image/jpeg,image/png" onChange={e => this.whiteboardRef.current.addImg(URL.createObjectURL(e.target.files[0]))}>
                                    <Button type={"link"} size="large" 
                                        className={"red-link"} style={{color: 'white'}}><CameraOutlined /></Button>
                                </UploadWrapper>}
                                <div style={{height: '25px'}} />
                                <Popover placement="topLeft" trigger="click" content={
                                    <TwitterPicker triangle="hide" style={{width: '300px'}} color={this.state.whiteboardStrokeColor} onChangeComplete={color => this.setState({whiteboardStrokeColor: color.hex})} />
                                }>
                                    <Button type={"link"} size="large" 
                                    className={"red-link"} style={{color: 'white'}}><BgColorsOutlined /></Button>
                                </Popover>
                                <Popover placement="topLeft" trigger="click" content={
                                    <Slider min={1} style={{width: '300px'}} max={100} value={this.state.whiteboardStrokeWeight} onChange={value => this.setState({whiteboardStrokeWeight: value})} />
                                }>
                                    <Button type={"link"} size="large" 
                                    className={"red-link"} style={{color: 'white'}}><StockOutlined /></Button>
                                </Popover>
                            </div>

                            <Button onClick={this.props.onClose} type={"link"} size="large" 
                                className={"red-link"} style={{color: 'white'}}><CloseOutlined /></Button>
                            <br />
                        </div>
                        <div style={{width: WHITEBOARD_STREAM_WIDTH, height: WHITEBOARD_STREAM_HEIGHT, backgroundColor: 'white'}}>
                            {/* {this.state.isStageUser &&
                            <div style={{width: WHITEBOARD_STREAM_WIDTH, height: WHITEBOARD_STREAM_HEIGHT, backgroundColor: 'white', position: 'absolute', left: 45, top: 0}}>
                                <Video alwaysMuted muted srcObject={this.state[sessionStream(SESSION_TYPE_BOARD)]} 
                                style={{width: '100%', height: '100%'}} autoPlay playsInline controls={false} />
                            </div>}
                            {!this.state.isStageUser && this.state[sessionEnabled(SESSION_TYPE_STAGE_BOARD_OVERLAY)] &&
                            <div style={{width: WHITEBOARD_STREAM_WIDTH, height: WHITEBOARD_STREAM_HEIGHT, backgroundColor: 'white', position: 'absolute', left: 45, top: 0}}>
                                <Video alwaysMuted muted srcObject={this.state[sessionStream(SESSION_TYPE_STAGE_BOARD_OVERLAY)]} 
                                style={{width: '100%', height: '100%'}} autoPlay playsInline controls={false} />
                            </div>} */}
                            {this.props.backgroundStream !== undefined &&
                            <div style={{width: WHITEBOARD_STREAM_WIDTH, height: WHITEBOARD_STREAM_HEIGHT, backgroundColor: 'white', position: 'absolute', left: 45, top: 0}}>
                                <Video alwaysMuted muted srcObject={this.props.backgroundStream.rawStream} 
                                style={{width: '100%', height: '100%'}} autoPlay playsInline controls={false} />
                            </div>}

                            <SketchField
                            ref={this.whiteboardRef}
                            tool={this.state.selectedWhiteboardTool}
                            backgroundColor={"#ffffff00"}
                            widthCorrection={0}
                            width={WHITEBOARD_STREAM_WIDTH}
                            height={WHITEBOARD_STREAM_HEIGHT}
                            lineColor={this.state.whiteboardStrokeColor === undefined ? "black" : this.state.whiteboardStrokeColor}
                            lineWidth={this.state.whiteboardStrokeWeight === undefined ? 3 : this.state.whiteboardStrokeWeight}/>
                        </div>
                    </div>
                </Draggable>
            </div>
        )
    }

}

export default Whiteboard;