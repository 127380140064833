
export const STREAM_TYPE_CAMERA_VIDEO = 0;
export const STREAM_TYPE_DESKTOP_VIDEO = 1;
export const STREAM_TYPE_MIC_AUDIO = 2
export const STREAM_TYPE_WHITEBOARD = 3;
export const STREAM_TYPE_STAGE_WHITEBOARD = 4;
export const STREAM_TYPE_DESKTOP_AUDIO = 5;

export const ALL_STREAM_TYPES = [
    STREAM_TYPE_CAMERA_VIDEO,
    STREAM_TYPE_DESKTOP_VIDEO,
    STREAM_TYPE_MIC_AUDIO,
    STREAM_TYPE_WHITEBOARD,
    STREAM_TYPE_STAGE_WHITEBOARD,
    STREAM_TYPE_DESKTOP_AUDIO
]

export const isStreamTypeVideo = (streamType) => {
    if (streamType == STREAM_TYPE_MIC_AUDIO || streamType == STREAM_TYPE_DESKTOP_AUDIO) {
        return false;
    } else {
        return true;
    }
}