import { Button, Divider, Input, Menu } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import React from 'react'
import Page from '../base/Page';
import withMediaQuery from '../components/WithMediaQuery';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import { 
    MailOutlined,
    AppstoreOutlined,
    SettingOutlined,
    UserOutlined,
    ContainerOutlined,
    ArrowRightOutlined,
    ContactsOutlined,
    EditOutlined,
    PhoneOutlined,
    LockOutlined,
    FileProtectOutlined,
    AuditOutlined,
    QuestionCircleOutlined,
    UnorderedListOutlined,

    AppleOutlined,
    AndroidOutlined,
    WindowsOutlined,
    MacCommandOutlined,
    DesktopOutlined,
} from '@ant-design/icons';
import SessionManager from '../session/SessionManager';
import Util from '../util/Util';
import { Link } from 'react-router-dom';
import Api from '../session/Api';
import Modal from 'antd/lib/modal/Modal';
import UIUtil from '../util/UIUtil';
import { APP_STORE_APP_LINK, GOOGLE_PLAY_APP_LINK } from '../Constants';

class SettingsPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            selectedMenu: "my-account",
            
            fullName: SessionManager.getAccount().fullName,
            phoneNumber: undefined,

            showUpdateFullName: false,
            newFullNameValue: "",
            updatingFullName: false,

            showUpdatePhoneNumber: false,
            newPhoneNumberValue: "",
            updatingPhoneNumber: false,

            showUpdatePassword: false,
            currentPasswordValue: "",
            newPasswordValue: "",
            newConfirmPasswordValue: "",
            updatingPassword: false
        }
    }

    onPageStart() {
        if (SessionManager.isLoggedInAsTutor()) {
            Api.getPhoneNumber(response => {
                if (response.status === true) {
                    this.setState({phoneNumber: response.payload})
                }
            })
        }
    }

    updateFullName() {
        if (!Util.isStringExists(this.state.newFullNameValue)) {
            return;
        }

        this.setState({updatingFullName: true})

        const newFullName = this.state.newFullNameValue;
        Api.updateAccountInfo(newFullName, undefined, response => {
            if (response.status === true) {
                UIUtil.showSuccess();

                SessionManager.updateAccount(account => account.fullName = newFullName);
                this.setState({updatingFullName: false, showUpdateFullName: false, fullName: newFullName})
            } else {
                UIUtil.showError();
                this.setState({updatingFullName: false})
            }
        })
    }

    updatePhoneNumber() {
        if (!Util.isStringExists(this.state.newPhoneNumberValue)) {
            return;
        }

        this.setState({updatingPhoneNumber: true})

        const newPhoneNumber = this.state.newPhoneNumberValue;
        Api.updateAccountInfo(undefined, newPhoneNumber, response => {
            if (response.status === true) {
                UIUtil.showSuccess();
                this.setState({updatingPhoneNumber: false, showUpdatePhoneNumber: false, phoneNumber: newPhoneNumber})
            } else {
                UIUtil.showError();
                this.setState({updatingPhoneNumber: false})
            }
        })
    }

    updatePassword() {
        if (!Util.isStringExists(this.state.currentPasswordValue) || !Util.isStringExists(this.state.newPasswordValue) || !Util.isStringExists(this.state.newConfirmPasswordValue)) {
            return;
        }

        if (this.state.newPasswordValue != this.state.newConfirmPasswordValue) {
            UIUtil.showError("Passwords do not match!");
            return;
        }

        this.setState({updatingPassword: true})
        Api.updateAccountPassword(this.state.currentPasswordValue, this.state.newPasswordValue, response => {
            if (response.status === true) {
                UIUtil.showSuccess();
                this.setState({updatingPassword: false, showUpdatePassword: false})
            } else {
                UIUtil.showError(response.message);
                this.setState({updatingPassword: false})
            }
        })
    }

    renderGeneralResources() {
        return (
            <div>
                <h2 style={{}}><ContainerOutlined /> General Resources</h2>

                <Divider orientation="left" style={{width: '100%', marginTop: 25}}>Learnerix</Divider>
                <div style={{ marginTop: 7}}>
                    <Link to="/blog" target="_blank">
                        <div className="settings-item" style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10}}>
                            <Button size="large" type="link" style={{marginRight: 10, marginLeft: 2, pointerEvents: 'none', padding: 2}} >
                                <UnorderedListOutlined style={{fontSize: 24, marginTop: 3}} />
                            </Button>
                            <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                                <h3 style={{color: 'black', margin: 0}}>Blog</h3>
                            </div>
                            {<Button type="link" style={{marginTop: 2}}><ArrowRightOutlined /></Button>}
                        </div>
                    </Link>
                </div>
                <div style={{ marginTop: 7}}>
                    <Link to="/about-us" target="_blank">
                        <div className="settings-item" style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10}}>
                            <Button size="large" type="link" style={{marginRight: 10, marginLeft: 2, pointerEvents: 'none', padding: 2}} >
                                <QuestionCircleOutlined style={{fontSize: 24, marginTop: 3}} />
                            </Button>
                            <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                                <h3 style={{color: 'black', margin: 0}}>About us</h3>
                            </div>
                            {<Button type="link" style={{marginTop: 2}}><ArrowRightOutlined /></Button>}
                        </div>
                    </Link>
                </div>
                <div style={{ marginTop: 7}}>
                    <Link to="/contact" target="_blank">
                        <div className="settings-item" style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10}}>
                            <Button size="large" type="link" style={{marginRight: 10, marginLeft: 2, pointerEvents: 'none', padding: 2}} >
                                <PhoneOutlined style={{fontSize: 24, marginTop: 3}} />
                            </Button>
                            <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                                <h3 style={{color: 'black', margin: 0}}>Contact us</h3>
                            </div>
                            {<Button type="link" style={{marginTop: 2}}><ArrowRightOutlined /></Button>}
                        </div>
                    </Link>
                </div>

                <Divider orientation="left" style={{width: '100%', marginTop: 40}}>Apps</Divider>
                <div style={{ marginTop: 7}}>
                    <a target="_blank" href={APP_STORE_APP_LINK}>
                        <div className="settings-item" style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10}}>
                            <Button size="large" type="link" style={{marginRight: 10, marginLeft: 2, pointerEvents: 'none', padding: 2}} >
                                <AppleOutlined style={{fontSize: 24, marginTop: 3}} />
                            </Button>
                            <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                                <h3 style={{color: 'black', margin: 0}}>iOS</h3>
                            </div>
                            {<Button type="link" style={{marginTop: 2}}><ArrowRightOutlined /></Button>}
                        </div>
                    </a>
                </div>
                <div style={{ marginTop: 7}}>
                    <a target="_blank" href={GOOGLE_PLAY_APP_LINK}>
                        <div className="settings-item" style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10}}>
                            <Button size="large" type="link" style={{marginRight: 10, marginLeft: 2, pointerEvents: 'none', padding: 2}} >
                                <AndroidOutlined style={{fontSize: 24, marginTop: 3}} />
                            </Button>
                            <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                                <h3 style={{color: 'black', margin: 0}}>Android</h3>
                            </div>
                            {<Button type="link" style={{marginTop: 2}}><ArrowRightOutlined /></Button>}
                        </div>
                    </a>
                </div>

                <div style={{ marginTop: 25}}>
                    <a target="_blank" download href="/Learnerix.exe">
                        <div className="settings-item" style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10}}>
                            <Button size="large" type="link" style={{marginRight: 10, marginLeft: 2, pointerEvents: 'none', padding: 2}} >
                                <WindowsOutlined style={{fontSize: 24, marginTop: 3}} />
                            </Button>
                            <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                                <h3 style={{color: 'black', margin: 0}}>Windows</h3>
                            </div>
                            {<Button type="link" style={{marginTop: 2}}><ArrowRightOutlined /></Button>}
                        </div>
                    </a>
                </div>
                <div style={{ marginTop: 7}}>
                    <a target="_blank" download href="/Learnerix.dmg">
                        <div className="settings-item" style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10}}>
                            <Button size="large" type="link" style={{marginRight: 10, marginLeft: 2, pointerEvents: 'none', padding: 2}} >
                                <MacCommandOutlined style={{fontSize: 24, marginTop: 3}} />
                            </Button>
                            <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                                <h3 style={{color: 'black', margin: 0}}>macOS</h3>
                            </div>
                            {<Button type="link" style={{marginTop: 2}}><ArrowRightOutlined /></Button>}
                        </div>
                    </a>
                </div>
                <div style={{ marginTop: 7}}>
                    <a target="_blank" download href="/Learnerix.AppImage">
                        <div className="settings-item" style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10}}>
                            <Button size="large" type="link" style={{marginRight: 10, marginLeft: 2, pointerEvents: 'none', padding: 2}} >
                                <DesktopOutlined style={{fontSize: 24, marginTop: 3}} />
                            </Button>
                            <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                                <h3 style={{color: 'black', margin: 0}}>Linux</h3>
                            </div>
                            {<Button type="link" style={{marginTop: 2}}><ArrowRightOutlined /></Button>}
                        </div>
                    </a>
                </div>

                <Divider orientation="left" style={{width: '100%', marginTop: 40}}>Legal</Divider>
                <div style={{ marginTop: 7}}>
                    <Link to="/terms-of-service" target="_blank">
                        <div className="settings-item" style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10}}>
                            <Button size="large" type="link" style={{marginRight: 10, marginLeft: 2, pointerEvents: 'none', padding: 2}} >
                                <AuditOutlined style={{fontSize: 24, marginTop: 3}} />
                            </Button>
                            <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                                <h3 style={{color: 'black', margin: 0}}>Terms of Services</h3>
                            </div>
                            {<Button type="link" style={{marginTop: 2}}><ArrowRightOutlined /></Button>}
                        </div>
                    </Link>
                </div>
                <div style={{ marginTop: 7}}>
                    <Link to="/privacy-policy" target="_blank">
                        <div className="settings-item" style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10}}>
                            <Button size="large" type="link" style={{marginRight: 10, marginLeft: 2, pointerEvents: 'none', padding: 2}} >
                                <FileProtectOutlined style={{fontSize: 24, marginTop: 3}} />
                            </Button>
                            <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                                <h3 style={{color: 'black', margin: 0}}>Privacy Policy</h3>
                            </div>
                            {<Button type="link" style={{marginTop: 2}}><ArrowRightOutlined /></Button>}
                        </div>
                    </Link>
                </div>
                <div style={{height: 50}} />
                
            </div>
        )
    }

    renderMyAccount() {
        return (
            <div style={{}}>
                <h2 style={{}}><UserOutlined /> My Account</h2>

                <Divider orientation="left" style={{width: '100%', marginTop: 25}}>Account info</Divider>
                <div style={{ marginTop: 7}}>
                    <div className="settings-item" style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10}}
                    onClick={() => this.setState({showUpdateFullName: true, newFullNameValue: this.state.fullName})}>
                        <Button size="large" type="link" style={{marginRight: 10, marginLeft: 2, pointerEvents: 'none', padding: 2}} >
                            <ContactsOutlined style={{fontSize: 24, marginTop: 3}} />
                        </Button>
                        <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                            <h3 style={{color: 'black', margin: 0}}>Update name</h3>
                            <h5 style={{color: '#1f1f1f', margin: 0, marginTop: -4, fontWeight: 'normal', flex: 1}}>
                                {this.state.fullName}
                            </h5>
                        </div>
                        {<Button type="link" style={{marginTop: 2}}><EditOutlined /></Button>}
                    </div>
                </div>

                {SessionManager.isLoggedInAsTutor() &&
                <div style={{ marginTop: 7}}>
                    <div className="settings-item" style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10}}
                    onClick={() => this.setState({showUpdatePhoneNumber: true, newPhoneNumberValue: this.state.phoneNumber})}>
                        <Button size="large" type="link" style={{marginRight: 10, marginLeft: 2, pointerEvents: 'none', padding: 2}} >
                            <PhoneOutlined style={{fontSize: 24, marginTop: 3}} />
                        </Button>
                        <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                            <h3 style={{color: 'black', margin: 0}}>Update phone number</h3>
                            <h5 style={{color: '#1f1f1f', margin: 0, marginTop: -4, fontWeight: 'normal', flex: 1}}>
                                {Util.isStringExists(this.state.phoneNumber) ? this.state.phoneNumber : '-'}
                            </h5>
                        </div>
                        {<Button type="link" style={{marginTop: 2}}><EditOutlined /></Button>}
                    </div>
                </div>}
                
                <Divider orientation="left" style={{width: '100%', marginTop: 40}}>Security</Divider>
                <div style={{ marginTop: 7}}>
                    <div className="settings-item" style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10}}
                    onClick={() => this.setState({showUpdatePassword: true, newPasswordValue: "", newConfirmPasswordValue: "", currentPasswordValue: ""})}>
                        <Button size="large" type="link" style={{marginRight: 10, marginLeft: 2, pointerEvents: 'none', padding: 2}} >
                            <LockOutlined style={{fontSize: 24, marginTop: 3}} />
                        </Button>
                        <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                            <h3 style={{color: 'black', margin: 0}}>Change password</h3>
                            <h5 style={{color: '#1f1f1f', margin: 0, marginTop: -4, fontWeight: 'normal', flex: 1}}>
                                Set a new password
                            </h5>
                        </div>
                        {<Button type="link" style={{marginTop: 2}}><EditOutlined /></Button>}
                    </div>
                </div>

                <Modal 
                title="Update name"
                cancelButtonProps={{disabled: this.state.updatingFullName}}
                okButtonProps={{disabled: !Util.isStringExists(this.state.newFullNameValue), loading: this.state.updatingFullName}}
                onOk={this.updateFullName.bind(this)}
                visible={this.state.showUpdateFullName} 
                onCancel={() => this.setState({showUpdateFullName: false})}>
                    <Input size="large" value={this.state.newFullNameValue} onPressEnter={this.updateFullName.bind(this)}
                    onChange={(e) => this.setState({newFullNameValue: e.target.value})}></Input>
                </Modal>

                {SessionManager.isLoggedInAsTutor() &&
                <Modal 
                title="Update phone number"
                cancelButtonProps={{disabled: this.state.updatingPhoneNumber}}
                okButtonProps={{disabled: !Util.isStringExists(this.state.newPhoneNumberValue), loading: this.state.updatingPhoneNumber}}
                onOk={this.updatePhoneNumber.bind(this)}
                visible={this.state.showUpdatePhoneNumber} 
                onCancel={() => this.setState({showUpdatePhoneNumber: false})}>
                    <PhoneInput
                    inputClass="ant-input ant-input-lg"
                    placeholder="Phone Number"
                    containerStyle={{height: 40}}
                    inputStyle={{height: 40, borderRadius: 2, fontSize: '16px', width: '100%'}}
                    onEnterKeyPress={this.updatePhoneNumber.bind(this)}
                    value={this.state.newPhoneNumberValue}
                    onChange={phoneNumberValue => this.setState({ newPhoneNumberValue: phoneNumberValue })}
                    />
                </Modal>}

                <Modal 
                title="Update password"
                cancelButtonProps={{disabled: this.state.updatingPassword}}
                okButtonProps={{disabled: !Util.isStringExists(this.state.currentPasswordValue) || !Util.isStringExists(this.state.newPasswordValue) || !Util.isStringExists(this.state.newConfirmPasswordValue), loading: this.state.updatingPassword}}
                onOk={this.updatePassword.bind(this)}
                visible={this.state.showUpdatePassword} 
                onCancel={() => this.setState({showUpdatePassword: false})}>
                    <h5 style={{margin: 0}}>Current password</h5>
                    <Input type="password" size="large" value={this.state.currentPasswordValue}
                    onPressEnter={this.updatePassword.bind(this)}
                    onChange={(e) => this.setState({currentPasswordValue: e.target.value})}></Input>

                    <h5 style={{margin: 0, marginTop: 15}}>New password</h5>
                    <Input type="password" size="large" value={this.state.newPasswordValue}
                    onPressEnter={this.updatePassword.bind(this)}
                    onChange={(e) => this.setState({newPasswordValue: e.target.value})}></Input>

                    <h5 style={{margin: 0, marginTop: 15}}>Confirm new password</h5>
                    <Input type="password" size="large" value={this.state.newConfirmPasswordValue}
                    onPressEnter={this.updatePassword.bind(this)}
                    onChange={(e) => this.setState({newConfirmPasswordValue: e.target.value})}></Input>
                </Modal>
            </div>
        )
    }

    render() {
        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div style={{width: this.props.renderFullWidth ? '90vw' : '75vw', paddingTop: '25px', paddingBottom: '25px'}}>
                    
                    <div style={{display: 'flex'}}>
                        <div>
                            <h2 style={{marginLeft: 24}}><SettingOutlined /> Settings</h2>
                            <Menu
                            style={{ width: 256, height: '100%' }}
                            selectedKeys={[this.state.selectedMenu]}
                            onSelect={e => this.setState({selectedMenu: e.key})}
                            //defaultOpenKeys={['sub1']}
                            //selectedKeys={[this.state.current]}
                            mode="inline"
                            >
                                <Menu.Item key="my-account" icon={<UserOutlined />}>My Account</Menu.Item>
                                <Menu.Item key="general-resources" icon={<ContainerOutlined />}>General Resources</Menu.Item>
                                
                            </Menu>

                        </div>
                        <div style={{width: 24}} />
                        <div style={{height: 500, flex: 1}}>
                            {this.state.selectedMenu == "my-account" ? this.renderMyAccount() : this.renderGeneralResources()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default withMediaQuery(SettingsPage);