import Api from "../../session/Api";
import { MediaPlayer } from 'dashjs'
import NativeMediaPlayer from "./NativeMediaPlayer";

import HlsMediaPlayer from "./HlsMediaPlayer";

export const MEDIA_PLAYER_TYPE = {
    NATIVE: 'native',
    MPEG_DASH: 'mpeg-dash',
    HLS: 'hls'
}

class StreamMediaPlayer {

    constructor(type, recordingId, streamId) {
        this.type = type;
        this.recordingId = recordingId;
        this.streamId = streamId;

        this.exec({
            mpegDash: () => this.dashMediaPlayer = MediaPlayer().create(),
            native: () => this.nativeMediaPlayer = new NativeMediaPlayer(),
            hls: () => this.hlsMediaPlayer = new HlsMediaPlayer()
        })
    }

    init() {
        this.src = Api.getStreamRecordingSrc(this.recordingId, this.streamId, this.getExt());

        this.exec({
            mpegDash: () => this.dashMediaPlayer.initialize(null, this.src, false),
            native: () => this.nativeMediaPlayer.init(this.src),
            hls: () => this.hlsMediaPlayer.init(this.src)
        })
    }

    play() {
        this.exec({
            mpegDash: () => this.dashMediaPlayer.play(),
            native: () => this.nativeMediaPlayer.play(),
            hls: () => this.hlsMediaPlayer.play()
        })
    }

    pause() {
        this.exec({
            mpegDash: () => this.dashMediaPlayer.pause(),
            native: () => this.nativeMediaPlayer.pause(),
            hls: () => this.hlsMediaPlayer.pause()
        })
    }

    seek(value) {
        this.exec({
            mpegDash: () => this.dashMediaPlayer.seek(value),
            native: () => this.nativeMediaPlayer.setCurrentTime(value),
            hls: () => this.hlsMediaPlayer.setCurrentTime(value)
        })
    }

    onBufferingStarted(eventListener) {
        this.exec({
            mpegDash: () => this.dashMediaPlayer.on('playbackWaiting', eventListener),
            native: () => this.nativeMediaPlayer.registerEvent('waiting', eventListener),
            hls: () => this.hlsMediaPlayer.registerEvent('waiting', eventListener),
        })
    }

    onBufferingStopped(eventListener) {
        this.exec({
            mpegDash: () => this.dashMediaPlayer.on('canPlayThrough', eventListener),
            native: () => this.nativeMediaPlayer.registerEvent('canplaythrough', eventListener),
            hls: () => this.hlsMediaPlayer.registerEvent('waiting', eventListener),
        })
    }

    attachView(mediaView) {
        this.exec({
            mpegDash: () => this.dashMediaPlayer.attachView(mediaView),
            native: () => this.nativeMediaPlayer.attachView(mediaView),
            hls: () => this.hlsMediaPlayer.attachView(mediaView)
        })
    }

    release() {
        this.exec({
            mpegDash: () => this.dashMediaPlayer.destroy(),
            native: () => this.nativeMediaPlayer.cleanup(),
            hls: () => this.hlsMediaPlayer.release()
        })
    }

    getExt() {
        return this.exec({
            mpegDash: () => 'mpd',
            hls: () => 'm3u8',
            native: () => this.mediaFileExt
        })
    }

    /**
     * 
     * @param {{native: function, mpegDash: function, hls: function}} action 
     * @returns {any?}
     */
    exec(action) {
        switch (this.type) {
            case MEDIA_PLAYER_TYPE.NATIVE:
                return action.native();
            case MEDIA_PLAYER_TYPE.MPEG_DASH:
                return action.mpegDash();
            case MEDIA_PLAYER_TYPE.HLS:
                return action.hls();
        }
    }

}

export default StreamMediaPlayer;