import Util from "./Util";

const { message } = require("antd");

class UIUtil {

    showSuccess(successMessage) {
        message.success(successMessage !== undefined ? successMessage : "Success!");
    }

    showError(errorMessage) {
        message.error(Util.isStringExists(errorMessage) ? errorMessage : "An error occurred. Please try again later.");
    }

    showInfo(infoMessage) {
        if (Util.isStringExists(infoMessage)) {
            message.info(infoMessage);
        }
    }

}

export default new UIUtil();