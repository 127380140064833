import React from 'react'
import Page from '../../base/Page';

import {
    Link
} from 'react-router-dom'

import { 
    EditOutlined,
    StarOutlined,
    InstagramFilled,
    FacebookFilled,
    TwitterCircleFilled,
    YoutubeFilled,
    MailFilled,
    GlobalOutlined,
    UserOutlined,
    CameraFilled,
    CloseCircleFilled,
    PlusOutlined,
    MessageOutlined,
    CloseOutlined,
    UploadOutlined,
    PlayCircleFilled,
    VideoCameraOutlined,
    InboxOutlined,
    BookOutlined,
    UnorderedListOutlined,
    AlertOutlined,
    ClusterOutlined,
    EnterOutlined,
    CheckOutlined,
    MailOutlined,
    CopyOutlined,
    CheckCircleFilled,
} from '@ant-design/icons';

import CoverPlaceholder from '../../images/CoverPlaceholder.jpg'
import { Avatar, Button, Modal, Rate, Input, Upload, Radio, Tabs, Row, Col, Empty, Alert, Popconfirm, Select, Tooltip } from 'antd';
import PostsView from '../../views/posts/PostsView';
import Api from '../../session/Api';

import './tutor-profile-page.less';
import Error404Page from '../Error404Page';
import UploadWrapper from '../../components/UploadWrapper';

import Lightbox from '../../components/media-list-viewer/react-image-lightbox';
import '../../components/media-list-viewer/lightbox-style.css';
import SocketSession from '../../session/SocketSession';
import UIUtil from '../../util/UIUtil';
import SaleCourseItem from '../../views/courses/SaleCourseItem';
import CoursesView, { VIEW_MODE_TUTOR_COURSES } from '../../views/courses/CoursesView';
import Util from '../../util/Util';
import withMediaQuery from '../../components/WithMediaQuery';
import { ACCOUNT_ROLE_ORGANIZATION, ACCOUNT_ROLE_STUDENT, ACCOUNT_ROLE_TUTOR, ENROLLMENT_INVITATION_DIRECTION_ORGANIZATION_TO_USER, ENROLLMENT_INVITATION_DIRECTION_USER_TO_ORGANIZATION, ORGANIZATION_PACKAGE_BLANK, ORGANIZATION_PACKAGE_BRONZE, ORGANIZATION_PACKAGE_GOLD, ORGANIZATION_PACKAGE_PLATINUM, ORGANIZATION_PACKAGE_SILVER, RATING_ITEM_TYPE_TUTOR } from '../../Constants';
import SessionManager from '../../session/SessionManager';
import LiveSessionsView, { VIEW_MODE_TUTOR_LIVE_SESSIONS } from '../../views/live-sessions/LiveSessionsView';
import PresenceIndicator from '../../components/PresenceIndicator';
import FrontendAuthModal from '../../components/FrontendAuthModal';
import UserList from '../../components/UserList';

import {CopyToClipboard} from 'react-copy-to-clipboard';
import MediaQuery from 'react-responsive';

import LiveIcon from '../../images/LiveIcon.png';
import FeaturedManageControl from '../content-management-pages/FeaturedManageControl';
import { FEATURED_ITEM_TYPE_ORGANIZATIONS, FEATURED_ITEM_TYPE_TUTORS } from '../content-management-pages/FeaturedSelectorPage';

const PROFILE_CONTENT_POSTS = "posts";
export const PROFILE_CONTENT_COURSES = "courses";
export const PROFILE_CONTENT_LIVE_SESSIONS = "live-sessions";
//const PROFILE_CONTENT_TUTORS = "tutors";
//const PROFILE_CONTENT_ORGANIZATIONS = "organizations";
const PROFILE_CONTENT_TUTORS_ORGANIZATIONS = "tutors-organizations";

const SocialMediaLink = ({style, href, icon, title, value}) => {
    const link = <a className="orange-link" style={style} href={href} target="_blank">{icon} {value.length > 20 ? title : value}</a>;

    if (value.length > 20) {
        return (
            <Tooltip title={value} placement="topLeft">
                {link}
            </Tooltip>
        )
    } else {
        return link;
    }
}

class TutorProfilePage extends Page {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            loading: true,
            profile: undefined,
            profileHasVideo: undefined,
            updateProfile: {},
            updateCoverPicFile: undefined,
            updateProfilePicFile: undefined,
            updateProfileVideoFile: undefined,
            updateProfileOpen: false,
            updatingProfile: false,
            coverImageSuffix: '',
            profilePicSuffix: '',
            userDoesNotExist: false,
            followingLoading: false,
            showProfileVideo: false,
            showSendMessageAuthModal: false,
            showJoinLiveStreamAuthModal: false,
            
            showFollowingUsers: false,
            showFollowerUsers: false,
            //currentProfileContent: PROFILE_CONTENT_POSTS

            sendingJoinRequest: false,
            cancellingJoinRequest: false,
            acceptingJoinRequest: false,
            revokingEnrollment: false,

            updatingPackage: false,
            verificationToggleLoading: false,
        }
    }

    onChangeProfileContentTab(tab) {
        this.props.history.push(this.props.location.pathname + (tab === PROFILE_CONTENT_POSTS ? "" : ("?tab=" + tab)));
        //this.setState({currentProfileContent: tab})
    }

    getCurrentProfileContent() {
        return Util.isStringExists(this.state.pathParams.tab) ? this.state.pathParams.tab : PROFILE_CONTENT_POSTS;
    }

    getProfileWebsitePath() {
        if (this.state.profile.websiteLink.startsWith("http")) {
            return this.state.profile.websiteLink;
        } else {
            return "https://" + this.state.profile.websiteLink;
        }
    }

    onPageStart() {
        if (this.state.profile !== undefined && this.state.profile.accountId == this.getTutorId()) {
            return;
        }

        if (!this.state.loading) {
            this.setState({loading: true});
        }

        this.setState({followModalTutorId: undefined, showFollowerUsers: false, showFollowingUsers: false})
        Api.getProfile(this.getTutorId(), (response) => {
            if (response.status === true) {
                this.setState({
                    loading: false,
                    profile: response.payload,
                    userDoesNotExist: false,
                })
            } else {
                this.setState({
                    loading: false,
                    userDoesNotExist: true,
                    profile: undefined
                })
            }
        })
    }

    onUpdateProfileBtn() {
        this.setState({
            updateProfile: Object.assign({}, this.state.profile),
            updateCoverPicFile: undefined,
            updateProfilePicFile: undefined,
            updateProfileVideoFile: undefined,
            updateProfileOpen: true
        })
    }

    onFollowProfileBtn() {
        this.toggleTutorFollowBtn()
    }

    onUnfollowProfileBtn() {
        this.toggleTutorFollowBtn()
    }

    toggleTutorFollowBtn() {
        this.setState({ followingLoading: true })
        Api.toggleTutorFollow(this.getTutorId(), response => {
            if (response.status === true) {
                let profile = this.state.profile;
                profile.followed = response.payload;

                this.setState({
                    profile: profile,
                    followingLoading: false,
                })
            } else {
                this.setState({
                    followingLoading: false,
                })
                UIUtil.showError();
            }
        })
    }

    toggleTutorVerificationBtn() {
        this.setState({ verificationToggleLoading: true });
        Api.toggleAccountVerification(this.getTutorId(), response => {
            if (response.status === true) {
                let profile = this.state.profile;
                profile.accountVerification = response.payload;

                this.setState({
                    profile: profile,
                    verificationToggleLoading: false,
                })
            } else {
                this.setState({
                    verificationToggleLoading: false,
                })
                UIUtil.showError();
            }
        })
    }

    onSendJoinRequestBtn() {
        this.setState({sendingJoinRequest: true})
        Api.sendInvitationForEnrollment(this.getTutorId(), response => {
            if (response.status === true) {
                this.state.profile.enrollmentStatus.enrollmentInvitation = response.payload;
            } else {
                UIUtil.showError(response.message);
            }
            this.setState({sendingJoinRequest: false})
        })
    }

    onCancelJoinRequestBtn() {
        this.setState({cancellingJoinRequest: true})
        Api.cancelEnrollmentInvitation(this.state.profile.enrollmentStatus.enrollmentInvitation.id, response => {
            if (response.status === true) {
                this.state.profile.enrollmentStatus.enrollmentInvitation = null;
            } else {
                UIUtil.showError();
            }
            this.setState({cancellingJoinRequest: false})
        })
    }

    onAcceptJoinRequestBtn() {
        this.setState({acceptingJoinRequest: true})
        Api.acceptEnrollmentInvitation(this.state.profile.enrollmentStatus.enrollmentInvitation.id, response => {
            if (response.status === true) {
                this.state.profile.enrollmentStatus.enrollmentInvitation = null;
                this.state.profile.enrollmentStatus.enrollment = {
                    dateCreated: response.payload.enrollmentDate
                }
            } else {
                UIUtil.showError(response.message);
            }
            this.setState({acceptingJoinRequest: false})
        })
    }

    onRevokeEnrollmentBtn() {
        this.setState({revokingEnrollment: true})
        Api.revokeEnrollment(this.getTutorId(), response => {
            if (response.status === true) {
                this.state.profile.enrollmentStatus.enrollmentInvitation = null;
                this.state.profile.enrollmentStatus.enrollment = null;
            } else {
                UIUtil.showError();
            }
            this.setState({revokingEnrollment: false})
        })
    }

    updateAccountAccessPackage(updatePackage) {
        const prevPackage = this.state.profile.accountAccess.organizationPackage;
        if (prevPackage == updatePackage) {
            return;
        }

        this.state.profile.accountAccess.organizationPackage = updatePackage;
        this.setState({updatingPackage: true});
        Api.updateAccountAccessPackage(this.state.profile.accountAccess.accountId, updatePackage, response => {
            if (response.status === true) {
                UIUtil.showSuccess("Package updated successfully!")
            } else {
                UIUtil.showError();
                this.state.profile.accountAccess.organizationPackage = prevPackage;
            }
            this.setState({updatingPackage: false})
        })
    }

    setUpdateProfileProperty(property, value) {
        let updateProfile = this.state.updateProfile;
        updateProfile[property] = value;
        this.setState({
            updateProfile: updateProfile
        });
    }

    updateProfile() {
        this.setState({
            updatingProfile: true
        })

        Api.updateProfile(this.state.updateProfile, this.state.updateCoverPicFile, this.state.updateProfilePicFile, this.state.updateProfileVideoFile, response => {
            if (response.status === true) {
                const newProfileHasVideo = this.state.updateProfileVideoFile !== undefined ? true : (this.state.updateProfile.clearProfileVideo ? false : this.state.profileHasVideo);
                this.state.updateProfile.clearProfileVideo = false;

                this.setState({
                    updatingProfile: false,
                    profile: this.state.updateProfile,
                    updateProfileOpen: false,
                    coverImageSuffix: '&t=' + new Date().getTime(),
                    profilePicSuffix: '&t=' + new Date().getTime(),
                    profileHasVideo: newProfileHasVideo,
                    updateProfileVideoFile: undefined,  
                })

                if (this.props.onProfilePicUpdate !== undefined) {
                    this.props.onProfilePicUpdate();
                }
            } else {
                this.setState({
                    updatingProfile: false  
                })
                UIUtil.showError();
            }
        });
    }

    onCloseUpdateProfileDialog() {
        this.setState({
            updateProfileOpen: false
        })
    }

    isProfileNonExistent() {
        return ((this.props.myProfile === undefined || this.props.myProfile === false) && this.getTutorId() === undefined) || this.state.userDoesNotExist;
    }

    getProfileVideoComponent() {
        return <video autoPlay controls src={(!this.props.myProfile ? Api.getProfileVideo(this.getTutorId()) : Api.getMyProfileVideo())} style={{maxHeight: '95%', maxWidth: '95%', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)',}} />
    }

    getTutorId() {
        //return this.getTutorId();
        //return this.state.pathParams.tutorId;
        return this.props.match.params.tutorId;
    }

    renderProfileContent() {
        //switch (this.state.currentProfileContent) {
        //console.log(this.getCurrentProfileContent())
        switch (this.getCurrentProfileContent()) {
            case PROFILE_CONTENT_POSTS:
                return <PostsView tutorId={this.getTutorId()} canAdd={this.props.myProfile} hideProfilePic={true} />
            case PROFILE_CONTENT_COURSES:
                return <CoursesView tutorId={this.getTutorId()} mode={VIEW_MODE_TUTOR_COURSES} count={this.props.isSmall ? 1 : undefined} />
            case PROFILE_CONTENT_LIVE_SESSIONS:
                return <LiveSessionsView tutorId={this.getTutorId()} mode={VIEW_MODE_TUTOR_LIVE_SESSIONS} count={this.props.isSmall ? 1 : undefined} />
            /*case PROFILE_CONTENT_TUTORS:
                return <UserList showOrganizationTutors={this.getTutorId()} emptyMessage={<Empty style={{marginTop: '50px'}} image={Empty.PRESENTED_IMAGE_SIMPLE} description={"No tutors"} />} />
            case PROFILE_CONTENT_ORGANIZATIONS:
                return <UserList horizontalMode showTutorOrganizations={this.getTutorId()} emptyMessage={<Empty style={{marginTop: '50px'}} image={Empty.PRESENTED_IMAGE_SIMPLE} description={"No organizations"} />} />*/
            case PROFILE_CONTENT_TUTORS_ORGANIZATIONS:
                const role = Util.safeGetProperty(this.state, 'profile', {}).role;
                if (role == ACCOUNT_ROLE_TUTOR) {
                    return <UserList horizontalMode showTutorOrganizations={this.getTutorId()} emptyMessage={<Empty style={{marginTop: '50px'}} image={Empty.PRESENTED_IMAGE_SIMPLE} description={"No organizations"} />} miniMode={this.props.isSmall} />
                } else if (role == ACCOUNT_ROLE_ORGANIZATION) {
                    return <UserList showOrganizationTutors={this.getTutorId()} emptyMessage={<Empty style={{marginTop: '50px'}} image={Empty.PRESENTED_IMAGE_SIMPLE} description={"No tutors"} />} />
                }
            default:
                return <> </>
        }
    }

    render() {
        return (
            <div key={this.getTutorId() !== undefined ? this.getTutorId() : 'my-profile'} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                
                <img src={(!this.props.myProfile ? Api.getCoverImage(this.getTutorId()) : Api.getMyCoverImage()) + this.state.coverImageSuffix} onError={e => {
                    e.target.onerror = null;
                    e.target.src = CoverPlaceholder;
                }} style={{height: '350px', width: '100%', objectFit: 'cover', position: 'absolute', zIndex: '-1'}} />
                

                {this.state.profileHasVideo ? (
                    <div style={{height: '350px', width: '100%', position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'}} onClick={e => this.setState({showProfileVideo: true})}>
                        <PlayCircleFilled style={{fontSize: '60px', color: 'white', }} />
                    </div>
                ) : null}

                <video style={{display: 'none'}} playsInline src={(!this.props.myProfile ? Api.getProfileVideo(this.getTutorId()) : Api.getMyProfileVideo())} 
                    onLoadedMetadata={() => this.setState({profileHasVideo: true})} onError={e => {e.target.onerror = null; this.setState({profileHasVideo: false})}} />

                <div style={{width: this.props.renderFullWidth ? '90vw' : '75vw', marginTop: '250px', display: 'flex', flexDirection: this.props.isSmall ? "column" : undefined, alignItems: this.props.isSmall ? 'center' : undefined}}>
                    <div style={{width: this.props.isSmall ? '90vw' : '200px'}}>
                        <div style={{display: 'flex', width: '100%', justifyContent: 'center'}}>
                        <Avatar size={200} style={{border: 'solid'}} icon={<UserOutlined />} src={(!this.props.myProfile ? Api.getProfilePic(this.getTutorId()) : Api.getMyProfilePic()) + this.state.profilePicSuffix} />
                        </div>
                        
                        {(!this.state.loading && this.state.profile !== undefined) ? (
                            <>
                                <h1 style={{marginTop: '10px', textAlign: this.props.isSmall ? "center" : undefined, display: 'flex', alignItems: 'center'}}>
                                    {this.state.profile.fullName} {this.state.profile.accountVerification !== undefined && this.state.profile.accountVerification !== null &&
                                    <Tooltip title="Verified">
                                        <CheckCircleFilled style={{color: 'rgb(0,152,235)', fontSize: '20px', marginLeft: '10px'}} />
                                    </Tooltip>}
                                </h1>
                                {!this.props.myProfile && this.state.profile.role == ACCOUNT_ROLE_TUTOR && <PresenceIndicator style={{marginTop: '-10px', marginBottom: '15px'}} textMode userId={this.getTutorId()} />}
                                {this.state.profile.role == ACCOUNT_ROLE_STUDENT && <p style={{margin: 0, marginTop: -20, marginBottom: 15}}>Learner</p>}


                                {!this.props.myProfile && Util.isStringExists(this.state.profile.accountLiveStreamId) &&
                                <div style={{width: '100%', marginTop: '15px', marginBottom: 25, backgroundColor: '#2d3436', backgroundImage: 'linear-gradient(315deg, #2d3436 0%, #000000 74%)',
                                borderRadius: '5px', padding: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}} 
                                onClick={() => {
                                    if (SessionManager.isLoggedIn()) {
                                        this.props.history.push(Util.getLiveStreamPath(this.state.profile.accountLiveStreamId));
                                    } else {
                                        this.setState({showJoinLiveStreamAuthModal: true})
                                    }
                                }}>
                                    <h5 style={{color: 'white', marginBottom: 7}}><AlertOutlined /> Live stream in progress</h5>
                                    <img src={LiveIcon} style={{width: '25px'}} />
                                </div>}

                                <div style={{width: '100%', display: 'flex', marginBottom: '15px'}}>
                                    <div style={{flex: '1', textAlign: 'center', cursor: 'pointer'}} onClick={() => this.setState({showFollowingUsers: true, followModalTutorId: (this.getTutorId() !== undefined ? this.getTutorId() : SessionManager.getAccount().id)})}>
                                        <h3 style={{height: '20px'}}>{this.state.profile.following}</h3>
                                        <h5 style={{color: '#8a8a8a'}}>Following</h5>
                                    </div>
                                    <div style={{flex: '1', textAlign: 'center', cursor: 'pointer'}} onClick={() => this.setState({showFollowerUsers: true, followModalTutorId: (this.getTutorId() !== undefined ? this.getTutorId() : SessionManager.getAccount().id)})}>
                                        <h3 style={{height: '20px'}}>{this.state.profile.followers}</h3>
                                        <h5 style={{color: '#8a8a8a'}}>Followers</h5>
                                    </div>
                                </div>

                                <p style={{marginTop: '10px', overflowWrap: 'break-word', whiteSpace: 'pre-line'}}>{this.state.profile.bio}</p>


                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                    {this.state.profile.displayEmailAddress ? 
                                        <SocialMediaLink style={{marginBottom: '20px'}} href={'mailto:' + this.state.profile.displayEmailAddress} icon={<MailFilled />} title="Email" value={this.state.profile.displayEmailAddress} />: null}
                                    {this.state.profile.instagramUsername ? 
                                        <SocialMediaLink href={'https://instagram.com/' + this.state.profile.instagramUsername} target="_blank" icon={<InstagramFilled />} title="Instagram" value={this.state.profile.instagramUsername} /> : null}
                                    {this.state.profile.twitterUsername ? 
                                        <SocialMediaLink href={'https://twitter.com/' + this.state.profile.twitterUsername} target="_blank" icon={<TwitterCircleFilled />} title="Twitter" value={this.state.profile.twitterUsername} /> : null}
                                    {this.state.profile.facebookUsername ? 
                                        <SocialMediaLink href={'https://facebook.com/' + this.state.profile.facebookUsername} target="_blank" icon={<FacebookFilled />} title="Facebook" value={this.state.profile.facebookUsername} /> : null}
                                    {this.state.profile.youtubeUsername ? 
                                        <SocialMediaLink href={this.state.profile.youtubeUsername} target="_blank" icon={<YoutubeFilled />} title="YouTube" value={this.state.profile.youtubeUsername} /> : null}
                                    {this.state.profile.websiteLink ? 
                                        <SocialMediaLink style={{marginTop: '20px'}} href={this.getProfileWebsitePath()} target="_blank" icon={<GlobalOutlined />} title="Website" value={this.state.profile.websiteLink} /> : null}
                                </div>
                            
                                {this.props.myProfile ?
                                    <Button type="primary" onClick={this.onUpdateProfileBtn.bind(this)} style={{width: '100%', marginTop: '20px'}} size="large" loading={this.state.profileHasVideo === undefined}><EditOutlined /> Edit Profile</Button>
                                :  
                                <>
                                    {SessionManager.isLoggedIn() ? (
                                        <Button style={{width: '100%', marginTop: '20px'}} size="large">
                                            <Link to={'/messages?userId=' + this.getTutorId()}><MessageOutlined /> Send Message</Link>
                                        </Button>
                                    ) : (
                                        <Button style={{width: '100%', marginTop: '20px'}} size="large" onClick={() => {
                                            if (SessionManager.isLoggedIn()) {
                                                this.props.history.push('/messages?userId=' + this.getTutorId());
                                            } else {
                                                this.setState({showSendMessageAuthModal: true})
                                            }
                                        }}>
                                            <MessageOutlined /> Send Message
                                        </Button>    
                                    )}
                                    
                                    {!SessionManager.isLoggedInAsRelationManager() && !SessionManager.isLoggedInAsPaymentManager() && <>
                                        {this.state.profile.followed ? (
                                            <Button type="primary" className="red-button" loading={this.state.followingLoading} onClick={this.onUnfollowProfileBtn.bind(this)} style={{width: '100%', marginTop: '10px'}} size="large"><CloseOutlined /> Unfollow</Button>
                                        ) : (
                                            <Button type="primary" loading={this.state.followingLoading} onClick={() => {
                                                if (SessionManager.isLoggedIn()) {
                                                    this.onFollowProfileBtn();
                                                } else {
                                                    this.props.history.push("/auth?dP=" + encodeURIComponent("/profile/" + this.getTutorId()))
                                                }
                                            }} style={{width: '100%', marginTop: '10px'}} size="large"><PlusOutlined /> Follow</Button>
                                        )}
                                    </>}
                                    
                                </>}

                                {SessionManager.isLoggedInAsRelationManager() && Util.isStringExists(this.state.profile.phoneNumber) && <>
                                    <div style={{width: '75%', height: '1px', background: '#cecece', marginTop: '40px', marginBottom: '30px'}} />
                                    <h2 style={{marginTop: '10px'}}>Phone Number</h2>
                                    <p>{this.state.profile.phoneNumber}</p>
                                </>}

                                {this.state.profile.role === ACCOUNT_ROLE_ORGANIZATION && SessionManager.isLoggedInAsStudentOrTutor() && <>
                                    <div style={{width: '75%', height: '1px', background: '#cecece', marginTop: '40px', marginBottom: '30px'}} />

                                    <h2 style={{marginTop: '10px'}}>Organization</h2>

                                    {this.state.profile.enrollmentStatus.enrollment === null && 
                                    this.state.profile.enrollmentStatus.enrollmentInvitation === null &&
                                    <Button type="primary" style={{width: '100%'}} loading={this.state.sendingJoinRequest} onClick={() => this.onSendJoinRequestBtn()}><EnterOutlined /> Request to Join</Button>}

                                    {this.state.profile.enrollmentStatus.enrollment === null && 
                                    this.state.profile.enrollmentStatus.enrollmentInvitation !== null &&
                                    this.state.profile.enrollmentStatus.enrollmentInvitation.direction == ENROLLMENT_INVITATION_DIRECTION_USER_TO_ORGANIZATION && <>
                                    <p>Join request sent on {Util.getDate(this.state.profile.enrollmentStatus.enrollmentInvitation.dateCreated)}</p>
                                    <Popconfirm title="Are you sure?" loading={this.state.cancellingJoinRequest} onConfirm={() => this.onCancelJoinRequestBtn()}>
                                        <Button type="primary" style={{width: '100%'}} danger loading={this.state.cancellingJoinRequest}><CloseOutlined /> Cancel Join Request</Button>
                                    </Popconfirm> </>}

                                    {this.state.profile.enrollmentStatus.enrollment === null && 
                                    this.state.profile.enrollmentStatus.enrollmentInvitation !== null &&
                                    this.state.profile.enrollmentStatus.enrollmentInvitation.direction == ENROLLMENT_INVITATION_DIRECTION_ORGANIZATION_TO_USER && <>
                                    <p>Invitation to join received on {Util.getDate(this.state.profile.enrollmentStatus.enrollmentInvitation.dateCreated)}</p>
                                    <div style={{display: 'flex'}}>
                                        <Popconfirm title="Are you sure?" loading={this.state.cancellingJoinRequest} onConfirm={() => this.onCancelJoinRequestBtn()}>
                                            <Button type="linl" style={{width: '100%'}} danger loading={this.state.cancellingJoinRequest}><CloseOutlined /> Reject</Button> 
                                        </Popconfirm>
                                        <div style={{width: '15px'}} />
                                        <Button type="primary" style={{width: '100%'}} loading={this.state.acceptingJoinRequest} onClick={() => this.onAcceptJoinRequestBtn()}><CheckOutlined /> Accept</Button> 
                                    </div>
                                    </>}

                                    {this.state.profile.enrollmentStatus.enrollment !== null && 
                                    this.state.profile.enrollmentStatus.enrollmentInvitation === null && <>
                                    <Alert message={'Enrolled on ' + Util.getDate(this.state.profile.enrollmentStatus.enrollment.dateCreated)} type="success" showIcon />
                                    <Link to={"/organization/" + this.getTutorId()}>
                                        <Button type="primary" style={{width: '100%', marginTop: '15px'}}><ClusterOutlined /> View Organization</Button>
                                    </Link> </>}
                                </>}

                                {this.state.profile.role === ACCOUNT_ROLE_TUTOR && SessionManager.isLoggedInAsOrganization() && <>
                                    <div style={{width: '75%', height: '1px', background: '#cecece', marginTop: '40px', marginBottom: '30px'}} />

                                    <h2 style={{marginTop: '10px'}}>Organization</h2>

                                    {this.state.profile.enrollmentStatus.enrollment === null && 
                                    this.state.profile.enrollmentStatus.enrollmentInvitation === null &&
                                    <Button type="primary" style={{width: '100%'}} loading={this.state.sendingJoinRequest} onClick={() => this.onSendJoinRequestBtn()}><MailOutlined /> Invite</Button>}

                                    {this.state.profile.enrollmentStatus.enrollment === null && 
                                    this.state.profile.enrollmentStatus.enrollmentInvitation !== null &&
                                    this.state.profile.enrollmentStatus.enrollmentInvitation.direction == ENROLLMENT_INVITATION_DIRECTION_ORGANIZATION_TO_USER && <>
                                    <p>Invitation sent on {Util.getDate(this.state.profile.enrollmentStatus.enrollmentInvitation.dateCreated)}</p>
                                    <Popconfirm title="Are you sure?" loading={this.state.cancellingJoinRequest} onConfirm={() => this.onCancelJoinRequestBtn()}>
                                        <Button type="primary" style={{width: '100%'}} danger loading={this.state.cancellingJoinRequest}><CloseOutlined /> Cancel Invitation</Button>
                                    </Popconfirm> </>}

                                    {this.state.profile.enrollmentStatus.enrollment === null && 
                                    this.state.profile.enrollmentStatus.enrollmentInvitation !== null &&
                                    this.state.profile.enrollmentStatus.enrollmentInvitation.direction == ENROLLMENT_INVITATION_DIRECTION_USER_TO_ORGANIZATION && <>
                                    <p>Join request received on {Util.getDate(this.state.profile.enrollmentStatus.enrollmentInvitation.dateCreated)}</p>
                                    <div style={{display: 'flex'}}>
                                        <Popconfirm title="Are you sure?" loading={this.state.cancellingJoinRequest} onConfirm={() => this.onCancelJoinRequestBtn()}>
                                            <Button type="linl" style={{width: '100%'}} danger loading={this.state.cancellingJoinRequest}><CloseOutlined /> Reject</Button> 
                                        </Popconfirm>
                                        <div style={{width: '15px'}} />
                                        <Button type="primary" style={{width: '100%'}} loading={this.state.acceptingJoinRequest} onClick={() => this.onAcceptJoinRequestBtn()}><CheckOutlined /> Accept</Button> 
                                    </div>
                                    </>}

                                    {this.state.profile.enrollmentStatus.enrollment !== null && 
                                    this.state.profile.enrollmentStatus.enrollmentInvitation === null && <>
                                    <Alert message={'Enrolled on ' + Util.getDate(this.state.profile.enrollmentStatus.enrollment.dateCreated)} type="success" showIcon />
                                    <Popconfirm title="Are you sure?" loading={this.state.onRevokeEnrollmentBtn} onConfirm={() => this.onRevokeEnrollmentBtn()}>
                                        <Button type="primary" style={{width: '100%', marginTop: '15px'}} danger loading={this.state.onRevokeEnrollmentBtn}><CloseOutlined /> Revoke Enrollment</Button>
                                    </Popconfirm> </>}
                                </>}

                                {this.state.profile.role === ACCOUNT_ROLE_ORGANIZATION && SessionManager.isLoggedInAsRelationManager() && <>
                                    <div style={{width: '75%', height: '1px', background: '#cecece', marginTop: '40px', marginBottom: '30px'}} />
                                    <h2 style={{marginTop: '10px'}}>Relation Manager</h2>

                                    <h3>Account Package</h3>
                                    <Select disabled={this.state.updatingPackage} loading={this.state.updatingPackage} style={{marginBottom: '20px', width: '100%'}} value={this.state.profile.accountAccess.organizationPackage} onChange={value => this.updateAccountAccessPackage(value)}>
                                        <Select.Option value={ORGANIZATION_PACKAGE_BLANK}>
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                <div style={{width: '15px', height: '15px', background: 'black', marginRight: '5px', borderRadius: '50%'}} /> 
                                                <p style={{height: this.state.profile.accountAccess.organizationPackage == ORGANIZATION_PACKAGE_BLANK ? '15px' : '7px'}}>No package</p>
                                            </div>
                                        </Select.Option>
                                        <Select.Option value={ORGANIZATION_PACKAGE_BRONZE}>
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                <div style={{width: '15px', height: '15px', background: '#cd7f32', marginRight: '5px', borderRadius: '50%'}} /> 
                                                <p style={{height: this.state.profile.accountAccess.organizationPackage == ORGANIZATION_PACKAGE_BRONZE ? '15px' : '7px'}}>Bronze</p>
                                            </div>
                                        </Select.Option>
                                        <Select.Option value={ORGANIZATION_PACKAGE_SILVER}>
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                <div style={{width: '15px', height: '15px', background: 'gray', marginRight: '5px', borderRadius: '50%'}} /> 
                                                <p style={{height: this.state.profile.accountAccess.organizationPackage == ORGANIZATION_PACKAGE_SILVER ? '15px' : '7px'}}>Silver</p>
                                            </div>
                                        </Select.Option>
                                        <Select.Option value={ORGANIZATION_PACKAGE_GOLD}>
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                <div style={{width: '15px', height: '15px', background: 'goldenrod', marginRight: '5px', borderRadius: '50%'}} /> 
                                                <p style={{height: this.state.profile.accountAccess.organizationPackage == ORGANIZATION_PACKAGE_GOLD ? '15px' : '7px'}}>Gold</p>
                                            </div>
                                        </Select.Option>
                                        <Select.Option value={ORGANIZATION_PACKAGE_PLATINUM}>
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                <div style={{width: '15px', height: '15px', background: /*'#E5E4E2'*/ '#2f54eb', marginRight: '5px', borderRadius: '50%'}} /> 
                                                <p style={{height: this.state.profile.accountAccess.organizationPackage == ORGANIZATION_PACKAGE_PLATINUM ? '15px' : '7px'}}>Platinum</p>
                                            </div>
                                        </Select.Option>
                                    </Select>

                                    <h3 style={{}}>Serial Number
                                        <CopyToClipboard text={this.state.profile.accountAccess.serialNo} onCopy={() => UIUtil.showSuccess("Copied to clipboard!")}>
                                            <Tooltip title="Copy"><Button type="link"><CopyOutlined /></Button></Tooltip>
                                        </CopyToClipboard>
                                        </h3>
                                    <p>{this.state.profile.accountAccess.serialNo}</p>
                                    
                                    <h3>Account Status</h3>
                                    {this.state.profile.accountAccess.activated ? 
                                    <p style={{color: 'green'}}>Activated <CheckCircleFilled /></p>
                                        : 
                                    <>
                                        {this.state.profile.accountAccess.daysLeftTillOrganization < 0 ? (<>
                                            <p style={{color: 'red'}}>Trial expired <CloseCircleFilled /></p>
                                        </>) : (<>
                                            <p>Trial mode ({Util.getDaysLeft(this.state.profile.accountAccess.daysLeftTillOrganization)})</p>
                                        </>)}
                                    </>}

                                </>}

                                {this.state.profile.role === ACCOUNT_ROLE_TUTOR && SessionManager.isLoggedInAsRelationManager() && <>
                                    <div style={{width: '75%', height: '1px', background: '#cecece', marginTop: '40px', marginBottom: '30px'}} />
                                    <h2 style={{marginTop: '10px'}}>Relation Manager</h2>

                                    <h3>Verification Status</h3>
                                    {this.state.profile.accountVerification !== undefined && this.state.profile.accountVerification !== null ? (<>
                                        <p style={{color: 'green'}}>Verified on {Util.getDate(this.state.profile.accountVerification.dateCreated)} <CheckCircleFilled /></p>
                                        <Popconfirm title="Are you sure?" onConfirm={() => this.toggleTutorVerificationBtn()} loading={this.state.verificationToggleLoading}>
                                            <Button danger loading={this.state.verificationToggleLoading} type="primary" style={{width: '100%'}}><CloseOutlined /> Revoke Verification</Button>
                                        </Popconfirm>
                                    </>) : (<>
                                        <p>Not verified</p>
                                        <Button onClick={() => this.toggleTutorVerificationBtn()} loading={this.state.verificationToggleLoading} type="primary" style={{width: '100%'}}><CheckOutlined /> Verify Tutor</Button>
                                    </>)}

                                </>}

                                {(this.state.profile.role === ACCOUNT_ROLE_TUTOR || this.state.profile.role === ACCOUNT_ROLE_ORGANIZATION) && SessionManager.isLoggedInAsContentManager() && <>
                                    <div style={{width: '75%', height: '1px', background: '#cecece', marginTop: '40px', marginBottom: '30px'}} />
                                    
                                    <FeaturedManageControl itemId={this.state.profile.accountId} item={this.state.profile} featuredContentType={this.state.profile.role === ACCOUNT_ROLE_ORGANIZATION ? FEATURED_ITEM_TYPE_ORGANIZATIONS : FEATURED_ITEM_TYPE_TUTORS} />
                                </>}



                                {Util.safeGetProperty(this.state, 'profile', {}).role != ACCOUNT_ROLE_STUDENT && <>
                                    <div style={{width: '75%', height: '1px', background: '#cecece', marginTop: '40px', marginBottom: '30px'}} />
                                    <h2 style={{marginTop: '10px'}}>Rating</h2>
                                    <Rate disabled value={this.state.profile.tutorRating} />

                                    <Button type="primary" className="orange-button" style={{width: '100%', marginTop: '25px', marginBottom: '25px'}}>
                                        <Link to={"/reviews/" + RATING_ITEM_TYPE_TUTOR + "/" + (this.props.myProfile ? SessionManager.getAccount().id : this.getTutorId()) + "?name=" + encodeURIComponent(this.state.profile.fullName)}><StarOutlined /> View Reviews</Link>
                                    </Button>
                                </>}
                            </>
                        ) : (this.isProfileNonExistent() ? 
                            <h2 style={{marginTop: '10px'}}>User does not exist</h2>
                        : null)}
                    </div>
                    {!this.props.isSmall && <div style={{width: '40px'}} />}
                    {!this.isProfileNonExistent() ? (
                        (Util.safeGetProperty(this.state, 'profile', {}).role == ACCOUNT_ROLE_STUDENT || this.props.myProfile) ? (
                            <div style={{padding: !this.props.isSmall ? '25px 0 25px 25px' : null, flex: '1', minWidth: '0', marginTop: this.props.isSmall ? '15px' : '100px'}}>
                                <PostsView tutorId={this.getTutorId()} canAdd={this.props.myProfile} hideProfilePic={true} />
                            </div>
                        ) : (
                            <div style={{padding: !this.props.isSmall ? '25px 0 25px 25px' : null, flex: '1', minWidth: '0', marginTop: this.props.isSmall ? '15px' : '75px', width: this.props.isSmall && '90vw'}}>
                                <MediaQuery maxWidth={619}>
                                    <Select value={this.getCurrentProfileContent()} onChange={value => this.onChangeProfileContentTab(value)} 
                                    style={{ width: '100%', marginBottom: '15px' }} size="large">
                                        <Select.Option value={PROFILE_CONTENT_POSTS}><UnorderedListOutlined /> &nbsp;&nbsp;Posts</Select.Option>
                                        <Select.Option value={PROFILE_CONTENT_COURSES}><BookOutlined /> &nbsp;&nbsp;Courses</Select.Option>
                                        <Select.Option value={PROFILE_CONTENT_LIVE_SESSIONS}><AlertOutlined /> &nbsp;&nbsp;Live Sessions</Select.Option>

                                        {Util.safeGetProperty(this.state, 'profile', {}).role === ACCOUNT_ROLE_ORGANIZATION &&
                                        <Select.Option value={PROFILE_CONTENT_TUTORS_ORGANIZATIONS}><UserOutlined /> &nbsp;&nbsp;Tutors</Select.Option>}
                                        {Util.safeGetProperty(this.state, 'profile', {}).role === ACCOUNT_ROLE_TUTOR &&
                                        <Select.Option value={PROFILE_CONTENT_TUTORS_ORGANIZATIONS}><ClusterOutlined /> &nbsp;&nbsp;Organizations</Select.Option>}
                                    </Select>
                                </MediaQuery>
                                <MediaQuery minWidth={620}>
                                    <Tabs key={this.getTutorId()} activeKey={this.getCurrentProfileContent()} size="large" onChange={key => this.onChangeProfileContentTab(key)} className="profile-tabs">
                                        <Tabs.TabPane
                                            tab={
                                            <span>
                                                <UnorderedListOutlined />
                                                Posts
                                            </span>
                                            }
                                            key={PROFILE_CONTENT_POSTS}
                                        />
                                        <Tabs.TabPane
                                            tab={
                                            <span>
                                                <BookOutlined />
                                                Courses
                                            </span>
                                            }
                                            key={PROFILE_CONTENT_COURSES}
                                        />
                                        <Tabs.TabPane
                                            tab={
                                            <span>
                                                <AlertOutlined />
                                                Live Sessions
                                            </span>
                                            }
                                            key={PROFILE_CONTENT_LIVE_SESSIONS}
                                        />
                                        {Util.safeGetProperty(this.state, 'profile', {}).role === ACCOUNT_ROLE_ORGANIZATION &&
                                        <Tabs.TabPane
                                            tab={
                                            <span>
                                                <UserOutlined />
                                                Tutors
                                            </span>
                                            }
                                            key={PROFILE_CONTENT_TUTORS_ORGANIZATIONS}
                                        />}
                                        {Util.safeGetProperty(this.state, 'profile', {}).role === ACCOUNT_ROLE_TUTOR &&
                                        <Tabs.TabPane
                                            tab={
                                            <span>
                                                <ClusterOutlined />
                                                Organizations
                                            </span>
                                            }
                                            key={PROFILE_CONTENT_TUTORS_ORGANIZATIONS}
                                        />}
                                    </Tabs>
                                </MediaQuery>
                                {this.renderProfileContent()}
                            </div>
                        )
                    ) : null}
                </div>

                {this.props.myProfile ?
                    <Modal
                        title="Update Profile"
                        okText="Save"
                        bodyStyle={{padding: '0px'}}
                        centered={true}
                        visible={this.state.updateProfileOpen}
                        confirmLoading={this.state.updatingProfile}
                        onCancel={this.onCloseUpdateProfileDialog.bind(this)}
                        onOk={this.updateProfile.bind(this)}>
                            <div style={{height: '175px', position: 'absolute', width: '100%'}}>
                                <label for="cover-file-input">
                                    {/*<img src={CoverPlaceholder} style={{height: '175px', width: '100%', objectFit: 'cover', position: 'absolute', zIndex: '0'}} />*/}

                                    <img src={this.state.updateCoverPicFile !== undefined ? URL.createObjectURL(this.state.updateCoverPicFile) : Api.getMyCoverImage()} onError={e => {
                                        e.target.onerror = null;
                                        e.target.src = CoverPlaceholder;
                                    }} style={{height: '175px', width: '100%', objectFit: 'cover',
                                        position: 'absolute', zIndex: '0'}} />

                                    <div className="upload-cover-overlay">
                                        {this.state.updateCoverPicFile === undefined ? (
                                            <CameraFilled style={{color: 'white', fontSize: '20px'}} />
                                        ) : (
                                            <CloseCircleFilled style={{color: 'white', fontSize: '20px'}} />
                                        )}
                                        
                                    </div>
                                </label>

                                <input accept="image/gif,image/jpeg,image/png" id="cover-file-input" type="file" 
                                    style={{display: 'none'}} onClick={e => {
                                        if (this.state.updateCoverPicFile !== undefined) {
                                            e.preventDefault();
                                            this.setState({
                                                updateCoverPicFile: undefined
                                            })
                                        }
                                    }} onChange={e => {this.setState({updateCoverPicFile: e.target.files[0]}); e.target.value = ""}} />
                            </div>

                            <div style={{width: '100%', paddingTop: '112.5px', display: 'flex'}}>
                                <div style={{width: '100%', paddingLeft: '25px', paddingRight: '25px', paddingBottom: '25px'}}>

                                    <div style={{height: '125px'}}>
                                        <label for="avatar-file-input">
                                            <Avatar icon={<UserOutlined />} size={125} src={this.state.updateProfilePicFile !== undefined ? URL.createObjectURL(this.state.updateProfilePicFile) : Api.getMyProfilePic()} icon={<UserOutlined />} style={{border: 'solid', position: 'absolute'}} />
                                            <div className="upload-profile-overlay">
                                                {this.state.updateProfilePicFile === undefined ? (
                                                    <CameraFilled style={{color: 'white', fontSize: '20px'}} />
                                                ) : (
                                                    <CloseCircleFilled style={{color: 'white', fontSize: '20px'}} />
                                                )}
                                            </div>
                                        </label>

                                        <input accept="image/gif,image/jpeg,image/png" id="avatar-file-input" type="file" 
                                            style={{display: 'none'}} onClick={e => {
                                                if (this.state.updateProfilePicFile !== undefined) {
                                                    e.preventDefault();
                                                    this.setState({
                                                        updateProfilePicFile: undefined
                                                    })
                                                }
                                            }} onChange={e => {this.setState({updateProfilePicFile: e.target.files[0]}); e.target.value = ""}} />
                                    </div>

                                    <div style={{width: '100%', display: 'flex', alignItems: 'center'}}>
                                        <h2 style={{marginTop: '15px'}}>Profile Video</h2>
                                        <div style={{flex: '1'}} />
                                        {/*!this.state.profileHasVideo || this.state.updateProfile.clearProfileVideo ? (
                                            <UploadWrapper id="profile-video-input" accept="video/mp4" onChange={e => this.setState({updateProfileVideoFile: e.target.files[0]})}>
                                                <Button>
                                                    <VideoCameraOutlined /> Select Video
                                                </Button>
                                            </UploadWrapper>
                                        ) : (
                                            <Button loading={this.state.profileHasVideo === undefined} danger onClick={e => this.setUpdateProfileProperty('clearProfileVideo', true)}>
                                                <CloseCircleFilled /> Clear Video
                                            </Button>
                                        )*/}

                                        {(this.state.profileHasVideo && !this.state.updateProfile.clearProfileVideo) || this.state.updateProfileVideoFile !== undefined ? (
                                            <Button loading={this.state.profileHasVideo === undefined} danger onClick={e => {
                                                if (this.state.updateProfileVideoFile !== undefined) {
                                                    this.setState({
                                                        updateProfileVideoFile: undefined
                                                    })
                                                } else {
                                                    this.setUpdateProfileProperty('clearProfileVideo', true);
                                                }
                                            }}>
                                                <CloseCircleFilled /> Clear Video
                                            </Button>
                                        ) : null}
                                    </div>

                                    {(this.state.updateProfileVideoFile !== undefined || (this.state.profileHasVideo && !this.state.updateProfile.clearProfileVideo)) ? (
                                            <video muted loop autoPlay playsInline style={{width: '100%', height: '250px', objectFit: 'cover'}} 
                                            src={this.state.updateProfileVideoFile !== undefined ? URL.createObjectURL(this.state.updateProfileVideoFile) : 
                                                (!this.props.myProfile ? Api.getProfileVideo(this.getTutorId()) : Api.getMyProfileVideo())} />
                                        ) : (
                                            <div style={{height: '175px', maxHeight: '250px'}}>
                                                <Upload.Dragger accept="video/mp4" beforeUpload={file => {
                                                    this.setState({updateProfileVideoFile: file})
                                                    return false;
                                                }}>
                                                    <p className="ant-upload-drag-icon">
                                                        <InboxOutlined />
                                                    </p>
                                                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                                    <p className="ant-upload-hint">
                                                        Upload a profile video to further shine from the crowd!
                                                    </p>
                                                </Upload.Dragger>
                                            </div>
                                        )}
                                    
                                    <h2 style={{marginTop: '15px'}}>Bio</h2>

                                    <Input.TextArea maxLength={1500} disabled={this.state.updatingProfile} addonBefore="Bio" rows={4} onChange={e => this.setUpdateProfileProperty('bio', e.target.value)} 
                                        value={this.state.updateProfile.bio} />

                                    <h2 style={{marginTop: '15px'}}>Social</h2>
                                    <Alert
                                        message="Important"
                                        description={<>
                                            Please only input your username for Instagram, Twitter and Facebook.
                                        </>}
                                        type="info"
                                        showIcon
                                        style={{marginBottom: 10}}
                                    />

                                    <Input disabled={this.state.updatingProfile} addonBefore={<MailFilled />} placeholder="Add email address"  
                                            onChange={e => this.setUpdateProfileProperty('displayEmailAddress', e.target.value)} value={this.state.updateProfile.displayEmailAddress} />
                                    <Input disabled={this.state.updatingProfile} style={{marginTop: '15px'}} addonBefore={<InstagramFilled />} placeholder="Add Instagram username" 
                                            onChange={e => this.setUpdateProfileProperty('instagramUsername', e.target.value)} value={this.state.updateProfile.instagramUsername} />
                                    <Input disabled={this.state.updatingProfile} style={{marginTop: '15px'}} addonBefore={<TwitterCircleFilled />} placeholder="Add Twitter username" 
                                            onChange={e => this.setUpdateProfileProperty('twitterUsername', e.target.value)} value={this.state.updateProfile.twitterUsername} />
                                    <Input disabled={this.state.updatingProfile} style={{marginTop: '15px'}} addonBefore={<FacebookFilled />} placeholder="Add Facebook username"
                                            onChange={e => this.setUpdateProfileProperty('facebookUsername', e.target.value)} value={this.state.updateProfile.facebookUsername} />
                                    <Input disabled={this.state.updatingProfile} style={{marginTop: '15px'}} addonBefore={<YoutubeFilled />} placeholder="Add YouTube link" 
                                            onChange={e => this.setUpdateProfileProperty('youtubeUsername', e.target.value)} value={this.state.updateProfile.youtubeUsername} />
                                    <Input disabled={this.state.updatingProfile} style={{marginTop: '15px'}} addonBefore={<GlobalOutlined />} placeholder="Add website" 
                                            onChange={e => this.setUpdateProfileProperty('websiteLink', e.target.value)} value={this.state.updateProfile.websiteLink} />
                                </div>
                            </div>
                    </Modal>
                : null}
                {this.state.showProfileVideo ? (
                    <Lightbox
                        mainSrc={null}
                        imageTitle="Profile Video"
                        enableZoom={false}
                        mainCustomContent={this.getProfileVideoComponent()}
                        onCloseRequest={() => this.setState({showProfileVideo: undefined})}
                    />
                ) : null}
                {/*<Modal
                    title="Profile Video"
                    visible={this.state.showProfileVideo}
                    bodyStyle={{padding: '0px'}}
                    okText="Send"
                    footer={null}
                    onCancel={() => this.setState({showProfileVideo: undefined})}
                    >
                        {this.state.showProfileVideo ? 
                            <video controls autoPlay style={{width: '100%', height: '250px', objectFit: 'contain'}} 
                            src={(!this.props.myProfile ? Api.getProfileVideo(this.getTutorId()) : Api.getMyProfileVideo())} /> 
                        : null}
                </Modal>*/}

                <Modal
                title={this.state.showFollowerUsers ? "Followers" : "Followings"}
                visible={(this.state.showFollowerUsers || this.state.showFollowingUsers) && this.state.followModalTutorId == (this.getTutorId() !== undefined ? this.getTutorId() : SessionManager.getAccount().id)}
                footer={null}
                onCancel={() => this.setState({showFollowerUsers: false, showFollowingUsers: false, followModalTutorId: undefined})}>
                    {(this.state.showFollowerUsers || this.state.showFollowingUsers) && (
                        <UserList showFollow={{
                            tutorId: this.getTutorId() !== undefined ? this.getTutorId() : SessionManager.getAccount().id,
                            showFollowers: this.state.showFollowerUsers
                        }} />
                    )}
                </Modal>

                <FrontendAuthModal visible={this.state.showSendMessageAuthModal} onCancel={() => this.setState({showSendMessageAuthModal: false})} onDonePath={'/messages?userId=' + this.getTutorId()} />
                {this.state.profile !== undefined &&
                <FrontendAuthModal visible={this.state.showJoinLiveStreamAuthModal} onCancel={() => this.setState({showJoinLiveStreamAuthModal: false})} onDonePath={Util.getLiveStreamPath(this.state.profile.accountLiveStreamId)} />}
            </div>
        )
    }
}

export default withMediaQuery(TutorProfilePage);