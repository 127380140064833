
import React from 'react'
import Api from '../../session/Api';

import NoImageEmpty from '../../images/NoImageEmpty.png';
import { Button, Tooltip, Popconfirm, Popover, Progress } from 'antd';

import {  
    EyeOutlined,
    EyeInvisibleOutlined,
    EditOutlined,
    DeleteOutlined,
    VideoCameraOutlined,
    AudioOutlined,
    FileOutlined,
    OrderedListOutlined,
    AlertOutlined,
    CheckOutlined,
    AreaChartOutlined,
    ArrowRightOutlined,
    PercentageOutlined,
    ClockCircleOutlined,
    DownloadOutlined,
    RiseOutlined,
    UserOutlined
} from '@ant-design/icons';
import { COURSE_ITEM_TYPE_LECTURE_VIDEO, COURSE_ITEM_TYPE_LECTURE_AUDIO, COURSE_ITEM_TYPE_FILE, COURSE_ITEM_TYPE_QUIZ, COURSE_ITEM_TYPE_HELP_SESSION_LIVE } from '../../Constants';
import UIUtil from '../../util/UIUtil';
import Util from '../../util/Util';
import SessionManager from '../../session/SessionManager';

class CourseItemItem extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            hiddenToggleLoading: false,
            deleteLoading: false,

            showingAnalytics: false,
            showingLearnerAnalytics: false,
        }
    }

    getThumbnailIcon() {
        switch (this.props.courseItem.type) {
            case COURSE_ITEM_TYPE_LECTURE_VIDEO:
                return <VideoCameraOutlined style={{fontSize: '20px', color: 'white', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', pointerEvents: 'none'}} />;
            case COURSE_ITEM_TYPE_LECTURE_AUDIO:
                return <AudioOutlined style={{fontSize: '20px', color: 'white', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', pointerEvents: 'none'}} />;
            case COURSE_ITEM_TYPE_FILE:
                return <FileOutlined style={{fontSize: '20px', color: 'white', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', pointerEvents: 'none'}} />;
            case COURSE_ITEM_TYPE_QUIZ:
                return <OrderedListOutlined style={{fontSize: '20px', color: 'white', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', pointerEvents: 'none'}} />;
            case COURSE_ITEM_TYPE_HELP_SESSION_LIVE:
                return <AlertOutlined style={{fontSize: '20px', color: 'white', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', pointerEvents: 'none'}} />;;
            default:
                return <> </>
        }
    }

    onToggleHiddenBtn() {
        this.setState({hiddenToggleLoading: true})
        Api.toggleCourseItemHidden(this.props.courseItem.id, response => {
            if (response.status === true) {
                this.props.courseItem.hidden = response.payload;
            } else {
                UIUtil.showError();
            }
            this.setState({hiddenToggleLoading: false})
        })
    }

    onDeleteBtn() {
        this.setState({deleteLoading: true})
        Api.removeCourseItem(this.props.courseItem.id, response => {
            if (response.status === true) {
                this.props.onItemDeleted();
            } else {
                UIUtil.showError();
            }
            this.setState({deleteLoading: false})
        })
    }

    render() {
        const courseItem = this.props.courseItem;
        const deletable = (new Date().getTime() - courseItem.dateCreated) <= (10 * 60 * 1000);

        const thumbnailWidth = courseItem.type <= COURSE_ITEM_TYPE_LECTURE_AUDIO ? '20%' : '100px'
        const thumbnailHeight = courseItem.type <= COURSE_ITEM_TYPE_LECTURE_AUDIO ? '175px' : '100px'

        const lastLeft = courseItem.lectureProgress !== null ? courseItem.lectureProgress.latestPointPercent : 0;
        const progress = courseItem.lectureProgress !== null ? courseItem.lectureProgress.totalProgressPercent : 0;

        return (
            <div style={{width: '100%', borderRadius: '15px', padding: '15px', boxShadow: "0px 0px 25px 0px rgba(0, 0, 0, 0.2)", display: 'flex'}}>
                <div style={{width: thumbnailWidth, height: thumbnailHeight, objectFit: 'cover', borderRadius: '15px'}}>
                    <div style={{height: '100%', width: '100%', position: 'relative', cursor: 'pointer'}} onClick={this.props.onClick}>
                        <img src={Api.getCourseItemThumbnail(courseItem.id) + (this.props.thumbnailSuffix !== undefined ? ("&t=" + this.props.thumbnailSuffix) : "")} style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: '15px'}} onError={e => {
                            e.target.onerror = null;
                            e.target.src = NoImageEmpty;
                        }} />
                        <div style={{width: '50px', height: '50px', borderRadius: '50%', background: '#00000050', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', pointerEvents: 'none'}}>
                            {this.getThumbnailIcon()}
                        </div>
                        <div style={{position: 'absolute', bottom: 0, left: 10, right: 10}}>
                            <div style={{ marginBottom: 10, height: 9, backgroundColor: '#4e2e89', borderRadius: 25,
                            borderStyle: 'solid', borderWidth: progress > 0 ? 2 : 0, borderColor: "white", width: progress + "%", position: 'absolute', bottom: 0, opacity: 0.6}} />
                            <div style={{ marginBottom: 10, height: 9, backgroundColor: '#f26622', borderRadius: 25,
                            borderStyle: 'solid', borderWidth: lastLeft > 0 ? 2 : 0, borderColor: "white", width: lastLeft + "%", position: 'absolute', bottom: 0}} />
                        </div>
                    </div>
                </div>

                {/*<img src={Api.getCourseItemThumbnail(courseItem.id)} style={{width: '20%', height: '175px', objectFit: 'cover', borderRadius: '15px'}} onError={e => {
                    e.target.onerror = null;
                    e.target.src = NoImage;
                }} />*/}
                <div style={{marginLeft: '25px', flex: 1, minWidth: 1}}>
                    <div style={{display: 'flex', alignItems: 'center', marginRight: '5px'}}>
                        <h2 style={{height: '25px', cursor: 'pointer'}} onClick={this.props.onClick}>{courseItem.title}</h2>
                        {Util.isNumberExist(courseItem.duration) && <Button type="link" style={{cursor: 'default'}}>{Util.formatSeconds(courseItem.duration)}</Button>}
                        {Util.isStringExists(courseItem.format) && <Button type="link" style={{cursor: 'default'}}>{'.' + courseItem.format}</Button>}
                        {Util.isNumberExist(courseItem.size) && <Button type="link" style={{cursor: 'default'}}>{Util.formatBytes(courseItem.size)}</Button>}
                        {Util.isNumberExist(courseItem.questions) && <Button type="link" style={{cursor: 'default'}}>{courseItem.questions + " questions"}</Button>}
                        {courseItem.type == COURSE_ITEM_TYPE_HELP_SESSION_LIVE && <Button type="link" style={{cursor: 'default'}}>{Util.getDate(courseItem.dateCreated)}</Button>}
    
                        <div style={{flex: '1'}} />
                        
                        {courseItem.type != COURSE_ITEM_TYPE_HELP_SESSION_LIVE && (<>
                            {this.props.studentMode ? <>
                                {courseItem.hidden &&
                                <Button type="text" size="large" style={{cursor: 'default'}}><EyeInvisibleOutlined /></Button>}

                                {courseItem.type == COURSE_ITEM_TYPE_FILE && SessionManager.isLoggedIn() && (
                                    courseItem.downloaded ? (
                                        <Tooltip placement="topRight" title="Downloaded">
                                            <CheckOutlined style={{fontSize: 15, marginTop: -15, color: '#4e2e89', opacity: 0.7}} />
                                        </Tooltip>
                                    ) : (
                                        <Tooltip placement="topRight" title="Not downloaded">
                                            <div style={{width: 10, height: 10, borderRadius: 10 / 2, marginTop: -15, backgroundColor: "#4e2e89", opacity: 0.7}} />
                                        </Tooltip>
                                    )
                                )}

                                {courseItem.type == COURSE_ITEM_TYPE_QUIZ && SessionManager.isLoggedIn() && (
                                    courseItem.bestQuizAttempt !== null ? (
                                        <Tooltip title="View attempt history">
                                            <Button type="link" onClick={() => this.props.onViewSubmissionHistoryBtn()}>
                                                Best attempt: {courseItem.bestQuizAttempt.score}/{courseItem.bestQuizAttempt.totalScore} ({courseItem.bestQuizAttempt.percent}%)
                                            </Button>
                                        </Tooltip>
                                    ) : (
                                        <Button type="text" style={{cursor: 'default'}}>
                                            No attempts made
                                        </Button>
                                    )
                                )}
                            </> : <>
                                {(courseItem.type == COURSE_ITEM_TYPE_LECTURE_AUDIO || courseItem.type == COURSE_ITEM_TYPE_LECTURE_VIDEO
                                 || courseItem.type == COURSE_ITEM_TYPE_FILE || courseItem.type == COURSE_ITEM_TYPE_QUIZ) &&
                                <Tooltip title="Analytics" placement="top" {...((this.state.showingAnalytics) ? {visible: false} : {})}>
                                    <Popover placement="topRight" content={<div style={{width: 300}}>
                                        <h2 style={{color: 'black', fontSize: 25, height: 30}}>
                                            <AreaChartOutlined /> Analytics
                                        </h2>

                                        {(courseItem.type == COURSE_ITEM_TYPE_LECTURE_AUDIO || courseItem.type == COURSE_ITEM_TYPE_LECTURE_VIDEO) && <>
                                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                <p style={{flex: 1, height: 11}}><PercentageOutlined /> &nbsp;Average progress</p>
                                                <Progress className="progress-non-white-color" type="circle" percent={courseItem.averageProgressLearners} width={45} 
                                                strokeColor={courseItem.averageProgressLearners < 100 && "#f26622"} strokeWidth={7} />
                                            </div>

                                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 15}}>
                                                <p style={{flex: 1, height: 11}}><ClockCircleOutlined /> &nbsp;Total time spent</p>
                                                <Progress className="progress-purple-color" type="circle" percent={100} 
                                                format={() => Util.formatSecondsBrief(courseItem.totalTimeSpentLearners / 1000)} 
                                                style={{marginLeft: 10}} width={45} strokeWidth={7} strokeColor="#4e2e89" />
                                            </div>
                                        </>}

                                        {(courseItem.type == COURSE_ITEM_TYPE_FILE) && <>
                                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                <p style={{flex: 1, height: 11}}><DownloadOutlined /> &nbsp;Downloaded on average</p>
                                                <Progress className="progress-non-white-color" type="circle" percent={courseItem.downloadedOnAverageLearners ? 100 : 0} width={45} 
                                                strokeWidth={7} format={() => courseItem.downloadedOnAverageLearners ? "Yes" : "No"} />
                                            </div>

                                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 15}}>
                                                <p style={{flex: 1, height: 11}}><RiseOutlined /> &nbsp;Total unique downloads</p>
                                                <Progress className="progress-purple-color" type="circle" percent={100} 
                                                format={() => courseItem.totalUniqueDownloadLearners} 
                                                style={{marginLeft: 10}} width={45} strokeWidth={7} strokeColor="#4e2e89" />
                                            </div>
                                        </>}
                                        
                                        {(courseItem.type == COURSE_ITEM_TYPE_QUIZ) && <>
                                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                <p style={{flex: 1, height: 11}}><PercentageOutlined /> &nbsp;Average highest score</p>
                                                <Progress className="progress-non-white-color" type="circle" 
                                                percent={courseItem.averageBestQuizScoreLearners} width={45} 
                                                format={value => value + "%"}
                                                strokeColor={courseItem.averageBestQuizScoreLearners < 100 && "#f26622"} strokeWidth={7} />
                                            </div>

                                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 15}}>
                                                <p style={{flex: 1, height: 11}}><OrderedListOutlined /> &nbsp;Attempted on average</p>
                                                <Progress className="progress-non-white-color" type="circle" percent={courseItem.attemptedQuizOnAverageLearners ? 100 : 0} width={45} 
                                                strokeWidth={7} format={() => courseItem.attemptedQuizOnAverageLearners ? "Yes" : "No"} />
                                            </div>

                                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 15}}>
                                                <p style={{flex: 1, height: 11}}><RiseOutlined /> &nbsp;Total attempts</p>
                                                <Progress className="progress-purple-color" type="circle" percent={100} 
                                                format={() => courseItem.totalAttemptsLearners}
                                                style={{marginLeft: 10}} width={45} strokeWidth={7} strokeColor="#4e2e89" />
                                            </div>
                                        </>}
                                        
                                        <div style={{display: 'flex'}}>
                                            <div style={{flex: 1}} />
                                            <Button type="primary" className="orange-button" style={{marginTop: 15}} onClick={() => {
                                                this.setState({showingLearnerAnalytics: true, showingAnalytics: false})
                                                this.props.onViewMoreAnalyticsBtn(() => this.setState({showingLearnerAnalytics: false}))
                                            }}>
                                                <UserOutlined /> Learner Analytics
                                            </Button>
                                        </div>
                                        
                                    </div>} trigger="click" onVisibleChange={visible => this.setState({showingAnalytics: visible})}
                                    {...((this.state.showingLearnerAnalytics) ? {visible: false} : {})}>
                                        <Button type="link" size="large" onClick={e => {
                                            e.stopPropagation();
                                        }}><AreaChartOutlined /></Button>
                                    </Popover>
                                </Tooltip>}

                                <Tooltip title={courseItem.hidden ? "Show" : "Hide"} placement="top">
                                    <Button type="link" size="large" loading={this.state.hiddenToggleLoading} onClick={e => {
                                        e.stopPropagation();
                                        this.onToggleHiddenBtn();
                                    }}>{courseItem.hidden ? <EyeInvisibleOutlined /> : <EyeOutlined />}</Button>
                                </Tooltip>
            
                                <Tooltip title="Edit" placement="topRight">
                                    <Button type="link" size="large" onClick={e => {
                                        e.stopPropagation();
                                        this.props.onEditBtn()
                                    }}><EditOutlined /></Button>
                                </Tooltip>
            
                                {deletable ? 
                                    <Popconfirm title="Are you sure you want to delete this component?" onConfirm={this.onDeleteBtn.bind(this)} placement="topRight">
                                        <Button danger type="link" size="large" onClick={e => e.stopPropagation()} loading={this.state.deleteLoading}><DeleteOutlined /></Button>
                                    </Popconfirm>
                                : null}
                            </>}

                        </>)}
                        
                    </div>
                    
                    {//<p style={{paddingTop: '5px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', flex: '1'}}>
                    }
                    <p style={{paddingTop: '5px', marginTop: '5px', overflowWrap: 'break-word', whiteSpace: 'pre-line'}}>{courseItem.description}</p>
                </div>
            </div>
        )
    }

}

export default CourseItemItem