import React from 'react'
import Window from '../../base/Window';
import SessionManager from '../../session/SessionManager';
import Error404Page from '../../pages/Error404Page';
import FrontendAuthView from '../../views/auth/FrontendAuthView';

class PublicAuthWindow extends Window {
    constructor(props) {
        super(props);
    }

    getLayout() {
        return ( <FrontendAuthView registerType={this.params.registerType} onDonePath={this.params.dP !== undefined ? this.params.dP : '/'} /> )
    }

    isLockBehindAuth() {
        return false;
    }
}

export default PublicAuthWindow