import React from 'react'
import Page from '../../base/Page';
import withMediaQuery from '../../components/WithMediaQuery';

import {
    DollarCircleFilled,
    BankFilled,
    HistoryOutlined,
    LineChartOutlined,
    CreditCardFilled,
    HourglassOutlined,
    CalendarFilled,
    BulbFilled,
    CloseCircleOutlined,
    FieldTimeOutlined,
    ProfileFilled,
    TableOutlined,
    ClockCircleOutlined,
    QuestionCircleOutlined,
    UnorderedListOutlined,
    DownloadOutlined,
    DownCircleFilled,
    LoadingOutlined,
    MailFilled
} from '@ant-design/icons'
import { Alert, Button, Card, DatePicker, Input, InputNumber, Popconfirm, Space, Spin, Table, Tabs, Tag, Tooltip } from 'antd';
import Util from '../../util/Util';
import StatementsTableView from '../../views/accounting/StatementsTableView';
import { STATEMENTS_DATA_SOURCE_ALL, STATEMENTS_DATA_SOURCE_EARNINGS, STATEMENTS_DATA_SOURCE_PURCHASES, STATEMENTS_DATA_SOURCE_WITHDRAWALS, STATEMENTS_TYPE_EARNING, STATEMENTS_TYPE_WITHDRAW } from '../../Constants';
import Api from '../../session/Api';
import UIUtil from '../../util/UIUtil';
import Modal from 'antd/lib/modal/Modal';

const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

const lastMonthDate = new Date();
lastMonthDate.setDate(0)

const currentMonthDate = new Date();
currentMonthDate.setDate(1)

const lastMonth = monthNames[lastMonthDate.getUTCMonth()];
const currentMonth = monthNames[currentMonthDate.getUTCMonth()];

class TutorBalancePage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            inError: false,

            balanceInfo: undefined,

            showLinkWithdrawEmailAddressDialog: false,

            linkWithdrawEmailValue: "",
            linkWithdrawEmailConfirmValue: "",

            loadingLinkWithdrawDest: false,
            loadingUnlinkWithdrawDest: false,

            showRequestWithdrawalDialog: false,
            requestWithdrawalAmountValue: 0,
            loadingRequestWithdrawal: false,

            loadingCancelActiveWithdrawRequest: false,

            statmentsKey: Util.newTempId()
        }
    }

    componentDidMount() {
        Api.getBalanceInfo(response => {
            if (response.status === true) {
                this.setState({loading: false, inError: false, balanceInfo: response.payload})
            } else {
                this.setState({loading: false, inError: true})
            }
        })
    }

    linkWithdrawDest() {
        if (this.state.linkWithdrawEmailValue != this.state.linkWithdrawEmailConfirmValue) {
            UIUtil.showError("Email addresses do not match!")
            return;
        }

        this.setState({loadingLinkWithdrawDest: true})
        Api.linkWithdrawDestination(this.state.linkWithdrawEmailValue, response => {
            if (response.status === true) {
                this.state.balanceInfo.withdrawDestination = response.payload;
                UIUtil.showSuccess();
                this.setState({loadingLinkWithdrawDest: false, showLinkWithdrawEmailAddressDialog: false})
            } else {
                UIUtil.showError();
                this.setState({loadingLinkWithdrawDest: false})
            }
        })
    }

    unlinkWithdrawDest() {
        if (this.state.loadingUnlinkWithdrawDest) {
            return;
        }

        this.setState({loadingUnlinkWithdrawDest: true})
        Api.unlinkWithdrawDestination(response => {
            if (response.status === true) {
                this.state.balanceInfo.withdrawDestination = null;
                UIUtil.showSuccess();
                this.setState({loadingUnlinkWithdrawDest: false})
            } else {
                UIUtil.showError(response.message);
                this.setState({loadingUnlinkWithdrawDest: false})
            }
        })
    }

    createWithdrawRequest() {
        this.setState({loadingRequestWithdrawal: true})
        Api.createWithdrawRequest(this.state.requestWithdrawalAmountValue, response => {
            if (response.status === true) {
                //this.state.balanceInfo.activeWithdrawRequest = response.payload;
                //this.state.balanceInfo = response.payload;
                UIUtil.showSuccess();
                this.setState({showRequestWithdrawalDialog: false, loadingRequestWithdrawal: false, balanceInfo: response.payload, statmentsKey: Util.newTempId()})
            } else {
                UIUtil.showError();
                this.setState({loadingRequestWithdrawal: false})
            }
        })
    }

    cancelWithdrawRequest() {
        this.setState({loadingCancelActiveWithdrawRequest: true})
        Api.cancelWithdrawRequest(response => {
            if (response.status === true) {
                //this.state.balanceInfo.activeWithdrawRequest = null;
                //this.state.balanceInfo = response.payload;
                UIUtil.showSuccess();
                this.setState({loadingCancelActiveWithdrawRequest: false, balanceInfo: response.payload, statmentsKey: Util.newTempId()})
            } else {
                UIUtil.showError();
                this.setState({loadingCancelActiveWithdrawRequest: false})
            }
        })
    }

    render() {
        return (
            <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                <div style={{width: this.props.isSmall ? '90vw' : '75vw', paddingTop: '25px', paddingBottom: '25px'}}>
                    <div style={{width: '100%', display: 'flex', flexDirection: this.props.isSmall ? 'column' : 'row'}}>
                        {this.state.loading || this.state.inError ? (
                            this.state.loading ? (
                                <div style={{flex: 1, height: 250, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <Spin size="large" />
                                </div>
                            ) : (
                                <div style={{flex: 1, height: 250, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <p>An error occurred trying to load balance. Please try again later.</p>
                                </div>
                            )
                        ) : (<>
                            <div style={{flex: 1}}>
                                <h2 style={{color: 'rgba(0, 0, 0, 0.65)', margin: 0}}><DollarCircleFilled style={{marginRight: 0}} /> Balance</h2>
                                <p style={{fontSize: 60, margin: 0, marginTop: -20, color: 'green'}}>
                                    ${this.state.balanceInfo.balance.toFixed(2)}
                                </p>

                                <div style={{height: 15}} />

                                <h3 style={{color: 'rgba(0, 0, 0, 0.65)', margin: 0}}>
                                    <BankFilled style={{marginRight: 2}} />
                                    Month Earnings ({currentMonth})
                                </h3>
                                {this.state.balanceInfo.balance > 0 &&
                                <p style={{color: 'green', fontSize: 12, margin: 0, marginLeft: 8, marginTop: 0}}>
                                    <LineChartOutlined /> 100% increase from last month
                                </p>}
                                <p style={{fontSize: 30, margin: 0, marginTop: 0, color: 'black'}}>
                                    ${this.state.balanceInfo.monthsEarnings.toFixed(2)}
                                </p>

                                <h3 style={{color: 'rgba(0, 0, 0, 0.65)', margin: 0, marginTop: 10}}><HistoryOutlined style={{marginRight: 0}} /> Last Month Earnings ({lastMonth})</h3>
                                <p style={{fontSize: 30, margin: 0, marginTop: -5, color: 'black'}}>
                                    $0.00
                                </p>
                            </div>

                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                                <h2 style={{color: 'rgba(0, 0, 0, 0.65)', margin: 0, marginRight: 5}}><DownCircleFilled style={{marginRight: 0}} /> Withdrawal</h2>
                                
                                <h3 style={{color: 'rgba(0, 0, 0, 0.65)', margin: 0, marginTop: 5}}>
                                    <DollarCircleFilled style={{marginRight: 0}} /> Withdrawable Balance
                                    <Tooltip title="The amount that you can withdraw from the balance. Amounts from transactions that are on hold can not be withdrawn.">
                                        <Button type="link" style={{padding: 0, marginLeft: 10}}><QuestionCircleOutlined /></Button>
                                    </Tooltip>
                                </h3>
                                <p style={{fontSize: 35, margin: 0, marginTop: -10, color: 'green'}}>
                                    ${this.state.balanceInfo.withdrawableBalance.toFixed(2)}
                                </p>

                                
                                {this.state.balanceInfo.activeWithdrawRequest != null ? (
                                    <Card title={<><HourglassOutlined /> Withdrawal in progress </>} 
                                    actions={[
                                        <div style={{flex: 1, display: 'flex', justifyContent: 'flex-end', marginRight: 15}}>
                                            <Popconfirm title="Are you sure?" onConfirm={this.cancelWithdrawRequest.bind(this)}>
                                                <Button type="primary" danger
                                                loading={this.state.loadingCancelActiveWithdrawRequest}>
                                                    <CloseCircleOutlined /> Cancel Withdrawal
                                                </Button>
                                            </Popconfirm>
                                        </div>
                                    ]}
                                    hoverable={false} style={{marginTop: 5}} bodyStyle={{paddingTop: 15, paddingBottom: 15}}>
                                        <h5 style={{color: 'rgba(0, 0, 0, 0.65)', margin: 0, marginTop: 10}}><DollarCircleFilled style={{marginRight: 0}} /> Amount</h5>
                                        <p style={{fontSize: 20, margin: 0, marginTop: -5, color: 'black'}}>
                                            ${Math.abs(this.state.balanceInfo.activeWithdrawRequest.amount).toFixed(2)}
                                        </p>
    
                                        <h5 style={{color: 'rgba(0, 0, 0, 0.65)', margin: 0, marginTop: 10}}><CalendarFilled style={{marginRight: 0}} /> Date initiated</h5>
                                        <p style={{fontSize: 20, margin: 0, marginTop: -5, color: 'black'}}>
                                            {Util.getFullDate(this.state.balanceInfo.activeWithdrawRequest.date)}
                                        </p>
    
                                        <h5 style={{color: 'rgba(0, 0, 0, 0.65)', margin: 0, marginTop: 10}}><MailFilled style={{marginRight: 0}} /> Destination</h5>
                                        <p style={{fontSize: 20, margin: 0, marginTop: -5, color: 'black'}}>
                                            {this.state.balanceInfo.withdrawDestination !== null && this.state.balanceInfo.withdrawDestination.emailAddress}
                                        </p>
                                    </Card>
                                ) : (<>
                                    <Button disabled={this.state.balanceInfo.withdrawDestination == null}
                                    onClick={() => this.setState({showRequestWithdrawalDialog: true, requestWithdrawalAmountValue: 0})}
                                    style={{marginTop: 10}} type="primary">Request a Withdrawal</Button>
                                    {this.state.balanceInfo.withdrawDestination !== null ? (
                                        <p style={{fontSize: 13, color: 'rgba(0,0,0,0.6)', width: undefined, marginTop: 10, textAlign: 'right'}}>
                                            {this.state.balanceInfo.withdrawDestination.emailAddress}
                                            {this.state.balanceInfo.activeWithdrawRequest == null && <>
                                                <br />
                                                <Popconfirm title="Are you sure?" onConfirm={this.unlinkWithdrawDest.bind(this)}>
                                                    <a style={{color: '#990000', opacity: this.state.loadingUnlinkWithdrawDest ? 0.65 : 1, pointerEvents: this.state.loadingUnlinkWithdrawDest ? 'none' : undefined}}>Unlink {this.state.loadingUnlinkWithdrawDest ? <LoadingOutlined /> : <CloseCircleOutlined />}</a>
                                                </Popconfirm>
                                            </>}
                                        </p>
                                    ) : (
                                        <p style={{fontSize: 13, color: 'rgba(0,0,0,0.6)', width: undefined, marginTop: 10, textAlign: 'right'}}>
                                            Please link a PayPal account in order to request a withdrawal. 
                                            <br />
                                            <a onClick={() => this.setState({showLinkWithdrawEmailAddressDialog: true, linkWithdrawEmailValue: "", linkWithdrawEmailConfirmValue: ""})}>Link PayPal account</a>
                                        </p>
                                    )}
                                </>)}
                            </div>
                        </>)}
                    </div>

                    <Tabs key={this.state.statmentsKey} defaultActiveKey="s" size="large" className="left-aligned-tabs" style={{marginTop: 25}}>
                        <Tabs.TabPane key="s" tab={<><ProfileFilled /> Statement</>}>
                            <StatementsTableView key="statments-table" dataSource={STATEMENTS_DATA_SOURCE_ALL} />
                        </Tabs.TabPane>
                        <Tabs.TabPane key="e" tab={<><DollarCircleFilled /> Earnings</>}>
                            <StatementsTableView key="earnings-table" dataSource={STATEMENTS_DATA_SOURCE_EARNINGS} />
                        </Tabs.TabPane>
                        <Tabs.TabPane key="p" tab={<><CreditCardFilled /> Purchases</>}>
                            <StatementsTableView key="earnings-table" dataSource={STATEMENTS_DATA_SOURCE_PURCHASES} />
                        </Tabs.TabPane>
                        <Tabs.TabPane key="w" tab={<><DownCircleFilled /> Withdrawals</>}>
                            <StatementsTableView key="withdrawals-table" dataSource={STATEMENTS_DATA_SOURCE_WITHDRAWALS} />
                        </Tabs.TabPane>
                    </Tabs>
                </div>

                {!this.state.loading && !this.state.inError && <>
                    <Modal visible={this.state.showLinkWithdrawEmailAddressDialog} onOk={this.linkWithdrawDest.bind(this)} confirmLoading={this.state.loadingLinkWithdrawDest}
                    title="Link Withdraw Destination" okButtonProps={{disabled: !(Util.isStringExists(this.state.linkWithdrawEmailValue) && Util.isStringExists(this.state.linkWithdrawEmailConfirmValue))}}
                    onCancel={() => this.setState({showLinkWithdrawEmailAddressDialog: false})}>
                        <Alert type="info" showIcon message="Important" description="Make sure that the email address is correct and that you can receive the funds on it (PayPal). Learnerix is not liable for any issues that might arise due to incorrect information." />

                        <h4 style={{marginTop: 25}}>Email Address</h4>
                        <Input style={{width: '100%'}} value={this.state.linkWithdrawEmailValue} onChange={e => this.setState({linkWithdrawEmailValue: e.target.value})} />
                        <h4 style={{marginTop: 15}}>Confirm Email Address</h4>
                        <Input style={{width: '100%'}} value={this.state.linkWithdrawEmailConfirmValue} onChange={e => this.setState({linkWithdrawEmailConfirmValue: e.target.value})} />
                    </Modal>

                    <Modal visible={this.state.showRequestWithdrawalDialog} title="Request a Withdrawal" onOk={this.createWithdrawRequest.bind(this)}
                    okButtonProps={{disabled: this.state.requestWithdrawalAmountValue <= 0}} confirmLoading={this.state.loadingRequestWithdrawal}
                    onCancel={() => this.setState({showRequestWithdrawalDialog: false})} okText="Submit Request"
                    width={600}
                    bodyStyle={{padding: 0}}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: 25, paddingRight: 25}}>
                            <InputNumber min={0} style={{fontSize: 25, flex: 1, padding: 0, margin: 0, border: 'none'}}
                            value={this.state.requestWithdrawalAmountValue} onChange={value => this.setState({requestWithdrawalAmountValue: value})}
                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                            max={this.state.balanceInfo.withdrawableBalance} />

                            <h1 style={{fontSize: 75, marginLeft: 10}}>/</h1>
                            <h1 style={{color: 'green', fontSize: 75}}>${this.state.balanceInfo.withdrawableBalance.toFixed(2)}</h1>
                        </div>
                    </Modal>
                </>}
            </div>
        )
    }

}

export default withMediaQuery(TutorBalancePage);



{/*<Table columns={columns} dataSource={data} title={() => (
    <div style={{display: 'flex'}}>
        <div style={{flex: 1}} />
        <DatePicker.RangePicker style={{marginRight: 10}} />
        <Button type="primary"><TableOutlined /> Export to spreadsheet</Button>
    </div>
)} />

const columns = [
    {
        title: 'Transaction',
        dataIndex: 'key',
        key: 'key',
        render: text => {
            switch (text) {
                case "1":
                    return (
                        <div>
                            <p style={{color: 'black', margin: 0, padding: 0}}>Painting Class Intro</p>
                            <p style={{color: '#f26622', margin: 0, padding: 0, fontSize: 10, marginTop: -5}}>Purchase Earning</p>
                            <p style={{margin: 0, padding: 0, fontSize: 10, marginTop: -5}}>Course</p>
                        </div>
                    )
                case "2":
                    return <p style={{ margin: 0, padding: 0}}>User initiated withdrawal</p>
                case "3":
                    return (
                        <div>
                            <p style={{color: 'black', margin: 0, padding: 0}}>Musical Practice 101</p>
                            <p style={{color: '#4e2e89', margin: 0, padding: 0, fontSize: 10, marginTop: -5}}>Subscription Earning</p>
                            <p style={{margin: 0, padding: 0, fontSize: 10, marginTop: -5}}>Live Session</p>
                        </div>
                    )
            }
            
            return <div />
        },
    },
    {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        render: type => <>
            <Tag color={type == 0 ? "green" : undefined}>{type == 0 ? "Earning" : "Withdraw"}</Tag>
            {type == 0 && <Tooltip title="Transactions from purchases will be on hold for 15 days after the payment date. Your balance includes these amounts as well but they can not be withdrawn (Display only).">
                <Tag color="processing" icon={<ClockCircleOutlined />} >On Hold</Tag>
            </Tooltip>}
        </>,
    },
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        //render: date => <p>{Util.getFullDate(date)}</p>
        render: date => Util.getFullDate(date)
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        render: value => <span style={{color: value < 0 ? undefined : "green"}}>${value}</span>
    },
  ];
  
  const data = [
    {
      key: '1',
      name: 'John Brown',
      age: 32,
      address: 'New York No. 1 Lake Park',
      tags: ['nice', 'developer'],
      amount: 30.00,
      type: 0,
      date: new Date().getTime()
    },
    {
      key: '2',
      name: 'Jim Green',
      age: 42,
      address: 'London No. 1 Lake Park',
      tags: ['loser'],
      amount: -20.00,
      type: 1,
      date: new Date().getTime()
    },
    {
      key: '3',
      name: 'Joe Black',
      age: 32,
      address: 'Sidney No. 1 Lake Park',
      tags: ['cool', 'teacher'],
      amount: 100,
      type: 0,
      date: new Date().getTime()
    },
  ];
*/}