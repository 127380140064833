import React from 'react';
import { Row, Col, Rate, Progress } from 'antd';

import { 
    StarFilled
} from '@ant-design/icons';

class RateChart extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const ratingsReport = this.props.ratingsReport !== undefined ? this.props.ratingsReport : {};
        return (
            <div style={{width: '100%'}}>
                <div>
                    <div style={{display: 'flex'}}>
                        <div style={{width: '150px', height: '175px', borderRadius: '15px', padding: '15px', background: '#f26622'}}>
                            <div style={{ height: '75%', borderRadius: '15px', padding: '15px', background: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <h1 style={{fontSize: '60px', marginTop: '25px'}}>{ratingsReport.averageRating === 0 ? '-' : ratingsReport.averageRating}</h1>
                            </div>
                            <div style={{ height: '25%', display: 'flex', alignItems: 'end', justifyContent: 'center'}}>
                                <h3 style={{color: 'white', marginTop: '11px'}}>out of 5</h3>
                            </div>
                        </div>



                        <div style={{marginLeft: '25px', flex: '1', marginRight: '25px'}}>
                            <div style={{display: 'flex', alignItems: 'center', width: '100%', height: '20%'}}>
                                <span>5</span>
                                <StarFilled style={{marginLeft: '15px'}} /> 
                                <Progress percent={(ratingsReport.fiveRatingsCount / ratingsReport.totalRatingsCount) * 100} strokeColor="#f26622" size="large" style={{marginLeft: '15px'}} showInfo={false}/>
                                <span style={{marginLeft: '15px', color: '#8a8a8a', fontSize: '10px'}}>({ratingsReport.fiveRatingsCount})</span>
                            </div>
                            <div style={{display: 'flex', alignItems: 'center', width: '100%', height: '20%'}}>
                                <span>4</span>
                                <StarFilled style={{marginLeft: '15px'}} /> 
                                <Progress percent={(ratingsReport.fourRatingsCount / ratingsReport.totalRatingsCount) * 100} strokeColor="#f26622" size="large" style={{marginLeft: '15px'}} showInfo={false}/>
                                <span style={{marginLeft: '15px', color: '#8a8a8a', fontSize: '10px'}}>({ratingsReport.fourRatingsCount})</span>
                            </div>
                            <div style={{display: 'flex', alignItems: 'center', width: '100%', height: '20%'}}>
                                <span>3</span>
                                <StarFilled style={{marginLeft: '15px'}} /> 
                                <Progress percent={(ratingsReport.threeRatingsCount / ratingsReport.totalRatingsCount) * 100} strokeColor="#f26622" size="large" style={{marginLeft: '15px'}} showInfo={false}/>
                                <span style={{marginLeft: '15px', color: '#8a8a8a', fontSize: '10px'}}>({ratingsReport.threeRatingsCount})</span>
                            </div>
                            <div style={{display: 'flex', alignItems: 'center', width: '100%', height: '20%'}}>
                                <span>2</span>
                                <StarFilled style={{marginLeft: '15px'}} /> 
                                <Progress percent={(ratingsReport.twoRatingsCount / ratingsReport.totalRatingsCount) * 100} strokeColor="#f26622" size="large" style={{marginLeft: '15px'}} showInfo={false}/>
                                <span style={{marginLeft: '15px', color: '#8a8a8a', fontSize: '10px'}}>({ratingsReport.twoRatingsCount})</span>
                            </div>
                            <div style={{display: 'flex', alignItems: 'center', width: '100%', height: '20%'}}>
                                <span>1</span>
                                <StarFilled style={{marginLeft: '15px'}} /> 
                                <Progress percent={(ratingsReport.oneRatingsCount / ratingsReport.totalRatingsCount) * 100} strokeColor="#f26622" size="large" style={{marginLeft: '15px'}} showInfo={false}/>
                                <span style={{marginLeft: '15px', color: '#8a8a8a', fontSize: '10px'}}>({ratingsReport.oneRatingsCount})</span>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div style={{width: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                        <Rate value={ratingsReport.averageRating} disabled />
                        <h3 style={{marginTop: '15px'}}>{ratingsReport.totalRatingsCount} review{ratingsReport.totalRatingsCount !== 1 ? "s" : ""}</h3>
                    </div>
                </div>
            </div>
        )
    }
}

export default RateChart;