import { Button, Tooltip } from 'antd';
import React, { createRef, useEffect, useRef, useState, } from 'react'
import ResizeObserver from 'react-resize-observer';

import { 
    BlockOutlined,
    FullscreenOutlined,
    ArrowsAltOutlined,
    SwapOutlined
} from '@ant-design/icons';
import { StreamIcon } from './StreamIcon';
import { STREAM_TYPE_DESKTOP_VIDEO } from '../streams/StreamTypes';
import Util from '../../util/Util';

import * as portals from 'react-reverse-portal';
import useSize from '../../hooks/useSize';

export default function LiveVideo({ srcObject, refListener, alwaysMuted, onFullScreenBtn, fullScreenMode, minimizedMode, stream, placement, fillParentWidth, onPiPEntered, 
    //recordingMode, recordingPlay, recordingPosChange, recordingPos, onRecordingLoadingUpdate, 
    recordingMediaPlayer,
    ...props 
}) {
    const containerRef = useRef(null);
    const videoRef = useRef(null);
    const mediaPlayerRef = useRef(null);

    const [videoWidth, setVideoWidth] = useState(fillParentWidth ? 1920 : 0);
    const [videoHeight, setVideoHeight] = useState(0);

    const [videoX, setVideoX] = useState(0);
    const [videoY, setVideoY] = useState(0);

    const [showOverlay, setShowOverlay] = useState(false);
    const [overlayTimer, setOverlayTimer] = useState(undefined);

    const [inPiP, setInPiP] = useState(false);

    // const [syncCheckCount, setSyncCheckCount] = useState(0);
    //const [buffering, setBuffering] = useState(false);

    const containerSize = useSize(containerRef);

    const onMouseMove = () => {
        if (overlayTimer !== undefined) {
            clearTimeout(overlayTimer);
        } 
        setShowOverlay(true);
        setOverlayTimer(setTimeout(() => setShowOverlay(false), 1000));
    }

    const onMouseLeave = () => {
        if (overlayTimer !== undefined) {
            clearTimeout(overlayTimer);
        }
        setShowOverlay(false);
    }

    const setVideoSize = (videoSize) => {
        setVideoWidth(videoSize.width);
        setVideoHeight(videoSize.height);
        setVideoX(videoSize.x);
        setVideoY(videoSize.y);
    }

    const onVideoResize = () => {
        if (videoRef.current !== null && containerRef.current !== null) {
            const videoSize = getVideoSize(containerRef.current.offsetHeight, containerRef.current.offsetWidth, videoRef.current.videoHeight, videoRef.current.videoWidth, placement)
            setVideoSize(videoSize);
        }
    }

    useEffect(onVideoResize, [minimizedMode, fullScreenMode, placement, fillParentWidth, containerSize]);

    const startPiP = () => {
        setInPiP(true);
        onPiPEntered(stream.streamId);
        videoRef.current.requestPictureInPicture()
    }

    const onLeftPiP = () => {
        setInPiP(false);
        
        // if (videoRef.current && recordingMode) {
        //     setTimeout(() => videoRef.current.play());
        // }
    }

    useEffect(() => {
        if (!videoRef.current) return
        //if (stream.streamUrl !== undefined) {
        if (recordingMediaPlayer) {
            // videoRef.current.src = stream.streamUrl;
            // videoRef.current.currentTime = (recordingPos - stream.streamInfo.startTime) / 1000;

            // if (mediaPlayerRef.current) {
            //     return;
            // }

            // const mediaPlayer = MediaPlayer().create();
            // console.log("intializing", stream.streamUrl)
            // mediaPlayer.initialize(videoRef.current, stream.streamUrl, true);
            // mediaPlayerRef.current = mediaPlayer;

            recordingMediaPlayer.attachView(videoRef.current);
        } else {
            videoRef.current.srcObject = stream.rawStream;
            videoRef.current.play()
        }

        videoRef.current.removeEventListener('resize', onVideoResize);
        videoRef.current.removeEventListener('leavepictureinpicture', onLeftPiP);

        videoRef.current.addEventListener('resize', onVideoResize);
        videoRef.current.addEventListener('leavepictureinpicture', onLeftPiP);
    }, [stream])




    // useEffect(() => {
    //     if (!videoRef.current || !recordingMode) return
         
    //     if (recordingPlay) {
    //         videoRef.current.play()
    //     } else {
    //         videoRef.current.pause();
    //     }
    // }, [recordingPlay])

    // useEffect(() => {
    //     if (!videoRef.current || !recordingMode) return
        
    //     videoRef.current.currentTime = (recordingPosChange - stream.streamInfo.startTime) / 1000;
    // }, [recordingPosChange])

    // useEffect(() => {
    //     if (!videoRef.current || !recordingMode) return

    //     const isVideoPlaying = !!(videoRef.current.currentTime > 0 && !videoRef.current.paused && !videoRef.current.ended && videoRef.current.readyState > 2);
    //     if (!isVideoPlaying) {
    //         return;
    //     }
        
    //     const timeToBe = (recordingPos - stream.streamInfo.startTime) / 1000;
    //     const actualTime = videoRef.current.currentTime;

    //     const diff = Math.abs((timeToBe - actualTime));
        
    //     // console.log("sync!", diff);
    //     //if (diff > 0.70) {
    //     if (diff > 1) {
    //         // console.log("truing to fix", diff)
    //         // console.log("truing to fix", syncCheckCount)
    //         // if (syncCheckCount == 0) {
    //             videoRef.current.currentTime = timeToBe;
    //         // }
    //         // setSyncCheckCount((syncCheckCount + 1) % 8)
    //         // setSyncCheckCount((syncCheckCount + 1) % 2)
    //     }
    // }, [recordingPos])

    return (<>
        {/* <portals.InPortal node={portalNode}>
            
        </portals.InPortal> */}

        <div className="live-video" style={{padding: !fullScreenMode ? 15 : undefined, width: fillParentWidth ? videoWidth : '100%', height: '100%'}}>
            <div ref={containerRef} style={{position: 'relative', width: fillParentWidth ? videoWidth : '100%', height: '100%', overflow: 'hidden', background: fullScreenMode ? 'black' : undefined}}>
                {/* <portals.OutPortal node={props.portalNode} /> */}
                <video 
                // {...(recordingMode ? {
                //     onWaiting: () => {
                //         onRecordingLoadingUpdate(stream.streamId, true)
                //         // setBuffering(true);
                //     },
                //     onCanPlayThrough: () => {
                //         onRecordingLoadingUpdate(stream.streamId, false)
                //         // setBuffering(false)
                //     }
                // } : {})}


                muted 
                playsInline
                //autoPlay={recordingMode ? false : true}
                autoPlay={recordingMediaPlayer ? false : true}
                ref={videoRef}
                key={'v' + props.key}
                controlsList="nodownload"
                onContextMenu={e => e.preventDefault()}
                onLoadedMetadata={() => onVideoResize()}
                style={{position: 'absolute', left: videoX, top: videoY, width: videoWidth, height: videoHeight, 
                //objectFit: 'cover', 
                objectFit: 'contain', objectPosition: 'center', background: 'black',
                overflow: 'hidden', borderRadius: fullScreenMode ? 0 : 5}} 
                
                controls={false}
                //src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4"
                />

                {/* {buffering && <h1 style={{color: 'red', position: 'absolute', left: 0, top: 0, zIndex: 10000}}>BUFFERING</h1>} */}

                {!fullScreenMode && <div 
                onMouseMove={onMouseMove} onMouseLeave={onMouseLeave} className="live-stream-button"
                style={{position: 'absolute', left: videoX, top: videoY, width: videoWidth, height: videoHeight, borderRadius: 5, transition: 'opacity 250ms', background: minimizedMode ? 'linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.65) 40%, rgba(0,0,0,1) 80%)' : 'linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.65) 55%, rgba(0,0,0,1) 95%)', opacity: showOverlay ? 1 : 0,
                display: 'flex', alignItems: 'flex-end', padding: 15}}>
                    <div style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
                        <StreamIcon streamType={stream.streamType} style={{margin: 0, marginRight: 4, color: 'white'}} />
                        <p style={{margin: 0, fontSize: 14, overflow: 'hidden', textOverflow: 'ellipsis', color: 'white', whiteSpace: 'pre'}}>
                            {Util.isStringExists(stream.userFullName) ? stream.userFullName.split(" ")[0] : ''}
                        </p>
                        <p style={{margin: 0, marginLeft: 2, fontSize: 10, opacity: 0.65, fontStyle: 'italic', alignSelf: 'flex-end', color: 'white'}}>({stream.userIsHost ? 'host' : 'stage'})</p>
                    </div>

                    <div style={{minWidth: 15, flex: 1}} />

                    {//!recordingMode 
                    true && <>
                    
                        {!minimizedMode && 
                        <><Tooltip title="Picture in Picture">
                            <Button onClick={startPiP} type="primary" style={{borderRadius: '50%', minWidth: 35, width: 35, height: 35, padding: 0}}><BlockOutlined /></Button>
                        </Tooltip>
                        <div style={{minWidth: 2, width: 5}} />
                        <Tooltip title="Full Screen">
                            <Button onClick={() => onFullScreenBtn(stream.streamId, false)} type="primary" style={{borderRadius: '50%', minWidth: 35, width: 35, height: 35, padding: 0}}><FullscreenOutlined /></Button>
                        </Tooltip></>}

                        {minimizedMode &&
                        <Tooltip title="Swap Full Screen">
                            <Button onClick={() => onFullScreenBtn(stream.streamId, true)} type="primary" style={{borderRadius: '50%', minWidth: 35, width: 35, height: 35, padding: 0}}><ArrowsAltOutlined /></Button>
                        </Tooltip>}
                    
                    </>}
                </div>}

                <div 
                className="live-stream-button"
                style={{position: 'absolute', left: videoX, top: videoY, width: videoWidth, height: videoHeight, borderRadius: 5, transition: 'opacity 250ms', background: 'black', opacity: inPiP ? 1 : 0,
                display: 'flex', alignItems: 'flex-end', padding: 15, pointerEvents: !inPiP && "none"}}>
                    <h2 style={{margin: 0, position: 'absolute', left: 15, top: 15, color: 'white'}}><BlockOutlined /> Picture in Picture</h2>

                    <div style={{display: 'flex', alignItems: 'center', overflow: 'hidden', flex: 1}}>
                        <StreamIcon streamType={stream.streamType} style={{margin: 0, marginRight: 4, color: 'white'}} />
                        <p style={{margin: 0, fontSize: 14, overflow: 'hidden', textOverflow: 'ellipsis', color: 'white'}}>
                            {Util.isStringExists(stream.userFullName) ? stream.userFullName.split(" ")[0] : ''}
                        </p>
                        <p style={{margin: 0, marginLeft: 2, fontSize: 10, opacity: 0.65, fontStyle: 'italic', alignSelf: 'flex-end', color: 'white'}}>({stream.userIsHost ? 'host' : 'stage'})</p>
                    </div>

                    <div style={{minWidth: 15}} />

                    <Tooltip title="Leave Picture in Picture">
                        <Button onClick={() => document.exitPictureInPicture()} type="primary" style={{borderRadius: '50%', minWidth: 35, width: 35, height: 35, padding: 0}}><SwapOutlined /></Button>
                    </Tooltip>
                </div>


                {/* <ResizeObserver onResize={rect => {
                    if (videoRef.current !== null) {
                        const videoSize = getVideoSize(rect.height, rect.width, videoRef.current.videoHeight, videoRef.current.videoWidth, placement)
                        setVideoSize(videoSize);
                    }
                }}/> */}
            </div>
        </div>
    </>)
}


const getVideoSize = (layoutHeight, layoutWidth, videoHeight, videoWidth, placement) => {
    videoHeight = 9;
    videoWidth = 16;

    const vertScale = layoutHeight / videoHeight;
    const horizScale = layoutWidth / videoWidth;

    const scale = Math.min(horizScale, vertScale);

    let height = videoHeight * scale;
    let width = videoWidth * scale;

    let x = 0;
    let y = 0;
    
    switch (placement) {
        case "tl": //top left
            x = layoutWidth - width;
            y = layoutHeight - height;
            break;

        case "t":
            x = (layoutWidth / 2) - (width / 2);
            y = layoutHeight - height;
            break;

        case "tr":
            x = 0;
            y = layoutHeight - height;
            break;

        case "l":
            x = layoutWidth - width;
            y = (layoutHeight / 2) - (height / 2);;
            break;

        case "c": //center
            x = (layoutWidth / 2) - (width / 2);
            y = (layoutHeight / 2) - (height / 2);;
            break;

        case "r":
            x = 0;
            y = (layoutHeight / 2) - (height / 2);;
            break;

        case "bl":
            x = layoutWidth - width;
            y = 0;
            break;

        case "b":
            x = (layoutWidth / 2) - (width / 2);
            y = 0;
            break;
        
        case "br":
            x = 0;
            y = 0;
            break;
    }

    // width = width > height ? width : height;
    // height = width > height ? height : width;
    
    return { height, width, x, y }
}