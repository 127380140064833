import { Button, Tag } from 'antd';
import React from 'react'
import Page from '../../base/Page';
import { BLGO_POST_TAGS } from '../../BlogPostTags';
import withMediaQuery from '../../components/WithMediaQuery';
import Api from '../../session/Api';
import Util from '../../util/Util';
import Error404Page from '../Error404Page';

import CoverImage from '../../images/CoverPlaceholder.jpg';

import { 
    CommentOutlined,
    LikeOutlined,
    ShareAltOutlined,
    TwitterCircleFilled,
    HeartOutlined,
    HeartFilled,
    SyncOutlined,
    UnorderedListOutlined
} from '@ant-design/icons';
import ProfilePicture from '../../components/ProfilePicture';
import CommentsView from '../../views/comments/CommentsView';
import { EditorState, convertFromRaw } from "draft-js";
import { Editor } from 'react-draft-wysiwyg';


import {
    LinkedinShareButton,

    TwitterShareButton,
    FacebookShareButton,

    RedditShareButton,
    
    WhatsappShareButton,
    TelegramShareButton,
    EmailShareButton,

    LinkedinIcon,
    TwitterIcon,
    FacebookIcon,
    RedditIcon,
    WhatsappIcon,
    TelegramIcon,
    EmailIcon,
} from "react-share";
import SessionManager from '../../session/SessionManager';
import UIUtil from '../../util/UIUtil';
import { COMMENT_PARENT_TYPE_BLOG_POST } from '../../Constants';
import { Link } from 'react-router-dom';

class BlogPostPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            loading: true,
            blogPost: undefined,
            liking: false
        }
    }

    onPageStart() {
        if (this.state.blogPost !== undefined && this.state.pathParams.d == this.state.blogPost.date) {
            return;
        }

        this.setState({loading: true, blogPost: undefined})
        Api.getBlogPost(this.state.pathParams.t, this.state.pathParams.d, response => {
            if (response.status === true) {
                this.setState({loading: false, blogPost: response.payload})
            } else {
                this.setState({loading: false, blogPost: undefined})
            }
        })
    }

    getBlogPostTag() {
        for (const tag of BLGO_POST_TAGS) {
            if (tag.id == this.state.blogPost.tag) {
                return tag;
            }
        }

        return BLGO_POST_TAGS[0];
    }

    likePost() {
        if (this.state.liking) {
            return;
        }

        this.setState({liking: true})
        Api.toggleLikeBlogPost(this.state.blogPost.blogPostId, response => {
            if (response.status === true) {
                let post = this.state.blogPost;
                if (post.liked && !response.payload) {
                    post.likeCount = post.likeCount - 1;
                } else if (!post.liked && response.payload) {
                    post.likeCount = post.likeCount + 1;
                }
                post.liked = response.payload;

                this.setState({
                    liking: false,
                    post: post
                })
            } else {
                //alert('error');
                this.setState({liking: false})
                UIUtil.showError();
            }
        })
    }

    onCommentAdded() {
        let blogPost = this.state.blogPost;
        blogPost.commentCount = blogPost.commentCount + 1;

        this.setState({
            blogPost: blogPost
        })
    }

    onCommentRemoved() {
        let blogPost = this.state.blogPost;
        blogPost.commentCount = blogPost.commentCount - 1;

        this.setState({
            blogPost: blogPost
        })
    }

    render() {
        if (this.state.loading) {
            return <div />
        }

        if (this.state.blogPost === undefined) {
            return <Error404Page customMessage="Blog post does not exist" />
        }

        const blogPost = this.state.blogPost;
        const tag = this.getBlogPostTag();
        const URL = window.location.href;

        const contentState = blogPost.content !== null ? EditorState.createWithContent(convertFromRaw(JSON.parse(blogPost.content))) : EditorState.createEmpty();
        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                <div style={{width: this.props.renderFullWidth ? '90vw' : '50vw', paddingTop: '45px', paddingBottom: '25px'}}>
                    <div style={{display: 'flex'}}>
                        <div style={{flex: 1}}>
                            <div style={{display: 'flex', width: '100%'}}>
                                <h1 style={{fontStyle: 'bold', fontSize: 45, margin: 0, marginTop: -7, color: 'black', lineHeight: 1}}>{blogPost.title}</h1>
                            </div>
                            <div style={{display: 'flex', width: '100%', alignItems: 'center', paddingTop: 10}}>
                                <ProfilePicture userId={blogPost.userId} size={35} style={{marginRight: 7, marginTop: 5}} />
                                <div>
                                    <p style={{margin: 0, color: 'black', opacity: 1, fontSize: 14}}>{blogPost.userFullName}</p>
                                    <p style={{margin: 0, color: 'black', marginTop: -4, opacity: 0.65, fontSize: 10}}>{Util.getDateOnly(blogPost.date)}</p>
                                </div>
                                {!this.props.isSmall && <>
                                    <div style={{marginLeft: 25, marginRight: 25, width: 4, height: 4, opacity: 0.65, background: 'black', borderRadius: '50%'}} />
                                    <Tag icon={tag.icon} color={tag.color} style={{margin: 0, marginLeft: 0, opacity: 1, fontSize: 12, }}>{tag.title}</Tag>

                                    <div style={{flex: 1}} />

                                    <Link to="/blog">
                                        <Button type="primary" className="orange-button" icon={<UnorderedListOutlined />}>
                                            View Blog
                                        </Button>
                                    </Link>
                                </>}
                                
                                
                            </div>

                            {this.props.isSmall && <>
                                <div style={{display: 'flex', width: '100%', alignItems: 'center', paddingTop: 10}}>
                                    <Tag icon={tag.icon} color={tag.color} style={{margin: 0, marginLeft: 0, opacity: 1, fontSize: 12, }}>{tag.title}</Tag>

                                    <div style={{flex: 1}} />

                                    <Link to="/blog">
                                        <Button type="primary" className="orange-button" icon={<UnorderedListOutlined />}>
                                            View Blog
                                        </Button>
                                    </Link>
                                </div>
                            </>}

                            {/*<div style={{marginTop: 15, position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <div style={{width: '100%', height: 325, objectFit: 'cover', objectPosition: 'top', 
                                borderRadius: 5, backgroundImage: 'url("' + Api.getBlogPostThumbnail(blogPost.blogPostId) + '")',
                                backgroundSize: 'cover', backgroundPosition: 'center',
                                filter: 'blur(8px)', WebkitFilter: 'blur(8px)', position: 'absolute', top: 0, left: 0}} />

                                <img src={Api.getBlogPostThumbnail(blogPost.blogPostId)} style={{width: '100%', marginTop: 0, height: 300, 
                                objectFit: 'contain', //objectPosition: 'top', 
                                borderRadius: 5, zIndex: 2}}
                                onError={e => {
                                    e.target.onerror = null;
                                    e.target.src = CoverImage;
                                }}/>
                            </div>*/}


                            <img src={Api.getBlogPostThumbnail(blogPost.blogPostId)} style={{width: '100%', marginTop: 15, //height: 325, 
                            //objectFit: 'cover', objectPosition: 'top', 
                            objectFit: 'contain',
                            borderRadius: 5,}}
                            onError={e => {
                                e.target.onerror = null;
                                e.target.src = CoverImage;
                            }}
                            />                            


                            <div style={{marginTop: 15, maxWidth: this.props.renderFullWidth ? '90vw' : '60vw'}}>
                                <Editor toolbarHidden editorState={contentState} readOnly={true} />
                            </div>

                            
                            <div style={{height: 1, marginTop: 45, marginBottom: 15, width: '100%', background: 'black', opacity: 0.15}} /> 
                            <h2><ShareAltOutlined style={{marginRight: 5}} /> Share</h2>
                            <div style={{display: 'flex', alignItems: 'center', marginTop: 5}}>
                                <LinkedinShareButton url={URL} source={URL} title={this.state.blogPost.title} summary={this.state.blogPost.preview}>
                                    <LinkedinIcon size={32} round={true} />
                                </LinkedinShareButton>
                                
                                <div style={{width: 15}} />

                                <TwitterShareButton url={URL} title={this.state.blogPost.title} hashtags={["learnerix"]}>
                                    <TwitterIcon size={32} round={true} />
                                </TwitterShareButton>

                                <div style={{width: 15}} />

                                <FacebookShareButton url={URL} quote={this.state.blogPost.title} hashtag="#learnerix">
                                    <FacebookIcon size={32} round={true} />
                                </FacebookShareButton>

                                <div style={{width: 15}} />

                                <RedditShareButton url={URL} title={this.state.blogPost.title}>
                                    <RedditIcon size={32} round={true} />
                                </RedditShareButton>

                                <div style={{width: 25}} />

                                <WhatsappShareButton url={URL} title={this.state.blogPost.title}>
                                    <WhatsappIcon size={32} round={true} />
                                </WhatsappShareButton>

                                <div style={{width: 15}} />

                                <TelegramShareButton url={URL} title={this.state.blogPost.title}>
                                    <TelegramIcon size={32} round={true} />
                                </TelegramShareButton>

                                <div style={{width: 25}} />

                                <EmailShareButton url={URL} subject={this.state.blogPost.title}>
                                    <EmailIcon size={32} round={true} />
                                </EmailShareButton>
                            </div>

                            <div style={{height: 1, marginTop: 25, marginBottom: 15, width: '100%', background: 'black', opacity: 0.15}} /> 
                            <h2>{blogPost.likeCount} Likes</h2>
                            {SessionManager.isLoggedIn() &&
                            <Tag size="small" style={{cursor: 'pointer'}} 
                            loading={this.state.liking} onClick={this.likePost.bind(this)}
                            icon={this.state.liking ? <SyncOutlined spin /> : (blogPost.liked ? <HeartFilled /> : <HeartOutlined />)} 
                            color={blogPost.liked ? "#f26622" : "#4e2e89"}>{blogPost.liked ? 'Liked' : 'Like'}</Tag>}

                            <div style={{height: 1, marginTop: 25, marginBottom: 15, width: '100%', background: 'black', opacity: 0.15}} /> 
                            <h2>{blogPost.commentCount} Comments</h2>
                            <CommentsView parentOwner={blogPost.userId} customParentType={COMMENT_PARENT_TYPE_BLOG_POST} customParentId={blogPost.blogPostId} windowMode 
                            onCommentRemoved={this.onCommentRemoved.bind(this)}
                            onCommentAdded={this.onCommentAdded.bind(this)} /> 
                        </div>
                    </div>
                </div>
                
            </div>
        )
    }

}

export default withMediaQuery(BlogPostPage);