
import React from 'react'
import Page from '../base/Page';
import withMediaQuery from '../components/WithMediaQuery';

import { 
    PlusOutlined,
    UnorderedListOutlined,
    BookOutlined,
    AlertOutlined,
    SearchOutlined,
    UserOutlined,
    ClusterOutlined
} from '@ant-design/icons';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import HorizontalCoursesView, { HORIZONTAL_MODE_TOP_COURSES, HORIZONTAL_MODE_SEARCH_COURSES } from '../views/courses/HorizontalCoursesView';
import HorizontalLiveSessionsView, { HORIZONTAL_MODE_TOP_LIVE_SESSIONS, HORIZONTAL_MODE_SEARCH_LIVE_SESSIONS } from '../views/live-sessions/HorizontalLiveSessionsView';
import PostsView from '../views/posts/PostsView';
import Util from '../util/Util';
import Error404Page from './Error404Page';
import UserList from '../components/UserList';
import SessionManager from '../session/SessionManager';

class SearchPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
        }
    }

    isTutorSearch() {
        return this.state.pathParams.tutorValue !== undefined;
    }

    getSearchValue() {
        return this.state.pathParams.value;
    }

    getTutorSearchValue() {
        return this.state.pathParams.tutorValue;
    }

    onPageStart() {

    }
    
    render() {
        if (!this.isTutorSearch() && !Util.isStringExists(this.getSearchValue())) {
            return <Error404Page />
        }

        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div style={{width: this.props.renderFullWidth ? '90vw' : '75vw', paddingTop: '25px', paddingBottom: '25px'}}>
                    <h1 style={{height: '22px'}}><SearchOutlined /> Search results for '{this.isTutorSearch() ? this.getTutorSearchValue() : this.getSearchValue()}'</h1>
                    <div style={{height: '25px'}} />

                    {!SessionManager.isLoggedInAsRelationManager() && <>
                        {!this.isTutorSearch() && <>
                        <HorizontalCoursesView hideIfEmpty mode={HORIZONTAL_MODE_SEARCH_COURSES} searchValue={this.getSearchValue()}>
                            <div style={{display: 'flex', alignItems: 'center', marginTop: '25px', width: '100%', marginBottom: '20px'}}>
                                <BookOutlined style={{ fontSize: '20px', color: 'black' }} />
                                &nbsp;&nbsp;&nbsp;
                                <h2 style={{height: '22px'}}>Courses</h2>
                                <div style={{flex: '1'}} />
                                <Button type="link" className="orange-link">
                                    <Link to="/all-courses">
                                        View All Courses
                                    </Link>
                                </Button>
                            </div>
                        </HorizontalCoursesView>

                        <HorizontalLiveSessionsView hideIfEmpty mode={HORIZONTAL_MODE_SEARCH_LIVE_SESSIONS} searchValue={this.getSearchValue()}>
                            <div style={{display: 'flex', alignItems: 'center', marginTop: '25px', width: '100%', marginBottom: '20px'}}>
                                <AlertOutlined style={{ fontSize: '20px', color: 'black' }} />
                                &nbsp;&nbsp;&nbsp;
                                <h2 style={{height: '22px'}}>Live Sessions</h2>
                                <div style={{flex: '1'}} />
                                <Button type="link" className="orange-link">
                                    <Link to="/all-live-sessions">
                                        View All Live Sessions
                                    </Link>
                                </Button>
                            </div>
                        </HorizontalLiveSessionsView> </>}
                    </>}

                    
                    <UserList hideIfEmpty searchValue={this.isTutorSearch() ? this.getTutorSearchValue() : this.getSearchValue()}>
                        <div style={{display: 'flex', alignItems: 'center', marginTop: '25px', width: '100%', marginBottom: '20px'}}>
                            <UserOutlined style={{ fontSize: '20px', color: 'black' }} />
                            &nbsp;&nbsp;&nbsp;
                            <h2 style={{height: '22px'}}>Tutors</h2>
                            <div style={{flex: '1'}} />
                            <Button type="link" className="orange-link">
                                <Link to="/all-tutors">
                                    View All Tutors
                                </Link>
                            </Button>
                        </div>
                    </UserList>

                    <UserList hideIfEmpty organizationSearchValue={this.getSearchValue()} horizontalMode>
                        <div style={{display: 'flex', alignItems: 'center', marginTop: '25px', width: '100%', marginBottom: '20px'}}>
                            <ClusterOutlined style={{ fontSize: '20px', color: 'black' }} />
                            &nbsp;&nbsp;&nbsp;
                            <h2 style={{height: '22px'}}>Organizations</h2>
                            <div style={{flex: '1'}} />
                            <Button type="link" className="orange-link">
                                <Link to="/all-organizations">
                                    View All Organizations
                                </Link>
                            </Button>
                        </div>
                    </UserList>

                    <br />
                </div>
            </div>
        )
    }

}

export default withMediaQuery(SearchPage);