import React from 'react';

import NoImage from '../../images/NoImage.png';
import ProductImagePlaceholder from '../../images/ProductImagePlaceholder.png';
import { Button, Tag, Badge, Tooltip } from 'antd';

import {  
    InfoCircleOutlined, 
    UnorderedListOutlined,
    EditOutlined,
    QuestionCircleOutlined,
    LinkOutlined,
    MessageOutlined,
    CalendarOutlined,
    AlertOutlined,
    EyeInvisibleOutlined,
    LockOutlined,
    ClusterOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Api from '../../session/Api';
import Util from '../../util/Util';
import LiveStreamScheduleState from '../../components/LiveStreamScheduleState';

export default function TutorLiveSessionItem(props) {
    const liveSession = props.liveSession;
    const [inProgress, setInProgress] = React.useState(liveSession.upcomingState.inProgress);

    return (
        <div style={{width: '100%', borderRadius: '15px', padding: '15px', boxShadow: "0px 0px 25px 0px rgba(0, 0, 0, 0.2)"}}>
            <div style={{display: 'flex'}}>
                <Link to={'/my-live-session/' + liveSession.id + '?tab=schedule'} style={{width: '20%', height: '115px'}}>
                    <img src={Api.getLiveSessionThumbnail(liveSession.id)} style={{width: '100%', height: '115px', objectFit: 'cover', borderRadius: '15px'}} onError={e => {
                        e.target.onerror = null;
                        e.target.src = ProductImagePlaceholder;
                    }} />
                </Link>
                <div style={{marginLeft: '25px', flex: 1, minWidth: 1}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <Link to={'/my-live-session/' + liveSession.id + '?tab=schedule'}><h2 style={{height: '25px'}}>{liveSession.title}</h2></Link>

                        {//Util.isStringExists(liveSession.courseId) && <>
                        Util.isStringExists(liveSession.hasConnectedCourse) && <>
                            <Link to={"/my-course/" + liveSession.courseId} style={{marginLeft: '15px'}}>{liveSession.courseTitle}</Link>
                        </>}

                        {/* {(liveSession.unlisted) && <Tooltip title="Unlisted"><Button style={{cursor: 'default'}} type="link"><EyeInvisibleOutlined /></Button></Tooltip>} */}
                        {(liveSession.organizationPrivateAccess) && <Tooltip title="Organization"><Button style={{cursor: 'default'}} type="link"><LockOutlined /> <ClusterOutlined /></Button></Tooltip>}
                        {(!liveSession.organizationPrivateAccess && liveSession.privateAccess) && <Tooltip title="Private"><Button style={{cursor: 'default'}} type="link"><LockOutlined /></Button></Tooltip>}
                        {(!liveSession.organizationPrivateAccess && !liveSession.privateAccess && liveSession.unlisted) && <Tooltip title="Unlisted"><Button style={{cursor: 'default'}} type="link"><EyeInvisibleOutlined /></Button></Tooltip>}

                        <div style={{flex: '1'}} />
                        
                        {/*<Badge count={20} overflowCount={9}>
                            <Tag icon={<MessageOutlined />} color="blue">Messages</Tag>
                        </Badge>
                        <div style={{width: '25px'}} />
                        <Badge count={20} overflowCount={9}>
                            <Tag icon={<SnippetsOutlined />} color="blue">Requests</Tag>
                        </Badge>
                        <Button>Live Session in progress</Button>*/}
                        <LiveStreamScheduleState state={liveSession.upcomingState} onStateUpdate={() => setInProgress(true)}/>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{borderRadius: '50%', marginRight: '10px', background: liveSession.published ? '#009900' : '#faad14', width: '10px', height: '10px'}} />
                        <h4 style={{height: '16px'}}>{liveSession.published ? 'Published' : 'Unpublished'}</h4>
                    </div>
                    <br />
                    {Util.isNumberExist(liveSession.learnerCount) && 
                    <p style={{paddingTop: '5px', fontSize: '12px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', flex: '1'}}>({liveSession.learnerCount} Learner{liveSession.learnerCount > 1 && 's'})</p>}
                    {/*<p style={{paddingTop: '5px', fontSize: '12px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', flex: '1'}}>(5 Learners)</p>*/}
                    {/*<p style={{paddingTop: '5px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', flex: '1'}}></p>*/}
                </div>
            </div>

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '15px', width: '100%'}}>
                <div style={{flex: 1}} />

                <Button type="link" disabled={!liveSession.published}>
                    <Link to={'/live-session-sale/' + liveSession.id}><LinkOutlined /> Store Link</Link>
                </Button>

                {props.organization === undefined &&
                <Button type="link">
                    <Link to={'/my-live-session/' + liveSession.id + '?tab=details'}><InfoCircleOutlined /> Details</Link>
                </Button>}
                
                <Button type="link">
                    <Link to={'/my-live-session/' + liveSession.id + '?tab=announcements'}>
                        <UnorderedListOutlined /> Announcements
                    </Link>
                </Button>
                <Button type="link">
                    <Link to={'/my-live-session/' + liveSession.id + '?tab=schedule'}>
                        <CalendarOutlined /> Schedule
                    </Link>                    
                </Button>
                {//(inProgress || ((liveSession.upcomingState.startDate - (liveSession.loungeDuration * 60000)) < new Date().getTime())) &&
                (inProgress) &&
                <Button type="primary" className="red-button" style={{marginLeft: '15px'}}>
                    <Link to={Util.getLiveStreamPath(liveSession.upcomingState.liveStreamId)}>
                        <AlertOutlined /> {inProgress ? 'Join Live Stream' : 'Join Live Stream Lounge'}
                    </Link>
                </Button>}
            </div>
        </div>
    )
}