import React from 'react';
import Window from '../../../base/Window';
import Error404Page from '../../../pages/Error404Page';
import LogoWhite from '../../../images/LogoWhite.png'
import LogoWhiteSquare from '../../../images/LogoWhiteSquare.png'
import SessionManager from '../../../session/SessionManager'

import {
    BrowserRouter as Router,
    Route,
    Switch,
    Link,
    withRouter
} from 'react-router-dom'

import { Layout, Menu, Popover, Button } from 'antd';
import { Row, Col, Space } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  AuditOutlined,
  UploadOutlined,
  DashboardOutlined,
  BookOutlined,
  TeamOutlined,
  CaretDownOutlined,
  UnorderedListOutlined,
  ClusterOutlined
} from '@ant-design/icons';
import './admin-window.css';
import { ACCOUNT_ROLE_ADMIN, ACCOUNT_SCOPE_BACKEND } from '../../../Constants';
import AdminAnnouncementsPages from '../../../pages/AdminAnnouncementsPage';
import UserOrganizationsPage from '../../../admin/pages/UserOrganizationsPage';

const { SubMenu } = Menu;
const { Header, Sider, Content } = Layout;

class AdminWindow extends Window {
    constructor(props) {
        super(props);

        this.state = {
            menuCollapsed: false
        }
    }

    toggleMenu() {
        this.setState({
            menuCollapsed: !this.state.menuCollapsed
        })
    }

    getLayout() {
        return (
            <Layout style={{minHeight: '100vh'}}>
                <Sider trigger={null} collapsible collapsed={this.state.menuCollapsed} width='250'>
                    <div style={{margin: '16px'}}>
                        <img className="logo" src={this.state.menuCollapsed ? LogoWhiteSquare : LogoWhite} />
                    </div>
                    <Menu theme="dark" mode="inline" defaultSelectedKeys={[this.props.location.pathname]} >
                        <Menu.Item key="/admin" icon={<DashboardOutlined />}>
                            <Link to="/admin" />
                            Dashboard
                        </Menu.Item>

                        <Menu.Item key="/admin/announcements" icon={<UnorderedListOutlined />}>
                            <Link to="/admin/announcements" />
                            Announcements
                        </Menu.Item>
                        
                        <SubMenu key="sub1" icon={<TeamOutlined />} title="User Management">
                            <Menu.Item key="2" icon={<BookOutlined />}>
                                <Link to="/admin/learners/" />
                                Learners
                            </Menu.Item>
                            <Menu.Item key="3" icon={<AuditOutlined />}>
                                <Link to="/admin/tutors/" />
                                Tutors
                            </Menu.Item>
                            <Menu.Item key="5" icon={<ClusterOutlined />}>
                                <Link to="/admin/organizations/" />
                                Organizations
                            </Menu.Item>
                            <Menu.Item key="4" icon={<UserOutlined />}>
                                <Link to="/admin/employees/" />
                                Employees
                            </Menu.Item>
                        </SubMenu>
                        <Menu.Item key="6" icon={<UploadOutlined />} >
                            <Link to="/admin/work" />
                            Test
                        </Menu.Item>
                    </Menu>
                </Sider>
                <Layout className="site-layout">
                    <Header className="site-layout-background" style={{ padding: 0 }}>
                        <Row>
                            <Col span={8}>
                                <Space>
                                    {React.createElement(this.state.menuCollapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                                        className: 'trigger',
                                        onClick: this.toggleMenu.bind(this),
                                    })}
                                    <Switch>
                                        <Route exact path="/admin/"><h1>Dashboard</h1></Route>
                                        <Route exact path="/admin/announcements"><h1>Announcements</h1></Route>
                                        <Route exact path="/admin/students"><h1>Students</h1></Route>
                                        <Route exact path="/admin/tutors"><h1>Tutors</h1></Route>
                                        <Route exact path="/admin/employees"><h1>Employees</h1></Route>
                                        <Route exact path="/admin/organizations"><h1>Organizations</h1></Route>
                                        <Route><h1>Page not found</h1></Route>
                                    </Switch>
                                </Space>
                            </Col>
                            <Col flex="auto" style={{display: 'flex', justifyContent:'flex-end', alignItems:'flex-end', paddingRight: '48px'}}>
                                <Popover placement="bottomRight" content={
                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                        {/*<Button size="large">
                                            {SessionManager.isLoggedInAsTutor() ? <Link to="/my-profile"><UserOutlined /> Tutor Profile</Link> : <><UserOutlined /> Profile</>}
                                        </Button>
                                        <Button size="large" style={{marginTop: '15px'}}><SettingOutlined /> Account Settings</Button>*/}
                                        <h4 style={{width: '175px', textAlign: 'center'}}>{SessionManager.getAccount().fullName}</h4>
                                        <Button type="primary" danger style={{width: '175px', marginTop: '15px'}} onClick={() => {
                                            SessionManager.logout();
                                            window.location.replace('/');
                                        }}>Logout</Button>
                                    </div>
                                } trigger="hover">
                                    <h4>{SessionManager.getAccount().fullName} <CaretDownOutlined style={{marginLeft: '15px'}} /></h4>
                                </Popover>
                            </Col>
                        </Row>
                    </Header>
                    <Content
                        className="site-layout-background"
                        style={{
                        margin: '24px 16px',
                        padding: 24,
                        minHeight: 280,
                        }}>
                            <Switch>
                                {/* Pages */}
                                {/*<Route exact path="/admin/work" component={PageTest} />*/}

                                <Route exact path="/admin/announcements" component={AdminAnnouncementsPages} />

                                <Route exact path="/admin/organizations" component={UserOrganizationsPage} />

                                <Route component={Error404Page} />
                            </Switch>
                    </Content>
                </Layout>
            </Layout>
        )
    }

    isLockBehindAuth() {
        return true;
    }

    getScope() {
        return ACCOUNT_SCOPE_BACKEND;
    }

    getRole() {
        return ACCOUNT_ROLE_ADMIN;
    }

}

export default withRouter(AdminWindow);