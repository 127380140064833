import { Tabs } from 'antd';
import React from 'react'
import Page from '../../base/Page';
import withMediaQuery from '../../components/WithMediaQuery';
import { STATEMENTS_DATA_SOURCE_ALL, STATEMENTS_DATA_SOURCE_EARNINGS, STATEMENTS_DATA_SOURCE_PURCHASES, STATEMENTS_DATA_SOURCE_WITHDRAWALS } from '../../Constants';
import AllStatementsTableView from '../../views/accounting/AllStatementsTableView';

import {
    DollarCircleFilled,
    BankFilled,
    HistoryOutlined,
    LineChartOutlined,
    CreditCardFilled,
    HourglassOutlined,
    CalendarFilled,
    BulbFilled,
    CloseCircleOutlined,
    FieldTimeOutlined,
    ProfileFilled,
    TableOutlined,
    ClockCircleOutlined,
    QuestionCircleOutlined,
    UnorderedListOutlined,
    DownloadOutlined,
    DownCircleFilled,
    LoadingOutlined,
    MailFilled
} from '@ant-design/icons'

class AllWithdrawsPage extends Page {

    render() {
        return (
            <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                <div style={{width: this.props.isSmall ? '90vw' : '75vw', paddingTop: '25px', paddingBottom: '25px'}}>
                    <h2 style={{height: '25px'}}><DownCircleFilled /> Withdraw Requests</h2>
                    <AllStatementsTableView key="withdrawals-table" loadInProgressOnly dataSource={STATEMENTS_DATA_SOURCE_WITHDRAWALS} showWithdrawActions />
                </div>
            </div>
        )
    }

}

export default withMediaQuery(AllWithdrawsPage);