import React from 'react'
import { PRESENCE_AVAILABLE, PRESENCE_AWAY, PRESENCE_DO_NOT_DISTURB, PRESENCE_OFFLINE } from '../Constants'
import PresenceManager from '../session/PresenceManager';

const getPresenceColor = presence => {
    switch (presence) {
        case PRESENCE_AVAILABLE:
            return "green";
        case PRESENCE_AWAY:
            return "#fbc02d";
        case PRESENCE_DO_NOT_DISTURB:
            return "red";
        case PRESENCE_OFFLINE:
            return "#8a8a8a"
        default:
            return 'white'
    }
}

const getPresenceText = presence => {
    switch (presence) {
        case PRESENCE_AVAILABLE:
            return "Online";
        case PRESENCE_AWAY:
            return "Away";
        case PRESENCE_DO_NOT_DISTURB:
            return "Do not disturb";
        case PRESENCE_OFFLINE:
            return "Offline"
        default:
            return ''
    }
}

export default class PresenceIndicator extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            presence: undefined
        }
    }

    componentWillReceiveProps(nextProps) {
        PresenceManager.getPresence(nextProps.userId, this.presenceUpdateListener);
    }

    componentDidMount() {
        if (this.props.realtime) {
            PresenceManager.registerListener(this.props.userId, this.presenceUpdateListener);
        } else {
            PresenceManager.getPresence(this.props.userId, this.presenceUpdateListener);
        }
    }

    componentWillUnmount() {
        if (this.props.realtime) {
            PresenceManager.unregisterListener(this.props.userId, this.presenceUpdateListener);
        }
    }

    onPresenceUpdate(presence) {
        this.setState({presence: presence})
    }
    presenceUpdateListener = this.onPresenceUpdate.bind(this);

    render() {
        if (this.state.presence !== undefined) {
            //return <div style={{height: '12px', width: '12px', background: getPresenceColor(this.state.presence), marginRight: '5px', borderRadius: '50%', ...this.props.style}} />
            if (this.props.textMode) {
                return (
                    <div style={{display: 'flex', alignItems: 'center', ...this.props.style}}>
                        <div style={{width: '15px', height: '15px', background: getPresenceColor(this.state.presence), marginRight: '5px', borderRadius: '50%'}} />
                        <p style={{height: '7px'}}>{getPresenceText(this.state.presence)}</p>
                    </div>
                )
            } else {
                return <div style={{paddingTop: '25%', width: '25%', background: getPresenceColor(this.state.presence), marginRight: '5px', borderRadius: '50%', ...this.props.style}} />
            }
        } else {
            return null;
        }
    }

}