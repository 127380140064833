import Api from "../../session/Api";
import SocketSession from "../../session/SocketSession";
import { SOCKET_EVENT_WEB_RTC_SIGNALLING } from "../../Constants";

import WebRTCSession, { WEB_RTC_MESSAGE_TYPE_OFFER, WEB_RTC_MESSAGE_TYPE_CANDIDATE } from './WebRTCSession'

class WebRTCBroadcaster extends WebRTCSession {

    createConnection() {
        super.createConnection();
    }

    addStream(stream) {
        stream.getTracks().forEach(track => {
            this.connection.addTrack(track);
        });
    }

}

export default WebRTCBroadcaster;