import React from 'react'
import { COURSE_SECTION_TYPE_CUSTOM, COURSE_SECTION_TYPE_LECTURE, COURSE_SECTION_TYPE_HELP_SESSION, COURSE_SECTION_TYPE_LIVE_SESSION, PAGINATION_PAGE_CONTENT_COUNT, COURSE_ITEM_TYPE_LECTURE_VIDEO, COURSE_ITEM_TYPE_LECTURE_AUDIO, COURSE_ITEM_TYPE_FILE, COURSE_ITEM_TYPE_QUIZ, COURSE_ITEM_TYPE_HELP_SESSION_LIVE, NO_PAGE_TOKEN } from '../../Constants';
import { PageHeader, Button, Input, Upload, Empty, Spin } from 'antd'

import { 
    PlusOutlined,
    AudioOutlined,
    VideoCameraOutlined,
    OrderedListOutlined,
    FileAddOutlined,
    ScheduleOutlined,
    FontColorsOutlined,
    VideoCameraAddOutlined,
    InboxOutlined,
    QuestionOutlined,
    AlertOutlined
} from '@ant-design/icons';
import UploadWrapper from '../../components/UploadWrapper';
import Modal from 'antd/lib/modal/Modal';
import Api from '../../session/Api';

import PostsView from '../posts/PostsView';

import NoImage from '../../images/NoImage.png';
import CourseItemItem from './CourseItemItem';
import UIUtil from '../../util/UIUtil';
import InfiniteScroll from 'react-infinite-scroller';
import AudioWaveformDisplay from '../../components/AudioWaveformDisplay';

import FileCard from '../../components/FileCard'
import Util from '../../util/Util';

import Lightbox from '../../components/media-list-viewer/react-image-lightbox';
import QuizDesigner from '../quiz/QuizDesigner';
import QuizViewer from '../quiz/QuizViewer';
import TutorMyLiveSessionsPage from '../../pages/TutorMyLiveSessionsPage';
import StudentMyLiveSessionsPage from '../../pages/StudentMyLiveSessionsPage';
import { withRouter } from 'react-router-dom';
import LiveStreamRecordingPlayer from '../../livestream/components/LiveStreamRecordingPlayer';
import ProgressAwareMedia from '../../components/ProgressAwareMedia';
import QuizSubmissionHistoryDialog from '../../components/QuizSubmissionHistoryDialog';
import CourseLearnerAnalytics from './CourseLearnerAnalytics';
import { stringify } from 'query-string';
import LiveStreamWindow from '../../livestream2/windows/LiveStreamWindow';
import CourseLiveStreamsView from './CourseLiveStreamsView';

import FileViewer from 'react-file-viewer';

const FormPadding = () => {
    return ( <> <br /> <br /> </> )
}

class CourseSectionView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            videoAttachment: undefined,
            videoAttachmentUrl: undefined,

            audioAttachment: undefined,
            audioAttachmentUrl: undefined,

            fileAttachment: undefined,

            loadingAttachment: true,
            attachmentDuration: 0,
            attachmentFormat: "",
            attachmentSize: 0,

            createQuiz: false,
            
            newCourseItemThumbnail: undefined,
            newCourseItemTitle: "",
            newCourseItemDesc: "",
            creatingNewCourseItem: false,

            updateCourseItemThumbnail: undefined,
            updateCourseItemTitle: "",
            updateCourseItemDesc: "",
            updateCourse: undefined,
            updatingCourseItem: false,

            selectedCourseItem: undefined,

            items: [],
            page: NO_PAGE_TOKEN,
            hasMore: true,
            loadingItems: false,

            selectedQuizSubmissionHistoryItem: undefined,

            selectedCourseItemLearnerAnalytics: undefined
        }
    }

    getNextCourseItems() {
        if (this.state.loadingItems) {
            return;
        }

        this.setState({
            loadingItems: true
        })

        //list error
        Api.getCourseItems(this.props.section.id, this.state.page, response => {
            if (response.status === true) {
                let items = this.state.items;
                items.push(...response.payload.items);

                this.setState({
                    items: items,
                    page: response.payload.token,
                    hasMore: Util.isStringExists(response.payload.token),
                    loadingItems: false
                })
            } else {
                //alert('errowr');
            }
        });
    }

    newCourseItem(type, thumbnail, attachment, successListener) {
        this.setState({creatingNewCourseItem: true})
        Api.createCourseItem({
            courseSectionId: this.props.section.id,
            title: this.state.newCourseItemTitle,
            description: this.state.newCourseItemDesc,
            type: type,
            duration: this.state.attachmentDuration,
            format: this.state.attachmentFormat,
            size: this.state.attachmentSize
        }, thumbnail, attachment, response => {
            if (response.status === true) {
                this.state.items.unshift(response.payload);

                UIUtil.showSuccess();
                successListener(response.payload);
            } else {
                UIUtil.showError();
            }
            this.setState({creatingNewCourseItem: false})
        });
    }

    updateCourseItem(thumbnail, successListener) {
        this.setState({updatingCourseItem: true})
        Api.updateCourseItem(this.state.updateCourse.id, this.state.updateCourseItemTitle, this.state.updateCourseItemDesc, thumbnail, response => {
            if (response.status === true) {
                const index = this.state.items.indexOf(this.state.updateCourse);
                if (index != -1) {
                    this.state.items[index] = response.payload;
                }
                if (thumbnail !== undefined) {
                    this.setState({
                        updatingCourseItem: false,
                        [response.payload.id + "-thumbnail-suffix"]: new Date().getTime()
                    })
                }
                successListener();
            } else {
                UIUtil.showError();
                this.setState({updatingCourseItem: false})
            }
        })
    }

    getCourseItems() {
        let items = this.state.items;
        if (this.props.studentMode) {
            if (!this.props.showHiddenItems) {
                items = items.filter(item => !item.hidden)
            }
        }
        return items;
    }

    getSelectedCourseFileViewer() {
        return (
            <div style={{height: '95%', width: '95%', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div style={{height: '100%', width: '100%',}}>
                    <FileViewer
                    fileType={this.state.selectedCourseItem.format}
                    filePath={Api.getCourseItemAttachment(this.state.selectedCourseItem.id)}
                    // errorComponent={(props) => (
                    //     <Button>hi</Button>
                    // )}
                    // onError={() => {}} 
                    />
                </div>
            </div>
        )
    }

    getSelectedCourseItemComponent() {
        const courseItemId = this.state.selectedCourseItem.id;
        let initalPos;
        if (this.state.selectedCourseItem.lectureProgress != null && this.state.selectedCourseItem.lectureProgress.latestPointPercent < 100) {
            initalPos = this.state.selectedCourseItem.lectureProgress.latestPoint;
        } else {
            initalPos = 0;
        }

        const courseItem = this.state.selectedCourseItem;

        switch (Util.safeGetProperty(this.state.selectedCourseItem, 'type', -1)) {
            case COURSE_ITEM_TYPE_LECTURE_VIDEO:
                //return <video autoPlay controls src={Api.getCourseItemAttachment(this.state.selectedCourseItem.id)} style={{maxHeight: '95%', maxWidth: '95%', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)',}} />;
                return <ProgressAwareMedia initialPos={initalPos} video trackCourseLectureId={this.state.selectedCourseItem.id} 
                progressListener={(progress, atEnd) => Api.saveCourseLecturePoint(courseItemId, progress, atEnd, response => {
                    if (response.status === true) {
                        //courseItem.lectureProgress.latestPointPercent = 88;
                        courseItem.lectureProgress = response.payload;
                        this.setState({})

                        if (this.props.onUpdateProgress !== undefined) {
                            this.props.onUpdateProgress();
                        }
                    }
                })} 
                autoPlay controls src={Api.getCourseItemAttachment(this.state.selectedCourseItem.id)} style={{maxHeight: '95%', maxWidth: '95%', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)',}} />;
            case COURSE_ITEM_TYPE_LECTURE_AUDIO:
                //return <audio autoPlay controls src={Api.getCourseItemAttachment(this.state.selectedCourseItem.id)} style={{maxHeight: '95%', maxWidth: '95%', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)',}} />;
                return <ProgressAwareMedia initialPos={initalPos} trackCourseLectureId={this.state.selectedCourseItem.id} 
                progressListener={(progress, atEnd) => Api.saveCourseLecturePoint(courseItemId, progress, atEnd, response => {
                    if (response.status === true) {
                        //courseItem.lectureProgress.latestPointPercent = 88;
                        courseItem.lectureProgress = response.payload;
                        this.setState({})

                        if (this.props.onUpdateProgress !== undefined) {
                            this.props.onUpdateProgress();
                        }
                    }
                })} 
                autoPlay controls src={Api.getCourseItemAttachment(this.state.selectedCourseItem.id)} style={{maxHeight: '95%', maxWidth: '95%', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)',}} />;
            case -1:
                return <> </>
        }
    }

    getCourseButtons() {
        if (this.props.studentMode) {
            return [];
        }

        switch (this.props.section.type) {
            case COURSE_SECTION_TYPE_CUSTOM:
                return [
                    <UploadWrapper onChange={e => this.setState({fileAttachment: e.target.files[0], attachmentFormat: Util.getFileExt(e.target.files[0]), attachmentSize: e.target.files[0].size})}>
                        <Button type="primary"><FileAddOutlined /> Upload File</Button>
                    </UploadWrapper>,
                    <Button type="primary" onClick={() => this.setState({createQuiz: true})}><OrderedListOutlined /> Create Quiz</Button>,
                    //<Button type="primary"><ScheduleOutlined /> Create Flashcard</Button>,
                    //<Button type="primary"><FontColorsOutlined /> Create Spell Check</Button>
                ]
                
                break;
            case COURSE_SECTION_TYPE_LECTURE:
                return [
                    <UploadWrapper accept="audio/mp3" onChange={e => this.setState({audioAttachment: e.target.files[0], audioAttachmentUrl: URL.createObjectURL(e.target.files[0]), loadingAttachment: true})}>
                        <Button type="primary"><AudioOutlined /> Upload Audio</Button>
                    </UploadWrapper>,
                    <UploadWrapper accept="video/mp4" onChange={e => this.setState({videoAttachment: e.target.files[0], videoAttachmentUrl: URL.createObjectURL(e.target.files[0]), loadingAttachment: true})}>
                        <Button type="primary"><VideoCameraAddOutlined /> Upload Video</Button>
                    </UploadWrapper>
                ]

            case COURSE_SECTION_TYPE_HELP_SESSION:
                return [];

            case COURSE_SECTION_TYPE_LIVE_SESSION:
                return [
                    //<Button type="primary" onClick={() => this.setState({createLiveSession: true})}><PlusOutlined /> Create Live Stream</Button>
                ]

            default:
                return [];
        }

    }

    render() {
        const section = this.props.section;

        let title;
        switch (section.type) {
            case COURSE_SECTION_TYPE_CUSTOM:
                title = section.title;
                break;
            case COURSE_SECTION_TYPE_LECTURE:
                title = <><VideoCameraOutlined /> &nbsp; {section.title}</>;
                break;
            case COURSE_SECTION_TYPE_HELP_SESSION:
                //title = <><QuestionOutlined /> &nbsp; {section.title}</>;
                title = <><QuestionOutlined /> &nbsp; {'Recorded Help Session Lives'}</>;
                break;
            case COURSE_SECTION_TYPE_LIVE_SESSION:
                //title = <><AlertOutlined /> &nbsp; {section.title}</>;
                title = <><AlertOutlined /> &nbsp; Live Streams</>;
                break;
            default:
                return (<> </>);
        }

        return (
            <div>
                {Util.safeGetProperty(this.state, "selectedCourseItem", {}).type == COURSE_ITEM_TYPE_QUIZ ? (<>
                    {this.props.studentMode ? (
                        <QuizViewer courseItem={this.state.selectedCourseItem} onBack={() => this.setState({selectedCourseItem: undefined,
                            items: [],
                            page: NO_PAGE_TOKEN,
                            hasMore: true,
                            loadingItems: false,
                        })} onSubmit={() => {
                            if (this.props.onUpdateProgress !== undefined) {
                                this.props.onUpdateProgress();
                            }
                        }} />
                    ) : (
                        <QuizDesigner courseItem={this.state.selectedCourseItem} onBack={() => this.setState({selectedCourseItem: undefined})} />
                    )}
                </>) : (<>
                    <PageHeader
                    title={title}
                    extra={this.getCourseButtons()} />

                    {section.type === COURSE_SECTION_TYPE_LIVE_SESSION ? (
                        <CourseLiveStreamsView courseId={this.props.section.courseId} studentMode={this.props.studentMode} {...(this.props.studentMode ? {
                            createLiveSession: this.state.createLiveSession,
                            onCancel: () => this.setState({createLiveSession: false})
                        } : {})} />
                        //this.props.studentMode ? <StudentMyLiveSessionsPage showCourseLiveSessions courseId={this.props.section.courseId} /> : 
                        // <TutorMyLiveSessionsPage showCourseLiveSessions createLiveSession={this.state.createLiveSession} onCancel={() => this.setState({createLiveSession: false})} courseId={this.props.section.courseId} />
                    ) : (
                        <div style={{paddingRight: '25px', marginTop: '10px'}}>
                            <InfiniteScroll
                                loadMore={this.getNextCourseItems.bind(this)}
                                hasMore={this.state.hasMore}
                                threshold={750}
                                loader={
                                    <div style={{display: 'flex', justifyContent: 'center', marginTop: '45px', marginBottom: '45px'}}>
                                        <Spin size="large" tip="Loading..." />
                                    </div>
                                }
                                style={{overflow: 'visible'}}>

                                {this.getCourseItems().map((item, index) => {
                                    return ( 
                                        <div key={item.id}>
                                            {index > 0 ? <br /> : null}
                                            <CourseItemItem 
                                            studentMode={this.props.studentMode}
                                            courseItem={item}
                                            thumbnailSuffix={this.state[item.id + "-thumbnail-suffix"]}
                                            onViewMoreAnalyticsBtn={(onLearnerAnalyticsClose) => {
                                                this.setState({selectedCourseItemLearnerAnalytics: item});
                                                this.onLearnerAnalyticsClose = onLearnerAnalyticsClose;
                                            }}
                                            onViewSubmissionHistoryBtn={() => {
                                                this.setState({selectedQuizSubmissionHistoryItem: item});
                                            }}
                                            onClick={() => {
                                                switch (item.type) {
                                                    case COURSE_ITEM_TYPE_FILE:
                                                        if (!item.downloaded) {
                                                            Api.recordCourseItemDownload(item.id, () => {});
                                                            item.downloaded = true;
                                                            this.setState({})
                                                        }
                                                        ///download
                                                        const supportedFileFormats = [
                                                            "png", "jpg", "jpeg", "gif", "bmp",
                                                            "pdf", "csv", "xlsx", "docx", "mp4",
                                                            "webm", "mp3"
                                                        ]

                                                        if (supportedFileFormats.includes(item.format)) {
                                                            this.setState({ selectedCourseItem: item });
                                                        } else {
                                                            window.location = Api.getCourseItemAttachment(item.id)
                                                        }
                                                        
                                                        if (this.props.onUpdateProgress !== undefined) {
                                                            this.props.onUpdateProgress();
                                                        }
                                                        break;
                                                    case COURSE_ITEM_TYPE_HELP_SESSION_LIVE:
                                                        //this.props.history.push("/live-stream-recording/" + item.liveStreamId);
                                                        this.setState({ selectedCourseItem: item });
                                                        break;
                                                    default:
                                                        this.setState({ selectedCourseItem: item });
                                                        break;
                                                }
                                            }}
                                            onItemDeleted={() => {
                                                const index = this.state.items.indexOf(item);
                                                if (index != -1) {
                                                    this.state.items.splice(index, 1);
                                                    this.setState({});
                                                }
                                            }}
                                            onEditBtn={() => this.setState({
                                                updateCourse: item,
                                                updateCourseItemTitle: item.title,
                                                updateCourseItemDesc: item.description
                                            })} />
                                        </div>
                                    )
                                })}
                            </InfiniteScroll>
                            {!this.state.loadingItems ? <Empty style={{marginTop: '50px'}} image={Empty.PRESENTED_IMAGE_SIMPLE} description={this.state.items.length > 0 ? "No more course components" : "No course components"} /> : null}
                            <br />
                        </div>
                    )}
                </>)}

                <Modal
                title="Upload Video"
                width={750}
                centered
                visible={this.state.videoAttachment !== undefined}
                okText="Upload"
                okButtonProps={{disabled: this.state.newCourseItemTitle.trim() === ""}}
                confirmLoading={this.state.creatingNewCourseItem || this.state.loadingAttachment}
                onOk={() => this.newCourseItem(COURSE_ITEM_TYPE_LECTURE_VIDEO, this.state.newCourseItemThumbnail, this.state.videoAttachment, 
                    () => this.setState({videoAttachment: undefined, videoAttachmentUrl: undefined, attachmentDuration: 0, newCourseItemThumbnail: undefined, newCourseItemTitle: "", newCourseItemDesc: ""}))}
                onCancel={() => this.setState({videoAttachment: undefined, videoAttachmentUrl: undefined, attachmentDuration: 0, newCourseItemThumbnail: undefined, newCourseItemTitle: "", newCourseItemDesc: ""})}>
                    <video muted loop autoPlay playsInline onLoadedMetadata={e => this.setState({attachmentDuration: Math.trunc(e.target.duration), loadingAttachment: false})} style={{width: '100%', height: '250px', objectFit: 'cover'}} src={this.state.videoAttachmentUrl !== undefined ? this.state.videoAttachmentUrl : undefined} />
                    <FormPadding />
                    <h2>Details</h2>
                    <h3>Title</h3>
                    <Input size="large" value={this.state.newCourseItemTitle} onChange={e => this.setState({newCourseItemTitle: e.target.value})} />

                    <FormPadding />
                    <h3>Description</h3>
                    <Input.TextArea maxLength={1000}  size="large" rows={4} value={this.state.newCourseItemDesc} onChange={e => this.setState({newCourseItemDesc: e.target.value})} />

                    <FormPadding />

                    <h3>Thumbnail</h3>
                    <div style={{display: 'flex', maxHeight: '250px'}}>
                        <img src={this.state.newCourseItemThumbnail !== undefined ? URL.createObjectURL(this.state.newCourseItemThumbnail) : NoImage} onError={e => {
                            e.target.onerror = null;
                            e.target.src = NoImage;
                        }} style={{width: '300px', height: '250', objectFit: 'cover'}} />
                        <div style={{height: '250px', maxHeight: '250px', flex: 1, marginLeft: '25px'}}>
                            <Upload.Dragger accept="image/gif,image/jpeg,image/png" showUploadList={false} beforeUpload={file => {
                                this.setState({newCourseItemThumbnail: file})
                                return false;
                            }}>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            </Upload.Dragger>
                        </div>
                    </div>
                </Modal>

                <Modal
                title="Upload Audio"
                width={750}
                centered
                visible={this.state.audioAttachment !== undefined}
                okText="Upload"
                okButtonProps={{disabled: this.state.newCourseItemTitle.trim() === ""}}
                confirmLoading={this.state.creatingNewCourseItem || this.state.loadingAttachment}
                onOk={() => this.newCourseItem(COURSE_ITEM_TYPE_LECTURE_AUDIO, this.state.newCourseItemThumbnail, this.state.audioAttachment, 
                    () => this.setState({audioAttachment: undefined, audioAttachmentUrl: undefined, attachmentDuration: 0, newCourseItemThumbnail: undefined, newCourseItemTitle: "", newCourseItemDesc: ""}))}
                onCancel={() => this.setState({audioAttachment: undefined, audioAttachmentUrl: undefined, attachmentDuration: 0, newCourseItemThumbnail: undefined, newCourseItemTitle: "", newCourseItemDesc: ""})}>
                    <p>{this.state.audioAttachment !== undefined ? this.state.audioAttachment.name : ''}</p>
                    {this.state.audioAttachment !== undefined &&
                    <AudioWaveformDisplay onAudioDurationUpdate={duration => this.setState({attachmentDuration: Math.trunc(duration), loadingAttachment: false})} style={{width: '100%', height: '250px', objectFit: 'cover'}} src={this.state.audioAttachmentUrl !== undefined ? this.state.audioAttachmentUrl : undefined} />}
                    <FormPadding />
                    <h2>Details</h2>
                    <h3>Title</h3>
                    <Input size="large" value={this.state.newCourseItemTitle} onChange={e => this.setState({newCourseItemTitle: e.target.value})} />

                    <FormPadding />
                    <h3>Description</h3>
                    <Input.TextArea maxLength={1000}  size="large" rows={4} value={this.state.newCourseItemDesc} onChange={e => this.setState({newCourseItemDesc: e.target.value})} />

                    <FormPadding />

                    <h3>Thumbnail</h3>
                    <div style={{display: 'flex', maxHeight: '250px'}}>
                        <img src={this.state.newCourseItemThumbnail !== undefined ? URL.createObjectURL(this.state.newCourseItemThumbnail) : NoImage} onError={e => {
                            e.target.onerror = null;
                            e.target.src = NoImage;
                        }} style={{width: '300px', height: '250', objectFit: 'cover'}} />
                        <div style={{height: '250px', maxHeight: '250px', flex: 1, marginLeft: '25px'}}>
                            <Upload.Dragger accept="image/gif,image/jpeg,image/png" showUploadList={false} beforeUpload={file => {
                                this.setState({newCourseItemThumbnail: file})
                                return false;
                            }}>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            </Upload.Dragger>
                        </div>
                    </div>
                </Modal>

                <Modal
                title="Upload File"
                width={750}
                centered
                visible={this.state.fileAttachment !== undefined}
                okText="Upload"
                okButtonProps={{disabled: this.state.newCourseItemTitle.trim() === ""}}
                confirmLoading={this.state.creatingNewCourseItem}
                onOk={() => this.newCourseItem(COURSE_ITEM_TYPE_FILE, this.state.newCourseItemThumbnail, this.state.fileAttachment, 
                    () => this.setState({fileAttachment: undefined, attachmentFormat: "", attachmentSize: 0, newCourseItemThumbnail: undefined, newCourseItemTitle: "", newCourseItemDesc: ""}))}
                onCancel={() => this.setState({fileAttachment: undefined, attachmentFormat: "", attachmentSize: 0, newCourseItemThumbnail: undefined, newCourseItemTitle: "", newCourseItemDesc: ""})}>
                    <FileCard black fileName={this.state.fileAttachment !== undefined ? this.state.fileAttachment.name : ""} fileSize={this.state.fileAttachment !== undefined ? this.state.fileAttachment.size : 0} />

                    <FormPadding />
                    <h2>Details</h2>
                    <h3>Title</h3>
                    <Input size="large" value={this.state.newCourseItemTitle} onChange={e => this.setState({newCourseItemTitle: e.target.value})} />

                    <FormPadding />
                    <h3>Description</h3>
                    <Input.TextArea maxLength={1000}  size="large" rows={4} value={this.state.newCourseItemDesc} onChange={e => this.setState({newCourseItemDesc: e.target.value})} />

                    <FormPadding />

                    <h3>Thumbnail</h3>
                    <div style={{display: 'flex', maxHeight: '250px'}}>
                        <img src={this.state.newCourseItemThumbnail !== undefined ? URL.createObjectURL(this.state.newCourseItemThumbnail) : NoImage} onError={e => {
                            e.target.onerror = null;
                            e.target.src = NoImage;
                        }} style={{width: '300px', height: '250', objectFit: 'cover'}} />
                        <div style={{height: '250px', maxHeight: '250px', flex: 1, marginLeft: '25px'}}>
                            <Upload.Dragger accept="image/gif,image/jpeg,image/png" showUploadList={false} beforeUpload={file => {
                                this.setState({newCourseItemThumbnail: file})
                                return false;
                            }}>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            </Upload.Dragger>
                        </div>
                    </div>
                </Modal>

                <Modal
                title="Create Quiz"
                width={750}
                centered
                visible={this.state.createQuiz}
                okText="Create"
                okButtonProps={{disabled: this.state.newCourseItemTitle.trim() === ""}}
                confirmLoading={this.state.creatingNewCourseItem}
                onOk={() => this.newCourseItem(COURSE_ITEM_TYPE_QUIZ, this.state.newCourseItemThumbnail, undefined, 
                    createdCourseItem => this.setState({createQuiz: false, newCourseItemThumbnail: undefined, newCourseItemTitle: "", newCourseItemDesc: ""}, 
                    () => this.setState({ selectedCourseItem: createdCourseItem })))}
                onCancel={() => this.setState({createQuiz: false, newCourseItemThumbnail: undefined, newCourseItemTitle: "", newCourseItemDesc: ""})}>
                    <h2>Details</h2>
                    <h3>Title</h3>
                    <Input size="large" value={this.state.newCourseItemTitle} onChange={e => this.setState({newCourseItemTitle: e.target.value})} />

                    <FormPadding />
                    <h3>Description</h3>
                    <Input.TextArea maxLength={1000}  size="large" rows={4} value={this.state.newCourseItemDesc} onChange={e => this.setState({newCourseItemDesc: e.target.value})} />

                    <FormPadding />

                    <h3>Thumbnail</h3>
                    <div style={{display: 'flex', maxHeight: '250px'}}>
                        <img src={this.state.newCourseItemThumbnail !== undefined ? URL.createObjectURL(this.state.newCourseItemThumbnail) : NoImage} onError={e => {
                            e.target.onerror = null;
                            e.target.src = NoImage;
                        }} style={{width: '300px', height: '250', objectFit: 'cover'}} />
                        <div style={{height: '250px', maxHeight: '250px', flex: 1, marginLeft: '25px'}}>
                            <Upload.Dragger accept="image/gif,image/jpeg,image/png" showUploadList={false} beforeUpload={file => {
                                this.setState({newCourseItemThumbnail: file})
                                return false;
                            }}>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            </Upload.Dragger>
                        </div>
                    </div>
                </Modal>

                <Modal
                title="Update"
                width={750}
                centered
                visible={this.state.updateCourse !== undefined}
                okText="Update"
                okButtonProps={{disabled: this.state.updateCourseItemTitle.trim() === ""}}
                confirmLoading={this.state.updatingCourseItem}
                onOk={() => this.updateCourseItem(this.state.updateCourseItemThumbnail, 
                    () => this.setState({updateCourse: undefined, updateCourseItemThumbnail: undefined, updateCourseItemTitle: "", updateCourseItemDesc: ""}))}
                onCancel={() => this.setState({updateCourse: undefined, updateCourseItemThumbnail: undefined, updateCourseItemTitle: "", updateCourseItemDesc: ""})}>
                    <h2>Details</h2>
                    <h3>Title</h3>
                    <Input size="large" value={this.state.updateCourseItemTitle} onChange={e => this.setState({updateCourseItemTitle: e.target.value})} />

                    <FormPadding />
                    <h3>Description</h3>
                    <Input.TextArea maxLength={1000}  size="large" rows={4} value={this.state.updateCourseItemDesc} onChange={e => this.setState({updateCourseItemDesc: e.target.value})} />

                    <FormPadding />

                    <h3>Thumbnail</h3>
                    <div style={{display: 'flex', maxHeight: '250px'}}>
                        <img src={this.state.updateCourseItemThumbnail !== undefined ? URL.createObjectURL(this.state.updateCourseItemThumbnail) : Api.getCourseItemThumbnail(this.state.updateCourse !== undefined ? this.state.updateCourse.id : 0)} onError={e => {
                            e.target.onerror = null;
                            e.target.src = NoImage;
                        }} style={{width: '300px', height: '250', objectFit: 'cover'}} />
                        <div style={{height: '250px', maxHeight: '250px', flex: 1, marginLeft: '25px'}}>
                            <Upload.Dragger accept="image/gif,image/jpeg,image/png" showUploadList={false} beforeUpload={file => {
                                this.setState({updateCourseItemThumbnail: file})
                                return false;
                            }}>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            </Upload.Dragger>
                        </div>
                    </div>
                </Modal>

                {this.state.selectedCourseItem !== undefined && 
                (this.state.selectedCourseItem.type === COURSE_ITEM_TYPE_FILE) && (
                    <Lightbox
                        mainCustomContent={this.getSelectedCourseFileViewer()}
                        imageTitle={this.state.selectedCourseItem.title}
                        imageCaption={this.state.selectedCourseItem.description}
                        onCloseRequest={() => this.setState({ selectedCourseItem: undefined })}
                    />
                )}

                {this.state.selectedCourseItem !== undefined && 
                (this.state.selectedCourseItem.type === COURSE_ITEM_TYPE_LECTURE_VIDEO || 
                this.state.selectedCourseItem.type === COURSE_ITEM_TYPE_LECTURE_AUDIO) && (
                    <Lightbox
                        mainCustomContent={this.getSelectedCourseItemComponent()}
                        imageTitle={this.state.selectedCourseItem.title}
                        imageCaption={this.state.selectedCourseItem.description}
                        onCloseRequest={() => this.setState({ selectedCourseItem: undefined })}
                    />
                )}

                {this.state.selectedCourseItem !== undefined && this.state.selectedCourseItem.type === COURSE_ITEM_TYPE_HELP_SESSION_LIVE &&
                <Modal width="95vw" 
                closable={false}
                visible={this.state.selectedCourseItem !== undefined && this.state.selectedCourseItem.type === COURSE_ITEM_TYPE_HELP_SESSION_LIVE} 
                centered footer={null} bodyStyle={{padding: '0px'}} onCancel={() => this.setState({selectedCourseItem: undefined})}>
                    <div style={{display: 'flex', height: '90vh'}}>
                        <LiveStreamWindow recordingMode liveStreamId={this.state.selectedCourseItem.liveStreamId} onBack={() => this.setState({selectedCourseItem: undefined})} />
                    </div>
                </Modal>}

                {this.state.selectedQuizSubmissionHistoryItem !== undefined &&
                <QuizSubmissionHistoryDialog quizItem={this.state.selectedQuizSubmissionHistoryItem} onCancel={() => this.setState({selectedQuizSubmissionHistoryItem: undefined})} />}

                {this.state.selectedCourseItemLearnerAnalytics !== undefined &&
                <Modal visible width={700} onCancel={() => this.setState({selectedCourseItemLearnerAnalytics: undefined}, () => {
                    if (this.onLearnerAnalyticsClose !== undefined) {
                        this.onLearnerAnalyticsClose();
                        this.onLearnerAnalyticsClose = undefined;
                    }
                })} footer={null}>
                    <CourseLearnerAnalytics courseItem={this.state.selectedCourseItemLearnerAnalytics} courseId={this.props.section.courseId} />
                </Modal>}
                
            </div>
        )
    }

}

export default withRouter(CourseSectionView);