import { Empty, Spin } from 'antd';
import React from 'react'
import InfiniteScroll from 'react-infinite-scroller';
import { NO_PAGE_TOKEN, PAGINATION_PAGE_CONTENT_COUNT } from '../../Constants';
import Api from '../../session/Api';
import Util from '../../util/Util';
import NotificationItem from './NotificationItem';

class NotificationsView extends React.Component {

    constructor(props) {
        super(props) 

        this.state = {
            items: [],
            page: NO_PAGE_TOKEN,
            hasMore: true,
            loadingItems: false,
        }
    }

    componentDidMount() {
        this.getNextItems()
    }

    getNextItems() {
        if (this.state.loadingItems) {
            return;
        }

        this.setState({
            loadingItems: true
        })
        //list error
        Api.getNotifications(this.state.page, response => {
            if (response.status === true) {
                let items = this.state.items;
                items.push(...response.payload.items);

                this.setState({
                    items: items,
                    page: response.payload.token,
                    hasMore: Util.isStringExists(response.payload.token),
                    loadingItems: false
                })
            } else {
                //alert('errowr');
            }
        });
    };
    //hasMore: response.payload.length === PAGINATION_PAGE_CONTENT_COUNT,

    render() {
        return (
            <div style={{ height: 400, width: 325, overflow: 'auto'}}>
                <InfiniteScroll
                    loadMore={this.getNextItems.bind(this)}
                    hasMore={this.state.hasMore}
                    //threshold={750}
                    useWindow={false}
                    loader={
                        <div style={{display: 'flex', justifyContent: 'center', marginTop: '45px', marginBottom: '45px'}}>
                            <Spin size="large" tip="Loading..." />
                        </div>
                    }
                    style={{overflow: 'visible'}}>

                    {this.state.items.map((item) => {
                        return ( 
                            <div key={item.id}>
                                <NotificationItem key={item.id + "n"} onNotificationClick={this.props.onNotificationClick} notification={item} style={{paddingLeft: 15, paddingRight: 15, borderBottomStyle: 'solid', borderBottomColor: 'rgb(0,0,0,0.1)', borderBottomWidth: 1, paddingBottom: 15, paddingTop: 15,}} />
                                
                            </div>
                        )
                    })}
                </InfiniteScroll>

                {!this.state.loadingItems ? <Empty style={{marginTop: '50px'}} image={Empty.PRESENTED_IMAGE_SIMPLE} description={this.state.items.length > 0 ? "No more notifications" : "No notifications"} /> : null}

                <br />
            </div>
        )
    }

}

export default NotificationsView;