import { Button, Popconfirm, Spin } from 'antd';
import React, { useState } from 'react'

import {  
    FileFilled,
    FileAddOutlined,
    DeleteOutlined
} from '@ant-design/icons';
import Util from '../../util/Util';
import Api from '../../session/Api';
import UIUtil from '../../util/UIUtil';
import UploadWrapper from '../../components/UploadWrapper';

import Lightbox from '../../components/media-list-viewer/react-image-lightbox';
import FileViewer from 'react-file-viewer';

const Item = ({attachment, deleteable, onRemove, onView}) => {
    const [deleteLoading, setDeleteLoading] = useState(false);

    const onRemoveBtn = e => {
        e.stopPropagation()
        setDeleteLoading(true);
        Api.removeLiveSessionScheduleAttachment(attachment.id, response => {
            if (response.status === true) {
                onRemove();
                UIUtil.showSuccess();
            } else {
                UIUtil.showError();
            }
        })
    }

    return (
        <div 
        onClick={() => {
            let fileFormat;
            if (attachment.fileName.includes(".")) {
                const nameData = attachment.fileName.split(".");
                fileFormat = nameData[nameData.length - 1];
            }

            const supportedFileFormats = [
                "png", "jpg", "jpeg", "gif", "bmp",
                "pdf", "csv", "xlsx", "docx", "mp4",
                "webm", "mp3"
            ]

            if (supportedFileFormats.includes(fileFormat)) {
                onView(attachment);
            } else {
                window.location = Api.getLiveSessionScheduleAttachment(attachment.id)
            }
        }}
        style={{width: '100%', borderRadius: '15px', 
        cursor: 'pointer',
        padding: '5px', paddingLeft: 15, paddingRight: 15, marginTop: 7, boxShadow: "0px 0px 25px 0px rgba(0, 0, 0, 0.2)", display: 'flex', alignItems: 'center'}}>
            <FileFilled style={{color: 'black'}} />
            <div style={{marginLeft: 15, flex: 1}}>
                <h5 style={{margin: 0, fontSize: 14}}>{attachment.fileName}</h5>
                <p style={{margin: 0, fontSize: 12}}>{Util.formatBytes(attachment.fileSize)}</p>
            </div>
            {deleteable && <Popconfirm title="Are you sure you want to remove this attachment?" onCancel={e => e.stopPropagation()} onConfirm={onRemoveBtn} placement="topRight">
                <Button danger type="link" size="large" onClick={e => e.stopPropagation()} loading={deleteLoading}><DeleteOutlined /></Button>
            </Popconfirm>}
        </div>
    )
}

class ScheduleAttachmentsView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            items: [],

            adding: false,
            selectedItem: undefined
        }
    }

    getSelectedCourseFileViewer() {
        return (
            <div style={{height: '95%', width: '95%', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div style={{height: '100%', width: '100%',}}>
                    <FileViewer
                    fileType={this.state.selectedItem.fileName.split(".").pop()}
                    // filePath="https://d1n9c9kc7fz1oa.cloudfront.net/courseitemattachment/AAAAAAAAANRBQUFBQUFBQUFIdEJRVUZCUVVGQlFVRkVhR3BaYlVWNlRYcEZkMDFwTUhoTlJFWnBURlJSTVU5VVozUlBWRWw0V1hrd2QwNXFWbXhhYWtreFQwUmpORTlIU1hoT2FrVXlUV3BaTTAxNlRYaE5lazE1WmtWT1VGWldTbFJTVVVGQlFVRXdlRTVxUlRKUFJFRjRUbFJWTUU1RVVUSjhRMDlWVWxORlgxTkZRMVJKVDA0QUFBQU5NVFl4TnpFeE16QTFOalV5TVE9PXxDT1VSU0VfSVRFTQAAAD8xNjMwNDc5NjkxMzMxfDRiNjkxYTZiLTExYWQtNDBmYi1hYTk5LTlmNGM1OTU2ZTAzZTE2MzA0Nzk2OTEzMzE="
                    filePath={Api.getLiveSessionScheduleAttachment(this.state.selectedItem.id)}
                    // errorComponent={(props) => (
                    //     <Button>hi</Button>
                    // )}
                    // onError={() => {}} 
                    />
                </div>
            </div>
        )
    }

    componentDidMount() {
        this.setState({loading: true})
        Api.getLiveSessionScheduleAttachments(this.props.scheduleId, response => {
            if (response.status === true) {
                this.setState({loading: false, items: response.payload})
            } else {
                UIUtil.showError();
            }
        })
    }

    addAttachment(file) {
        this.setState({adding: true})
        Api.addLiveSessionScheduleAttachment(this.props.scheduleId, file, response => {
            if (response.status === true) {
                UIUtil.showSuccess();
                this.setState({adding: false, items: [response.payload, ...this.state.items]})
            } else {
                UIUtil.showError();
                this.setState({adding: false})
            }
        })
    }

    render() {
        if (this.state.loading) {
            return (
                <div style={{display: 'flex', justifyContent: 'center', marginTop: '45px', marginBottom: '45px'}}>
                    <Spin size="large" tip="Loading..." />
                </div>
            )
        }

        return (
            <div>
                {this.props.canAdd && <div style={{display: 'flex', width: '100%', justifyContent: 'flex-end', marginBottom: 10}}>
                    <UploadWrapper onChange={e => {
                        if (e.target.files !== undefined && e.target.files.length > 0) {
                            this.addAttachment(e.target.files[0]);
                        }
                    }}>
                        <Button loading={this.state.adding} type="primary" icon={<FileAddOutlined />}>Upload New</Button>
                    </UploadWrapper>
                </div>}

                {this.state.items.length > 0 ? 
                this.state.items.map(item => <Item key={item.id} 
                    onView={() => this.setState({selectedItem: item})}
                    deleteable={this.props.canAdd} attachment={item} onRemove={() => this.setState(prevState => ({items: prevState.items.filter(e => e !== item)}))} />) : (
                    <div style={{display: 'flex', justifyContent: 'center', marginTop: '45px', marginBottom: '45px'}}>
                        <p>No documents</p>
                    </div>
                )}

                {this.state.selectedItem !== undefined && 
                (
                    <Lightbox
                        mainCustomContent={this.getSelectedCourseFileViewer()}
                        imageTitle={this.state.selectedItem.fileName}
                        onCloseRequest={() => this.setState({ selectedItem: undefined })}
                    />
                )}
            </div>
        )
    }

}

export default ScheduleAttachmentsView;