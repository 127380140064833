import { Button, Col, Rate, Row, Spin } from 'antd';
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import Api from '../../session/Api';

import { 
    MessageOutlined,
} from '@ant-design/icons';
import ProfilePicture from '../../components/ProfilePicture';
import PresenceIndicator from '../../components/PresenceIndicator';
import Util from '../../util/Util';

export default ({}) => {
    const [loading, setLoading] = React.useState(true);
    const [tutors, setTutors] = React.useState(undefined);

    useEffect(() => {
        setLoading(true);
        Api.getFeaturedTutors(response => {
            setLoading(false)
            if (response.status === true) {
                setTutors(response.payload);
            }
        })
    }, [])

    if (loading) {
        return (
            <div style={{display: 'flex', justifyContent: 'center', marginTop: '45px', marginBottom: '45px'}}>
                <Spin size="large" tip="Loading..." />
            </div>
        );
    }

    if (tutors === undefined) {
        return null;
    }

    return (
        <div>
            <Row style={{width: '100%'}}>
                {tutors !== undefined && tutors.map(tutor => (
                    <Col span={6} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                        <ProfilePicture clickable userId={tutor.id} size={150} style={{borderStyle: 'solid'}} />
                        <Link to={Util.getProfilePath(tutor.id)}><h2 style={{color: 'black', marginTop: '15px'}}>{tutor.fullName}</h2></Link>
                        <PresenceIndicator textMode userId={tutor.id} style={{color: 'black', marginTop: '-10px', marginBottom: '15px'}} />
                        <Rate disabled defaultValue={tutor.averageRating} />
                        <Link to={`/messages?userId=${tutor.id}`}>
                            <Button type="text" style={{marginTop: '5px', color: 'black'}}><MessageOutlined /> Send Message</Button>
                        </Link>
                    </Col>
                ))}
            </Row>
        </div>
    )
}