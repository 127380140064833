import React from 'react';
import Page from '../base/Page';
import { Button, Radio, Input, Spin, Empty, Checkbox, Dropdown, Menu, Tooltip } from 'antd';

import { 
    UsergroupAddOutlined,
    MailOutlined,
    UserOutlined,
    AuditOutlined,
    EnterOutlined,
    SwapOutlined,
    TeamOutlined,
    PlusCircleOutlined
} from '@ant-design/icons';
import Modal from 'antd/lib/modal/Modal';
import Api from '../session/Api';
import { withRouter } from 'react-router-dom';
import TutorCourseItem from '../views/courses/TutorCourseItem';

import InfiniteScroll from 'react-infinite-scroller';
import { ACCOUNT_ROLE_STUDENT, ACCOUNT_ROLE_TUTOR, PAGINATION_PAGE_CONTENT_COUNT } from '../Constants';
import UIUtil from '../util/UIUtil';
import withMediaQuery from '../components/WithMediaQuery';
import MultipleUserInput from '../components/MultipleUserInput';
import UserList from '../components/UserList';
import UserListDialog from '../components/UserListDialog';

import GetUserCreditsView from './user-allowance-page/GetUserCreditsView'

import { Link } from 'react-router-dom'
import Util from '../util/Util';

const FILTER_ALL = "0";
const FILTER_TUTORS = "1";
const FILTER_LEARNERS = "2";

const ValueLabel = ({label, value, bold}) => (
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        
        <h2 style={{margin: 0, fontSize: 11.25}}>
            {label}
        </h2>
        <h1 style={{fontWeight: bold ? 'bold' : undefined, color: !bold ? 'black' : '#4e2e89', margin: 0, marginTop: -7, fontSize: 22.5}}>{Util.zeroPad(value, 4)}</h1>
    </div>
)

class OrganizationUsersPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            createUsers: false,
            createUsersRole: -1,
            createUsersList: [],

            inviteUsers: false,
            inviteUsersList: [],

            loadingAction: false,
            actionReport: undefined,

            filter: FILTER_ALL,

            showSentInvites: false,
            showJoinRequests: false,
            
            getUserCredits: false,

            loadingUserUsage: true,
            errorUserUsage: false,
            userUsage: null,

            totalUsers: 0,
            userLimitLeft: 0,
        }
    }

    onPageStart() {
        this.setState({loadingUserUsage: true})
        Api.getUserCreditUsage(response => {
            this.setState({loadingUserUsage: false, errorUserUsage: response.payload === null, userUsage: response.payload, totalUsers: response.payload.credits.totalUsers, userLimitLeft: response.payload.credits.limitLeftUsers})
        })
    }
    
    onCreateUsersAndEnroll() {
        this.setState({loadingAction: true})

        const accounts = this.state.createUsersList.map(user => ({
            email: user.email,
            fullName: user.fullName,
            role: this.state.createUsersRole,
            passwordValue: user.password,
        }));

        Api.createUsersAndEnroll(accounts, response => {
            this.setState({loadingAction: false})

            if (response.status === true) {
                this.setState(prevState => ({createUsers: false, totalUsers: prevState.totalUsers + response.payload.length, userLimitLeft: prevState.userLimitLeft - response.payload.length}))
                if (this.onMultipleUserAddedTutor !== undefined) {
                    this.onMultipleUserAddedTutor(response.payload)
                }
            } else {
                UIUtil.showError(response.message)
            }
        })
    }
    
    onInviteUsers() {
        this.setState({loadingAction: true});

        const userEmails = this.state.inviteUsersList.map(user => user.email);

        Api.inviteUsersForEnrollment(userEmails, response => {
            this.setState({loadingAction: false})

            if (response.status === true) {
                this.setState({inviteUsers: false})
                UIUtil.showSuccess();
            } else {
                UIUtil.showError(response.message)
            }
        })
    }
    
    getUserRoleFilter() {
        switch (this.state.filter) {
            case FILTER_ALL:
                return undefined;
            case FILTER_TUTORS:
                return ACCOUNT_ROLE_TUTOR;
            case FILTER_LEARNERS:
                return ACCOUNT_ROLE_STUDENT;
            default:
                return undefined;
        }
    }

    render() {
        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div style={{width: this.props.renderFullWidth ? '90vw' : '75vw', paddingTop: '25px', paddingBottom: '25px'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <h1><TeamOutlined /> User Management</h1>
                        <div style={{flex: 1}} />

                        {this.state.loadingUserUsage && !this.state.errorUserUsage ? (
                            <Spin size="small" />
                        ) : (<>
                            <ValueLabel label="users" value={this.state.totalUsers} bold />
                            <div style={{width: 25}} />
                            <ValueLabel label="limit left" value={this.state.userLimitLeft} increaseBtn />

                            <Tooltip title="Increase limit">
                                <Button type="link" onClick={() => this.setState({getUserCredits: true})}><PlusCircleOutlined /></Button>
                            </Tooltip>
                        </>)}
                    </div>

                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <h2 style={{height: '25px'}}><UserOutlined /> Users</h2>
                        {<Radio.Group value={this.state.filter} onChange={e => this.setState({filter: e.target.value})} buttonStyle="solid" size="medium" style={{marginLeft: '15px'}}>
                            <Radio.Button value={FILTER_ALL}>All</Radio.Button>
                            <Radio.Button value={FILTER_TUTORS}>Tutors</Radio.Button>
                            <Radio.Button value={FILTER_LEARNERS}>Learners</Radio.Button>
                        </Radio.Group>}
                        <Button type="primary" style={{marginLeft: '25px'}} onClick={() => this.setState({showSentInvites: true})}><EnterOutlined /> Sent Invites</Button>
                        <Button type="primary" style={{marginLeft: '10px'}} onClick={() => this.setState({showJoinRequests: true})}><SwapOutlined /> Join Requests</Button>
                        <div style={{flex: '1'}} />
                        <Button type="link" onClick={e => this.setState({inviteUsers: true, inviteUsersList: []})}>
                            <MailOutlined /> Invite Users
                        </Button>
                        <Dropdown overlay={
                            <Menu>
                                <Menu.Item onClick={e => this.setState({createUsers: true, createUsersRole: ACCOUNT_ROLE_STUDENT, createUsersList: []})} style={{height: '50px', display: 'flex', alignItems: 'center'}}>
                                    <UserOutlined style={{fontSize: '20px'}} /> Learners
                                </Menu.Item>
                                <Menu.Item onClick={e => this.setState({createUsers: true, createUsersRole: ACCOUNT_ROLE_TUTOR, createUsersList: []})} style={{height: '50px', display: 'flex', alignItems: 'center'}}>
                                    <AuditOutlined style={{fontSize: '20px'}} /> Tutors
                                </Menu.Item>
                            </Menu>
                        } trigger={['click']}>
                            <Button type="link">
                                <UsergroupAddOutlined /> Create Users
                            </Button>
                        </Dropdown>
                    </div>
                    <br />

                    <UserList roleFilter={this.getUserRoleFilter()} showOrganizationUsers onMultipleUserAddedMethodListener={onMultipleUserAdded => this.onMultipleUserAddedTutor = onMultipleUserAdded}
                    onUserRemovedListener={() => this.setState(prevState => ({totalUsers: prevState.totalUsers - 1, userLimitLeft: prevState.userLimitLeft + 1}))} />

                    {/*<InfiniteScroll
                        loadMore={this.getNextCourses.bind(this)}
                        hasMore={this.state.hasMore}
                        threshold={750}
                        loader={
                            <div style={{display: 'flex', justifyContent: 'center', marginTop: '45px', marginBottom: '45px'}}>
                                <Spin size="large" tip="Loading..." />
                            </div>
                        }
                        style={{overflow: 'visible'}}>

                        {this.state.items.map((item) => {
                            return ( 
                                <div key={item.id}>
                                    <br />
                                    <TutorCourseItem course={item} />
                                </div>
                            )
                        })}
                    </InfiniteScroll>

                    {!this.state.loadingItems ? <Empty style={{marginTop: '50px'}} image={Empty.PRESENTED_IMAGE_SIMPLE} description={this.state.items.length > 0 ? "No more courses" : "No courses"} /> : null}*/}
                    <br />
                </div>

                
                <Modal
                    title={"Create " + (this.state.createUsersRole == ACCOUNT_ROLE_TUTOR ? "Tutors" : "Learners")}
                    visible={this.state.createUsers}
                    okText={"Create (" + this.state.createUsersList.length + "/" + this.state.userLimitLeft + ")"}
                    width={this.state.userLimitLeft <= 0 ? undefined : this.props.renderFullWidth ? '90vw' : '75vw'}
                    confirmLoading={this.state.loadingAction}
                    okButtonProps={{disabled: this.state.createUsersList.length == 0}}
                    onOk={() => this.onCreateUsersAndEnroll()}
                    onCancel={() => this.setState({createUsers: false})}
                    >
                        {this.state.userLimitLeft <= 0 ? (<div style={{paddingBottom: 25, paddingTop: 25, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                            <p style={{opacity: 0.65}}>User limit reached!</p>
                            <Button style={{marginTop: 0}} type="primary" onClick={() => this.setState({getUserCredits: true, createUsers: false})} icon={<PlusCircleOutlined />}>Increase Limit</Button>
                        </div>) : (
                            this.state.createUsers && <MultipleUserInput limit={this.state.userLimitLeft} onInputUpdated={users => this.setState({createUsersList: users})} />
                        )}
                </Modal>

                <Modal
                    title="Invite Users"
                    visible={this.state.inviteUsers}
                    okText={"Invite (" + this.state.inviteUsersList.length + "/" + this.state.userLimitLeft + ")"}
                    //width={this.props.renderFullWidth ? '90vw' : '75vw'}
                    confirmLoading={this.state.loadingAction}
                    okButtonProps={{disabled: this.state.inviteUsersList.length == 0}}
                    onOk={() => this.onInviteUsers()}
                    onCancel={() => this.setState({inviteUsers: false})}
                    >
                        {this.state.userLimitLeft <= 0 ? (<div style={{paddingBottom: 25, paddingTop: 25, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                            <p style={{opacity: 0.65}}>User limit reached!</p>
                            <Button style={{marginTop: 0}} type="primary" onClick={() => this.setState({getUserCredits: true, inviteUsers: false})} icon={<PlusCircleOutlined />}>Increase Limit</Button>
                        </div>) : (
                            this.state.inviteUsers && <MultipleUserInput limit={this.state.userLimitLeft} emailOnly onInputUpdated={users => this.setState({inviteUsersList: users})} />
                        )}
                </Modal>

                {this.state.showSentInvites &&
                <UserListDialog
                    title="Sent Invites"
                    emptyMessage="No invites currenly sent/pending"
                    width="60vw"
                    visible={this.state.showSentInvites}
                    onCancel={() => this.setState({showSentInvites: false})}
                    footer={null}
                    showSentInvites>
                </UserListDialog>}

                {this.state.showJoinRequests &&
                <UserListDialog
                    title="Join Requests"
                    emptyMessage="No join requests currenly received/pending"
                    width="60vw"
                    visible={this.state.showJoinRequests}
                    onCancel={() => this.setState({showJoinRequests: false})}
                    footer={null}
                    showReceivedInvites
                    inviteAcceptListener={user => {
                        this.setState(prevState => ({totalUsers: prevState.totalUsers + 1, userLimitLeft: prevState.userLimitLeft - 1}))
                        if (this.onMultipleUserAddedTutor !== undefined) {
                            this.onMultipleUserAddedTutor([user])
                        }
                    }}>
                </UserListDialog>}

                {!this.state.loadingUserUsage && !this.state.errorUserUsage &&
                    <Modal
                    title="Pay-as-you-go"
                    width="50vw"
                    footer={null}
                    visible={this.state.getUserCredits}
                    onCancel={() => this.setState({getUserCredits: false})}
                    >
                    <GetUserCreditsView 
                    liveStreamCreditRate={this.state.userUsage.liveStreamCreditRate} priceRate={this.state.userUsage.priceRate}
                    maxPayCredit={this.state.userUsage.maxPayCredit} payInterval={this.state.userUsage.payInterval} />
                </Modal>}
            </div>
        );
    }

}

export default withMediaQuery(OrganizationUsersPage);