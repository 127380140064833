import React, { useEffect, useState } from 'react'
import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { Button, Modal, Spin } from 'antd';
import Api from '../../session/Api';
import SessionManager from '../../session/SessionManager';
import UIUtil from '../../util/UIUtil';

import { 
    CloseOutlined,
    CreditCardOutlined,
    LoadingOutlined
} from '@ant-design/icons';

import LogoWhite from '../../images/LogoWhite.png';

const Content = ({productId, productType, onPurchaseLoading, onPurchaseSuccess}) => {
    const [{ isPending, isRejected, isResolved }] = usePayPalScriptReducer();
    const [loading, setLoading] = useState(true);
    const [learnerixBalancePayment, setLearnerixBalancePayment] = useState(undefined);
    const [learnerixBalanceInError, setLearnerixBalanceInError] = useState(false);

    useEffect(() => {
        setLoading(true);

        if (SessionManager.isLoggedInAsTutor()) {
            Api.getBalanceLessOnHold(response => {
                if (response.status === true) {
                    setLoading(false);
                    setLearnerixBalancePayment(response.payload)
                    setLearnerixBalanceInError(false);
                } else {
                    setLoading(false);
                    setLearnerixBalancePayment(undefined)
                    setLearnerixBalanceInError(true);
                }
            })
        } else {
            setLoading(false);
            setLearnerixBalancePayment(undefined)
            setLearnerixBalanceInError(false);
        }
    }, [productId, productType])

    if (isPending || loading) {
        return (
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                <Spin tip="Loading Payment Methods..." />
            </div>
        )
    }

    if (isRejected || learnerixBalanceInError) {
        return (
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                <p>An error occurred. Please try again later.</p>
            </div>
        )
    }

    return (
        <div>
            {learnerixBalancePayment !== undefined &&
            <Button type="primary" style={{width: '100%', marginBottom: 50, height: 45}} 
            onClick={() => {
                Modal.confirm({
                    title: "Confirm Payment",
                    content: "By confirming the amount will be taken from your Learnerix Balance",
                    onOk: () => Api.purchaseWithLearnerixBalance(productType, productId, response => {
                        if (response.status === true) {
                            onPurchaseSuccess();
                        } else {
                            UIUtil.showError(response.message);
                        }
                    })
                })
            }}>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <img src={LogoWhite} style={{height: 30}} />
                    <h5 style={{marginLeft: 15, color: 'white', height: 12, alignSelf: 'center'}}>BALANCE ${learnerixBalancePayment.toFixed(2)}</h5>
                </div>
            </Button>}

            <PayPalButtons 
            onError={() => UIUtil.showError()}
            createOrder={() => 
                fetch(Api.getServerIp() + '/api/student/pay-pal/create-order/' + productType + "/" + productId, {
                    method: 'get',
                    headers: {
                        "Authorization": "Bearer " + SessionManager.getAccount().token
                    }
                })
                .then(res => res.json())
                .then(response => {
                    if (response.status === true) {
                        return response.payload;
                    } else {
                        throw response;
                    }
                })
            } 
            onApprove={(data) => {
                onPurchaseLoading();

                return fetch(Api.getServerIp() + '/api/student/pay-pal/finalize-order/' + productType + "/" + productId + "/" + data.orderID, {
                    method: 'get',
                    headers: {
                        "Authorization": "Bearer " + SessionManager.getAccount().token
                    }
                })
                .then(res => res.json())
                .then(response => {
                    if (response.status === true) {
                        onPurchaseSuccess();
                    } else {
                        throw response;
                    }
                })
            }}
            />
        </div>
    )
}


export default function OneTimePayment({productId, productType, onPurchaseSuccess}) {
    const [loadingPurchase, setLoadingPurchase] = React.useState(false);

    return (
        <div style={{position: 'relative', padding: 15}}>
            <PayPalScriptProvider options={{ 
                //"client-id": "AWpj8R7Iquzv1S1qRCCu6HgW6Ea-NDGweF0e6-q40WuSCBJKytue_OqUIMd3JmEfdpCx7oe2qf50GPeg",
                "client-id": "AR7g4cE3hMVfRFy_M3X_ifzax7U-afQd0u-qNHeaou2W_meIfPuuIV64lawHlN3gSGbm0NRnftE5UOcw",
                //"intent": "authorize"
            }}>
                <Content {...{
                    productId, 
                    productType, 
                    onPurchaseLoading: () => setLoadingPurchase(true), 
                    onPurchaseSuccess: () => {
                        setLoadingPurchase(false);
                        onPurchaseSuccess();
                    }
                }} />
                
            </PayPalScriptProvider>

            {loadingPurchase &&
            <div style={{position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', background: '#000000DD',
            display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000, borderRadius: 5}}>
                <Spin indicator={<LoadingOutlined style={{ fontSize: 30, color: '#f26622' }} spin />} style={{color: '#f26622'}} tip="Purchasing..." />
            </div>}
        </div>
    )
}