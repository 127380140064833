import React from 'react'
import Page from '../base/Page';
import withMediaQuery from '../components/WithMediaQuery';

import { 
    PlusOutlined,
    UnorderedListOutlined,
    BookOutlined,
    AlertOutlined,
    ClusterOutlined,
    ArrowRightOutlined,
    AuditOutlined,
    FireFilled
} from '@ant-design/icons';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import HorizontalCoursesView, { HORIZONTAL_MODE_TOP_COURSES } from '../views/courses/HorizontalCoursesView';
import HorizontalLiveSessionsView, { HORIZONTAL_MODE_TOP_LIVE_SESSIONS } from '../views/live-sessions/HorizontalLiveSessionsView';
import PostsView from '../views/posts/PostsView';
import UserList from '../components/UserList';
import HorizontalBlogPostView from '../views/blog/HorizontalBlogPostView';
import FeaturedTutorsView from '../views/tutors/FeaturedTutorsView';
import HorizontalHashtagsView from './hashtag/HorizontalHashtagsView';

class DiscoveryPage extends Page {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div style={{width: this.props.renderFullWidth ? '90vw' : '75vw', paddingTop: '25px', paddingBottom: '25px'}}>
                    <div style={{display: 'flex', alignItems: 'center', marginTop: '0px'}}>
                        <FireFilled style={{ fontSize: '20px', color: 'black' }} />
                        &nbsp;&nbsp;&nbsp;
                        <h2 style={{height: '22px'}}>Trending</h2>
                        <div style={{flex: '1'}} />
                    </div>
                    <br />
                    <HorizontalHashtagsView style={{width: this.props.renderFullWidth ? '90vw' : '75vw'}}  />


                    <div style={{display: 'flex', alignItems: 'center', marginTop: '50px'}}>
                        <BookOutlined style={{ fontSize: '20px', color: 'black' }} />
                        &nbsp;&nbsp;&nbsp;
                        <h2 style={{height: '22px'}}>Top Courses</h2>
                        <div style={{flex: '1'}} />
                        <Button type="link" className="orange-link">
                            <Link to="/all-courses">
                                View All Courses
                            </Link>
                        </Button>
                    </div>
                    <br />
                    <HorizontalCoursesView mode={HORIZONTAL_MODE_TOP_COURSES} />

                    <div style={{display: 'flex', alignItems: 'center', marginTop: '50px'}}>
                        <AlertOutlined style={{ fontSize: '20px', color: 'black' }} />
                        &nbsp;&nbsp;&nbsp;
                        <h2 style={{height: '22px'}}>Top Live Sessions</h2>
                        <div style={{flex: '1'}} />
                        <Button type="link" className="orange-link">
                            <Link to="/all-live-sessions">
                                View All Live Sessions
                            </Link>
                        </Button>
                    </div>
                    <br />
                    <HorizontalLiveSessionsView mode={HORIZONTAL_MODE_TOP_LIVE_SESSIONS} />

                    <div style={{display: 'flex', alignItems: 'center', marginTop: '50px'}}>
                        <AuditOutlined style={{ fontSize: '20px', color: 'black' }} />
                        &nbsp;&nbsp;&nbsp;
                        <h2 style={{height: '22px'}}>Featured Tutors</h2>
                        <div style={{flex: '1'}} />
                        <Button type="link" className="orange-link">
                            <Link to="/all-tutors">
                                View All Tutors
                            </Link>
                        </Button>
                    </div>
                    <br />
                    <FeaturedTutorsView />

                    <div style={{display: 'flex', alignItems: 'center', marginTop: '50px'}}>
                        <ClusterOutlined style={{ fontSize: '20px', color: 'black' }} />
                        &nbsp;&nbsp;&nbsp;
                        <h2 style={{height: '22px'}}>Featured Organizations</h2>
                        <div style={{flex: '1'}} />
                        <Button type="link" className="orange-link">
                        <Link to="/all-organizations">
                            View All Organizations
                        </Link>
                        </Button>
                    </div>
                    <br />
                    <UserList showFeaturedOrganizations horizontalMode miniMode={this.props.isSmall} customWidth={this.props.renderFullWidth ? '90vw' : '75vw'} />

                    <div style={{display: 'flex', alignItems: 'center', marginTop: '50px'}}>
                        <UnorderedListOutlined style={{ fontSize: '20px', color: 'black' }} />
                        &nbsp;&nbsp;&nbsp;
                        <h2 style={{height: '22px'}}>Learnerix Blog</h2>
                        <div style={{flex: '1'}} />
                        <Button type="link" className="orange-link">
                        <Link to="/blog">
                            View Blog
                        </Link>
                        </Button>
                    </div>
                    <br />
                    <HorizontalBlogPostView style={{width: this.props.renderFullWidth ? '90vw' : '75vw'}}  />
                </div>



                <h2 style={{height: '22px', marginTop: '50px'}}>What our tutors and organizations are posting</h2>
                <div style={{width: this.props.renderFullWidth ? '90vw' : '50vw', marginTop: '25px'}}>
                    <PostsView canAdd={false} allPosts />
                </div>
            </div>
        )
    }
}

export default withMediaQuery(DiscoveryPage);