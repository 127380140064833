import React from 'react';
import { Dropdown, Menu, Button } from 'antd';

import {  
    PlusCircleOutlined,
    EllipsisOutlined,
    HistoryOutlined,
    QuestionCircleOutlined,
    HeatMapOutlined,
    AlertOutlined
} from '@ant-design/icons';
import Api from '../../session/Api';
import UIUtil from '../../util/UIUtil';
import { useHistory, Link } from 'react-router-dom';
import Util from '../../util/Util';

export default function StudentCourseHelpSessionButton(props) {
    const helpSessionState = props.helpSessionState;

    const [loading, setLoading] = React.useState(false);
    const history = useHistory();

    const createNewRequest = () => {
        setLoading(true);
        Api.newHelpSessionRequest(props.courseId, response => {
            setLoading(false)
            if (response.status === true) {
                helpSessionState.hasCurrentRequest = true;
                helpSessionState.currentRequestStatus = 0;
                if (props.onUpdate !== undefined) {
                    props.onUpdate();
                }
                history.push("/active-help-session/" + props.courseId);
            } else {
                UIUtil.showError(response.message)
            }
        })
    }

    return (
        <Dropdown overlay={<Menu>
            {helpSessionState.hasCurrentRequest ? (
                <Menu.Item onClick={() => history.push("/active-help-session/" + props.courseId)} style={{height: '50px', display: 'flex', alignItems: 'center'}}>
                    <HeatMapOutlined style={{fontSize: '20px'}} /> Active Request
                </Menu.Item>
            ) : (
                <Menu.Item onClick={createNewRequest} style={{height: '50px', display: 'flex', alignItems: 'center'}}>
                    <PlusCircleOutlined style={{fontSize: '20px'}} /> New Request
                </Menu.Item>
            )}

            <Menu.Item onClick={() => history.push("/help-session/" + props.courseId)} style={{height: '50px', display: 'flex', alignItems: 'center'}}>
                <HistoryOutlined style={{fontSize: '20px'}} /> Request History
            </Menu.Item>

            {Util.isStringExists(props.liveStreamInProgress) && 
            <Menu.Item danger onClick={() => history.push(Util.getLiveStreamPath(props.liveStreamInProgress))} style={{height: '50px', display: 'flex', alignItems: 'center'}}>
                <AlertOutlined style={{fontSize: '20px'}} /> Join live stream
            </Menu.Item>}
            </Menu>} 
        trigger="click">
            <Button type="primary" size="large" className={Util.isStringExists(props.liveStreamInProgress) ? "red-button" : ""} loading={loading} style={{paddingLeft: '25px', paddingRight: '25px'}} {...props}><QuestionCircleOutlined /> Help Session</Button>
        </Dropdown> 
    );
}