import React from 'react';
import Page from '../base/Page';
import { Button, Radio, Input, Spin, Empty } from 'antd';

import { 
    PlusOutlined,
    UnorderedListOutlined,
    BookOutlined
} from '@ant-design/icons';
import Modal from 'antd/lib/modal/Modal';
import Api from '../session/Api';
import { withRouter, Link } from 'react-router-dom';
import StudentCourseItem from '../views/courses/StudentCourseItem';

import InfiniteScroll from 'react-infinite-scroller';
import { NO_PAGE_TOKEN, PAGINATION_PAGE_CONTENT_COUNT } from '../Constants';
import UIUtil from '../util/UIUtil';
import LiveSubscriptionDialog from '../components/LiveSubscriptionDialog';
import Util from '../util/Util';

class StudentMyCoursesPage extends Page {

    constructor(props) {
        super(props)

        this.state = {
            ...this.state, 
            items: [],
            page: NO_PAGE_TOKEN,
            hasMore: true,
            loadingItems: false,

            showSubscribeDialog: false,
        }
    }

    getNextCourses() {
        if (this.state.loadingItems) {
            return;
        }

        this.setState({
            loadingItems: true
        })

        const listener = response => {
            if (response.status === true) {
                let items = this.state.items;
                items.push(...response.payload.items);

                this.setState({
                    items: items,
                    page: response.payload.token,
                    hasMore: Util.isStringExists(response.payload.token),
                    loadingItems: false
                })
            } else {
                //alert('errowr');
            }
        }

        //list error
        if (this.props.organization !== undefined) {
            Api.getMyStudentOrganizationCourses(this.props.organization.id, this.state.page, listener);
        } else {
            Api.getMyStudentCourses(this.state.page, listener);
        }
    }

    render() {
        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div style={{width: '75vw', paddingTop: this.props.organization === undefined && '25px', paddingBottom: '25px'}}>
                    {this.props.organization === undefined && (<> 
                        <div style={{display: 'flex', alignItems: 'center'}}>
                        {/*<Radio.Group value={"grid"} style={{width: '200px'}}>
                            <Radio.Button value="grid"><AppstoreOutlined /></Radio.Button>
                            <Radio.Button value="list"><UnorderedListOutlined /></Radio.Button>
                        </Radio.Group>*/}
                        <h2 style={{height: '25px'}}>Courses</h2>
                        <div style={{flex: '1'}} />
                        <Button type="link">
                            <Link to="/all-courses">
                                <BookOutlined /> View All Courses
                            </Link>
                        </Button>
                        </div>
                        <br />
                    </>)}

                    <InfiniteScroll
                        loadMore={this.getNextCourses.bind(this)}
                        hasMore={this.state.hasMore}
                        threshold={750}
                        loader={
                            <div style={{display: 'flex', justifyContent: 'center', marginTop: '45px', marginBottom: '45px'}}>
                                <Spin size="large" tip="Loading..." />
                            </div>
                        }
                        style={{overflow: 'visible'}}>

                        {this.state.items.map((item) => {
                            return ( 
                                <div key={item.id}>
                                    <br />
                                    <StudentCourseItem course={item} onSubscribeBtn={() => this.setState({showSubscribeDialog: true})} />
                                </div>
                            )
                        })}
                    </InfiniteScroll>

                    {!this.state.loadingItems ? <Empty style={{marginTop: '50px'}} image={Empty.PRESENTED_IMAGE_SIMPLE} description={this.state.items.length > 0 ? "No more courses" : "No courses"} /> : null}
                    <br />
                </div>

                <LiveSubscriptionDialog visible={this.state.showSubscribeDialog} onCancel={() => this.setState({showSubscribeDialog: false})} />
            </div>
        );
    }

}

export default StudentMyCoursesPage;