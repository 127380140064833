import React from 'react';
import Modal from 'antd/lib/modal/Modal';
import { useEffect } from 'react';
import { Spin, Checkbox, Popconfirm, Button, Rate, Tag, Tooltip, Row, Col, Card, Progress, Popover } from 'antd';
import Api from '../session/Api';
import UIUtil from '../util/UIUtil';
import ProfilePicture from './ProfilePicture';
import { Link } from 'react-router-dom';
import Util from '../util/Util';
import StudentProfileDialog from './student-profile-dialog/StudentProfileDialog';
import { ACCOUNT_ROLE_STUDENT, ACCOUNT_ROLE_TUTOR, COURSE_ITEM_TYPE_FILE, COURSE_ITEM_TYPE_LECTURE_AUDIO, COURSE_ITEM_TYPE_LECTURE_VIDEO, COURSE_ITEM_TYPE_QUIZ, PRODUCT_TYPE_COURSE, PRODUCT_TYPE_LIVE_SESSION } from '../Constants';

import { 
    CloseOutlined,
    IdcardOutlined,
    BookOutlined,
    LineChartOutlined,
    HistoryOutlined,
    AlertOutlined,
    DollarCircleOutlined
} from '@ant-design/icons';
import { render } from '@testing-library/react';

const UserItem = ({user, onClick, privateAccessMode, privateAccessModeLiveSession, onItemRemoved, organziationCourseAccess, showOrganizationUsers, horizontalMode, showCourseStats, showCourseItemStats}) => {
    const [loading, setLoading] = React.useState(false);

    const onRemoveBtn = () => {
        setLoading(true);
        const listener = response => {
            if (response.status === true) {
                if (onItemRemoved !== undefined) {
                    onItemRemoved();
                }
            } else {
                UIUtil.showError();
            }
            setLoading(false);
        };

        if (privateAccessMode !== undefined) {
            if (privateAccessModeLiveSession === true) {
                Api.removeLiveSessionPrivateAccess(privateAccessMode, user.id, listener);
            } else {
                //privateAccessModeLiveSession
                Api.removeCoursePrivateAccess(privateAccessMode, user.id, listener);
            }
        } else if (organziationCourseAccess !== undefined) {
            if (organziationCourseAccess.role == ACCOUNT_ROLE_TUTOR) {
                if (organziationCourseAccess.courseId !== undefined) {
                    Api.removeTutorEditAccess(organziationCourseAccess.courseId, PRODUCT_TYPE_COURSE, user.id, listener);
                } else {
                    Api.removeTutorEditAccess(organziationCourseAccess.liveSessionId, PRODUCT_TYPE_LIVE_SESSION, user.id, listener);
                }
            } else {
                if (organziationCourseAccess.courseId !== undefined) {
                    Api.removeUserAccess(organziationCourseAccess.courseId, PRODUCT_TYPE_COURSE, user.id, listener);
                } else {
                    Api.removeUserAccess(organziationCourseAccess.liveSessionId, PRODUCT_TYPE_LIVE_SESSION, user.id, listener);
                }
            }
        } else if (showOrganizationUsers) {
            Api.revokeEnrollment(user.id, listener);
        } else {
            setLoading(false);
        }
    }

    if (horizontalMode) {
        return (
            <Card actions={[<Rate disabled defaultValue={user.averageRating} />]} hoverable style={{ width: '100%'}} onClick={onClick}>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                    <ProfilePicture size={75} showStatus={user.role === ACCOUNT_ROLE_TUTOR} userId={user.id} />
                    <h4 style={{textAlign: 'center', marginTop: '15px'}}>{user.fullName}</h4>
                </div>
            </Card>
        )
    } else {
        return (
            <div style={{display: 'flex', alignItems: 'center', cursor: 'pointer', marginBottom: '15px', boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)", borderRadius: '5px', padding: '15px'}} onClick={onClick}>
                <ProfilePicture showStatus={user.role === ACCOUNT_ROLE_TUTOR} userId={user.id} />
                <p style={{marginLeft: '15px', height: '12px'}}>{user.fullName}</p>
                {showOrganizationUsers && <Tag style={{marginLeft: '15px'}} color={user.role === ACCOUNT_ROLE_TUTOR ? "purple" : "orange"}>{user.role === ACCOUNT_ROLE_TUTOR ? 'Tutor' : 'Learner'}</Tag>}
                {showOrganizationUsers && <p style={{marginLeft: '15px', height: '5px', color: '#8a8a8a', fontSize: '10px'}}>Enrolled on {Util.getDate(user.enrollmentDate)}</p>}
                {Util.isNumberExist(user.subscriptionDate) && <p style={{marginLeft: '15px', height: '5px', color: '#8a8a8a', fontSize: '10px'}}>Subscribed on {Util.getDate(user.subscriptionDate)}</p>}

                

                <div style={{flex: '1'}} />

                {showCourseItemStats && showCourseItemStats.courseItemType == COURSE_ITEM_TYPE_QUIZ &&
                <Button disabled={user.bestQuizRecord === null} style={{marginRight: 15, fontSize: 12}} type="primary" onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    e.nativeEvent.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();
                    showCourseItemStats.onQuizAttemptHistoryBtn(user);
                }}>
                    <HistoryOutlined /> Attempt History
                </Button>}

                {!isNaN(user.averageRating) && <Rate disabled defaultValue={user.averageRating} />}
                {(user.subscribed == true && !showCourseItemStats) && <Button type="link" className="red-link" style={{cursor: 'default'}}><IdcardOutlined /> Subscribed</Button>}

                {Util.isNumberExist(user.subscriptionDate) &&
                <Popover content={
                    <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', width: 225}}> 
                        <h2><LineChartOutlined /> Statistics</h2>

                        <p style={{color: 'rgba(0,0,0,0.65)', fontSize: 12}}>
                            <DollarCircleOutlined /> Total Tutor Profit: <span style={{color: 'green'}}>$0</span>
                        </p>
                        <p style={{color: 'rgba(0,0,0,0.65)', fontSize: 12, marginTop: 0}}>
                            <BookOutlined /> Total Active Courses: <span style={{color: '#4e2e89'}}>0</span>
                        </p>
                        <p style={{color: 'rgba(0,0,0,0.65)', fontSize: 12, marginTop: 0}}>
                            <AlertOutlined /> Total Active Live Sessions: <span style={{color: '#4e2e89'}}>0</span>
                        </p>
                    </div>
                }>
                    <p style={{color: '#4e2e89', fontSize: 10}}><LineChartOutlined /> Last billing cycle stats</p>
                </Popover>
                /**/}

                {showCourseStats && (
                    <Tooltip title="Time Spent">
                        <Progress className="progress-purple-color" type="circle" percent={100} format={() => Util.formatSecondsBrief(user.totalTimeSpent / 1000)} 
                        style={{marginLeft: 10}} width={45} strokeWidth={7} strokeColor="#4e2e89" />
                    </Tooltip>
                )}

                {showCourseStats && (
                    <Tooltip title="Progress">
                        <Progress className="progress-non-white-color" type="circle" percent={user.progress} width={45} 
                        style={{marginLeft: 10}} strokeColor={user.progress < 100 && "#f26622"} strokeWidth={7} />
                    </Tooltip>
                )}


                {showCourseItemStats && <>
                    {(showCourseItemStats.courseItemType == COURSE_ITEM_TYPE_LECTURE_AUDIO || showCourseItemStats.courseItemType == COURSE_ITEM_TYPE_LECTURE_VIDEO) && <>
                        <Tooltip title="Time Spent">
                            <Progress className="progress-purple-color" type="circle" percent={100} format={() => Util.formatSecondsBrief(user.totalTimeSpent / 1000)} 
                            style={{marginLeft: 10}} width={45} strokeWidth={7} strokeColor="#4e2e89" />
                        </Tooltip>
                        <Tooltip title="Progress">
                            <Progress className="progress-non-white-color" type="circle" percent={user.progress} width={45} 
                            style={{marginLeft: 10}} strokeColor={user.progress < 100 && "#f26622"} strokeWidth={7} />
                        </Tooltip>
                    </>}

                    {(showCourseItemStats.courseItemType == COURSE_ITEM_TYPE_FILE) && <>
                        <p style={{marginLeft: '15px', height: '5px', color: '#8a8a8a', fontSize: '12px'}}>
                            {user.downloadDate > 0 ? (<>
                                Downloaded on <strong>{Util.getDate(user.downloadDate)}</strong>
                            </>) : (<>
                                Not downloaded
                            </>)}
                        </p>
                    </>}

                    {(showCourseItemStats.courseItemType == COURSE_ITEM_TYPE_QUIZ) && <>
                        <Tooltip title="Total Attempts">
                            <Progress className="progress-purple-color" type="circle" percent={100} format={() => user.totalQuizAttempts} 
                            style={{marginLeft: 10}} width={45} strokeWidth={7} strokeColor="#4e2e89" />
                        </Tooltip>
                        <Tooltip title={user.bestQuizRecord !== null ? ("Best Score - " + user.bestQuizRecord.score + "/" + user.bestQuizRecord.totalScore) : "Never attempted"}>
                            <Progress className="progress-non-white-color" type="circle" percent={(user.bestQuizRecord !== null ? user.bestQuizRecord.percent : 0)} width={45} 
                            format={value => value + "%"}
                            style={{marginLeft: 10}} strokeColor={(user.bestQuizRecord !== null ? user.bestQuizRecord.percent : 0) < 100 && "#f26622"} strokeWidth={7} />
                        </Tooltip>
                    </>}

                </>}

                {showOrganizationUsers && (<>
                    <Tooltip title="Course Access">
                        <Button type="link" style={{cursor: 'default'}}><BookOutlined /> {user.courseAccess}</Button>
                    </Tooltip>
                    
                    <Tooltip title="Live Session Access">
                        <Button type="link" style={{cursor: 'default'}}><AlertOutlined /> {user.liveSessionAccess}</Button>
                    </Tooltip>
                </>)}
                {(privateAccessMode !== undefined || organziationCourseAccess !== undefined || showOrganizationUsers) && 
                <Popconfirm title={showOrganizationUsers ? "Are you sure you want to revoke this enrollment?" : "Are you sure you want to remove this access?"} onCancel={e => e.stopPropagation()} onConfirm={e => {
                    e.stopPropagation();
                    onRemoveBtn();
                }} placement="topRight">
                    {showOrganizationUsers ? (
                        <Button danger type="link" size="small" onClick={e => e.stopPropagation()} loading={loading}><CloseOutlined /></Button>
                    ) : (
                        <Button danger type="link" size="large" onClick={e => e.stopPropagation()} loading={loading}><CloseOutlined /></Button>
                    )}
                </Popconfirm>}
            </div>
        )    
    }
}
export default function UserList(props) {
    const [loading, setLoading] = React.useState(true);
    const [users, setUsers] = React.useState([]);
    const [selectedUser, setSelectedUser] = React.useState(undefined);


    

    useEffect(() => {
        const listener = response => {
            setLoading(false);
            if (response.status === true) {
                setUsers(response.payload);
                /*for (const item of response.payload) {
                    setUsers([...response.payload, 
                        {...item, fullName: "James", progress: 65, subscribed: false, totalTimeSpent: 367400},
                        {...item, fullName: "Linda", progress: 25, subscribed: true, totalTimeSpent: 207400},
                        {...item, fullName: "Jack", progress: 100, subscribed: true, totalTimeSpent: 867400},
                        {...item, fullName: "Devin", progress: 95, subscribed: false, totalTimeSpent: 587400}])
                }*/
            } else {
                UIUtil.showError();
                if (props.onCancel !== undefined) {
                    props.onCancel();
                }
            }
        };

        if (props.searchValue !== undefined) {
            Api.searchTutors(props.searchValue, listener)
        } else if (props.organizationSearchValue !== undefined) {
            Api.searchOrganizations(props.organizationSearchValue, listener);
        } else if (props.learnerSearchValue !== undefined) {
            Api.searchLearners(props.learnerSearchValue, listener);
        } else if (props.coursePrivateAccessId !== undefined) {
            Api.getCoursePrivateAccessUsers(props.coursePrivateAccessId, PRODUCT_TYPE_COURSE, listener);
        } else if (props.liveSessionPrivateAccessId !== undefined) {
            Api.getCoursePrivateAccessUsers(props.liveSessionPrivateAccessId, PRODUCT_TYPE_LIVE_SESSION, listener);
        } else if (props.productAccessUsers !== undefined) {
            Api.getProductAccessUsers(props.productAccessUsers.id, props.productAccessUsers.type, listener);
        } else if (props.showCourseItemStats !== undefined) {
            Api.getCourseStudentAnalyticsUsers(props.showCourseItemStats.courseId, props.showCourseItemStats.courseItemId, listener);
        } else if (props.showFollow !== undefined) {
            Api.getFollowUsers(props.showFollow.tutorId, props.showFollow.showFollowers, listener);
        } else if (props.liveStreamId !== undefined) {
            Api.getLiveStreamViewers(props.liveStreamId, listener);
        } else if (props.showAll) {
            Api.getTutors(listener);
        } else if (props.showOrganizationUsers) {
            Api.getEnrolledUsers(listener);
        } else if (props.showOrganizationTutors !== undefined) {
            Api.getOrganizationTutors(props.showOrganizationTutors, listener);
        } else if (props.showTutorOrganizations !== undefined) {
            Api.getTutorOrganizations(props.showTutorOrganizations, listener);
        } else if (props.showEnrolledUserAccess !== undefined) {
            if (props.showEnrolledUserAccess.courseId !== undefined) {
                Api.getCourseAccessEnrolledUsers(props.showEnrolledUserAccess.courseId, PRODUCT_TYPE_COURSE, props.showEnrolledUserAccess.role, listener);
            } else {
                Api.getCourseAccessEnrolledUsers(props.showEnrolledUserAccess.liveSessionId, PRODUCT_TYPE_LIVE_SESSION, props.showEnrolledUserAccess.role, listener);
            }
        } else if (props.showFeaturedOrganizations) {
            Api.getFeaturedOrganizations(listener);
        } else if (props.showAllOrganizations) {
            Api.getAllOrganizations(listener);
        } else if (props.showCourseEditorTutors !== undefined) {
            Api.getCourseTutorEditors(props.showCourseEditorTutors, PRODUCT_TYPE_COURSE, listener)
        } else if (props.showLiveSessionEditorTutors !== undefined) {
            Api.getCourseTutorEditors(props.showLiveSessionEditorTutors, PRODUCT_TYPE_LIVE_SESSION, listener)
        } else if (props.showAllSubscribers) {
            Api.getAllSubscribers(listener);
        } else if (props.showAllLearners) {
            Api.getLearners(listener);
        } else if (props.showLikedByUsers !== undefined) {
            Api.getLikedByUsers(props.showLikedByUsers.parentId, props.showLikedByUsers.parentType, listener);
        } else {
            setLoading(false)
        }
    }, [props.searchValue, props.organizationSearchValue, props.learnerSearchValue, props.showCourseEditorTutors])

    const onUserRemoved = user => {
        const newUsers = [...users];
        const index = newUsers.indexOf(user);
        if (index != -1) {
            newUsers.splice(index, 1);
        }
        setUsers(newUsers);

        if (props.onUserRemovedListener) {
            props.onUserRemovedListener();
        }
    }

    if (props.onUserAddedMethodListener !== undefined) {
        props.onUserAddedMethodListener(user => {
            try {
                setUsers([user, ...users]);
            } catch (e) {
                
            }
        })
    }

    if (props.onMultipleUserAddedMethodListener !== undefined) {
        props.onMultipleUserAddedMethodListener(mulitpleUser => {
            try {
                setUsers([...mulitpleUser, ...users]);
            } catch (e) {
                
            }
        })
    }

    const renderHorizontal = () => {
        const items = (props.roleFilter === undefined ? users : users.filter(user => user.role === props.roleFilter));
        const elements = [];

        const count = props.miniMode ? 2 : 4;

        for (var i = 0; i < items.length + (items.length % count); i += count) {
            const itemSelection = items.slice(i, i + count);
            const span = 24 / count;
            
            elements.push(
                <Row gutter={15} style={{marginTop: i === 0 ? '0px' : '15px'}}>
                    {itemSelection.map(user => (
                        <Col span={span}>
                            {
                                //user.role === ACCOUNT_ROLE_STUDENT ? 
                                //<UserItem showCourseItemStats={props.showCourseItemStats} showCourseStats={props.showCourseStats} miniMode={props.miniMode} horizontalMode showOrganizationUsers={props.showOrganizationUsers} organziationCourseAccess={props.showEnrolledUserAccess} onItemRemoved={() => onUserRemoved(user)} privateAccessMode={props.coursePrivateAccessId !== undefined ? props.coursePrivateAccessId : props.liveSessionPrivateAccessId} privateAccessModeLiveSession={props.liveSessionPrivateAccessId !== undefined} selectable={props.selectable} onClick={() => setSelectedUser(user)} user={user} />
                                 //: 
                                <Link to={Util.getProfilePath(user.id)}>
                                    <UserItem showCourseItemStats={props.showCourseItemStats} showCourseStats={props.showCourseStats} miniMode={props.miniMode} horizontalMode showOrganizationUsers={props.showOrganizationUsers} organziationCourseAccess={props.showEnrolledUserAccess} onItemRemoved={() => onUserRemoved(user)} privateAccessMode={props.coursePrivateAccessId !== undefined ? props.coursePrivateAccessId : props.liveSessionPrivateAccessId} privateAccessModeLiveSession={props.liveSessionPrivateAccessId !== undefined} selectable={props.selectable} user={user} />
                                </Link>
                            }
                        </Col>    
                    ))}
                </Row>
            )
        }

        return elements;
    }

    return (
        <div style={{width: props.customWidth !== undefined ? props.customWidth : undefined}}>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                {props.hideIfEmpty ? (
                    users.length > 0 ? props.children : null
                ) : props.children}
                {props.message}
                {props.message !== undefined && <div style={{height: '15px'}} />}
                {!loading && users.length == 0 && props.emptyMessage !== undefined && <p>{props.emptyMessage}</p>}
            </div>
            {loading ? (
                <div style={{display: 'flex', justifyContent: 'center', marginTop: '45px', marginBottom: '45px'}}>
                    <Spin tip="Loading..." />
                </div>
            ) : (<>
                
                {props.horizontalMode ? (
                    renderHorizontal()
                ) : (
                    props.roleFilter === undefined ? users : users.filter(user => user.role === props.roleFilter)).map(user => 
                        //user.role === ACCOUNT_ROLE_STUDENT ? 
                        //<UserItem showCourseItemStats={props.showCourseItemStats} showCourseStats={props.showCourseStats} showOrganizationUsers={props.showOrganizationUsers} organziationCourseAccess={props.showEnrolledUserAccess} onItemRemoved={() => onUserRemoved(user)} privateAccessMode={props.coursePrivateAccessId !== undefined ? props.coursePrivateAccessId : props.liveSessionPrivateAccessId} privateAccessModeLiveSession={props.liveSessionPrivateAccessId !== undefined} selectable={props.selectable} onClick={() => setSelectedUser(user)} user={user} />
                         //: 
                        <Link to={Util.getProfilePath(user.id)}>
                            <UserItem showCourseItemStats={props.showCourseItemStats} showCourseStats={props.showCourseStats} showOrganizationUsers={props.showOrganizationUsers} organziationCourseAccess={props.showEnrolledUserAccess} onItemRemoved={() => onUserRemoved(user)} privateAccessMode={props.coursePrivateAccessId !== undefined ? props.coursePrivateAccessId : props.liveSessionPrivateAccessId} privateAccessModeLiveSession={props.liveSessionPrivateAccessId !== undefined} selectable={props.selectable} user={user} />
                        </Link>
                )}

            </>)}

            <StudentProfileDialog 
                showSendMessage
                visible={selectedUser !== undefined}
                name={selectedUser !== undefined ? selectedUser.fullName : ""}
                userId={selectedUser !== undefined ? selectedUser.id : 0}
                onCancel={() => setSelectedUser(undefined)} />
        </div>
    )
}