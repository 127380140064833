import React from 'react'
import Page from '../../base/Page';
import Api from '../../session/Api';
import Error404Page from '../Error404Page';
import { ROOM_PARENT_TYPE_HELP_SESSION, HELP_SESSION_STATUS_AWAITING_CONFIRM, HELP_SESSION_STATUS_ACTIVE, HELP_SESSION_STATUS_DONE } from '../../Constants';
import ChatView from '../../views/chats/ChatView';
import { PageHeader, Row, Col, Radio, Button } from 'antd';
import { withRouter } from 'react-router-dom';
import MessagesPage from '../messages-page/MessagesPage';

import {
    CheckOutlined,
} from '@ant-design/icons'

import './tutor-help-session-page.less';
import Util from '../../util/Util';
import UIUtil from '../../util/UIUtil';

class StudentHelpSessionHistoryPage extends Page {

    constructor(props) {
        super(props)

        this.state = {
            courseTitle: undefined,
            helpSessionAccessDoesNotExist: false,
        }
    }

    getCourseId() {
        return this.props.match.params.courseId;
    }

    onPageStart() {
        this.setState({loading: true})
        Api.getCourseHelpSessionInfo(this.getCourseId(), response => {
            if (response.status === true) {
                this.setState({
                    courseTitle: response.payload.courseTitle,
                    helpSessionAccessDoesNotExist: false,
                })
            } else {
                this.setState({
                    courseTitle: "",
                    helpSessionAccessDoesNotExist: true,
                })
            }
        })
    }

    render() {
        if (this.state.helpSessionAccessDoesNotExist) {
            return <Error404Page />
        }

        return (
            <div style={{flex: '1', height: '100%', display: 'flex', flexDirection: 'column'}}>
                <PageHeader
                    style={{background: 'rgb(237,240,243)'}}
                    className="site-page-header"
                    //onBack={() => this.props.history.goBack()}
                    title="Help Session"
                    subTitle={this.state.courseTitle}
                    />
                <div style={{flex: '1', display: 'flex'}}>
                    <div style={{flex: '1'}}>
                        <MessagesPage {...this.props} helpSession={{courseId: this.getCourseId(), status: HELP_SESSION_STATUS_DONE, tutor: false}} disableInput={true} />
                    </div>
                </div>
            </div>
        )
    }

}

export default withRouter(StudentHelpSessionHistoryPage);