import React from 'react'
import Page from '../base/Page';
import Api from '../session/Api';

import LiveSessionsView, { VIEW_MODE_CATEGORY_LIVE_SESSIONS } from '../views/live-sessions/LiveSessionsView'

import LifestyleCover from '../images/categories/LifestyleCover.jpg';
import EnglishCover from '../images/categories/EnglishCover.jpeg';
import MusicCover from '../images/categories/MusicCover.jpg';
import CookingCover from '../images/categories/CookingCover.jpg';
import FitnessCover from '../images/categories/FitnessCover.jpg';
import BusinessCover from '../images/categories/BusinessCover.jpg';
import AcademicsCover from '../images/categories/AcademicsCover.jpg';
import ArtCover from '../images/categories/ArtCover.jpg';
import PhotographyCover from '../images/categories/PhotographyCover.jpg';
import OtherCover from '../images/categories/OtherCover.jpg';

import { 
    CATEGORIES, 
    CATEGORY_LIFESTYLE, 
    CATEGORY_ENGLISH, 
    CATEGORY_MUSIC, 
    CATEGORY_COOKING, 
    CATEGORY_FITNESS, 
    CATEGORY_BUSINESS, 
    CATEGORY_ACADEMICS, 
    CATEGORY_ART, 
    CATEGORY_PHOTOGRAPHY, 
    CATEGORY_OTHER 
} from '../Categories';

class LiveSessionCategoryPage extends Page {

    constructor(props) {
        super(props);
        
        this.state = {
            ...this.state,
            category: undefined
        }
    }

    onPageStart() {
        for (let i = 0; i < CATEGORIES.length; i++) {
            const category = CATEGORIES[i];
            if (category.title.toLowerCase() === this.props.match.params.categoryName) {
                this.setState({
                    category: category
                })
                break;
            }
        }
    }

    getCover() {
        switch (this.state.category.id) {
            case CATEGORY_LIFESTYLE:
                return LifestyleCover;
            case CATEGORY_ENGLISH:
                return EnglishCover;
            case CATEGORY_MUSIC:
                return MusicCover;
            case CATEGORY_COOKING:
                return CookingCover;
            case CATEGORY_FITNESS:
                return FitnessCover;
            case CATEGORY_BUSINESS:
                return BusinessCover;
            case CATEGORY_ACADEMICS:
                return AcademicsCover;
            case CATEGORY_ART:
                return ArtCover;
            case CATEGORY_PHOTOGRAPHY:
                return PhotographyCover;
            case CATEGORY_OTHER:
                return OtherCover;
        }
    }

    render() {
        if (this.state.category === undefined) {
            return <> </>
        }
        
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <img src={this.getCover()}
                style={{height: '400px', width: '100%', objectFit: 'cover', position: 'absolute', zIndex: '-1'}} />

                <div style={{paddingTop: '240px', width: '75vw'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        {React.cloneElement(this.state.category.icon, {
                            style: {
                                color: 'white',
                                fontSize: '80px'
                            }
                        })}
                        <h2 style={{color: 'white', fontWeight: 'bold', fontSize: '100px', height: '100px', marginLeft: '25px'}}>{this.state.category.title}</h2>
                    </div>

                    <LiveSessionsView category={this.state.category.id} count={4} mode={VIEW_MODE_CATEGORY_LIVE_SESSIONS} />
                </div>
                
            </div>
        )
    }

}

export default LiveSessionCategoryPage;