import React from 'react';

import { 
    PlayCircleFilled,
} from '@ant-design/icons';

export default function VideoThumbnail(props) {
    return (
        <div style={{height: props.height !== undefined ? props.height : '100%', width: '100%', position: 'relative', cursor: props.notClickable ? 'default' : 'pointer'}}>
            <video controlslist="nodownload" disablePictureInPicture onContextMenu={e => e.preventDefault()} {...props} />
            {!props.hidePlay ? <PlayCircleFilled style={{fontSize: props.playSize === undefined ? '50px' : props.playSize, color: 'white', opacity: '0.60', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', pointerEvents: 'none'}} /> : null}
        </div>
    )
}