import React from 'react';
import { Avatar } from 'antd';
import Api from '../session/Api';

import {
    UserOutlined
} from '@ant-design/icons';
import Util from '../util/Util';
import { Link } from 'react-router-dom';
import SessionManager from '../session/SessionManager';
import PresenceIndicator from './PresenceIndicator';

export default function ProfilePicture(props) {
    const showStatus = props.showStatus;
    
    if (props.clickable) {
        return (
            <Link to={Util.getProfilePath(props.userId)}>
                {showStatus && props.userId !== undefined && props.userId !== SessionManager.getAccount().id ? (
                    <div style={{position: 'relative'}}>
                        <Avatar src={props.userId !== undefined ? Api.getProfilePic(props.userId) : Api.getMyProfilePic()} icon={<UserOutlined />} {...props} />
                        <PresenceIndicator realtime={props.realtime} style={{position: 'absolute', bottom: 0, right: -3}} userId={props.userId} />
                    </div>
                ) : (
                    <Avatar src={props.userId !== undefined ? Api.getProfilePic(props.userId) : Api.getMyProfilePic()} icon={<UserOutlined />} {...props} />
                )}
            </Link>
        )
    } else {
        return (
            showStatus && props.userId !== undefined && props.userId !== SessionManager.getAccount().id ? (
                <div style={{position: 'relative'}}>
                    <Avatar src={props.userId !== undefined ? Api.getProfilePic(props.userId) : Api.getMyProfilePic()} icon={<UserOutlined />} {...props} />
                    <PresenceIndicator realtime={props.realtime} style={{position: 'absolute', bottom: 0, right: -3}} userId={props.userId} />
                </div>
            ) : (
                <Avatar src={props.userId !== undefined ? Api.getProfilePic(props.userId) : Api.getMyProfilePic()} icon={<UserOutlined />} {...props} />
            )
        )
    }
}