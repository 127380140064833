import { Card, Tag } from 'antd';
import React from 'react'


import CoverImage from '../../images/CoverPlaceholder.jpg';
import Api from '../../session/Api';
import Util from '../../util/Util';

import { 
    CommentOutlined,
    LikeOutlined
} from '@ant-design/icons';
import { BLGO_POST_TAGS, BLOG_POST_TAG_TUTORIALS } from '../../BlogPostTags';
import { Link } from 'react-router-dom';
import withMediaQuery from '../../components/WithMediaQuery';


class ListBlogPostItem extends React.Component {

    constructor(props) {
        super(props);
    }

    getBlogPostTag() {
        for (const tag of BLGO_POST_TAGS) {
            if (tag.id == this.props.blogPost.tag) {
                return tag;
            }
        }

        return BLGO_POST_TAGS[0];
    }

    render() {
        const blogPost = this.props.blogPost;
        const tag = this.getBlogPostTag();

        return (
            <div style={{width: '100%'}}>
                <Link to={Util.getBlogPostPath(false, blogPost.title, blogPost.date)}>
                    <div className="blog-list-item" style={{display: 'flex', flexDirection: this.props.isSmall ? "column" : "row", width: '100%', borderTopLeftRadius: 5, borderTopRightRadius: 5, padding: 10}}>
                        <img src={Api.getBlogPostThumbnail(blogPost.id)} style={{width: this.props.isSmall ? "100%" : 350, height: 200, objectFit: 'cover', borderRadius: 5,}}
                        onError={e => {
                            e.target.onerror = null;
                            e.target.src = CoverImage;
                        }}/>

                        <div style={{width: 15, height: 15}} />

                        <div style={{flex: 1}}>
                            <div style={{display: 'flex', width: '100%'}}>
                                <h1 style={{fontStyle: 'bold', margin: 0, marginBottom: 0, marginTop: 0, color: 'black', lineHeight: 1}}>{blogPost.title}</h1>
                                <div style={{flex: 1}} />
                                <div>
                                    <Tag color={tag.color} style={{margin: 0, opacity: 1, fontSize: 14, fontWeight: 'lighter'}}>{tag.title}</Tag>
                                </div>
                            </div>
                            <div style={{display: 'flex', width: '100%', alignItems: 'center'}}>
                                <p style={{margin: 0, color: 'black', opacity: 0.65, fontSize: 14}}>{blogPost.userFullName}</p>
                                <div style={{marginLeft: 12, marginRight: 12, width: 4, height: 4, opacity: 0.65, background: 'black', borderRadius: '50%'}} />
                                <p style={{margin: 0, color: 'black', opacity: 0.65, fontSize: 14}}>{Util.getDateOnly(blogPost.date)}</p>
                                
                                <div style={{marginLeft: 12, marginRight: 12, width: 4, height: 4, opacity: 0.65, background: 'black', borderRadius: '50%'}} />

                                <p style={{margin: 0, color: 'black', opacity: 0.65, fontSize: 14}}><CommentOutlined style={{marginRight: 2}} /> {blogPost.commentCount}</p>
                                <div style={{marginLeft: 2, marginRight: 2, width: 4, height: 4, opacity: 0, background: 'black', borderRadius: '50%'}} />
                                <p style={{margin: 0, color: 'black', opacity: 0.65, fontSize: 14}}><LikeOutlined style={{marginRight: 2}} /> {blogPost.likeCount}</p>
                            </div>

                            <p style={{margin: 0, marginTop: 10, color: 'black', opacity: 0.65, fontSize: 16, overflowWrap: 'break-word', whiteSpace: 'pre-line'}}>
                                {blogPost.preview}
                            </p>
                        </div>
                    </div>
                </Link>

                <div style={{height: 1, width: '100%', background: 'black', opacity: 0.15}} /> 
            </div>
        )
    }

}

export default withMediaQuery(ListBlogPostItem);