import React from 'react'
import Api from '../../session/Api';
import { NO_PAGE_TOKEN, PAGINATION_PAGE_CONTENT_COUNT } from '../../Constants';
import { Empty, Spin, Row, Col } from 'antd';
import SaleCourseItem from './SaleCourseItem';
import InfiniteScroll from 'react-infinite-scroller';
import Util from '../../util/Util';

export const VIEW_MODE_TUTOR_COURSES = 0;
export const VIEW_MODE_CATEGORY_COURSES = 1;
export const VIEW_MODE_HASHTAG_COURSES = 2;

class CoursesView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            items: [],
            page: NO_PAGE_TOKEN,
            hasMore: true,
            loadingItems: false,
        }
    }

    getNextCourses() {
        if (this.state.loadingItems) {
            return;
        }

        this.setState({
            loadingItems: true
        })

        //list error
        const listener = response => {
            if (response.status === true) {
                let items = this.state.items;
                items.push(...response.payload.items);

                this.setState({
                    items: items,
                    page: response.payload.token,
                    hasMore: Util.isStringExists(response.payload.token),
                    loadingItems: false
                })
            } else {
                //alert('errowr');
            }
        }

        switch (this.props.mode) {
            case VIEW_MODE_TUTOR_COURSES:
                Api.getTutorCourses(this.props.tutorId, this.state.page, listener);
                break;
            case VIEW_MODE_CATEGORY_COURSES:
                Api.getCategoryCourses(this.props.category, this.state.page, listener);
                break;
            case VIEW_MODE_HASHTAG_COURSES:
                Api.getHashtagCourses(this.props.hashtag, this.state.page, listener);
                break;
        }
    }

    count() {
        return this.props.count !== undefined ? this.props.count : 2;
    }

    renderCourseItems() {
        const items = this.state.items;
        const elements = [];

        for (var i = 0; i < items.length + (items.length % this.count()); i += this.count()) {
            const itemSelection = items.slice(i, i + this.count());
            const span = 24 / this.count();
            
            elements.push(
                <Row gutter={15} style={{marginTop: i === 0 ? '0px' : '15px'}}>
                    {itemSelection.map(item => (
                        <Col span={span}>
                            <SaleCourseItem course={item} />
                        </Col>    
                    ))}
                </Row>
            )
        }

        return elements;
    }

    render() {
        return (
            <div>
                <InfiniteScroll
                    loadMore={this.getNextCourses.bind(this)}
                    hasMore={this.state.hasMore}
                    threshold={750}
                    loader={
                        <div style={{display: 'flex', justifyContent: 'center', marginTop: '45px', marginBottom: '45px'}}>
                            <Spin size="large" tip="Loading..." />
                        </div>
                    }
                    style={{overflow: 'visible'}}>

                    {this.renderCourseItems()}
                    {/*this.state.items.map((item, index) => {
                        return ( 
                            <div key={item.id}>
                                {index > 0 ? <br /> : null}
                                <SaleCourseItem />
                            </div>
                        )
                    })*/}
                </InfiniteScroll>
                {!this.state.loadingItems ? <Empty style={{marginTop: '50px'}} image={Empty.PRESENTED_IMAGE_SIMPLE} description={this.state.items.length > 0 ? "No more courses" : "No courses"} /> : null}
                <br />
            </div>
        )
    }

}

export default CoursesView;