import React from 'react'


import LiveIconView from './LiveIconView';
import { Button, Spin } from 'antd';


import { 
    LoadingOutlined,
    ApiTwoTone,
    HomeOutlined,
    ClockCircleTwoTone,
    CheckCircleTwoTone,
    ReloadOutlined,
    RocketTwoTone,
    ThunderboltTwoTone,
    InteractionTwoTone,
    EyeFilled,
    CustomerServiceFilled,
    PauseCircleTwoTone,
    ArrowLeftOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Util from '../../util/Util';
import SessionManager from '../../session/SessionManager';
import GetLiveStreamCreditsView from '../../pages/live-stream-credits/GetLiveStreamCreditsView';

export const BroadcastingQuestionView = ({theme, onBroadcaster, onViewer}) => (
    <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <img src={theme.mainLogo} style={{width: 120, marginBottom: 7}} />
        <LiveIconView />
        <div style={{height: 80}} />

        <InteractionTwoTone twoToneColor={theme.iconViewColors.activeAccentColor} style={{fontSize: 80}} />
        <h2 style={{margin: 0, marginTop: 25, color: theme.mainTextColor}}>{'Welcome, ' + SessionManager.getAccount().fullName + '!'}</h2>
        <p style={{color: theme.subtleTextColor, fontSize: 14, marginTop: -5}}>{'Join Live Stream as:'}</p>

        <div style={{height: 40}} />

        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <div className="live-stream-active-button" style={{marginRight: 7}} onClick={onBroadcaster}>
                <Button type="primary" size="medium" icon={<CustomerServiceFilled />}>
                    Broadcaster
                </Button>
            </div>
            <div className="live-stream-button" onClick={onViewer}>
                <Button type="primary" size="medium" icon={<EyeFilled />}>
                    Viewer
                </Button>
            </div>
        </div>
    </div>
)


export const LoadingMessageView = ({theme}) => (
    <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <img src={theme.mainLogo} style={{width: 120, marginBottom: 7}} />
        <LiveIconView />
        <div style={{height: 80}} />

        <ThunderboltTwoTone twoToneColor={theme.iconViewColors.activeAccentColor} style={{fontSize: 80}} />
        <h2 style={{margin: 0, marginTop: 25, color: theme.mainTextColor}}>Setting Everything Up</h2>
        <p style={{color: theme.subtleTextColor, fontSize: 14, marginTop: -5}}>Sit back and relax</p>

        <div style={{height: 40}} />

        <Spin indicator={<LoadingOutlined style={{ fontSize: 35, color: theme.iconViewColors.activeAccentColor }} spin />} style={{color: theme.iconViewColors.activeAccentColor}} />
    </div>
)

export const ErrorMessageView = ({icon, title, message, reconnectButton, onBackBtn, theme}) => (
    <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <img src={theme.mainLogo} style={{width: 120, marginBottom: 7}} />
        <LiveIconView />
        <div style={{height: 80}} />

        {React.createElement(icon, {
            twoToneColor: theme.iconViewColors.activeAccentColor,
            style: {
                fontSize: 80
            }
        })}
        <h2 style={{margin: 0, marginTop: 25, color: theme.mainTextColor}}>{Util.isStringExists(title) ? title : 'Something Went Wrong'}</h2>
        <p style={{color: theme.subtleTextColor, fontSize: 14, marginTop: -5}}>{message}</p>

        <div style={{height: 40}} />

        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            {reconnectButton &&
            <div className="live-stream-active-button" style={{marginRight: 7}} onClick={() => window.location.reload()}>
                <Button type="primary" size="medium" className="" icon={<ReloadOutlined />}>
                    {Util.isStringExists(reconnectButton) ? reconnectButton : 'Reconnect'}
                </Button>
            </div>}
            {onBackBtn ? (
                <div onClick={onBackBtn} className={reconnectButton ? "live-stream-button" : "live-stream-active-button"}>
                    <Button type="primary" size="medium" className="" icon={<ArrowLeftOutlined />}>
                        Go Back
                    </Button>
                </div>
            ) : (
                <Link to="/">
                    <div className={reconnectButton ? "live-stream-button" : "live-stream-active-button"}>
                        <Button type="primary" size="medium" className="" icon={<HomeOutlined />}>
                            Go Home
                        </Button>
                    </div>
                </Link>
            )}
        </div>
    </div>
)

export const LiveStreamOverMessage = ({totalTimeSpent, theme}) => (
    <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <img src={theme.mainLogo} style={{width: 120, marginBottom: 7}} />
        <LiveIconView />
        <div style={{height: 80}} />

        <CheckCircleTwoTone twoToneColor={theme.iconViewColors.activeAccentColor} style={{fontSize: 80}} />
        <h2 style={{margin: 0, marginTop: 25, color: theme.mainTextColor}}>Live Stream Has Ended</h2>
        <p style={{color: theme.subtleTextColor, fontSize: 14, marginTop: -5}}>Total time spent: {Util.formatSeconds(totalTimeSpent)}</p>

        <div style={{height: 40}} />

        <Link to="/">
            <div className="live-stream-active-button">
                <Button type="primary" size="medium" className="" icon={<HomeOutlined />}>
                    Go Home
                </Button>
            </div>
        </Link>
    </div>
)

export const LiveStreamCreditOverMessage = ({theme}) => (
    <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <img src={theme.mainLogo} style={{width: 120, marginBottom: 7}} />
        <LiveIconView />
        <div style={{height: 80}} />

        <PauseCircleTwoTone twoToneColor={theme.iconViewColors.activeAccentColor} style={{fontSize: 80}} />
        <h2 style={{margin: 0, marginTop: 25, color: theme.mainTextColor}}>Oops! Live stream credits have run out</h2>
        {false ? (
            <p style={{color: theme.subtleTextColor, fontSize: 14, marginTop: -5}}>Inform your organization to get more credits in order to continue the live stream</p>
        ) : (
            <p style={{color: theme.subtleTextColor, fontSize: 14, marginTop: -5}}>Get more credits in order to continue the live stream</p>
        )}

        <div style={{height: 40}} />

        <div style={{width: '50vw'}}>
            <GetLiveStreamCreditsView />
        </div>

        <div style={{height: 40}} />

        <Link to="/">
            <div className="live-stream-active-button">
                <Button type="primary" size="medium" className="" icon={<HomeOutlined />}>
                    Go Home
                </Button>
            </div>
        </Link>
    </div>
)