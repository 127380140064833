import React from 'react';
import { 
    QuestionCircleFilled,
    VideoCameraFilled,
    TabletFilled,
    FundFilled
} from '@ant-design/icons';
import { STREAM_TYPE_CAMERA_VIDEO, STREAM_TYPE_DESKTOP_VIDEO, STREAM_TYPE_WHITEBOARD } from '../streams/StreamTypes';

export const StreamIcon = ({streamType, ...props}) => {
    switch (streamType) {
        case STREAM_TYPE_CAMERA_VIDEO:
            return <VideoCameraFilled {...props} />;
        case STREAM_TYPE_DESKTOP_VIDEO:
            return <TabletFilled {...props} />;
        case STREAM_TYPE_WHITEBOARD:
            return <FundFilled {...props} />
        default:
            return <QuestionCircleFilled {...props} />;
    }
}