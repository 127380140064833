import React from 'react'

import Util from '../../util/Util';
import ProfilePicture from '../../components/ProfilePicture';
import { MESSAGE_TYPE_ATTACHMENT, MESSAGE_TYPE_TEXT, } from '../../Constants';

import AttachmentViewer from './AttachmentViewer';
import { Button, Tag, Row, Col, Modal, Popover } from 'antd';
import StudentProfileDialog from '../../components/student-profile-dialog/StudentProfileDialog';
import Api from '../../session/Api';
import UIUtil from '../../util/UIUtil';

import { 
    MoreOutlined,
    DeleteOutlined,
    EllipsisOutlined
} from '@ant-design/icons';
import SessionManager from '../../session/SessionManager';

class ChatBubble extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            audioDuration: 0,

            //learnerProfile: undefined,
            //showLearnerProfile: false,
            deleting: false,
            showingDeleteConfirm: false,
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        //if (nextProps.message.requestPlay === true || nextProps.message.requestPlay === false || nextProps.message.requestStop === true || ) {
        if (Util.isBooleanExist(nextProps.message.requestPlay) || Util.isBooleanExist(nextProps.message.requestStop)) {
            if (nextProps.message.requestPlay === false) {
                nextProps.message.requestPlay = undefined;
            }
            if (nextProps.message.requestStop === false) {
                nextProps.message.requestStop = undefined;
            }
            return true;
        }

        if (this.state.audioDuration !== nextState.audioDuration || this.state.deleting !== nextState.deleting || this.props.messageId !== nextProps.messageId || this.props.message !== nextProps.message) {
            return true;        
        }

        if (this.props.message.messageAttachment !== undefined) {
            return this.props.messageUploadProgress !== nextProps.messageUploadProgress;
        } else {
            return false;
        }
    }

    deleteMessage() {
        if (this.state.showingDeleteConfirm) {
            return;
        }

        this.setState({showingDeleteConfirm: true});
        Modal.confirm({
            title: "Confirm",
            content: "Are you sure you want to delete this message? This action is irreversible.",
            onOk: () => {
                this.setState({deleting: true, showingDeleteConfirm: false});
                const listener = response => {
                    if (response.status === true) {
                        if (this.props.onItemDeleted !== undefined) {
                            this.props.onItemDeleted();
                        }
                    } else {
                        UIUtil.showError();
                    }
                    this.setState({deleting: false})
                };

                if (this.props.liveStream) {
                    Api.deleteLiveStreamMessage(this.props.roomParentId, this.props.message.id, listener)
                } else {
                    Api.deleteMessage(this.props.message.id, listener)
                }
            },
            onCancel: () => {
                this.setState({showingDeleteConfirm: false})
            }
        })
    }

    render() {
        const message = this.props.message;
        //message.message = message.message + "woehfjkhfkjdshfkjdshfkjdhswoehfjkhfkjdshfkjdshfkjdhswoehfjkhfkjdshfkjdshfkjdhswoehfjkhfkjdshfkjdshfkjdhswoehfjkhfkjdshfkjdshfkjdhswoehfjkhfkjdshfkjdshfkjdhs"

        if (this.props.liveStream) {
            return (
                <div style={{width: '100%', paddingLeft: '5px', paddingRight: '15px', marginBottom: '15px'}}>
                    <div style={{width: '100%', paddingLeft: '10px', marginBottom: '5px', display: 'flex',}}>
                        <ProfilePicture style={{marginTop: '4px'}} clickable={false} userId={message.userId} size={25} />
                        <p style={{fontSize: '10px', height: '10px', marginLeft: '10px'}}>{message.userFullName}</p>
                        <div style={{flex: '1'}} />
                        <p style={{fontSize: '10px', height: '10px'}}>{Util.isStringExists(message.id) ? Util.getDate(message.date) : (message.id === -1 ? "Failed" : "Sending...")}</p>
                        
                        {Util.isStringExists(message.id) && (message.userId == SessionManager.getAccount().id || this.props.liveStreamIsBroadcaster) &&
                        <Popover content={
                            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', width: '175px'}}>
                                {/*<Divider style={{width: '100%', margin: 0, marginTop: 5, marginBottom: 5}} />*/}
                                <Button type="link" danger loading={this.state.deleting} onClick={this.deleteMessage.bind(this)}>
                                    <DeleteOutlined style={{marginRight: 8}} /> Delete
                                </Button>
                            </div>
                        } trigger="click">
                            <a type="link" style={{margin: 0, marginLeft: 15, marginTop: -3}}><EllipsisOutlined style={{fontWeight: 'bold', fontSize: 15}} /></a>
                        </Popover>}
                    </div>
                    <div style={{paddingLeft: '45px', marginTop: '-20px'}}>
                        
                        {message.id === -1 && message.type === MESSAGE_TYPE_TEXT ? (
                            <div style={{minHeight: '75px', paddingBlock: '10px', paddingLeft: '10px', paddingRight: '10px', marginTop: '10px', paddingTop: '10px', paddingBottom: '10px', marginBottom: '10px', position: 'relative'}}>
                                {Util.isStringExists(message.message) ? 
                                <p style={{overflowWrap: 'break-word', color: 'black', whiteSpace: 'pre-line'}}>{message.message}</p> : null}
                                <div style={{background: '#00000050', position: 'absolute', left: '0', top: '0', bottom: '0', right: '0', borderRadius: '25px 25px 25px 25px'}}>
                                    <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <Tag color="error" onClick={this.props.onResendMessage}>Failed to send. Click to retry.</Tag>
                                    </div>
                                </div>
                            </div>
                        ) : <p style={{overflowWrap: 'break-word', color: 'black', whiteSpace: 'pre-line'}}>{message.message}</p>}

                        {message.type === MESSAGE_TYPE_ATTACHMENT ?  
                            <AttachmentViewer liveStream black onResendMessage={this.props.onResendMessage} onDonePlayingAudio={this.props.onDonePlayingAudio} onStartedPlayingAudio={this.props.onStartedPlayingAudio} onAudioDurationUpdate={newDuration => this.setState({audioDuration: newDuration})} message={message} onMediaClick={this.props.onMediaClick} /> : null}
                    </div>
                    
                </div>
            );
        }

        if (message.self) {
            return (
                <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end', marginBottom: '15px'}}>
                    <div style={{maxWidth: '45%', borderRadius: '25px 2px 25px 25px', 
                        padding: '5px',
                        //background: '#4e2e89'}}>
                        background: 'rgb(238, 238, 238)'}}>
    

                        {message.type === MESSAGE_TYPE_ATTACHMENT ?  
                            <AttachmentViewer black onResendMessage={this.props.onResendMessage} onDonePlayingAudio={this.props.onDonePlayingAudio} onStartedPlayingAudio={this.props.onStartedPlayingAudio} onAudioDurationUpdate={newDuration => this.setState({audioDuration: newDuration})} message={message} onMediaClick={this.props.onMediaClick} /> : null}
    
                        {message.id === -1 && message.type === MESSAGE_TYPE_TEXT ? (
                            <div style={{minWidth: '250px', minHeight: '75px', paddingBlock: '10px', paddingTop: '10px', paddingBottom: '10px', marginBottom: '10px', position: 'relative'}}>
                                {Util.isStringExists(message.message) ? 
                                <p style={{padding: '5px 15px 0px 15px', overflowWrap: 'break-word', whiteSpace: 'pre-line', color: 'black'}}>{message.message}</p> : null}
                                <div style={{background: '#00000050', position: 'absolute', left: '0', top: '0', bottom: '0', right: '0', borderRadius: '25px 2px 25px 25px'}}>
                                    <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <Tag color="error" onClick={this.props.onResendMessage}>Failed to send. Click to retry.</Tag>
                                    </div>
                                </div>
                            </div>
                        ) : Util.isStringExists(message.message) ? 
                        <p style={{padding: '5px 15px 0px 15px', overflowWrap: 'break-word', whiteSpace: 'pre-line', color: 'black'}}>{message.message}</p> : null}
    

                        <div style={{display: 'flex'}}>
                            {this.state.audioDuration > 0 ? <h6 style={{padding: '0px 15px 5px 15px', color: 'black'}}>{Util.formatSeconds(this.state.audioDuration)}</h6> : null}
                            <div style={{flex: '1'}} />
                            <h6 style={{padding: '0px 15px 5px 15px', color: 'black', textAlign: 'end'}}>
                                {Util.isStringExists(message.id) ? Util.getDate(message.date) : (message.id === -1 ? "Failed" : "Sending...")}
                            
                                {Util.isStringExists(message.id) &&
                                <Popover content={
                                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', width: '175px'}}>
                                        {/*<Divider style={{width: '100%', margin: 0, marginTop: 5, marginBottom: 5}} />*/}
                                        <Button type="link" danger loading={this.state.deleting} onClick={this.deleteMessage.bind(this)}>
                                            <DeleteOutlined style={{marginRight: 8}} /> Delete
                                        </Button>
                                    </div>
                                } trigger="click">
                                    <a type="link" style={{margin: 0, marginLeft: 15}}><EllipsisOutlined style={{fontWeight: 'bold', fontSize: 15}} /></a>
                                </Popover>}
                            </h6>
                            
                            
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div style={{width: '100%', display: 'flex', justifyContent: 'start', marginBottom: '15px'}}>
                    <ProfilePicture clickable={false} userId={message.userId} size={40} />
                    {/*<ProfilePicture clickable={this.props.room.otherMember.role === ACCOUNT_ROLE_TUTOR} 
                    onClick={this.props.room.otherMember.role === ACCOUNT_ROLE_STUDENT ? e => this.setState({showLearnerProfile: true}) : undefined} 
                    style={this.props.room.otherMember.role === ACCOUNT_ROLE_STUDENT ? {cursor: 'pointer'} : undefined}
                    userId={this.props.room.otherMember.id} size="large" />*/}

                    <div style={{maxWidth: '45%', borderRadius: '2px 25px 25px 25px', 
                        padding: '5px',
                        background: '#ffffff', border: 'solid', borderColor: '#cecece', borderWidth: '1px', marginLeft: '15px'}}>
    
                        {message.type === MESSAGE_TYPE_ATTACHMENT ?  
                            <AttachmentViewer onDonePlayingAudio={this.props.onDonePlayingAudio} onStartedPlayingAudio={this.props.onStartedPlayingAudio} black flipBorder onAudioDurationUpdate={newDuration => this.setState({audioDuration: newDuration})} message={message} onMediaClick={this.props.onMediaClick} /> : null}
        
                        {message.message !== undefined && message.message !== null && message.message !== "" ? 
                            <p style={{padding: '5px 15px 0px 15px', overflowWrap: 'break-word', whiteSpace: 'pre-line', color: 'black'}}>{message.message}</p> : null}
        
                        <div style={{display: 'flex'}}>
                            {this.state.audioDuration > 0 ? <h6 style={{padding: '0px 15px 5px 15px', color: 'black'}}>{Util.formatSeconds(this.state.audioDuration)}</h6> : null}
                            <div style={{flex: '1'}} />
                            <h6 style={{padding: '0px 15px 5px 15px', color: 'black', textAlign: 'end'}}>{Util.isStringExists(message.id) ? Util.getDate(message.date) : (message.id === -1 ? "Failed" : "Sending...")}</h6>
                        </div>
                    </div>

                    {/*this.state.learnerProfile !== undefined ? (
                        <StudentProfileDialog 
                        visible={this.state.showLearnerProfile}
                        name={this.state.learnerProfile.fullName}
                        userId={this.state.learnerProfile.id}
                        onCancel={() => this.setState({showLearnerProfile: false})} />
                    ) : null*/}
                </div>
            )
        }
    }
}

export default ChatBubble;