import React from 'react'
import Page from '../../base/Page'

import {
    LeftOutlined,
    EditOutlined,
    UserOutlined
} from '@ant-design/icons'
import { Button, Row, Col, Avatar } from 'antd';

import RateChart from './RateChart';
import PostsView from '../../views/posts/PostsView';
import { RATING_ITEM_TYPE_TUTOR, RATING_ITEM_TYPE_COURSE, RATING_ITEM_TYPE_LIVE_SESSION } from '../../Constants';
import Api from '../../session/Api';
import Error404Page from '../Error404Page';
import UIUtil from '../../util/UIUtil';

import NoImage from '../../images/NoImage.png'
import ProductImagePlaceholder from '../../images/ProductImagePlaceholder.png';

class RatingsPage extends Page {
    
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            ratingDoesNotExist: false,
            ratingsReport: {},
        }
    }

    getItemId() {
        return this.props.itemId !== undefined ? this.props.itemId : this.props.match.params.itemId;
    }

    getItemType() {
        return this.props.itemType !== undefined ? this.props.itemType : this.props.match.params.itemType;
    }

    getItemTypeImage() {
        switch (parseInt(this.getItemType())) {
            case RATING_ITEM_TYPE_TUTOR:
                return Api.getProfilePic(this.getItemId());
            case RATING_ITEM_TYPE_COURSE:
                return Api.getCourseThumbnail(this.getItemId());
            case RATING_ITEM_TYPE_LIVE_SESSION:
                return Api.getLiveSessionThumbnail(this.getItemId())
            default:
                return NoImage;
        }
    }

    getItemTypeName() {
        switch (parseInt(this.getItemType())) {
            case RATING_ITEM_TYPE_TUTOR:
                //return "Tutor";
                return "";
            case RATING_ITEM_TYPE_COURSE:
                return "Course";
            case RATING_ITEM_TYPE_LIVE_SESSION:
                return "Live Session";
            default:
                return "";
        }
    }

    onPageStart() {
        Api.getRatings(this.getItemId(), this.getItemType(), response => {
            if (response.status === true) {
                this.setState({
                    ratingsReport: response.payload,
                    ratingDoesNotExist: false
                })
            } else {
                this.setState({
                    ratingsReport: {},
                    ratingDoesNotExist: true
                })
            }
        })
    }

    render() {
        if (this.state.ratingDoesNotExist) {
            return <Error404Page />
        }

        return (
            <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                <div style={{width: this.isComponentMode() ? '100%' : '75vw', paddingTop: this.isComponentMode() ? '0' : '25px'}}>
                    {!this.isComponentMode() && <Button type="link" onClick={() => this.props.history.goBack()}><LeftOutlined /> Go Back</Button>}
                

                    {this.isComponentMode() ? (
                        <div style={{marginTop: '50px'}}>
                            <RateChart ratingsReport={this.state.ratingsReport} />
                        </div>
                    ) : (
                        <Row style={{marginTop: '50px'}}>
                            <Col span={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                                {this.getItemType() == RATING_ITEM_TYPE_TUTOR ? (
                                    <Avatar src={this.getItemTypeImage()} size={200} icon={<UserOutlined />} />
                                ) : (
                                    <img src={this.getItemTypeImage()} style={{width: '300px', height: '175px', objectFit: 'cover', borderRadius: '15px'}} onError={e => {
                                        e.target.onerror = null;
                                        e.target.src = ProductImagePlaceholder;
                                    }} />
                                )}
                                <h2 style={{marginTop: '10px'}}>{this.state.pathParams.name}</h2>
                                <h4 style={{marginTop: this.state.pathParams.name !== undefined ? '-15px' : '', color: '#8a8a8a'}}>{this.getItemTypeName()}</h4>
                            </Col>
                            <Col span={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                                <RateChart ratingsReport={this.state.ratingsReport} />
                            </Col>
                        </Row>
                    )}
                    

                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '50px'}}>
                        {/*<Button type="primary" size="large"><EditOutlined /> Write a Review</Button>*/}

                        <h1 style={{marginTop: '50px', marginBottom: '25px'}}>All Reviews</h1>

                        <PostsView canAdd={this.state.ratingsReport.canReview} ratingPosts={true} ratingItemId={this.getItemId()} ratingItemType={this.getItemType()} />
                    </div>
                </div>
            </div>
        )
    }
}

export default RatingsPage;