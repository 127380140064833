import { Button, Dropdown, Input, Menu, Radio, Spin, Table, Tag, Tooltip } from 'antd';
import React, { useState } from 'react'
import Page from '../base/Page';
import withMediaQuery from '../components/WithMediaQuery';
import { ACCOUNT_ROLE_ORGANIZATION, ACCOUNT_ROLE_STUDENT, ACCOUNT_ROLE_TUTOR, PAGINATION_PAGE_CONTENT_COUNT } from '../Constants';

import { 
    UsergroupAddOutlined,
    MailOutlined,
    UserOutlined,
    AuditOutlined,
    EnterOutlined,
    SwapOutlined,
    ClusterOutlined,
    SearchOutlined,
    TableOutlined,
    TeamOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    CloseCircleFilled,
    CheckCircleFilled
} from '@ant-design/icons';
import UserList from '../components/UserList';
import Util from '../util/Util';
import { RadialChart } from 'react-vis';
import Api from '../session/Api';
import UIUtil from '../util/UIUtil';
import ProfilePicture from '../components/ProfilePicture';
import { Link } from 'react-router-dom';
import StudentProfileDialog from '../components/student-profile-dialog/StudentProfileDialog';

const FILTER_ALL_USERS = "-1";
const FILTER_LEARNERS = "0";
const FILTER_TUTORS = "1";
const FILTER_ORGANIZATIONS = "2";

class AllUsersPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            loading: true,
            allUsers: undefined,

            filter: FILTER_ALL_USERS,
            // userListId: Util.newTempId(),
            searchValue: "",
            // actualSearchValue: ""

            selectedUser: undefined
        }
    }
    
    componentDidMount() {
        this.setState({ loading: true })
        Api.getAllUsers(response => {
            if (response.status === true) {
                this.setState({ loading: false, allUsers: response.payload })
            } else {
                UIUtil.showError();
            }
        })
    }

    getData() {
        let list;
        switch (this.state.filter) {
            case FILTER_ALL_USERS:
                list = this.state.allUsers.allUsers;
                break;

            case FILTER_LEARNERS:
                list = this.state.allUsers.learners;
                break;
            
            case FILTER_TUTORS:
                list = this.state.allUsers.tutors;
                break;

            case FILTER_ORGANIZATIONS:
                list = this.state.allUsers.organizations
                break;
        }
        
        if (Util.isStringExists(this.state.searchValue)) {
            return list.filter(item => item.fullName.toLowerCase().includes(this.state.searchValue.toLowerCase()))
        } else {
            return list;
        }
    }

    // getUserListConfig() {
    //     switch (this.state.filter) {
    //         case FILTER_LEARNERS:
    //             return {
    //                 showAllLearners: true,
    //                 searchValue: undefined,
    //                 organizationSearchValue: undefined,
    //                 learnerSearchValue: Util.isStringExists(this.state.actualSearchValue) ? this.state.actualSearchValue : undefined,
    //             };
            
    //         case FILTER_TUTORS:
    //             return {
    //                 showAll: true,
    //                 searchValue: Util.isStringExists(this.state.actualSearchValue) ? this.state.actualSearchValue : undefined,
    //                 organizationSearchValue: undefined,
    //                 learnerSearchValue: undefined,
    //             };

    //         case FILTER_ORGANIZATIONS:
    //             return {
    //                 showAllOrganizations: true,
    //                 horizontalMode: true,
    //                 searchValue: undefined,
    //                 organizationSearchValue: Util.isStringExists(this.state.actualSearchValue) ? this.state.actualSearchValue : undefined,
    //                 learnerSearchValue: undefined,
    //             };
    //     }
    // }

    /*
     
    private String fullName;

    private String emailAddress;
    private String phoneNumber;
    private int role;

    private int totalOwnedCourses;
    private int totalOwnedLiveSessions;

    private int totalTutoringCourses;
    private int totalTutoringLiveSessions;

    private int currentLiveStreamCredit;
    private int usedLiveStreamMinutes;

    private int totalUsersUsed;

    private boolean deactivated; 
    */

    columns = [
        {
            title: 'Name',
            dataIndex: 'id',
            key: 'fullName',
            render: id => {
                let item;
                for (const user of this.state.allUsers.allUsers) {
                    if (user.id == id) {
                        item = user;
                        break;
                    }
                }

                if (item == undefined) {
                    return undefined;
                }

                return (<div style={{display: 'flex', alignItems: 'center', gap: 5}}>
                    <ProfilePicture userId={id} />
                    {item.fullName}
                </div>)
            }
        },
        {
            title: 'Email Address',
            dataIndex: 'emailAddress',
            key: 'emailAddress',
        },
        {
            title: 'Phone Number',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
            render: phoneNumber => Util.isStringExists(phoneNumber) ? phoneNumber : '-'
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            render: role => {
                switch (role) {
                    case ACCOUNT_ROLE_STUDENT:
                        return "Learner";
                    case ACCOUNT_ROLE_TUTOR:
                        return "Tutor";
                    case ACCOUNT_ROLE_ORGANIZATION:
                        return "Organization";
                }
            }
        },
        // {
        //     title: 'Stats',
        //     dataIndex: 'id',
        //     key: 'stats',
        //     render: id => {
        //         let item;
        //         for (const user of this.state.allUsers.allUsers) {
        //             if (user.id == id) {
        //                 item = user;
        //                 break;
        //             }
        //         }

        //         if (item == undefined) {
        //             return undefined;
        //         }

        //         return (<div style={{display: 'flex', flexDirection: 'column', gap: 5}}>
        //             <h6 style={{margin: 0, marginBottom: -5}}>Owned Courses</h6>
        //             <p style={{}}>{item.totalOwnedCourses}</p>

        //             <h6 style={{margin: 0, marginBottom: -5, marginTop: 0}}>Owned Courses</h6>
        //             <p style={{}}>{item.totalOwnedCourses}</p>
        //         </div>)
        //     }
        // },
        // {
        //     title: 'Access',
        //     dataIndex: 'deactivated',
        //     key: 'deactivated',
        //     render: deactivated => (
        //         deactivated ? (
        //             <Tag color="green" icon={<CheckCircleOutlined />}>Active</Tag>
        //         ) : (
        //             <Tag color="red" icon={<CloseCircleOutlined />}>Deactivated</Tag>
        //         )
        //     )
        // },
        {
            title: '',
            dataIndex: 'id',
            key: 'options',
            render: id => {
                let item;
                for (const user of this.state.allUsers.allUsers) {
                    if (user.id == id) {
                        item = user;
                        break;
                    }
                }

                if (item == undefined) {
                    return undefined;
                }

                return (<div style={{display: 'flex', alignItems: 'center', gap: 5}}>
                    {/* {
                        !item.deactivated ? (
                            <Tooltip title="Activate user's access">
                                <Button type="link"><CheckCircleFilled /></Button>
                            </Tooltip>
                        ) : (
                            <Tooltip title="Deactivate user's access">
                                <Button type="link" className="red-link"><CloseCircleFilled /></Button>
                            </Tooltip>
                        )
                    } */}
                    <Tooltip title="View Profile">
                        {
                            //item.role === ACCOUNT_ROLE_STUDENT ? 
                            //<Button type="link" onClick={() => this.setState({selectedUser: item})}><UserOutlined /></Button>
                             //: 
                            <Link to={Util.getProfilePath(item.id)} target="_blank">
                                <Button type="link"><UserOutlined /></Button>
                            </Link>
                        }
                    </Tooltip>
                </div>)
            }
        },
    ]

    exportToSpreadsheet() {
        const data = this.getData()

        const rows = data.map(user => {
            let fullName = user.fullName;
            let emailAddress = user.emailAddress;
            let phoneNumber = user.phoneNumber;

            let role;
            switch (user.role) {
                case ACCOUNT_ROLE_STUDENT:
                    role = "Learner";
                    break;
                case ACCOUNT_ROLE_TUTOR:
                    role = "Tutor";
                    break;
                case ACCOUNT_ROLE_ORGANIZATION:
                    role = "Organization";
                    break;
            }

            return [
                fullName,
                emailAddress,
                phoneNumber,
                role
            ]
        })
        
        let csvContent = "data:text/csv;charset=utf-8,";
        
        [["Full Name", "Email Address", "Phone Number", "Role"], ...rows].forEach(function(rowArray) {
            let row = rowArray.join(",");
            csvContent += row + "\r\n";
        });

        const encodedUri = encodeURI(csvContent);

        const a = document.createElement("a");
        a.href = encodedUri;
        a.download = "LearnerixUserReport.csv";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    render() {
        if (this.state.loading) {
            return (
                <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingTop: 45, paddingBottom: 45}}>
                    <Spin size="large" tip="Loading..." />
                </div>
            )
        }

        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div style={{width: this.props.renderFullWidth ? '90vw' : '90vw', paddingTop: '25px', paddingBottom: '25px'}}>
                    <div style={{display: 'flex', marginBottom: 15}}>
                        <div style={{flex: 1}}>
                            <h1 style={{margin: 0}}>User Management</h1>

                            <h1 style={{margin: 0, marginTop: 0, fontSize: 16, color: 'black'}}>All Users</h1>
                            <h1 style={{fontSize: 45, color: '#f26622', margin: 0, marginTop: -5}}><TeamOutlined /> {this.state.allUsers.totalUsers}</h1>

                            <div style={{marginTop: 10, display: 'flex', alignItems: 'center'}}>
                                <h1 style={{margin: 0, fontSize: 16, color: 'black'}}>Learners</h1>
                                <div style={{width: 7, height: 7, borderRadius: '50%', background: '#f26622', marginLeft: 5}} />
                            </div>
                            <h1 style={{fontSize: 35, color: '#4e2e89', margin: 0, marginTop: -5}}><UserOutlined /> {this.state.allUsers.totalLearners}</h1>

                            <div style={{marginTop: 10, display: 'flex', alignItems: 'center'}}>
                                <h1 style={{margin: 0, fontSize: 16, color: 'black'}}>Tutors</h1>
                                <div style={{width: 7, height: 7, borderRadius: '50%', background: '#4e2e89', marginLeft: 5}} />
                            </div>
                            <h1 style={{fontSize: 35, color: '#4e2e89', margin: 0, marginTop: -5}}><AuditOutlined /> {this.state.allUsers.totalTutors}</h1>

                            <div style={{marginTop: 10, display: 'flex', alignItems: 'center'}}>
                                <h1 style={{margin: 0, fontSize: 16, color: 'black'}}>Organizations</h1>
                                <div style={{width: 7, height: 7, borderRadius: '50%', background: '#000000', marginLeft: 5}} />
                            </div>
                            <h1 style={{fontSize: 35, color: '#4e2e89', margin: 0, marginTop: -5}}><ClusterOutlined /> {this.state.allUsers.totalOrganizations}</h1>
                        </div>
                        <RadialChart innerRadius={100}
                        radius={140} 
                        // showLabels
                        labelsStyle={{fontSize: 16, fontWeight: 'bolder',}}
                        colorType="literal"
                        data={[
                            {
                                label: "Total Learners",
                                angle: this.state.allUsers.totalLearners / this.state.allUsers.totalUsers, 
                                color: "#f26622"
                            }, 
                            {
                                label: "Total Tutors",
                                angle: this.state.allUsers.totalTutors / this.state.allUsers.totalUsers, 
                                color: "#4e2e89"
                            }, 
                            {
                                label: "Total Organizations",
                                angle: this.state.allUsers.totalOrganizations / this.state.allUsers.totalUsers, 
                                color: '#000000'
                            }
                        ]} width={300} height={300} />
                    </div>

                    <Table rowKey="id" columns={this.columns} dataSource={this.getData()} title={() => (
                    <div style={{display: 'flex'}}>
                        {<Radio.Group value={this.state.filter} onChange={e => this.setState({filter: e.target.value})} buttonStyle="solid" size="medium">
                            <Radio.Button value={FILTER_ALL_USERS}><TeamOutlined /> All Users</Radio.Button>
                            <Radio.Button value={FILTER_LEARNERS}><UserOutlined /> Learners</Radio.Button>
                            <Radio.Button value={FILTER_TUTORS}><AuditOutlined /> Tutors</Radio.Button>
                            <Radio.Button value={FILTER_ORGANIZATIONS}><ClusterOutlined /> Organizations</Radio.Button>
                        </Radio.Group>}
                        <Input style={{width: '25%', marginLeft: 5}} placeholder="Search full name" value={this.state.searchValue} onChange={e => this.setState({searchValue: e.target.value})} 
                        onPressEnter={() => this.setState({actualSearchValue: this.state.searchValue})}/>
                        <div style={{flex: 1}} />
                        <Button onClick={this.exportToSpreadsheet.bind(this)} type="primary"><TableOutlined /> Export to spreadsheet</Button>
                    </div>
                )} />
                </div>

                <StudentProfileDialog
                showSendMessage
                visible={this.state.selectedUser !== undefined}
                name={this.state.selectedUser !== undefined ? this.state.selectedUser.fullName : ""}
                userId={this.state.selectedUser !== undefined ? this.state.selectedUser.id : 0}
                onCancel={() => this.setState({selectedUser: undefined})} />
            </div>
        )

        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div style={{width: this.props.renderFullWidth ? '90vw' : '75vw', paddingTop: '25px', paddingBottom: '25px'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <h2 style={{height: '25px'}}>Users</h2>
                        {<Radio.Group value={this.state.filter} onChange={e => this.setState({filter: e.target.value, userListId: Util.newTempId(), searchValue: ""})} buttonStyle="solid" size="medium" style={{marginLeft: '15px'}}>
                            <Radio.Button value={FILTER_LEARNERS}><UserOutlined /> Learners</Radio.Button>
                            <Radio.Button value={FILTER_TUTORS}><AuditOutlined /> Tutors</Radio.Button>
                            <Radio.Button value={FILTER_ORGANIZATIONS}><ClusterOutlined /> Organizations</Radio.Button>
                        </Radio.Group>}
                        <div style={{flex: 1}} />
                        <Input style={{width: '25%'}} placeholder="Search..." value={this.state.searchValue} onChange={e => this.setState({searchValue: e.target.value})} 
                        onPressEnter={() => this.setState({actualSearchValue: this.state.searchValue})}/>
                        <Button type="primary" style={{marginLeft: '10px'}} onClick={() => this.setState({actualSearchValue: this.state.searchValue})}><SearchOutlined /></Button>
                    </div>
                    <br />
                    <div key={this.state.userListId}>
                        <UserList {...this.getUserListConfig()} />
                    </div>
                </div>
            </div>
        )
    }

}

export default withMediaQuery(AllUsersPage);