class DesktopStream {

    startStream(listener) {
        var mediaOptions = { audio: false, video: true };
      
        if (!navigator.mediaDevices.getDisplayMedia){
            listener(false);
            return;
        }
      
        navigator.mediaDevices.getDisplayMedia(mediaOptions).then((stream) => {
            this.stream = stream;

            /*stream.oninactive = (event) => {
                listener(false);
            };*/

            listener(true);
        }, (error) => {
            //console.log(error)
            listener(false);
        });
    }

    closeStream() {
        if (this.stream !== undefined) {
            this.stream.getTracks().forEach(track => {
                track.stop();
            });
        }
    }

}

export default DesktopStream;