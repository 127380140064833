import React from 'react';

import NoImage from '../../images/NoImage.png';
import ProductImagePlaceholder from '../../images/ProductImagePlaceholder.png';
import { Button, Tag, Badge, Menu, Dropdown, Tooltip } from 'antd';

import {  
    InfoCircleOutlined, 
    UnorderedListOutlined,
    EditOutlined,
    QuestionCircleOutlined,
    LinkOutlined,
    MessageOutlined,
    SnippetsOutlined,
    AlertOutlined,
    LockOutlined,
    EyeInvisibleOutlined,
    ClusterOutlined,
    HeatMapOutlined
} from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import Api from '../../session/Api';
import Util from '../../util/Util';

export default function TutorCourseItem(props) {
    const history = useHistory();

    const course = props.course;
    return (
        <div style={{width: '100%', borderRadius: '15px', padding: '15px', boxShadow: "0px 0px 25px 0px rgba(0, 0, 0, 0.2)"}}>
            <div style={{display: 'flex'}}>
                <Link to={'/my-course/' + course.id + '?tab=content'} style={{width: '20%', height: '115px'}}>
                    <img src={Api.getCourseThumbnail(course.id)} style={{width: '100%', height: '115px', objectFit: 'cover', borderRadius: '15px'}} onError={e => {
                        e.target.onerror = null;
                        e.target.src = ProductImagePlaceholder;
                    }} />
                </Link>
                <div style={{marginLeft: '25px', flex: 1, minWidth: 1}}>
                    <div style={{display: 'flex', alignItems: 'center', marginRight: '15px'}}>
                        <Link to={'/my-course/' + course.id + '?tab=content'}><h2 style={{height: '25px'}}>{course.title}</h2></Link>
                        {(course.organizationPrivateAccess) && <Tooltip title="Organization"><Button style={{cursor: 'default'}} type="link"><LockOutlined /> <ClusterOutlined /></Button></Tooltip>}
                        {(!course.organizationPrivateAccess && course.privateAccess) && <Tooltip title="Private"><Button style={{cursor: 'default'}} type="link"><LockOutlined /></Button></Tooltip>}
                        {(!course.organizationPrivateAccess && !course.privateAccess && course.unlisted) && <Tooltip title="Unlisted"><Button style={{cursor: 'default'}} type="link"><EyeInvisibleOutlined /></Button></Tooltip>}

                        <div style={{flex: '1'}} />
                        
                        {Util.isStringExists(course.liveStreamInProgress) && 
                            <Button type="link" className="red-link" style={{cursor: 'default'}}><AlertOutlined /> Live</Button>}

                        
                        
                        <Badge count={course.activeHelpSessionRequests} overflowCount={9}>
                            <Link to={"/my-help-session/" + course.id}>
                                <Tag style={{marginRight: course.activeHelpSessionRequests > 9 ? undefined : 2, cursor: 'pointer'}} icon={<SnippetsOutlined />} color="blue">Requests</Tag>
                            </Link>
                        </Badge>
                        <div style={{width: '25px'}} />
                        <Badge count={course.activeHelpSessionRequestsActiveStatus} overflowCount={9}>
                            <Link to={"/my-help-session/" + course.id + "?initialShowActive=true"}>
                                <Tag style={{marginRight: course.activeHelpSessionRequestsActiveStatus > 9 ? undefined : 2, cursor: 'pointer'}} icon={<HeatMapOutlined />} color="blue">Active</Tag>
                            </Link>
                        </Badge>
                        <div style={{width: '25px'}} />
                        <Badge count={course.unreadHelpSessionMessages} overflowCount={9}>
                            <Link to={"/my-help-session/" + course.id + "?initialShowActive=true"}>
                                <Tag style={{marginRight: course.unreadHelpSessionMessages > 9 ? undefined : 2}} icon={<MessageOutlined />} color="blue">Messages</Tag>
                            </Link>
                        </Badge>
                        
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{borderRadius: '50%', marginRight: '10px', background: course.published ? '#009900' : '#faad14', width: '10px', height: '10px'}} />
                        <h4 style={{height: '16px'}}>{course.published ? 'Published' : 'Unpublished'}</h4>
                    </div>
                    <br />
                    {Util.isNumberExist(course.learnerCount) && 
                    <p style={{paddingTop: '5px', fontSize: '12px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', flex: '1'}}>({course.learnerCount} Learner{course.learnerCount > 1 && 's'})</p>}
                    {/*<p style={{paddingTop: '5px', fontSize: '12px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', flex: '1'}}>(5 Learners)</p>*/}
                    {/*<p style={{paddingTop: '5px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', flex: '1'}}></p>*/}
                </div>
            </div>

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '15px', width: '100%'}}>
                <div style={{flex: 1}} />

                <Button type="link" disabled={!course.published}>
                    <Link to={'/course-sale/' + course.id}><LinkOutlined /> Store Link</Link>
                </Button>

                {props.organization === undefined &&
                <Button type="link">
                    <Link to={'/my-course/' + course.id + '?tab=details'}><InfoCircleOutlined /> Details</Link>
                </Button>}

                <Button type="link">
                    <Link to={'/my-course/' + course.id + '?tab=announcements'}>
                        <UnorderedListOutlined /> Announcements
                    </Link>
                </Button>
                <Button type="link">
                    <Link to={'/my-course/' + course.id + '?tab=content'}>
                        <EditOutlined /> Content
                    </Link>                    
                </Button>
                {Util.isStringExists(course.liveStreamInProgress) ? (
                    <Dropdown overlay={<Menu>
                        <Menu.Item onClick={() => history.push("/my-help-session/" + course.id)} style={{height: '50px', display: 'flex', alignItems: 'center'}}>
                            <QuestionCircleOutlined style={{fontSize: '20px'}} /> Help Sessions
                        </Menu.Item>
                        <Menu.Item danger onClick={() => history.push(Util.getLiveStreamPath(course.liveStreamInProgress))} style={{height: '50px', display: 'flex', alignItems: 'center'}}>
                            <AlertOutlined style={{fontSize: '20px'}} /> Join live stream
                        </Menu.Item>
                    </Menu>} 
                    trigger="click">
                        <Button type="primary" className="red-button" style={{marginLeft: '15px'}}>
                            <QuestionCircleOutlined /> Help Session
                        </Button>
                    </Dropdown> 
                ) : (
                    <Button type="primary" style={{marginLeft: '15px'}}>
                        <Link to={'/my-help-session/' + course.id}>
                            <QuestionCircleOutlined /> Help Session
                        </Link>
                    </Button>
                )}
            </div>
        </div>
    )
}