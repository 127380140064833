import React from 'react'
import ProfilePicture from '../../components/ProfilePicture';
import { ACCOUNT_ROLE_STUDENT, NOTIFICATION_CLICK_EVENT_BALANCE_PAGE, NOTIFICATION_CLICK_EVENT_LIVE_STREAM, NOTIFICATION_CLICK_EVENT_MESSAGE, NOTIFICATION_CLICK_EVENT_NONE, NOTIFICATION_CLICK_EVENT_POST, NOTIFICATION_CLICK_EVENT_PROFILE, NOTIFICATION_PAYLOAD_TYPE_COURSE, NOTIFICATION_PAYLOAD_TYPE_LIVE_SESSION, NOTIFICATION_PAYLOAD_TYPE_USER } from '../../Constants';
import Api from '../../session/Api';
import Util from '../../util/Util';
import { 
    BellFilled,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import StudentProfileDialog from '../../components/student-profile-dialog/StudentProfileDialog';
import { Modal } from 'antd';

class NotificationItem extends React.Component {

    hasLink() {
        return this.props.notification.clickEvent > NOTIFICATION_CLICK_EVENT_NONE;
    }

    getLink() {
        switch (this.props.notification.clickEvent) {
            case NOTIFICATION_CLICK_EVENT_POST:
                return "/post/" + this.props.notification.clickEventPayload.eventId;
            case NOTIFICATION_CLICK_EVENT_PROFILE:
                //return this.props.notification.clickEventPayload.userRole != ACCOUNT_ROLE_STUDENT ? Util.getProfilePath(this.props.notification.clickEventPayload.eventId) : undefined;
                return Util.getProfilePath(this.props.notification.clickEventPayload.eventId);
            case NOTIFICATION_CLICK_EVENT_BALANCE_PAGE:
                return "/balance"
            case NOTIFICATION_CLICK_EVENT_MESSAGE:
                return "/messages?userId=" + this.props.notification.clickEventPayload.eventId
            case NOTIFICATION_CLICK_EVENT_LIVE_STREAM:
                return Util.getLiveStreamPath(this.props.notification.clickEventPayload.eventId);
        }
    }

    renderThumbnail() {
        const notification = this.props.notification;
        switch (notification.payloadType) {
            case NOTIFICATION_PAYLOAD_TYPE_USER:
                return <ProfilePicture size={50} userId={notification.payloadId} />
            case NOTIFICATION_PAYLOAD_TYPE_COURSE:
                return (
                    <div style={{width: 50, height: 50, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'relative'}}>
                        <img src={Api.getCourseThumbnail(notification.payloadId)} style={{width: 52, height: 52, borderRadius: 15, objectFit: 'cover'}} />
                        <div style={{position: 'absolute', bottom: -4, right: -4}}>
                            <ProfilePicture src={Api.getCourseTutorProfilePic(notification.payloadId)} size={22} style={{ borderWidth: 1, borderColor: 'white' }} />
                        </div>
                    </div>
                )
            case NOTIFICATION_PAYLOAD_TYPE_LIVE_SESSION:
                return (
                    <div style={{width: 50, height: 50, display: 'flex' , flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'relative'}}>
                        <img src={Api.getLiveSessionThumbnail(notification.payloadId)} style={{width: 52, height: 52, borderRadius: 15, objectFit: 'cover'}} />
                        <div style={{position: 'absolute', bottom: -4, right: -4}}>
                            <ProfilePicture src={Api.getLiveSessionTutorProfilePic(notification.payloadId)} size={22} style={{ borderWidth: 1, borderColor: 'white' }} />
                        </div>
                    </div>
                )
        }

        //return <AnimatedButton style={{width: 50, height: 50}} status="basic" 
        //accessoryLeft={props => <NotificationIcon {...props} fill="#4e2e89" width={18} height={18} />}  />;
        return (
            <div style={{width: 50, height: 50, borderRadius: 30, backgroundColor: 'rgb(233,236,245)', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <BellFilled style={{fontSize: 18, color: "#4e2e89"}} />
            </div>
        )
    }

    render() {
        const style = this.props.style !== undefined ? this.props.style : {};
        const notification = this.props.notification;
        const miniMode = this.props.miniMode;

        const content = (
            <div style={{paddingHorizontal: 20, paddingVertical: 15, display: 'flex', flexDirection: 'row', backgroundColor: !notification.seen && 'rgba(78, 46, 137, 0.08)', 
             ...style}}>
                {this.renderThumbnail()}
                <div style={{marginLeft: 15, flex: 1}}>
                    <p style={{fontSize: 8, color: '#8a8a8a'}}>
                        {Util.getDate(notification.date)}
                    </p>
                    <h5 style={{marginTop: miniMode ? -20 : -5}}>{notification.title}</h5>
                    <p style={{fontSize: 12, marginTop: miniMode ? -15 : -10, color: 'rgba(0,0,0,0.65)'}}>{notification.message}</p>
                </div>
            </div>
        )

        const onNotificationClick = () => {
            if (this.props.onNotificationClick !== undefined) {
                this.props.onNotificationClick();
            }

            if (this.props.notification.clickEvent == NOTIFICATION_CLICK_EVENT_PROFILE && this.props.notification.clickEventPayload.userRole == ACCOUNT_ROLE_STUDENT) {
                Modal.info({
                    icon: <ProfilePicture size={60} userId={this.props.notification.clickEventPayload.eventId} />,
                    title:  <h4 style={{margin: 0, marginTop: 15}}>{this.props.notification.clickEventPayload.userFullName}</h4>,
                    content: <p style={{marginTop: -10}}>Learner</p>,
                })
            }
        }

        return (
            this.hasLink() ? (
                this.props.customHistory !== undefined ? (
                    <div style={{cursor: 'pointer'}} onClick={() => {
                        onNotificationClick();
                        this.props.customHistory.push(this.getLink())
                    }}>{content}</div>
                ) : (
                    <Link onClick={onNotificationClick} to={this.getLink()}>{content}</Link>
                )
            ) : content
        )
    }

}

export default NotificationItem;