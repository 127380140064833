import { Button, Tag } from 'antd';
import React from 'react'
import Page from '../../base/Page';
import { BLGO_POST_TAGS } from '../../BlogPostTags';
import withMediaQuery from '../../components/WithMediaQuery';
import Api from '../../session/Api';
import Util from '../../util/Util';
import Error404Page from '../Error404Page';

import CoverImage from '../../images/CoverPlaceholder.jpg';

import { 
    CommentOutlined,
    LikeOutlined,
    ShareAltOutlined,
    TwitterCircleFilled,
    HeartOutlined,
    HeartFilled,
    SyncOutlined,
    UnorderedListOutlined
} from '@ant-design/icons';
import ProfilePicture from '../../components/ProfilePicture';
import CommentsView from '../../views/comments/CommentsView';
import { EditorState, convertFromRaw } from "draft-js";
import { Editor } from 'react-draft-wysiwyg';


import {
    LinkedinShareButton,

    TwitterShareButton,
    FacebookShareButton,

    RedditShareButton,
    
    WhatsappShareButton,
    TelegramShareButton,
    EmailShareButton,

    LinkedinIcon,
    TwitterIcon,
    FacebookIcon,
    RedditIcon,
    WhatsappIcon,
    TelegramIcon,
    EmailIcon,
} from "react-share";
import SessionManager from '../../session/SessionManager';
import UIUtil from '../../util/UIUtil';
import { COMMENT_PARENT_TYPE_BLOG_POST } from '../../Constants';
import { Link } from 'react-router-dom';

class RawBlogPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            loading: true,
            blogPost: undefined,
            liking: false
        }
    }

    onPageStart() {
        if (this.state.blogPost !== undefined && this.state.pathParams.d == this.state.blogPost.date) {
            return;
        }

        this.setState({loading: true, blogPost: undefined})
        Api.getBlogPost(this.state.pathParams.t, this.state.pathParams.d, response => {
            if (response.status === true) {
                this.setState({loading: false, blogPost: response.payload})
            } else {
                this.setState({loading: false, blogPost: undefined})
            }
        })
    }

    getBlogPostTag() {
        for (const tag of BLGO_POST_TAGS) {
            if (tag.id == this.state.blogPost.tag) {
                return tag;
            }
        }

        return BLGO_POST_TAGS[0];
    }

    likePost() {
        if (this.state.liking) {
            return;
        }

        this.setState({liking: true})
        Api.toggleLikeBlogPost(this.state.blogPost.blogPostId, response => {
            if (response.status === true) {
                let post = this.state.blogPost;
                if (post.liked && !response.payload) {
                    post.likeCount = post.likeCount - 1;
                } else if (!post.liked && response.payload) {
                    post.likeCount = post.likeCount + 1;
                }
                post.liked = response.payload;

                this.setState({
                    liking: false,
                    post: post
                })
            } else {
                //alert('error');
                this.setState({liking: false})
                UIUtil.showError();
            }
        })
    }

    onCommentAdded() {
        let blogPost = this.state.blogPost;
        blogPost.commentCount = blogPost.commentCount + 1;

        this.setState({
            blogPost: blogPost
        })
    }

    onCommentRemoved() {
        let blogPost = this.state.blogPost;
        blogPost.commentCount = blogPost.commentCount - 1;

        this.setState({
            blogPost: blogPost
        })
    }

    render() {
        if (this.state.loading) {
            return <div style={{backgroundColor: '#F7F9FC'}} />
        }

        if (this.state.blogPost === undefined) {
            return <div style={{backgroundColor: '#F7F9FC'}} />
        }

        const blogPost = this.state.blogPost;
        const contentState = blogPost.content !== null ? EditorState.createWithContent(convertFromRaw(JSON.parse(blogPost.content))) : EditorState.createEmpty();
        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#F7F9FC' }}>
                <div style={{width: '100vw', paddingLeft: 15, paddingRight: 15, paddingTop: '15px', paddingBottom: '25px'}}>
                    <div style={{display: 'flex'}}>
                        <div style={{flex: 1}}>
                            <div style={{display: 'flex', width: '100%', marginTop: 0}}>
                                <h1 style={{fontStyle: 'bold', fontFamily: 'Open Sans', fontSize: 25, margin: 0, marginTop: -7, color: 'black', lineHeight: 1}}>{blogPost.title}</h1>
                            </div>
                            <img src={Api.getBlogPostThumbnail(blogPost.blogPostId)} style={{width: '100%', marginTop: 20, height: 325, 
                            //objectFit: 'cover', objectPosition: 'top', 
                            objectFit: 'cover',
                            borderRadius: 5,}}
                            onError={e => {
                                e.target.onerror = null;
                                e.target.src = CoverImage;
                            }}
                            />                            


                            <div style={{marginTop: 15, maxWidth: '90vw'}}>
                                <Editor toolbarHidden editorState={contentState} readOnly={true} />
                            </div>

                        </div>
                    </div>
                </div>
                
            </div>
        )
    }

}

export default RawBlogPage;