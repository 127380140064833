import React from 'react'
import Window from '../../base/Window';
import SessionManager from '../../session/SessionManager';
import Error404Page from '../../pages/Error404Page';
import FrontendAuthView from '../../views/auth/FrontendAuthView';
import Page from '../../base/Page';

import Logo from '../../images/Logo.png'

import { 
    LoginOutlined,
    UserOutlined,
    LockOutlined,
    AuditOutlined,
    ClusterOutlined,
    MailTwoTone,
    ReloadOutlined,
    ArrowRightOutlined,
    LockTwoTone,
    MailOutlined,
    ArrowLeftOutlined,
    LoadingOutlined
} from '@ant-design/icons';
import { Link, withRouter } from 'react-router-dom';
import { Button, Input, Modal, Spin } from 'antd';
import Util from '../../util/Util';
import Api from '../../session/Api';
import Avatar from 'antd/lib/avatar/avatar';
import ProfilePicture from '../../components/ProfilePicture';
import UIUtil from '../../util/UIUtil';

class PublicResetPasswordWindow extends Page {
    constructor(props) {
        super(props);
    
        this.state = {
            ...this.state,
            loading: true,
            user: undefined,
            errorMessage: undefined,

            newPasswordValue: "",
            newConfirmPasswordValue: "",

            resetingPassword: false
        }
    }

    onPageStart() {
        this.setState({loading: true})
        Api.validateResetPassword(this.state.pathParams.token, response => {
            if (response.status === true) {
                setTimeout(() => this.setState({loading: false, user: response.payload, errorMessage: undefined, newPasswordValue: "", newConfirmPasswordValue: "", resetingPassword: false}), 150)
            } else {
                this.setState({loading: false, user: undefined, errorMessage: response.message})
            }
        })
    }

    resetPassword() {
        if (this.state.newPasswordValue != this.state.newConfirmPasswordValue) {
            UIUtil.showError("Passwords do not match!")
            return;
        }

        this.setState({resetingPassword: true})
        Api.resetPassword(this.state.pathParams.token, this.state.newPasswordValue, response => {
            if (response.status === true) {
                Modal.success({
                    title: "Password reset successful!",
                    content: "You can now use your new password to log in to your account.",
                    okText: "Login",
                    onOk: () => {
                        if (SessionManager.isLoggedIn()) {
                            SessionManager.logout(() => this.props.history.replace("/auth"))
                        } else {
                            this.props.history.replace("/auth")
                        }
                    },
                })
            } else {
                UIUtil.showError(response.message);
                this.setState({resetingPassword: false})
            }
        })
    }

    getSubtitle() {
        if (this.state.loading) {
            return "Please wait while we're getting everything ready";
        } else if (this.state.user !== undefined) {
            return "Welcome back, " + this.state.user.fullName + "!";
        } else if (this.state.errorMessage !== undefined) {
            return this.state.errorMessage;
        } else {
            return "";
        }
    }

    render() {
        //return ( <FrontendAuthView registerType={this.params.registerType} onDonePath={this.params.dP !== undefined ? this.params.dP : '/'} /> )
        return (
            <div style={{width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                <Link to="/">
                    <img src={Logo} style={{height: '75px'}} />
                </Link>

                
                <div style={{marginTop: 25, position: 'relative'}}>
                    <ProfilePicture userId={this.state.user !== undefined ? this.state.user.id : 0} size={100} style={{opacity: this.state.user !== undefined ? 1 : 0, transition: '250ms'}} />
                    <LockTwoTone style={{transition: '250ms', fontSize: this.state.user !== undefined ? 40 : 50, 
                    position: 'absolute', bottom: this.state.user !== undefined ? -5 : 25, right: this.state.user !== undefined ? -5 : 25}} twoToneColor="#f15f43" />
                </div>
                {/*this.state.user !== undefined ? (
                    <div style={{marginTop: 25, position: 'relative'}}>
                        <ProfilePicture userId={this.state.user.id} size={100} />
                        <LockTwoTone style={{fontSize: 40, position: 'absolute', bottom: -5, right: -5}} twoToneColor="#f15f43" />
                    </div>
                ) : (
                    <LockTwoTone style={{fontSize: 50, marginTop: 25}} twoToneColor="#f15f43" />
                )*/}

                <h2 style={{marginTop: 15}}>Reset your password</h2>
                <h4 style={{marginTop: -15, color: this.state.errorMessage !== undefined ? '#990000' : undefined, fontWeight: this.state.errorMessage !== undefined ? 'normal' : undefined}}>{this.getSubtitle()}</h4>

                {this.state.loading && (
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 50, color: '#f15f43' }} spin />} style={{color: '#f15f43', marginTop: 40}} /> 
                )}

                {this.state.errorMessage !== undefined && (
                    <Link to="/">
                        <Button type="primary" style={{marginTop: 40}}><ArrowLeftOutlined /> Go Home</Button>
                    </Link>
                )}

                {!this.state.loading && this.state.errorMessage === undefined &&
                <div style={{width: 250, marginTop: 40}}>

                    <Input
                    size="large"
                    value={this.state.newPasswordValue}
                    onChange={(e) => this.setState({newPasswordValue: e.target.value})}
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="New Password"
                    disabled={this.state.resetingPassword}
                    />

                    <Input
                    size="large"
                    value={this.state.newConfirmPasswordValue}
                    onChange={(e) => this.setState({newConfirmPasswordValue: e.target.value})}
                    style={{marginTop: 5}}
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Confirm New Password"
                    disabled={this.state.resetingPassword}
                    />

                    <Button disabled={!Util.isStringExists(this.state.newPasswordValue) || !Util.isStringExists(this.state.newConfirmPasswordValue)} loading={this.state.resetingPassword} onClick={() => this.resetPassword()} style={{width: '100%', marginTop: 25}} type="primary"><LockOutlined /> Reset Password</Button>
                    <Link to="/">
                        <Button disabled={this.state.resetingPassword} style={{width: '100%', marginTop: 5}} size="small"><ArrowLeftOutlined /> Go home</Button>
                    </Link>
                </div>}
            </div>
        )
    }
}

export default withRouter(PublicResetPasswordWindow)