import React from 'react'
import Page from '../../base/Page';

import CoverPlaceholder from '../../images/HashtagCover.jpg'
import Api from '../../session/Api';
import UIUtil from '../../util/UIUtil';
import Util from '../../util/Util';

import { 
    PlusOutlined,
    UnorderedListOutlined,
    BookOutlined,
    AlertOutlined,
    ClusterOutlined,
    ArrowRightOutlined,
    AuditOutlined
} from '@ant-design/icons';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import HorizontalCoursesView, { HORIZONTAL_MODE_LATEST_HASHTAG_COURSES, HORIZONTAL_MODE_TOP_COURSES } from '../../views/courses/HorizontalCoursesView';
import HorizontalLiveSessionsView, { HORIZONTAL_MODE_LATEST_HASHTAG_LIVE_SESSIONS, HORIZONTAL_MODE_TOP_LIVE_SESSIONS } from '../../views/live-sessions/HorizontalLiveSessionsView';
import withMediaQuery from '../../components/WithMediaQuery';
import PostsView from '../../views/posts/PostsView';

class HashtagPage extends Page {
    
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            loading: true,
            hashtagUsage: {},

            noCourses: false,
            noLiveSessions: false,

            hideFade: true
        }
    }

    onPageStart() {
        this.setState({ loading: true })
        Api.getHashtagUsage(this.getHashtag(), response => {
            if (response.status === true) {
                this.setState({loading: false, hashtagUsage: response.payload})
            } else { }
        })
    }

    getHashtag() {
        return this.props.match.params.hashtag;
    }

    render() {
        let customFontSize;
        if (Util.isStringExists(this.getHashtag()) && this.getHashtag().length > 12) {
            customFontSize = this.props.isSmall ? 40 : 60;
        }

        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <img src={Api.getHashtagCover(this.getHashtag())}
                onLoad={() => this.setState({hideFade: false})}
                onError={e => {
                    e.target.onerror = null;
                    e.target.src = CoverPlaceholder;
                    //this.setState({hideFade: true})
                }}
                style={{height: this.props.isSmall ? 225 : '300px', width: '100%', objectFit: 'cover', position: 'absolute', zIndex: '-2'}} />

                <div
                className="hashtag-fade"
                style={{height: this.props.isSmall ? 225 : '300px', width: '100%', transition: '250ms', opacity: this.state.hideFade ? 0 : 1, position: 'absolute', zIndex: '-1'}} />

                <div style={{height: this.props.isSmall ? 225 : '300px', width: this.props.renderFullWidth ? '90vw' : '75vw', display: 'flex', alignItems: 'flex-end'}}>
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingLeft: this.props.isSmall ? 5 : 25, width: '100%'}}>
                        <h2 style={{color: 'white', fontWeight: 'bold', fontSize: customFontSize !== undefined ? customFontSize : (this.props.isSmall ? 60 : '100px'), lineHeight: 1, margin: 0, wordWrap: 'break-word'}}>
                            <span style={{marginRight: 5}}>#</span>
                            {this.getHashtag()}
                        </h2>
                        <div style={{display: 'flex', alignItems: 'center', //marginTop: this.props.isSmall ? -17 : -25, 
                        marginBottom: '1em', transition: '250ms', opacity: this.state.loading ? 0 : 0.80}}>
                            {!Util.isNumberExist(this.state.hashtagUsage.lastUpdateDate) ? (<>
                                <p style={{color: 'white', margin: 0}}>Be the first to use this hashtag!</p>
                            </>) : (<>
                                <p style={{color: 'white', margin: 0}}>Last updated on {Util.getDate(this.state.hashtagUsage.lastUpdateDate)}</p>
                                {/* <div style={{marginLeft: 10, marginRight: 10, width: 4, height: 4, borderRadius: '50%', background: 'white'}} /> */}
                                {/* <p style={{color: 'white', margin: 0}}>{this.state.hashtagUsage.totalCourse + this.state.hashtagUsage.totalLiveSession + this.state.hashtagUsage.totalPosts} items</p> */}
                            </>)}
                        </div>
                    </div>
                </div>
                
                <div style={{width: this.props.renderFullWidth ? '90vw' : '75vw', paddingTop: this.state.noCourses && this.state.noLiveSessions ? 0 : '25px', paddingBottom: '25px'}}>
                    <HorizontalCoursesView hideIfEmpty onEmpty={() => this.setState({noCourses: true})} mode={HORIZONTAL_MODE_LATEST_HASHTAG_COURSES} hashtag={this.getHashtag()}>
                        <div style={{display: 'flex', alignItems: 'center', marginTop: '0px'}}>
                            <BookOutlined style={{ fontSize: '20px', color: 'black' }} />
                            &nbsp;&nbsp;&nbsp;
                            <h2 style={{height: '22px', flex: 1}}>Latest Courses</h2>
                            <Button type="link" className="orange-link" style={{textDecoration: this.props.isSmall ? 'underline' : undefined}}>
                                <Link to={"/hashtag-courses/" + this.getHashtag()}>
                                    {this.props.isSmall ? 'All' : 'View All Courses'}
                                </Link>
                            </Button>
                        </div>
                        <br />
                    </HorizontalCoursesView>

                    
                    <HorizontalLiveSessionsView hideIfEmpty onEmpty={() => this.setState({noLiveSessions: true})} mode={HORIZONTAL_MODE_LATEST_HASHTAG_LIVE_SESSIONS} hashtag={this.getHashtag()}>
                        <div style={{display: 'flex', alignItems: 'center', marginTop: '50px'}}>
                            <AlertOutlined style={{ fontSize: '20px', color: 'black' }} />
                            &nbsp;&nbsp;&nbsp;
                            <h2 style={{height: '22px', flex: 1}}>Latest Live Sessions</h2>
                            <Button type="link" className="orange-link" style={{textDecoration: this.props.isSmall ? 'underline' : undefined}}>
                                <Link to={"/hashtag-live-sessions/" + this.getHashtag()}>
                                    {this.props.isSmall ? 'All' : 'View All Live Sessions'}
                                </Link>
                            </Button>
                        </div>
                        <br />
                    </HorizontalLiveSessionsView>
                </div>

                <h2 style={{ marginTop: this.state.noCourses && this.state.noLiveSessions ? 0 : '50px', textAlign: 'center'}}>What our tutors and organizations are posting</h2>
                <div style={{width: this.props.renderFullWidth ? '90vw' : '50vw', marginTop: '25px'}}>
                    <PostsView canAdd={false} hashtagPosts={{hashtag: this.getHashtag()}} />
                </div>
            </div>
        )
    }

}

export default withMediaQuery(HashtagPage);