
import { Modal } from 'antd';
import React, { createElement, useState } from 'react';
import { useEffect, useRef } from 'react'

export default function AudioPlayer({ stream, recordingMediaPlayer, ...props }) {
    const ref = useRef(null)

    const playAudio = () => {
        const audio = ref.current;
        audio.muted = false;
        
        audio.play()
        .catch((e) => {
            if (ref.current === audio && recordingMediaPlayer == undefined) {
                Modal.info({
                    title: "Are you there?",
                    content: "We have muted a new stream waiting for you",
                    okText: "Unmute",
                    onOk: () => {
                        if (ref.current === audio) {
                            audio.play();
                        }
                    }
                })
            }
        })
    }

    useEffect(() => {
        if (!ref.current) return

        if (recordingMediaPlayer) {
            recordingMediaPlayer.attachView(ref.current);
        } else {
            ref.current.srcObject = stream.rawStream;
            playAudio();
        }
    }, [stream])

    return (
        <audio 
        ref={ref} 
        autoPlay={recordingMediaPlayer ? false : true}

        {...props} style={{display: 'none'}} />
    )
}