import Api from "../../session/Api";
import LiveStreamState from "./LiveStreamState";

class LiveStreamTicker {

    /**
     * @type {LiveStreamState}
     */
    liveStreamState;

    constructor(liveStreamState) {
        this.liveStreamState = liveStreamState;
    }

    //i=0;
    start() {
        this.durationInterval = setInterval(() => {
            if (this.liveStreamState.hasLiveStream() && !this.liveStreamState.isLiveStreamOver()) {
                const joinInfo = this.liveStreamState.getJoinInfo();
                const time = new Date().getTime();

                this.liveStreamState.setState(prevState => ({
                    //liveStreamLiveDuration: prevState.liveStreamLiveDuration + 1,
                    //liveStreamTimeLeft: prevState.liveStreamTimeLeft - 1
                    liveStreamLiveDuration: (time - joinInfo.liveStream.date) / 1000,
                    liveStreamTimeLeft: ((joinInfo.liveStream.date + (joinInfo.liveStream.duration * 60000)) - time) / 1000,
                }));
    
                //this.i++;
                if (time >= this.liveStreamState.getJoinInfo().endDate) { // || this.i >= 5) {
                    this.liveStreamState.setState({ liveStreamOver: true, totalTimeSpent: (time - this.liveStreamState.getJoinInfo().joinDate) / 1000 })
                }
            }
        }, 1000)
        
        this.pingInterval = setInterval(() => {
            if (this.liveStreamState.hasLiveStream()) {
                Api.pingLiveStream(this.liveStreamState.getLiveStream().id, response => {
                    if (response.status === false) {
                        this.liveStreamState.setTimeoutDisconnectedError(true);
                    }
                });
            }
        }, 6000)
    }

    stop() {
        clearInterval(this.durationInterval);
        clearInterval(this.pingInterval);
    }

}

export default LiveStreamTicker;