import React from 'react'
import Page from '../../base/Page';
import { Row, Col, Avatar, Input, Spin } from 'antd';
import ChatView from '../../views/chats/ChatView';

import './messages-page.less';

import {
    RightOutlined,
    LoadingOutlined
} from '@ant-design/icons'
import RoomItem from './RoomItem';
import Api from '../../session/Api';
import { Link, Prompt } from 'react-router-dom';
import { ROOM_PARENT_TYPE_PROFILE, SOCKET_EVENT_CHAT } from '../../Constants';
import SocketSession from '../../session/SocketSession';
import SessionManager from '../../session/SessionManager';

class MessagesPage extends Page {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            loading: true,
            rooms: [],
            searchedRooms: undefined,
            searchedValue: undefined,
            selectedRoom: undefined,
        }
    }

    onMessageListener(message) {
        if (this.state.selectedRoom !== undefined && this.state.selectedRoom.id == message.roomId) {
            return;   
        }

        if (message.userId == SessionManager.getAccount().id) {
            return;
        }

        const room = this.getRoom(message.roomId);
        if (room !== undefined) {
            room.unreadMessages++;
        }
        this.onRoomUpdated(room);
    }
    chatEventListener = this.onMessageListener.bind(this);

    componentDidMount() {
        super.componentDidMount();
        SocketSession.registerListener(SOCKET_EVENT_CHAT, this.chatEventListener);
    }

    componentWillUnmount() {
        SocketSession.unregisterListener(SOCKET_EVENT_CHAT, this.chatEventListener);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.helpSession && this.props.helpSession.status !== nextProps.helpSession.status) {
            this.setState({
                loading: true,
                rooms: [],
                searchedRooms: undefined,
                searchedValue: undefined,
                selectedRoom: undefined,
            }, this.onPageStart)
            if (this.props.onRoomSelected !== undefined) {
                this.props.onRoomSelected()
            }
        }
    }

    onPageStart() {
        if (this.state.rooms.length === 0) {
            //list error
            const listener = response => {
                if (response.status === true) {
                    this.setState({
                        loading: false,
                        rooms: response.payload
                    })

                    if (this.state.pathParams.userId !== undefined || this.state.pathParams.id !== undefined) {
                        this.loadRoom();
                    }                    
                } else {
                    //alert('errowr');
                }
            }

            if (this.props.helpSession) {
                Api.getHelpSessionRooms(this.props.helpSession.courseId, this.props.helpSession.status, listener);
            } else {
                Api.getProfileRooms(this.state.pathParams.userId !== undefined ? this.state.pathParams.userId : 0, listener)
            }
        } else if (this.state.pathParams.userId !== undefined || this.state.pathParams.id !== undefined) {
            this.loadRoom();
        } else {
            this.setState({selectedRoom: undefined})
            if (this.props.onRoomSelected !== undefined) {
                this.props.onRoomSelected()
            }
        }
    }

    loadRoom() {
        const rooms = this.state.rooms;
        if (this.state.pathParams.userId !== undefined) {
            //const userId = parseInt(this.state.pathParams.userId);
            const userId = this.state.pathParams.userId;

            for (let i = 0; i < rooms.length; i++) {
                const room = rooms[i];
                
                if (room.otherMember.id == userId) {
                    room.unreadMessages = 0
                    this.setState({
                        selectedRoom: room,
                    })
                    if (this.props.onRoomSelected !== undefined) {
                        this.props.onRoomSelected(room)
                    }
                    break;
                }
            }
        } else {
            for (let i = 0; i < rooms.length; i++) {
                const room = rooms[i];
                
                if (room.id == this.state.pathParams.id) {
                    room.unreadMessages = 0
                    this.setState({
                        selectedRoom: room,
                    })
                    if (this.props.onRoomSelected !== undefined) {
                        this.props.onRoomSelected(room)
                    }
                    break;
                }
            }
        }
    }

    onRoomUpdated(room) {
        const rooms = this.state.rooms;
        const searchedRooms = this.state.searchedRooms;

        const roomIndex = rooms.indexOf(room);
        if (roomIndex >= 0) {
            rooms.splice(roomIndex, 1);
            rooms.unshift(room);
        }

        if (searchedRooms !== undefined) {
            const searchedRoomIndex = searchedRooms.indexOf(room);
            if (searchedRoomIndex >= 0) {
                searchedRooms.splice(searchedRoomIndex, 1);
                searchedRooms.unshift(room);
            }

            this.setState({rooms: rooms, searchedRooms: searchedRooms});
        } else {
            this.setState({rooms: rooms});
        }
    }

    getRoom(roomId) {
        for (let i = 0; i < this.state.rooms.length; i++) {
            const room = this.state.rooms[i];
            if (room.id === roomId) {
                return room;
            }
        }

        return undefined;
    }

    onSearch(searchValue) {
        if (searchValue.trim() === "") {
            this.setState({
                searchedRooms: undefined,
                searchedValue: undefined
            })
        } else {
            this.setState({
                searchedRooms: this.state.rooms.filter(room => 
                    room.otherMember.fullName.toLowerCase().includes(searchValue.trim().toLowerCase())),
                searchedValue: searchValue
            })
        }
    }

    getRoomsList() {
        return this.state.searchedRooms === undefined ? this.state.rooms : this.state.searchedRooms;
    }

    render() {
        return (
            <div style={{flex: '1', height: '100%', display: 'flex'}}>
                <Row style={{flex: '1'}}>
                    <Col span={6} style={{background: '#4e2e89', height: '100%', overflow: 'auto'}}>
                        {!this.state.loading ? (
                            <>
                                <div style={{ margin: '25px', height: '50px', borderRadius: '15px', boxShadow: "0px 0px 25px 0px rgba(0, 0, 0, 0.2)"}}>
                                    <Input placeholder="Search" className="search-field" style={{borderRadius: '15px', height: '100%'}} onChange={e => this.onSearch(e.target.value)} />
                                </div>

                                <div style={{height: '1px'}}>
                                    {this.getRoomsList().length > 0 ? this.getRoomsList().map(room => {
                                        return (
                                            <Link to={'/' + (this.props.helpSession ? (this.props.helpSession.tutor ? 'my-' : '') + 'help-session/' + this.props.helpSession.courseId + "?id=" + room.id : 'messages?userId=' + room.otherMember.id)}>
                                                <RoomItem room={room} selected={room.otherMember.id == this.state.pathParams.userId || room.id == this.state.pathParams.id} searchedValue={this.state.searchedValue} />
                                            </Link>
                                        )
                                    }) : (
                                        <>
                                            <h4 style={{color: 'white', marginTop: '50px', opacity: 0.75}} align="center">
                                                {this.props.helpSession ? 'No help sessions' : 'No messages'}
                                            </h4>
                                            <p style={{color: 'white', marginTop: '5px', opacity: 0.60, fontSize: '12px'}} align="center">
                                                {this.props.helpSession ? 'Help session messages will appear here' : 'Find other people to message to'}
                                            </p>
                                        </>
                                    )}
                                </div>
                            </>
                        ) : (
                            <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                                <div style={{position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}>
                                    <h1 style={{color: 'white'}} align="center">
                                        {this.props.helpSession ? "Loading help sessions" : 'Loading messages'}
                                    </h1>
                                    <br />
                                    <h5 style={{color: 'white'}} align="center">
                                        <Spin indicator={<LoadingOutlined style={{ fontSize: 30, color: 'white' }} spin />} />
                                    </h5>
                                </div>
                            </div>
                        )}
                    </Col>
                    <Col span={18} style={{height: '100%'}}>
                        <ChatView helpSession={this.props.helpSession} loading={this.state.loadingChats} room={this.state.selectedRoom} 
                        roomParentType={this.props.roomParentType !== undefined ? this.props.roomParentType : ROOM_PARENT_TYPE_PROFILE} 
                        roomParentId={this.props.roomParentId !== undefined ? this.props.roomParentId : 0}
                        roomSecondaryParentId={this.props.roomSecondaryParentId !== undefined ? this.props.roomSecondaryParentId : 0}
                        roomOtherMemberId={this.state.selectedRoom !== undefined ? this.state.selectedRoom.otherMember.id : 0} 
                        onRoomUpdated={this.onRoomUpdated.bind(this)} getRoom={this.getRoom.bind(this)} disableInput={this.props.disableInput} />
                    </Col>
                </Row>
            </div>
        )
    }
}

export default MessagesPage;