import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import Api from '../../session/Api';
import Util from '../../util/Util';
import { ErrorMessageView, LoadingMessageView } from '../components/LiveStreamSplashViews';

import { 
    ApiTwoTone,
} from '@ant-design/icons';

import './live-stream-window-2.less';
import { getTheme, THEME_LOCAL_STORAGE_KEY } from '../theme/Themes';


export default (props) => {
    const [loading, setLoading] = useState(true);
    const [inError, setInError] = useState(false);

    const history = useHistory();
    const theme = getTheme(window.localStorage.getItem(THEME_LOCAL_STORAGE_KEY));

    useEffect(() => {
        setLoading(true);
        setInError(false);
        Api.findLiveStreamFromHash(props.match.params.liveStreamHashId, response => {
            if (response.status === true) {
                setInError(false);
                history.replace(Util.getLiveStreamPath(response.payload));
            } else {
                setLoading(false);
                setInError(true);
            }
        })
    }, [])


    return (
        <div className={"live-stream-window " + theme.className}
        style={{width: '100vw', height: '100vh', background: theme.backgroundColor, color: theme.mainTextColor}}>
            {loading ? <LoadingMessageView theme={theme} /> : <ErrorMessageView theme={theme} icon={ApiTwoTone} message="Live stream does not exist" />}
        </div>
    )
}