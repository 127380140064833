import { Button, Empty, Spin } from 'antd';
import React from 'react'
import Page from '../../base/Page';
import withMediaQuery from '../../components/WithMediaQuery';

import {
    LeftOutlined,
    AlertOutlined
} from '@ant-design/icons'
import Api from '../../session/Api';
import Error404Page from '../Error404Page';
import Util from '../../util/Util';
import Modal from 'antd/lib/modal/Modal';
import LiveStreamAttendanceReport from '../../livestream/components/LiveStreamAttendanceReport';
import LiveStreamAttendanceReport2 from '../../livestream/components/LiveStreamAttendanceReport2';

class HelpSessionHistoryPage extends Page {

    constructor(props) {
        super(props)

        this.state = {
            loadingItems: true,
            items: [],
            helpSessionAccessDoesNotExist: false,

            selectedLiveStreamId: undefined
        }
    }

    getCourseId() {
        return this.props.match.params.courseId;
    }

    onPageStart() {
        this.setState({
            loadingItems: true,
            items: [],
            helpSessionAccessDoesNotExist: false
        })

        Api.getHelpSessionLiveHistory(this.getCourseId(), response => {
            if (response.status === true) {
                this.setState({
                    loadingItems: false,
                    items: response.payload,
                    //items: [],
                    helpSessionAccessDoesNotExist: false
                })
            } else {
                this.setState({
                    loadingItems: false,
                    items: [],
                    helpSessionAccessDoesNotExist: true
                })
            }
        })
    }

    render() {
        if (this.state.helpSessionAccessDoesNotExist) {
            return <Error404Page />
        }

        return (
            <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                <div style={{width: this.props.renderFullWidth ? '90vw' : '75vw', marginTop: '25px'}}>
                    <a type="link" onClick={() => this.props.history.goBack()}><LeftOutlined /> Go Back</a>

                    <div style={{display: 'flex', alignItems: 'center', marginTop: 20}}>
                        <h2 style={{height: '25px'}}><AlertOutlined /> Help Session Lives History</h2>
                        <div style={{flex: '1'}} />
                    </div>
                    <br />

                    {this.state.items.map(item => (
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', cursor: 'pointer', marginBottom: '15px', boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)", borderRadius: '5px', padding: '15px'}}
                        onClick={() => this.setState({selectedLiveStreamId: item.liveStreamId})}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <h4 style={{fontSize: 16}}><AlertOutlined /> Live</h4>
                                <h5 style={{fontSize: 10, color: '#8a8a8a'}}>&nbsp;- at {Util.getFullDate(item.date)}</h5>
                                <div style={{flex: 1}} />

                                {!item.record && <Button type="text">Not recorded</Button>}
                                {item.record && <Button type="link" className="red-link">Recorded</Button>}
                            </div>

                        </div>
                    ))}

                    {this.state.loadingItems ? <div style={{display: 'flex', justifyContent: 'center', marginTop: '45px', marginBottom: '45px'}}>
                        <Spin tip="Loading..." />
                    </div> : null}
                    {!this.state.loadingItems ? <Empty style={{marginTop: '50px'}} image={Empty.PRESENTED_IMAGE_SIMPLE} description={this.state.items.length > 0 ? "No more help session lives" : "No help session lives"} /> : null}
                </div>

                {this.state.selectedLiveStreamId !== undefined &&
                <Modal visible width={700} onCancel={() => this.setState({selectedLiveStreamId: undefined})} footer={null}>
                    <LiveStreamAttendanceReport2 liveStreamId={this.state.selectedLiveStreamId} />
                </Modal>}
            </div>
        )
    }

}

export default withMediaQuery(HelpSessionHistoryPage);