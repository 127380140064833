import React from 'react';

import { 
    FileOutlined,
} from '@ant-design/icons';
import Util from '../util/Util';

export default function FileCard(props) {
    const color = props.black ? 'black' : 'white'

    return (
        <div style={{display: 'flex', alignItems: 'center', padding: '15px', cursor: props.clickable ? 'pointer' : 'auto'}}>
            <div style={{background: color, width: '50px', height: '50px', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <FileOutlined style={{fontSize: '20px', color: props.black ? 'white' : 'black'}} />
            </div>
            <div style={{marginLeft: '15px', flex: '1', minWidth: '0'}}>
                <h3 style={{color: color, height: '22px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{props.fileName}</h3>
                <p style={{color: color, opacity: '0.75', fontSize: '10px', height: '10px'}}>{Util.formatBytes(props.fileSize)}</p>
            </div>
        </div>
    )
}