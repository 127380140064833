import { Button, Col, Divider, Row, Space } from 'antd';
import React from 'react';
import MediaQuery, { useMediaQuery } from 'react-responsive';

import LogoWhite from '../../images/LogoWhite.png'
//import apps from '../../images/apps.png';
//import desktops from '../../images/desktops.png';
import visa from 'payment-icons/min/flat/visa.svg';
import mastercard from 'payment-icons/min/flat/mastercard.svg';
import paypal from 'payment-icons/min/flat/paypal.svg';

import GooglePlayButton from '../../images/GooglePlayButton.png';
import AppStoreButton from '../../images/AppStoreButton.png';

import WindowsButton from '../../images/WindowsButton.png';
import MacOSButton from '../../images/MacOSButton.png';
import LinuxButton from '../../images/LinuxButton.png';

import { 
    AuditOutlined,
    UserOutlined,
    ArrowRightOutlined,
    SearchOutlined,
    InstagramFilled,
    FacebookFilled,
    TwitterCircleFilled,
    LikeOutlined,
    YoutubeFilled,
    MessageOutlined,
    ClusterOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { APP_STORE_APP_LINK, GOOGLE_PLAY_APP_LINK } from '../../Constants';
import SessionManager from '../../session/SessionManager';

const footerColStyles = {
    flexBasis: "20%",
    width: "20%"
};

const HelpfulLinks = () => {
    return (<>
        <Link to="/about-us">
            <a className="orange-link">About us</a>
        </Link>
        <Link to="/contact">
            <a className="orange-link">Contact us</a>
        </Link>
        <Link to="/become-a-tutor">
            <a className="orange-link">Become a Tutor</a>
        </Link>
        <Link to="/terms-of-service">
            <a className="orange-link">Terms of Service</a>
        </Link>
        <Link to="/privacy-policy">
            <a className="orange-link">Privacy Policy</a>
        </Link>
    </>)
}

export default function FooterView(props) {
    const renderFullWidth = useMediaQuery({maxWidth: 800})

    if (SessionManager.isLoggedIn()) {
        return null;
    }

    return (<>
        <MediaQuery maxWidth={619}>
            <div style={{ background: '#2b2b2b', width: '100%', paddingTop: '25px', paddingBottom: '25px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ width: '90vw', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <img style={{ height: '30px' }} src={LogoWhite} />

                    <Divider style={{ border: '1px solid #f0f0f0' }} />
                    <p style={{ color: 'white', textAlign: 'center', width: '75vw' }}>Learnerix is an all-in-one online learning software as a service (SAAS) that allows freelance tutors to create a stream of income by sharing their skills with thousands of learners.</p>

                    <Link to="/about-us">
                        <Button type="link" className="orange-link" style={{ marginTop: '10px' }}>About us</Button>
                    </Link>
                    <Link to="/contact">
                        <Button type="link" className="orange-link">Contact us</Button>
                    </Link>
                    <Link to="/become-a-tutor">
                        <Button type="link" className="orange-link">Become a Tutor</Button>
                    </Link>
                    <Link to="/terms-of-service">
                        <Button type="link" className="orange-link">Terms of Service</Button>
                    </Link>
                    <Link to="/privacy-policy">
                        <Button type="link" className="orange-link">Privacy Policy</Button>
                    </Link>
                </div>
            </div>
            <div style={{ background: '#191919', padding: '5px', width: '100%', display: 'flex', justifyContent: 'space-around' }}>
                <Button type="text" style={{ cursor: 'default', color: 'white', paddingLeft: 0, marginLeft: 0 }}>Follow us</Button>
                <a href="https://instagram.com/learnerix" target="_blank">
                    <Button type="link" className="orange-link">
                        <InstagramFilled />
                    </Button>
                </a>
                {/*<Button type="link" className="orange-link">
                    <TwitterCircleFilled />
                </Button>*/}
                <a href="https://facebook.com/learnerix" target="_blank">
                    <Button type="link" className="orange-link">
                        <FacebookFilled />
                    </Button>
                </a>
                <a href="https://www.youtube.com/channel/UCI8qgUEPRtGvYlCcNESL7Hg" target="_blank">
                    <Button type="link" className="orange-link">
                        <YoutubeFilled />
                    </Button>
                </a>
            </div>
        </MediaQuery>
        <MediaQuery minWidth={620}>
            <div style={{ background: '#2b2b2b', width: '100%', paddingTop: '25px', paddingBottom: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ width: renderFullWidth ? '90vw' : '70vw' }}>
                    <Row type="flex" gutter={[24, 0]}>
                        <Col style={{ ...footerColStyles }}>
                            {/*<h3 style={{color: 'white'}}>Learnerix</h3>*/}
                            <img style={{ height: '30px' }} src={LogoWhite} />
                            <Divider style={{ border: '1px solid #f0f0f0' }} />
                            <p style={{ color: 'white' }}>Learnerix is an all-in-one online learning software as a service (SAAS) that allows freelance tutors to create a stream of income by sharing their skills with thousands of learners.</p>
                        </Col>
                        <Col style={{ ...footerColStyles }}>
                            <h3 style={{ color: 'white' }}>Quick Links</h3>
                            <Divider style={{ border: '1px solid #f0f0f0' }} />
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <HelpfulLinks />
                            </div>
                        </Col>
                        <Col style={{ ...footerColStyles }}>
                            <h3 style={{ color: 'white' }}>Keep in touch</h3>
                            <Divider style={{ border: '1px solid #f0f0f0' }} />
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <a href="https://instagram.com/learnerix" target="_blank" className="orange-link"><InstagramFilled /> Instagram</a>
                                {/*<a className="orange-link"><TwitterCircleFilled /> Twitter</a>*/}
                                <a href="https://facebook.com/learnerix" target="_blank" className="orange-link"><FacebookFilled /> Facebook</a>
                                <a href="https://www.youtube.com/channel/UCI8qgUEPRtGvYlCcNESL7Hg" target="_blank" className="orange-link"><YoutubeFilled /> YouTube</a>
                            </div>
                        </Col>
                        <Col style={{ ...footerColStyles }}>
                            <h3 style={{ color: 'white' }}>Mobile Apps</h3>
                            <Divider style={{ border: '1px solid #f0f0f0' }} />

                            {/*<img style={{ width: '80%' }} src={apps} />*/}
                            <a target="_blank" href={GOOGLE_PLAY_APP_LINK}>
                                <img style={{ width: '80%' }} src={GooglePlayButton} />
                            </a>
                            <a target="_blank" href={APP_STORE_APP_LINK}>
                                <img style={{ width: '80%', marginTop: 9 }} src={AppStoreButton} />
                            </a>
                        </Col>

                        <Col style={{ ...footerColStyles }}>
                            <h3 style={{ color: 'white' }}>Desktop Apps</h3>
                            <Divider style={{ border: '1px solid #f0f0f0' }} />

                            <a target="_blank" download href="/Learnerix.exe">
                                <img style={{ width: '80%' }} src={WindowsButton} />
                            </a>
                            <a target="_blank" download href="/Learnerix.dmg">
                                <img style={{ width: '80%', marginTop: 9 }} src={MacOSButton} />
                            </a>
                            <a target="_blank" download href="/Learnerix.AppImage">
                                <img style={{ width: '80%', marginTop: 9 }} src={LinuxButton} />
                            </a>
                        </Col>
                    </Row>
                </div>
            </div>
        </MediaQuery>

        <div style={{background: 'black', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{width: renderFullWidth ? '90vw' : '70vw', height: '40px', }}>
                <div style={{float: 'left', display: 'flex', alignItems: 'center', height: '100%'}}>
                    <h3 style={{color: 'white', height: '12px', fontSize: '12px'}}>Copyright © 2021 Learnerix</h3>
                </div>
                
                <Space style={{float: 'right', display: 'flex', alignItems: 'center', height: '100%', paddingRight: '4%'}}>
                    <img style={{height: '17px'}} src={paypal} />
                    <img style={{height: '17px'}} src={mastercard} />
                    <img style={{height: '17px'}} src={visa} />
                </Space>
            </div>
        </div>
    </>)
}