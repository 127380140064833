import React from 'react';
import Modal from 'antd/lib/modal/Modal';
import { useEffect } from 'react';
import { Spin, Checkbox, Popconfirm, Button, Tag } from 'antd';
import Api from '../session/Api';
import UIUtil from '../util/UIUtil';
import ProfilePicture from './ProfilePicture';
import { Link } from 'react-router-dom';
import Util from '../util/Util';
import StudentProfileDialog from './student-profile-dialog/StudentProfileDialog';
import { ACCOUNT_ROLE_STUDENT, ACCOUNT_ROLE_TUTOR, PRODUCT_TYPE_COURSE, PRODUCT_TYPE_LIVE_SESSION } from '../Constants';

import {  
    CloseOutlined,
    CheckOutlined
} from '@ant-design/icons';

const UserItem = ({user, onClick, selectable, onChecked, sentInvite, receivedInvite, onItemRemoved, inviteAcceptListener}) => {
    const [loading, setLoading] = React.useState(false);

    const onRemoveBtn = (acceptReceivedInvite) => {
        setLoading(true);
        const listener = response => {
            if (response.status === true) {
                if (onItemRemoved !== undefined) {
                    onItemRemoved();
                }
                if (acceptReceivedInvite && inviteAcceptListener !== undefined) {
                    inviteAcceptListener(response.payload);
                }
            } else {
                UIUtil.showError(response.message);
            }
            setLoading(false);
        };

        if (sentInvite || receivedInvite) {
            if (receivedInvite && acceptReceivedInvite) {
                Api.acceptEnrollmentInvitation(user.invitationId, listener);
            } else {
                Api.cancelEnrollmentInvitation(user.invitationId, listener);
            }
        } else {
            setLoading(false);
        }
    }

    return (
        <div style={{display: 'flex', alignItems: 'center', cursor: user.inLive ? 'default' : 'pointer', opacity: user.inLive ? 0.50 : 1, marginBottom: '15px'}} onClick={onClick}>
            <ProfilePicture showStatus={user.role === ACCOUNT_ROLE_TUTOR} userId={user.id} />
            <p style={{marginLeft: '15px', height: '12px', width: (sentInvite || receivedInvite) && '175px'}}>{user.fullName}</p>
            {(sentInvite || receivedInvite) && (user.role === ACCOUNT_ROLE_TUTOR || user.role === ACCOUNT_ROLE_STUDENT) && <Tag style={{marginLeft: '15px', ...((sentInvite || receivedInvite) ? {width: '75px', display: 'flex', justifyContent: 'center', alignItems: 'center'} : {})}} color={user.role === ACCOUNT_ROLE_TUTOR ? "purple" : "orange"}>{user.role === ACCOUNT_ROLE_TUTOR ? 'Tutor' : 'Learner'}</Tag>}
            {(sentInvite || receivedInvite) && <p style={{marginLeft: '15px', height: '5px', color: '#8a8a8a', fontSize: '10px'}}>Invite sent on {Util.getDate(user.inviteDate)}</p>}
            <div style={{flex: '1'}} />
            {selectable && !user.inLive && <> 
                <Checkbox onChange={e => onChecked(e.target.checked)} onClick={e => e.stopPropagation()} />
            </>}
            {sentInvite && 
            <Popconfirm title="Are you sure you want to cancel this invitation?" onCancel={e => e.stopPropagation()} onConfirm={e => {
                e.stopPropagation();
                onRemoveBtn();
            }} placement="topRight">
                <Button danger type="link" size="small" onClick={e => e.stopPropagation()} loading={loading}><CloseOutlined /></Button>
            </Popconfirm>}
            {receivedInvite && 
            <Popconfirm title="Are you sure you want to reject this invitation?" onCancel={e => e.stopPropagation()} onConfirm={e => {
                e.stopPropagation();
                onRemoveBtn();
            }} placement="topRight">
                <Button danger type="link" size="small" onClick={e => e.stopPropagation()} loading={loading}><CloseOutlined /> Reject</Button>
            </Popconfirm>}
            {receivedInvite && 
            <Popconfirm title="Are you sure you want to accept this invitation?" onCancel={e => e.stopPropagation()} onConfirm={e => {
                e.stopPropagation();
                onRemoveBtn(true)
            }} placement="topRight">
                <Button type="primary" size="medium" style={{marginLeft: '15px'}} onClick={e => e.stopPropagation()} loading={loading}><CheckOutlined /> Accept</Button>
            </Popconfirm>}
        </div>
    )
}

export default function UserListDialog(props) {
    const [loading, setLoading] = React.useState(true);
    const [users, setUsers] = React.useState([]);
    const [selectedUser, setSelectedUser] = React.useState(undefined);
    const [checkedUsers, setCheckedUsers] = React.useState([]);


    useEffect(() => {
        const listener = response => {
            setLoading(false);
            if (response.status === true) {
                setUsers(response.payload);
            } else {
                UIUtil.showError();
                if (props.onCancel !== undefined) {
                    props.onCancel();
                }
            }
        };

        if (props.showHelpSessionUsers) {
            Api.getHelpSessionUsers(props.courseId, listener);
        } else if (props.showOrganizationNonAccessLearners || props.showOrganizationNonAccessTutors) {
            if (props.courseId !== undefined) {
                Api.getEnrolledUsersWithoutAccess(props.courseId, PRODUCT_TYPE_COURSE, props.showOrganizationNonAccessLearners ? ACCOUNT_ROLE_STUDENT : ACCOUNT_ROLE_TUTOR, listener);
            } else {
                Api.getEnrolledUsersWithoutAccess(props.liveSessionId, PRODUCT_TYPE_LIVE_SESSION, props.showOrganizationNonAccessLearners ? ACCOUNT_ROLE_STUDENT : ACCOUNT_ROLE_TUTOR, listener);
            }
        } else if (props.showSentInvites) {
            Api.getMySentEnrollmentInvitations(listener);
        } else if (props.showReceivedInvites) {
            Api.getMyEnrollmentInvitations(listener);
            //Api.getMySentEnrollmentInvitations(listener);
        } else {
            setLoading(false)
        }
    }, [])

    const onUserChecked = (user, checked) => {
        let updatedCheckedUsers = [...checkedUsers];
        if (checked) {
            updatedCheckedUsers.push(user.id);
        } else {
            updatedCheckedUsers.splice(updatedCheckedUsers.indexOf(user.id), 1);
        }
        setCheckedUsers(updatedCheckedUsers);
    }

    const onUserRemoved = user => {
        const newUsers = [...users];
        const index = newUsers.indexOf(user);
        if (index != -1) {
            newUsers.splice(index, 1);
        }
        setUsers(newUsers);
    }

    return (
        <div>
            <Modal {...props} okButtonProps={{disabled: checkedUsers.length == 0}} onOk={() => {
                if (props.selectable) {
                    props.onOk(checkedUsers)
                } else {
                    props.onOk()
                }
            }}>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    {props.children}
                    {props.message}
                    {props.message !== undefined && <div style={{height: '15px'}} />}
                    {!loading && users.length == 0 && props.emptyMessage !== undefined && <p style={{fontSize: '12px'}}>{props.emptyMessage}</p>}
                </div>
                {loading ? (
                    <div style={{display: 'flex', justifyContent: 'center', marginTop: '45px', marginBottom: '45px'}}>
                        <Spin tip="Loading..." />
                    </div>
                ) : (
                    users.map(user => 
                        //user.role === ACCOUNT_ROLE_STUDENT ? 
                        //<UserItem inviteAcceptListener={props.inviteAcceptListener} onItemRemoved={() => onUserRemoved(user)} receivedInvite={props.showReceivedInvites} sentInvite={props.showSentInvites} onChecked={checked => onUserChecked(user, checked)} selectable={props.selectable} onClick={() => setSelectedUser(user)} user={user} /> : 
                        <Link to={Util.getProfilePath(user.id)}>
                        <UserItem inviteAcceptListener={props.inviteAcceptListener} onItemRemoved={() => onUserRemoved(user)} receivedInvite={props.showReceivedInvites} sentInvite={props.showSentInvites} onChecked={checked => onUserChecked(user, checked)} selectable={props.selectable} user={user} />
                    </Link>)
                )}
            </Modal>

            <StudentProfileDialog 
                visible={selectedUser !== undefined}
                name={selectedUser !== undefined ? selectedUser.fullName : ""}
                userId={selectedUser !== undefined ? selectedUser.id : 0}
                onCancel={() => setSelectedUser(undefined)} />
        </div>
    )
}