import React from 'react'
import Page from '../../base/Page';

import { 
    FileUnknownOutlined,
    QuestionCircleOutlined,
    CheckCircleFilled,
    ReadOutlined,
    DollarOutlined,
    ShareAltOutlined
} from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import { Button } from 'antd';
import FooterView from '../../views/public-views/FooterView';

class AboutUsPage extends Page {

    getContent() {
        return (<>
            <p>
                Learnerix is an all-in-one online learning software as a service (SAAS) that allows freelance tutors to create a stream of income by sharing their skills with thousands of learners. It also provides a learning platform for organizations and schools to reach their staff and students. Our simple user interface allows tutors and learners to easily interact and communicate with each other. Our fully functioning live session has features such as desktop and browser sharing, file sharing, whiteboard and chat. It can also accommodate unlimited number of participants. 
            </p>
            <br />
            <h2 style={{textAlign: 'center'}}><ReadOutlined /> Learn</h2>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                <Button style={{cursor: 'default'}} type="text">
                    <CheckCircleFilled /> Hundreds of courses in different categories
                </Button>
                <Button style={{cursor: 'default'}} type="text">
                    <CheckCircleFilled /> Lifetime access to purchased courses 
                </Button>
                <Button style={{cursor: 'default'}} type="text">
                    <CheckCircleFilled /> Watch at your own convenience on your preferred device 
                </Button>
                <Button style={{cursor: 'default'}} type="text">
                    <CheckCircleFilled /> Communicate with other members and tutors
                </Button>
                <Button style={{cursor: 'default'}} type="text">
                    <CheckCircleFilled /> Ongoing learning by requesting help sessions for the courses you purchased
                </Button>
            </div>

            <br />
            <h2 style={{textAlign: 'center'}}><DollarOutlined /> Earn</h2>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                <Button style={{cursor: 'default'}} type="text">
                    <CheckCircleFilled /> Share your skills and knowledge 
                </Button>
                <Button style={{cursor: 'default'}} type="text">
                    <CheckCircleFilled /> Tutors and organizations can create a stream of passive income 
                </Button>
                <Button style={{cursor: 'default'}} type="text">
                    <CheckCircleFilled /> Promote your course on your profile and your social media accounts
                </Button>
                <Button style={{cursor: 'default'}} type="text">
                    <CheckCircleFilled /> Get promoted by uploading more and receiving higher ratings
                </Button>
            </div>

            <br />
            <h2 style={{textAlign: 'center'}}><ShareAltOutlined /> Connect</h2>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                <Button style={{cursor: 'default'}} type="text">
                    <CheckCircleFilled /> Be followed and follow others
                </Button>
                <Button style={{cursor: 'default'}} type="text">
                    <CheckCircleFilled /> Post feeds and get likes and comments from your followers and audience
                </Button>
                <Button style={{cursor: 'default'}} type="text">
                    <CheckCircleFilled /> Skilled individuals can conduct online webinars for their audience
                </Button>
                <Button style={{cursor: 'default'}} type="text">
                    <CheckCircleFilled /> Subscribing to Learnerix Live allows learners to join and record live sessions      
                </Button>
                <Button style={{cursor: 'default'}} type="text">
                    <CheckCircleFilled /> Unlimited numbers of participants on live sessions  
                </Button>
                <Button style={{cursor: 'default'}} type="text">
                    <CheckCircleFilled /> Provides an opportunity for teaching institutes to sell their courses online
                </Button>
                <Button style={{cursor: 'default'}} type="text">
                    <CheckCircleFilled /> Fully customizable platform for schools, universities and organizations
                </Button>
                <Button style={{cursor: 'default'}} type="text">
                    <CheckCircleFilled /> Bronze, Silver, Gold and Platinum packages for schools and organizations
                </Button>
            </div>
            <br /> 
        </>)
    }

    render() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '25px'}}>
                <h1 style={{ marginTop: '25px', marginBottom: '25px' }}><QuestionCircleOutlined /> About Us</h1>
                <div style={{width: '75vw', marginBottom: '25px' }}>
                    {this.getContent()}
                </div>
                <FooterView />
            </div>
        )
    }

}

export default withRouter(AboutUsPage);