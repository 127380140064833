import React from 'react'

import { 
    DesktopOutlined,
    VideoCameraOutlined,
    FormOutlined,
    LoadingOutlined,
    FullscreenOutlined,
    FullscreenExitOutlined,
    ArrowsAltOutlined,
    MessageOutlined,
    CaretDownOutlined,
    AudioFilled,
    VideoCameraFilled
} from '@ant-design/icons';
import { Row, Col, Switch as SwitchToggle, Spin, Alert, Button, Popover, Popconfirm } from 'antd';
import { SESSION_TYPE_CAMERA, SESSION_TYPE_DESKTOP, SESSION_TYPE_BOARD } from '../sessions/Session';
import { motion } from 'framer-motion';



export const SessionIcon = props => {
    switch (props.type) {
        case -1:
            return <MessageOutlined {...props} />
        case SESSION_TYPE_CAMERA:
            return <VideoCameraOutlined {...props} />;
        case SESSION_TYPE_DESKTOP:
            return <DesktopOutlined {...props} />
        case SESSION_TYPE_BOARD:
            return <FormOutlined {...props} />
    }
}

export default function SessionView(props) {
    const [loading, setLoading] = React.useState(false);
    const [hasAudio, setHasAudio] = React.useState(true);
    const [hasVideo, setHasVideo] = React.useState(true);

    const getSessionName = () => {
        switch (props.type) {
            case -1:
                return "Chat";
            case SESSION_TYPE_CAMERA:
                return "Camera";
            case SESSION_TYPE_DESKTOP:
                return "Desktop"
            case SESSION_TYPE_BOARD:
                return "Whiteboard";
        }
    }

    const getTitle = () => {
        return props.broadcasting ? (
            'Currently not sharing ' + getSessionName().toLowerCase()
        ) : (
            getSessionName() + ' not being shared currently'
        )
    }

    return (
        <div key={props.key} style={{height: '100%', width: '100%', display: 'flex', flexDirection: 'column'}}>
            <Row style={{paddingLeft: '25px', paddingRight: '25px'}} align="middle">
                <Col span={12} style={{display: 'flex', alignItems: 'center', height: '40px'}}>
                    <h3 style={{color: 'white', marginRight: '5px', height: '20px'}}><SessionIcon type={props.type} /> {getSessionName()}</h3>
                    <Popover content={!props.fullScreen && !props.focus ? (
                        <> 
                            <Button type="link" className="red-link" onClick={props.onFullScreenBtn}><FullscreenOutlined /></Button>
                            <Button type="link" className="red-link" onClick={props.onFocusBtn}><ArrowsAltOutlined /></Button>
                        </>
                    ) : (
                        (props.focus && !props.fullScreen) ? (
                            <Button type="link" className="red-link" onClick={props.onFullScreenBtn}><FullscreenOutlined /></Button>
                        ) : (
                            <Button type="link" className="red-link" onClick={props.onFullScreenBtn}><FullscreenExitOutlined /></Button>
                        )
                    )} trigger="click" placement="bottom">
                        <CaretDownOutlined style={{color: 'white', fontSize: '12px'}} />
                    </Popover>
                    {/*//props.active && (
                    (
                        !props.fullScreen && !props.focus ? (
                            <> 
                                <Button type="link" className="red-link" onClick={props.onFullScreenBtn}><FullscreenOutlined /></Button>
                                <Button type="link" className="red-link" onClick={props.onFocusBtn}><ArrowsAltOutlined /></Button>
                            </>
                        ) : (
                            props.focus ? (
                                <Button type="link" className="red-link" onClick={props.onFullScreenBtn}><FullscreenOutlined /></Button>
                            ) : (
                                <Button type="link" className="red-link" onClick={props.onFullScreenBtn}><FullscreenExitOutlined /></Button>
                            )
                        )
                    )*/}
                </Col>
                <Col span={12} style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                    {props.broadcasting && props.type !== SESSION_TYPE_CAMERA &&
                    //<Popover trigger="hover">
                        <SwitchToggle checkedChildren="On" unCheckedChildren="Off" checked={props.active !== undefined ? props.active : false} loading={props.loading} onChange={enabled => props.onChange(props.type, enabled)} />
                    //</Popover>
                    }

                    {props.broadcasting && props.type === SESSION_TYPE_CAMERA &&
                    <Popconfirm overlayClassName="popconfirm-title-no-padding" trigger="click" disabled={props.active} icon={null} 
                    style={{padding: 0}} placement="bottom"
                    okButtonProps={{disabled: !hasAudio && !hasVideo}}
                    title={<div style={{width: '225px'}}>
                        <p>Share</p>

                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <AudioFilled style={{color: 'black'}} />
                            <p style={{fontWeight: 'bold', marginLeft: '5px', color: 'black', height: '10px'}}>Mic Audio</p>
                            <div style={{flex: 1}} />
                            <SwitchToggle checkedChildren="On" unCheckedChildren="Off" checked={hasAudio} onChange={checked => setHasAudio(checked)} />
                        </div>

                        <div style={{display: 'flex', alignItems: 'center', marginTop: '5px'}}>
                            <VideoCameraFilled style={{color: 'black'}} />
                            <p style={{fontWeight: 'bold', marginLeft: '5px', color: 'black', height: '10px'}}>Camera Video</p>
                            <div style={{flex: 1}} />
                            <SwitchToggle checkedChildren="On" unCheckedChildren="Off" checked={hasVideo} onChange={checked => setHasVideo(checked)} />
                        </div>
                    </div>}
                    onVisibleChange={visible => setLoading(visible)}
                    onConfirm={() => {
                        setLoading(false);
                        props.onChange2(true, hasAudio, hasVideo)
                    }} onCancel={() => {
                        setLoading(false);
                    }}>
                        <SwitchToggle checkedChildren="On" unCheckedChildren="Off" 
                        checked={props.active !== undefined ? props.active : false} 
                        loading={props.loading || loading} onChange={props.active ? props.onChange2 : null} 
                        />
                    </Popconfirm>}

                    {props.whiteBoardControlMessage &&
                    <p style={{color: 'white', fontSize: 10, fontWeight: 'bold', textAlign: 'right'}}>Click on the whiteboard to open editor</p>
                    }
                </Col>
            </Row>
            <div style={{background: '#1B1B1B', borderRadius: '25px', width: '100%', flex: '1', overflow: 'hidden'}}>
                {props.showError && false && <Alert style={{borderTopLeftRadius: '25px', borderTopRightRadius: '25px'}} message="An error occurred. Please try again later." type="error" showIcon />}

                <div style={{width: '100%', height: '100%', position: 'relative'}}>
                    {(!props.hideInfo || !props.active) && <div style={{width: '100%', height: '100%', position: 'absolute'}}>
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                            <SessionIcon type={props.type} style={{fontSize: '115px'}} />
                            <h4 style={{marginTop: '15px'}}>{getTitle()}</h4>
                        </div>
                    </div>}
                    {(props.active || props.showAnyway) &&
                    <div style={{width: '100%', height: '100%', position: 'absolute'}}>
                        {props.children}
                    </div>}
                </div>

                {/*!props.active ? 
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                    <SessionIcon type={props.type} style={{fontSize: '150px'}} />
                    <h2 style={{marginTop: '25px'}}>{props.loading ? 'Loading ' + getSessionName().toLowerCase() : getTitle()}</h2>
                    {props.loading &&
                    <motion.div initial={{height: '0'}} animate={{height: '30'}} >
                        <Spin style={{marginTop: '25px'}} indicator={<LoadingOutlined style={{ fontSize: '30', color: '#990000' }} spin />} />
                    </motion.div>}
                </div> : props.children }*/}
            </div>
        </div>
    )
}