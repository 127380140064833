import React from 'react'
import Page from '../base/Page';
import Error404Page from './Error404Page';

import { 
    PlusOutlined,
    UnorderedListOutlined,
    BookOutlined,
    InfoCircleOutlined,
    AlertOutlined
} from '@ant-design/icons';
import Api from '../session/Api';
import ProfilePicture from '../components/ProfilePicture';
import { Button, Popconfirm, Radio, Result, Tooltip } from 'antd';
import SessionManager from '../session/SessionManager';
import StudentMyCoursesPage from './StudentMyCoursesPage';
import TutorMyCoursesPage from './TutorMyCoursesPage';
import { Link } from 'react-router-dom';
import Util from '../util/Util';
import Modal from 'antd/lib/modal/Modal';
import UIUtil from '../util/UIUtil';
import { PRODUCT_TYPE_COURSE, PRODUCT_TYPE_LIVE_SESSION } from '../Constants';
import StudentMyLiveSessionsPage from './StudentMyLiveSessionsPage';
import TutorMyLiveSessionsPage from './TutorMyLiveSessionsPage';

class EnrolledOrganizationPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            loading: false,
            organization: undefined,
            organizationDoesNotExist: false,

            showEnrollmentDetails: false,
            revokingEnrollment: false,

            shownProductType: PRODUCT_TYPE_COURSE
        }
    }

    getOrganizationId() {
        return this.props.match.params.organizationId;
    }

    onPageStart() {
        this.setState({loading: true})

        Api.getOrganization(this.getOrganizationId(), response => {
            if (response.status === true) {
                this.setState({
                    loading: false,
                    organization: response.payload,
                    organizationDoesNotExist: false,
                })
            } else {
                this.setState({
                    loading: false,
                    organization: undefined,
                    organizationDoesNotExist: true,
                })
            }
        })
    }

    onRevokeEnrollmentBtn() {
        this.setState({revokingEnrollment: true})
        Api.revokeEnrollment(this.getOrganizationId(), response => {
            if (response.status === true) {
                alert("Successfully revoked enrollment!")
                window.location.replace("/");
            } else {
                this.setState({revokeEnrollment: false});
                UIUtil.showError();
            }
        })
    }

    render() {
        if (this.state.loading) {
            return <> </>;
        }

        if (this.state.organizationDoesNotExist) {
            return (
                <Error404Page {...this.props} customMessage="Organization does not exist" />
            )
        }

        if (this.state.organization === undefined) {
            return <> </>; 
        }

        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div style={{width: '75vw', paddingTop: '25px', paddingBottom: '25px'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <Link to={Util.getProfilePath(this.getOrganizationId())}>
                            <h1 style={{height: '22px'}}><ProfilePicture size={50} userId={this.getOrganizationId()} /> &nbsp; {this.state.organization.organization.fullName}</h1>
                        </Link>
                        <div style={{flex: '1'}} />
                        <Tooltip title="Enrollment Details">
                            <Button size="large" type="link" onClick={() => this.setState({showEnrollmentDetails: true})}><InfoCircleOutlined /></Button>
                        </Tooltip>
                    </div>
                    <div style={{height: '40px'}} />


                    <div style={{display: 'flex', alignItems: 'center'}}>
                        {this.state.shownProductType == PRODUCT_TYPE_COURSE ? (
                            <h3 style={{height: '25px', flex: 1}}><BookOutlined /> Courses</h3>
                        ) : (
                            <h3 style={{height: '25px', flex: 1}}><AlertOutlined /> Live Sessions</h3>
                        )}
                        

                        {<Radio.Group value={this.state.shownProductType} onChange={e => this.setState({shownProductType: e.target.value})} buttonStyle="solid" size="medium" style={{marginLeft: '15px'}}>
                            <Radio.Button value={PRODUCT_TYPE_COURSE}><BookOutlined /> Courses</Radio.Button>
                            <Radio.Button value={PRODUCT_TYPE_LIVE_SESSION}><AlertOutlined /> Live Sessions</Radio.Button>
                        </Radio.Group>}
                    </div>


                    

                    {this.state.shownProductType == PRODUCT_TYPE_COURSE ? (
                        SessionManager.isLoggedInAsStudent() ? <StudentMyCoursesPage organization={this.state.organization.organization} /> : <TutorMyCoursesPage organization={this.state.organization.organization} />
                    ) : (
                        SessionManager.isLoggedInAsStudent() ? <StudentMyLiveSessionsPage organization={this.state.organization.organization} /> : <TutorMyLiveSessionsPage organization={this.state.organization.organization} />
                    )}
                </div>

                <Modal footer={null} visible={this.state.showEnrollmentDetails} onCancel={() => this.setState({showEnrollmentDetails: false})}>
                    <Result title="Enrollment" subTitle={"Enrolled on " + Util.getDate(this.state.organization.enrollment.dateCreated)} extra={
                        <Popconfirm okButtonProps={{loading: this.state.revokingEnrollment}} title="Are you sure? You will lose access to Courses and Live Sessions under the enrollment." onConfirm={() => this.onRevokeEnrollmentBtn()}>
                            <Button danger loading={this.state.revokingEnrollment}>Revoke Enrollment</Button>
                        </Popconfirm>
                    }/>
                </Modal>
            </div>
        )
    }

}

export default EnrolledOrganizationPage;