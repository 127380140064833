import React, { useState } from 'react';
import { UpCircleTwoTone, ArrowUpOutlined } from '@ant-design/icons'

const ScrollButton = () => {
    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 200) {
            setVisible(true)
        }
        else if (scrolled <= 200) {
            setVisible(false)
        }
    };

    const scrollToTop = () => {
        if (!visible) {
            return;
        }

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
        setVisible(false);
    };

    window.addEventListener('scroll', toggleVisible);

    return (
        <div onClick={scrollToTop} className="scroll-to-top-btn" style={{
            opacity: visible ? 1 : 0,
            transform: visible ? undefined : 'scale(0)',
            bottom: visible ? undefined : -100
        }}>

            {/* <UpCircleTwoTone twoToneColor="#f26622" style={{fontSize: 25}} /> */}
            <ArrowUpOutlined />
        </div>

        //<button onClick={scrollToTop} style={{ position: 'fixed', bottom: 25, right: 25, zIndex: 2, display: visible ? 'inline' : 'none' }} > agala</button>
    );
}

export default ScrollButton;