import React, { createRef } from 'react'
import { QRCode } from 'react-qrcode-logo';

import Logo from '../images/QrCodeLogo.png';
import { generateQrCode } from './QrCodeImageGenerator';

import LogoFullWhite from '../images/LogoFullWhite.png';
import ProfilePicture from '../components/ProfilePicture';

import html2canvas from 'html2canvas';
import Api from '../session/Api';
import { Spin } from 'antd';
import UIUtil from '../util/UIUtil';

class LiveStreamQrCode extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loadingCanvas: true,
            canvasURL: undefined
        }

        this.cardRef = createRef();
    }

    generateQrCode() {
        this.setState({loadingCanvas: true})
        setTimeout(() => {
            html2canvas(this.cardRef.current, { useCORS: true, allowTaint: true })
            .then(canvas => this.setState({loadingCanvas: false, canvasURL: canvas.toDataURL()}))
            .catch(() => UIUtil.showError("Failed to generate QR code"))
        }, 500)
    }

    renderQrCode() {
        return (
            <QRCode value={this.props.value}
                eyeRadius={15}
                eyeRadius={[
                    [10, 10, 0, 10],
                    [10, 10, 10, 0], // top/right eye
                    [10, 0, 10, 10], // bottom/left
                ]}
                //fgColor="#f15f43"
                fgColor="#4f3688"
                qrStyle="dots"
                //quietZone={50}
                size={255}
                logoWidth={60}
                logoHeight={60}
                logoImage={Logo}
            />
        )
    }

    render() {
        return (
            <div style={{ position: 'relative' }}>
                {this.state.loadingCanvas && <div ref={this.cardRef} style={{width: 1080 * 0.5, height: 1920 * 0.5, background: 'linear-gradient(135deg, #f15f43, #4f3688)',
                display: 'flex', flexDirection: 'column'}}>
                    <div style={{width: '100%', flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <img src={LogoFullWhite} style={{width: '60%', objectFit: 'contain'}} onLoad={this.generateQrCode.bind(this)} />
                    </div>
                    <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <div style={{width: 300, height: 300, background: 'white', borderRadius: 25, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            {this.renderQrCode()}
                        </div>
                    </div>
                    <div style={{width: '100%', flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        {/* <ProfilePicture size={60} style={{borderWidth: 1, borderStyle: 'solid', borderColor: 'white'}} /> */}
                        <h1 style={{margin: 0, color: 'white'}}>{this.props.hostName}</h1>
                        <h2 style={{margin: 0, marginTop: -5, opacity: 0.65, color: 'white'}}>is inviting you to join</h2>

                        <h1 style={{margin: 0, fontWeight: 'bold', marginLeft: 15, marginRight: 15, textAlign: 'center', color: 'white', marginTop: '10%'}}>{this.props.liveStreamName}</h1>
                    </div>
                </div>}

                <div style={{width: 1080 * 0.5, height: 1920 * 0.5, background: 'white', position: 'absolute', left: 0, top: 0, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    {this.state.loadingCanvas ? <Spin /> : <img src={this.state.canvasURL} style={{width: '100%', height: '100%'}} />}
                </div>
            </div>
        )
    }

}

export default LiveStreamQrCode;