import React from 'react';

import LogoWhite from '../../images/LogoWhite.png'
import HeaderAnimatedB from '../../images/HeaderAnimatedB.mp4'
import HandIcon from '../../images/HandIcon.png'
import {
    BrowserRouter as Router,
    Route,
    Switch,
    withRouter,
    Link
} from 'react-router-dom'
import { Row, Col, Button, Space, Switch as SwitchToggle, Tag, Popover, Input, Slider } from 'antd';
import { Spin, Alert } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { TwitterPicker } from 'react-color';

import './live-stream-window.less'

import { 
    DesktopOutlined,
    VideoCameraOutlined,
    EyeOutlined,
    DragOutlined,
    EditOutlined,
    LineOutlined,
    BorderOutlined,
    AimOutlined,
    ToolOutlined,
    CloseOutlined,
    FontSizeOutlined,
    CheckOutlined,
    CameraOutlined,
    BgColorsOutlined,
    StockOutlined,
    InsertRowBelowOutlined,
    CaretDownOutlined,
    VideoCameraFilled,
    EditFilled,
    AudioFilled
} from '@ant-design/icons';

import CameraStream from '../streams/CameraStream';
import DesktopStream from '../streams/DesktopStream';
import Window from '../../base/Window';
import WebRTCBroadcaster from '../webrtc/WebRTCBroadcaster';
import Page from '../../base/Page';
import Api from '../../session/Api';
import SocketSession from '../../session/SocketSession';
import UIUtil from '../../util/UIUtil';
import Session, { SESSION_TYPE_CAMERA, SESSION_TYPE_DESKTOP, SESSION_TYPE_BOARD, SESSION_TYPE_STAGE_CAMERA, SESSION_TYPE_STAGE_BOARD_OVERLAY } from '../sessions/Session';
import SessionView from '../components/SessionView';
import SessionManager from '../../session/SessionManager';
import { SOCKET_EVENT_LIVE_STREAM_SIGNALLING, ROOM_PARENT_TYPE_LIVE_STREAM } from '../../Constants';
import WebRTCReceiver from '../webrtc/WebRTCReceiver';
import Video from '../../components/Video';
import Util from '../../util/Util';
import ChatView from '../../views/chats/ChatView';

import Draggable from 'react-draggable';

import {SketchField, Tools} from 'react-sketch';
import UploadWrapper from '../../components/UploadWrapper';

import {isChrome} from 'react-device-detect';
import Modal from 'antd/lib/modal/Modal';
import { Modal as ModalUtil } from 'antd';
import UserList from '../../components/UserList';
import StageUserList from '../components/StageUserList';
import LiveStreamAttendanceReport from '../components/LiveStreamAttendanceReport';

const LIVE_STREAM_EVENT_ON_SESSION_CREATED = 0;
const LIVE_STREAM_EVENT_ON_SESSION_CLOSED = 1;
const LIVE_STREAM_EVENT_ON_BROADCAST_TOKEN_CHANGED = 2;
const LIVE_STREAM_EVENT_ON_CHAT_ENABLE_SET = 3;
const LIVE_STREAM_EVENT_ON_MESSAGE = 4;
const LIVE_STREAN_EVENT_ON_VIEWERS_UPDATE = 5;

export const WHITEBOARD_WIDTH = 800;
export const WHITEBOARD_HEIGHT = 600;

const sessionEnabled = (type) => {
    return type + "Enabled";
}

const sessionLoading = (type) => {
    return type + "Loading";
}

const sessionError = (type) => {
    return type + "Error";
}

const sessionErrorTimeout = (type) => {
    return type + "ErrorTimeout";
}

const sessionSession = (type) => {
    return type + "Session";
}

const sessionStream = (type) => {
    return type + "Stream";
}

const sessionHideInfo = (type) => {
    return type + "HideInfo";
}

const sessionShowAnyway = (type) => {
    return type = "ShowAnyway";
}

const sessionElement = (type) => {
    return type + "Element"
}

class LiveBroadcasterWindow extends Page {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            liveStream: undefined,
            liveStreamDoesNotExist: false,
            liveStreamRevoked: false,
            liveStreamOver: false,

            liveStreamLiveDuration: 0,
            liveStreamTimeLeft: 0,

            liveStreamViewers: 0,

            focusSession: SESSION_TYPE_BOARD,
            fullScreenSession: undefined,

            showViewers: false,

            isStageUser: false,

            stageAllowedMic: true,
            stageAllowedCamera: true,
            stageAllowedWhiteboard: true,

            myPermissionMic: false,
            myPermissionCamera: false,
            myPermissionWhiteboard: false,

            raisedHand: false,
            raisingHand: false,
        }

        this.chatRef = React.createRef();
        this.whiteboardRef = React.createRef();
        this.stageListRef = React.createRef();

        setInterval(() => {
            if (this.state.liveStreamOver) {
                return;
            }

            if (this.state.liveStream !== undefined && this.state.liveStream.id == this.getLiveStreamId()) {
                this.setState(prevState => ({
                    liveStreamLiveDuration: prevState.liveStreamLiveDuration + 1,
                    liveStreamTimeLeft: prevState.liveStreamTimeLeft - 1
                }));
                if ((this.state.liveStreamTimeLeft - 1) <= 0) {
                    this.setState({
                        liveStreamOver: true
                    })                    
                }
            }
        }, 1000)

        if (!this.isBroadcasting()) {
            setInterval(() => {
                if (this.state.liveStreamOver || this.isBroadcasting()) {
                    return;
                }
    
                if (this.state.liveStream !== undefined && this.state.liveStream.id == this.getLiveStreamId()) {
                    Api.pingLiveStreamViewer(this.getLiveStreamId(), () => {});
                }
            }, 3000)
        }
    }

    updatePermission(onFail) {
        Api.setLiveStreamStagePermission(this.getLiveStreamId(), this.state.stageAllowedMic, this.state.stageAllowedCamera, this.state.stageAllowedWhiteboard, response => {
            if (response.status === false) {
                UIUtil.showError("An error occurred updating permission")
                onFail();
            }
        });
    }

    isBroadcasting() {
        //return SessionManager.isLoggedInAsTutor();
        if (this.state.liveStream !== undefined) {
            return Util.isStringExists(this.state.liveStream.broadcastToken);
        } else {
            return Util.isStringExists(this.broadcastToken);
        }
    }

    getLiveStreamId() {
        return this.props.match.params.liveStreamId;
    }

    onPageStart() {
        this.setState({loading: true})
        Api.getLiveStream(this.getLiveStreamId(), response => {
            if (response.status === true) {
                const broadcasting = Util.isStringExists(response.payload.broadcastToken)

                //if (this.isBroadcasting()) {
                if (broadcasting) {
                    this.broadcastToken = response.payload.broadcastToken;
                }

                //if (!this.isBroadcasting()) {
                if (!broadcasting) {
                    if (response.payload.hasCamera) {
                        //this.onToggleSession(SESSION_TYPE_CAMERA, true)
                        this.onToggleSession(SESSION_TYPE_CAMERA, true, response.payload.cameraStreamInfo.hasAudio, response.payload.cameraStreamInfo.hasVideo);
                    }
                    if (response.payload.hasDesktop) {
                        this.onToggleSession(SESSION_TYPE_DESKTOP, true);
                    }
                    if (response.payload.hasWhiteboard) {
                        this.onToggleSession(SESSION_TYPE_BOARD, true);
                    }
                }

                if (response.payload.hasStageCamera) {
                    this.onToggleSession(SESSION_TYPE_STAGE_CAMERA, true, response.payload.stageCameraStreamInfo.hasAudio, response.payload.stageCameraStreamInfo.hasVideo);
                }

                if (response.payload.hasChat) {
                    this.setState({[sessionEnabled(-1)]: true})
                }

                this.socketSubscriptionEvent = SOCKET_EVENT_LIVE_STREAM_SIGNALLING + "-" + response.payload.id;
                SocketSession.subscribe(this.socketSubscriptionEvent, subscription => {
                    this.socketSubscription = subscription;
                    //onLiveStreamEventListener = (this.isBroadcasting() ? this.onBroadcastingLiveStreamEvent : this.onViewerLiveStreamEvent).bind(this)
                    this.onLiveStreamEventListener = (broadcasting ? this.onBroadcastingLiveStreamEvent : this.onViewerLiveStreamEvent).bind(this)
                    SocketSession.registerListener(this.socketSubscriptionEvent, this.onLiveStreamEventListener);

                    this.setState({
                        loading: false,
                        liveStream: response.payload,
                        liveStreamDoesNotExist: false,
                        liveStreamRevoked: false,

                        liveStreamLiveDuration: (new Date().getTime() - response.payload.date) / 1000,
                        liveStreamTimeLeft: ((response.payload.date + (response.payload.duration * 60000)) - new Date().getTime()) / 1000,

                        liveStreamViewers: response.payload.viewers,

                        stageAllowedMic: response.payload.stageAllowedMic,
                        stageAllowedCamera: response.payload.stageAllowedCamera,
                        stageAllowedWhiteboard: response.payload.stageAllowedWhiteboard,

                        raisedHand: response.payload.raisedHand
                    })
                });
            } else {
                this.setState({
                    loading: false,
                    liveStream: {},
                    liveStreamDoesNotExist: true,
                    liveStreamRevoked: false
                })
            }
        })
    }

    componentWillUnmount() {
        this.close();
    }

    close() {
        this.broadcastToken = undefined;

        this.closeSession(SESSION_TYPE_CAMERA);
        this.closeSession(SESSION_TYPE_DESKTOP);
        this.closeSession(SESSION_TYPE_BOARD);
        this.closeSession(SESSION_TYPE_STAGE_CAMERA);
        this.closeSession(SESSION_TYPE_STAGE_BOARD_OVERLAY);

        if (this.socketSubscription !== undefined) {
            this.socketSubscription.unsubscribe();
        }
        if (this.onLiveStreamEventListener !== undefined) {
            SocketSession.unregisterListener(this.socketSubscriptionEvent, this.onLiveStreamEventListener);
        }
    }

    onViewerLiveStreamEvent({type, payload}) {
        switch (type) {
            case LIVE_STREAM_EVENT_ON_SESSION_CREATED:
                if (payload != SESSION_TYPE_STAGE_BOARD_OVERLAY) {
                    if (payload != SESSION_TYPE_STAGE_CAMERA || !this.state.isStageUser) {
                        this.onToggleSession(payload, true)
                    }
                }
                break;
            case LIVE_STREAM_EVENT_ON_SESSION_CLOSED:
                if (payload != SESSION_TYPE_STAGE_BOARD_OVERLAY) {
                    if (payload != SESSION_TYPE_STAGE_CAMERA || !this.state.isStageUser) {
                        this.onToggleSession(payload, false)
                    }
                }
                break;
            case LIVE_STREAM_EVENT_ON_CHAT_ENABLE_SET:
                this.setState({[sessionEnabled(-1)]: payload})
                break;
            case LIVE_STREAM_EVENT_ON_MESSAGE:
                if (this.chatRef.current !== undefined && this.chatRef.current !== null) {
                    this.chatRef.current.addMessage(payload, true);
                }
                break;
            case LIVE_STREAN_EVENT_ON_VIEWERS_UPDATE:
                this.setState({liveStreamViewers: payload})
                if (this.stageListRef.current !== undefined && this.stageListRef.current !== null) {
                    this.stageListRef.current.loadStageList();
                }
                break;
        }
    }
    onBroadcastingLiveStreamEvent({type, payload}) {
        switch (type) {
            case LIVE_STREAM_EVENT_ON_SESSION_CREATED:
                if (payload == SESSION_TYPE_STAGE_CAMERA || payload == SESSION_TYPE_STAGE_BOARD_OVERLAY) {
                    this.onToggleSession(payload, true)
                }
                break;
            case LIVE_STREAM_EVENT_ON_SESSION_CLOSED:
                if (payload == SESSION_TYPE_STAGE_CAMERA || payload == SESSION_TYPE_STAGE_BOARD_OVERLAY) {
                    this.onToggleSession(payload, false)
                }
                break;

            case LIVE_STREAM_EVENT_ON_BROADCAST_TOKEN_CHANGED:
                if (payload != this.broadcastToken) {
                    this.setState({ liveStreamRevoked: true })
                    this.close();
                }
                break;
            case LIVE_STREAM_EVENT_ON_CHAT_ENABLE_SET:
                this.setState({[sessionEnabled(-1)]: payload})
                break;
            case LIVE_STREAM_EVENT_ON_MESSAGE:
                if (this.chatRef.current !== undefined && this.chatRef.current !== null) {
                    this.chatRef.current.addMessage(payload, true);
                }
                break;
            case LIVE_STREAN_EVENT_ON_VIEWERS_UPDATE:
                this.setState({liveStreamViewers: payload})
                if (this.stageListRef.current !== undefined && this.stageListRef.current !== null) {
                    this.stageListRef.current.loadStageList();
                }
                break;
        }
    }
    //onLiveStreamEventListener = (this.isBroadcasting() ? this.onBroadcastingLiveStreamEvent : this.onViewerLiveStreamEvent).bind(this);

    onToggleSession(type, enable, hasAudio, hasVideo) {
        this.setState({
            [sessionLoading(type)]: true
        })

        if (enable) {
            this.enableSession(type, hasAudio, hasVideo);
        } else {
            this.closeSession(type);
        }
    }

    enableSession(type, hasAudio, hasVideo) {
        if (this[sessionErrorTimeout(type)] !== undefined) {
            clearTimeout(this[sessionErrorTimeout(type)]);
            this.setState({
                [sessionError(type)]: false
            })
            this[sessionErrorTimeout(type)] = undefined;
        }

        const onSuccess = (extra) => {
            this.setState({
                [sessionEnabled(type)]: true,
                [sessionLoading(type)]: false,
                ...extra
            })
        }

        const onError = () => {
            this.setState({
                [sessionLoading(type)]: false,
                [sessionError(type)]: true
            })

            this[sessionErrorTimeout(type)] = setTimeout(() => {
                this.setState({
                    [sessionError(type)]: false
                })
            }, 2000)
        }

        if (type === -1) {
            Api.setLiveStreamChatEnable(this.getLiveStreamId(), true, response => {
                if (response.status === true) {
                    onSuccess();
                } else {
                    onError();
                }
            })
        } else {
            const createSession = () => {
                //this[sessionSession(type)] = new Session(this.getLiveStreamId(), type, this.isBroadcasting());
                if (type != SESSION_TYPE_STAGE_CAMERA && type != SESSION_TYPE_STAGE_BOARD_OVERLAY) {
                    if (this.isBroadcasting() && !isChrome) {
                        UIUtil.showError("Your browser is not supported. Please download Google Chrome to continue.")
                        onError();
                        return;
                    }

                    this[sessionSession(type)] = new Session(this.getLiveStreamId(), type, this.isBroadcasting());
                } else {
                    if (this.state.isStageUser && !isChrome) {
                        UIUtil.showError("Your browser is not supported. Please download Google Chrome to continue.")
                        onError();
                        return;
                    }

                    this[sessionSession(type)] = new Session(this.getLiveStreamId(), type, this.state.isStageUser);
                }
                this[sessionSession(type)].hasAudio = hasAudio !== undefined ? hasAudio : true;
                this[sessionSession(type)].hasVideo = hasVideo !== undefined ? hasVideo : true;
                if ((this.isBroadcasting() && type === SESSION_TYPE_BOARD) || (this.state.isStageUser && type === SESSION_TYPE_STAGE_BOARD_OVERLAY)) {
                    this[sessionSession(type)].boardCanvas = this.whiteboardRef.current._canvas;
                    this.setState({
                        selectedWhiteboardTool: Tools.Pencil,
                        showWhiteboard: true,
                        whiteboardAddTextValue: "",
                        whiteboardStrokeColor: "black",
                        whiteboardStrokeWeight: 3
                    })
                    //this.setState({[sessionHideInfo(type)]: true})
                }

                this[sessionSession(type)].create(success => {
                    if (success) {
                        //console.log(this[sessionSession(type)].getTrackStream())
                        //this.setState({test: this[sessionSession(type)].getTrackStream()})
                        onSuccess({
                            [sessionStream(type)]: this[sessionSession(type)].getTrackStream(),
                            [sessionShowAnyway(type)]: false
                        })

                        if (type == SESSION_TYPE_BOARD && !this.isBroadcasting() && this.state.isStageUser && this.state.myPermissionWhiteboard) {
                            this.onToggleSession(SESSION_TYPE_STAGE_BOARD_OVERLAY, true);
                        }

                        if (type == SESSION_TYPE_STAGE_BOARD_OVERLAY && this.isBroadcasting() && !this.state.isStageUser && this[sessionSession(SESSION_TYPE_BOARD)] !== undefined && this[sessionSession(SESSION_TYPE_BOARD)].stream !== undefined) {
                            this[sessionSession(SESSION_TYPE_BOARD)].stream
                            .setBackground(this[sessionSession(type)].getTrackStream())
                        }

                        /*setTimeout(() => {
                            if (this.boardOverlay !== undefined && this.boardOverlay.current !== undefined) {
                                const canvas = this.whiteboardRef.current._fc;

                                const video = new fabric.Image(this.boardOverlay.current, {
                                    objectCaching: false,
                                });
                                canvas.backgroundImage = video;

                                fabric.util.requestAnimFrame(function render() {
                                    canvas.renderAll();
                                    fabric.util.requestAnimFrame(render);
                                });
                            }
                        }, 2500)*/

                        //if (type == SESSION_TYPE_BOARD && this.whiteboardRef.current) {
                            // && !this.isBroadcasting()) {
                            //const canvas = this.whiteboardRef.current._canvas;
                            /*const canvas = this.whiteboardRef.current._fc;

                            console.log(this.testRef.current)
                            const video = new fabric.Image(this.testRef.current, {
                                //left: 0,
                                //top: 0,
                                //originX: 'center',
                                //originY: 'center',
                                objectCaching: false,
                                //width: 200,
                               //height: 200
                           });
                            canvas.backgroundImage = video;
                           //canvas.add(video);

                           fabric.util.requestAnimFrame(function render() {
                            canvas.renderAll();
                            fabric.util.requestAnimFrame(render);
                          });*/
                            /*setInterval(() => {
                                const context = canvas.getContext("2d");
                                if (this.testRef.current) {
                                    const data = context.getImageData(0,0,canvas.width,canvas.height);
                                    context.drawImage(this.testRef.current, 0, 0)
                                    context.putImageData(data,0,0);
                                }
                            }, 1000 / 5);*/
                        //}
                    } else {
                        onError();
                    }
                })
            }

            if (type === SESSION_TYPE_BOARD) {
                this.setState({[sessionShowAnyway(type)]: true}, createSession);
            } else {
                createSession();
            }
        }
    }

    closeSession(type) {
        if (type === -1) {
            Api.setLiveStreamChatEnable(this.getLiveStreamId(), false, response => {
                if (response.status === true) {
                    this.setState({
                        [sessionEnabled(type)]: false,
                        [sessionLoading(type)]: false
                    })
                } else {
                    this.setState({
                        [sessionLoading(type)]: false
                    })
                    UIUtil.showError();
                }
            })
        } else {
            if (this[sessionSession(type)] !== undefined) {
                this[sessionSession(type)].close(true);
                this[sessionSession(type)] = undefined;
            }

            this.setState({
                [sessionEnabled(type)]: false,
                [sessionLoading(type)]: false
            })

            if (type == SESSION_TYPE_BOARD && !this.isBroadcasting() && this.state.isStageUser && this.state[sessionEnabled(SESSION_TYPE_STAGE_BOARD_OVERLAY)]) {
                this.onToggleSession(SESSION_TYPE_STAGE_BOARD_OVERLAY, false);
            }

            if (type == SESSION_TYPE_STAGE_BOARD_OVERLAY && this.isBroadcasting() && !this.state.isStageUser && this.state[sessionEnabled(SESSION_TYPE_BOARD)]
            && this[sessionSession(SESSION_TYPE_BOARD)] !== undefined && this[sessionSession(SESSION_TYPE_BOARD)].stream !== undefined) {
                this[sessionSession(SESSION_TYPE_BOARD)].stream
                .setBackground(undefined)
            }
        }
    }
    
    renderSession(type) {
        return (
            <SessionView
            whiteBoardControlMessage={type == SESSION_TYPE_BOARD && this.state[sessionEnabled(SESSION_TYPE_STAGE_BOARD_OVERLAY)] && this.state.isStageUser && !this.isBroadcasting()}
            key={type} showAnyway={this.state[sessionShowAnyway(type)]}
            type={type} broadcasting={this.isBroadcasting()} hideInfo={this.state[sessionHideInfo(type)]}
            fullScreen={this.state.fullScreenSession === type} onFullScreenBtn={() => this.setState(prevState => 
                ({fullScreenSession: prevState.fullScreenSession === undefined ? type : undefined}))} 
            focus={this.state.focusSession === type} onFocusBtn={() => this.setState({focusSession: type})} 
            loading={this.state[sessionLoading(type)]} showError={this.state[sessionError(type)]} active={this.state[sessionEnabled(type)]} 
            onChange={this.onToggleSession.bind(this)} onChange2={(enable, hasAudio, hasVideo) => {
                if (enable) {
                    this.onToggleSession(type, true, hasAudio, hasVideo);
                } else {
                    this.onToggleSession(type, false);
                }
            }}>
                {type === -1 ? (
                    <div style={{borderRadius: '25px', width: '100%', height: '100%'}}>
                        <ChatView ref={this.chatRef} liveStream liveStreamStartDate={this.state.liveStream.date} roomParentType={ROOM_PARENT_TYPE_LIVE_STREAM} roomParentId={this.getLiveStreamId()} />
                    </div>
                ) : (type === SESSION_TYPE_BOARD ? (this.isBroadcasting() ? (
                    <div style={{borderRadius: '25px', width: '100%', height: '100%', textAlign: 'center'}}>
                        <Video /*alwaysMuted={this.isBroadcasting()} muted={this.isBroadcasting()}*/ alwaysMuted muted onClick={() => this.setState({showWhiteboard: true})} onLoadedMetadata={() => this.setState({[sessionHideInfo(type)]: true})} srcObject={this.state[sessionStream(type)]} style={{borderRadius: '25px', width: '100%', height: '100%'}} autoPlay playsInline controls={false} />
                    </div>
                )   : (
                    //whiteboard user
                    <Video alwaysMuted muted onClick={this.state.isStageUser && this.state.myPermissionWhiteboard && isChrome && (() => this.setState({showWhiteboard: true}))} 
                    onLoadedMetadata={() => this.setState({[sessionHideInfo(type)]: true}, () => {
                        if (!this.state[sessionEnabled(SESSION_TYPE_STAGE_BOARD_OVERLAY)] && this.state[sessionEnabled(SESSION_TYPE_BOARD)] && 
                            !this.isBroadcasting() && this.state.isStageUser && this.state.myPermissionWhiteboard) {
                            this.onToggleSession(SESSION_TYPE_STAGE_BOARD_OVERLAY, true);
                        }
                    })} 
                    srcObject={this.state[sessionStream(type)]} style={{borderRadius: '25px', width: '100%', height: '100%'}} autoPlay playsInline controls={false} />
                )
                ) : (
                    <Video alwaysMuted={this.isBroadcasting()} muted={this.isBroadcasting()} onLoadedMetadata={() => this.setState({[sessionHideInfo(type)]: true})} srcObject={this.state[sessionStream(type)]} style={{borderRadius: '25px', width: '100%', height: '100%'}} autoPlay playsInline controls={false} />
                ))}
            </SessionView>
        )
    }

    renderStageUserList() {
        const type = SESSION_TYPE_STAGE_CAMERA;
        return (
            <StageUserList ref={this.stageListRef} liveStream={this.state.liveStream} broadcasting={this.isBroadcasting()} 
            onStageUserUpdate={(isStageUser, stage) => {
                const leavingStage = !isStageUser && this.state.isStageUser;

                if (leavingStage && this.state[sessionEnabled(type)]) {
                    this.onToggleSession(type, false);
                }
                if (isStageUser && !this.state.isStageUser) {
                    ModalUtil.info({
                        title: "Stage",
                        content: "You are now on stage!",
                        okText: "OK",
                    })
                }

                this.setState({isStageUser: isStageUser, myPermissionMic: stage.allowedMic, 
                    myPermissionCamera: stage.allowedCamera, myPermissionWhiteboard: stage.allowedWhiteboard}, () => {
                    if (this.state[sessionEnabled(SESSION_TYPE_BOARD)] && !this.state[sessionEnabled(SESSION_TYPE_STAGE_BOARD_OVERLAY)] && 
                    this.state[sessionHideInfo(SESSION_TYPE_BOARD)] && !this.isBroadcasting() && isStageUser && this.state.myPermissionWhiteboard) {
                        this.onToggleSession(SESSION_TYPE_STAGE_BOARD_OVERLAY, true);
                    } else if (this.state[sessionEnabled(SESSION_TYPE_STAGE_BOARD_OVERLAY)] && !this.isBroadcasting() && leavingStage) {
                        this.onToggleSession(SESSION_TYPE_STAGE_BOARD_OVERLAY, false);
                    }

                    if (!this.isBroadcasting() && isStageUser) {
                        if (!this.state.myPermissionCamera && !this.state.myPermissionMic && this.state[sessionEnabled(SESSION_TYPE_STAGE_CAMERA)]) {
                            this.onToggleSession(SESSION_TYPE_STAGE_CAMERA, false);
                        } else if (this.state[sessionEnabled(SESSION_TYPE_STAGE_CAMERA)]) {
                            if ((this[sessionSession(SESSION_TYPE_STAGE_CAMERA)] !== undefined && this[sessionSession(SESSION_TYPE_STAGE_CAMERA)].stream !== undefined && this[sessionSession(SESSION_TYPE_STAGE_CAMERA)].stream.hasAudio && !this.state.myPermissionMic) ||
                            this[sessionSession(SESSION_TYPE_STAGE_CAMERA)] !== undefined && this[sessionSession(SESSION_TYPE_STAGE_CAMERA)].stream !== undefined && this[sessionSession(SESSION_TYPE_STAGE_CAMERA)].stream.hasVideo && !this.state.myPermissionCamera) {
                                this.onToggleSession(SESSION_TYPE_STAGE_CAMERA, false);
                            }
                        }

                        if (!this.state.myPermissionWhiteboard && this.state[sessionEnabled(SESSION_TYPE_STAGE_BOARD_OVERLAY)]) {
                            this.onToggleSession(SESSION_TYPE_STAGE_BOARD_OVERLAY, false);
                        }
                    }

                })
            }}
            showAnyway={this.state[sessionShowAnyway(type)]}
            type={type} hideInfo={this.state[sessionHideInfo(type)]}
            loading={this.state[sessionLoading(type)]} showError={this.state[sessionError(type)]} active={this.state[sessionEnabled(type)]} 
            onChange={(enable, hasAudio, hasVideo) => {
                if (enable) {
                    this.setState({isStageUser: true}, () => this.onToggleSession(type, true, hasAudio, hasVideo));
                } else {
                    this.onToggleSession(type, false);
                }
            }}

            >
                <Video alwaysMuted={this.state.isStageUser} muted={this.state.isStageUser} onLoadedMetadata={() => this.setState({[sessionHideInfo(type)]: true})} 
                srcObject={this.state[sessionStream(type)]} style={{borderRadius: '25px', width: '100%', height: '100%'}}
                autoPlay playsInline controls={false} />
            </StageUserList>
        )
    }

    renderWhiteboardTool(tool, icon) {
        return (
            <Button onClick={e => this.setState({selectedWhiteboardTool: tool})} type={this.state.selectedWhiteboardTool === tool ? "primary" : "link"} size="large" 
            className={this.state.selectedWhiteboardTool === tool ? "red-button" : "red-link"} style={{color: 'white'}}>{icon}</Button>
        )
    }

    render() {
        if (this.state.loading) {
            return (
                <div style={{background: 'black', width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 40, color: '#990000' }} spin />} style={{color: '#990000'}} /> 
                </div>
            )
        }

        if (this.state.liveStreamDoesNotExist) {
            return (
                <div style={{background: 'black', width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                    <h1 style={{color: 'white'}} align="center">
                        404 Page not found
                    </h1>
                    <h5 style={{color: '#8a8a8a'}} align="center">
                        {this.props.customMessage !== undefined ? this.props.customMessage : 'Live stream does not exist'}
                    </h5>

                    <br />
                    <br />
                    
                    <Button type="primary" size="medium" className="red-button" >
                        <Link to="/">
                            Go Home
                        </Link>
                    </Button>
                </div>
            )
        }

        if (this.isBroadcasting() && !isChrome) {
        //if (!isChrome) {
            return (
                <div style={{background: 'black', width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                    <h1 style={{color: 'white'}} align="center">
                        Your browser is not supported
                    </h1>
                    <h5 style={{color: '#8a8a8a'}} align="center">
                        Please download Google Chrome to continue
                    </h5>

                    <br />
                    <br />
                    
                    <Button type="primary" size="medium" className="red-button" >
                        <Link to="/">
                            Go Home
                        </Link>
                    </Button>
                </div>
            )
        }

        if (this.state.liveStreamOver) {
            if (this.isBroadcasting()) {
                return (
                    <div style={{background: 'black', width: '100%', height: '100vh', display: 'flex', alignItems: 'center', flexDirection: 'column', overflow: 'auto', paddingTop: 100, paddingBottom: 100}}>
                        <h1 style={{color: 'white'}} align="center">
                            Live stream is over
                        </h1>
    
                        <br />
                        <br />
    
                        <LiveStreamAttendanceReport liveStreamId={this.getLiveStreamId()} />
    
                        <br />
                        <br />
                        <br />
                        <br />
                        
                        <Button type="primary" size="medium" className="red-button" >
                            <Link to="/">
                                Go Home
                            </Link>
                        </Button>
                    </div>
                )
            } else {
                return (
                    <div style={{background: 'black', width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                        <h1 style={{color: 'white'}} align="center">
                            Live stream is over
                        </h1>
                        <h5 style={{color: '#8a8a8a'}} align="center">
                            
                        </h5>

                        <br />
                        <br />
                        
                        <Button type="primary" size="medium" className="red-button" >
                            <Link to="/">
                                Go Home
                            </Link>
                        </Button>
                    </div>
                )
            }
        }

        if (this.state.liveStreamRevoked) {
            return (
                <div style={{background: 'black', width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                    <h1 style={{color: 'white'}} align="center">
                        Live stream being accessed by another place
                    </h1>
                    <h5 style={{color: '#8a8a8a'}} align="center">
                        Live stream broadcasting can only by done in one place
                    </h5>

                    <br />
                    <br />
                    
                    <Button type="primary" size="medium" className="red-button" onClick={() => window.location.reload()}>
                        Connect Here
                        {/*<Link to={Util.getLiveStreamPath(this.getLiveStreamId())}>
                            Connect Here
                        </Link>*/}
                    </Button>
                </div>
            )
        }

        /*let sessionElements = {};
        sessionElements[sessionElement(-1)] = this.renderSession(-1);
        sessionElements[sessionElement(SESSION_TYPE_BOARD)] = this.renderSession(SESSION_TYPE_BOARD);
        sessionElements[sessionElement(SESSION_TYPE_CAMERA)] = this.renderSession(SESSION_TYPE_CAMERA);
        sessionElements[sessionElement(SESSION_TYPE_DESKTOP)] = this.renderSession(SESSION_TYPE_DESKTOP);*/

        return (
            <div style={{background: 'black', width: '100%', display: 'flex', flexDirection: 'column', height: '100vh'}}>
                <div style={{height: '75px', paddingLeft: '20px', paddingRight: '20px', display: 'flex', alignItems: 'center'}}>
                    <Link to="/"><img src={LogoWhite} style={{height: '50px'}} /></Link>
                    {/*<h2 style={{color: '#990000', marginLeft: '40px', paddingTop: '5px', height: '34px'}}>Live Stream {this.isBroadcasting() ? 'Broadcaster' : ''}</h2>*/}
                    <Tag color="#990000" style={{marginLeft: '40px', cursor: 'pointer'}} onClick={() => this.setState({showViewers: true})}><EyeOutlined /> {this.state.liveStreamViewers}</Tag>
                    <h5 style={{color: 'gray', paddingTop: '1.5px', marginLeft: '30px', height: '15px'}}>{Util.formatSeconds(this.state.liveStreamLiveDuration) + " - " + Util.formatSeconds(this.state.liveStreamTimeLeft)}</h5>
                    <div style={{flex: '1'}} />
                    <Button onClick={() => this.props.history.goBack()} className="red-button" size="large" type="primary" style={{color: 'white', fontSize: '12px', paddingLeft: '25px', paddingRight: '25px', marginLeft: '15px'}}>Leave Live Stream</Button>
                </div>

                <div style={{}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <h3 style={{color: 'white', marginRight: '5px', height: '20px', paddingLeft: '25px'}}><InsertRowBelowOutlined /> Stage</h3>
                        {this.isBroadcasting() &&
                        <Popover content={<div style={{width: '225px'}}>
                            <p>Permissions</p>

                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <AudioFilled style={{color: 'black'}} />
                                <p style={{fontWeight: 'bold', marginLeft: '5px', color: 'black', height: '10px'}}>Mic</p>
                                <div style={{flex: 1}} />
                                <SwitchToggle checkedChildren="On" unCheckedChildren="Off" checked={this.state.stageAllowedMic} onChange={checked => {
                                    const oldStageAllowedMic = this.state.stageAllowedMic;
                                    this.setState({stageAllowedMic: checked}, () => {
                                        this.updatePermission(() => this.setState({stageAllowedMic: oldStageAllowedMic}))
                                    })
                                }} />
                            </div>

                            <div style={{display: 'flex', alignItems: 'center', marginTop: '5px'}}>
                                <VideoCameraFilled style={{color: 'black'}} />
                                <p style={{fontWeight: 'bold', marginLeft: '5px', color: 'black', height: '10px'}}>Camera</p>
                                <div style={{flex: 1}} />
                                <SwitchToggle checkedChildren="On" unCheckedChildren="Off" checked={this.state.stageAllowedCamera} onChange={checked => {
                                    const oldStageAllowedCamera = this.state.stageAllowedCamera;
                                    this.setState({stageAllowedCamera: checked}, () => {
                                        this.updatePermission(() => this.setState({stageAllowedCamera: oldStageAllowedCamera}))
                                    })
                                }} />
                            </div>

                            <div style={{display: 'flex', alignItems: 'center', marginTop: '5px'}}>
                                <EditFilled style={{color: 'black'}} />
                                <p style={{fontWeight: 'bold', marginLeft: '5px', color: 'black', height: '10px'}}>Whiteboard</p>
                                <div style={{flex: 1}} />
                                <SwitchToggle checkedChildren="On" unCheckedChildren="Off" checked={this.state.stageAllowedWhiteboard} onChange={checked => {
                                    const oldStageAllowedWhiteboard = this.state.stageAllowedWhiteboard;
                                    this.setState({stageAllowedWhiteboard: checked}, () => {
                                        this.updatePermission(() => this.setState({stageAllowedWhiteboard: oldStageAllowedWhiteboard}))
                                    })
                                }} />
                            </div>
                        </div>} trigger="click" placement="bottomLeft">
                            <CaretDownOutlined size="small" style={{color: 'white', fontSize: '12px'}} />
                        </Popover>}

                        <div style={{flex: 1}} />

                        {!this.isBroadcasting() &&
                        <Button style={{padding: 0}} icon={<img src={HandIcon} style={{width: 15, height: 15, marginRight: 7}} />} 
                        loading={this.state.raisingHand}
                        type="link" className={this.state.raisedHand ? "red-link" : "orange-link"} onClick={() => {
                            this.setState(prevState => {
                                const raisedHand = !prevState.raisedHand;
                                Api.setLiveStreamRaiseHand(this.getLiveStreamId(), raisedHand, response => {})
                                return {raisedHand: raisedHand};
                            })

                            /*this.setState(prevState => ({raisedHand: !prevState.raisedHand}), () => {
                                
                            })*/
                        }}> 
                            {this.state.raisedHand ? 'Cancel hand raise' : 'Raise Hand'}
                        </Button>}

                        <div style={{width: '25px'}} />
                    </div>
                    {this.renderStageUserList()}
                </div>

                <div style={{flex: '1', minHeight: '1px'}}>
                    {this.state.fullScreenSession !== undefined ? (
                        <div style={{padding: '15px', width: '100%', height: '100%'}}>
                            {this.renderSession(this.state.fullScreenSession)}
                        </div>
                    ) : (
                        <Row style={{height: '100%'}}>
                            <Col span={7} style={{padding: '15px'}}>
                                {this.renderSession(this.state.focusSession === -1 ? SESSION_TYPE_BOARD : -1)}
                            </Col>
                            <Col span={11} style={{padding: '15px'}}>
                                {this.renderSession(this.state.focusSession)}
                            </Col>
                            <Col span={6} style={{padding: '15px', display: 'flex', flexDirection: 'column'}}>
                                {this.renderSession(this.state.focusSession === SESSION_TYPE_CAMERA ? SESSION_TYPE_BOARD : SESSION_TYPE_CAMERA)}
                                <div style={{height: '25px'}} />
                                {this.renderSession(this.state.focusSession === SESSION_TYPE_DESKTOP ? SESSION_TYPE_BOARD : SESSION_TYPE_DESKTOP)}
                            </Col>
                        </Row>
                    )}
                </div>

                {(this.isBroadcasting() || this.state.isStageUser) && (this.state[sessionEnabled(SESSION_TYPE_BOARD)] || this.state[sessionShowAnyway(SESSION_TYPE_BOARD)]) && 
                //true &&
                //<div style={this.state.showWhiteboard ? {opacity: '0', pointerEvents: 'none'} : undefined}>
                <div style={!this.state.showWhiteboard ? {opacity: '0', pointerEvents: 'none'} : undefined}>
                    <Draggable handle=".side-bar">
                        <div style={{position: 'fixed', top: '20px', left: '20px', borderRadius: '25px', boxShadow: "0px 0px 25px 0px rgba(0, 0, 0, 0.2)", overflow: 'hidden', display: 'flex'}}>
                            <div className="side-bar" style={{background: '#1B1B1B', width: '45px', height: WHITEBOARD_HEIGHT, 
                            borderRadius: '25px 0 0 25px', cursor: 'move', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <div style={{flex: '1', overflow: 'auto'}}>
                                    <br />
                                    {this.renderWhiteboardTool(Tools.Select, <ToolOutlined />)}
                                    {this.renderWhiteboardTool(Tools.Pencil, <EditOutlined />)}
                                    {this.renderWhiteboardTool(Tools.Line, <LineOutlined />)}
                                    {this.renderWhiteboardTool(Tools.Rectangle, <BorderOutlined />)}
                                    {this.renderWhiteboardTool(Tools.Circle, <AimOutlined />)}
                                    {this.renderWhiteboardTool(Tools.Pan, <DragOutlined />)}
                                    <div style={{height: '25px'}} />
                                    <Popover placement="topLeft" trigger="click" content={
                                        <Input size="large"
                                        onPressEnter={e => {
                                            this.whiteboardRef.current.addText(this.state.whiteboardAddTextValue)
                                            this.setState({whiteboardAddTextValue: ""})
                                        }}
                                        value={this.state.whiteboardAddTextValue} onChange={e => this.setState({whiteboardAddTextValue: e.target.value})} style={{ width: '300px' }} placeholder="Add text" 
                                        suffix={<Button disabled={this.state.whiteboardAddTextValue === undefined || this.state.whiteboardAddTextValue.trim() === ""} onClick={e => {
                                            this.whiteboardRef.current.addText(this.state.whiteboardAddTextValue)
                                            this.setState({whiteboardAddTextValue: ""})
                                        }}><CheckOutlined /></Button>} />
                                    }>
                                        <Button type={"link"} size="large" 
                                            className={"red-link"} style={{color: 'white'}}><FontSizeOutlined /></Button>
                                    </Popover>
                                    {//!((!this.state.isStageUser && this.state[sessionEnabled(SESSION_TYPE_STAGE_BOARD_OVERLAY)]) || this.state.isStageUser) && 
                                    <UploadWrapper accept="image/gif,image/jpeg,image/png" onChange={e => this.whiteboardRef.current.addImg(URL.createObjectURL(e.target.files[0]))}>
                                        <Button type={"link"} size="large" 
                                            className={"red-link"} style={{color: 'white'}}><CameraOutlined /></Button>
                                    </UploadWrapper>}
                                    <div style={{height: '25px'}} />
                                    <Popover placement="topLeft" trigger="click" content={
                                        <TwitterPicker triangle="hide" style={{width: '300px'}} color={this.state.whiteboardStrokeColor} onChangeComplete={color => this.setState({whiteboardStrokeColor: color.hex})} />
                                    }>
                                        <Button type={"link"} size="large" 
                                        className={"red-link"} style={{color: 'white'}}><BgColorsOutlined /></Button>
                                    </Popover>
                                    <Popover placement="topLeft" trigger="click" content={
                                        <Slider min={1} style={{width: '300px'}} max={100} value={this.state.whiteboardStrokeWeight} onChange={value => this.setState({whiteboardStrokeWeight: value})} />
                                    }>
                                        <Button type={"link"} size="large" 
                                        className={"red-link"} style={{color: 'white'}}><StockOutlined /></Button>
                                    </Popover>
                                </div>

                                <Button onClick={e => this.setState({showWhiteboard: false})} type={"link"} size="large" 
                                    className={"red-link"} style={{color: 'white'}}><CloseOutlined /></Button>
                                <br />
                            </div>
                            <div style={{width: WHITEBOARD_WIDTH, height: WHITEBOARD_HEIGHT, backgroundColor: 'white'}}>
                                {this.state.isStageUser &&
                                <div style={{width: WHITEBOARD_WIDTH, height: WHITEBOARD_HEIGHT, backgroundColor: 'white', position: 'absolute', left: 45, top: 0}}>
                                    <Video alwaysMuted muted srcObject={this.state[sessionStream(SESSION_TYPE_BOARD)]} 
                                    style={{width: '100%', height: '100%'}} autoPlay playsInline controls={false} />
                                </div>}
                                {!this.state.isStageUser && this.state[sessionEnabled(SESSION_TYPE_STAGE_BOARD_OVERLAY)] &&
                                <div style={{width: WHITEBOARD_WIDTH, height: WHITEBOARD_HEIGHT, backgroundColor: 'white', position: 'absolute', left: 45, top: 0}}>
                                    <Video alwaysMuted muted srcObject={this.state[sessionStream(SESSION_TYPE_STAGE_BOARD_OVERLAY)]} 
                                    style={{width: '100%', height: '100%'}} autoPlay playsInline controls={false} />
                                </div>}

                                <SketchField
                                ref={this.whiteboardRef}
                                tool={this.state.selectedWhiteboardTool}
                                backgroundColor={"#ffffff00"}
                                widthCorrection={0}
                                //width={window.innerWidth * 0.75}
                                //height={window.innerHeight * 0.9}
                                width={WHITEBOARD_WIDTH}
                                height={WHITEBOARD_HEIGHT}
                                lineColor={this.state.whiteboardStrokeColor === undefined ? "black" : this.state.whiteboardStrokeColor}
                                lineWidth={this.state.whiteboardStrokeWeight === undefined ? 3 : this.state.whiteboardStrokeWeight}/>
                            </div>
                        </div>
                    </Draggable>
                </div>
                }

                <Modal
                title="Viewers"
                visible={this.state.showViewers}
                footer={null}
                onCancel={() => this.setState({showViewers: false})}>
                    {this.state.showViewers && <UserList liveStreamId={this.getLiveStreamId()} />}
                </Modal>

                {/*<div style={{position: 'absolute', left: 0, top: 0, opacity: 0, pointerEvents: "none"}}>
                {this.state.isStageUser && this.state[sessionEnabled(SESSION_TYPE_BOARD)] &&
                <Video refListener={ref => this.boardOverlay = ref} width="200" height="200" muted srcObject={this.state[sessionStream(SESSION_TYPE_BOARD)]} 
                style={{width: '1', height: '1'}} autoPlay playsInline controls={false} />}
                {!this.state.isStageUser && this.state[sessionEnabled(SESSION_TYPE_STAGE_BOARD_OVERLAY)] &&
                <Video refListener={ref => this.boardOverlay = ref} width="200" height="200" muted srcObject={this.state[sessionStream(SESSION_TYPE_STAGE_BOARD_OVERLAY)]} 
                style={{width: '1', height: '1'}} autoPlay playsInline controls={false} />}
                </div>*/}
            </div>
        )
    }
}

export default withRouter(LiveBroadcasterWindow);