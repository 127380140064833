import React from 'react';

import NoImage from '../../images/NoImage.png';
import ProductImagePlaceholder from '../../images/ProductImagePlaceholder.png';
import { Button, Tag, Badge, Rate } from 'antd';

import {  
    InfoCircleOutlined, 
    UnorderedListOutlined,
    EditOutlined,
    QuestionCircleOutlined,
    LinkOutlined,
    MessageOutlined,
    SnippetsOutlined,
    AlertOutlined,
    LoginOutlined
} from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import Api from '../../session/Api';
import ProfilePicture from '../../components/ProfilePicture';
import Util from '../../util/Util';
import { ACCOUNT_ROLE_TUTOR, RATING_ITEM_TYPE_LIVE_SESSION } from '../../Constants';
import LiveStreamScheduleState from '../../components/LiveStreamScheduleState';
import UIUtil from '../../util/UIUtil';

export default function StudentLiveSessionItem(props) {
    const liveSession = props.liveSession;
    const [inProgress, setInProgress] = React.useState(liveSession.upcomingState.inProgress);
    const [loading, setLoading] = React.useState(false);
    const history = useHistory();

    const onGetFreeAccessBtn = () => {
        /*setLoading(true);
        Api.purchaseLiveSession(liveSession.id, response => {
            if (response.status === true) {
                liveSession.owned = true;
                history.push("/live-session/" + liveSession.id)
            } else {
                UIUtil.showError();
            }
            setLoading(false)
        })*/
        UIUtil.showError();
    }

    return (
        <div style={{width: '100%', borderRadius: '15px', padding: '15px', boxShadow: "0px 0px 25px 0px rgba(0, 0, 0, 0.2)"}}>
            <div style={{display: 'flex'}}>
                <Link to={liveSession.owned ? '/live-session/' + liveSession.id + '?tab=schedule' : "/live-session-sale/" + liveSession.id} style={{width: '20%', height: '115px'}}>
                    <img src={Api.getLiveSessionThumbnail(liveSession.id)} style={{width: '100%', height: '115px', objectFit: 'cover', borderRadius: '15px'}} onError={e => {
                        e.target.onerror = null;
                        e.target.src = ProductImagePlaceholder;
                    }} />
                </Link>
                <div style={{marginLeft: '25px', flex: 1, minWidth: 1}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <Link to={liveSession.owned ? '/live-session/' + liveSession.id + '?tab=schedule' : "/live-session-sale/" + liveSession.id}><h2 style={{height: '25px'}}>{liveSession.title}</h2></Link>

                        {//Util.isStringExists(liveSession.courseId) && <>
                        liveSession.hasConnectedCourse && <>
                            <Link to={"/course/" + liveSession.courseId} style={{marginLeft: '15px'}}>{liveSession.courseTitle}</Link>
                        </>}

                        <div style={{flex: '1'}} />
                        
                        <LiveStreamScheduleState state={liveSession.upcomingState} onStateUpdate={() => setInProgress(true)}/>
                    </div>
                    
                    
                    
                    <div style={{display: 'flex', alignItems: 'center', }}>
                        <Link to={Util.getProfilePath(liveSession.tutorId)} style={{display: 'flex', alignItems: 'center', }}>
                        <div style={{width: '26px', height: '26px'}}>
                            <ProfilePicture showStatus={liveSession.tutorRole == ACCOUNT_ROLE_TUTOR} userId={liveSession.tutorId} size={25}/>
                        </div>
                        <Button type="link" style={{marginLeft: '-5px'}}>
                                {liveSession.tutorName}
                        </Button>
                        </Link>
                    </div>
                    {/*<div style={{display: 'flex', alignItems: 'center'}}>
                        {course.subscribed && <h4 style={{height: '16px', color: '#990000'}}>Subscribed</h4>}
                    </div>*/}
                    <br />
                    {/*<p style={{paddingTop: '5px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', flex: '1'}}></p>*/}
                </div>
            </div>

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '10px', width: '100%'}}>
                <Rate disabled value={liveSession.liveSessionRating} style={{marginLeft: '2px'}} />
                <Button type="link" className="orange-link" style={{marginLeft: '2px', paddingTop: '5px'}}>
                    <Link to={"/reviews/" + RATING_ITEM_TYPE_LIVE_SESSION + "/" + liveSession.id + "?name=" + encodeURIComponent(liveSession.title)}>View Reviews</Link>
                </Button>
                <div style={{flex: 1}} />

                {/*<Button type="link">
                    <Link to={'/live-session/' + course.id + '?tab=content'}>
                        <FileOutlined /> Content
                    </Link>                    
                </Button>*/}


                {liveSession.owned &&
                <Button type="link">
                    <Link to={'/live-session/' + liveSession.id + '?tab=announcements'}>
                        <UnorderedListOutlined /> Announcements
                    </Link>
                </Button>}
                
                {//Util.isStringExists(liveSession.courseId) && !liveSession.owned && (liveSession.upcomingState.inProgress || liveSession.upcomingState.startDate > 0) &&
                liveSession.hasConnectedCourse && !liveSession.owned && (liveSession.upcomingState.inProgress || liveSession.upcomingState.startDate > 0) &&
                <Button type="text" style={{cursor: 'default'}}>
                    You can get free access to the recordings once its over
                </Button>}

                {//Util.isStringExists(liveSession.courseId) && !liveSession.owned && !liveSession.upcomingState.inProgress && liveSession.upcomingState.startDate == 0 &&
                liveSession.hasConnectedCourse && !liveSession.owned && !liveSession.upcomingState.inProgress && liveSession.upcomingState.startDate == 0 &&
                <Button type="primary" loading={loading} onClick={onGetFreeAccessBtn}>
                    <LoginOutlined /> Get free access
                </Button>}

                {//(inProgress || ((liveSession.upcomingState.startDate - (liveSession.loungeDuration * 60000)) < new Date().getTime())) &&
                inProgress &&
                <Button type="primary" className="red-button" style={{marginLeft: '15px'}}>
                    <Link to={Util.getLiveStreamPath(liveSession.upcomingState.liveStreamId)}>
                        <AlertOutlined /> {inProgress ? 'Join Live Stream' : 'Join Live Stream Lounge'}
                    </Link>
                </Button>}
            </div>
        </div>
    )
}