import React from 'react';
import { Button } from 'antd';
import Page from '../../base/Page';

class UserOrganizationsPage extends Page {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Button>WHAYT</Button>
        )
    }

}

export default UserOrganizationsPage;