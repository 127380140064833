import React from 'react';
import Page from '../../base/Page';

import { Tabs, Button, Menu, Input, Upload, InputNumber, Checkbox, Alert, Popconfirm, Popover, Radio, AutoComplete, Spin } from 'antd';

import './tutor-live-session-page.less'

import {  
    InfoCircleOutlined, 
    UnorderedListOutlined,
    EditOutlined,
    FormOutlined,
    StarOutlined,
    UserOutlined,
    DollarCircleOutlined,
    SaveOutlined,
    CheckOutlined,
    CloseOutlined,
    PlusOutlined,
    InboxOutlined,
    VideoCameraOutlined,
    QuestionCircleOutlined,
    EyeOutlined,
    EyeInvisibleOutlined,
    QuestionOutlined,
    AlertOutlined,
    FontSizeOutlined,
    DeleteOutlined,
    AuditOutlined,
    CalendarOutlined,
    RightOutlined,
    CopyOutlined,
    ClusterOutlined,
    ReadOutlined,
    FireFilled,
    FolderViewOutlined
} from '@ant-design/icons';
import PostsView from '../../views/posts/PostsView';
import { withRouter, Link } from 'react-router-dom';
import RateChart from '../ratings-page/RateChart';
import Api from '../../session/Api';
import Error404Page from '../Error404Page';
import Util from '../../util/Util';
import { Tag, Tooltip } from 'antd';

import { Select } from 'antd';
import { CATEGORIES } from '../../Categories';
import TagBox from '../../components/TagBox';
import UIUtil from '../../util/UIUtil';
import { motion } from 'framer-motion';
import { LIVE_SESSION_SECTION_TYPE_CUSTOM, LIVE_SESSION_SECTION_TYPE_LECTURE, LIVE_SESSION_SECTION_TYPE_HELP_SESSION, LIVE_SESSION_SECTION_TYPE_LIVE_SESSION, GALLERY_ITEM_TYPE_IMAGE, GALLERY_ITEM_TYPE_VIDEO, RATING_ITEM_TYPE_LIVE_SESSION, PRODUCT_TYPE_LIVE_SESSION, ACCOUNT_ROLE_ORGANIZATION, ACCOUNT_ROLE_STUDENT, ACCOUNT_ROLE_TUTOR } from '../../Constants';
import Modal from 'antd/lib/modal/Modal';

import NoImage from '../../images/NoImage.png';
import ProductImagePlaceholder from '../../images/ProductImagePlaceholder.png';
//import LiveSessionSectionView from '../../views/liveSession-section/LiveSessionSectionView';
import { getFieldId } from 'antd/lib/form/util';
import RatingsPage from '../ratings-page/RatingsPage';
import withMediaQuery from '../../components/WithMediaQuery';
import LiveSessionScheduleView from '../../views/live-session-schedule/LiveSessionScheduleView';
import LiveStreamScheduleState from '../../components/LiveStreamScheduleState';
import UserList from '../../components/UserList';
import SessionManager from '../../session/SessionManager';
import CopyToClipboard from 'react-copy-to-clipboard';
import UserListDialog from '../../components/UserListDialog';

import HashtagCover from '../../images/HashtagCover.jpg'

const { Option } = Select;
const { TabPane } = Tabs;
const { SubMenu } = Menu;

const TabTitle = ({icon, title}) => {
    return (
        <span style={{height: '35px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            {icon}
            {title}
        </span>
    )
}

const DetailsTabTitle = ({icon, title}) => {
    return (
        <span>
            {icon}
            {title}
        </span>
    )
}

const ContentSectionTabTitle = ({icon, title, hidden, editable, deletable, onToggleHiddenBtn, hiddenToggleLoading, onRenameBtn, onDeleteBtn, deleteLoading}) => {
    return (
        <span style={{height: '35px', width: '250px', display: 'flex', alignItems: 'center'}}>
            {icon}
            {title}

            <div style={{flex: 1}} />
            <Tooltip title={hidden ? "Show" : "Hide"} placement="top">
                <Button type="link" style={{width: 25}} loading={hiddenToggleLoading} onClick={e => {
                    e.stopPropagation();
                    onToggleHiddenBtn();
                }}>{hidden ? <EyeInvisibleOutlined /> : <EyeOutlined />}</Button>
            </Tooltip>

            {editable ? 
                <Tooltip title="Rename" placement="topRight">
                    <Button type="link" style={{width: 25}} onClick={e => {
                        e.stopPropagation();
                        onRenameBtn();
                    }}><FontSizeOutlined /></Button>
                </Tooltip>
            : null}

            {deletable ? 
                <Popconfirm title="Are you sure you want to delete this section?" onConfirm={onDeleteBtn}>
                    <Button danger type="link" style={{width: 25}} onClick={e => e.stopPropagation()} loading={deleteLoading}><DeleteOutlined /></Button>
                </Popconfirm>
            : null}
        </span>
    )
}

const FormPadding = () => {
    return ( <> <br /> <br /> </> )
}

class TutorLiveSessionPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            featuredHashtags: undefined,

            detailsTab: "store-preview",
            liveSession: {},
            liveSessionDoesNotExist: false,

            thumbnailValue: undefined,
            galleryItemsValue: [],
            titleValue: "",
            descriptionValue: "",
            categoryValue: false,
            hashtagValue: "",
            searchTagsValue: [],
            unlistedValue: false,
            privateValue: false,
            organizationPrivateAccessValue: false,
            priceValue: 0,
            loungeDurationValue: 0,
            recordValue: false,

            saving: false,
            publishToggleLoading: false,
            showSuccess: false,

            addPrivateAccess: false,
            addPrivateAccessEmailAddress: "",
            addingPrivateAccess: false,

            grantLearnerAccess: false,
            grantingLearnersAccess: false,

            grantTutorAccess: false,
            grantingTutorsAccess: false,

            hideUpcomingStateView: false,

            showEmptyLiveSessionMessage: false
        }
    }

    getLiveSessionId() {
        return this.props.match.params.liveSessionId;
    }

    onPageStart() {
        if (this.emptyTimeout) {
            clearTimeout(this.emptyTimeout);
        }
        this.setState({showEmptyLiveSessionMessage: false});
        Api.getTutorLiveSession(this.getLiveSessionId(), response => {
            if (response.status === true) {
                response.payload.hasNoSchedule = response.payload.schedules.length == 0;
                this.setLiveSession(response.payload)
                if (response.payload.id != this.shownMessageForId) {
                    if (response.payload.hasNoSchedule) {
                        this.shownMessageForId = response.payload.id;
                        this.setState({showEmptyLiveSessionMessage: true})
                        if (this.emptyTimeout) {
                            clearTimeout(this.emptyTimeout);
                        }
                        this.emptyTimeout = setTimeout(() => this.setState({showEmptyLiveSessionMessage: false}), 10000)
                    }
                }
            } else {
                this.setState({
                    liveSessionDoesNotExist: true
                })
            }
        })

        Api.getFeaturedHashtags(response => this.setState({featuredHashtags: response.status === true ? response.payload : []}))
    }

    onTogglePublishBtn() {
        this.setState({ publishToggleLoading: true })
        Api.toggleLiveSessionPublish(this.state.liveSession.id, response => {
            if (response.status === true) {
                this.state.liveSession.published = response.payload;
                this.state.liveSession.publishedAtLeastOnce = true;
                this.setState({
                    publishToggleLoading: false,
                    showSuccess: true
                })
                setTimeout(() => this.setState({showSuccess: false}), 1500);
            } else {
                this.setState({ publishToggleLoading: false })
                UIUtil.showError(response.message);
            }
        })
    }

    onSaveChangesBtn() {
        if (this.state.galleryItemsValue !== undefined && this.state.galleryItemsValue.length > 10) {
            UIUtil.showError("Maximum gallery size exceeded (> 10 items)")
            return;
        }

        this.setState({ saving: true })
        Api.saveLiveSessionDetail({
            id: this.state.liveSession.id,
            title: this.state.titleValue,
            description: this.state.descriptionValue,
            galleryItems: this.state.galleryItemsValue,
            category: this.state.categoryValue,
            hashtag: this.state.hashtagValue,
            searchTags: this.state.searchTagsValue,
            unlisted: this.state.unlistedValue,
            privateAccess: this.state.privateValue,
            organizationPrivateAccess: this.state.organizationPrivateAccessValue,
            price: this.state.priceValue,
            loungeDuration: this.state.loungeDurationValue,
            record: this.state.recordValue
        }, this.state.thumbnailValue, this.state.galleryItemsValue.map(galleryItem => ({
            id: galleryItem.id,
            type: galleryItem.type,
            fileId: galleryItem.fileId,
            file: galleryItem.file,
        })), response => {
            if (response.status === true) {
                for (const galleryItem of this.state.galleryItemsValue) {
                    if (galleryItem.fileUrl !== undefined) {
                        URL.revokeObjectURL(galleryItem.fileUrl);
                    }
                }

                this.setLiveSession(response.payload, { saving: false, showSuccess: true })
                setTimeout(() => this.setState({showSuccess: false}), 1500);
            } else {
                this.setState({ saving: false })
                UIUtil.showError(Util.isStringExists(response.message) ? response.message : undefined);
            }
        })
    }

    setLiveSession(liveSession, extra) {
        this.setState(prevState => ({
            liveSession: liveSession,
            thumbnailValue: undefined,
            titleValue: liveSession.title,
            galleryItemsValue: Object.assign([], liveSession.galleryItems),
            descriptionValue: Util.existString(liveSession.description),
            hashtagValue: liveSession.hashtag,
            categoryValue: liveSession.category,
            searchTagsValue: liveSession.searchTags,
            unlistedValue: liveSession.unlisted,
            privateValue: liveSession.privateAccess,
            organizationPrivateAccessValue: liveSession.organizationPrivateAccess,
            priceValue: Util.existNumber(liveSession.price),
            loungeDurationValue: liveSession.loungeDuration,
            recordValue: liveSession.record,

            // detailsTab: liveSession.tutorId == SessionManager.getAccount().id ? 'store-preview' : 'enrolled-learners',
            detailsTab: liveSession.tutorId == SessionManager.getAccount().id ? (prevState.detailsTab == 'store-listing' ? 'store-listing' : 'store-preview') : 'enrolled-learners',

            ...(extra !== undefined ? extra : {}),
        }))
    }

    changesMade() {
        return  this.state.thumbnailValue !== undefined ||
                this.state.liveSession.title !== this.state.titleValue || 
                !Util.arrayEqual(this.state.liveSession.galleryItems, this.state.galleryItemsValue) ||
                Util.existString(this.state.liveSession.description) !== this.state.descriptionValue ||
                this.state.liveSession.category !== this.state.categoryValue ||
                this.state.liveSession.hashtag !== this.state.hashtagValue ||
                !Util.arrayEqual(this.state.liveSession.searchTags, this.state.searchTagsValue) ||
                this.state.liveSession.unlisted !== this.state.unlistedValue ||
                this.state.liveSession.privateAccess !== this.state.privateValue ||
                this.state.liveSession.organizationPrivateAccess !== this.state.organizationPrivateAccessValue ||
                Util.existNumber(this.state.liveSession.price) != this.state.priceValue ||
                this.state.liveSession.loungeDuration != this.state.loungeDurationValue ||
                this.state.liveSession.record !== this.state.recordValue;
    }

    onChangeTab(tab) {
        this.props.history.push(this.props.location.pathname + "?tab=" + tab);
    }

    addPrivateAccess(emailAddress, onSuccess) {
        this.setState({addingPrivateAccess: true})
        Api.addLiveSessionPrivateAccess(this.getLiveSessionId(), emailAddress, response => {
            if (response.status === true) {
                onSuccess();
                if (this.onUserAdded !== undefined) {
                    this.onUserAdded(response.payload)
                }
            } else {
                UIUtil.showError(response.message);
            }
            this.setState({addingPrivateAccess: false})
        })
    }

    grantLearnerAccess(users, onSuccess) {
        this.setState({grantingLearnersAccess: true})
        Api.createUsersAccess(this.getLiveSessionId(), PRODUCT_TYPE_LIVE_SESSION, users, response => {
            if (response.status === true) {
                onSuccess();
                if (this.onMultipleUserAdded !== undefined) {
                    this.onMultipleUserAdded(response.payload)
                }
            } else {
                UIUtil.showError();
            }
            this.setState({grantingLearnersAccess: false})
        })
    }

    grantTutorAccess(users, onSuccess) {
        this.setState({grantingTutorsAccess: true})
        Api.createTutorsEditAccess(this.getLiveSessionId(), PRODUCT_TYPE_LIVE_SESSION, users, response => {
            if (response.status === true) {
                onSuccess();
                if (this.onMultipleUserAddedTutor !== undefined) {
                    this.onMultipleUserAddedTutor(response.payload)
                }
            } else {
                UIUtil.showError();
            }
            this.setState({grantingTutorsAccess: false})
        })
    }

    renderDetailsTabContent() {
        switch (this.state.detailsTab) {
            case "store-preview":
                return this.renderPreviewPage();

            case "store-listing":
                return this.renderStoreListing();

            case "ratings":
                return this.renderRatings();

            case "enrolled-learners":
                return this.renderEnrolledLearners();

            case "view-access":
                return this.renderViewAccess();
            
            case "edit-access":
                return this.renderEditAccess();
        }
    }

    renderViewAccess() {
        return (
            <div key="view-access" style={{width: '75vw', paddingTop: '25px', paddingBottom: '25px'}}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <h2 style={{height: '25px'}}>Learners</h2>
                    <div style={{flex: '1'}} />
                    <Button type="link" onClick={e => this.setState({grantLearnerAccess: true})}>
                        <PlusOutlined /> Grant Viewing Access
                    </Button>
                </div>
                <br />
                <UserList showEnrolledUserAccess={{liveSessionId: this.getLiveSessionId(), role: ACCOUNT_ROLE_STUDENT}} emptyMessage="No learners given access" 
                onMultipleUserAddedMethodListener={onMultipleUserAdded => this.onMultipleUserAdded = onMultipleUserAdded}/>
            </div>
        )
    }

    renderEditAccess() {
        return (
            <div key="edit-access" style={{width: '75vw', paddingTop: '25px', paddingBottom: '25px'}}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <h2 style={{height: '25px'}}>Tutors</h2>
                    <div style={{flex: '1'}} />
                    <Button type="link" onClick={e => this.setState({grantTutorAccess: true})}>
                        <PlusOutlined /> Grant Editorial Access
                    </Button>
                </div>
                <br />
                <UserList showEnrolledUserAccess={{liveSessionId: this.getLiveSessionId(), role: ACCOUNT_ROLE_TUTOR}} emptyMessage="No tutors given access" 
                onMultipleUserAddedMethodListener={onMultipleUserAdded => this.onMultipleUserAddedTutor = onMultipleUserAdded}/>
            </div>
        )
    }

    renderEnrolledLearners() {
        return (
            <div style={{width: '75vw', paddingTop: '25px', paddingBottom: '25px'}}>
                <UserList productAccessUsers={{id: this.getLiveSessionId(), type: PRODUCT_TYPE_LIVE_SESSION}} emptyMessage="No learners enrolled" />
            </div>
        )
    }

    renderPreviewPage() {
        return (
            <div style={{width: '75vw', paddingTop: '25px', paddingBottom: '25px',}}>
                {this.renderPreview(true)}
            </div>
        )
    }

    renderPreview(hideSaveChanges) {
        if (!Util.isStringExists(this.state.liveSession.id)) {
            return null;
        }

        return (
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: 450, width: '100%',}}>
                <img src={this.state.thumbnailValue !== undefined ? URL.createObjectURL(this.state.thumbnailValue) : Api.getLiveSessionThumbnail(this.state.liveSession.id)} onError={e => {
                    e.target.onerror = null;
                    e.target.src = ProductImagePlaceholder;
                }} style={{width: '360px', height: '250', borderRadius: 25, objectFit: 'cover',}} />


                {this.state.liveSession.published ? (
                    <Link style={{textDecoration: 'none'}} to={"/live-session-sale/" + this.getLiveSessionId()}>
                        <h1 style={{margin: 0, marginTop: 15,}}>{this.state.liveSession.title}</h1>
                    </Link>
                ) : (
                    <h1 style={{margin: 0, marginTop: 15,}}>{this.state.liveSession.title}</h1>
                )}
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{borderRadius: '50%', marginRight: '15px', background: this.state.liveSession.published ? '#009900' : '#faad14', width: '12px', height: '12px'}} />
                    <h3 style={{margin: 0}}>{this.state.liveSession.published ? 'Published' : 'Unpublished'}</h3>
                </div>


                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: 10}}>
                    {
                    this.state.liveSession.hasNoSchedule && 
                    <Alert
                        message="No Schedule"
                        description={<>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                Complete your live session by scheduling live streams!
                                <br />
                                <Button onClick={() => this.onChangeTab("schedule")} style={{alignSelf: 'flex-end'}} type="link">Set Schedule</Button>
                            </div>
                        </>}
                        type="error"
                        showIcon
                    />}

                
                </div>

                <div style={{marginTop: 40}}>
                    <div style={{flex: 1}} />
                    <Popconfirm disabled={this.changesMade()} title="Are you sure?" onConfirm={this.onTogglePublishBtn.bind(this)}>
                        {!this.state.liveSession.published ? (
                            <Button type="primary" disabled={this.changesMade()} loading={this.state.publishToggleLoading}><CheckOutlined /> Publish</Button>
                        ) : (
                            <Button type="primary" className={this.changesMade() ? "" : "red-button"} disabled={this.changesMade()} loading={this.state.publishToggleLoading}><CloseOutlined /> Unpublish</Button>  
                        )}
                    </Popconfirm>
                    
                    {!hideSaveChanges &&
                        <Button style={{marginLeft: '15px'}} disabled={!this.changesMade()} loading={this.state.saving} onClick={this.onSaveChangesBtn.bind(this)}><SaveOutlined /> Save Changes</Button>}

                    <Button style={{marginLeft: '15px'}} onClick={() => this.onChangeTab("schedule")}><CalendarOutlined /> Set Schedule</Button>
                </div>
            </div>
        )
    }

    renderStoreListing() {
        return (
            <div style={{width: '75vw', paddingTop: '25px', paddingBottom: '25px'}}>

                {this.state.showSuccess ? 
                    <motion.div initial={{ height: 0, opacity: 0 }} animate={{ height: 55, opacity: 1 }}>
                        <Alert message="Success!" type="success" showIcon closable style={{marginBottom: '15px'}} />
                    </motion.div> 
                : null}

                <div style={{display: 'flex', alignItems: 'center'}}>
                    {/*<div style={{borderRadius: '50%', marginRight: '15px', background: this.state.liveSession.published ? '#009900' : '#faad14', width: '12px', height: '12px'}} />
                    <h3 style={{height: '18px'}}>{this.state.liveSession.published ? 'Published' : 'Unpublished'}</h3>*/}
                    <div style={{flex: 1}} />
                    <Popconfirm disabled={this.changesMade()} title="Are you sure?" onConfirm={this.onTogglePublishBtn.bind(this)}>
                        {!this.state.liveSession.published ? (
                            <Button type="primary" disabled={this.changesMade()} loading={this.state.publishToggleLoading}><CheckOutlined /> Publish</Button>
                        ) : (
                            <Button type="primary" className={this.changesMade() ? "" : "red-button"} disabled={this.changesMade()} loading={this.state.publishToggleLoading}><CloseOutlined /> Unpublish</Button>  
                        )}
                    </Popconfirm>
                    
                    <Button style={{marginLeft: '15px'}} disabled={!this.changesMade()} loading={this.state.saving} onClick={this.onSaveChangesBtn.bind(this)}><SaveOutlined /> Save Changes</Button>
                </div>


                <FormPadding />

                <h2>Details</h2>

                {//Util.isStringExists(this.state.liveSession.courseId) && <>
                this.state.liveSession.hasConnectedCourse && <>
                    <h3>Connected Course</h3>
                    <Link to={"/my-course/" + this.state.liveSession.courseId}>{this.state.liveSession.courseTitle}</Link>
                    <FormPadding />
                </>}
                
                <h3>Title</h3>
                <Input size="large" value={this.state.titleValue} onChange={e => this.setState({titleValue: e.target.value})} />

                <FormPadding />
                <h3>Description</h3>
                <Input.TextArea maxLength={1000} size="large" rows={4} value={this.state.descriptionValue} onChange={e => this.setState({descriptionValue: e.target.value})} />

                <FormPadding />
                <h3>Category</h3>
                <Select value={this.state.categoryValue} onChange={value => this.setState({categoryValue: value})} style={{ width: '100%' }} size="large">
                    {CATEGORIES.map(category => 
                        <Option value={category.id}>{category.icon} &nbsp;&nbsp;{category.title}</Option>)}
                </Select>

                <FormPadding />
                <h3>Hashtag <p style={{fontWeight: 'normal', fontSize: 14, opacity: 0.65}}>(optional) {this.state.featuredHashtags === undefined && <span style={{fontSize: 12, opacity: 0.65}}>Loading trending hashtags</span>}</p></h3>
                <AutoComplete size="large" addonBefore="#" 
                style={{width: '100%'}} 
                options={(this.state.featuredHashtags !== undefined && this.state.featuredHashtags.length > 0) ? [{
                    label: (
                        <p style={{pointerEvents: 'none', fontStyle: 'bolder', fontSize: 18, marginBottom: 2, marginTop: 2}}><FireFilled /> Trending</p>
                    ),
                    options: this.state.featuredHashtags.map(hashtag => ({
                        value: hashtag,
                        label: (
                            <div style={{height: 60, display: 'flex', alignItems: 'center', gap: 10}}>
                                <img src={Api.getHashtagThumbnail(hashtag)} style={{width: 45, height: 45, borderRadius: 5, objectFit: 'cover', objectPosition: 'center'}}
                                onError={e => {
                                    e.target.onerror = null;
                                    e.target.src = HashtagCover;
                                }}/>

                                <p style={{fontSize: 14, color: 'black', margin: 0, opacity: 0.80, textOverflow: 'ellipsis', overflow: 'hidden', paddingRight: 5}}>#{hashtag}</p>
                            </div>
                        )
                    }))
                }] : []}
                value={this.state.hashtagValue} onChange={e => this.setState({hashtagValue: e.replace("#", "")})}
                >
                    <Input addonBefore="#" size="large" />
                </AutoComplete>


                <FormPadding />
                <h3>Search Tags</h3>
                <TagBox value={this.state.searchTagsValue} onChange={e => this.setState({searchTagsValue: e})} />
                <FormPadding />


                {/* <Alert
                    message="Pricing"
                    description={<>
                        Prices can only be set for non-public Live Sessions. Free Live Sessions are monetized based on learner's engagement with the live streams. <a>Learn how you can earn more money with free Live Sessions.</a>
                    </>}
                    type="info"
                    showIcon
                />
                <br />
                <Checkbox checked={this.state.unlistedValue} onChange={e => this.setState({unlistedValue: e.target.checked})}>Unlisted</Checkbox>
                <FormPadding /> */}


                <h2>Store Presence</h2>
            
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <h3 style={{height: '19px'}}>Store Link</h3>
                    <CopyToClipboard text={window.location.origin.toString() + "/live-session-sale/" + this.getLiveSessionId()} onCopy={() => UIUtil.showSuccess("Copied to clipboard!")}>
                        <Tooltip title="Copy"><Button type="link"><CopyOutlined /></Button></Tooltip>
                    </CopyToClipboard>
                </div>
                <p>{window.location.origin.toString() + "/live-session-sale/" + this.getLiveSessionId()}</p>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <h3 style={{height: 19}}>Visibility Mode</h3>
                    <Tooltip color="purple" title={<p>
                        <strong>Visibility mode affects who can view your Live Session and get access to it.</strong>
                        <br />
                        <br />
                        <strong>Public</strong> includes everyone.
                        <br />
                        <br />
                        <strong>Unlisted</strong> means only the people with an link can view it. It will not appear in any list or search.
                        <br />
                        <br />
                        <strong>Private</strong> means no one can view the Live Session except the accounts you specified.

                        {SessionManager.isLoggedInAsOrganization() && <>
                            <br />
                            <br />
                            <strong>Organization</strong> means no one can view the Live Session except the users enrolled under your organization.
                        </>}
                    </p>}><Button type="link"><QuestionOutlined /></Button></Tooltip>
                </div>
                {/* <Alert
                    message="Pricing"
                    description={<>
                        Prices can only be set for non-public Live Sessions. Free Live Sessions are monetized based on learner's engagement with the live streams. <a>Learn how you can earn more money with free Live Sessions.</a>
                    </>}
                    type="info"
                    showIcon
                />
                <br /> */}
                <Radio.Group value={(() => {
                    if (this.state.organizationPrivateAccessValue) {
                        return "3";
                    } else if (this.state.privateValue) {
                        return "2";
                    } else if (this.state.unlistedValue) {
                        return "1";
                    } else {
                        return "0";
                    }
                })()} onChange={e => {
                    switch (e.target.value) {
                        case "0":
                            this.setState({
                                unlistedValue: false,
                                privateValue: false,
                                organizationPrivateAccessValue: false,
                            })
                            break;
                        case "1":
                            this.setState({
                                unlistedValue: true,
                                privateValue: false,
                                organizationPrivateAccessValue: false,
                            })
                            break;
                        case "2":
                            this.setState({
                                unlistedValue: true,
                                privateValue: true,
                                organizationPrivateAccessValue: false,
                            })
                            break;
                        case "3":
                            this.setState({
                                unlistedValue: true,
                                privateValue: false,
                                organizationPrivateAccessValue: true,
                            })
                            break;
                    }
                }} buttonStyle="solid">
                    <Radio.Button value="0">Public</Radio.Button>
                    <Radio.Button value="1">Unlisted</Radio.Button>
                    <Radio.Button value="2">Private</Radio.Button>
                    {SessionManager.isLoggedInAsOrganization() && <Radio.Button value="3">Organization</Radio.Button>}
                </Radio.Group>
                <FormPadding />
                {this.state.privateValue && <>
                    <div style={{width: '100%', display: 'flex', alignItems: 'center'}}>
                        <h3 style={{height: '19px'}}>Private Access</h3>
                        <div style={{flex: '1'}} />
                        <Button onClick={() => this.setState({addPrivateAccess: true})}><PlusOutlined /> Add Access</Button>
                    </div>
                    <br />
                    <UserList liveSessionPrivateAccessId={this.getLiveSessionId()} onUserAddedMethodListener={onUserAdded => this.onUserAdded = onUserAdded}  />
                </>}


                <FormPadding />
                <h2>Live Stream</h2>
                <Alert
                    message="Important"
                    description="Make sure about the Live Stream settings. These values can not be changed after the first publish."
                    type="info"
                    showIcon
                />
                <br />
                <h3>Lounge Duration (minutes)</h3>
                <InputNumber
                    //defaultValue={1000}
                    disabled={this.state.liveSession.publishedAtLeastOnce || true}
                    size="large"
                    style={{width: '100%'}}
                    max={30}
                    //formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    //parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    value={this.state.loungeDurationValue} onChange={value => this.setState({loungeDurationValue: value})} />
                <FormPadding />

                {//Util.isStringExists(this.state.liveSession.courseId) ? (<>
                this.state.liveSession.hasConnectedCourse ? (<>
                    <h3>Recording</h3>
                    <p>Course Live Sessions are always recorded</p>
                    <br />
                </>) : (<>
                    <h3>Recording</h3>
                    <Checkbox disabled={this.state.liveSession.publishedAtLeastOnce} checked={this.state.recordValue} onChange={e => this.setState({recordValue: e.target.checked})}>Record</Checkbox>
                    <FormPadding />
                </>)}

                

                <FormPadding />
                <h2>Promotional Media</h2>

                <h3>Thumbnail</h3>
                <div style={{display: 'flex', maxHeight: '250px'}}>
                    <img src={this.state.thumbnailValue !== undefined ? URL.createObjectURL(this.state.thumbnailValue) : Api.getLiveSessionThumbnail(this.state.liveSession.id)} onError={e => {
                        e.target.onerror = null;
                        e.target.src = NoImage;
                    }} style={{width: '360px', height: '250', objectFit: 'cover'}} />
                    <div style={{height: '250px', maxHeight: '250px', flex: 1, marginLeft: '25px'}}>
                        <Upload.Dragger accept="image/gif,image/jpeg,image/png" showUploadList={false} beforeUpload={file => {
                            this.setState({thumbnailValue: file})
                            return false;
                        }}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        </Upload.Dragger>
                    </div>
                </div>
                {/*<div style={{height: '250px', maxHeight: '250px'}}>
                    <Upload.Dragger>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        {/*<p className="ant-upload-hint">
                            Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                            band files
                        </p>}
                    </Upload.Dragger>
                </div>*/}
                
                <FormPadding />

                <h3>Gallery</h3>
                <Upload 
                listType="picture-card" 
                fileList={this.state.galleryItemsValue.map(galleryItem => ({
                    uid: Util.isStringExists(galleryItem.id) ? galleryItem.id : galleryItem.fileId,
                    status: 'done',
                    name: galleryItem.type === GALLERY_ITEM_TYPE_VIDEO ? "Video" : "Image",
                    url: galleryItem.type === GALLERY_ITEM_TYPE_IMAGE ? (Util.isStringExists(galleryItem.id) ? Api.getGalleryItem(galleryItem.id) : galleryItem.fileUrl) : undefined
                }))} 
                showUploadList={{
                    showPreviewIcon: false
                }}
                accept="image/gif,image/jpeg,image/png,video/mp4"
                multiple={true}
                onRemove={file => {
                    for (let i = 0; i < this.state.galleryItemsValue.length; i++) {
                        const galleryItem = this.state.galleryItemsValue[i];
                        if ((Util.isStringExists(galleryItem.id) ? galleryItem.id : galleryItem.fileId) === file.uid) {
                            if (galleryItem.fileUrl !== undefined) {
                                URL.revokeObjectURL(galleryItem.fileUrl);
                            }
                            this.state.galleryItemsValue.splice(i, 1);
                            break;
                        }
                    }
                    this.setState({})
                }}
                beforeUpload={file => {
                    this.state.galleryItemsValue.push({
                        type: Util.getFileExt(file).toLowerCase() === "mp4" ? GALLERY_ITEM_TYPE_VIDEO : GALLERY_ITEM_TYPE_IMAGE,
                        fileId: Util.newTempId(),
                        file: file,
                        fileUrl: URL.createObjectURL(file)
                    })
                    this.setState({})
                    return false;
                }}>
                    <div>
                        <PlusOutlined />
                        <div className="ant-upload-text">Add</div>
                    </div>
                </Upload>

                {/*<FormPadding />
                <h2>Pricing</h2>

                <h3>Access Price</h3>
                <InputNumber
                    //defaultValue={1000}
                    size="large"
                    style={{width: '100%'}}
                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    value={this.state.priceValue} onChange={value => this.setState({priceValue: value})} />
                <FormPadding />*/}

                    {//this.state.unlistedValue 
                    true && <>
                    <FormPadding />
                    <h2 style={{margin: 0}}>Pricing</h2>
                    <h3 style={{margin: 0}}>Access Price</h3>

                    <br />
                    <Alert
                        message="Revenue Distribution"
                        description={<>
                            Learnerix takes a {SessionManager.getAccount().role == ACCOUNT_ROLE_ORGANIZATION ? '20%' : '40%'} cut of each paid Live Session purchase. Only free Live Sessions will be monetized based on learner's engagement with the live streams. <a>Learn how you can earn more money with free Live Sessions.</a>
                        </>}
                        type="info"
                        showIcon
                    />
                    <br />
                    
                    <InputNumber
                        //defaultValue={1000}
                        size="large"
                        style={{width: '100%'}}
                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                        value={this.state.priceValue} onChange={value => this.setState({priceValue: value})} />
                    <FormPadding />
                </>}


                <FormPadding />
                <FormPadding />

                <h2>You are all set!</h2>
                <p style={{marginTop: -15}}>Preview:</p>

                {this.renderPreview()}

                <FormPadding />
                <FormPadding />
            </div>
        )
    }

    renderRatings() {
        return (
            <div style={{width: '75vw', paddingTop: '25px', paddingBottom: '25px'}}>
                <RatingsPage itemId={this.state.liveSession.id} itemType={RATING_ITEM_TYPE_LIVE_SESSION} />
            </div>
        )
    }

    render() {
        if (this.state.liveSessionDoesNotExist) {
            return (
                <Error404Page {...this.props} customMessage="Live Session does not exist" />
            )
        }

        if (!Util.isStringExists(this.state.liveSession.id)) {
            return (
                <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingTop: 45, paddingBottom: 45}}>
                    <Spin size="large" tip="Loading..." />
                </div>
            )
        }

        return (
            <div style={{height: '100%'}}>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: '45px', paddingRight: '45px', marginTop: '30px', marginBottom: '12px'}}>
                    <h1 style={{height: '34px'}}>{this.state.liveSession.title}</h1>
                        
                    <div style={{borderRadius: '50%', marginLeft: '25px', marginRight: '15px', background: this.state.liveSession.published ? '#009900' : '#faad14', width: '12px', height: '12px'}} />
                    <h3 style={{height: '18px', marginRight: !this.state.liveSession.published ? 15 : 0}}>{this.state.liveSession.published ? 'Published' : 'Unpublished'}</h3>
                    {!this.state.liveSession.published &&
                    <Popconfirm disabled={this.changesMade()} title="Are you sure?" onConfirm={this.onTogglePublishBtn.bind(this)}>
                        <Button className="orange-button" type="primary" disabled={this.changesMade()} loading={this.state.publishToggleLoading}><CheckOutlined /> Publish</Button>
                    </Popconfirm>}
                    {!this.state.liveSession.published && this.state.liveSession.hasAtLeastOneStudent &&
                    <Tooltip title="This Live Session can still be accessed by the learners that own it"><Button type="link" style={{cursor: 'default'}}><AuditOutlined /></Button></Tooltip>}

                    <div style={{flex: 1}} />
                    {Util.isStringExists(this.state.liveSession.id) && !this.state.hideUpcomingStateView && <>
                        <LiveStreamScheduleState state={this.state.liveSession.upcomingState} onStateUpdate={() => this.setState({upcomingStateInProgress: true})}/>
                        {//(this.state.upcomingStateInProgress || ((this.state.liveSession.upcomingState.startDate - (this.state.liveSession.loungeDuration * 60000)) < new Date().getTime())) &&
                        (this.state.upcomingStateInProgress || this.state.liveSession.upcomingState.inProgress) &&
                        <Link to={Util.getLiveStreamPath(this.state.liveSession.upcomingState.liveStreamId)}>
                            <Button type="primary" className="red-button" size="large" style={{marginLeft: '15px'}}>
                                {/* {this.state.upcomingStateInProgress ? 'Join Live Stream' : 'Join Live Stream Lounge'} <RightOutlined /> */}
                                Join Live Stream <RightOutlined />
                            </Button>
                        </Link>}
                    </>}
                </div>
                <Tabs activeKey={this.state.pathParams.tab} size="large" style={{width: '100%',}} type="card" onChange={this.onChangeTab.bind(this)} className="live-session-page-tabs">
                    <TabPane tab={<TabTitle icon={<InfoCircleOutlined />} title={'Details'} />} key="details">
                        <div style={{display: 'flex'}}>
                            <Menu
                                onClick={this.handleClick}
                                style={{ width: 256, }}
                                defaultSelectedKeys={[this.state.liveSession.tutorId == SessionManager.getAccount().id ? 'store-preview' : 'enrolled-learners']}
                                // onSelect={({key}) => this.setState({detailsTab: key})}
                                onSelect={({key}) => this.setLiveSession(this.state.liveSession, {detailsTab: key})}
                                selectedKeys={[this.state.detailsTab]}
                                mode="inline">
                                {this.state.liveSession.tutorId == SessionManager.getAccount().id &&
                                <Menu.Item key="store-preview">
                                    <DetailsTabTitle icon={<FolderViewOutlined />} title="Preview" />
                                </Menu.Item>}
                                {this.state.liveSession.tutorId == SessionManager.getAccount().id &&
                                <Menu.Item key="store-listing">
                                    <DetailsTabTitle icon={<FormOutlined />} title="Store Listing" />
                                </Menu.Item>}
                                <Menu.Item key="ratings">
                                    <DetailsTabTitle icon={<StarOutlined />} title="Ratings" />
                                </Menu.Item>
                                <Menu.Item key="enrolled-learners">
                                    <DetailsTabTitle icon={<UserOutlined />} title="Enrolled Learners" />
                                </Menu.Item>
                                {SessionManager.isLoggedInAsOrganization() &&
                                <Menu.SubMenu icon={<ClusterOutlined />} title="Organizational Access">
                                    <Menu.Item key="view-access">
                                        <DetailsTabTitle icon={<ReadOutlined />} title="Viewing Access" />
                                    </Menu.Item>
                                    <Menu.Item key="edit-access">
                                        <DetailsTabTitle icon={<EditOutlined />} title="Editorial Access" />
                                    </Menu.Item>
                                </Menu.SubMenu>}
                                {/*
                                <Menu.Item key="credits-earned">
                                    <DetailsTabTitle icon={<DollarCircleOutlined />} title="Credits Earned" />
                                </Menu.Item>*/}
                            </Menu>
                            <div style={{flex: 1, display: 'flex', justifyContent: 'center'}}>
                                {this.renderDetailsTabContent()}
                            </div>
                        </div>
                    </TabPane>
                    {/* <TabPane tab={<TabTitle icon={<CalendarOutlined />} title={'Schedule'} />} key="schedule"> */}
                    <TabPane tab={<Popover visible={this.state.pathParams.tab != "schedule" && this.state.showEmptyLiveSessionMessage} placement="top" content={<div style={{display: this.state.pathParams.tab != "schedule" && this.state.showEmptyLiveSessionMessage ? 'flex' : 'none', cursor: 'pointer', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        <InboxOutlined style={{fontSize: 60, marginBottom: 5, color: '#f26622'}} />
                        <h2 style={{margin: 0}}>No schedule!</h2>
                        <p>Make sure to schedule your live streams!</p>
                    </div>}>
                        <TabTitle icon={<CalendarOutlined />} title={'Schedule'} />
                    </Popover>} key="schedule">
                        <LiveSessionScheduleView liveSession={this.state.liveSession} onScheduleAdded={() => this.setState({hideUpcomingStateView: true})} />
                    </TabPane>
                    <TabPane tab={<TabTitle icon={<UnorderedListOutlined />} title={'Announcements'} />} key="announcements">
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <div style={{width: '50vw', paddingTop: '25px'}}>
                                <PostsView canAdd={true} liveSessionId={this.getLiveSessionId()} />
                            </div>
                        </div>
                    </TabPane>
                </Tabs>

                <Modal
                    title="Add Private Access"
                    visible={this.state.addPrivateAccess}
                    okText="Add"
                    confirmLoading={this.state.addingPrivateAccess}
                    okButtonProps={{disabled: this.state.addPrivateAccessEmailAddress.trim() === ""}}
                    onOk={() => this.addPrivateAccess(this.state.addPrivateAccessEmailAddress, () => this.setState({addPrivateAccess: false, addPrivateAccessEmailAddress: ""}))}
                    onCancel={() => this.setState({addPrivateAccess: false, addPrivateAccessEmailAddress: ""})}>
                        <Input className="message-input" placeholder="User email address" size="large" value={this.state.addPrivateAccessEmailAddress} onChange={e => this.setState({addPrivateAccessEmailAddress: e.target.value})} 
                            onPressEnter={() => this.addPrivateAccess(this.state.addPrivateAccessEmailAddress, () => this.setState({addPrivateAccess: false, addPrivateAccessEmailAddress: ""}))}/>
                </Modal>

                {this.state.grantLearnerAccess &&
                <UserListDialog showOrganizationNonAccessLearners title="Grant Access" message="Select learners that you want to grant access to" 
                selectable={true} visible={this.state.grantLearnerAccess} onCancel={() => this.setState({grantLearnerAccess: false})} 
                confirmLoading={this.state.grantingLearnersAccess} liveSessionId={this.getLiveSessionId()} emptyMessage="No users left"
                onOk={users => this.grantLearnerAccess(users, () => this.setState({grantLearnerAccess: false}))} />}

                {this.state.grantTutorAccess &&
                <UserListDialog showOrganizationNonAccessTutors title="Grant Access" message="Select tutors that you want to grant access to" 
                selectable={true} visible={this.state.grantTutorAccess} onCancel={() => this.setState({grantTutorAccess: false})} 
                confirmLoading={this.state.grantingTutorsAccess} liveSessionId={this.getLiveSessionId()} emptyMessage="No users left"
                onOk={users => this.grantTutorAccess(users, () => this.setState({grantTutorAccess: false}))} />}
            </div>
        )
    }

}

export default withMediaQuery(withRouter(TutorLiveSessionPage));