import React from 'react'
import { PageHeader, Button, Popconfirm, Tooltip, Alert, Spin, Empty, Input, Upload, Table, Radio, Tag } from 'antd';

import { 
    PlusOutlined,
    EditOutlined,
    DeleteOutlined,
    InboxOutlined,
    CloseOutlined,
    CloseCircleOutlined,
    PlusCircleOutlined,
    CheckOutlined,
    CheckCircleFilled,
} from '@ant-design/icons';
import Api from '../../session/Api';
import { NO_PAGE_TOKEN, PAGINATION_PAGE_CONTENT_COUNT } from '../../Constants';

import InfiniteScroll from 'react-infinite-scroller';
import Modal from 'antd/lib/modal/Modal';
import Util from '../../util/Util';

import NoImage from '../../images/NoImage.png';
import UIUtil from '../../util/UIUtil';

const FormPadding = () => {
    return ( <> <br /> <br /> </> )
}

const getAnswer = (question, answerId) => {
    for (const choice of question.choices) {
        if (choice.id == answerId) {
            return choice.answer;
        }
    }

    return "";
}

const QuizItem = ({quiz, answerReport, onChoiceSelected}) => (
    <div style={{width: '100%', borderRadius: '15px', padding: '15px', boxShadow: "0px 0px 25px 0px rgba(0, 0, 0, 0.2)", display: 'flex'}}>
        <div style={{marginLeft: '5px', flex: 1, minWidth: 1}}>
            <div style={{display: 'flex', alignItems: 'center', marginRight: '5px'}}>
                <h2 style={{height: '25px'}}>{quiz.question}</h2>
                <div style={{flex: '1'}} />
                {answerReport !== undefined && 
                (answerReport.correct ? (
                    <Tag color="green"><CheckOutlined /> Correct</Tag>
                ) : (
                    <Tag color="red"><CloseOutlined /> Wrong</Tag>
                ))}
            </div>
            
            {quiz.hasImage && 
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <img src={Api.getQuizQuestionImage(quiz.id)} style={{objectFit: 'contain', objectPosition: 'center', maxWidth: '75%', maxHeight: '450px'}} />
            </div>}
        
            
            <Radio.Group onChange={e => {
                const choiceId = e.target.value;
                for (const choice of quiz.choices) {
                    choice.answered = choiceId == choice.id;
                }
                onChoiceSelected();
            }} disabled={answerReport !== undefined}>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    {quiz.choices.map(choice => (<>
                        <Radio style={{marginTop: '10px'}} value={choice.id}>{choice.answer}</Radio>
                    </>))}
                </div>
            </Radio.Group>

            {answerReport !== undefined && !answerReport.correct && 
            <>
                <h5 style={{marginTop: '10px'}}>Correct Answer: </h5>
                <p>{getAnswer(quiz, answerReport.correctAnswerId)}</p>
            </>}
        </div>
    </div>
)

class QuizViewer extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            answering: false,

            items: [],
            page: NO_PAGE_TOKEN,
            hasMore: true,
            loadingItems: false,

            selectedChoices: 0,
            answerReport: undefined,
        }

        this.resultRef = React.createRef();
    }

    getNextQuestions() {
        if (this.state.loadingItems) {
            return;
        }

        this.setState({
            loadingItems: true
        })

        //list error
        Api.getQuizQuestions(this.props.courseItem.id, this.state.page, response => {
            if (response.status === true) {
                let items = this.state.items;
                items.push(...response.payload.items);

                this.setState({
                    items: items,
                    page: response.payload.token,
                    hasMore: Util.isStringExists(response.payload.token),
                    loadingItems: false
                })
            } else {
                //alert('errowr');
            }
        });
    }

    answerQuestion() {
        this.setState({answering: true})
        Api.answerQuestion({
            quizId: this.props.courseItem.id,
            answers: this.state.items.map(question => {
                let answeredChoice = 0;
                for (const choice of question.choices) {
                    if (choice.answered) {
                        answeredChoice = choice.id;
                        break;
                    }
                }

                return {
                    questionId: question.id,
                    answerId: answeredChoice
                }
            })
        }, response => {
            if (response.status === true) {
                this.setState({
                    answering: false,
                    answerReport: response.payload
                }, () => {
                    this.resultRef.current.scrollIntoView({behavior: "smooth"});
                })

                if (this.props.onSubmit !== undefined) {
                    this.props.onSubmit();
                }
            } else {
                this.setState({answering: false})
                UIUtil.showError();
            }
        })
    }

    getSpecificAnswerReport(questionId) {
        if (this.state.answerReport !== undefined) {
            for (const report of this.state.answerReport.answers) {
                if (report.questionId == questionId) {
                    return report;
                }
            }
        }
    }

    getSelectedChoicesCount() {
        let count = 0;
        for (const question of this.state.items) {
            for (const choice of question.choices) {
                if (choice.answered) {
                    count++;
                    break;
                }
            }
        }

        return count;
    }

    render() {
        return (
            <div>
                <PageHeader
                    title={this.props.courseItem.title}
                    subTitle={"Quiz - " + (this.state.selectedChoices + "/" + this.props.courseItem.questions)}
                    onBack={this.props.onBack}/>
                
                <div style={{paddingRight: '25px', marginTop: '10px'}}>
                    <InfiniteScroll
                        loadMore={this.getNextQuestions.bind(this)}
                        hasMore={this.state.hasMore}
                        threshold={750}
                        loader={
                            <div style={{display: 'flex', justifyContent: 'center', marginTop: '45px', marginBottom: '45px'}}>
                                <Spin size="large" tip="Loading..." />
                            </div>
                        }
                        style={{overflow: 'visible'}}>

                        {this.state.items.map((item, index) => {
                            return ( 
                                <div key={item.id}>
                                    {index > 0 ? <br /> : null}
                                    <QuizItem quiz={item} answerReport={this.getSpecificAnswerReport(item.id)} 
                                    //onChoiceSelected={() => this.setState(prevState => ({selectedChoices: prevState.selectedChoices + 1}))} 
                                    onChoiceSelected={() => this.setState({selectedChoices: this.getSelectedChoicesCount()})}
                                    />
                                </div>
                            )
                        })}
                    </InfiniteScroll>
                    {!this.state.loadingItems ? 
                        (this.state.items.length > 0 ? (
                            <>
                                <br />
                                <br />
                                <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                                    {this.state.answerReport !== undefined ? (<div ref={this.resultRef} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                        <h1>Quiz Submitted!</h1>
                                        <h2><CheckCircleFilled style={{color: 'green', marginRight: '5px'}} /> Score: {this.state.answerReport.score}/{this.state.answerReport.totalScore} </h2>
                                    </div>) : (<>
                                        <h5>{"Questions answered " + this.state.selectedChoices + "/" + this.props.courseItem.questions}</h5>
                                        <Button onClick={() => this.answerQuestion()} type="primary" size="large" loading={this.state.answering}><CheckOutlined /> Submit Quiz</Button>
                                    </>)}
                                </div>
                            </>
                        ) : (
                            <Empty style={{marginTop: '50px'}} image={Empty.PRESENTED_IMAGE_SIMPLE} description="No questions" />
                        ))
                    : null}
                    <br />
                </div>

            </div>
        );
    }
}

export default QuizViewer;