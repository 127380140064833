
import React, { useEffect } from 'react';
import Api from '../../session/Api';
import AudioWaveform from '../../components/AudioWaveform';
import { MESSAGE_ATTACHMENT_TYPE_AUDIO, MESSAGE_ATTACHMENT_TYPE_IMAGE, MESSAGE_ATTACHMENT_TYPE_VIDEO, MESSAGE_ATTACHMENT_TYPE_FILE } from '../../Constants';
import VideoThumbnail from '../../components/VideoThumbnail';
import FileCard from '../../components/FileCard';

import { 
    LoadingOutlined,
} from '@ant-design/icons';
import { Progress, Tag } from 'antd';
import Util from '../../util/Util';

const InLimboOverlay = ({message, error, borderRadius, onResendMessage}) => {
    return (
        <div style={{background: '#00000050', position: 'absolute', left: '0', top: '0', bottom: '0', right: '0', borderRadius: borderRadius}}>
            {!error ? (<>
                <div style={{width: '40px', height: '40px', borderRadius: '50%', background: (message.messageAttachment.uploadProgress < 100 ? '#FFFFFF50' : ''), position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}} />
                {message.messageAttachment.uploadProgress < 100 ? (
                    <Progress type="circle" percent={message.messageAttachment.uploadProgress} width={45} style={{position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', color: 'white !important'}} strokeColor="white" trailColor="#FFFFFF50" />
                ) : (
                    <LoadingOutlined style={{fontSize: '45px', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', color: 'white'}} />
                )}  
            </>) : (
                <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Tag color="error" onClick={onResendMessage} style={{zIndex: '2'}}>Failed to send. Click to retry.</Tag>
                </div>
            )}
        </div>
    )
}

export default function AttachmentViewer(props) {
    const message = props.message;
    //const exists = message.attachmentId !== undefined && message.attachmentId > 0;
    const exists = message.messageAttachment !== undefined && Util.isStringExists(message.messageAttachment.id);
    const error = message.id === -1;

    const borderRadius = props.liveStream ? '0' : (props.flipBorder ? '2px 25px 25px 25px' : '25px 2px 25px 25px');

    switch (message.messageAttachment !== undefined ? message.messageAttachment.attachmentType : -1) {
        
        case MESSAGE_ATTACHMENT_TYPE_AUDIO:
            return exists ? (
                <div style={{width: props.liveStream ? '' : '250px', marginBottom: '10px'}}>
                    <AudioWaveform {...props} audioUrl={Api.getMessageAttachment(message.messageAttachment.id)} message={message} />
                </div>
            ) : (
                <div style={{width: props.liveStream ? '' : '250px', marginBottom: '10px', position: 'relative'}}>
                    <AudioWaveform {...props} audioBlob={message.messageAttachment.blob} />
                    <InLimboOverlay message={message} error={error} borderRadius={borderRadius} onResendMessage={props.onResendMessage} />
                </div>
            )

        case MESSAGE_ATTACHMENT_TYPE_IMAGE:
            return exists ? (
                <div style={{width: props.liveStream ? '' : '450px', maxWidth: '100%', height: '250px', marginBottom: '10px'}}>
                    <img {...props} style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: borderRadius, cursor: 'pointer'}} src={Api.getMessageAttachment(message.messageAttachment.id)} 
                    onClick={props.onMediaClick}/>
                </div>
            ) : (
                <div style={{width: props.liveStream ? '' : '450px', maxWidth: '100%', height: '250px', marginBottom: '10px', position: 'relative'}}>
                    <img {...props} style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: borderRadius, cursor: 'pointer'}} src={URL.createObjectURL(message.messageAttachment.blob)}/>
                    <InLimboOverlay message={message} error={error} borderRadius={borderRadius} onResendMessage={props.onResendMessage} />
                </div>
            )

        case MESSAGE_ATTACHMENT_TYPE_VIDEO:
            return exists ? (
                <div style={{width: props.liveStream ? '' : '450px', maxWidth: '100%', height: '250px', marginBottom: '10px'}}>
                    <VideoThumbnail style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: borderRadius}} src={Api.getMessageAttachment(message.messageAttachment.id)} 
                        onClick={props.onMediaClick}/>
                </div>
            ) : (
                <div style={{width: props.liveStream ? '' : '450px', maxWidth: '100%', height: '250px', marginBottom: '10px', position: 'relative'}}>
                    <VideoThumbnail hidePlay style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: borderRadius}} src={URL.createObjectURL(message.messageAttachment.blob)}
                        onClick={props.onMediaClick}/>
                    <InLimboOverlay message={message} error={error} borderRadius={borderRadius} onResendMessage={props.onResendMessage} />
                </div>
            )

        case MESSAGE_ATTACHMENT_TYPE_FILE:
            return exists ? (
                <div style={{width: props.liveStream ? '' : '300px', marginBottom: '10px'}}>
                    <a href={Api.getMessageAttachment(message.messageAttachment.id)}>
                        <FileCard clickable {...props} fileName={message.messageAttachment.fileName} fileSize={message.messageAttachment.fileSize} />
                    </a>
                </div>
            ) : (
                <div style={{width: props.liveStream ? '' : '300px', position: 'relative', marginBottom: '10px'}}>
                    <FileCard {...props} fileName={message.messageAttachment.blob.name} fileSize={message.messageAttachment.blob.size} />
                    <InLimboOverlay message={message} error={error} borderRadius={borderRadius} onResendMessage={props.onResendMessage} />
                </div>
            )


        default:
            return (<></>)
    }
}