import React, { useEffect, useRef } from 'react'
import Util from '../../util/Util';

const DIM_PERC = 10000;

const generateFlexes = (count) => {
    let flexes = [];
    for (let i = 0; i < count; i++) {
        flexes[i] = DIM_PERC / count;
    }
    return flexes;
}

const generateFlexViews = (count, render) => {
    let views = [];
    for (let i = 0, y = 0; i < count + (count - 1); i++) {
        const isView = i % 2 == 0;
        views[i] = render(isView, isView ? Math.max(y, 0) : [y - 1, y])
        if (isView) {
            y++;
        }
    }
    return views;
}

const Divider = ({onStartResize, verticalResize, viewIndexRange, key}) => (
    <div key={key} onMouseDown={() => onStartResize(viewIndexRange)} style={{width: verticalResize ? '100%' : 4, height: verticalResize ? 4 : '100%', cursor: verticalResize ? 'row-resize' : 'col-resize', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <div style={{background: 'rgb(255,255,255,0.05)', borderRadius: '50%', width: verticalResize ? '50%' : 2, height: verticalResize ? 2 : '75%'}} />
    </div>
)

const Row = ({flex, vertical, children, key}) => {
    const ref = useRef(null);
    
    return (
        <div key={key} ref={ref} style={{width: vertical ? '100%' : undefined, height: !vertical ? '100%' : undefined, flex: flex, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            {children}
        </div>
    )
}

export default function GridView(props) {
    const verticalGrid = props.vertical;

    const singleViewMode = props.children.length === undefined || props.children.length === 1;
    const rowCount = singleViewMode ? 1 : props.children.length;
    const container = useRef(null);

    var resizedViewIndex = [0, 0];
    var savedFlexValues = generateFlexes(rowCount)
    if (props.firstRowSmall && savedFlexValues.length == 2) {
        savedFlexValues[0] = DIM_PERC * (1/4);
        savedFlexValues[1] = DIM_PERC * (3/4);
    }
    const [flexValues, setFlexValues] = React.useState([...savedFlexValues]);

    useEffect(() => {
        if (props.firstRowSmall && savedFlexValues.length == 2 && savedFlexValues[0] == (DIM_PERC * (1/4)) && savedFlexValues[1] == (DIM_PERC * (3/4))) {
            setFlexValues([...savedFlexValues]);
        } else {
            if (Util.arrayEqual(savedFlexValues, generateFlexes(rowCount))) {
                setFlexValues([...savedFlexValues]);
            }
        }
    }, [props.children.length])

    const resize = (e) => {
        const movement = verticalGrid ? e.movementY : e.movementX;
        const perc = (movement / (verticalGrid ? container.current.offsetHeight : container.current.offsetWidth)) * DIM_PERC;

        const firstRowFlex = savedFlexValues[0];
        const secondRowFlex = savedFlexValues[1];

        let firstRowNewFlex = firstRowFlex + perc;
        let secondRowNewFlex = secondRowFlex - perc;

        if (((firstRowNewFlex / DIM_PERC) < (verticalGrid ? 0.2 : 0.1)) || ((secondRowNewFlex / DIM_PERC) < (verticalGrid ? 0.2 : 0.1))) {
            firstRowNewFlex = firstRowFlex;
            secondRowNewFlex = secondRowFlex;
        }

        const firstFlex = firstRowNewFlex > 0 ? Math.round(firstRowNewFlex) : 1;
        const secondFlex = secondRowNewFlex > 0 ? Math.round(secondRowNewFlex) : 1;

        savedFlexValues[0] = firstFlex;
        savedFlexValues[1] = secondFlex;

        let newFlexValues = [...flexValues];
        newFlexValues[resizedViewIndex[0]] = firstFlex;
        newFlexValues[resizedViewIndex[1]] = secondFlex;

        setFlexValues(newFlexValues);
    }
    
    const stopResize = () => {
        window.removeEventListener('mousemove', resize)
        window.removeEventListener('mouseup', stopResize)
    }

    const onStartResize = (viewIndexRange) => {
        resizedViewIndex = viewIndexRange;
        savedFlexValues = [...flexValues.slice(viewIndexRange[0], viewIndexRange[1] + 1)]

        window.addEventListener('mousemove', resize)
        window.addEventListener('mouseup', stopResize)
    }

    return (
        <div key={props.key} ref={container} style={{width: '100%', height: '100%', display: 'flex', flexDirection: verticalGrid ? 'column' : 'row', padding: 0}}>
            {generateFlexViews(rowCount, (isView, indexData) => (
                isView ? (
                    <Row key={'row' + indexData} flex={flexValues[indexData]} vertical={verticalGrid}>
                        {singleViewMode ? props.children : props.children[indexData]}
                    </Row>
                ) : (
                    <Divider key={'divider' + indexData[0]} onStartResize={onStartResize} verticalResize={verticalGrid} viewIndexRange={indexData} />
                )
            ))}
        </div>
    )
}