import React from 'react'
import Modal from 'antd/lib/modal/Modal'
import ProfilePicture from '../ProfilePicture'
import { Button, Avatar, Alert } from 'antd'

import { Link } from 'react-router-dom';
import UploadWrapper from '../UploadWrapper';

import { 
    CameraFilled,
    CloseCircleFilled,
    MessageOutlined,
    UserOutlined,
    CheckOutlined
} from '@ant-design/icons';

import './student-profile-dialog.less';
import Api from '../../session/Api';
import UIUtil from '../../util/UIUtil';

export default function StudentProfileDialog(props) {
    const [updateProfilePicFile, setUpdateProfilePicFile] = React.useState();
    const [loading, setLoading] = React.useState(false);

    const onUpload = () => {
        setLoading(true);
        Api.updateStudentProfile(updateProfilePicFile, response => {
            setLoading(false)
            if (response.status === true) {
                setUpdateProfilePicFile(undefined)
                
                if (props.onProfilePicUpdate !== undefined) {
                    props.onProfilePicUpdate();
                }
                props.onCancel();
            } else {
                UIUtil.showError();
            }
        })
    }

    return (
        <Modal
            visible={props.visible}
            onCancel={props.onCancel}
            title="Learner Profile"
            width={250}
            footer={null}>
            <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                {props.userId !== undefined ? (
                    <ProfilePicture size={160} userId={props.userId} />
                ) : (
                    <UploadWrapper accept="image/gif,image/jpeg,image/png" onChange={e => setUpdateProfilePicFile(e.target.files[0])} onClick={e => {
                        if (updateProfilePicFile !== undefined) {
                            setUpdateProfilePicFile(undefined);
                            e.preventDefault();
                        }
                    }}>
                        <div style={{position: 'relative', width: '160px', marginBottom: '160px'}}>
                            <ProfilePicture size={160} userId={props.userId} style={{position: 'absolute'}} />
                            <Avatar src={updateProfilePicFile !== undefined ? URL.createObjectURL(updateProfilePicFile) : Api.getMyProfilePic()} icon={<UserOutlined />} size={160} style={{position: 'absolute'}} />
                            <div className="upload-profile-pic-overlay" style={{position: 'absolute'}}>
                                {updateProfilePicFile === undefined ? (
                                    <CameraFilled style={{color: 'white', fontSize: '20px'}} />
                                ) : (
                                    <CloseCircleFilled style={{color: 'white', fontSize: '20px'}} />
                                )}
                            </div>
                        </div>
                    </UploadWrapper>
                )}

                <h2 style={{marginTop: '25px'}}>{props.name}</h2>
                <p style={{marginTop: '-15px'}}>Learner</p>

                {props.showSendMessage ? 
                    <Link to={'/messages?userId=' + props.userId} {...(props.targetBlank ? { target: "blank" } : {})}>
                        <Button size="large" type="primary" style={{marginTop: '15px'}}><MessageOutlined /> Send Message</Button> 
                    </Link>
                : null}

                {props.userId === undefined ? 
                    <Button style={{marginTop: '15px'}} disabled={updateProfilePicFile === undefined} loading={loading} onClick={onUpload}>Save</Button> 
                : null}
            </div>
        </Modal>
    )
}