import React from 'react'
import Page from '../../base/Page';
import withMediaQuery from '../../components/WithMediaQuery';

import { 
    PlusOutlined,
    UnorderedListOutlined,
    AppstoreOutlined,
    LockOutlined,
} from '@ant-design/icons';
import UIUtil from '../../util/UIUtil';
import { Button, Radio, Tag } from 'antd';
import { BLGO_POST_TAGS } from '../../BlogPostTags';
import GridBlogPostView from '../../views/blog/GridBlogPostView';
import Util from '../../util/Util';
import ListBlogPostView from '../../views/blog/ListBlogPostView';

class BlogPage extends Page {

    constructor(props) {
        super(props)

        this.state = {
            ...this.state,
            tag: -1,
            displayMode: "list",

            listId: Util.newTempId()
        }
    }

    render() {
        const tagConfig = (tagId) => ({
            style: {
                cursor: 'pointer',
                marginBottom: 5
            },
            className: this.state.tag != tagId ? "unselected-tag" : undefined,
            onClick: this.state.tag != tagId ? (() => this.setState({tag: tagId, listId: Util.newTempId()})) : undefined
        })

        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div style={{width: this.props.renderFullWidth ? '90vw' : '75vw', paddingTop: '25px', paddingBottom: '25px'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <h2>Learnerix Blog</h2>
                        <div style={{flex: '1'}} />
                        <Radio.Group value={this.state.displayMode} onChange={e => this.setState({displayMode: e.target.value})} style={{alignSelf: 'flex-end', marginBottom: 15}}>
                            <Radio.Button value="list"><UnorderedListOutlined /></Radio.Button>
                            <Radio.Button value="grid"><AppstoreOutlined /></Radio.Button>
                        </Radio.Group>
                    </div>
                    <div>
                        <Tag color="black" {...tagConfig(-1)}>All</Tag>
                        {BLGO_POST_TAGS.map(tag => <Tag color={tag.color} icon={tag.icon} {...tagConfig(tag.id)}>{tag.title}</Tag>)}
                    </div>
                    <br />

                    <div key={this.state.listId}>
                        {this.state.displayMode == "list" ? (
                            <ListBlogPostView filterTag={this.state.tag} />
                        ) : (
                            <GridBlogPostView filterTag={this.state.tag} />
                        )}
                    </div>
                </div>
            </div>
        )
    }

}

export default withMediaQuery(BlogPage);