import React from 'react';
import Page from '../../base/Page';

import { Tabs, Button, Menu, Input, Upload, InputNumber, Checkbox, Alert, Popconfirm, Popover, Dropdown, Modal, Progress } from 'antd';

import './student-course-page.less'

import {  
    UnorderedListOutlined,
    EditOutlined,
    VideoCameraOutlined,
    QuestionCircleOutlined,
    EyeInvisibleOutlined,
    QuestionOutlined,
    AlertOutlined,
    AuditOutlined,
    CloseOutlined,
    CreditCardOutlined,
    FileOutlined,
    PlusCircleOutlined,
    CheckCircleFilled,
    CheckCircleOutlined,
    LockFilled
} from '@ant-design/icons';
import PostsView from '../../views/posts/PostsView';
import { Link, withRouter } from 'react-router-dom';
import RateChart from '../ratings-page/RateChart';
import Api from '../../session/Api';
import Error404Page from '../Error404Page';
import Util from '../../util/Util';
import { Tag, Tooltip } from 'antd';

import { Select } from 'antd';
import { CATEGORIES } from '../../Categories';
import TagBox from '../../components/TagBox';
import UIUtil from '../../util/UIUtil';
import { motion } from 'framer-motion';
import { COURSE_SECTION_TYPE_CUSTOM, COURSE_SECTION_TYPE_LECTURE, COURSE_SECTION_TYPE_HELP_SESSION, COURSE_SECTION_TYPE_LIVE_SESSION, GALLERY_ITEM_TYPE_IMAGE, GALLERY_ITEM_TYPE_VIDEO, API_ERROR_MESSAGE_SUBSCRIPTION_REQUIRED } from '../../Constants';

import NoImage from '../../images/NoImage.png';
import CourseSectionView from '../../views/course-section/CourseSectionView';
import { getFieldId } from 'antd/lib/form/util';
import StudentCourseHelpSessionButton from '../../views/courses/StudentCourseHelpSessionButton';

import LogoWhite from '../../images/LogoWhite.png';
import LiveIcon from '../../images/LiveIcon.png';
import LogoSquare from '../../images/LogoSquare.png';
import LiveSubscriptionDialog from '../../components/LiveSubscriptionDialog';
import SessionManager from '../../session/SessionManager';

const { Option } = Select;
const { TabPane } = Tabs;
const { SubMenu } = Menu;

const TabTitle = ({icon, title}) => {
    return (
        <span style={{height: '35px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            {icon}
            {title}
        </span>
    )
}

const DetailsTabTitle = ({icon, title}) => {
    return (
        <span>
            {icon}
            {title}
        </span>
    )
}

const ContentSectionTabTitle = ({icon, title, hidden, progress}) => {
    return (
        <span style={{height: '35px', width: '250px', display: 'flex', alignItems: 'center'}}>
            {icon}
            {title}

            <div style={{flex: 1}} />
            {hidden && <Button type="text" style={{width: 25}}>{<EyeInvisibleOutlined />}</Button>}
            {!hidden && progress != -1 && SessionManager.isLoggedIn() && (
                progress == 100 ? (
                    <CheckCircleOutlined style={{color: '#52c41a', fontSize: 28, margin: 0}} />
                ) : (
                    <Progress className="progress-non-white-color" type="circle" percent={progress} width={30} 
                    strokeColor={progress < 100 && "#f26622"} strokeWidth={7} />
                )
            )}
        </span>
    )
}

const FormPadding = () => {
    return ( <> <br /> <br /> </> )
}

class StudentCoursePage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            detailsTab: "store-listing",
            course: {},
            courseDoesNotExist: false,
            courseRequiresSubscription: false,

            showHiddenItems: false,

            subscribing: false,

            showSubscribeDialog: false,
        }
    }

    getCourseId() {
        return this.props.match.params.courseId;
    }

    onPageStart() {
        Api.getStudentCourse(this.getCourseId(), response => {
            if (response.status === true) {
                this.setCourse(response.payload)
            } else {
                if (response.message == API_ERROR_MESSAGE_SUBSCRIPTION_REQUIRED) {
                    this.setState({courseRequiresSubscription: true});
                } else {
                    this.setState({courseDoesNotExist: true});
                }
            }
        })
    }

    /*subscribeCourse() {
        const courseId = this.getCourseId();

        this.setState({subscribing: true})
        Api.subscribeCourse(courseId, response => {
            if (response.status === true) {
                this.state.course.subscribed = true;
                Modal.success({
                    title: "Success!",
                    content: "Course subscribed successfully!",
                    onOk: () => window.location.reload()
                })
            } else {
                UIUtil.showError();
            }
            this.setState({subscribing: false})
        })
    }*/

    setCourse(course, extra) {
        this.setState({
            ...(extra !== undefined ? extra : {}),
            course: course,
        })
    }

    onChangeTab(tab) {
        this.props.history.push(this.props.location.pathname + "?tab=" + tab);
    }

    getSections() {
        let sections = Util.safeGetProperty(this.state.course, "sections", []);
        if (!this.state.showHiddenItems) {
            sections = sections.filter(section => !section.hidden)
        }
        return sections;
    }

    render() {
        if (this.state.courseRequiresSubscription) {
            return (
                <div style={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                    <LockFilled style={{fontSize: 75}} />
                    <h2 style={{margin: 0, marginTop: 15}}>Subscription required in order to continue</h2>
                    <p>Subscribe again in order to regain Course access</p>

                    <div style={{height: 25}} />

                    <div style={{width: 200, height: '70px', backgroundColor: '#2d3436', backgroundImage: 'linear-gradient(315deg, #2d3436 0%, #000000 74%)',
                    borderRadius: '5px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}} onClick={() => this.setState({showSubscribeDialog: true})}>
                        <img src={LogoWhite} style={{width: '100px'}} />
                        <img src={LiveIcon} style={{width: '25px'}} />
                    </div>

                    <LiveSubscriptionDialog visible={this.state.showSubscribeDialog} onCancel={() => this.setState({showSubscribeDialog: false})} />
                </div>
            )
        }

        if (this.state.courseDoesNotExist) {
            return (
                <Error404Page {...this.props} customMessage="Course does not exist" />
            )
        }

        return (
            <div style={{height: '100%'}}>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: '45px', paddingRight: '45px', marginTop: '30px', marginBottom: '12px'}}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <h1 style={{height: '34px'}}>{this.state.course.title}</h1>
                        {Util.isStringExists(this.state.course.hashtag) &&
                        <Link style={{marginTop: -10, fontSize: 14}} target="_blank" to={`/hashtag/${this.state.course.hashtag}`}>#{this.state.course.hashtag}</Link>}
                    </div>

                    {SessionManager.isLoggedIn() && <Progress className="progress-non-white-color" type="circle" percent={this.state.course.progress} width={50} 
                    style={{marginLeft: 15}} strokeColor={this.state.course.progress < 100 && "#f26622"} />}
                    
                    {/*!this.state.course.published && this.state.course.hasAtLeastOneStudent &&
                    <Tooltip title="This course can still be accessed by the learners that own it"><Button type="link"><AuditOutlined /></Button></Tooltip>*/}

                    <div style={{flex: 1}} />
                    
                    {SessionManager.isLoggedIn() ? (<>
                        {Util.isStringExists(this.state.course.liveStreamInProgress) && 
                            <Button type="link" className="red-link" style={{cursor: 'default'}}><AlertOutlined /> Live</Button>}
                        {this.state.course.subscribed ? (
                            <StudentCourseHelpSessionButton courseId={this.state.course.id} helpSessionState={this.state.course.helpSessionState} onUpdate={() => this.setState({})} liveStreamInProgress={this.state.course.liveStreamInProgress} />
                        ) : (this.state.course.allowSubscriptions &&
                            <div style={{paddingLeft: '15px', paddingRight: '15px', height: '40px', backgroundColor: '#2d3436', backgroundImage: 'linear-gradient(315deg, #2d3436 0%, #000000 74%)',
                            borderRadius: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}} onClick={() => this.setState({showSubscribeDialog: true})}>
                                <img src={LogoWhite} style={{width: '85px'}} />
                                <img src={LiveIcon} style={{width: '25px', marginLeft: '15px'}} />
                            </div>
                            //<Button type="primary" size="large" className="red-button" onClick={() => this.subscribeCourse()}><CreditCardOutlined /> Subscribe - {this.state.course.subscriptionPrice + " USD monthly"}</Button>
                        )}
                    </>) : (<>
                        <Link to="/auth">
                            <Button type="primary" size="large"><img src={LogoSquare} style={{height: '100%', objectFit: 'contain', objectPosition: 'center', marginRight: 15}} /> Join Learnerix</Button>
                        </Link>
                    </>)}
                </div>
                <Tabs activeKey={this.state.pathParams.tab} size="large" style={{width: '100%',}} type="card" onChange={this.onChangeTab.bind(this)} className="student-course-page-tabs">
                    <TabPane tab={<TabTitle icon={<FileOutlined />} title={'Content'} />} key="content">
                        <Tabs size="large" style={{width: '100%',}} tabPosition="left" type="editable-card" addIcon={
                            !this.state.showHiddenItems ? (
                                <Button type="link" onClick={() => this.setState(state => ({showHiddenItems: !state.showHiddenItems}))}><EyeInvisibleOutlined /> Show hidden</Button>
                            ) : (
                                <Button type="link" onClick={() => this.setState(state => ({showHiddenItems: !state.showHiddenItems}))}><CloseOutlined /> Exit hidden mode</Button>
                            )
                        } tabBarStyle={{marginTop: 25}} onEdit={(targetKey, action) => {
                            if (action === 'add') {
                                
                            }
                        }} activeKey={this.state.selectedSectionId} onChange={selectedId => this.setState({selectedSectionId: selectedId})}>
                            {this.getSections().map(section => {
                                const tabProps = {
                                    hidden: section.hidden,
                                    title: section.title,
                                }

                                let tab;
                                switch (section.type) {
                                    case COURSE_SECTION_TYPE_CUSTOM:
                                        tab = <ContentSectionTabTitle progress={section.progress} editable onRenameBtn={() => this.setState({
                                            renameSection: true,
                                            renameSectionItem: section,
                                            renameSectionTitle: section.title,
                                        })} {...tabProps} deletable={(new Date().getTime() - section.dateCreated) <= (10 * 60 * 1000)} />
                                        break;
                                    case COURSE_SECTION_TYPE_LECTURE:
                                        tab = <ContentSectionTabTitle progress={section.progress} icon={<VideoCameraOutlined />} {...tabProps} />
                                        break;
                                    case COURSE_SECTION_TYPE_HELP_SESSION:
                                        tab = <ContentSectionTabTitle progress={section.progress} icon={<QuestionOutlined />} {...tabProps} />
                                        break;
                                    case COURSE_SECTION_TYPE_LIVE_SESSION:
                                        tab = <ContentSectionTabTitle progress={-1} icon={<AlertOutlined />} {...tabProps} title="Live Streams" />
                                        break;
                                    default:
                                        return (<> </>);
                                }

                                return (
                                    <TabPane tab={tab} closable={false} key={section.id}>
                                        <CourseSectionView section={section} studentMode showHiddenItems={this.state.showHiddenItems} 
                                        onUpdateProgress={() => {
                                            Api.getCourseProgressUpdate(this.state.course.id, section.id, response => {
                                                if (response.status === true) {
                                                    this.state.course.progress = response.payload.courseProgress;
                                                    section.progress = response.payload.currentCourseSectionProgress;
                                                    this.setState({})
                                                }
                                            })
                                        }} />
                                    </TabPane>
                                )
                            })}
                        </Tabs>
                    </TabPane>
                    <TabPane tab={<TabTitle icon={<UnorderedListOutlined />} title={'Announcements'} />} key="announcements">
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <div style={{width: '50vw', paddingTop: '25px'}}>
                                <PostsView courseId={this.getCourseId()} />
                            </div>
                        </div>
                    </TabPane>
                </Tabs>

                <LiveSubscriptionDialog visible={this.state.showSubscribeDialog} onCancel={() => this.setState({showSubscribeDialog: false})} />
            </div>
        )
    }

}

export default withRouter(StudentCoursePage);