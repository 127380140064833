import React from 'react'
import { PageHeader, Button, Popconfirm, Tooltip, Alert, Spin, Empty, Input, Upload, Table } from 'antd';

import { 
    PlusOutlined,
    EditOutlined,
    DeleteOutlined,
    InboxOutlined,
    CloseOutlined,
    CloseCircleOutlined,
    PlusCircleOutlined,
    CheckOutlined
} from '@ant-design/icons';
import Api from '../../session/Api';
import { NO_PAGE_TOKEN, PAGINATION_PAGE_CONTENT_COUNT } from '../../Constants';

import InfiniteScroll from 'react-infinite-scroller';
import Modal from 'antd/lib/modal/Modal';
import Util from '../../util/Util';

import NoImage from '../../images/NoImage.png';
import UIUtil from '../../util/UIUtil';

const FormPadding = () => {
    return ( <> <br /> <br /> </> )
}

const QuizItem = ({quiz, onEditBtn, onDeleteBtn, deleteLoading, thumbnailSuffix}) => (
    <div style={{width: '100%', borderRadius: '15px', padding: '15px', boxShadow: "0px 0px 25px 0px rgba(0, 0, 0, 0.2)", display: 'flex'}}>
        <div style={{marginLeft: '5px', flex: 1, minWidth: 1}}>
            <div style={{display: 'flex', alignItems: 'center', marginRight: '5px'}}>
                <h2 style={{height: '25px'}}>{quiz.question}</h2>

                <div style={{flex: '1'}} />
                
                <Tooltip title="Edit" placement="topRight">
                    <Button type="link" size="large" onClick={e => {
                        e.stopPropagation();
                        onEditBtn();
                    }}><EditOutlined /></Button>
                </Tooltip>

                <Popconfirm title="Are you sure you want to delete this question?" onConfirm={() => onDeleteBtn()} placement="topRight">
                    <Button danger type="link" size="large" onClick={e => e.stopPropagation()} loading={deleteLoading}><DeleteOutlined /></Button>
                </Popconfirm>
            </div>
            
            {quiz.hasImage && 
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <img src={Api.getQuizQuestionImage(quiz.id) + (thumbnailSuffix !== undefined ? "&t=" + thumbnailSuffix : "")} style={{objectFit: 'contain', objectPosition: 'center', maxWidth: '75%', maxHeight: '450px'}} />
            </div>}
            
            {quiz.choices.map(choice => (<>
                <Alert message={choice.answer} type={choice.correct ? "success" : "info"} showIcon={choice.correct} style={{marginTop: '10px'}} /> 
            </>))}
        </div>
    </div>
)

class QuizDesigner extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            question: undefined,
            
            questionFormQuestion: "",
            questionFormImage: undefined,
            questionFormHasImage: false,
            questionFormNewChoice: "",
            questionFormChoices: [],
            savingQuestion: false,

            items: [],
            page: NO_PAGE_TOKEN,
            hasMore: true,
            loadingItems: false,
        }
    }

    getNextQuestions() {
        if (this.state.loadingItems) {
            return;
        }

        this.setState({
            loadingItems: true
        })

        //list error
        Api.getQuizQuestions(this.props.courseItem.id, this.state.page, response => {
            if (response.status === true) {
                let items = this.state.items;
                items.push(...response.payload.items);

                this.setState({
                    items: items,
                    page: response.payload.token,
                    hasMore: Util.isStringExists(response.payload.token),
                    loadingItems: false
                })
            } else {
                //alert('errowr');
            }
        });
    }

    saveQuestion() {
        this.setState({savingQuestion: true})

        const adding = !Util.isStringExists(this.state.question.id);
        const updatingImage = this.state.questionFormImage !== undefined;
        Api.saveQuestion({
            id: this.state.question.id,
            courseItemId: this.props.courseItem.id,
            orderNo: this.state.question.orderNo,
            uniqueId: this.state.question.uniqueId,
            question: this.state.questionFormQuestion,
            hasImage: this.state.questionFormHasImage,
            choices: this.state.questionFormChoices
        }, this.state.questionFormImage, response => {
            if (response.status === true) {
                if (adding) {
                    this.state.items.unshift(response.payload);
                } else {
                    const index = this.state.items.indexOf(this.state.question);
                    if (index != -1) {
                        this.state.items[index] = response.payload;
                    }
                }
                if (updatingImage) {
                    this.setState({question: undefined, questionFormImage: undefined, savingQuestion: false, [response.payload.id + "-thumbnail-suffix"]: new Date().getTime()})
                } else {
                    this.setState({question: undefined, questionFormImage: undefined, savingQuestion: false})
                }
            } else {
                UIUtil.showError(response.message);
                this.setState({savingQuestion: false})
            }
        })
    }

    deleteQuestion(question) {
        this.setState({
            ['deleteLoading' + question.id]: true,
        })

        Api.deleteQuestion(question.id, response => {
            if (response.status === true) {
                const index = this.state.items.indexOf(question);
                if (index != -1) {
                    this.state.items.splice(index, 1);
                }
            } else {
                UIUtil.showError();
            }
            this.setState({
                ['deleteLoading' + question.id]: undefined,
            })
        })
    }

    setQuestion(question) {
        if (question !== undefined) {
            this.setState({question: question, questionFormQuestion: question.question, questionFormHasImage: question.hasImage, questionFormChoices: JSON.parse(JSON.stringify(question.choices))})
        } else {
            this.setState({question: {}, questionFormQuestion: "", questionFormHasImage: false, questionFormChoices: []})
        }
    }

    render() {
        return (
            <div>
                <PageHeader
                    title={this.props.courseItem.title}
                    subTitle="Quiz"
                    onBack={this.props.onBack}
                    extra={[<Button type="primary" onClick={() => this.setQuestion()}><PlusOutlined /> New Question</Button>]} />
                
                <div style={{paddingRight: '25px', marginTop: '10px'}}>
                    <InfiniteScroll
                        loadMore={this.getNextQuestions.bind(this)}
                        hasMore={this.state.hasMore}
                        threshold={750}
                        loader={
                            <div style={{display: 'flex', justifyContent: 'center', marginTop: '45px', marginBottom: '45px'}}>
                                <Spin size="large" tip="Loading..." />
                            </div>
                        }
                        style={{overflow: 'visible'}}>

                        {this.state.items.map((item, index) => {
                            return ( 
                                <div key={item.id}>
                                    {index > 0 ? <br /> : null}
                                    <QuizItem 
                                    quiz={item}
                                    onEditBtn={() => this.setQuestion(item)}
                                    onDeleteBtn={() => this.deleteQuestion(item)}
                                    deleteLoading={this.state['deleteLoading' + item.id]}
                                    thumbnailSuffix={this.state[item.id + "-thumbnail-suffix"]}/>
                                </div>
                            )
                        })}
                    </InfiniteScroll>
                    {!this.state.loadingItems ? <Empty style={{marginTop: '50px'}} image={Empty.PRESENTED_IMAGE_SIMPLE} description={this.state.items.length > 0 ? "No more questions" : "No questions"} /> : null}
                    <br />
                </div>

                <Modal
                //title={Util.isNumberExist(this.state.question) ? "Updating" : "New"}
                title={this.state.question !== undefined && Util.isStringExists(this.state.question.id) ? "Updating" : "New"}
                width={750}
                centered
                visible={this.state.question !== undefined}
                okText="Submit"
                okButtonProps={{disabled: this.state.questionFormQuestion.trim() === "" || this.state.questionFormChoices.length === 0}}
                confirmLoading={this.state.savingQuestion}
                onOk={() => this.saveQuestion()}
                onCancel={() => this.setState({question: undefined, questionFormImage: undefined})}>
                    <h3>Question</h3>
                    <Input size="large" maxLength={512} value={this.state.questionFormQuestion} onChange={e => this.setState({questionFormQuestion: e.target.value})} />

                    <FormPadding />
                    <div style={{width: '100%', display: 'flex'}}>
                        <h3>Image</h3>
                        <div style={{flex: '1'}} />
                        {(this.state.questionFormImage !== undefined || this.state.questionFormHasImage) &&
                        <Button danger style={{marginBottom: '15px'}} onClick={() => {
                            this.setState({
                                questionFormImage: undefined,
                                questionFormHasImage: false
                            })
                        }}><CloseCircleOutlined /> Clear Image</Button>}
                    </div>
                    <div style={{display: 'flex', maxHeight: '250px'}}>
                        <img src={this.state.questionFormImage !== undefined ? URL.createObjectURL(this.state.questionFormImage) : ((this.state.questionFormHasImage && this.state.question !== undefined) ? Api.getQuizQuestionImage(this.state.question.id) : NoImage)} onError={e => {
                            e.target.onerror = null;
                            e.target.src = NoImage;
                        }} style={{width: '300px', height: '250', objectFit: 'cover'}} />
                        <div style={{height: '250px', maxHeight: '250px', flex: 1, marginLeft: '25px'}}>
                            <Upload.Dragger accept="image/gif,image/jpeg,image/png" showUploadList={false} beforeUpload={file => {
                                this.setState({
                                    questionFormImage: file,
                                    questionFormHasImage: true
                                })
                                return false;
                            }}>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            </Upload.Dragger>
                        </div>
                    </div>

                    <br />
                    <Table rowKey={item => item.key !== undefined ? item.key : item.id} size="small" footer={() => 
                        <div style={{display: 'flex', justifyContent: "flex-end", alignItems: 'center'}}>
                            <Input maxLength={512} value={this.state.questionFormNewChoice} onChange={e => this.setState({questionFormNewChoice: e.target.value})} onPressEnter={() => {
                                if (this.state.questionFormNewChoice.trim() === "") {
                                    return;
                                }

                                const newChoice = {answer: this.state.questionFormNewChoice, key: Util.newTempId()};
                                this.setState(prevState => ({
                                    questionFormNewChoice: "",
                                    questionFormChoices: [...prevState.questionFormChoices, newChoice]
                                }))
                            }} placeholder="New Choice" style={{width: '200px'}} />
                            <Button disabled={this.state.questionFormNewChoice.trim() === ""} type="link" size="small" onClick={() => {
                                const newChoice = {answer: this.state.questionFormNewChoice, key: Util.newTempId()};
                                this.setState(prevState => ({
                                    questionFormNewChoice: "",
                                    questionFormChoices: [...prevState.questionFormChoices, newChoice]
                                }))
                            }}><PlusCircleOutlined /></Button>
                        </div>
                    } rowSelection={{type: 'radio', columnTitle: <CheckOutlined />, onChange: (sK, selectedRows) => {
                        for (const row of this.state.questionFormChoices) {
                            row.correct = false;
                        }
                        for (const row of selectedRows) {
                            row.correct = true;
                        }
                        this.setState({})
                    }, selectedRowKeys: this.state.questionFormChoices.filter(choice => choice.correct).map(item => item.key !== undefined ? item.key : item.id) }}
                    columns={[{title: 'Choice', dataIndex: 'answer'}, {title: '', render: choice => (
                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button type="link" size="small" danger onClick={() => {
                                const choices = [...this.state.questionFormChoices];
                                const index = choices.indexOf(choice);
                                if (index != -1) {
                                    choices.splice(index, 1);
                                    this.setState({questionFormChoices: choices})
                                }
                            }}><CloseOutlined /></Button>
                        </div>
                    )}]} 
                    dataSource={this.state.questionFormChoices} pagination={false} />
                    <FormPadding />
                </Modal>
            </div>
        );
    }
}

export default QuizDesigner;