
import { Modal } from 'antd';
import React, { createElement } from 'react';
import { VideoHTMLAttributes, useEffect, useRef } from 'react'

import { 
    AudioOutlined
} from '@ant-design/icons';

export default function Video({ srcObject, refListener, alwaysMuted, ...props }) {
    const refVideo = useRef(null)

    //const videoTracks = srcObject.getVideoTracks();
    const isAudio = srcObject !== undefined && srcObject.getVideoTracks !== undefined && srcObject.getVideoTracks().length == 0;

    useEffect(() => {
        if (!refVideo.current) return
        refVideo.current.srcObject = srcObject

        if (!alwaysMuted) {
            const video = refVideo.current;
            video.muted = false;
            video.play()
                .catch(() => {
                    if (refVideo.current === video) {
                        if (isAudio) {
                            Modal.info({
                                title: "Are you there?",
                                content: "We have muted a new stream waiting for you",
                                okText: "Unmute",
                                onOk: () => {
                                    if (refVideo.current === video) {
                                        video.play();
                                    }
                                }
                            })
                        } else {
                            video.muted = true;
                            video.play().then(() => {
                                if (refVideo.current === video) {
                                    Modal.info({
                                        title: "Are you there?",
                                        content: "We have muted a new stream waiting for you",
                                        okText: "Unmute",
                                        onOk: () => {
                                            if (refVideo.current === video) {
                                                video.muted = false;
                                            }
                                        }
                                    })
                                }
                            })  
                        }
                    }
                })
        }
    }, [srcObject])

    if (refListener !== undefined) {
        refListener(refVideo);
    }

    return isAudio ? (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
            <audio ref={refVideo} {...props} />
            <AudioOutlined style={{fontSize: '75px', color: 'black'}} />
        </div>
    ) : (
        <video ref={refVideo} {...props} />
    )
}