import React from 'react';
import queryString from 'query-string'
import SessionManager from '../session/SessionManager';
import { ACCOUNT_SCOPE_BACKEND } from '../Constants';

class Page extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pathParams: this.props.location !== undefined ? queryString.parse(this.props.location.search) : {}
        }
    }

    componentDidMount() {
        this.onPageStart();
    }

    componentDidUpdate(prevProps) {
        if (this.isComponentMode()) {
            return;
        }

        /*if (this.props.location !== prevProps.location) {
            console.log(this.props)
            window.scrollTo(0, 0)
        }*/

        if (this.props.location.search !== prevProps.location.search) {
            const pathParams = this.props.location !== undefined ? queryString.parse(this.props.location.search) : {};
            this.setState({
                pathParams: pathParams
            }, () => this.onPageStart());
        } else if (JSON.stringify(this.props.match.params) !== JSON.stringify(prevProps.match.params)) {
            this.setState({}, () => this.onPageStart())
        }
    }

    onPageStart() { }

    isComponentMode() {
        return this.props.location === undefined;
    }

}

export default Page;