
import React from 'react';

import {  
    SmileOutlined,
    CommentOutlined,
    AudioOutlined,
    FireOutlined,
    TrophyOutlined,
    BankOutlined,
    FundProjectionScreenOutlined,
    BgColorsOutlined,
    CameraOutlined,
    EllipsisOutlined
} from '@ant-design/icons';

export const CATEGORY_LIFESTYLE = 0;
export const CATEGORY_ENGLISH = 1;
export const CATEGORY_MUSIC = 2;
export const CATEGORY_COOKING = 3;
export const CATEGORY_FITNESS = 4;
export const CATEGORY_BUSINESS = 5;
export const CATEGORY_ACADEMICS = 6;
export const CATEGORY_ART = 7;
export const CATEGORY_PHOTOGRAPHY = 8;
export const CATEGORY_OTHER = 9;

export const CATEGORIES = [
    {
        id: CATEGORY_LIFESTYLE,
        title: "Lifestyle", 
        icon: <SmileOutlined />
    },
    {
        id: CATEGORY_ENGLISH,
        title: "English", 
        icon: <CommentOutlined />
    },
    {
        id: CATEGORY_MUSIC,
        title: "Music", 
        icon: <AudioOutlined />
    },
    {
        id: CATEGORY_COOKING,
        title: "Cooking", 
        icon: <FireOutlined />
    },
    {
        id: CATEGORY_FITNESS,
        title: "Fitness", 
        icon: <TrophyOutlined />
    },
    {
        id: CATEGORY_BUSINESS,
        title: "Business", 
        icon: <BankOutlined />
    },
    {
        id: CATEGORY_ACADEMICS,
        title: "Academics", 
        icon: <FundProjectionScreenOutlined />
    },
    {
        id: CATEGORY_ART,
        title: "Art", 
        icon: <BgColorsOutlined />
    },
    {
        id: CATEGORY_PHOTOGRAPHY,
        title: "Photography", 
        icon: <CameraOutlined />
    },
    {
        id: CATEGORY_OTHER,
        title: "Other", 
        icon: <EllipsisOutlined />
    }
];