import React, { useEffect } from 'react'
import Modal from 'antd/lib/modal/Modal'
import { Button, Popconfirm, Spin, Modal as ModalUtil, Alert, Popover, Input, Tag } from 'antd'

import Logo from '../images/Logo.png';
import OrganizationIcon from '../images/OrganizationIcon.png';

import { 
    CloseOutlined,
    CreditCardOutlined,
    LoadingOutlined,
    KeyOutlined,
    ArrowRightOutlined
} from '@ant-design/icons';
import Api from '../session/Api';
import Util from '../util/Util';
import UIUtil from '../util/UIUtil';
import { ORGANIZATION_PACKAGE_BRONZE, ORGANIZATION_PACKAGE_GOLD, ORGANIZATION_PACKAGE_PLATINUM, ORGANIZATION_PACKAGE_SILVER } from '../Constants';

export default function OrganizationAccessDialog(props) {
    const [initialLoading, setInitialLoading] = React.useState(props.accountAccess == undefined);
    const [accountAccess, setAccountAccess] = React.useState(props.accountAccess);

    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        if (props.accountAccess == undefined) {
            Api.getAccountAccess(response => {
                setAccountAccess(response.status === true ? response.payload : undefined)
                setInitialLoading(false);
            })
        }
    }, [])

    const onActivateBtn = (serialNo) => {
        if (!Util.isStringExists(serialNo)) {
            return;
        }

        setLoading(true);

        const listener = response => {
            setLoading(false);
            if (response.status === true) {
                ModalUtil.success({
                    title: "Success!",
                    content: "You have activated your organization account!",
                    onOk: () => props.customActivateHandlerOnSuccess !== undefined ? props.customActivateHandlerOnSuccess(response) : window.location.reload()
                })
            } else {
                UIUtil.showError(response.message);
            }
        };
    
        if (props.customActivateHandler !== undefined) {
            props.customActivateHandler(serialNo, listener);
        } else {
            Api.activateAccountAccess(serialNo, listener);
        }
    }

    const renderOrganizationPackage = () => {
        let name;
        let color;
        let bgColor;
        switch (accountAccess.organizationPackage) {
            case ORGANIZATION_PACKAGE_BRONZE:
                name = "Bronze";
                color = "#cd7f32";
                bgColor = "volcano"
                break;
            case ORGANIZATION_PACKAGE_SILVER:
                name = "Silver";
                color = "gray";
                break;
            case ORGANIZATION_PACKAGE_GOLD:
                name = "Gold";
                color = "goldenrod";
                bgColor = "orange";
                break;
            case ORGANIZATION_PACKAGE_PLATINUM:
                name = "Platinum";
                color = "#2f54eb";
                bgColor = "geekblue"
                break;
        }

        return (
            <Tag color={bgColor} style={{marginTop: '15px', height: '35px', paddingLeft: '25px', paddingRight: '25px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{width: '15px', height: '15px', background: color, marginRight: '5px', borderRadius: '50%'}} /> 
                    <p style={{height: '7px'}}>{name} Package</p>
                </div>
            </Tag>
        )
    }

    return (
        <Modal footer={null} bodyStyle={{padding: 0, background: '#f2994a'}} closeIcon={<CloseOutlined style={{color: 'black'}} />} {...props}>
            <div style={{width: '100%', backgroundColor: '#DBDBDB' , backgroundImage: 'linear-gradient(to right, #DBDBDB, #EAEAEA)',
            display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '50px', paddingBottom: '50px'}}>
                <img src={Logo} style={{width: '125px'}} />
                <img src={OrganizationIcon} style={{width: '80px', marginTop: '10px'}} />

                <div style={{height: '40px'}} />

                <h3 style={{color: 'black'}}>Always be connected</h3>

                <p style={{padding: '10px', paddingLeft: '25px', paddingRight: '25px', color: '#545454', fontSize: '12px', textAlign: 'center',overflowWrap: 'break-word', whiteSpace: 'pre-line'}}>Connect with and train members in your organization by managing courses tailored specifically for your needs.</p>

                <div style={{height: '25px'}} />

                {!initialLoading && accountAccess !== undefined ? <>
                    { accountAccess.activated ? (<>
                        <Alert type="success" showIcon message="Account is activated!" />
                        {renderOrganizationPackage()}
                    </>) : (<>
                        <p style={{color: accountAccess.daysLeftTillOrganization < 0 ? 'red' : '#545454', fontSize: '10px', textAlign: 'center', overflowWrap: 'break-word', whiteSpace: 'pre-line'}}>
                            {accountAccess.daysLeftTillOrganization < 0 ? (<>
                                Organization account trial is over, activate account to continue
                            </>) : (<>
                                {Util.getDaysLeft(accountAccess.daysLeftTillOrganization)} in trial mode
                            </>)}
                        </p>
                        {loading ? (
                            <Button loading={loading} size="medium" type="primary"><KeyOutlined /> Activate Account</Button>
                        ) : (
                            <Popover trigger="click" content={
                                <Input.Group size="large" compact>
                                    <Input.Search loading={loading} size="large" style={{ width: '300px' }} placeholder="Serial Number"
                                    enterButton={<Button><ArrowRightOutlined /></Button>}
                                    onSearch={value => onActivateBtn(value)} />
                                </Input.Group>
                            }>
                                <Button loading={loading} size="medium" type="primary"><KeyOutlined /> Activate Account</Button>
                            </Popover>
                        )}
                        
                    </>)}
                </> : <Spin indicator={<LoadingOutlined style={{ fontSize: 30, color: 'black' }} spin />} style={{color: 'black'}} /> }

            </div>
        </Modal>
    )
}